var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { useBankAccountForm } from "../services/bank-account-modal";
import { mapBooleanToState, mapStateToBoolean } from "GlobalShared/helpers/booleanHelper";
import { getAllBankAccountConfigFields } from "../services/BankAccountConfigValidator";
import {
  canHavePaymentReference,
  getEslFrequencyOptions,
  getFrequencyDataSource,
  getIntrastatFrequencyOptions,
  getLocalListingFrequencyOptions,
  getPrimaryVatFrequencyOptions,
  getSecondaryVatFrequencyOptions,
  getVlsFrequencyOptions
} from "../services/service-helpers";
import { getLocalVatChangeFields, LocalVatChangeValidator } from "./LocalVatChangeValidator";
import { isEuCountry } from "GlobalShared/helpers/countryHelper";
import { ONLY_PDF_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
const DEFAULT_LOCAL_VAT_CHANGE = {
  LocalVATCertificateReferenceId: void 0,
  LocalVatNumber: void 0,
  TaxIdentifier: void 0,
  LocalVatRegistrationDate: void 0,
  PaymentReference: void 0,
  PrimaryVATFrequencyType: void 0,
  PrimaryVATEFiling: void 0,
  PrimaryVATFilingExtension: void 0,
  SecondaryVATFrequencyType: void 0,
  SecondaryVATEFiling: void 0,
  SecondaryVATFilingExtension: void 0,
  VLSFrequencyType: void 0,
  VLSEFiling: void 0,
  VLSFilingExtension: void 0,
  ESLFrequencyType: void 0,
  ESLEFiling: void 0,
  ESLFilingExtension: void 0,
  LocalListingFrequencyType: void 0,
  IntrastatFrequencyType: void 0,
  IntrastatEFiling: void 0,
  IntrastatFilingExtension: void 0,
  primaryVatEFilingOptions: [],
  primaryVatFilingExtensionOptions: [],
  secondaryVatEFilingOptions: [],
  secondaryVatFilingExtensionOptions: [],
  eslEFilingOptions: [],
  eslFilingExtensionOptions: [],
  localListingEFilingOptions: [],
  localListingFilingExtensionOptions: [],
  vlsEFilingOptions: [],
  vlsFilingExtensionOptions: [],
  intrastatEFilingOptions: [],
  intrastatFilingExtensionOptions: [],
  sendEmail: true,
  TaxOfficeId: void 0
};
export const useVatRegLocalVatModal = (props) => {
  const showToggle = (options, val, defaultValue) => {
    return options.length === 1 && val !== void 0 && val !== defaultValue || options.length === 2;
  };
  const save = () => __async(void 0, null, function* () {
    setLocalVatChange(__spreadValues({}, localVatChange));
    bankAccountForm.setBankAccountConfig(__spreadValues({}, bankAccountForm.bankAccountConfig));
    localVatChangeValidationContext.validateFields(getLocalVatChangeFields());
    bankAccountForm.bankAccountConfigValidationContext.validateFields(getAllBankAccountConfigFields());
    setDispatchIfLocalVatChangeIsValid(true);
  });
  const show = () => {
    setShowModal(true);
  };
  const calculatePrimaryVATFrequencyOptions = (primaryVATFrequencyType) => {
    if (primaryVATFrequencyType) {
      let newPrimaryVatEFiling = void 0;
      let newPrimaryVatFilingExtension = void 0;
      const options = getPrimaryVatFrequencyOptions(props.submissionConfig, props.serviceCountryCode, primaryVATFrequencyType);
      if (options.primaryVatEFilingOptions.length === 1) {
        newPrimaryVatEFiling = options.primaryVatEFilingOptions[0] === "Yes";
      }
      if (options.primaryVatFilingExtensionOptions.length === 1) {
        newPrimaryVatFilingExtension = options.primaryVatFilingExtensionOptions[0] === "Yes";
      }
      return {
        primaryVatEFilingOptions: options.primaryVatEFilingOptions,
        primaryVatFilingExtensionOptions: options.primaryVatFilingExtensionOptions,
        PrimaryVATEFiling: newPrimaryVatEFiling,
        PrimaryVATFilingExtension: newPrimaryVatFilingExtension
      };
    } else {
      return {
        primaryVatEFilingOptions: [],
        primaryVatFilingExtensionOptions: [],
        PrimaryVATEFiling: void 0,
        PrimaryVATFilingExtension: void 0
      };
    }
  };
  const calculateSecondaryVATFrequencyOptions = (secondaryVATFrequencyType) => {
    if (secondaryVATFrequencyType) {
      let newSecondaryVatEFiling = void 0;
      let newSecondaryVatFilingExtension = void 0;
      const options = getSecondaryVatFrequencyOptions(props.submissionConfig, props.serviceCountryCode, secondaryVATFrequencyType);
      if (options.secondaryVatEFilingOptions.length === 1) {
        newSecondaryVatEFiling = options.secondaryVatEFilingOptions[0] === "Yes";
      }
      if (options.secondaryVatFilingExtensionOptions.length === 1) {
        newSecondaryVatFilingExtension = options.secondaryVatFilingExtensionOptions[0] === "Yes";
      }
      return {
        secondaryVatEFilingOptions: options.secondaryVatEFilingOptions,
        secondaryVatFilingExtensionOptions: options.secondaryVatFilingExtensionOptions,
        SecondaryVATEFiling: newSecondaryVatEFiling,
        SecondaryVATFilingExtension: newSecondaryVatFilingExtension
      };
    } else {
      return {
        secondaryVatEFilingOptions: [],
        secondaryVatFilingExtensionOptions: [],
        SecondaryVATEFiling: void 0,
        SecondaryVATFilingExtension: void 0
      };
    }
  };
  const calculateVLSFrequencyOptions = (vlsFrequencyType) => {
    if (vlsFrequencyType) {
      let newVLSEFiling = void 0;
      let newVLSFilingExtension = void 0;
      const options = getVlsFrequencyOptions(props.submissionConfig, props.serviceCountryCode, vlsFrequencyType);
      if (options.vlsEFilingOptions.length === 1) {
        newVLSEFiling = options.vlsEFilingOptions[0] === "Yes";
      }
      if (options.vlsFilingExtensionOptions.length === 1) {
        newVLSFilingExtension = options.vlsFilingExtensionOptions[0] === "Yes";
      }
      return {
        vlsEFilingOptions: options.vlsEFilingOptions,
        vlsFilingExtensionOptions: options.vlsFilingExtensionOptions,
        VLSEFiling: newVLSEFiling,
        VLSFilingExtension: newVLSFilingExtension
      };
    } else {
      return {
        vlsEFilingOptions: [],
        vlsFilingExtensionOptions: [],
        VLSEFiling: void 0,
        VLSFilingExtension: void 0
      };
    }
  };
  const calculateESLFrequencyOptions = (eslFrequencyType) => {
    if (eslFrequencyType) {
      let newESLEFiling = void 0;
      let newESLFilingExtension = void 0;
      const options = getEslFrequencyOptions(props.submissionConfig, props.serviceCountryCode, eslFrequencyType);
      if (options.eslEFilingOptions.length === 1) {
        newESLEFiling = options.eslEFilingOptions[0] === "Yes";
      }
      if (options.eslFilingExtensionOptions.length === 1) {
        newESLFilingExtension = options.eslFilingExtensionOptions[0] === "Yes";
      }
      return {
        eslEFilingOptions: options.eslEFilingOptions,
        eslFilingExtensionOptions: options.eslFilingExtensionOptions,
        ESLEFiling: newESLEFiling,
        ESLFilingExtension: newESLFilingExtension
      };
    } else {
      return {
        eslEFilingOptions: [],
        eslFilingExtensionOptions: [],
        ESLEFiling: void 0,
        ESLFilingExtension: void 0
      };
    }
  };
  const calculateLocalListingFrequencyOptions = (localListingFrequencyType) => {
    if (localListingFrequencyType) {
      let newLocalListingEFiling = void 0;
      let newLocalListingFilingExtension = void 0;
      const options = getLocalListingFrequencyOptions(props.submissionConfig, props.serviceCountryCode, localListingFrequencyType);
      if (options.localListingEFilingOptions.length === 1) {
        newLocalListingEFiling = options.localListingEFilingOptions[0] === "Yes";
      }
      if (options.localListingFilingExtensionOptions.length === 1) {
        newLocalListingFilingExtension = options.localListingFilingExtensionOptions[0] === "Yes";
      }
      return {
        localListingEFilingOptions: options.localListingEFilingOptions,
        localListingFilingExtensionOptions: options.localListingFilingExtensionOptions,
        localListingEFiling: newLocalListingEFiling,
        localListingFilingExtension: newLocalListingFilingExtension
      };
    } else {
      return {
        localListingEFilingOptions: [],
        localListingFilingExtensionOptions: [],
        localListingEFiling: void 0,
        localListingFilingExtension: void 0
      };
    }
  };
  const calculateIntrastatFrequencyOptions = (intrastatFrequencyType) => {
    if (intrastatFrequencyType) {
      let newIntrastatEFiling = void 0;
      let newIntrastatFilingExtension = void 0;
      const options = getIntrastatFrequencyOptions(props.submissionConfig, props.serviceCountryCode, intrastatFrequencyType);
      if (options.intrastatEFilingOptions.length === 1) {
        newIntrastatEFiling = options.intrastatEFilingOptions[0] === "Yes";
      }
      if (options.intrastatFilingExtensionOptions.length === 1) {
        newIntrastatFilingExtension = options.intrastatFilingExtensionOptions[0] === "Yes";
      }
      return {
        intrastatEFilingOptions: options.intrastatEFilingOptions,
        intrastatFilingExtensionOptions: options.intrastatFilingExtensionOptions,
        IntrastatEFiling: newIntrastatEFiling,
        IntrastatFilingExtension: newIntrastatFilingExtension
      };
    } else {
      return {
        intrastatEFilingOptions: [],
        intrastatFilingExtensionOptions: [],
        IntrastatEFiling: void 0,
        IntrastatFilingExtension: void 0
      };
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [localVatChange, setLocalVatChange] = useState(DEFAULT_LOCAL_VAT_CHANGE);
  const [primaryVatFrequencies, setPrimaryVatFrequencies] = useState([]);
  const [secondaryVatFrequencies, setSecondaryVatFrequencies] = useState([]);
  const [vlsFrequencies, setVlsFrequencies] = useState([]);
  const [eslFrequencies, setESLFrequencies] = useState([]);
  const [localListingFrequencies, setLocalListingFrequencies] = useState([]);
  const [intrastatFrequencies, setIntrastatFrequencies] = useState([]);
  const bankAccountForm = useBankAccountForm({
    serviceCountryCode: props == null ? void 0 : props.serviceCountryCode,
    isInEu: isEuCountry(props == null ? void 0 : props.euCountries, props == null ? void 0 : props.establishmentCountryCode, !(props == null ? void 0 : props.hasFiscalRepContract)),
    bankAccountConfig: props == null ? void 0 : props.bankAccountConfig,
    predefinedBankAccounts: props == null ? void 0 : props.predefinedBankAccounts
  });
  const localVatChangeValidationContext = useValidationContext(
    new LocalVatChangeValidator(props == null ? void 0 : props.submissionConfig, props == null ? void 0 : props.serviceCountryCode, props == null ? void 0 : props.hasIntrastatSubscription)
  );
  const [dispatchIfLocalVatChangeIsValid, setDispatchIfLocalVatChangeIsValid] = useState(false);
  useEffect(() => __async(void 0, null, function* () {
    if ((props == null ? void 0 : props.submissionConfig) !== void 0 && (props == null ? void 0 : props.serviceCountryCode)) {
      const tempPrimaryVatFrequencies = getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "PrimaryVAT");
      setPrimaryVatFrequencies(tempPrimaryVatFrequencies);
      const tempVlsFrequencies = getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "VLS");
      setVlsFrequencies(tempVlsFrequencies);
      const tempESLFrequencies = getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "ESL");
      setESLFrequencies(tempESLFrequencies);
      const tempLocalListingFrequencies = getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "LocalListing");
      setLocalListingFrequencies(tempLocalListingFrequencies);
      const tempSecondaryVatFrequencies = getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "SecondaryVAT");
      setSecondaryVatFrequencies(tempSecondaryVatFrequencies);
      const tempIntrastatFrequencies = getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "Intrastat");
      setIntrastatFrequencies(tempIntrastatFrequencies);
      const primaryVATFrequencyType = tempPrimaryVatFrequencies.length === 1 ? tempPrimaryVatFrequencies[0] : void 0;
      const secondaryVatFrequencyType = tempSecondaryVatFrequencies.length === 1 ? tempSecondaryVatFrequencies[0] : void 0;
      const vlsFrequencyType = tempVlsFrequencies.length === 1 ? tempVlsFrequencies[0] : void 0;
      const eslFrequencyType = tempESLFrequencies.length === 1 ? tempESLFrequencies[0] : void 0;
      const localListingFrequencyType = tempLocalListingFrequencies.length === 1 ? tempLocalListingFrequencies[0] : void 0;
      const intrastatFrequencyType = tempIntrastatFrequencies.length === 1 ? tempIntrastatFrequencies[0] : void 0;
      const primaryVATOptions = calculatePrimaryVATFrequencyOptions(primaryVATFrequencyType);
      const secondaryVATOptions = calculateSecondaryVATFrequencyOptions(secondaryVatFrequencyType);
      const vlsOptions = calculateVLSFrequencyOptions(vlsFrequencyType);
      const eslOptions = calculateESLFrequencyOptions(eslFrequencyType);
      const localListingOptions = calculateLocalListingFrequencyOptions(localListingFrequencyType);
      const intrastatOptions = calculateIntrastatFrequencyOptions(intrastatFrequencyType);
      setLocalVatChange(__spreadProps(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, DEFAULT_LOCAL_VAT_CHANGE), primaryVATOptions), secondaryVATOptions), vlsOptions), eslOptions), localListingOptions), intrastatOptions), {
        PrimaryVATFrequencyType: primaryVATFrequencyType,
        VLSFrequencyType: vlsFrequencyType,
        ESLFrequencyType: eslFrequencyType,
        LocalListingFrequencyType: localListingFrequencyType,
        SecondaryVATFrequencyType: secondaryVatFrequencyType,
        IntrastatFrequencyType: intrastatFrequencyType,
        LocalVatNumber: props.localVatCertificate.LocalVatNumber,
        LocalVATCertificateReferenceId: props.localVatCertificate.LocalVATCertificateReferenceId,
        LocalVatRegistrationDate: props.localVatCertificate.LocalVatRegistrationDate
      }));
      bankAccountForm.bankAccountConfigValidationContext.clearFieldValidations();
      localVatChangeValidationContext.clearFieldValidations();
    }
  }), [props]);
  useEffect(() => {
    const primaryVATOptions = calculatePrimaryVATFrequencyOptions(localVatChange == null ? void 0 : localVatChange.PrimaryVATFrequencyType);
    setLocalVatChange(__spreadValues(__spreadValues({}, localVatChange), primaryVATOptions));
  }, [localVatChange == null ? void 0 : localVatChange.PrimaryVATFrequencyType]);
  useEffect(() => {
    const secondaryVATOptions = calculateSecondaryVATFrequencyOptions(localVatChange == null ? void 0 : localVatChange.SecondaryVATFrequencyType);
    setLocalVatChange(__spreadValues(__spreadValues({}, localVatChange), secondaryVATOptions));
  }, [localVatChange == null ? void 0 : localVatChange.SecondaryVATFrequencyType]);
  useEffect(() => {
    const vlsOptions = calculateVLSFrequencyOptions(localVatChange == null ? void 0 : localVatChange.VLSFrequencyType);
    setLocalVatChange(__spreadValues(__spreadValues({}, localVatChange), vlsOptions));
  }, [localVatChange == null ? void 0 : localVatChange.VLSFrequencyType]);
  useEffect(() => {
    const eslOptions = calculateESLFrequencyOptions(localVatChange == null ? void 0 : localVatChange.ESLFrequencyType);
    setLocalVatChange(__spreadValues(__spreadValues({}, localVatChange), eslOptions));
  }, [localVatChange == null ? void 0 : localVatChange.ESLFrequencyType]);
  useEffect(() => {
    const localListingOptions = calculateLocalListingFrequencyOptions(localVatChange == null ? void 0 : localVatChange.LocalListingFrequencyType);
    setLocalVatChange(__spreadValues(__spreadValues({}, localVatChange), localListingOptions));
  }, [localVatChange == null ? void 0 : localVatChange.LocalListingFrequencyType]);
  useEffect(() => {
    const intrastatOptions = calculateIntrastatFrequencyOptions(localVatChange == null ? void 0 : localVatChange.IntrastatFrequencyType);
    setLocalVatChange(__spreadValues(__spreadValues({}, localVatChange), intrastatOptions));
  }, [localVatChange == null ? void 0 : localVatChange.IntrastatFrequencyType]);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfLocalVatChangeIsValid) {
      setDispatchIfLocalVatChangeIsValid(false);
      if (localVatChangeValidationContext.validationResult.isValid() && bankAccountForm.bankAccountConfigValidationContext.validationResult.isValid()) {
        yield props.save(__spreadProps(__spreadValues({}, localVatChange), { BankAccountConfig: bankAccountForm.bankAccountConfig }));
        setShowModal(false);
      }
    }
  }), [localVatChangeValidationContext.validationResult]);
  useEffect(() => {
    if (bankAccountForm.bankAccountConfig !== void 0) {
      bankAccountForm.bankAccountConfigValidationContext.validate(bankAccountForm.bankAccountConfig);
    }
  }, [bankAccountForm.bankAccountConfig]);
  useEffect(() => {
    if (localVatChange !== void 0) {
      localVatChangeValidationContext.validate(localVatChange);
    }
  }, [localVatChange]);
  const htmlPrimaryVatFrequency = () => {
    const selectEnabled = primaryVatFrequencies.length > 0;
    const eFilingToggleEnabled = showToggle(localVatChange.primaryVatEFilingOptions, localVatChange.PrimaryVATEFiling, true);
    const filingExtensionToggleEnabled = showToggle(localVatChange.primaryVatFilingExtensionOptions, localVatChange.PrimaryVATFilingExtension, false);
    return (props == null ? void 0 : props.submissionConfig) === void 0 || !selectEnabled && !eFilingToggleEnabled && !filingExtensionToggleEnabled ? html`Loading...` : html`<div>
					${selectEnabled ? html`<dc-select
								.label=${"Primary VAT Frequency"}
								.readonly=${primaryVatFrequencies.length === 0}
								.dataSource=${primaryVatFrequencies}
								.selectedValues=${[localVatChange.PrimaryVATFrequencyType]}
								.validationMessage=${localVatChangeValidationContext.getValidationMessage("PrimaryVatFrequency")}
								@change=${(e) => {
      localVatChangeValidationContext.validateField("PrimaryVatFrequency");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        PrimaryVATFrequencyType: e.detail.selectedValue
      }));
    }}
						  ></dc-select>` : ""}
					<div class="flex space-x-4">
						${eFilingToggleEnabled ? html`<dc-toggle
									.label=${"E-filing"}
									.state=${mapBooleanToState(localVatChange.PrimaryVATEFiling)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("PrimaryVatEFiling")}
									.readonly=${localVatChange.primaryVatEFilingOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("PrimaryVatEFiling");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        PrimaryVATEFiling: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
						${filingExtensionToggleEnabled ? html`<dc-toggle
									.label=${"Filing extension"}
									.state=${mapBooleanToState(localVatChange.PrimaryVATFilingExtension)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("PrimaryVatFilingExtension")}
									.readonly=${localVatChange.primaryVatFilingExtensionOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("PrimaryVatFilingExtension");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        PrimaryVATFilingExtension: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
					</div>
			  </div>`;
  };
  const htmlSecondaryVatFrequency = () => {
    const selectEnabled = secondaryVatFrequencies.length > 0;
    const eFilingToggleEnabled = showToggle(localVatChange.secondaryVatEFilingOptions, localVatChange.SecondaryVATEFiling, true);
    const filingExtensionToggleEnabled = showToggle(localVatChange.secondaryVatFilingExtensionOptions, localVatChange.SecondaryVATFilingExtension, false);
    return (props == null ? void 0 : props.submissionConfig) === void 0 || !selectEnabled && !eFilingToggleEnabled && !filingExtensionToggleEnabled ? "" : html`<div>
					${selectEnabled ? html`<dc-select
								.label=${"Secondary VAT Frequency"}
								.readonly=${secondaryVatFrequencies.length === 0}
								.dataSource=${secondaryVatFrequencies}
								.selectedValues=${[localVatChange.SecondaryVATFrequencyType]}
								.validationMessage=${localVatChangeValidationContext.getValidationMessage("SecondaryVatFrequency")}
								@change=${(e) => {
      localVatChangeValidationContext.validateField("SecondaryVatFrequency");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        SecondaryVATFrequencyType: e.detail.selectedValue
      }));
    }}
						  ></dc-select>` : ""}
					<div class="flex space-x-4">
						${eFilingToggleEnabled ? html`<dc-toggle
									.label=${"E-filing"}
									.state=${mapBooleanToState(localVatChange.SecondaryVATEFiling)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("SecondaryVatEFiling")}
									.readonly=${localVatChange.secondaryVatEFilingOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("SecondaryVatEFiling");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        SecondaryVATEFiling: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
						${filingExtensionToggleEnabled ? html`<dc-toggle
									.label=${"Filing extension"}
									.state=${mapBooleanToState(localVatChange.SecondaryVATFilingExtension)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("SecondaryVatFilingExtension")}
									.readonly=${localVatChange.secondaryVatFilingExtensionOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("SecondaryVatFilingExtension");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        SecondaryVATFilingExtension: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
					</div>
			  </div>`;
  };
  const htmlVLSFrequency = () => {
    const selectEnabled = vlsFrequencies.length > 0;
    const eFilingToggleEnabled = showToggle(localVatChange.vlsEFilingOptions, localVatChange.VLSEFiling, true);
    const filingExtensionToggleEnabled = showToggle(localVatChange.vlsFilingExtensionOptions, localVatChange.VLSFilingExtension, false);
    return (props == null ? void 0 : props.submissionConfig) === void 0 || !selectEnabled && !eFilingToggleEnabled && !filingExtensionToggleEnabled ? "" : html`<div>
					${selectEnabled ? html`<dc-select
								.label=${"VLS Frequency"}
								.readonly=${vlsFrequencies.length === 0}
								.dataSource=${vlsFrequencies}
								.selectedValues=${[localVatChange.VLSFrequencyType]}
								.validationMessage=${localVatChangeValidationContext.getValidationMessage("VlsFrequency")}
								@change=${(e) => {
      localVatChangeValidationContext.validateField("VlsFrequency");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        VLSFrequencyType: e.detail.selectedValue
      }));
    }}
						  ></dc-select>` : ""}
					<div class="flex space-x-4">
						${eFilingToggleEnabled ? html`<dc-toggle
									.label=${"E-filing"}
									.state=${mapBooleanToState(localVatChange.VLSEFiling)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("VlsEFiling")}
									.readonly=${localVatChange.vlsEFilingOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("VlsEFiling");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        VLSEFiling: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
						${filingExtensionToggleEnabled ? html`<dc-toggle
									.label=${"Filing extension"}
									.state=${mapBooleanToState(localVatChange.VLSFilingExtension)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("VlsFilingExtension")}
									.readonly=${localVatChange.vlsFilingExtensionOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("VlsFilingExtension");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        VLSFilingExtension: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
					</div>
			  </div>`;
  };
  const htmlESLFrequency = () => {
    const selectEnabled = eslFrequencies.length > 0;
    const eFilingToggleEnabled = showToggle(localVatChange.eslEFilingOptions, localVatChange.ESLEFiling, true);
    const filingExtensionToggleEnabled = showToggle(localVatChange.eslFilingExtensionOptions, localVatChange.ESLFilingExtension, false);
    return (props == null ? void 0 : props.submissionConfig) === void 0 || !selectEnabled && !eFilingToggleEnabled && !filingExtensionToggleEnabled ? "" : html`<div>
					${selectEnabled ? html`<dc-select
								.label=${"ESL Frequency"}
								.readonly=${eslFrequencies.length === 0}
								.dataSource=${eslFrequencies}
								.selectedValues=${[localVatChange.ESLFrequencyType]}
								.validationMessage=${localVatChangeValidationContext.getValidationMessage("EslFrequency")}
								@change=${(e) => {
      localVatChangeValidationContext.validateField("EslFrequency");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        ESLFrequencyType: e.detail.selectedValue
      }));
    }}
						  ></dc-select>` : ""}
					<div class="flex space-x-4">
						${eFilingToggleEnabled ? html`<dc-toggle
									.label=${"E-filing"}
									.state=${mapBooleanToState(localVatChange.ESLEFiling)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("EslEFiling")}
									.readonly=${localVatChange.eslEFilingOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("EslEFiling");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        ESLEFiling: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
						${filingExtensionToggleEnabled ? html`<dc-toggle
									.label=${"Filing extension"}
									.state=${mapBooleanToState(localVatChange.ESLFilingExtension)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("EslFilingExtension")}
									.readonly=${localVatChange.eslFilingExtensionOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("EslFilingExtension");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        ESLFilingExtension: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
					</div>
			  </div>`;
  };
  const htmlLocalListingFrequency = () => {
    const selectEnabled = localListingFrequencies.length > 0;
    const eFilingToggleEnabled = showToggle(localVatChange.localListingEFilingOptions, localVatChange.localListingEFiling, true);
    const filingExtensionToggleEnabled = showToggle(localVatChange.localListingFilingExtensionOptions, localVatChange.localListingFilingExtension, false);
    return (props == null ? void 0 : props.submissionConfig) === void 0 || !selectEnabled && !eFilingToggleEnabled && !filingExtensionToggleEnabled ? "" : html`<div>
					${selectEnabled ? html`<dc-select
								.label=${"Local Listing Frequency"}
								.readonly=${localListingFrequencies.length === 0}
								.dataSource=${localListingFrequencies}
								.selectedValues=${[localVatChange.LocalListingFrequencyType]}
								.validationMessage=${localVatChangeValidationContext.getValidationMessage("LocalListingFrequency")}
								@change=${(e) => {
      localVatChangeValidationContext.validateField("LocalListingFrequency");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        LocalListingFrequencyType: e.detail.selectedValue
      }));
    }}
						  ></dc-select>` : ""}
					<div class="flex space-x-4">
						${eFilingToggleEnabled ? html`<dc-toggle
									.label=${"E-filing"}
									.state=${mapBooleanToState(localVatChange.localListingEFiling)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("LocalListingEFiling")}
									.readonly=${localVatChange.localListingEFilingOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("LocalListingEFiling");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        localListingEFiling: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
						${filingExtensionToggleEnabled ? html`<dc-toggle
									.label=${"Filing extension"}
									.state=${mapBooleanToState(localVatChange.localListingFilingExtension)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("LocalListingFilingExtension")}
									.readonly=${localVatChange.localListingFilingExtensionOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("LocalListingFilingExtension");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        localListingFilingExtension: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
					</div>
			  </div>`;
  };
  const htmlIntrastatFrequency = () => {
    const selectEnabled = intrastatFrequencies.length > 0 && (props == null ? void 0 : props.hasIntrastatSubscription) === true;
    const eFilingToggleEnabled = showToggle(localVatChange.intrastatEFilingOptions, localVatChange.IntrastatEFiling, true);
    const filingExtensionToggleEnabled = showToggle(localVatChange.intrastatFilingExtensionOptions, localVatChange.IntrastatFilingExtension, false);
    return (props == null ? void 0 : props.submissionConfig) === void 0 || !selectEnabled && !eFilingToggleEnabled && !filingExtensionToggleEnabled ? "" : html`<div>
					${selectEnabled ? html`<dc-select
								.label=${"Intrastat Frequency"}
								.readonly=${intrastatFrequencies.length === 0}
								.dataSource=${intrastatFrequencies}
								.selectedValues=${[localVatChange.IntrastatFrequencyType]}
								.validationMessage=${localVatChangeValidationContext.getValidationMessage("IntrastatFrequency")}
								@change=${(e) => {
      localVatChangeValidationContext.validateField("IntrastatFrequency");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        IntrastatFrequencyType: e.detail.selectedValue
      }));
    }}
						  ></dc-select>` : ""}
					<div class="flex space-x-4">
						${eFilingToggleEnabled ? html`<dc-toggle
									.label=${"E-filing"}
									.state=${mapBooleanToState(localVatChange.IntrastatEFiling)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("IntrastatEFiling")}
									.readonly=${localVatChange.intrastatEFilingOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("IntrastatEFiling");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        IntrastatEFiling: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
						${filingExtensionToggleEnabled ? html`<dc-toggle
									.label=${"Filing extension"}
									.state=${mapBooleanToState(localVatChange.IntrastatFilingExtension)}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("IntrastatFilingExtension")}
									.readonly=${localVatChange.intrastatFilingExtensionOptions.length === 1}
									@change=${(e) => {
      localVatChangeValidationContext.validateField("IntrastatFilingExtension");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        IntrastatFilingExtension: mapStateToBoolean(e.detail.state)
      }));
    }}
							  ></dc-toggle>` : ""}
					</div>
			  </div>`;
  };
  const htmlLocalVatNumberAndRegAndPaymentRef = () => {
    return html`<div class="md:flex md:space-x-8">
			<dc-input
				class="md:w-1/2"
				.label=${"VAT number"}
				.value=${localVatChange.LocalVatNumber}
				.validationMessage=${localVatChangeValidationContext.getValidationMessage("LocalVatNumber")}
				@change=${(e) => {
      localVatChangeValidationContext.validateField("LocalVatNumber");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        LocalVatNumber: e.detail.value
      }));
    }}
			></dc-input>
			${props.serviceCountryCode === "IT" ? html`<dc-input
						class="md:w-1/2"
						.label=${"Tax Identifier"}
						.value=${localVatChange.TaxIdentifier}
						.validationMessage=${localVatChangeValidationContext.getValidationMessage("TaxIdentifier")}
						@change=${(e) => {
      localVatChangeValidationContext.validateField("TaxIdentifier");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        TaxIdentifier: e.detail.value
      }));
    }}
				  ></dc-input>` : ""}
			${props.serviceCountryCode === "DE" ? html`<dc-input
						class="md:w-1/2"
						.label=${"Tax office ID"}
						.value=${localVatChange.TaxOfficeId}
						.validationMessage=${localVatChangeValidationContext.getValidationMessage("TaxOfficeId")}
						@change=${(e) => {
      localVatChangeValidationContext.validateField("TaxOfficeId");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        TaxOfficeId: e.detail.value
      }));
    }}
				  ></dc-input>` : ""}
			<dc-datepicker
				class="md:w-1/2"
				.label=${"VAT registration date"}
				.value=${localVatChange.LocalVatRegistrationDate}
				.validationMessage=${localVatChangeValidationContext.getValidationMessage("LocalVatRegistrationDate")}
				@change=${(e) => {
      localVatChangeValidationContext.validateField("LocalVatRegistrationDate");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        LocalVatRegistrationDate: e.detail.value
      }));
    }}
			></dc-datepicker>
			${canHavePaymentReference(props.serviceCountryCode) ? html`<dc-input
						class="md:w-1/2"
						.label=${"Payment reference"}
						.value=${localVatChange.PaymentReference}
						.validationMessage=${localVatChangeValidationContext.getValidationMessage("PaymentReference")}
						@change=${(e) => {
      localVatChangeValidationContext.validateField("PaymentReference");
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        PaymentReference: e.detail.value
      }));
    }}
				  ></dc-input>` : ""}
		</div>`;
  };
  const template = () => {
    return html`${showModal ? html`<div>
					<dc-modal
						.visible=${showModal}
						@close=${() => setShowModal(false)}
						.header=${"Add local tax certificate"}
						.content=${html`<div class="grid gap-4 md:grid-cols-2">
							<div>
								<dc-fileupload
									.label=${"Local tax certificate"}
									.fileName=${void 0}
									.fileReference=${localVatChange.LocalVATCertificateReferenceId}
									.validationMessage=${localVatChangeValidationContext.getValidationMessage("LocalVATCertificateReferenceId")}
									.accept=${ONLY_PDF_ACCEPT_LIST}
									@upload=${(e) => __async(void 0, null, function* () {
      var _a;
      if (((_a = e.detail) == null ? void 0 : _a.files) && e.detail.files.length > 0) {
        localVatChangeValidationContext.validateField("LocalVATCertificateReferenceId");
        setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
          LocalVATCertificateReferenceId: yield props.uploadLocalVatCertificate(e.detail.files[0])
        }));
      }
    })}
								></dc-fileupload>
							</div>
							${htmlLocalVatNumberAndRegAndPaymentRef()}
							<div class="space-y-4">
								${htmlPrimaryVatFrequency()} ${htmlSecondaryVatFrequency()} ${htmlVLSFrequency()} ${htmlESLFrequency()}${htmlLocalListingFrequency()} ${htmlIntrastatFrequency()}
								<dc-checkbox
									.checked=${localVatChange.sendEmail}
									.label=${"Send email"}
									}
									@change=${(e) => {
      setLocalVatChange(__spreadProps(__spreadValues({}, localVatChange), {
        sendEmail: e.detail.checked
      }));
    }}
								></dc-checkbox>
							</div>
							${bankAccountForm.template()}
							<div>
								<button class="btn btn-primary ml-4" @click=${() => setShowModal(false)}>Cancel</button>
								<button
									class="btn btn-primary"
									?disabled=${(props == null ? void 0 : props.submissionConfig) === void 0}
									@click=${() => __async(void 0, null, function* () {
      yield save();
    })}
								>
									Save
								</button>
							</div>
						</div>`}
					>
					</dc-modal>
			  </div>` : html``}`;
  };
  return { show, template };
};
