var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { mapBooleanToState } from "GlobalShared/helpers/booleanHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { DEFAULT_SERVICES_MODEL, IOSS_TIER_MAPPING } from "./OrderServiceModels";
import { IossSelectorValidator, IossServiceValidator } from "./OrderServiceValidator";
import { getDay } from "GlobalShared/helpers/dateHelper";
const RADIO_IOSS_TIER_INPUT_NAME = "radio-ioss-tier-input";
const RADIO_IOSS_TIER_INPUT_ID_PREFIX = "radio_ioss_tier_input_";
export const useIossServices = (props) => {
  const radioIOSSTierInputId = (index) => `${RADIO_IOSS_TIER_INPUT_ID_PREFIX}${index}`;
  const getIossFixedEstablishmentValidationMessage = () => {
    return (iossService == null ? void 0 : iossService.hasFixedEuEstablishment) ? i18next.t(
      "We currently do not offer IOSS services to business who is established or has a fixed establishment in the EU via this Portal. Please contact {{- contactEmail }} for further details.",
      { contactEmail: ClientContext.ContactEmailAddress }
    ) : void 0;
  };
  const [iossService, setIossService] = useState({});
  const [iossSelection, setIossSelection] = useState(void 0);
  const [isValidIossSelection, setIsValidIossSelection] = useState(false);
  const [isValidIossService, setIsValidIossService] = useState(false);
  const iossServiceValidationContext = useValidationContext(new IossServiceValidator(iossSelection, props.currentCompanyServices));
  const iossSelectorValidationContext = useValidationContext(new IossSelectorValidator());
  const init = () => {
    if (props.devMode) {
      setIossService(DEFAULT_SERVICES_MODEL.iossService);
    }
  };
  useEffect(init, []);
  useEffect(() => __async(void 0, null, function* () {
    setIsValidIossService(iossServiceValidationContext.validationResult.isValid() && getIossFixedEstablishmentValidationMessage() === void 0);
  }), [iossServiceValidationContext.validationResult]);
  useEffect(() => {
    if (iossService) {
      iossServiceValidationContext.validate(iossService);
    }
  }, [iossService]);
  useEffect(() => __async(void 0, null, function* () {
    setIsValidIossSelection(iossSelectorValidationContext.validationResult.isValid());
  }), [iossSelectorValidationContext.validationResult]);
  useEffect(() => {
    if (iossSelection) {
      iossSelectorValidationContext.validate(iossSelection);
      iossServiceValidationContext.validate(iossService);
    }
  }, [iossSelection]);
  useEffect(() => {
    var _a;
    if (props.currentCompanyServices) {
      setIossService({
        origTier: (_a = props.currentCompanyServices.GlobalServices.IossService) == null ? void 0 : _a.Tier
      });
      setIossSelection(void 0);
    }
  }, [props.currentCompanyServices]);
  const htmlTierIossSelector = () => {
    var _a, _b, _c, _d;
    if (((_b = (_a = props.priceConfig) == null ? void 0 : _a.ioss) == null ? void 0 : _b.tiers) === void 0) {
      return html`<div class="mt-4">${i18next.t("Loading")}...</div>`;
    }
    if ((iossService == null ? void 0 : iossService.origTier) === "Tier0") {
      return "";
    }
    return html`<div>
				<div class="font-bold mt-4">${i18next.t("Select New Tier")}</div>
				<div>${i18next.t("What is the estimated annual shipments which will be covered by IOSS?")}</div>
			</div>
			${(_d = (_c = props.priceConfig) == null ? void 0 : _c.ioss) == null ? void 0 : _d.tiers.filter((t) => (iossService == null ? void 0 : iossService.origTier) === void 0 || t.name !== "Tier0").map((tier, i) => {
      return html`<label for=${radioIOSSTierInputId(i)} class="btn-radio order-services-ioss-selector-container lg:w-2/4 ${(iossSelection == null ? void 0 : iossSelection.tier) === tier.name ? "selected" : ""}">
						<div class="flex items-center">
							<input
								type="radio"
								name=${RADIO_IOSS_TIER_INPUT_NAME}
								id=${radioIOSSTierInputId(i)}
								?checked=${(iossSelection == null ? void 0 : iossSelection.tier) === tier.name}
								class="cursor-pointer"
								@click=${(_e) => {
        if ((iossSelection == null ? void 0 : iossSelection.tier) === tier.name) {
          const elem = document.getElementById(`${radioIOSSTierInputId(i)}`);
          if (elem) {
            elem.checked = false;
          }
        }
        setIossSelection(__spreadProps(__spreadValues({}, iossSelection), {
          tier: (iossSelection == null ? void 0 : iossSelection.tier) === tier.name ? void 0 : tier.name
        }));
      }}
							/>
							<div class="ml-2">${IOSS_TIER_MAPPING[tier.name]}</div>
						</div>
					</label>`;
    })}
			<div class="mt-4 italic">
				${i18next.t(
      "The price shown is based on the volume information provided by you. We reserve the right to change your tier and charge the difference based on your actual shipments. If you do not pay the additional amount on time, your contract may be terminated. "
    )}
			</div>`;
  };
  const htmlActiveTier = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
    if (((_c = (_b = (_a = props.currentCompanyServices) == null ? void 0 : _a.GlobalServices) == null ? void 0 : _b.IossService) == null ? void 0 : _c.ComplianceValidTo) && getDay((_f = (_e = (_d = props.currentCompanyServices) == null ? void 0 : _d.GlobalServices) == null ? void 0 : _e.IossService) == null ? void 0 : _f.ComplianceValidTo) >= getDay(/* @__PURE__ */ new Date()) && ((_i = (_h = (_g = props.currentCompanyServices) == null ? void 0 : _g.GlobalServices) == null ? void 0 : _h.IossService) == null ? void 0 : _i.Tier)) {
      return html`<div class="flex space-x-2 items-center">
				<div>${i18next.t("Active tier: ")}</div>
				<div class="font-semibold">${IOSS_TIER_MAPPING[(_l = (_k = (_j = props.currentCompanyServices) == null ? void 0 : _j.GlobalServices) == null ? void 0 : _k.IossService) == null ? void 0 : _l.Tier]}</div>
			</div>`;
    } else {
      return "";
    }
  };
  const htmlIossSelector = () => {
    return html`<div class="space-y-4 mt-4">${htmlActiveTier()} ${htmlTierIossSelector()}</div>`;
  };
  const htmlIossDetails = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
    return html`<div class="bg-gray-100 rounded-xl p-2">
			<div class="ml-4">
				<div class="mt-2">${htmlActiveTier()}</div>
				${(iossSelection == null ? void 0 : iossSelection.tier) === void 0 && ((_b = (_a = props.priceConfig) == null ? void 0 : _a.ioss) == null ? void 0 : _b.deregistration) && ((_e = (_d = (_c = props.currentCompanyServices) == null ? void 0 : _c.GlobalServices) == null ? void 0 : _d.IossService) == null ? void 0 : _e.RegistrationStatus) === "RegistrationCompleted" ? html`<div class="lg:flex lg:justify-between">
							<div
								class="btn-toggle mt-6 mb-1 xl:w-1/2 ${(iossService == null ? void 0 : iossService.deregistration) ? "selected" : ""}"
								@click=${() => {
      setIossService(__spreadProps(__spreadValues({}, iossService), {
        deregistration: !(iossService == null ? void 0 : iossService.deregistration)
      }));
    }}
							>
								<div class="flex justify-between">
									<div class="mr-2">${i18next.t("Deregistration")}</div>
								</div>
							</div>
					  </div>` : ""}
				${(iossSelection == null ? void 0 : iossSelection.tier) !== void 0 && ((_h = (_g = (_f = props.currentCompanyServices) == null ? void 0 : _f.GlobalServices) == null ? void 0 : _g.IossService) == null ? void 0 : _h.Tier) === void 0 && (((_j = (_i = props == null ? void 0 : props.priceConfig) == null ? void 0 : _i.ioss) == null ? void 0 : _j.newReg) !== void 0 || ((_l = (_k = props == null ? void 0 : props.priceConfig) == null ? void 0 : _k.ioss) == null ? void 0 : _l.takeOver) !== void 0) ? html`<div>
							<div class="mt-4">
								<div class="form-label">${i18next.t("Do you have current or previous IOSS number?")}</div>
								<dc-toggle
									.state=${mapBooleanToState(iossService == null ? void 0 : iossService.hasIossNumber)}
									.validationMessage=${iossServiceValidationContext.getValidationMessage("hasIossNumber")}
									@change=${(e) => {
      setIossService(__spreadProps(__spreadValues({}, iossService), { hasIossNumber: e.detail.state === "Yes" }));
    }}
								></dc-toggle>
							</div>
							<div class="mt-4 mb-4">
								<div class="relative">
									<div class="form-label">${i18next.t("Do you have any fixed establishment in the EU?")}</div>
									<dc-toggle
										.state=${mapBooleanToState(iossService == null ? void 0 : iossService.hasFixedEuEstablishment)}
										.validationMessage=${iossServiceValidationContext.getValidationMessage("hasFixedEuEstablishment")}
										@change=${(e) => {
      setIossService(__spreadProps(__spreadValues({}, iossService), {
        hasFixedEuEstablishment: e.detail.state === "Yes"
      }));
    }}
									></dc-toggle>
								</div>
								<div class="text-orange-600 my-4 h-8 ${getIossFixedEstablishmentValidationMessage() === void 0 ? "invisible" : ""}">
									${getIossFixedEstablishmentValidationMessage()}
								</div>
							</div>
					  </div>` : ""}
			</div>
		</div>`;
  };
  return {
    htmlIossSelector,
    htmlIossDetails,
    iossSelection,
    iossService,
    isValidIossSelection,
    isValidIossService
  };
};
