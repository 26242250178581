var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import { circleCheckedIcon, fileIcon, templateTitle, triangleExclamationIcon } from "GlobalShared/templates/commons";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import i18next from "i18next";
import { html } from "lit-html";
import { getRouteByKey } from "../globals";
import { SubmissionPeriods as SubmissionPeriodsClient } from "WebServices/APIs/self/clients";
import { BatchUploadResultErrorReason } from "WebServices/APIs/self/models/V1/Submission/BatchUploadResult";
import { SESSION_KEY_FOR_SUBMISSION_ONLY_SUBMISSION_PERIOD_IDS } from "GlobalShared/components/return-library/ReturnLibraryHelper";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
const FILE_NAME_CONVENTION = "Returntype_Country_Frequency_Period_CompanyID_CompanyName_Version";
const EXAMPLE_FILE_NAME = "ESL_DE_Q_202401_146_Test Company Name_v1";
const COMPANY_ID_INDEX = 4;
const RETURN_TYPES = ["VAT", "ESL", "VLS", "LOC", "INT"];
const COUNTRY_EXAMPLE = "ISO-2 Country (e.g.: DE, HU)";
const FREQUENCIES = ["M", "Q", "QS1", "QS2", "BM", "FM", "SM", "Y"];
const PERIOD_EXAMPLE = "yyyyMM (YearMonth)";
const REDIRECT_WITH_SET_COMPANY = "redirect-with-set-company";
const RETURN_LIBRARY_HOME = "admin/return-library";
export function useBatchReturnUploadModal() {
  const handleFileUpload = (files) => __async(this, null, function* () {
    const filesArray = Array.from(files);
    withErrorHandling(
      () => __async(this, null, function* () {
        dispatchLoading("inc");
        const uploadedDocuments = yield submissionPeriodsClient.uploadReturnDocuments({ files: filesArray });
        setBatchUploadResult(uploadedDocuments.data);
      }),
      (error) => notifyError(error),
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const mapBatchUploadErrorReason = (reason) => {
    switch (reason) {
      case BatchUploadResultErrorReason.Duplication:
        return "Duplicated name";
      case BatchUploadResultErrorReason.IncorrectNaming:
        return "Incorrect naming";
      case BatchUploadResultErrorReason.IncorrectStatus:
        return "Incorrect Status";
      case BatchUploadResultErrorReason.SubmissionPeriodMissing:
        return "Submission period missing";
      case BatchUploadResultErrorReason.SubmissionPeriodIsNotSubmissionOnly:
        return "Submission period is not submission only";
      case BatchUploadResultErrorReason.Cancelled:
        return "Cancelled";
      default:
        return "Unknown";
    }
  };
  const getCompanyIdFromFileName = (fileName) => {
    var _a;
    const companyId = (_a = fileName.split("_")) == null ? void 0 : _a[COMPANY_ID_INDEX];
    return companyId;
  };
  const onOpenReturnLibraryClick = (e, submissionPeriodId, fileName) => __async(this, null, function* () {
    e.preventDefault();
    e.stopPropagation();
    let allErrorSubmissionPeriodIds;
    if (!submissionPeriodId) {
      allErrorSubmissionPeriodIds = batchUploadResult.filter((r) => r.SubmissionPeriodId && !r.IsSuccess).map((r) => r.SubmissionPeriodId);
    }
    localStorage.setItem(SESSION_KEY_FOR_SUBMISSION_ONLY_SUBMISSION_PERIOD_IDS, JSON.stringify(submissionPeriodId ? [submissionPeriodId] : allErrorSubmissionPeriodIds));
    const returnLibraryHomePath = getRouteByKey(RETURN_LIBRARY_HOME);
    const companyId = getCompanyIdFromFileName(fileName);
    const newPath = window.location.pathname.split("/admin")[0];
    const changeCompanyLink = `${newPath}/${REDIRECT_WITH_SET_COMPANY}?companyId=${companyId}&redirect=${returnLibraryHomePath}`;
    window.open(changeCompanyLink, "_blank");
  });
  const [isOpen, setIsOpen] = useState(false);
  const [loading, dispatchLoading] = useLoadingReducer();
  const [batchUploadResult, setBatchUploadResult] = useState([]);
  const submissionPeriodsClient = useClient(SubmissionPeriodsClient);
  const uploadedFileTemplate = (file) => html`
		<div class="flex justify-between gap-2 items-start mb-3 w-full">
			<div class="flex flex-col w-full">
				<div class="flex items-end gap-3">
					<div>${fileIcon(file == null ? void 0 : file.FileName)}</div>
					<div class="text-sm truncate">${file == null ? void 0 : file.FileName}</div>

					${(file == null ? void 0 : file.IsSuccess) ? circleCheckedIcon() : triangleExclamationIcon()}
				</div>
				<div>
					${!(file == null ? void 0 : file.IsSuccess) ? html`
								<div class="flex flex-col sm:flex-row sm:gap-4">
									<div class="text-sm text-red-500 ml-9">${`File upload failed - ${mapBatchUploadErrorReason(file == null ? void 0 : file.ErrorReason)}`}</div>
									${(file == null ? void 0 : file.SubmissionPeriodId) ? html`
												<a
													class="text-sm text-red-500 ml-9 sm:ml-0 cursor-pointer hover:underline"
													@click=${(e) => onOpenReturnLibraryClick(e, file == null ? void 0 : file.SubmissionPeriodId, file == null ? void 0 : file.FileName)}
												>
													Open return library
												</a>
										  ` : ""}
								</div>
						  ` : ""}
				</div>
			</div>
		</div>
	`;
  const dropzoneSelectTemplate = () => html`
		<dc-dropzone-select
			class="h-40 rounded-xl bg-gray-100 block mb-3 hover:border-2 hover:border-dashed hover:border-gray-500"
			.uploadButtonLabel=${i18next.t("Select Files")}
			.note=${i18next.t("You can upload multiple files at once")}
			.label=${i18next.t("Drop return files here to upload")}
			.dragOverText=${i18next.t("Drop return files here to upload")}
			.onFileUpload=${(files) => handleFileUpload(files)}
			.multiple=${true}
			.accept=${[".pdf", ".csv", ".xml", ".txt", ".json", ".xlsx"]}
		></dc-dropzone-select>
	`;
  const modalContentTemplate = () => html`
		<div class="flex flex-col px-8 py-4 gap-3">
			${templateTitle(i18next.t("Upload Returns"))}
			<div>${i18next.t("File name convention")}: <span class="break-words">${FILE_NAME_CONVENTION}</span></div>
			<div>${i18next.t("Sample file name")}: <span class="break-words">${EXAMPLE_FILE_NAME}</span></div>
			<div>${i18next.t("Possible values")}:</div>
			<ul class="list-disc ml-8">
				<li><span class="font-semibold">${i18next.t("Return Types")}</span>: ${RETURN_TYPES.join(", ")}</li>
				<li><span class="font-semibold">${i18next.t("Country")}</span>: ${COUNTRY_EXAMPLE}</li>
				<li><span class="font-semibold">${i18next.t("Frequency")}</span>: ${FREQUENCIES.join(", ")}</li>
				<li><span class="font-semibold">${i18next.t("Period")}</span>: ${PERIOD_EXAMPLE}</li>
				<li><span class="font-semibold">${i18next.t("Company ID")}</span>: ${i18next.t("See Services Page")}</li>
			</ul>
			${dropzoneSelectTemplate()} ${batchUploadResult.map(uploadedFileTemplate)}
			${SecondaryButton({
    class: "max-w-fit mt-8",
    text: i18next.t("Cancel"),
    onClick: handleModalClose
  })}
		</div>
	`;
  const handleModalClose = () => {
    if (localStorage.getItem(SESSION_KEY_FOR_SUBMISSION_ONLY_SUBMISSION_PERIOD_IDS)) {
      localStorage.removeItem(SESSION_KEY_FOR_SUBMISSION_ONLY_SUBMISSION_PERIOD_IDS);
    }
    setIsOpen(false);
    setBatchUploadResult([]);
  };
  const modalTemplate = () => html`
			<dc-modal .customClass=${"max-w-[95%] w-[900px] top-[10%]"} .visible=${isOpen} @close=${handleModalClose} .header=${"Return Batch Upload"} .content=${modalContentTemplate()}> </dc-modal>
			${loading.count > 0 ? html` <dc-loader></dc-loader>` : ""}
		`;
  return { modalTemplate, setIsOpen };
}
