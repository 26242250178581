var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class PossibleMessageStatusChange {
  constructor(_data) {
    /**
     */
    __publicField(this, "IsBackwardChange");
    /**
     */
    __publicField(this, "IsEnabled");
    /**
     */
    __publicField(this, "NewStatus");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PossibleMessageStatusChange
     */
    __publicField(this, "$type", "V1.Cesop.Periods.PossibleMessageStatusChange");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.IsBackwardChange = _data["IsBackwardChange"];
      this.IsEnabled = _data["IsEnabled"];
      this.NewStatus = PossibleMessageStatusChangeNewStatus[_data["NewStatus"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["IsBackwardChange"] = this.IsBackwardChange;
    _data["IsEnabled"] = this.IsEnabled;
    _data["NewStatus"] = PossibleMessageStatusChangeNewStatus[this.NewStatus];
    return _data;
  }
}
export var PossibleMessageStatusChangeNewStatus = /* @__PURE__ */ ((PossibleMessageStatusChangeNewStatus2) => {
  PossibleMessageStatusChangeNewStatus2["New"] = "New";
  PossibleMessageStatusChangeNewStatus2["Sending"] = "Sending";
  PossibleMessageStatusChangeNewStatus2["SentToTa"] = "SentToTa";
  PossibleMessageStatusChangeNewStatus2["FullyRejected"] = "FullyRejected";
  PossibleMessageStatusChangeNewStatus2["PartiallyRejected"] = "PartiallyRejected";
  PossibleMessageStatusChangeNewStatus2["AcceptedByCesop"] = "AcceptedByCesop";
  PossibleMessageStatusChangeNewStatus2["TechnicalError"] = "TechnicalError";
  return PossibleMessageStatusChangeNewStatus2;
})(PossibleMessageStatusChangeNewStatus || {});
