import "./dc-step-progressbar.scss";
import { html } from "lit-html";
import { styleMap } from "lit-html/directives/style-map.js";
import { classMap } from "lit-html/directives/class-map";
import { component } from "haunted";
import { circleCheckedIcon, triangleExclamationIcon } from "GlobalShared/templates/commons";
export class ClickEvent extends CustomEvent {
  constructor(detail) {
    super("click", { detail });
  }
}
export const Component = (host) => {
  var _a, _b;
  const props = {
    items: host.items,
    activeIndex: host.activeIndex,
    inactiveClickable: (_a = host.inactiveClickable) != null ? _a : false,
    showValidationIcons: (_b = host.showValidationIcons) != null ? _b : false
  };
  const onClick = (e, step) => {
    e.stopPropagation();
    if (props.inactiveClickable) {
      host.dispatchEvent(
        new ClickEvent({
          step
        })
      );
    }
  };
  const validationResultIconTemplate = (isValid) => isValid ? circleCheckedIcon() : triangleExclamationIcon(void 0, void 0, "fill-orange-400");
  return html`
		<div>
			<ul>
				${(props.items || []).map(
    (item, i) => html`
						<li
							style=${styleMap({ width: `${100 / props.items.length}%` })}
							class=${classMap({
      "active": i < props.activeIndex,
      "current": i === props.activeIndex,
      "cursor-pointer": props.inactiveClickable,
      "pointer-events-none": !props.inactiveClickable
    })}
							@click=${(e) => onClick(e, i)}
						>
							${typeof item !== "string" && props.showValidationIcons && item.validity !== "dont-know" ? validationResultIconTemplate(item.validity === "valid") : ""}
							<span class="hidden sm:block capitalize">${typeof item === "string" ? item : item.stepName}</span>
						</li>
					`
  )}
			</ul>
		</div>
	`;
};
customElements.define(
  "dc-step-progressbar",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
