var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./dc-table.scss";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { repeat } from "lit-html/directives/repeat";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { createRange } from "GlobalShared/helpers/collectionHelper";
import { component } from "haunted";
export const getFilterByFieldName = (appliedFilters, fieldName) => {
  const filters = appliedFilters.filter((filter) => filter.field === fieldName);
  if (filters.length > 0) {
    return filters[0];
  } else {
    return void 0;
  }
};
export const mergeAppliedFilter = (appliedFilters, newFilter) => {
  let newFilters = [];
  const isDateFilterOn = newFilter.filterProps.max instanceof Date || newFilter.filterProps.min instanceof Date;
  const isNumberFilterOn = !isNaN(newFilter.filterProps.max) || !isNaN(newFilter.filterProps.min);
  const hasSelectedValues = Array.isArray(newFilter.filterProps.selectedValues) && newFilter.filterProps.selectedValues.length > 0;
  const hasSelectedValue = typeof newFilter.filterProps.selectedValues === "string" && !isEmpty(newFilter.filterProps.selectedValues);
  if (isDateFilterOn || hasSelectedValues || hasSelectedValue || isNumberFilterOn) {
    let found = false;
    newFilters = appliedFilters.map((filter) => {
      if (filter.field === newFilter.field) {
        found = true;
        return newFilter;
      } else {
        return filter;
      }
    });
    if (!found) {
      newFilters.push(newFilter);
    }
  } else {
    newFilters = appliedFilters.filter((filter) => filter.field !== newFilter.field);
  }
  return newFilters;
};
const DEFAULTS = {
  vm: {
    columns: [],
    data: [],
    paging: {
      pageable: true,
      pageSize: 25,
      pageIndex: 0,
      buttonCount: 10,
      pageSizes: [25, 50, 100],
      showInfo: true,
      showPreviousNext: true
    },
    sorting: {
      orderBy: void 0,
      orderDir: void 0
    },
    selection: {
      selectable: false
    },
    rowCustomization: [],
    appliedFilters: []
  }
};
export class SortChangeEvent extends CustomEvent {
  constructor(detail) {
    super("onSortChange", { detail });
  }
}
export class FilterChangeEvent extends CustomEvent {
  constructor(detail) {
    super("onFilterChange", { detail });
  }
}
export class RowsSelectedEvent extends CustomEvent {
  constructor(detail) {
    super("onRowsSelect", { detail });
  }
}
export class PageChangeEvent extends CustomEvent {
  constructor(detail) {
    super("onPageChange", { detail });
  }
}
const addIsSelectedPropertyToData = (data) => {
  return data.map((row) => {
    return __spreadProps(__spreadValues({}, row), { isSelected: false });
  });
};
export const Component = (host) => {
  const props = {
    vm: host.vm !== void 0 ? {
      columns: host.vm.columns !== void 0 ? host.vm.columns.map((c) => {
        if (c.filterable === void 0) {
          c.filterable = c.filterDescriptor !== void 0;
        }
        return c;
      }) : DEFAULTS.vm.columns,
      cellTemplate: host.vm.cellTemplate ? host.vm.cellTemplate : (index, field) => {
        return html`<span title=${props.vm.data[index][field]}>${props.vm.data[index][field]}</span>`;
      },
      cellClass: host.vm.cellClass ? host.vm.cellClass : (_index, _field) => {
        return "";
      },
      bodyTemplate: host.vm.bodyTemplate,
      rowDetailsTemplate: host.vm.rowDetailsTemplate,
      data: host.vm.data !== void 0 ? addIsSelectedPropertyToData(host.vm.data) : DEFAULTS.vm.data,
      paging: host.vm.paging !== void 0 ? {
        pageable: host.vm.paging.pageable !== void 0 ? host.vm.paging.pageable : DEFAULTS.vm.paging.pageable,
        pageSize: host.vm.paging.pageSize !== void 0 ? host.vm.paging.pageSize : DEFAULTS.vm.paging.pageSize,
        itemCount: host.vm.paging.itemCount !== void 0 ? host.vm.paging.itemCount : DEFAULTS.vm.paging.itemCount,
        pageSizes: host.vm.paging.pageSizes !== void 0 ? host.vm.paging.pageSizes : DEFAULTS.vm.paging.pageSizes,
        pageIndex: host.vm.paging.pageIndex !== void 0 ? host.vm.paging.pageIndex : DEFAULTS.vm.paging.pageIndex,
        buttonCount: host.vm.paging.buttonCount !== void 0 ? host.vm.paging.buttonCount : DEFAULTS.vm.paging.buttonCount,
        showInfo: host.vm.paging.showInfo !== void 0 ? host.vm.paging.showInfo : DEFAULTS.vm.paging.showInfo,
        showPreviousNext: host.vm.paging.showPreviousNext !== void 0 ? host.vm.paging.showPreviousNext : DEFAULTS.vm.paging.showPreviousNext
      } : DEFAULTS.vm.paging,
      sorting: host.vm.sorting ? host.vm.sorting : DEFAULTS.vm.sorting,
      selection: host.vm.selection !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.vm.selection), host.vm.selection) : DEFAULTS.vm.selection,
      rowCustomization: host.vm.rowCustomization !== void 0 ? host.vm.rowCustomization : DEFAULTS.vm.rowCustomization,
      appliedFilters: host.vm.appliedFilters !== void 0 ? host.vm.appliedFilters : DEFAULTS.vm.appliedFilters
    } : DEFAULTS.vm
  };
  const resetSelection = () => {
    setSelectedIndices([]);
  };
  const formatColumnClass = (currentColumnClass) => {
    let concreteColumnClasses = {};
    if (currentColumnClass) {
      concreteColumnClasses = (typeof currentColumnClass === "string" ? [currentColumnClass] : currentColumnClass).reduce((acc, cur) => {
        cur.split(" ").forEach((curItem) => {
          acc[curItem] = true;
        });
        return acc;
      }, concreteColumnClasses);
    }
    return concreteColumnClasses;
  };
  const getAppliedFilter = (fieldName) => {
    return props.vm.appliedFilters.find((column) => column.field === fieldName);
  };
  const isFilterAppliedOnColumn = (fieldName) => {
    const columnFilter = getAppliedFilter(fieldName);
    return columnFilter !== void 0 && columnFilter.filterProps !== void 0;
  };
  const onPageChanged = (e) => __async(void 0, null, function* () {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setSelectedPageIndex(pageIndex);
    setSelectedPageSize(pageSize);
    resetSelection();
    host.dispatchEvent(
      new PageChangeEvent({
        selectedPageIndex: pageIndex,
        selectedPageSize: pageSize
      })
    );
  });
  const onSortClicked = (field, sortOrder) => {
    setOrderDir(sortOrder);
    setOrderBy(field);
    resetSelection();
    host.dispatchEvent(
      new SortChangeEvent({
        orderBy: field,
        orderDir: sortOrder
      })
    );
  };
  const onFilterChanged = (field, filterProps) => {
    resetSelection();
    host.dispatchEvent(
      new FilterChangeEvent({
        field,
        filterProps
      })
    );
  };
  const onRowSelected = (rowIndex, e) => {
    let newIndices = [...selectedIndices];
    if (e.detail.checked) {
      if (!selectedIndices.includes(rowIndex)) {
        newIndices.push(rowIndex);
        newIndices.sort();
      }
    } else {
      newIndices = selectedIndices.filter((index) => index !== rowIndex);
    }
    setSelectedIndices(newIndices);
    host.dispatchEvent(
      new RowsSelectedEvent({
        indices: newIndices
      })
    );
  };
  const allRowsChanged = () => {
    const newIndices = selectedIndices.length === items.length ? [] : items.map((_, i) => i);
    setSelectedIndices(newIndices);
    host.dispatchEvent(
      new RowsSelectedEvent({
        indices: newIndices
      })
    );
  };
  useEffect(() => {
    setSelectedPageIndex(props.vm.paging.pageIndex);
  }, [props.vm.paging.pageIndex]);
  useEffect(() => {
    setSelectedPageSize(props.vm.paging.pageSize);
  }, [props.vm.paging.pageSize]);
  useEffect(() => {
    setOrderBy(props.vm.sorting.orderBy);
  }, [props.vm.sorting.orderBy]);
  useEffect(() => {
    setOrderDir(props.vm.sorting.orderDir);
  }, [props.vm.sorting.orderDir]);
  useEffect(() => {
    if (props.vm.selection.selectedIndices !== void 0) {
      const onlyValidIndices = props.vm.selection.selectedIndices.reduce((aggr, curr) => {
        if (curr >= 0 && curr < items.length && !aggr.includes(curr)) {
          return aggr.concat(curr);
        } else {
          return aggr;
        }
      }, []);
      onlyValidIndices.sort();
      setSelectedIndices(onlyValidIndices);
    }
  }, [props.vm.selection.selectedIndices]);
  useEffect(() => {
    setItems(props.vm.data);
  }, []);
  const [selectedPageIndex, setSelectedPageIndex] = useState(DEFAULTS.vm.paging.pageIndex);
  const [selectedPageSize, setSelectedPageSize] = useState(DEFAULTS.vm.paging.pageSize);
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("asc");
  const [items, setItems] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const getHeaderTemplate = () => {
    return html`
			<tr>
				${props.vm.selection.selectable && items.length > 0 ? html`
							<th class="w-10 overflow-hidden">
								<dc-checkbox2 .checked=${selectedIndices.length === items.length} @change=${allRowsChanged}></dc-checkbox2>
							</th>
					  ` : ""}
				${repeat(
      props.vm.columns,
      (column) => column.field,
      (column) => html`
						<th
							class="${classMap(__spreadValues({
        active: isFilterAppliedOnColumn(column.field)
      }, formatColumnClass(column.columnClass)))}"
						>
							<dc-table-header
								.fieldName=${column.field}
								.sortable=${column.sortable}
								.filterable=${column.filterable}
								.columnType=${column.columnType}
								.filterDescriptor=${column.filterDescriptor}
								.label=${column.label}
								.columnTitle=${column.columnTitle}
								.orderBy=${orderBy}
								.orderDir=${orderDir}
								.columnClass=${column.columnClass}
								.onSortClicked=${onSortClicked}
								.onFilterChanged=${onFilterChanged}
								.appliedFilter=${getAppliedFilter(column.field)}
							></dc-table-header>
						</th>
					`
    )}
			</tr>
		`;
  };
  const getBodyTemplate = () => {
    return html`
			${props.vm.bodyTemplate ? props.vm.bodyTemplate() : props.vm.data.length !== void 0 ? createRange(0, props.vm.data.length - 1).map((i) => {
      return html`
							<tr
								class="${props.vm.rowCustomization.map((row) => {
        return row.index === i ? row.classes.join(" ") : "";
      })}"
							>
								${props.vm.selection.selectable && items.length > 0 ? html`
											<td class="w-10 overflow-hidden">
												<dc-checkbox2
													.checked=${selectedIndices.includes(i)}
													@change=${(e) => {
        onRowSelected(i, e);
      }}
												></dc-checkbox2>
											</td>
									  ` : ""}
								${props.vm.columns.map((column) => {
        return html` <td class="${props.vm.cellClass(i, column.field)}">${props.vm.cellTemplate(i, column.field)}</td> `;
      })}
							</tr>
							${props.vm.rowDetailsTemplate ? props.vm.rowDetailsTemplate(i) : ""}
						`;
    }) : ""}
		`;
  };
  return html`
		<table class="dc-table">
			<thead>
				${getHeaderTemplate()}
			</thead>
			<tbody>
				${getBodyTemplate()}
			</tbody>
		</table>
		${props.vm.paging.pageable ? html`<div class="mt-2">
					<dc-pager
						.pageIndex=${selectedPageIndex}
						.pageSize=${selectedPageSize}
						.pageButtonCount=${props.vm.paging.buttonCount}
						.pageSizes=${props.vm.paging.pageSizes}
						.itemCount=${props.vm.paging.itemCount}
						@change=${onPageChanged}
					></dc-pager>
			  </div> ` : ""}
	`;
};
customElements.define(
  "dc-table",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
