import { DataProcessingJob } from "./DataProcessingJob";
import { AmazonDataProcessingJob } from "./AmazonDataProcessingJob";
import { AmazonSaftDataProcessingJob } from "./AmazonSaftDataProcessingJob";
import { CustomSaftDataProcessingJob } from "./CustomSaftDataProcessingJob";
import { CustomSourceDataProcessingJob } from "./CustomSourceDataProcessingJob";
import { IossDataProcessingJob } from "./IossDataProcessingJob";
import { SapExcelDataProcessingJob } from "./SapExcelDataProcessingJob";
import { SapTextDataProcessingJob } from "./SapTextDataProcessingJob";
import { VatDataProcessingJob } from "./VatDataProcessingJob";
export class DataProcessingJobFactory {
  static create($type) {
    if ($type === "V1.DataProcessingJobs.DataProcessingJob") {
      const result = new DataProcessingJob();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.AmazonDataProcessingJob") {
      const result = new AmazonDataProcessingJob();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.AmazonSaftDataProcessingJob") {
      const result = new AmazonSaftDataProcessingJob();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.CustomSaftDataProcessingJob") {
      const result = new CustomSaftDataProcessingJob();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.CustomSourceDataProcessingJob") {
      const result = new CustomSourceDataProcessingJob();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.IossDataProcessingJob") {
      const result = new IossDataProcessingJob();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.SapExcelDataProcessingJob") {
      const result = new SapExcelDataProcessingJob();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.SapTextDataProcessingJob") {
      const result = new SapTextDataProcessingJob();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.VatDataProcessingJob") {
      const result = new VatDataProcessingJob();
      return result;
    }
    throw new Error($type + " not found");
  }
}
