import { Subscription } from "./Subscription";
import { FilingAgentSubscription } from "./FilingAgentSubscription";
import { FiscalRepresentativeSubscription } from "./FiscalRepresentativeSubscription";
import { IntrastatInSubscription } from "./IntrastatInSubscription";
import { IntrastatOutSubscription } from "./IntrastatOutSubscription";
import { IossComplianceSubscription } from "./IossComplianceSubscription";
import { OssComplianceSubscription } from "./OssComplianceSubscription";
import { RetrospectiveReturnSubscription } from "./RetrospectiveReturnSubscription";
import { VatComplianceSubscription } from "./VatComplianceSubscription";
export class SubscriptionFactory {
  static create($type) {
    if ($type === "V1.CompanyInfo.Subscriptions.Subscription") {
      const result = new Subscription();
      return result;
    }
    if ($type === "V1.CompanyInfo.Subscriptions.FilingAgentSubscription") {
      const result = new FilingAgentSubscription();
      return result;
    }
    if ($type === "V1.CompanyInfo.Subscriptions.FiscalRepresentativeSubscription") {
      const result = new FiscalRepresentativeSubscription();
      return result;
    }
    if ($type === "V1.CompanyInfo.Subscriptions.IntrastatInSubscription") {
      const result = new IntrastatInSubscription();
      return result;
    }
    if ($type === "V1.CompanyInfo.Subscriptions.IntrastatOutSubscription") {
      const result = new IntrastatOutSubscription();
      return result;
    }
    if ($type === "V1.CompanyInfo.Subscriptions.IossComplianceSubscription") {
      const result = new IossComplianceSubscription();
      return result;
    }
    if ($type === "V1.CompanyInfo.Subscriptions.OssComplianceSubscription") {
      const result = new OssComplianceSubscription();
      return result;
    }
    if ($type === "V1.CompanyInfo.Subscriptions.RetrospectiveReturnSubscription") {
      const result = new RetrospectiveReturnSubscription();
      return result;
    }
    if ($type === "V1.CompanyInfo.Subscriptions.VatComplianceSubscription") {
      const result = new VatComplianceSubscription();
      return result;
    }
    throw new Error($type + " not found");
  }
}
