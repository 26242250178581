var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class Agent {
  constructor(_data) {
    /**
     */
    __publicField(this, "AssignedCountries");
    /**
     */
    __publicField(this, "AssignedPsps");
    /**
     */
    __publicField(this, "Email");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "IsTwoFactorEnabled");
    /**
     */
    __publicField(this, "Roles");
    /**
     */
    __publicField(this, "Status");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Agent
     */
    __publicField(this, "$type", "V1.Agents.Agent");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AssignedCountries"] && _data["AssignedCountries"].constructor === Array) {
        this.AssignedCountries = [];
        for (let item of _data["AssignedCountries"]) {
          this.AssignedCountries.push(item);
        }
      }
      if (_data["AssignedPsps"] && _data["AssignedPsps"].constructor === Array) {
        this.AssignedPsps = [];
        for (let item of _data["AssignedPsps"]) {
          this.AssignedPsps.push(item);
        }
      }
      this.Email = _data["Email"];
      this.Id = _data["Id"];
      this.IsTwoFactorEnabled = _data["IsTwoFactorEnabled"];
      if (_data["Roles"] && _data["Roles"].constructor === Array) {
        this.Roles = [];
        for (let item of _data["Roles"]) {
          this.Roles.push(item);
        }
      }
      this.Status = AgentStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.AssignedCountries && this.AssignedCountries.constructor === Array) {
      _data["AssignedCountries"] = [];
      for (let item of this.AssignedCountries) {
        _data["AssignedCountries"].push(item);
      }
    }
    if (this.AssignedPsps && this.AssignedPsps.constructor === Array) {
      _data["AssignedPsps"] = [];
      for (let item of this.AssignedPsps) {
        _data["AssignedPsps"].push(item);
      }
    }
    _data["Email"] = this.Email;
    _data["Id"] = this.Id;
    _data["IsTwoFactorEnabled"] = this.IsTwoFactorEnabled;
    if (this.Roles && this.Roles.constructor === Array) {
      _data["Roles"] = [];
      for (let item of this.Roles) {
        _data["Roles"].push(item);
      }
    }
    _data["Status"] = AgentStatus[this.Status];
    return _data;
  }
}
export var AgentStatus = /* @__PURE__ */ ((AgentStatus2) => {
  AgentStatus2["Enabled"] = "Enabled";
  AgentStatus2["Disabled"] = "Disabled";
  return AgentStatus2;
})(AgentStatus || {});
