var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Money } from "./../../Common/Money";
import { DateConverter } from "./../../../../converters/DateConverter";
import { PaymentFactory } from "./PaymentFactory";
import { ServiceFactory } from "./../Services/ServiceFactory";
import { BillingDocument } from "./BillingDocument";
export class Order {
  constructor(_data) {
    /**
     */
    __publicField(this, "BillingDocuments");
    /**
     */
    __publicField(this, "BusinessType");
    /**
     */
    __publicField(this, "CancelledAt");
    /**
     */
    __publicField(this, "ClientSource");
    /**
     */
    __publicField(this, "CompanyId");
    /**
     */
    __publicField(this, "CompanyName");
    /**
     */
    __publicField(this, "ConfirmedAt");
    /**
     */
    __publicField(this, "ContractId");
    /**
     */
    __publicField(this, "CreatedAt");
    /**
     */
    __publicField(this, "DraftServices");
    /**
     */
    __publicField(this, "HasContractReference");
    /**
     */
    __publicField(this, "HasProcessingFailure");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "NeedToGenerateInvoice");
    /**
     */
    __publicField(this, "OrderPaymentStatus");
    /**
     */
    __publicField(this, "Payments");
    /**
     */
    __publicField(this, "Services");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "SubTotal");
    /**
     */
    __publicField(this, "Total");
    /**
     */
    __publicField(this, "Vat");
    /**
     */
    __publicField(this, "Version");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Order
     */
    __publicField(this, "$type", "V1.Orders.Get.Order");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["BillingDocuments"] && _data["BillingDocuments"].constructor === Array) {
        this.BillingDocuments = [];
        for (let item of _data["BillingDocuments"]) {
          const billingDocuments_ = new BillingDocument();
          this.BillingDocuments.push(billingDocuments_.init(item));
        }
      }
      this.BusinessType = OrderBusinessType[_data["BusinessType"]];
      this.CancelledAt = DateConverter.from(_data["CancelledAt"]);
      this.ClientSource = OrderClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.CompanyName = _data["CompanyName"];
      this.ConfirmedAt = DateConverter.from(_data["ConfirmedAt"]);
      this.ContractId = _data["ContractId"];
      this.CreatedAt = DateConverter.from(_data["CreatedAt"]);
      if (_data["DraftServices"] && _data["DraftServices"].constructor === Array) {
        this.DraftServices = [];
        for (let item of _data["DraftServices"]) {
          const $typeDraftServices = item && item.$type;
          if ($typeDraftServices) {
            const draftServices_ = ServiceFactory.create($typeDraftServices);
            this.DraftServices.push(draftServices_.init(item));
          }
        }
      }
      this.HasContractReference = _data["HasContractReference"];
      this.HasProcessingFailure = _data["HasProcessingFailure"];
      this.Id = _data["Id"];
      this.NeedToGenerateInvoice = _data["NeedToGenerateInvoice"];
      this.OrderPaymentStatus = OrderOrderPaymentStatus[_data["OrderPaymentStatus"]];
      if (_data["Payments"] && _data["Payments"].constructor === Array) {
        this.Payments = [];
        for (let item of _data["Payments"]) {
          const $typePayments = item && item.$type;
          if ($typePayments) {
            const payments_ = PaymentFactory.create($typePayments);
            this.Payments.push(payments_.init(item));
          }
        }
      }
      if (_data["Services"] && _data["Services"].constructor === Array) {
        this.Services = [];
        for (let item of _data["Services"]) {
          const $typeServices = item && item.$type;
          if ($typeServices) {
            const services_ = ServiceFactory.create($typeServices);
            this.Services.push(services_.init(item));
          }
        }
      }
      this.Status = OrderStatus[_data["Status"]];
      if (_data["SubTotal"]) {
        const subTotal_ = new Money();
        this.SubTotal = subTotal_.init(_data["SubTotal"]);
      }
      if (_data["Total"]) {
        const total_ = new Money();
        this.Total = total_.init(_data["Total"]);
      }
      if (_data["Vat"]) {
        const vat_ = new Money();
        this.Vat = vat_.init(_data["Vat"]);
      }
      this.Version = _data["Version"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.BillingDocuments && this.BillingDocuments.constructor === Array) {
      _data["BillingDocuments"] = [];
      for (let item of this.BillingDocuments) {
        _data["BillingDocuments"].push(item.toJSON());
      }
    }
    _data["BusinessType"] = OrderBusinessType[this.BusinessType];
    _data["CancelledAt"] = DateConverter.toIsoUtcString(this.CancelledAt);
    _data["ClientSource"] = OrderClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["CompanyName"] = this.CompanyName;
    _data["ConfirmedAt"] = DateConverter.toIsoUtcString(this.ConfirmedAt);
    _data["ContractId"] = this.ContractId;
    _data["CreatedAt"] = DateConverter.toIsoUtcString(this.CreatedAt);
    if (this.DraftServices && this.DraftServices.constructor === Array) {
      _data["DraftServices"] = [];
      for (let item of this.DraftServices) {
        _data["DraftServices"].push(item.toJSON());
      }
    }
    _data["HasContractReference"] = this.HasContractReference;
    _data["HasProcessingFailure"] = this.HasProcessingFailure;
    _data["Id"] = this.Id;
    _data["NeedToGenerateInvoice"] = this.NeedToGenerateInvoice;
    _data["OrderPaymentStatus"] = OrderOrderPaymentStatus[this.OrderPaymentStatus];
    if (this.Payments && this.Payments.constructor === Array) {
      _data["Payments"] = [];
      for (let item of this.Payments) {
        _data["Payments"].push(item.toJSON());
      }
    }
    if (this.Services && this.Services.constructor === Array) {
      _data["Services"] = [];
      for (let item of this.Services) {
        _data["Services"].push(item.toJSON());
      }
    }
    _data["Status"] = OrderStatus[this.Status];
    _data["SubTotal"] = this.SubTotal ? this.SubTotal.toJSON() : void 0;
    _data["Total"] = this.Total ? this.Total.toJSON() : void 0;
    _data["Vat"] = this.Vat ? this.Vat.toJSON() : void 0;
    _data["Version"] = this.Version;
    return _data;
  }
}
export var OrderBusinessType = /* @__PURE__ */ ((OrderBusinessType2) => {
  OrderBusinessType2["Marketplace"] = "Marketplace";
  OrderBusinessType2["Enterprise"] = "Enterprise";
  return OrderBusinessType2;
})(OrderBusinessType || {});
export var OrderClientSource = /* @__PURE__ */ ((OrderClientSource2) => {
  OrderClientSource2["TaxDesk"] = "TaxDesk";
  OrderClientSource2["Geopost"] = "Geopost";
  OrderClientSource2["Partner"] = "Partner";
  OrderClientSource2["Hellotax"] = "Hellotax";
  return OrderClientSource2;
})(OrderClientSource || {});
export var OrderOrderPaymentStatus = /* @__PURE__ */ ((OrderOrderPaymentStatus2) => {
  OrderOrderPaymentStatus2["None"] = "None";
  OrderOrderPaymentStatus2["Pending"] = "Pending";
  OrderOrderPaymentStatus2["Approved"] = "Approved";
  return OrderOrderPaymentStatus2;
})(OrderOrderPaymentStatus || {});
export var OrderStatus = /* @__PURE__ */ ((OrderStatus2) => {
  OrderStatus2["Pending"] = "Pending";
  OrderStatus2["Cancelled"] = "Cancelled";
  OrderStatus2["Draft"] = "Draft";
  OrderStatus2["Confirmed"] = "Confirmed";
  OrderStatus2["Abandoned"] = "Abandoned";
  return OrderStatus2;
})(OrderStatus || {});
