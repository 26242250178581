import { useState } from "GlobalShared/haunted/CustomHooks";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import i18next from "i18next";
import { html } from "lit-html";
export const useConfirmModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestion] = useState("");
  const [callback, setCallback] = useState(void 0);
  const confirm = (q) => {
    setQuestion(q);
    setShowModal(true);
    return new Promise(function(resolve, _) {
      setCallback(() => resolve);
    });
  };
  const mainTemplate = () => html`
		<dc-modal
			.customClass=${"w-3/4 lg:w-1/3 top-1/4"}
			.visible=${showModal}
			@close=${() => {
    setShowModal(false);
    if (callback) {
      callback(false);
    }
  }}
			.header=${(props == null ? void 0 : props.header) ? props == null ? void 0 : props.header : i18next.t("Confirm")}
			.content=${html`<div class="p-4">
				<div>${question}</div>
				<div class="flex space-x-4 mt-4">
					${!(props == null ? void 0 : props.isConfirmOnly) ? html`
								${SecondaryButton({
    class: "max-w-fit",
    text: (props == null ? void 0 : props.cancelText) ? props == null ? void 0 : props.cancelText : i18next.t("No"),
    onClick: () => {
      setShowModal(false);
      if (callback) {
        callback(false);
      }
    }
  })}
						  ` : ""}
					${PrimaryButton({
    class: "max-w-fit",
    text: (props == null ? void 0 : props.confirmText) ? props == null ? void 0 : props.confirmText : i18next.t("Yes"),
    onClick: () => {
      setShowModal(false);
      if (callback) {
        callback(true);
      }
    }
  })}
				</div>
			</div>`}
		>
		</dc-modal>
	`;
  return { mainTemplate, confirm };
};
