var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { generateTaxDeskPDF } from "./taxdesk-pdf";
import { generateGeopostPDF } from "./geopost-pdf";
export const generatePDFContract = (pdfModel) => __async(void 0, null, function* () {
  let blob;
  const fileName = `Contract_${pdfModel.partnerName}${pdfModel.contractReference ? `_${pdfModel.contractReference}` : ""}`;
  if (pdfModel.clientSource === "TaxDesk") {
    blob = generateTaxDeskPDF(pdfModel);
  } else if (pdfModel.clientSource === "Geopost") {
    blob = generateGeopostPDF(pdfModel);
  }
  return { blob, fileName };
});
