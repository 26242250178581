var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Country } from "./../../../Common/MasterData/Country";
import { DateConverter } from "./../../../../../converters/DateConverter";
export class OssRegistration {
  constructor(_data) {
    /**
     */
    __publicField(this, "ClientSource");
    /**
     */
    __publicField(this, "CompanyId");
    /**
     */
    __publicField(this, "CompanyName");
    /**
     */
    __publicField(this, "CompanyStatus");
    /**
     */
    __publicField(this, "Country");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "RegistrationType");
    /**
     */
    __publicField(this, "ResponsibleEmail");
    /**
     */
    __publicField(this, "SignupDate");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "SubscriptionStatus");
    /**
     */
    __publicField(this, "SubscriptionValidTo");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof OssRegistration
     */
    __publicField(this, "$type", "V1.Company.OssRegistration.Search.OssRegistration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientSource = OssRegistrationClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.CompanyName = _data["CompanyName"];
      this.CompanyStatus = OssRegistrationCompanyStatus[_data["CompanyStatus"]];
      if (_data["Country"]) {
        const country_ = new Country();
        this.Country = country_.init(_data["Country"]);
      }
      this.Id = _data["Id"];
      this.RegistrationType = OssRegistrationRegistrationType[_data["RegistrationType"]];
      this.ResponsibleEmail = _data["ResponsibleEmail"];
      this.SignupDate = DateConverter.from(_data["SignupDate"]);
      this.Status = OssRegistrationStatus[_data["Status"]];
      this.SubscriptionStatus = OssRegistrationSubscriptionStatus[_data["SubscriptionStatus"]];
      this.SubscriptionValidTo = DateConverter.from(_data["SubscriptionValidTo"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientSource"] = OssRegistrationClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["CompanyName"] = this.CompanyName;
    _data["CompanyStatus"] = OssRegistrationCompanyStatus[this.CompanyStatus];
    _data["Country"] = this.Country ? this.Country.toJSON() : void 0;
    _data["Id"] = this.Id;
    _data["RegistrationType"] = OssRegistrationRegistrationType[this.RegistrationType];
    _data["ResponsibleEmail"] = this.ResponsibleEmail;
    _data["SignupDate"] = DateConverter.toUtcDateString(this.SignupDate);
    _data["Status"] = OssRegistrationStatus[this.Status];
    _data["SubscriptionStatus"] = OssRegistrationSubscriptionStatus[this.SubscriptionStatus];
    _data["SubscriptionValidTo"] = DateConverter.toUtcDateString(this.SubscriptionValidTo);
    return _data;
  }
}
export var OssRegistrationClientSource = /* @__PURE__ */ ((OssRegistrationClientSource2) => {
  OssRegistrationClientSource2["TaxDesk"] = "TaxDesk";
  OssRegistrationClientSource2["Geopost"] = "Geopost";
  OssRegistrationClientSource2["Partner"] = "Partner";
  OssRegistrationClientSource2["Hellotax"] = "Hellotax";
  return OssRegistrationClientSource2;
})(OssRegistrationClientSource || {});
export var OssRegistrationCompanyStatus = /* @__PURE__ */ ((OssRegistrationCompanyStatus2) => {
  OssRegistrationCompanyStatus2["Active"] = "Active";
  OssRegistrationCompanyStatus2["Rejected"] = "Rejected";
  OssRegistrationCompanyStatus2["Inactive"] = "Inactive";
  return OssRegistrationCompanyStatus2;
})(OssRegistrationCompanyStatus || {});
export var OssRegistrationRegistrationType = /* @__PURE__ */ ((OssRegistrationRegistrationType2) => {
  OssRegistrationRegistrationType2["NewRegistration"] = "NewRegistration";
  OssRegistrationRegistrationType2["TakeOver"] = "TakeOver";
  return OssRegistrationRegistrationType2;
})(OssRegistrationRegistrationType || {});
export var OssRegistrationStatus = /* @__PURE__ */ ((OssRegistrationStatus2) => {
  OssRegistrationStatus2["Pending"] = "Pending";
  OssRegistrationStatus2["WaitingForSignature"] = "WaitingForSignature";
  OssRegistrationStatus2["DocumentsSigned"] = "DocumentsSigned";
  OssRegistrationStatus2["SentToTA"] = "SentToTA";
  OssRegistrationStatus2["RegistrationCompleted"] = "RegistrationCompleted";
  OssRegistrationStatus2["Deregistered"] = "Deregistered";
  OssRegistrationStatus2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return OssRegistrationStatus2;
})(OssRegistrationStatus || {});
export var OssRegistrationSubscriptionStatus = /* @__PURE__ */ ((OssRegistrationSubscriptionStatus2) => {
  OssRegistrationSubscriptionStatus2["Active"] = "Active";
  OssRegistrationSubscriptionStatus2["Cancelled"] = "Cancelled";
  OssRegistrationSubscriptionStatus2["Expired"] = "Expired";
  OssRegistrationSubscriptionStatus2["Pending"] = "Pending";
  return OssRegistrationSubscriptionStatus2;
})(OssRegistrationSubscriptionStatus || {});
