var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { AmazonConfiguration } from "./Amazon/AmazonConfiguration";
export class CompanyConfiguration {
  constructor(_data) {
    /**
     */
    __publicField(this, "AmazonConfiguration");
    /**
     */
    __publicField(this, "UseAmazonTemplate");
    /**
     */
    __publicField(this, "UseMpTemplate");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CompanyConfiguration
     */
    __publicField(this, "$type", "V1.ComplianceUploads.Configuration.CompanyConfiguration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AmazonConfiguration"]) {
        const amazonConfiguration_ = new AmazonConfiguration();
        this.AmazonConfiguration = amazonConfiguration_.init(_data["AmazonConfiguration"]);
      }
      this.UseAmazonTemplate = _data["UseAmazonTemplate"];
      this.UseMpTemplate = _data["UseMpTemplate"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["AmazonConfiguration"] = this.AmazonConfiguration ? this.AmazonConfiguration.toJSON() : void 0;
    _data["UseAmazonTemplate"] = this.UseAmazonTemplate;
    _data["UseMpTemplate"] = this.UseMpTemplate;
    return _data;
  }
}
