var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class Subscription {
  constructor(_data) {
    /**
     */
    __publicField(this, "IsSubscribed");
    /**
     */
    __publicField(this, "UserEmail");
    /**
     */
    __publicField(this, "UserId");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Subscription
     */
    __publicField(this, "$type", "V1.Subscriptions.Subscription");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.IsSubscribed = _data["IsSubscribed"];
      this.UserEmail = _data["UserEmail"];
      this.UserId = _data["UserId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["IsSubscribed"] = this.IsSubscribed;
    _data["UserEmail"] = this.UserEmail;
    _data["UserId"] = this.UserId;
    return _data;
  }
}
