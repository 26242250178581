var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { PeriodSearchResponse } from "./../models/V1/Cesop/Periods/Search/PeriodSearchResponse";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { Period } from "./../models/V1/Cesop/Periods/Period";
import { CreateFileResult } from "./../models/V1/Cesop/Periods/CreateFileResult";
import { ValidationErrorCollection } from "./../models/V1/Cesop/Periods/Validation/Source/ValidationErrorCollection";
import { SourceFile } from "./../models/V1/Cesop/Periods/SourceFile";
export class Periods {
  /**
   * Creates an instance of Periods.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Periods
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof Periods
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/search", "POST", "application/json; charset=UTF-8", "application/json", options);
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PeriodSearchResponse },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetPeriodRq_ _getPeriodRq
   * @returns Promise<GetPeriodRq_>
   * @memberof Periods
   */
  getPeriod(pGetPeriodRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}", "GET", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pGetPeriodRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Period },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeUploadTypeRq_ _changeUploadTypeRq
   * @returns Promise<ChangeUploadTypeRq_>
   * @memberof Periods
   */
  changeUploadType(pChangeUploadTypeRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pChangeUploadTypeRq.id, required: true }]);
    client.addBody({ value: pChangeUploadTypeRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeletePeriodRq_ _deletePeriodRq
   * @returns Promise<DeletePeriodRq_>
   * @memberof Periods
   */
  deletePeriod(pDeletePeriodRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}", "DELETE", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pDeletePeriodRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeUploadRq_ _finalizeUploadRq
   * @returns Promise<FinalizeUploadRq_>
   * @memberof Periods
   */
  finalizeUpload(pFinalizeUploadRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/finalize", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pFinalizeUploadRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreateSourceFileRq_ _createSourceFileRq
   * @returns Promise<CreateSourceFileRq_>
   * @memberof Periods
   */
  createSourceFile(pCreateSourceFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/sourcefiles", "POST", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pCreateSourceFileRq.id, required: true }]);
    client.addBody({ value: pCreateSourceFileRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: CreateFileResult },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof Periods
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{id}/files/{fileReference}",
      "GET",
      "application/json; charset=UTF-8",
      "text/xml, text/csv, application/pdf, application/zip, application/xls, application/xlsx",
      options
    );
    client.addPathParams([
      { name: "id", value: pDownloadFileRq.id, required: true },
      { name: "fileReference", value: pDownloadFileRq.fileReference, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ApproveSummaryRq_ _approveSummaryRq
   * @returns Promise<ApproveSummaryRq_>
   * @memberof Periods
   */
  approveSummary(pApproveSummaryRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/approve", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pApproveSummaryRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RejectSummaryRq_ _rejectSummaryRq
   * @returns Promise<RejectSummaryRq_>
   * @memberof Periods
   */
  rejectSummary(pRejectSummaryRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/reject", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pRejectSummaryRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param PayeeSourceFileUploadedRq_ _payeeSourceFileUploadedRq
   * @returns Promise<PayeeSourceFileUploadedRq_>
   * @memberof Periods
   */
  payeeSourceFileUploaded(pPayeeSourceFileUploadedRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/payees", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pPayeeSourceFileUploadedRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreatePayeesSourceFileRq_ _createPayeesSourceFileRq
   * @returns Promise<CreatePayeesSourceFileRq_>
   * @memberof Periods
   */
  createPayeesSourceFile(pCreatePayeesSourceFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/payees", "POST", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pCreatePayeesSourceFileRq.id, required: true }]);
    client.addBody({ value: pCreatePayeesSourceFileRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: CreateFileResult },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeletePayeeSourceFileRq_ _deletePayeeSourceFileRq
   * @returns Promise<DeletePayeeSourceFileRq_>
   * @memberof Periods
   */
  deletePayeeSourceFile(pDeletePayeeSourceFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/payees", "DELETE", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pDeletePayeeSourceFileRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetPayeeValidationErrorsRq_ _getPayeeValidationErrorsRq
   * @returns Promise<GetPayeeValidationErrorsRq_>
   * @memberof Periods
   */
  getPayeeValidationErrors(pGetPayeeValidationErrorsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/payees/ValidationErrors", "GET", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pGetPayeeValidationErrorsRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: ValidationErrorCollection },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetTransactionValidationErrorsRq_ _getTransactionValidationErrorsRq
   * @returns Promise<GetTransactionValidationErrorsRq_>
   * @memberof Periods
   */
  getTransactionValidationErrors(pGetTransactionValidationErrorsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{periodId}/sourceFiles/{sourceFileId}/ValidationErrors",
      "GET",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "periodId", value: pGetTransactionValidationErrorsRq.periodId, required: true },
      { name: "sourceFileId", value: pGetTransactionValidationErrorsRq.sourceFileId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: ValidationErrorCollection },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSubmissionProofRq_ _uploadSubmissionProofRq
   * @returns Promise<UploadSubmissionProofRq_>
   * @memberof Periods
   */
  uploadSubmissionProof(pUploadSubmissionProofRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/Versions/{versionId}/SubmissionProofFiles", "POST", "multipart/form-data", "application/json", options);
    client.addPathParams([
      { name: "id", value: pUploadSubmissionProofRq.id, required: true },
      { name: "versionId", value: pUploadSubmissionProofRq.versionId, required: true }
    ]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadSubmissionProofRq.file, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteSubmissionProofRq_ _deleteSubmissionProofRq
   * @returns Promise<DeleteSubmissionProofRq_>
   * @memberof Periods
   */
  deleteSubmissionProof(pDeleteSubmissionProofRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/SubmissionProofFiles/{fileReference}", "DELETE", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "id", value: pDeleteSubmissionProofRq.id, required: true },
      { name: "fileReference", value: pDeleteSubmissionProofRq.fileReference, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadProofFilesRq_ _downloadProofFilesRq
   * @returns Promise<DownloadProofFilesRq_>
   * @memberof Periods
   */
  downloadProofFiles(pDownloadProofFilesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{id}/SubmissionProofFiles", "GET", "application/json; charset=UTF-8", "application/zip", options);
    client.addPathParams([{ name: "id", value: pDownloadProofFilesRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RegenerateXmlRq_ _regenerateXmlRq
   * @returns Promise<RegenerateXmlRq_>
   * @memberof Periods
   */
  regenerateXml(pRegenerateXmlRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/regenerate", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "periodId", value: pRegenerateXmlRq.periodId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ReopenRq_ _reopenRq
   * @returns Promise<ReopenRq_>
   * @memberof Periods
   */
  reopen(pReopenRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/reopen", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "periodId", value: pReopenRq.periodId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreateTemporalXmlFileRq_ _createTemporalXmlFileRq
   * @returns Promise<CreateTemporalXmlFileRq_>
   * @memberof Periods
   */
  createTemporalXmlFile(pCreateTemporalXmlFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/temporalxml", "POST", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "periodId", value: pCreateTemporalXmlFileRq.periodId, required: true }]);
    client.addBody({ value: pCreateTemporalXmlFileRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 201, clazz: CreateFileResult },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param TemporalXmlFileUploadedRq_ _temporalXmlFileUploadedRq
   * @returns Promise<TemporalXmlFileUploadedRq_>
   * @memberof Periods
   */
  temporalXmlFileUploaded(pTemporalXmlFileUploadedRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/temporalxml/{fileId}/uploaded", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pTemporalXmlFileUploadedRq.periodId, required: true },
      { name: "fileId", value: pTemporalXmlFileUploadedRq.fileId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteTemporalXmlFileRq_ _deleteTemporalXmlFileRq
   * @returns Promise<DeleteTemporalXmlFileRq_>
   * @memberof Periods
   */
  deleteTemporalXmlFile(pDeleteTemporalXmlFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/temporalxml/{fileId}", "DELETE", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pDeleteTemporalXmlFileRq.periodId, required: true },
      { name: "fileId", value: pDeleteTemporalXmlFileRq.fileId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param StartCorrectionRq_ _startCorrectionRq
   * @returns Promise<StartCorrectionRq_>
   * @memberof Periods
   */
  startCorrection(pStartCorrectionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{periodId}/Version/{versionId}/RequestCorrection",
      "PATCH",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "periodId", value: pStartCorrectionRq.periodId, required: true },
      { name: "versionId", value: pStartCorrectionRq.versionId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetSourceFileRq_ _getSourceFileRq
   * @returns Promise<GetSourceFileRq_>
   * @memberof Periods
   */
  getSourceFile(pGetSourceFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/SourceFiles/{id}", "GET", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pGetSourceFileRq.periodId, required: true },
      { name: "id", value: pGetSourceFileRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SourceFile },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FileUploadedRq_ _fileUploadedRq
   * @returns Promise<FileUploadedRq_>
   * @memberof Periods
   */
  fileUploaded(pFileUploadedRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/SourceFiles/{id}", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pFileUploadedRq.periodId, required: true },
      { name: "id", value: pFileUploadedRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteFileRq_ _deleteFileRq
   * @returns Promise<DeleteFileRq_>
   * @memberof Periods
   */
  deleteFile(pDeleteFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/SourceFiles/{id}", "DELETE", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pDeleteFileRq.periodId, required: true },
      { name: "id", value: pDeleteFileRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadFileRq_ _uploadFileRq
   * @returns Promise<UploadFileRq_>
   * @memberof Periods
   */
  uploadFile(pUploadFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/Messages/Files", "POST", "multipart/form-data", "application/json", options);
    client.addPathParams([{ name: "periodId", value: pUploadFileRq.periodId, required: true }]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadFileRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200 }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddMessageResponseRq_ _addMessageResponseRq
   * @returns Promise<AddMessageResponseRq_>
   * @memberof Periods
   */
  addMessageResponse(pAddMessageResponseRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/Messages/{messageId}/Responses", "POST", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pAddMessageResponseRq.periodId, required: true },
      { name: "messageId", value: pAddMessageResponseRq.messageId, required: true }
    ]);
    client.addBody({ value: pAddMessageResponseRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 201 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteProofFileRq_ _deleteProofFileRq
   * @returns Promise<DeleteProofFileRq_>
   * @memberof Periods
   */
  deleteProofFile(pDeleteProofFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/cesop/Periods/{periodId}/Messages/{messageId}/Proofs/{proofId}",
      "DELETE",
      "application/json; charset=UTF-8",
      "application/json",
      options
    );
    client.addPathParams([
      { name: "periodId", value: pDeleteProofFileRq.periodId, required: true },
      { name: "messageId", value: pDeleteProofFileRq.messageId, required: true },
      { name: "proofId", value: pDeleteProofFileRq.proofId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 201 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStatusRq_ _changeStatusRq
   * @returns Promise<ChangeStatusRq_>
   * @memberof Periods
   */
  changeStatus(pChangeStatusRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/Messages/{messageId}/status", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pChangeStatusRq.periodId, required: true },
      { name: "messageId", value: pChangeStatusRq.messageId, required: true }
    ]);
    client.addBody({ value: pChangeStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SendRq_ _sendRq
   * @returns Promise<SendRq_>
   * @memberof Periods
   */
  send(pSendRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/Messages/{messageId}/send", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pSendRq.periodId, required: true },
      { name: "messageId", value: pSendRq.messageId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ResendRq_ _resendRq
   * @returns Promise<ResendRq_>
   * @memberof Periods
   */
  resend(pResendRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/Periods/{periodId}/Messages/{messageId}/resend", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([
      { name: "periodId", value: pResendRq.periodId, required: true },
      { name: "messageId", value: pResendRq.messageId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
