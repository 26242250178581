var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { PossibleStatusChange } from "./../models/V1/Cesop/Periods/StatusChanges/PossibleStatusChange";
export class CesopPeriods {
  /**
   * Creates an instance of CesopPeriods.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof CesopPeriods
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param ChangeStatusRq_ _changeStatusRq
   * @returns Promise<ChangeStatusRq_>
   * @memberof CesopPeriods
   */
  changeStatus(pChangeStatusRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Cesop/Periods/{id}/ChangeStatus", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pChangeStatusRq.id, required: true }]);
    client.addBody({ value: pChangeStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetAvailableStatusChangesRq_ _getAvailableStatusChangesRq
   * @returns Promise<GetAvailableStatusChangesRq_>
   * @memberof CesopPeriods
   */
  getAvailableStatusChanges(pGetAvailableStatusChangesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Cesop/Periods/{periodId}/AvailableChanges", "GET", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "periodId", value: pGetAvailableStatusChangesRq.periodId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PossibleStatusChange, isArray: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SwitchToManualCorrectionRq_ _switchToManualCorrectionRq
   * @returns Promise<SwitchToManualCorrectionRq_>
   * @memberof CesopPeriods
   */
  switchToManualCorrection(pSwitchToManualCorrectionRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Cesop/Periods/{periodId}/SwitchToManualCorrection", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "periodId", value: pSwitchToManualCorrectionRq.periodId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateSubmissionDateRq_ _updateSubmissionDateRq
   * @returns Promise<UpdateSubmissionDateRq_>
   * @memberof CesopPeriods
   */
  updateSubmissionDate(pUpdateSubmissionDateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Cesop/Periods/{periodId}/Version/{versionId}/SubmissionDate",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "periodId", value: pUpdateSubmissionDateRq.periodId, required: true },
      { name: "versionId", value: pUpdateSubmissionDateRq.versionId, required: true }
    ]);
    client.addBody({ value: pUpdateSubmissionDateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
