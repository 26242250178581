var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { CustomParamBaseFactory } from "./CustomParamBaseFactory";
import { TemplateVersionDescription } from "./TemplateVersionDescription";
export class TemplateResponse {
  constructor(_data) {
    /**
     */
    __publicField(this, "Culture");
    /**
     */
    __publicField(this, "CustomParam");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Name");
    /**
     */
    __publicField(this, "TemplateType");
    /**
     */
    __publicField(this, "Versions");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TemplateResponse
     */
    __publicField(this, "$type", "V1.TemplateMapping.TemplateResponse");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Culture = _data["Culture"];
      const $typeCustomParam = _data["CustomParam"] && _data["CustomParam"].$type;
      if ($typeCustomParam) {
        const customParam_ = CustomParamBaseFactory.create($typeCustomParam);
        this.CustomParam = customParam_.init(_data["CustomParam"]);
      } else {
        this.CustomParam = _data["CustomParam"];
      }
      this.Id = _data["Id"];
      this.Name = _data["Name"];
      this.TemplateType = TemplateResponseTemplateType[_data["TemplateType"]];
      if (_data["Versions"] && _data["Versions"].constructor === Array) {
        this.Versions = [];
        for (let item of _data["Versions"]) {
          const versions_ = new TemplateVersionDescription();
          this.Versions.push(versions_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Culture"] = this.Culture;
    _data["CustomParam"] = this.CustomParam ? this.CustomParam.toJSON() : void 0;
    _data["Id"] = this.Id;
    _data["Name"] = this.Name;
    _data["TemplateType"] = TemplateResponseTemplateType[this.TemplateType];
    if (this.Versions && this.Versions.constructor === Array) {
      _data["Versions"] = [];
      for (let item of this.Versions) {
        _data["Versions"].push(item.toJSON());
      }
    }
    return _data;
  }
}
export var TemplateResponseTemplateType = /* @__PURE__ */ ((TemplateResponseTemplateType2) => {
  TemplateResponseTemplateType2["MPVatReg"] = "MPVatReg";
  TemplateResponseTemplateType2["MPCompany"] = "MPCompany";
  return TemplateResponseTemplateType2;
})(TemplateResponseTemplateType || {});
