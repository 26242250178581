var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { FileNamePattern } from "./FileNamePattern";
import { FileLimitFactory } from "./FileLimitFactory";
export class CountryConfiguration {
  constructor(_data) {
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "FileNamePattern");
    /**
     */
    __publicField(this, "FileSizeLimits");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CountryConfiguration
     */
    __publicField(this, "$type", "V1.Cesop.Configuration.CountryConfiguration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CountryCode = _data["CountryCode"];
      if (_data["FileNamePattern"]) {
        const fileNamePattern_ = new FileNamePattern();
        this.FileNamePattern = fileNamePattern_.init(_data["FileNamePattern"]);
      }
      const $typeFileSizeLimits = _data["FileSizeLimits"] && _data["FileSizeLimits"].$type;
      if ($typeFileSizeLimits) {
        const fileSizeLimits_ = FileLimitFactory.create($typeFileSizeLimits);
        this.FileSizeLimits = fileSizeLimits_.init(_data["FileSizeLimits"]);
      } else {
        this.FileSizeLimits = _data["FileSizeLimits"];
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CountryCode"] = this.CountryCode;
    _data["FileNamePattern"] = this.FileNamePattern ? this.FileNamePattern.toJSON() : void 0;
    _data["FileSizeLimits"] = this.FileSizeLimits ? this.FileSizeLimits.toJSON() : void 0;
    return _data;
  }
}
