var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
export class BillingDocument {
  constructor(_data) {
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Name");
    /**
     */
    __publicField(this, "OrderVersion");
    /**
     */
    __publicField(this, "ReceivedAt");
    /**
     */
    __publicField(this, "Reference");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BillingDocument
     */
    __publicField(this, "$type", "V1.Orders.Get.BillingDocument");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Id = _data["Id"];
      this.Name = _data["Name"];
      this.OrderVersion = _data["OrderVersion"];
      this.ReceivedAt = DateConverter.from(_data["ReceivedAt"]);
      this.Reference = _data["Reference"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Id"] = this.Id;
    _data["Name"] = this.Name;
    _data["OrderVersion"] = this.OrderVersion;
    _data["ReceivedAt"] = DateConverter.toIsoUtcString(this.ReceivedAt);
    _data["Reference"] = this.Reference;
    return _data;
  }
}
