var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { formatDate, getDay, interpretYearOneAsUndefined } from "GlobalShared/helpers/dateHelper";
import { notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { COMPLIANCE_SUBSCRIPTION_STATUS_LABELS } from "GlobalShared/models/ServicesModels";
import i18next from "i18next";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { useCommentModal } from "../reg-modals/comment-modal";
import { getStatusLabel } from "./services-common";
import { downloadPDFIcon } from "GlobalShared/templates/commons";
import { useIOSSModal } from "../reg-modals/ioss-modal";
import { useConfirmModal } from "../modals/commonModals";
import { ONLY_PDF_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
import { useSubCancellationModal } from "./sub-cancellation-modal";
import { useDeregistrationModal } from "../reg-modals/deregistration-modal";
import { useDateEditorModal } from "GlobalShared/components/services/date-editor-modal";
import { canChangeToSentToTa } from "./service-helpers";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
export const useIossService = (props) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F;
  const getValidNextIossTransition = (from) => {
    var _a2;
    return (_a2 = validIOSSTransitions == null ? void 0 : validIOSSTransitions.reduce((aggr, curr) => {
      if (curr.From === from) {
        return aggr.concat(curr.To);
      } else {
        return aggr;
      }
    }, [])) != null ? _a2 : [];
  };
  const mapToClientCompatibleIOSSStatus = (status) => {
    if (status === "Failed" || status === "Declined") {
      return getStatusLabel("UnderReview");
    } else {
      return getStatusLabel(status);
    }
  };
  const changeIossSubscriptionStartDate = (selectedDate) => __async(void 0, null, function* () {
    if (getDay(selectedDate) > getDay(props.companyServices.GlobalServices.IossService.ComplianceValidTo)) {
      notifyWarning(i18next.t("Service start date cannot be after the service expiration date."));
      return;
    }
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.changeSubscriptionStartDate(props.companyId, props.companyServices.GlobalServices.IossService.ComplianceSubscriptionId, selectedDate);
        notifySuccess(i18next.t("Saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const changeIossSubscriptionEndDate = (selectedDate) => __async(void 0, null, function* () {
    if (getDay(selectedDate) < getDay(props.companyServices.GlobalServices.IossService.ValidFrom)) {
      notifyWarning(i18next.t("Service expiration date cannot be before the service start date."));
      return;
    }
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.changeSubscriptionEndDate(props.companyId, props.companyServices.GlobalServices.IossService.ComplianceSubscriptionId, selectedDate);
        notifySuccess(i18next.t("Saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const changeIossRegistrationStartDate = (selectedDate) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.changeIossRegistrationStartDate(props.companyId, props.companyServices.GlobalServices.IossService.Id, selectedDate);
        notifySuccess(i18next.t("Saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [currentDateEditorModalProps, setCurrentDateEditorModalProps] = useState(void 0);
  const dateEditorModal = useDateEditorModal(currentDateEditorModalProps);
  const [showSignedIOSSDocumentUploadModal, setShowSignedIOSSDocumentUploadModal] = useState(false);
  const [currentIOSSRegId, setCurrentIOSSRegId] = useState(void 0);
  const [currentIOSSDocumentId, setCurrentIOSSDocumentId] = useState(void 0);
  const [validIOSSTransitions, setValidIOSSTransitions] = useState([]);
  const [currentCommentModalProps, setCurrentCommentModalProps] = useState(void 0);
  const commentModal = useCommentModal(currentCommentModalProps);
  const [currentIOSSModalProps, setCurrentIOSSModalProps] = useState(void 0);
  const iossModal = useIOSSModal(currentIOSSModalProps);
  const confirmModal = useConfirmModal();
  const [currentCancellationModalProps, setCurrentCancellationModalProps] = useState(void 0);
  const subCancellationModal = useSubCancellationModal(currentCancellationModalProps);
  const [currentDeregistrationModalProps, setCurrentDeregistrationModalProps] = useState(void 0);
  const deregistrationModal = useDeregistrationModal(currentDeregistrationModalProps);
  const closingDateEditorModal = () => {
    setCurrentDateEditorModalProps(void 0);
  };
  useEffect(() => __async(void 0, null, function* () {
    if (props == null ? void 0 : props.isAdmin) {
      setValidIOSSTransitions(yield props.callbackHandler.getPossibleIossStatuses());
    }
  }), [props.callbackHandler]);
  const htmlSignedIOSSDocumentUploadModal = () => {
    if (showSignedIOSSDocumentUploadModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showSignedIOSSDocumentUploadModal}
						@close=${() => setShowSignedIOSSDocumentUploadModal(false)}
						.header=${"Upload signed PDF"}
						.content=${html`<div>
							<dc-fileupload
								.label=${"PDF Document"}
								.fileName=${void 0}
								.fileReference=${void 0}
								.validationMessage=${void 0}
								.accept=${ONLY_PDF_ACCEPT_LIST}
								@upload=${(e) => __async(void 0, null, function* () {
        var _a2;
        if (((_a2 = e.detail) == null ? void 0 : _a2.files) && e.detail.files.length > 0) {
          yield props.callbackHandler.uploadIOSSSignedRegistrationDocument(currentIOSSRegId, currentIOSSDocumentId, e.detail.files[0]);
          setShowSignedIOSSDocumentUploadModal(false);
        }
      })}
							></dc-fileupload>

							<button class="btn btn-primary mt-4" @click=${() => setShowSignedIOSSDocumentUploadModal(false)}>Cancel</button>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlDateEditor = (options) => {
    return props.isAdmin && options.value && props.permissionManager.hasPermission(options.requiredPermission) ? html`<dc-edit-button
					@click=${() => {
      setCurrentDateEditorModalProps(__spreadProps(__spreadValues({}, options), {
        onClosing: closingDateEditorModal
      }));
      dateEditorModal.show();
    }}
			  ></dc-edit-button>` : "";
  };
  const mainTemplate = ((_b = (_a = props.companyServices) == null ? void 0 : _a.GlobalServices) == null ? void 0 : _b.IossService) !== void 0 && (props.showCancelledServices || ((_c = props.companyServices.GlobalServices) == null ? void 0 : _c.IossService.ComplianceSubscriptionStatus) !== "Cancelled") ? html`
					<div
						class="mt-8 shadow border px-6 py-4 xl:mx-auto rounded-xl ${props.companyServices.GlobalServices.IossService.ComplianceSubscriptionStatus === "Cancelled" ? "bg-red-200" : ""}"
					>
						<div class="flex justify-between">
							<div class="font-bold text-lg mt-1 mb-3">${i18next.t("IOSS Registration and Compliance")}</div>
							<div>
								${(props == null ? void 0 : props.isAdmin) ? html`<dc-dropdown
											.isStatusLike=${true}
											.buttonText=${getStatusLabel(props.companyServices.GlobalServices.IossService.RegistrationStatus)}
											.selectedValues=${[props.companyServices.GlobalServices.IossService.RegistrationStatus]}
											.dataSource=${[
    {
      label: getStatusLabel(props.companyServices.GlobalServices.IossService.RegistrationStatus),
      value: props.companyServices.GlobalServices.IossService.RegistrationStatus
    }
  ].concat(
    getValidNextIossTransition(props.companyServices.GlobalServices.IossService.RegistrationStatus).map((status) => ({
      label: getStatusLabel(status),
      value: status
    }))
  )}
											.readonly=${!props.permissionManager.hasPermission("IOSS_Modify")}
											@change=${(e) => {
    var _a2;
    const newIOSSStatus = e.detail.selectedValue;
    if (props.companyServices.GlobalServices.IossService.RegistrationStatus === "DocumentsSigned" && newIOSSStatus === "WaitingForSignature") {
      setCurrentCommentModalProps({
        save: (comment) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeIossRegistrationStatus(
            props.companyServices.GlobalServices.IossService.Id,
            props.companyServices.GlobalServices.IossService.RegistrationStatus,
            newIOSSStatus,
            comment
          );
        })
      });
      commentModal.show();
    } else if (props.companyServices.GlobalServices.IossService.RegistrationStatus === "SentToTA" && newIOSSStatus === "RegistrationCompleted") {
      setCurrentIOSSModalProps({
        iossCertificate: props.companyServices.GlobalServices.IossService.IOSSCertificate,
        save: (iossCertificate) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeIossRegistrationStatus(
            props.companyServices.GlobalServices.IossService.Id,
            props.companyServices.GlobalServices.IossService.RegistrationStatus,
            newIOSSStatus,
            void 0,
            iossCertificate
          );
        }),
        uploadIOSSCertificate: (file) => {
          return props.callbackHandler.uploadIossDocument(props.companyServices.GlobalServices.IossService.Id, file);
        }
      });
      iossModal.show();
    } else if (props.companyServices.GlobalServices.IossService.RegistrationStatus === "DeregistrationInProgress" && newIOSSStatus === "Deregistered") {
      setCurrentDeregistrationModalProps({
        change: {},
        save: (change) => __async(void 0, null, function* () {
          yield props.callbackHandler.changeIossRegistrationStatus(
            props.companyServices.GlobalServices.IossService.Id,
            props.companyServices.GlobalServices.IossService.RegistrationStatus,
            newIOSSStatus,
            void 0,
            void 0,
            change
          );
        }),
        uploadDocument: (file) => {
          return props.callbackHandler.uploadIossDocument(props.companyServices.GlobalServices.IossService.Id, file);
        }
      });
      deregistrationModal.show();
    } else if (props.companyServices.GlobalServices.IossService.RegistrationStatus === "WaitingForSignature" && newIOSSStatus === "DocumentsSigned") {
      if (((_a2 = props.companyServices.GlobalServices.IossService.Documents) == null ? void 0 : _a2.length) > 0 && !props.companyServices.GlobalServices.IossService.Documents.some(
        (d) => d.Category === "RequiredElectronicallyOnly" && d.SignedDocumentReference === void 0
      )) {
        props.callbackHandler.changeIossRegistrationStatus(
          props.companyServices.GlobalServices.IossService.Id,
          props.companyServices.GlobalServices.IossService.RegistrationStatus,
          newIOSSStatus
        );
      } else {
        notifyWarning(i18next.t("Signed documents need to be uploaded"));
      }
    } else if (props.companyServices.GlobalServices.IossService.RegistrationStatus === "DocumentsSigned" && newIOSSStatus === "SentToTA") {
      if (canChangeToSentToTa(props.companyServices, "IOSS_Modify_Advanced", props.permissionManager)) {
        props.callbackHandler.changeIossRegistrationStatus(
          props.companyServices.GlobalServices.IossService.Id,
          props.companyServices.GlobalServices.IossService.RegistrationStatus,
          newIOSSStatus
        );
      } else {
        notifyWarning(i18next.t("Client acceptance or AML is not valid."));
      }
    } else {
      props.callbackHandler.changeIossRegistrationStatus(
        props.companyServices.GlobalServices.IossService.Id,
        props.companyServices.GlobalServices.IossService.RegistrationStatus,
        newIOSSStatus
      );
    }
  }}
									  ></dc-dropdown>` : html`<div>${mapToClientCompatibleIOSSStatus(props.companyServices.GlobalServices.IossService.RegistrationStatus)}</div>
											<div class="${props.companyServices.GlobalServices.IossService.RegistrationStatus === "WaitingForSignature" ? "" : "hidden"}">
												<button
													class="btn btn-primary"
													?disabled=${props.companyServices.GlobalServices.IossService.RegistrationStatus !== "WaitingForSignature" || props.companyServices.GlobalServices.IossService.Documents.some(
    (doc) => doc.Category === "RequiredElectronicallyOnly" && doc.SignedDocumentReference === void 0
  )}
													@click=${() => __async(void 0, null, function* () {
    var _a2;
    if (((_a2 = props.companyServices.GlobalServices.IossService.Documents) == null ? void 0 : _a2.length) === 0) {
      notifyWarning(i18next.t("No document generated"));
    } else {
      yield props.callbackHandler.finalizeIOSSUpload(props.companyServices.GlobalServices.IossService.Id);
    }
  })}
												>
													${i18next.t("Finalize Upload")}
												</button>
											</div>`}
							</div>
						</div>
						<div class="border-b border-gray-300 pb-4">
							<div class="flex items-center text-sm">
								<span>${i18next.t("Service start date")}: ${formatDate(props.companyServices.GlobalServices.IossService.ValidFrom)}</span>
								${htmlDateEditor({
    title: "Change Service start date",
    fieldName: "Service start date",
    value: props.companyServices.GlobalServices.IossService.ValidFrom,
    saveDate: changeIossSubscriptionStartDate,
    requiredPermission: "Company_Subscription_Dates_Modify",
    max: props.companyServices.GlobalServices.IossService.ComplianceValidTo
  })}
								&nbsp;|&nbsp;
								<span
									>${i18next.t("Service expiration date")}:
									${props.companyServices.GlobalServices.IossService.ComplianceSubscriptionStatus === "Pending" ? "-" : formatDate(props.companyServices.GlobalServices.IossService.ComplianceValidTo)}</span
								>
								${props.companyServices.GlobalServices.IossService.ComplianceSubscriptionStatus !== "Pending" ? htmlDateEditor({
    title: "Change Service expiration date",
    fieldName: "Service expiration date",
    value: props.companyServices.GlobalServices.IossService.ComplianceValidTo,
    saveDate: changeIossSubscriptionEndDate,
    requiredPermission: "Company_Subscription_Dates_Modify",
    min: props.companyServices.GlobalServices.IossService.ValidFrom
  }) : ""}
							</div>
							${props.isAdmin ? html`<div class="text-sm">
											<span>${i18next.t("Merchant Id")}: ${props.companyServices.GlobalServices.IossService.MerchantId}</span>
										</div>
										<div class="text-sm">
											<span>${i18next.t("Product Id")}: ${props.companyServices.GlobalServices.IossService.ProductId}</span>
										</div>` : ""}
							<div class="flex items-center text-sm">
								<span
									>${((_e = (_d = props.companyServices.GlobalServices) == null ? void 0 : _d.IossService) == null ? void 0 : _e.RegistrationType) === "TakeOver" ? i18next.t("Start date of representation by Tax Desk") : i18next.t("Effective date of registration")}:
									${formatDate(props.companyServices.GlobalServices.IossService.RegistrationStartDate)}</span
								>
								${htmlDateEditor({
    title: "Change Start date",
    fieldName: "Start date",
    value: props.companyServices.GlobalServices.IossService.RegistrationStartDate,
    saveDate: changeIossRegistrationStartDate,
    requiredPermission: "IOSS_Modify_StartDate"
  })}
							</div>
						</div>
						<div class="justify-between border-b border-gray-300 items-center py-4">
							<div class="flex space-x-2 justify-between text-sm">
								<div class="flex space-x-2 p-2">
									<div>
										${i18next.t("Compliance status")}: ${COMPLIANCE_SUBSCRIPTION_STATUS_LABELS[props.companyServices.GlobalServices.IossService.ComplianceSubscriptionStatus]}
										${interpretYearOneAsUndefined(props.companyServices.GlobalServices.IossService.ComplianceSubscriptionCancellationDate) ? html`(${formatDate(props.companyServices.GlobalServices.IossService.ComplianceSubscriptionCancellationDate)})` : ""}
									</div>
									<div>|</div>
									<div>
										${props.companyServices.GlobalServices.IossService.Tier === "Tier1" || props.companyServices.GlobalServices.IossService.Tier === "Tier2" ? html`<div>${i18next.t("Available credit")}: ${props.companyServices.GlobalServices.IossService.PackageAmount}</div>` : ""}
									</div>
								</div>
								<div>
									${props.isAdmin && props.permissionManager.hasPermission("IOSS_Modify") && props.companyServices.GlobalServices.IossService.ComplianceSubscriptionStatus === "Active" ? html`
												${SecondaryButton({
    class: "max-w-fit",
    text: i18next.t("Cancel Subscription"),
    onClick: () => {
      setCurrentCancellationModalProps({
        contractFrom: props.companyServices.GlobalServices.IossService.ValidFrom,
        contractTo: props.companyServices.GlobalServices.IossService.ComplianceValidTo,
        subscriptionId: props.companyServices.GlobalServices.IossService.ComplianceSubscriptionId,
        cancelSubscription: props.callbackHandler.cancelSubscription
      });
      subCancellationModal.show();
    }
  })}
										  ` : ""}
								</div>
							</div>
						</div>
						${((_h = (_g = (_f = props.companyServices.GlobalServices) == null ? void 0 : _f.IossService) == null ? void 0 : _g.IOSSCertificate) == null ? void 0 : _h.CertificateReferenceId) || ((_k = (_j = (_i = props.companyServices.GlobalServices) == null ? void 0 : _i.IossService) == null ? void 0 : _j.IOSSCertificate) == null ? void 0 : _k.RegistrationNumber) || ((_n = (_m = (_l = props.companyServices.GlobalServices) == null ? void 0 : _l.IossService) == null ? void 0 : _m.IOSSCertificate) == null ? void 0 : _n.RegistrationDate) ? html`<div class="flex space-x-2 mt-4">
										<div>${i18next.t("IOSS Number")}:</div>
										<div>
											${(_q = (_p = (_o = props.companyServices.GlobalServices) == null ? void 0 : _o.IossService) == null ? void 0 : _p.IOSSCertificate) == null ? void 0 : _q.RegistrationNumber}
											(${formatDate((_t = (_s = (_r = props.companyServices.GlobalServices) == null ? void 0 : _r.IossService) == null ? void 0 : _s.IOSSCertificate) == null ? void 0 : _t.RegistrationDate)})
										</div>
										${((_w = (_v = (_u = props.companyServices.GlobalServices) == null ? void 0 : _u.IossService) == null ? void 0 : _v.IOSSCertificate) == null ? void 0 : _w.CertificateReferenceId) ? html`<div
													class="cursor-pointer"
													@click=${() => __async(void 0, null, function* () {
    yield props.callbackHandler.downloadIOSSCertificate(props.companyServices.GlobalServices.IossService.Id);
  })}
											  >
													${downloadPDFIcon()}
											  </div>` : ""}
									</div>
									<div class="mt-4">
										<span>${i18next.t("Country of registration")}: ${(_x = props.companyServices.GlobalServices.IossService.CountryOfRegistration) == null ? void 0 : _x.Code}</span>
									</div>
									${props.companyServices.GlobalServices.IossService.DeregistrationInfo ? html`<div class="flex space-x-2">
												<div>${i18next.t("Deregistered")}: (${formatDate(props.companyServices.GlobalServices.IossService.DeregistrationInfo.DeregisteredAt)})</div>
												${((_y = props.companyServices.GlobalServices.IossService.DeregistrationInfo) == null ? void 0 : _y.DocumentId) ? html` <div
															class="cursor-pointer"
															@click=${() => __async(void 0, null, function* () {
    yield props.callbackHandler.downloadIossDeregistrationDocument(props.companyServices.GlobalServices.IossService.Id);
  })}
													  >
															${downloadPDFIcon()}
													  </div>` : ""}
										  </div>` : ""}` : ""}
						${((_z = props.companyServices.GlobalServices.IossService.Documents) == null ? void 0 : _z.length) > 0 ? html`<div class="space-y-4">
									<div class="my-2">${i18next.t("Documents")}:</div>
									${props.companyServices.GlobalServices.IossService.Documents.some((d) => d.Category === "RequiredElectronicallyOnly") ? html`<div>
												<div class="text-sm">
													${i18next.t("Required electronically only")}:<dc-tooltip
														.label=${i18next.t(
    "Please download the document(s) listed here, print, have them signed by the authorized signatory, then scan and upload. We will review the document(s), you don't have to post them as we can proceed with the signed copies."
  )}
													></dc-tooltip>
												</div>
												<table class="w-full mt-2 mb-1">
													<tr class="border-b h-10">
														<th class="text-left">Name</th>
														<th>Generated</th>
														<th>Signed</th>
													</tr>
													${props.companyServices.GlobalServices.IossService.Documents.filter((d) => d.Category === "RequiredElectronicallyOnly").map(
    (document) => html`<tr class="border-b h-10">
																<td>
																	<div class="text-left">${document.Name}</div>
																</td>
																<td>
																	<div class="flex justify-around">
																		<div
																			class="cursor-pointer "
																			@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadIOSSRegistrationDocument(
        props.companyServices.GlobalServices.IossService.Id,
        document.Id,
        "Unsigned"
      );
    })}
																		>
																			${downloadPDFIcon()}
																		</div>
																	</div>
																</td>
																<td>
																	<div class="flex justify-around">
																		<div class="flex space-x-2 items-center">
																			<div>
																				${document.SignedDocumentReference ? html`<div
																							class="cursor-pointer"
																							@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadIOSSRegistrationDocument(
        props.companyServices.GlobalServices.IossService.Id,
        document.Id,
        "Signed"
      );
    })}
																					  >
																							${downloadPDFIcon()}
																					  </div>` : html``}
																			</div>
																			${props.companyServices.GlobalServices.IossService.RegistrationStatus === "WaitingForSignature" ? html`<div>
																						<button
																							class="btn btn-primary btn-sm cursor-pointer"
																							@click=${() => __async(void 0, null, function* () {
      setCurrentIOSSRegId(props.companyServices.GlobalServices.IossService.Id);
      setCurrentIOSSDocumentId(document.Id);
      setShowSignedIOSSDocumentUploadModal(true);
    })}
																						>
																							Upload
																						</button>
																				  </div>` : ""}
																		</div>
																	</div>
																</td>
															</tr>`
  )}
												</table>
										  </div>` : ""}
									${props.companyServices.GlobalServices.IossService.Documents.some((d) => d.Category === "ForInformationOnly") ? html`<div>
												<div class="text-sm">
													${i18next.t("For information only")}<dc-tooltip .label=${i18next.t("There is no action required on your side.")}></dc-tooltip>
												</div>
												<table class="w-full">
													<tr>
														<th>Name</th>
														<th>Generated</th>
													</tr>
													${props.companyServices.GlobalServices.IossService.Documents.filter((d) => d.Category === "ForInformationOnly").map(
    (document) => html`<tr class="border-b h-10">
																<td>
																	<div class="flex justify-around">${document.Name}</div>
																</td>
																<td>
																	<div class="flex justify-around">
																		<div
																			class="cursor-pointer "
																			@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadIOSSRegistrationDocument(
        props.companyServices.GlobalServices.IossService.Id,
        document.Id,
        "Unsigned"
      );
    })}
																		>
																			${downloadPDFIcon()}
																		</div>
																	</div>
																</td>
																<td></td>
															</tr>`
  )}
												</table>
										  </div>` : ""}
							  </div>` : ""}
						${(props == null ? void 0 : props.isAdmin) && ((_C = (_B = (_A = props.companyServices.GlobalServices) == null ? void 0 : _A.IossService) == null ? void 0 : _B.Errors) == null ? void 0 : _C.length) > 0 ? html`<div class="space-y-2 mt-4 text-red-500">
									<div>${i18next.t("Desucla Errors")}:</div>
									${(_F = (_E = (_D = props.companyServices.GlobalServices) == null ? void 0 : _D.IossService) == null ? void 0 : _E.Errors) == null ? void 0 : _F.map((e) => html`<div class="ml-4">${e}</div>`)}
							  </div>` : ""}
					</div>
			  ` : "";
  const htmlModals = html`${htmlSignedIOSSDocumentUploadModal()} ${commentModal.template()} ${iossModal.template} ${confirmModal.mainTemplate()} ${subCancellationModal.template()}
	${dateEditorModal.template()} ${deregistrationModal.template}`;
  return { mainTemplate, htmlModals };
};
