var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class PossibleStatusChange {
  constructor(_data) {
    /**
     */
    __publicField(this, "From");
    /**
     */
    __publicField(this, "To");
    /**
     */
    __publicField(this, "Type");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PossibleStatusChange
     */
    __publicField(this, "$type", "V1.Company.OssRegistration.PossibleStatusChange");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.From = PossibleStatusChangeFrom[_data["From"]];
      this.To = PossibleStatusChangeTo[_data["To"]];
      this.Type = PossibleStatusChangeType[_data["Type"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["From"] = PossibleStatusChangeFrom[this.From];
    _data["To"] = PossibleStatusChangeTo[this.To];
    _data["Type"] = PossibleStatusChangeType[this.Type];
    return _data;
  }
}
export var PossibleStatusChangeFrom = /* @__PURE__ */ ((PossibleStatusChangeFrom2) => {
  PossibleStatusChangeFrom2["Pending"] = "Pending";
  PossibleStatusChangeFrom2["WaitingForSignature"] = "WaitingForSignature";
  PossibleStatusChangeFrom2["DocumentsSigned"] = "DocumentsSigned";
  PossibleStatusChangeFrom2["SentToTA"] = "SentToTA";
  PossibleStatusChangeFrom2["RegistrationCompleted"] = "RegistrationCompleted";
  PossibleStatusChangeFrom2["Deregistered"] = "Deregistered";
  PossibleStatusChangeFrom2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return PossibleStatusChangeFrom2;
})(PossibleStatusChangeFrom || {});
export var PossibleStatusChangeTo = /* @__PURE__ */ ((PossibleStatusChangeTo2) => {
  PossibleStatusChangeTo2["Pending"] = "Pending";
  PossibleStatusChangeTo2["WaitingForSignature"] = "WaitingForSignature";
  PossibleStatusChangeTo2["DocumentsSigned"] = "DocumentsSigned";
  PossibleStatusChangeTo2["SentToTA"] = "SentToTA";
  PossibleStatusChangeTo2["RegistrationCompleted"] = "RegistrationCompleted";
  PossibleStatusChangeTo2["Deregistered"] = "Deregistered";
  PossibleStatusChangeTo2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return PossibleStatusChangeTo2;
})(PossibleStatusChangeTo || {});
export var PossibleStatusChangeType = /* @__PURE__ */ ((PossibleStatusChangeType2) => {
  PossibleStatusChangeType2["NewReg"] = "NewReg";
  PossibleStatusChangeType2["TakeOver"] = "TakeOver";
  PossibleStatusChangeType2["All"] = "All";
  return PossibleStatusChangeType2;
})(PossibleStatusChangeType || {});
