var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { DefaultVatRateType } from "./../models/V1/ComplianceMasterData/DefaultVatRateType";
import { VatRateTypeSetting } from "./../models/V1/ComplianceMasterData/VatRateTypeSetting";
import { SearchResult } from "./../models/V1/VatRateTypeSettings/Search/SearchResult";
export class VatRateTypes {
  /**
   * Creates an instance of VatRateTypes.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof VatRateTypes
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param CreateVATRateRq_ _createVATRateRq
   * @returns Promise<CreateVATRateRq_>
   * @memberof VatRateTypes
   */
  createVATRate(pCreateVATRateRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRateTypes/{countryCode}/vatrate", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "countryCode", value: pCreateVATRateRq.countryCode, required: true }]);
    client.addBody({ value: pCreateVATRateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportRq_ _exportRq
   * @returns Promise<ExportRq_>
   * @memberof VatRateTypes
   */
  export(pExportRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRateTypes/export", "POST", "application/json; charset=UTF-8", "text/csv, application/json", options);
    client.addBody({ value: pExportRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetDefaultVatRateTypesRq_ _getDefaultVatRateTypesRq
   * @returns Promise<GetDefaultVatRateTypesRq_>
   * @memberof VatRateTypes
   */
  getDefaultVatRateTypes(pGetDefaultVatRateTypesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRateTypes/{companyId}", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pGetDefaultVatRateTypesRq.companyId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: DefaultVatRateType, isArray: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SetDefaultVatRateTypesRq_ _setDefaultVatRateTypesRq
   * @returns Promise<SetDefaultVatRateTypesRq_>
   * @memberof VatRateTypes
   */
  setDefaultVatRateTypes(pSetDefaultVatRateTypesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRateTypes/{companyId}", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pSetDefaultVatRateTypesRq.companyId, required: true }]);
    client.addBody({ value: pSetDefaultVatRateTypesRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetVATRateTypeSettingsRq_ _getVATRateTypeSettingsRq
   * @returns Promise<GetVATRateTypeSettingsRq_>
   * @memberof VatRateTypes
   */
  getVATRateTypeSettings(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRateTypes", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: VatRateTypeSetting, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof VatRateTypes
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRateTypes/search", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
