var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../converters/DateConverter";
export class TemplateVersionDescription {
  constructor(_data) {
    /**
     */
    __publicField(this, "Disabled");
    /**
     */
    __publicField(this, "Draft");
    /**
     */
    __publicField(this, "ValidFrom");
    /**
     */
    __publicField(this, "Version");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TemplateVersionDescription
     */
    __publicField(this, "$type", "V1.TemplateMapping.TemplateVersionDescription");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Disabled = _data["Disabled"];
      this.Draft = _data["Draft"];
      this.ValidFrom = DateConverter.from(_data["ValidFrom"]);
      this.Version = _data["Version"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Disabled"] = this.Disabled;
    _data["Draft"] = this.Draft;
    _data["ValidFrom"] = DateConverter.toIsoUtcString(this.ValidFrom);
    _data["Version"] = this.Version;
    return _data;
  }
}
