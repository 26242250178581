import "./dc-checkbox.scss";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { component } from "haunted";
const DEFAULTS = {
  checked: false,
  label: "",
  readonly: false,
  labelnowrap: false
};
export class ChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, cancelable: true });
  }
}
export const Component = (host) => {
  const props = {
    checked: host.checked !== void 0 ? host.checked : DEFAULTS.checked,
    label: host.label !== void 0 ? host.label : DEFAULTS.label,
    labelnowrap: host.labelnowrap !== void 0 ? host.labelnowrap : DEFAULTS.labelnowrap,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly
  };
  const onClicked = (_e) => {
    if (!props.readonly) {
      if (host.dispatchEvent(
        new ChangeEvent({
          checked: !checked
        })
      )) {
        setChecked(!checked);
      }
    }
    return true;
  };
  const [checked, setChecked] = useState(false);
  const [componentId, setComponentId] = useState("");
  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);
  useEffect(() => {
    const id = `_${name}_${Math.random().toString(36).substr(2, 9)}`;
    setComponentId(id);
  }, []);
  return html`
		<input class="dc-checkbox" id="${componentId}" @click=${onClicked} ?checked=${checked} ?readonly=${props.readonly} />
		<label class="form-label ${props.labelnowrap ? "whitespace-nowrap" : ""}" for="${componentId}">${unsafeHTML(props.label)}</label>
	`;
};
customElements.define(
  "dc-checkbox",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
