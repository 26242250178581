var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class DefaultVatRateType {
  constructor(_data) {
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "Type");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof DefaultVatRateType
     */
    __publicField(this, "$type", "V1.ComplianceMasterData.DefaultVatRateType");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CountryCode = _data["CountryCode"];
      this.Type = DefaultVatRateTypeType[_data["Type"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CountryCode"] = this.CountryCode;
    _data["Type"] = DefaultVatRateTypeType[this.Type];
    return _data;
  }
}
export var DefaultVatRateTypeType = /* @__PURE__ */ ((DefaultVatRateTypeType2) => {
  DefaultVatRateTypeType2["Standard"] = "Standard";
  DefaultVatRateTypeType2["Standard2"] = "Standard2";
  DefaultVatRateTypeType2["Reduced"] = "Reduced";
  DefaultVatRateTypeType2["SuperReduced"] = "SuperReduced";
  DefaultVatRateTypeType2["SuperReduced2"] = "SuperReduced2";
  DefaultVatRateTypeType2["Exempt"] = "Exempt";
  DefaultVatRateTypeType2["ZeroRated"] = "ZeroRated";
  DefaultVatRateTypeType2["Middle"] = "Middle";
  DefaultVatRateTypeType2["Reduced2"] = "Reduced2";
  return DefaultVatRateTypeType2;
})(DefaultVatRateTypeType || {});
