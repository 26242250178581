var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { html, svg } from "lit-html";
import { useMemo, useState } from "GlobalShared/haunted/CustomHooks";
import { component, useEffect } from "haunted";
import { isEmpty, newUniqueId } from "GlobalShared/helpers/stringHelper";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "GlobalShared/common/Constants";
const DEFAULTS = {
  fieldName: "",
  sortable: false,
  filterable: false,
  columnType: "",
  filterDescriptor: void 0,
  label: "",
  columnTitle: void 0,
  orderBy: "",
  orderDir: "none",
  onSortClicked: void 0,
  onFilterChanged: void 0,
  appliedFilter: void 0
};
const svgFilter = (filterIsSet) => svg`<svg width="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M0.182812 2.57344C0.492187 1.91719 1.14844 1.5 1.875 1.5H22.125C22.8516 1.5 23.5078 1.91719 23.8172 2.57344C24.1266 3.22969 24.0328 4.00313 23.5734 4.56563L15 15.0422V21C15 21.5672 14.6812 22.0875 14.1703 22.3406C13.6594 22.5938 13.0547 22.5422 12.6 22.2L9.6 19.95C9.22031 19.6688 9 19.2234 9 18.75V15.0422L0.421874 4.56094C-0.0328133 4.00313 -0.131251 3.225 0.182812 2.57344Z"
fill="${filterIsSet ? SECONDARY_COLOR : PRIMARY_COLOR}"/>
</svg>`;
export const Component = (host) => {
  var _a, _b;
  const props = {
    fieldName: host.fieldName !== void 0 ? host.fieldName : DEFAULTS.fieldName,
    sortable: host.sortable !== void 0 ? host.sortable : DEFAULTS.sortable,
    filterable: host.filterable !== void 0 ? host.filterable : DEFAULTS.filterable,
    columnType: host.columnType !== void 0 ? host.columnType : DEFAULTS.columnType,
    filterDescriptor: host.filterDescriptor !== void 0 ? host.filterDescriptor : DEFAULTS.filterDescriptor,
    label: host.label !== void 0 ? host.label : DEFAULTS.label,
    columnTitle: host.columnTitle !== void 0 ? host.columnTitle : DEFAULTS.columnTitle,
    orderBy: host.orderBy !== void 0 ? host.orderBy : DEFAULTS.orderBy,
    orderDir: host.orderDir !== void 0 ? host.orderDir : DEFAULTS.orderDir,
    onSortClicked: host.onSortClicked !== void 0 ? host.onSortClicked : DEFAULTS.onSortClicked,
    onFilterChanged: host.onFilterChanged !== void 0 ? host.onFilterChanged : DEFAULTS.onFilterChanged,
    appliedFilter: host.appliedFilter !== void 0 ? host.appliedFilter : DEFAULTS.appliedFilter
  };
  const sortOrder = props.orderBy === props.fieldName ? props.orderDir : "none";
  const filterIsSet = () => {
    var _a2, _b2, _c;
    return ((_a2 = props.appliedFilter) == null ? void 0 : _a2.filterProps.max) !== void 0 || ((_b2 = props.appliedFilter) == null ? void 0 : _b2.filterProps.min) !== void 0 || ((_c = props.appliedFilter) == null ? void 0 : _c.filterProps.selectedValues) !== void 0;
  };
  const resetTempValues = () => {
    setTempStringValue(stringValue);
    setTempEnumValues(enumValues);
    setTempDateValue(dateValue);
    setTempNumberValue(numberValue);
  };
  const onEnumValuesChanged = (e) => {
    const newEnumValues = tempEnumValues != null ? tempEnumValues : [];
    if (newEnumValues.includes(e.detail.selectedValue)) {
      setTempEnumValues(newEnumValues.filter((v) => v !== e.detail.selectedValue));
    } else {
      setTempEnumValues([...newEnumValues, e.detail.selectedValue]);
    }
  };
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [stringValue, setStringValue] = useState(void 0);
  const [enumValues, setEnumValues] = useState([]);
  const [dateValue, setDateValue] = useState({ to: void 0, from: void 0 });
  const [numberValue, setNumberValue] = useState({ to: void 0, from: void 0 });
  const [tempStringValue, setTempStringValue] = useState(void 0);
  const [tempEnumValues, setTempEnumValues] = useState([]);
  const [tempDateValue, setTempDateValue] = useState({ to: void 0, from: void 0 });
  const [tempNumberValue, setTempNumberValue] = useState({ to: void 0, from: void 0 });
  const uniqueId = useMemo(() => newUniqueId(), []);
  useEffect(() => {
    const onClickedOutside = (e) => {
      if (!host.contains(e.target) && isSearchBoxOpen) {
        setIsSearchBoxOpen(false);
        resetTempValues();
      }
    };
    const onCloseOnEscKey = (e) => {
      if (e.key === "Escape" && isSearchBoxOpen) {
        setIsSearchBoxOpen(false);
        resetTempValues();
      }
    };
    document.addEventListener("keyup", onCloseOnEscKey, true);
    document.addEventListener("click", onClickedOutside);
    return () => {
      document.removeEventListener("keyup", onCloseOnEscKey);
      document.removeEventListener("click", onClickedOutside);
    };
  }, [isSearchBoxOpen]);
  useEffect(() => {
    var _a2, _b2, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o;
    if (props.filterDescriptor) {
      if (props.filterDescriptor.type === "string") {
        setTempStringValue((_a2 = props.appliedFilter) == null ? void 0 : _a2.filterProps.selectedValues);
        setStringValue((_b2 = props.appliedFilter) == null ? void 0 : _b2.filterProps.selectedValues);
      } else if (props.filterDescriptor.type === "date") {
        setTempDateValue({
          from: (_c = props.appliedFilter) == null ? void 0 : _c.filterProps.min,
          to: (_d = props.appliedFilter) == null ? void 0 : _d.filterProps.max
        });
        setDateValue({
          from: (_e = props.appliedFilter) == null ? void 0 : _e.filterProps.min,
          to: (_f = props.appliedFilter) == null ? void 0 : _f.filterProps.max
        });
      } else if (props.filterDescriptor.type === "select") {
        if (typeof ((_g = props.appliedFilter) == null ? void 0 : _g.filterProps.selectedValues) === "string") {
          setTempEnumValues([(_h = props.appliedFilter) == null ? void 0 : _h.filterProps.selectedValues]);
          setEnumValues([(_i = props.appliedFilter) == null ? void 0 : _i.filterProps.selectedValues]);
        } else {
          setTempEnumValues((_j = props.appliedFilter) == null ? void 0 : _j.filterProps.selectedValues);
          setEnumValues((_k = props.appliedFilter) == null ? void 0 : _k.filterProps.selectedValues);
        }
      } else if (props.filterDescriptor.type === "number") {
        setTempNumberValue({
          from: (_l = props.appliedFilter) == null ? void 0 : _l.filterProps.min,
          to: (_m = props.appliedFilter) == null ? void 0 : _m.filterProps.max
        });
        setNumberValue({
          from: (_n = props.appliedFilter) == null ? void 0 : _n.filterProps.min,
          to: (_o = props.appliedFilter) == null ? void 0 : _o.filterProps.max
        });
      }
    }
  }, [props.appliedFilter]);
  useEffect(() => {
    if (isSearchBoxOpen) {
      if (props.columnType === "string") {
        window.setTimeout(() => {
          const elem = document.querySelector(`#input_string_${uniqueId} input`);
          elem.focus();
        }, 0);
      }
    }
  }, [isSearchBoxOpen]);
  const templateButtonBar = (onFilter, onClear, isEmptyBB) => {
    return html`<div class="flex justify-between w-full">
			${isEmptyBB() ? html`<div></div>` : html`<div>
						<button
							class="btn btn-sm btn-primary"
							@click=${(e) => {
      setIsSearchBoxOpen(false);
      onClear(e);
    }}
						>
							Clear
						</button>
				  </div>`}

			<button
				class="btn btn-sm btn-primary"
				@click=${(e) => {
      setIsSearchBoxOpen(false);
      onFilter(e);
    }}
			>
				Filter
			</button>
		</div>`;
  };
  const templateStringSearchBox = () => {
    return html`
			<dc-input
				id=${`input_string_${uniqueId}`}
				@input=${(e) => {
      setTempStringValue(e.detail.value);
    }}
				.value=${tempStringValue}
				@keyup=${(e) => {
      if (e.key === "Enter") {
        setIsSearchBoxOpen(false);
        e.preventDefault();
        props.onFilterChanged(props.fieldName, { operator: "eq", selectedValues: tempStringValue }, e);
      }
    }}
			></dc-input>
			${templateButtonBar(
      (e) => props.onFilterChanged(
        props.fieldName,
        {
          operator: "eq",
          selectedValues: tempStringValue === "" ? void 0 : tempStringValue
        },
        e
      ),
      (e) => {
        props.onFilterChanged(props.fieldName, { operator: "eq", selectedValues: void 0 }, e);
      },
      () => isEmpty(stringValue)
    )}
		`;
  };
  const templateEnumSearchBox = () => {
    const selectFilterDescriptor = props.filterDescriptor;
    selectFilterDescriptor.multiSelect = selectFilterDescriptor.multiSelect === void 0 ? false : selectFilterDescriptor.multiSelect;
    selectFilterDescriptor.searchable = selectFilterDescriptor.searchable === void 0 ? false : selectFilterDescriptor.searchable;
    if (!selectFilterDescriptor.multiSelect) {
      return html`
				<div class="mt-4">
					<dc-select
						.dataSource=${selectFilterDescriptor.dataSource}
						.filterable=${selectFilterDescriptor.searchable}
						.filterProps=${selectFilterDescriptor.searchProps}
						.selectedValues=${tempEnumValues}
						@change=${(e) => {
        if (e.detail !== void 0) {
          setTempEnumValues([e.detail.selectedValue]);
        }
      }}
					></dc-select>
					${templateButtonBar(
        (e) => props.onFilterChanged(props.fieldName, { operator: "eq", selectedValues: (tempEnumValues == null ? void 0 : tempEnumValues.length) > 0 ? tempEnumValues[0] : void 0 }, e),
        (e) => {
          props.onFilterChanged(props.fieldName, { operator: "eq", selectedValues: void 0 }, e);
        },
        () => enumValues === void 0 || enumValues.length === 0
      )}
				</div>
			`;
    } else {
      return html`
				<div class="mt-4">
					<dc-select
						class="w-32"
						.dataSource=${selectFilterDescriptor.dataSource}
						.selectedValues=${tempEnumValues}
						.multiselect=${true}
						.filterable=${selectFilterDescriptor.searchable}
						.filterProps=${selectFilterDescriptor.searchProps}
						@change=${onEnumValuesChanged}
					></dc-select>
					${templateButtonBar(
        (e) => props.onFilterChanged(props.fieldName, { operator: "eq", selectedValues: tempEnumValues }, e),
        (e) => {
          props.onFilterChanged(props.fieldName, { operator: "eq", selectedValues: void 0 }, e);
        },
        () => enumValues === void 0 || enumValues.length === 0
      )}
				</div>
			`;
    }
  };
  const templateDateSearchBox = () => {
    return html`
			<dc-datepicker
				.value=${tempDateValue.from}
				.label=${"From"}
				@change=${(e) => {
      setTempDateValue(__spreadProps(__spreadValues({}, tempDateValue), { from: e.detail.value }));
    }}
			></dc-datepicker>
			<dc-datepicker
				class="mb-1"
				.value=${tempDateValue.to}
				.label=${"To"}
				@change=${(e) => {
      setTempDateValue(__spreadProps(__spreadValues({}, tempDateValue), { to: e.detail.value }));
    }}
			></dc-datepicker>
			${templateButtonBar(
      (e) => props.onFilterChanged(
        props.fieldName,
        {
          operator: "eq",
          min: tempDateValue.from,
          max: tempDateValue.to
        },
        e
      ),
      (e) => {
        props.onFilterChanged(props.fieldName, { operator: "eq", min: void 0, max: void 0 }, e);
      },
      () => dateValue.from === void 0 && dateValue.to === void 0
    )}
		`;
  };
  const templateNumberSearchBox = () => {
    return html`
			<dc-input
				.value=${tempNumberValue.from}
				.label=${"From"}
				.type=${"number"}
				@change=${(e) => {
      setTempNumberValue(__spreadProps(__spreadValues({}, tempNumberValue), { from: Number(e.detail.value) }));
    }}
			></dc-input>
			<dc-input
				class="mb-1"
				.value=${tempNumberValue.to}
				.label=${"To"}
				@change=${(e) => {
      setTempNumberValue(__spreadProps(__spreadValues({}, tempNumberValue), { to: Number(e.detail.value) }));
    }}
			></dc-input>
			${templateButtonBar(
      (e) => props.onFilterChanged(
        props.fieldName,
        {
          operator: "eq",
          min: tempNumberValue.from,
          max: tempNumberValue.to
        },
        e
      ),
      (e) => {
        props.onFilterChanged(props.fieldName, { operator: "eq", min: void 0, max: void 0 }, e);
      },
      () => numberValue.from === void 0 && numberValue.to === void 0
    )}
		`;
  };
  const searchBoxTemplate = () => {
    var _a2;
    let innerTemplate;
    if (props.filterDescriptor.type === "string" && props.columnType === "string") {
      innerTemplate = templateStringSearchBox;
    } else if (props.filterDescriptor.type === "select" && props.columnType === "enum") {
      innerTemplate = templateEnumSearchBox;
    } else if (props.filterDescriptor.type === "date" && props.columnType === "date") {
      innerTemplate = templateDateSearchBox;
    } else if (props.filterDescriptor.type === "number" && props.columnType === "number") {
      innerTemplate = templateNumberSearchBox;
    } else {
      throw new Error(`Unknown Filter Descriptor!`);
    }
    const isFilterAlignedRight = ((_a2 = props.filterDescriptor) == null ? void 0 : _a2.rightAlign) === void 0 ? false : props.filterDescriptor.rightAlign;
    if (isSearchBoxOpen) {
      return html`
				<div
					class="absolute top-0 ${isFilterAlignedRight ? "right-0" : "left-0"} bg-white z-40 p-2 border-solid border-2 border-gray-400 font-normal rounded-md"
					style="min-width: 150px"
					@click=${(e) => e.stopPropagation()}
				>
					${innerTemplate()}
				</div>
			`;
    } else {
      return "";
    }
  };
  return html`
		<div class="flex justify-between">
			${props.sortable ? html`<div
						class="hover:text-gray-500 cursor-pointer whitespace-nowrap overflow-hidden"
						@click=${(e) => {
    props.onSortClicked(props.fieldName, sortOrder === "none" || sortOrder === "desc" ? "asc" : "desc", e);
  }}
				  >
						<div class="flex">
							<div>
								${sortOrder !== "none" ? html`<div class="px-1 transform ${sortOrder === "asc" ? "-rotate-90" : "rotate-90"}">
											<div class="text-brand-primary">&#10095;</div>
									  </div>` : ""}
							</div>
							<div title=${(_a = props.columnTitle) != null ? _a : props.label}>${props.label}</div>
						</div>
				  </div>` : html`<div title=${(_b = props.columnTitle) != null ? _b : props.label} class="whitespace-nowrap overflow-hidden">${props.label}</div>`}
			${props.filterable && props.filterDescriptor ? html`<div class="relative cursor-pointer hover:text-gray-500">
						<div
							@click=${() => {
    setIsSearchBoxOpen(!isSearchBoxOpen);
  }}
						>
							<div class="pt-1 pl-1">${svgFilter(filterIsSet())}</div>
						</div>
						${searchBoxTemplate()}
				  </div>` : ""}
		</div>
	`;
};
customElements.define(
  "dc-table-header",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: ["appliedFilter"]
  })
);
