import { mapMoney } from "GlobalShared/mappers/MoneyMapper";
import i18next from "i18next";
export const SERVICE_DESCRIPTOR_METAS = [
  {
    Code: "VatCompliance",
    Label: i18next.t("VAT compliance")
  },
  {
    Code: "VatHomeCountryCompliance",
    Label: i18next.t("VAT home country compliance")
  },
  {
    Code: "VatMonthlyCompliance",
    Label: i18next.t("VAT monthly compliance")
  },
  {
    Code: "VatRegistration",
    Label: i18next.t("VAT registration")
  },
  {
    Code: "VatTakeOver",
    Label: i18next.t("VAT take over")
  },
  {
    Code: "VatSpanishBankAccount",
    Label: i18next.t("Spanish VAT Payment Service"),
    Tooltip: i18next.t("order-services-vat-spanish-bank-account-tooltip")
  },
  {
    Code: "VatFiscalRepresentative",
    Label: i18next.t("Fiscal representation"),
    Tooltip: i18next.t("When operating in a country where fiscal representation is required, you must appoint the Fiscal Representative provided by Tax Desk.")
  },
  {
    Code: "VatFilingAgent",
    Label: i18next.t("Filing agent")
  },
  {
    Code: "VatRetrospectiveReturn",
    Label: i18next.t("Retrospective return"),
    Tooltip: i18next.t(
      "The calculation of the retrospective periods is crucial for preparation of the registration documents in Italy. You must provide Tax Desk your transaction data covering past reporting periods as soon as possible. Without them the Italian VAT registration process might be delayed."
    )
  },
  {
    Code: "VatIntrastatIn",
    Label: i18next.t("Intrastat in")
  },
  {
    Code: "VatIntrastatOut",
    Label: i18next.t("Intrastat out")
  },
  {
    Code: "VatDeregistration",
    Label: i18next.t("VAT deregistration")
  },
  {
    Code: "IossComplianceTier0",
    Label: i18next.t("IOSS Compliance Pay As You Ship")
  },
  {
    Code: "IossComplianceTier1",
    Label: i18next.t("IOSS Compliance Tier 1")
  },
  {
    Code: "IossComplianceTier2",
    Label: i18next.t("IOSS Compliance Tier 2")
  },
  {
    Code: "IossComplianceTier3",
    Label: i18next.t("IOSS Compliance Tier 3")
  },
  {
    Code: "IossRegistration",
    Label: i18next.t("IOSS registration")
  },
  {
    Code: "IossTakeOver",
    Label: i18next.t("IOSS take over")
  },
  {
    Code: "IossDeregistration",
    Label: i18next.t("IOSS deregistration")
  },
  {
    Code: "OssCompliance",
    Label: i18next.t("OSS compliance")
  },
  {
    Code: "OssRegistration",
    Label: i18next.t("OSS registration")
  },
  {
    Code: "OssTakeOver",
    Label: i18next.t("OSS take over")
  },
  {
    Code: "OssDeregistration",
    Label: i18next.t("OSS deregistration")
  },
  {
    Code: "GlobalEoriEu",
    Label: i18next.t("EORI number application in the EU")
  },
  {
    Code: "GlobalEoriUk",
    Label: i18next.t("EORI number application in the UK")
  },
  {
    Code: "VatSelfRevision",
    Label: i18next.t("Self Revision / Retrospective Returns")
  },
  {
    Code: "GlobalGeneralVatAdvice",
    Label: i18next.t("General VAT advice")
  },
  {
    Code: "GlobalSpecialistVatAdvice",
    Label: i18next.t("Specialist VAT advice")
  },
  {
    Code: "GlobalCommunicatingWithTaxAuthorities",
    Label: i18next.t("Communicating with tax authorities")
  },
  {
    Code: "GlobalChangeCompanyDetail",
    Label: i18next.t("Change company detail")
  },
  {
    Code: "GlobalContactingTaxAuthorities",
    Label: i18next.t("Contacting tax authorities")
  },
  {
    Code: "GlobalTranslation",
    Label: i18next.t("Translation")
  },
  {
    Code: "GlobalTaxCertificate",
    Label: i18next.t("Tax certificate")
  },
  {
    Code: "GlobalIntrastatThresholdCheck",
    Label: i18next.t("Intrastat threshold check")
  },
  {
    Code: "GlobalDistanceSaleThresholdCheck",
    Label: i18next.t("Distance sale threshold check")
  }
];
export const mapServiceDescriptor = (descriptor) => {
  const vm = {
    Code: descriptor.Code,
    Country: descriptor.Country,
    Price: mapMoney(descriptor.Price)
  };
  return vm;
};
