import { PayeeValidationErrorGroup } from "WebServices/APIs/self/models/V1/Cesop/Periods/Validation/Source/Payee";
import { PayeeValidationErrorGroupColumn } from "WebServices/APIs/self/models/V1/Cesop/Periods/Validation/Source/Payee/PayeeValidationErrorGroupAbstraction";
import { TransactionValidationErrorGroup } from "WebServices/APIs/self/models/V1/Cesop/Periods/Validation/Source/Transaction";
import { TransactionValidationErrorGroupColumn } from "WebServices/APIs/self/models/V1/Cesop/Periods/Validation/Source/Transaction/TransactionValidationErrorGroupAbstraction";
export const formatValidationError = (error) => {
  if (error instanceof PayeeValidationErrorGroup) {
    return PAYEE_MESSAGE_MAPPING[error.Code](error);
  }
  if (error instanceof TransactionValidationErrorGroup) {
    return TRANSACTION_MESSAGE_MAPPING[error.Code](error);
  }
  throw new Error("Unknown validation error type");
};
const PAYEE_COLUMN_MAPPING = {
  PayeeAccountId: "Payee Account ID",
  NameType: "Name Type",
  NameValue: "Name Value",
  BusinessCountry: "Business Country",
  LegalAddressType: "Legal Address Type",
  AddressCountryCode: "Address Country Code",
  Street: "Street",
  BuildingIdentifier: "Building Identifier",
  DistrictName: "District Name",
  PostCode: "Post Code",
  City: "City",
  EmailAddress: "Email Address",
  WebPage: "Web Page",
  VatIdIssuedBy: "VAT ID Issued By",
  VatIdValue: "VAT ID Value",
  TaxIdIssuedBy: "TAX Id Issued By",
  TaxIdType: "TAX ID Type",
  TaxIdValue: "TAX ID Value",
  AccountIdentifierCountryCode: "Account Identifier Country Code",
  AccountIdentifierType: "Account Identifier Type",
  AccountIdentifierValue: "Account Identifier Value",
  RepresentativePspIdType: "Representative PSP Id Type",
  RepresentativePspValue: "Representative PSP Value",
  RepresentativeNameType: "Representative Name Type",
  RepresentativeValue: "Representative Value",
  PayeeLocation: "Payee Location",
  Action: "Action",
  Issue: "Issue",
  MessageId: "MessageId",
  DocRefId: "DocRefId"
};
const PAYEE_MESSAGE_MAPPING = {
  MigratedError: (_error) => `The file is incorrect, but the validation process changed. Please upload the file again to see the validation problems.`,
  MissingData: (error) => {
    switch (error.Column) {
      case PayeeValidationErrorGroupColumn.VatIdIssuedBy:
      case PayeeValidationErrorGroupColumn.VatIdValue:
        return `${PAYEE_COLUMN_MAPPING[error.Column]} must not be empty if VAT ID is provided in line ${error.LineIndex}`;
      case PayeeValidationErrorGroupColumn.TaxIdType:
      case PayeeValidationErrorGroupColumn.TaxIdIssuedBy:
      case PayeeValidationErrorGroupColumn.TaxIdValue:
        return `${PAYEE_COLUMN_MAPPING[error.Column]} must not be empty if TAX ID is provided in line ${error.LineIndex}`;
      case PayeeValidationErrorGroupColumn.AccountIdentifierCountryCode:
      case PayeeValidationErrorGroupColumn.AccountIdentifierType:
      case PayeeValidationErrorGroupColumn.AccountIdentifierValue:
        return `${PAYEE_COLUMN_MAPPING[error.Column]} must not be empty if Account Identifier is provided in line ${error.LineIndex}`;
      case PayeeValidationErrorGroupColumn.RepresentativePspIdType:
      case PayeeValidationErrorGroupColumn.RepresentativeNameType:
      case PayeeValidationErrorGroupColumn.RepresentativePspValue:
      case PayeeValidationErrorGroupColumn.RepresentativeValue:
        return `${PAYEE_COLUMN_MAPPING[error.Column]} must not be empty if Representative is provided in line ${error.LineIndex}`;
      default:
        return `${PAYEE_COLUMN_MAPPING[error.Column]} is mandatory in line ${error.LineIndex}`;
    }
  },
  InvalidEnumValue: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid value in line ${error.LineIndex}. Possible values are: ${error.Args.join(", ")}`,
  InvalidCountryCode: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid country code in line ${error.LineIndex}`,
  InvalidEmail: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid email in line ${error.LineIndex}`,
  InvalidVatId: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid VAT ID in line ${error.LineIndex}`,
  InvalidTaxId: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid Tax ID in line ${error.LineIndex}`,
  TooLong: (error) => `Value is too long in ${PAYEE_COLUMN_MAPPING[error.Column]} in line ${error.LineIndex}. Maximum length is ${error.Args[0]}`,
  DuplicatedValue: (error) => `Duplicated value found in ${PAYEE_COLUMN_MAPPING[error.Column]} in line ${error.LineIndex}. Value: ${error.Args[0]}`,
  AccountIdAndRepresentativeProvided: (error) => `Account Identifier and Representative PSP Identifier cannot be provided at the same time in line ${error.LineIndex}`,
  AccountIdAndRepresentativeMissing: (error) => `Account Identifier or Representative PSP Identifier are mandatory in line ${error.LineIndex}`,
  InvalidSeparator: (_error) => `Invalid separator. Expected separator: ,`,
  InvalidHeader: (error) => `Incorrect uploaded template header, please use the following headers: ${error.Args[0]}`,
  UnprocessableFile: (error) => `Technical Error: ${error.Args[0]}`,
  InvalidColumnCount: (error) => `Invalid column count. Expected: ${error.Args[0]}. Actual: ${error.Args[1]}`,
  InvalidIban: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid IBAN number in line ${error.LineIndex}`,
  InvalidGuid: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid UUID in line ${error.LineIndex}`,
  InvalidMessageId: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid Message ID in line ${error.LineIndex}`,
  InvalidDocRefId: (error) => `${PAYEE_COLUMN_MAPPING[error.Column]} has an invalid Doc Ref ID in line ${error.LineIndex}`
};
const TRANSACTION_COLUMN_MAPPING = {
  PayeeAccountId: "Payee Account Id",
  TransactionIdentifier: "Transaction Identifier",
  IsRefund: "Is Refund",
  CorrelatingTransactionIdentifier: "Correlating Transaction Identifier",
  TransactionDateType: "Transaction Date Type",
  TransactionDateValue: "Transaction Date Value",
  Currency: "Currency",
  Amount: "Amount",
  PaymentMethodType: "Payment Method Type",
  PaymentMethodOther: "Payment Method Other",
  InitiatedAtPhysicalPremisesOfMerchant: "Initiated At Physical Premises Of Merchant",
  PayerMsSource: "Payer MS Source",
  PayerMsCountryCode: "Payer MS Country Code",
  PspRoleType: "PSP Role Type",
  PspRoleOther: "PSP Role Other",
  Action: "Action",
  Issue: "Issue",
  MessageId: "MessageId",
  DocRefId: "DocRefId"
};
const TRANSACTION_MESSAGE_MAPPING = {
  MigratedError: (_error) => `The file is incorrect, but the validation process changed. Please upload the file again to see the validation problems.`,
  MissingData: (error) => {
    switch (error.Column) {
      case TransactionValidationErrorGroupColumn.CorrelatingTransactionIdentifier:
        return `Transaction reference is mandatory for refund transactions. Error in line ${error.LineIndex}`;
      case TransactionValidationErrorGroupColumn.PspRoleOther:
        return `Must not be empty if PSP Role Type is Other. Error in line ${error.LineIndex}`;
      case TransactionValidationErrorGroupColumn.PaymentMethodOther:
        return `Must not be empty if Payment Method Type is Other. Error in line ${error.LineIndex}`;
      default:
        return `${TRANSACTION_COLUMN_MAPPING[error.Column]} is mandatory in line ${error.LineIndex}`;
    }
  },
  InvalidPayeeIdentifier: (error) => `Payee reference is missing, please update payee list and resubmit transactions. Error in line ${error.LineIndex}`,
  InvalidBooleanValue: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} contains incorrect value in line ${error.LineIndex}. Value must be any of the followings: ${error.Args[0]}`,
  InvalidEnumValue: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} has an invalid value in line ${error.LineIndex}. Possible values are: ${error.Args.join(", ")}`,
  InvalidCurrencyCode: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} has an invalid currency code in line ${error.LineIndex}`,
  InvalidCountryCode: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} has an invalid country code in line ${error.LineIndex}`,
  InvalidDecimalValue: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} contains incorrect value in line ${error.LineIndex}. Value can only contain numbers and a delimitter.`,
  InvalidDateTimeValue: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} contains incorrect value in line ${error.LineIndex}. Format must be: yyyy-MM-ddTHH:mm:ss.fffZ or yyyy-MM-ddTHH:mm:ss.fff+HH:mm or yyyy-MM-ddTHH:mm:ss.fff-HH:mm (the millisecond part is not mandatory)`,
  TooLong: (error) => `Value is too long in ${TRANSACTION_COLUMN_MAPPING[error.Column]} in line ${error.LineIndex}. Maximum length is ${error.Args[0]}`,
  DuplicatedValue: (error) => `Duplicated value found in ${TRANSACTION_COLUMN_MAPPING[error.Column]} in line ${error.LineIndex}. Value: ${error.Args[0]}`,
  ZeroValue: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} contains incorrect value in line ${error.LineIndex}. Value cannot be zero.`,
  InvalidDateOutOfQuarter: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} must be within the reporting period in line ${error.LineIndex}`,
  InvalidSeparator: (error) => `Invalid separator. Expected separator: ${error.Args[0]}`,
  InvalidHeader: (error) => `Incorrect uploaded template header, please use the following headers: ${error.Args[0]}`,
  UnprocessableFile: (error) => `Technical Error: ${error.Args[0]}`,
  InvalidColumnCount: (error) => `Invalid column count. Expected: ${error.Args[0]}. Actual: ${error.Args[1]}`,
  InvalidXmlFile: (error) => `Invalid XML file. Error: ${error.Args[0]}`,
  DecimalIsNotNegative: (error) => `Amount must be negative for refund transactions. Error in line ${error.LineIndex}`,
  InvalidGuid: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} has an invalid UUID in line ${error.LineIndex}`,
  InvalidMessageId: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} has an invalid Message ID in line ${error.LineIndex}`,
  InvalidDocRefId: (error) => `${TRANSACTION_COLUMN_MAPPING[error.Column]} has an invalid Doc Ref ID in line ${error.LineIndex}`,
  DuplicatedMessageId: (error) => `The Message ID (${error.Args[0]}) is already registered in the Database`,
  TooLargeFile: (error) => `The file is too large. The limitation is maximum ${error.Args.join(" and maximum ")}`,
  NonCrossBorderTransaction: (error) => `The ${PAYEE_COLUMN_MAPPING[PayeeValidationErrorGroupColumn.BusinessCountry]} of the Payee is the same as the ${TRANSACTION_COLUMN_MAPPING[TransactionValidationErrorGroupColumn.PayerMsCountryCode]} in line ${error.LineIndex}, but the transaction should be a cross border transaction`,
  DecimalIsNotPositive: (error) => `Amount must be positive for non-refund transactions. Error in line ${error.LineIndex}`
};
