var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { closeIcon, fileIcon } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { useEffect, useQueryState, useState } from "GlobalShared/haunted/CustomHooks";
import classNames from "classnames";
import {
  mapPairsToCustomSourceMapping
} from "./CustomDataTemplateModels";
import { FormatTypeMapping, TAX_DESK_DATA_LABELS } from "./CustomDataTemplateHelper";
import { useSharedCustomDataTemplate } from "./useSharedCustomDataTemplates";
const TAB_NAMES = {
  Sale: "Sale",
  Purchase: "Purchase"
};
export const TAB_QUERY_PREFIX = "tab";
export function useSeparatedFileConfiguration(props) {
  var _a;
  const isFieldRequired = (fieldName) => {
    var _a2, _b;
    return selectedTab === "Sale" ? (_a2 = fieldMetadata.saleFieldMetadata.find((m) => m.Name === fieldName)) == null ? void 0 : _a2.IsRequired : (_b = fieldMetadata.purchaseFieldMetadata.find((m) => m.Name === fieldName)) == null ? void 0 : _b.IsRequired;
  };
  const isEveryRequiredDropFieldSet = (pairs) => !Array.from(pairs.keys()).some((key) => isFieldRequired(key) && !pairs.get(key));
  const saveCurrentMapping = () => {
    var _a2, _b, _c, _d;
    setIsValidated(true);
    if (!isEveryRequiredDropFieldSet(currentPairs))
      return;
    const mapping = mapPairsToCustomSourceMapping(currentPairs);
    if (selectedTab === "Sale") {
      props.updateCustomSourceMapping(mapping, props.loader, "Sale");
      if ((_b = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CustomSourceConfiguration) == null ? void 0 : _b.PurchaseMapping) {
        setIsBothMappingsSavedSuccessfully(true);
      }
    } else {
      props.updateCustomSourceMapping(mapping, props.loader, "Purchase");
      if ((_d = (_c = props.generationConfiguration) == null ? void 0 : _c.CustomSourceConfiguration) == null ? void 0 : _d.SaleMapping) {
        setIsBothMappingsSavedSuccessfully(true);
      }
    }
    setIsMappingChangedAfterSave(false);
    props.getAndSetGenerationConfiguration();
    if (selectedTab === "Sale" && isEveryRequiredDropFieldSet(purchasePairs) || selectedTab === "Purchase" && isEveryRequiredDropFieldSet(salePairs))
      return;
    changeTabAfterMappingSave();
  };
  const handleUploadTransactionDataButtonClick = (e) => __async(this, null, function* () {
    setIsFileUploading(true);
    yield props.updateTemplateReference(selectedTab === "Sale" ? "Sale" : "Purchase", e.detail.files[0]);
    setUploadedFile(e.detail.files[0]);
    setIsFileUploading(false);
    if (selectedTab === "Sale") {
      setSalePairs(new Map(fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
      setCurrentPairs(new Map(fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
    } else {
      setPurchasePairs(new Map(fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
      setCurrentPairs(new Map(fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
    }
  });
  const getMappedPairs = (mapping, tab) => {
    var _a2;
    if (!mapping && tab === "Sale")
      return new Map(fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0]));
    if (!mapping && tab === "Purchase")
      return new Map(fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0]));
    const newPairs = /* @__PURE__ */ new Map();
    const dropfieldNames = tab === "Sale" ? fieldMetadata.saleFieldMetadata.map((field) => field.Name) : fieldMetadata.purchaseFieldMetadata.map((field) => field.Name);
    for (const key in mapping) {
      if (!dropfieldNames.includes(key))
        continue;
      const field = mapping[key];
      const dragField = dragFields == null ? void 0 : dragFields.find((f) => f.id === (field == null ? void 0 : field.Source));
      const fallBackField = ((_a2 = field == null ? void 0 : field.FallbackSources) == null ? void 0 : _a2.length) > 0 ? dragFields == null ? void 0 : dragFields.find((f) => f.id === (field == null ? void 0 : field.Source)) : void 0;
      newPairs.set(key, { dragField, fallBackField });
    }
    return newPairs;
  };
  const mapAndSetDragFields = (newSelectedTab) => {
    var _a2, _b, _c, _d, _e, _f;
    const fields = newSelectedTab === "Sale" ? (_c = (_b = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.TemplateFileReference) == null ? void 0 : _b.SeparateTransactionData) == null ? void 0 : _c.SaleFields : (_f = (_e = (_d = props.generationConfiguration) == null ? void 0 : _d.TemplateFileReference) == null ? void 0 : _e.SeparateTransactionData) == null ? void 0 : _f.PurchaseFields;
    setDragFields(
      fields == null ? void 0 : fields.map((field) => ({
        id: field.Index,
        label: field.Name,
        info: field.Name,
        FieldType: "String",
        type: "TemplateDataSource"
      }))
    );
  };
  const changeTabAfterMappingSave = () => {
    window.scrollTo(0, 0);
    setIsValidated(false);
    if (selectedTab === "Sale") {
      setSalePairs(currentPairs);
      setCurrentPairs(purchasePairs);
      mapAndSetDragFields("Purchase");
      setSelectedTab("Purchase");
      return;
    }
    setPurchasePairs(currentPairs);
    setCurrentPairs(salePairs);
    mapAndSetDragFields("Sale");
    setSelectedTab("Sale");
  };
  const onTabSelect = (e) => {
    if (selectedTab === e.detail.selectedTab)
      return;
    setIsValidated(false);
    if (e.detail.selectedTab === "Sale") {
      setPurchasePairs(currentPairs);
      setCurrentPairs(salePairs);
    } else {
      setSalePairs(currentPairs);
      setCurrentPairs(purchasePairs);
    }
    mapAndSetDragFields(e.detail.selectedTab);
    setSelectedTab(e.detail.selectedTab);
  };
  const hasEmptyRequiredFields = () => !isEveryRequiredDropFieldSet(currentPairs) || selectedTab === "Sale" && !isEveryRequiredDropFieldSet(purchasePairs) || selectedTab === "Purchase" && !isEveryRequiredDropFieldSet(salePairs);
  const [selectedTab, setSelectedTab] = useQueryState(TAB_QUERY_PREFIX, "Sale");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [fieldMetadata, setFieldMetadata] = useState(void 0);
  const [dragFields, setDragFields] = useState(void 0);
  const [isValidated, setIsValidated] = useState(false);
  const [currentPairs, setCurrentPairs] = useState(/* @__PURE__ */ new Map());
  const [salePairs, setSalePairs] = useState(/* @__PURE__ */ new Map());
  const [purchasePairs, setPurchasePairs] = useState(/* @__PURE__ */ new Map());
  const [isMappingChangedAfterSave, setIsMappingChangedAfterSave] = useState(false);
  const [isBothMappingsSavedSuccessfully, setIsBothMappingsSavedSuccessfully] = useState(false);
  const sharedCustomDataTemplate = useSharedCustomDataTemplate();
  useEffect(() => __async(this, null, function* () {
    var _a2, _b, _c, _d;
    if (props.currentStep === 1 && ((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately) && ((_c = (_b = props.generationConfiguration) == null ? void 0 : _b.TemplateFileReference) == null ? void 0 : _c.SeparateTransactionData)) {
      mapAndSetDragFields(selectedTab);
      setIsValidated(false);
    }
    if (props.currentStep === 1 && props.generationConfiguration && ((_d = props.generationConfiguration) == null ? void 0 : _d.SalePurchaseSeparately)) {
      const saleFieldMetadata = yield props.getMetaDataConfiguration(props.loader, "Sale");
      const purchaseFieldMetadata = yield props.getMetaDataConfiguration(props.loader, "Purchase");
      setFieldMetadata({ saleFieldMetadata, purchaseFieldMetadata });
    }
  }), [props.generationConfiguration]);
  useEffect(() => {
    var _a2, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
    if (props.currentStep === 1 && ((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately)) {
      if (((_b = props.generationConfiguration) == null ? void 0 : _b.CustomSourceConfiguration) && fieldMetadata && dragFields && !salePairs.size && !purchasePairs.size) {
        const saleMappings = getMappedPairs((_d = (_c = props.generationConfiguration) == null ? void 0 : _c.CustomSourceConfiguration) == null ? void 0 : _d.SaleMapping, "Sale");
        const purchaseMappings = getMappedPairs((_f = (_e = props.generationConfiguration) == null ? void 0 : _e.CustomSourceConfiguration) == null ? void 0 : _f.PurchaseMapping, "Purchase");
        if (selectedTab === "Sale") {
          setCurrentPairs(saleMappings);
          setSalePairs(saleMappings);
          setPurchasePairs(purchaseMappings);
        } else if (selectedTab === "Purchase") {
          setCurrentPairs(purchaseMappings);
          setSalePairs(saleMappings);
          setPurchasePairs(purchaseMappings);
        }
      } else if (fieldMetadata && !((_g = props.generationConfiguration) == null ? void 0 : _g.CustomSourceConfiguration)) {
        if (!salePairs.size || !((_j = (_i = (_h = props.generationConfiguration) == null ? void 0 : _h.TemplateFileReference) == null ? void 0 : _i.SeparateTransactionData) == null ? void 0 : _j.SaleFields)) {
          setSalePairs(new Map(fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
        }
        if (!purchasePairs.size || !((_m = (_l = (_k = props.generationConfiguration) == null ? void 0 : _k.TemplateFileReference) == null ? void 0 : _l.SeparateTransactionData) == null ? void 0 : _m.PurchaseFields)) {
          setPurchasePairs(new Map(fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
        }
        if (selectedTab === "Sale" && !salePairs.size) {
          setCurrentPairs(new Map(fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
        } else if (selectedTab === "Purchase" && !purchasePairs.size) {
          setCurrentPairs(new Map(fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
        }
      }
    }
  }, [dragFields, fieldMetadata]);
  useEffect(() => {
    var _a2;
    if (props.currentStep === 1 && !((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately)) {
      setCurrentPairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
    }
  }, [(_a = props.generationConfiguration) == null ? void 0 : _a.SalePurchaseSeparately]);
  const tabsTemplate = () => html`
		<dc-tabs
			.customClass=${"lg:text-xl"}
			.data=${[
    {
      name: TAB_NAMES.Sale,
      label: i18next.t("Sale"),
      template: html` <div class="mt-6">${dataMappingTemplate()}</div> `
    },
    {
      name: TAB_NAMES.Purchase,
      label: i18next.t("Purchase"),
      template: html` <div class="mt-6">${dataMappingTemplate()}</div> `
    }
  ]}
			.selectedTab=${selectedTab}
			@tabSelected=${(e) => onTabSelect(e)}
		></dc-tabs>
	`;
  const fallBackFieldTemplate = (dropFieldName) => {
    var _a2;
    return ((_a2 = currentPairs == null ? void 0 : currentPairs.get(dropFieldName)) == null ? void 0 : _a2.fallBackField) ? fallBackPairTemplate(dropFieldName) : emptyFallBackFieldTemplate(dropFieldName);
  };
  const emptyFallBackFieldTemplate = (dropFieldName) => html`
		<dc-dropfield
			.class=${"w-36 px-4 py-2 text-center justify-center border-2 rounded-xl border-solid px-4 py-2 hover:border-dashed hover:border-gray-500 bg-white"}
			.emptyText=${`+ ${i18next.t("Add Fallback")}`}
			.allowedDragTypes=${["TemplateDataSource"]}
			.onDrop=${(e) => {
    var _a2;
    const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
    const newPairs = new Map(currentPairs);
    newPairs.set(dropFieldName, { dragField: (_a2 = currentPairs.get(dropFieldName)) == null ? void 0 : _a2.dragField, fallBackField: draggedItem });
    setCurrentPairs(newPairs);
  }}
		></dc-dropfield>
	`;
  const fallBackPairTemplate = (dropFieldName) => html`
		<div class="w-36 flex gap-4 items-center">
			<div
				class="flex justify-between gap-4 items-center w-36 truncate bg-gray-50 border-2 border-brand-tertiary border-solid rounded-xl px-4 py-2 font-semibold"
				title=${currentPairs.get(dropFieldName).fallBackField.label}
			>
				<div class="truncate">${currentPairs.get(dropFieldName).fallBackField.label}</div>
				<span
					class="rounded-full w-5 h-5 bg-gray-200 hover:bg-gray-300 flex flex-shrink-0 items-center justify-center cursor-pointer"
					@click=${() => {
    var _a2;
    const newPairs = new Map(currentPairs);
    newPairs.set(dropFieldName, { dragField: (_a2 = currentPairs.get(dropFieldName)) == null ? void 0 : _a2.dragField, fallBackField: void 0 });
    setCurrentPairs(newPairs);
    setIsMappingChangedAfterSave(true);
  }}
				>
					${closeIcon("fill-black w-3 h-3")}
				</span>
			</div>
		</div>
	`;
  const dropfieldTemplate = (dropFieldName, type) => {
    var _a2;
    return html`
		<div class=${classNames("flex items-center border shadow-lg rounded-xl px-6 py-4 gap-4")}>
			<div class="flex flex-col gap-1 basis-2/5"><span class="font-semibold">${TAX_DESK_DATA_LABELS[dropFieldName]}${isFieldRequired(dropFieldName) ? "*" : ""}</span><span>${type}</span></div>
			${((_a2 = currentPairs.get(dropFieldName)) == null ? void 0 : _a2.dragField) ? html`
						<div class="basis-3/5 flex gap-4 items-center">
							<div
								class="flex justify-between gap-4 items-center w-60 truncate bg-gray-50 border-2 border-brand-tertiary border-solid rounded-xl px-4 py-2 font-semibold"
								title=${currentPairs.get(dropFieldName).dragField.label}
							>
								<div class="truncate">${currentPairs.get(dropFieldName).dragField.label}</div>
								<span
									class="rounded-full w-5 h-5 bg-gray-200 hover:bg-gray-300 flex flex-shrink-0 items-center justify-center cursor-pointer"
									@click=${() => {
      const newPairs = new Map(currentPairs);
      newPairs.set(dropFieldName, void 0);
      setCurrentPairs(newPairs);
      setIsMappingChangedAfterSave(true);
    }}
								>
									${closeIcon("fill-black w-3 h-3")}
								</span>
							</div>
							${fallBackFieldTemplate(dropFieldName)}
						</div>
				  ` : html`
						<div class="flex justify-end basis-3/5 gap-4 items-center">
							<dc-dropfield
								.class=${classNames("px-4 w-60 py-2 text-center justify-center border-2 rounded-xl border-solid hover:border-dashed", {
      "border-red-500 bg-red-100": isValidated && !currentPairs.get(dropFieldName) && isFieldRequired(dropFieldName),
      "hover:border-gray-500 bg-gray-200": !isValidated || currentPairs.get(dropFieldName) || !isFieldRequired(dropFieldName)
    })}
								.emptyText=${i18next.t("Drop data mapping here")}
								.allowedDragTypes=${["TemplateDataSource"]}
								.onDrop=${(e) => {
      var _a3;
      const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
      const newPairs = new Map(currentPairs);
      newPairs.set(dropFieldName, { dragField: draggedItem, fallBackField: (_a3 = currentPairs.get(dropFieldName)) == null ? void 0 : _a3.fallBackField });
      setCurrentPairs(newPairs);
    }}
							></dc-dropfield>
						</div>
				  `}
		</div>
	`;
  };
  const uploadTransactionDataButtonTemplate = () => {
    var _a2;
    return html`
		<dc-fileupload2
			class="self-start w-fit"
			.label=${i18next.t("Upload Sample Data File")}
			.withLoader=${isFileUploading}
			.loaderPosition=${"right"}
			.showFileLink=${false}
			.accept=${FormatTypeMapping.get((_a2 = props.generationConfiguration) == null ? void 0 : _a2.AvailableTemplates[0])}
			@upload=${(e) => __async(this, null, function* () {
      return handleUploadTransactionDataButtonClick(e);
    })}
		></dc-fileupload2>
	`;
  };
  const taxDeskDataTemplate = () => html`
		<div class="flex flex-col gap-2 w-full">
			<div class="text-xl font-bold">${i18next.t("Tax Desk Data")}</div>
			<div class="flex flex-col gap-4 w-full max-w-[750px]">
				${Array.from(currentPairs.keys()).map(
    (dropFieldName) => {
      var _a2, _b;
      return dropfieldTemplate(
        dropFieldName,
        selectedTab === "Sale" ? (_a2 = fieldMetadata.saleFieldMetadata.find((field) => field.Name === dropFieldName)) == null ? void 0 : _a2.Type : (_b = fieldMetadata.purchaseFieldMetadata.find((field) => field.Name === dropFieldName)) == null ? void 0 : _b.Type
      );
    }
  )}
			</div>
		</div>
	`;
  const uploadedDataTemplate = () => (dragFields == null ? void 0 : dragFields.length) > 0 ? html`
					<div class="sticky top-20 flex flex-col gap-4 max-h-[600px] w-[340px] shadow-xl border rounded-l-xl px-8 py-6 overflow-y-auto font-semibold">
						${dragFields.map((field) => sharedCustomDataTemplate.draggableItemTemplate(field.label, field.info, field.id))}
					</div>
			  ` : "";
  const uploadSectionTemplate = () => {
    var _a2, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
    return html`
		<div class="flex flex-col gap-2">
			<div class="text-xl font-bold">${i18next.t("Upload Sample Transaction Data File")}</div>
			<div class="flex flex-wrap gap-2 items-center">
				${uploadTransactionDataButtonTemplate()}
				<div
					class=${classNames("flex gap-2 items-center", {
      hidden: selectedTab === "Purchase" && !((_b = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.TemplateFileReference) == null ? void 0 : _b.PurchaseFileName) || selectedTab === "Sale" && !((_d = (_c = props.generationConfiguration) == null ? void 0 : _c.TemplateFileReference) == null ? void 0 : _d.SaleFileName)
    })}
				>
					${fileIcon(selectedTab === "Sale" ? (_f = (_e = props.generationConfiguration) == null ? void 0 : _e.TemplateFileReference) == null ? void 0 : _f.SaleFileName : (_h = (_g = props.generationConfiguration) == null ? void 0 : _g.TemplateFileReference) == null ? void 0 : _h.PurchaseFileName)}
					<div class="hover:underline truncate">
						${selectedTab === "Sale" ? (_j = (_i = props.generationConfiguration) == null ? void 0 : _i.TemplateFileReference) == null ? void 0 : _j.SaleFileName : (_l = (_k = props.generationConfiguration) == null ? void 0 : _k.TemplateFileReference) == null ? void 0 : _l.PurchaseFileName}
					</div>
				</div>
			</div>
		</div>
	`;
  };
  const dataMappingTemplate = () => html`
		<div class="flex gap-8 w-full">
			<div class="flex flex-col w-full gap-4">
				<div>${i18next.t("Fields with * are mandatory to map, the rest of the fields are optional.")}</div>
				${taxDeskDataTemplate()}
			</div>
			<div class="flex flex-col gap-2 w-full">${uploadSectionTemplate()} ${uploadedDataTemplate()}</div>
		</div>
	`;
  const mapAllRequiredFieldsErrorTemplate = () => !isEveryRequiredDropFieldSet(currentPairs) && isValidated ? html`<div class="text-red-500 font-semibold">
					${selectedTab === "Sale" ? i18next.t("Please map all required fields at the Tax Desk sale data list.") : i18next.t("Please map all required fields at the Tax Desk purchase data list.")}
			  </div> ` : "";
  const template = () => html`
		<div class="m-8 flex flex-col gap-4 w-full">
			<div class="flex flex-col gap-4 w-full">
				${tabsTemplate()}
				<div class="flex gap-4 items-center">${PrimaryButton({ class: "max-w-fit", text: i18next.t("Save mapping"), onClick: saveCurrentMapping })} ${mapAllRequiredFieldsErrorTemplate()}</div>
			</div>
			<div class="flex items-center flex-wrap gap-4">
				${SecondaryButton({ class: "w-32 my-6", text: i18next.t("Previous"), onClick: () => props.setStep(0) })}
				${PrimaryButton({
    class: "w-32 my-6",
    text: i18next.t("Next"),
    disabled: hasEmptyRequiredFields() || isMappingChangedAfterSave || !isBothMappingsSavedSuccessfully,
    onClick: () => props.setStep(2)
  })}
			</div>
		</div>
	`;
  return { template, uploadedFile, selectedTab };
}
