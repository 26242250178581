import { html } from "lit-html";
import { useEffect } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
import { validateExtension } from "./uiHelper";
const DEFAULTS = {
  text: ""
};
export class DropEvent extends CustomEvent {
  constructor(detail) {
    super("drop", { detail });
  }
}
export const Component = (host) => {
  var _a;
  const props = {
    text: host.text !== void 0 ? host.text : DEFAULTS.text,
    accept: (_a = host.accept) != null ? _a : []
  };
  const onDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (Array.from(e.dataTransfer.files).some((f) => !validateExtension(f, props.accept))) {
      return false;
    }
    host.dispatchEvent(
      new DropEvent({
        files: e.dataTransfer.files
      })
    );
    return false;
  };
  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    return true;
  };
  const elemId = `_${name}_${Math.random().toString(36).substr(2, 9)}`;
  const init = () => {
    const dropZone = document.getElementById(elemId);
    dropZone.addEventListener("dragover", onDragOver);
    dropZone.addEventListener("drop", onDrop);
  };
  useEffect(init, []);
  return html` <div class="w-full h-full flex items-center justify-around" id="${elemId}">${props.text}</div>`;
};
customElements.define(
  "dc-dropzone",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
