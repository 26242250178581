import { SkuFieldBase } from "./SkuFieldBase";
import { CommodityCodeField } from "./CommodityCodeField";
import { Field } from "./Field";
export class SkuFieldBaseFactory {
  static create($type) {
    if ($type === "V1.ComplianceMasterData.SearchSku.SkuFieldBase") {
      const result = new SkuFieldBase();
      return result;
    }
    if ($type === "V1.ComplianceMasterData.SearchSku.CommodityCodeField") {
      const result = new CommodityCodeField();
      return result;
    }
    if ($type === "V1.ComplianceMasterData.SearchSku.Field") {
      const result = new Field();
      return result;
    }
    throw new Error($type + " not found");
  }
}
