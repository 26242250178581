var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { PredefinedBankAccount } from "./../models/V1/CompanyInfo/VatRegistration/PredefinedBankAccount";
export class VatRegistration {
  /**
   * Creates an instance of VatRegistration.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof VatRegistration
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param DownloadVatRegDocumentRq_ _downloadVatRegDocumentRq
   * @returns Promise<DownloadVatRegDocumentRq_>
   * @memberof VatRegistration
   */
  downloadVatRegDocument(pDownloadVatRegDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/registration/{vatRegId}/template/{templateId}",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addQueryParams([{ name: "type", value: pDownloadVatRegDocumentRq.type }]);
    client.addPathParams([
      { name: "vatRegId", value: pDownloadVatRegDocumentRq.vatRegId, required: true },
      { name: "templateId", value: pDownloadVatRegDocumentRq.templateId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSignedVatRegDocumentRq_ _uploadSignedVatRegDocumentRq
   * @returns Promise<UploadSignedVatRegDocumentRq_>
   * @memberof VatRegistration
   */
  uploadSignedVatRegDocument(pUploadSignedVatRegDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/registration/{vatRegId}/template/{templateId}/signed",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "vatRegId", value: pUploadSignedVatRegDocumentRq.vatRegId, required: true },
      { name: "templateId", value: pUploadSignedVatRegDocumentRq.templateId, required: true }
    ]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadSignedVatRegDocumentRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FinalizeUploadRq_ _finalizeUploadRq
   * @returns Promise<FinalizeUploadRq_>
   * @memberof VatRegistration
   */
  finalizeUpload(pFinalizeUploadRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/registration/{vatRegId}/finalized",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([{ name: "vatRegId", value: pFinalizeUploadRq.vatRegId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadVatCertificateRq_ _downloadVatCertificateRq
   * @returns Promise<DownloadVatCertificateRq_>
   * @memberof VatRegistration
   */
  downloadVatCertificate(pDownloadVatCertificateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/registration/{vatRegId}/certificate/{certificateType}",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "vatRegId", value: pDownloadVatCertificateRq.vatRegId, required: true },
      { name: "certificateType", value: pDownloadVatCertificateRq.certificateType, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetPredefinedBankAccountsRq_ _getPredefinedBankAccountsRq
   * @returns Promise<GetPredefinedBankAccountsRq_>
   * @memberof VatRegistration
   */
  getPredefinedBankAccounts(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/VatRegistration/PredefinedBankAccounts", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: PredefinedBankAccount, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadDeregistrationDocumentRq_ _downloadDeregistrationDocumentRq
   * @returns Promise<DownloadDeregistrationDocumentRq_>
   * @memberof VatRegistration
   */
  downloadDeregistrationDocument(pDownloadDeregistrationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/registration/{vatRegId}/deregistrationDocument",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([{ name: "vatRegId", value: pDownloadDeregistrationDocumentRq.vatRegId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadPoaRevokeConfirmationDocumentRq_ _downloadPoaRevokeConfirmationDocumentRq
   * @returns Promise<DownloadPoaRevokeConfirmationDocumentRq_>
   * @memberof VatRegistration
   */
  downloadPoaRevokeConfirmationDocument(pDownloadPoaRevokeConfirmationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/VatRegistration/registration/{vatRegId}/poarevokeconfirmationdocument",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([{ name: "vatRegId", value: pDownloadPoaRevokeConfirmationDocumentRq.vatRegId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
export var DownloadVatRegDocumentType = /* @__PURE__ */ ((DownloadVatRegDocumentType2) => {
  DownloadVatRegDocumentType2["Unsigned"] = "Unsigned";
  DownloadVatRegDocumentType2["Signed"] = "Signed";
  return DownloadVatRegDocumentType2;
})(DownloadVatRegDocumentType || {});
export var DownloadVatCertificateCertificateType = /* @__PURE__ */ ((DownloadVatCertificateCertificateType2) => {
  DownloadVatCertificateCertificateType2["Local"] = "Local";
  DownloadVatCertificateCertificateType2["Eu"] = "Eu";
  return DownloadVatCertificateCertificateType2;
})(DownloadVatCertificateCertificateType || {});
