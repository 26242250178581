var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { useAwesomeValidator, Validation } from "GlobalShared/validation/AwesomeValidator";
export const useDeregistrationModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [documentReference, setDocumentReference] = useState(void 0);
  const [dateOfDeregistration, setDateOfDeregistration] = useState(void 0);
  useEffect(() => __async(void 0, null, function* () {
    if (props && props.change) {
      setDocumentReference(props.change.DocumentId);
      setDateOfDeregistration(props.change.DateOfDeregistration);
    }
  }), [props]);
  const show = () => {
    setShowModal(true);
  };
  useEffect(() => {
    setDocumentReference(void 0);
    setDateOfDeregistration(void 0);
  }, [showModal]);
  useEffect(() => {
    if (showModal) {
      validator.clear();
    }
  }, [showModal]);
  useEffect(() => {
    validator.validate({ DocumentId: documentReference, DateOfDeregistration: dateOfDeregistration });
  }, [documentReference, dateOfDeregistration]);
  const validator = useAwesomeValidator(
    [Validation.ruleFor("DateOfDeregistration", (vm) => vm.DateOfDeregistration).isRequired()],
    () => props.save({ DocumentId: documentReference, DateOfDeregistration: dateOfDeregistration })
  );
  const template = html`${showModal ? html`<div>
				<dc-modal
					.visible=${showModal}
					@close=${() => setShowModal(false)}
					.header=${"VAT Deregistration"}
					.content=${html`<div class="space-y-4 md:w-1/2">
						<dc-fileupload
							.label=${"Confirmation of deregistration"}
							.fileName=${void 0}
							.fileReference=${documentReference}
							@upload=${(e) => __async(void 0, null, function* () {
    var _a;
    if (((_a = e.detail) == null ? void 0 : _a.files) && e.detail.files.length > 0) {
      setDocumentReference(yield props.uploadDocument(e.detail.files[0]));
    }
  })}
						></dc-fileupload>
						<dc-datepicker
							.label=${"Effective date of deregistration*"}
							.value=${dateOfDeregistration}
							.validationMessage=${validator.getMessage("DateOfDeregistration")}
							@change=${(e) => {
    validator.showMessageFor("DateOfDeregistration");
    setDateOfDeregistration(e.detail.value);
  }}
						></dc-datepicker>
						<div class="mt-8">
							<button class="btn btn-primary mr-4" @click=${() => setShowModal(false)}>Cancel</button>
							<button
								class="btn btn-primary"
								?disabled=${dateOfDeregistration === void 0}
								@click=${() => __async(void 0, null, function* () {
    validator.validateAndDispatch({
      DateOfDeregistration: dateOfDeregistration,
      DocumentId: documentReference
    });
    setShowModal(false);
  })}
							>
								Save
							</button>
						</div>
					</div>`}
				>
				</dc-modal>
		  </div>` : html``}`;
  return { show, template };
};
