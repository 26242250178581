var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { getSelectedVatServices } from "./CreateServiceModels";
import { DEFAULT_SERVICES_MODEL } from "./OrderServiceModels";
import { VatServicesValidator } from "./OrderServiceValidator";
export const useVatServices = (props) => {
  const onChangeVatCountry = (e) => {
    const newVatCountry = e.detail.vatCountry;
    setVatServices(vatServices.map((vatCountry) => vatCountry.code === newVatCountry.code ? newVatCountry : vatCountry));
  };
  const [ukEori, setUkEori] = useState(void 0);
  const [euEori, setEuEori] = useState(void 0);
  const [vatServices, setVatServices] = useState(void 0);
  const [isValidVatServices, setIsValidVatServices] = useState(false);
  const vatServicesValidationContext = useValidationContext(new VatServicesValidator(props.priceConfig));
  useEffect(() => {
    var _a;
    if (props.vatCountrySelection) {
      if (props.vatCountrySelection.filter((tc) => tc.code !== "GB").every((s) => !s.selected) && euEori) {
        setEuEori(false);
      }
      if (!((_a = props.vatCountrySelection.find((t) => t.code === "GB")) == null ? void 0 : _a.selected) && ukEori) {
        setUkEori(false);
      }
      setVatServices(props.vatCountrySelection.map((c) => __spreadValues(__spreadValues({}, vatServices == null ? void 0 : vatServices.find((vs) => vs.code === c.code)), c)));
    }
  }, [props.vatCountrySelection]);
  const init = () => {
    if (props.devMode) {
      setEuEori(DEFAULT_SERVICES_MODEL.euEori);
      setUkEori(DEFAULT_SERVICES_MODEL.ukEori);
    }
  };
  useEffect(init, []);
  useEffect(() => __async(void 0, null, function* () {
    setIsValidVatServices(vatServicesValidationContext.validationResult.isValid());
  }), [vatServicesValidationContext.validationResult]);
  useEffect(() => {
    if (vatServices) {
      vatServicesValidationContext.validate(vatServices);
    }
  }, [vatServices, props.priceConfig]);
  useEffect(() => __async(void 0, null, function* () {
    if (props.currentCompanyServices) {
      setVatServices(void 0);
    }
  }), [props.currentCompanyServices]);
  const htmlEori = () => {
    var _a, _b, _c, _d;
    return html`${((_a = props.priceConfig) == null ? void 0 : _a.eoriWithUK) || ((_b = props.priceConfig) == null ? void 0 : _b.eoriWithoutUK) ? html`${templateTitle(i18next.t("EORI"))}
					<div class="bg-gray-100 rounded-xl p-2">
						<div class="ml-4">
							${((_c = props.priceConfig) == null ? void 0 : _c.eoriWithoutUK) ? html`<div class="btn-toggle xl:w-1/2 my-4 ${euEori ? "selected" : ""}" @click=${() => setEuEori(!euEori)}>
										<div class="flex justify-between">
											<div class="mr-2">${i18next.t("EORI number application in the EU")}</div>
											<div>
												<dc-tooltip .label=${i18next.t("Do not choose this if you already have one EORI number in the EU.")}></dc-tooltip>
											</div>
										</div>
								  </div>` : ""}
							${((_d = props.priceConfig) == null ? void 0 : _d.eoriWithUK) ? html`<div class="btn-toggle xl:w-1/2 my-4 ${ukEori ? "selected" : ""}" @click=${() => setUkEori(!ukEori)}>
										<div class="flex justify-between">
											<div class="mr-2">${i18next.t("EORI number application in the UK")}</div>
											<div>
												<dc-tooltip
													.label=${i18next.t("Choose this if you do not have an EORI number in the UK, but you wish to import goods there. (Necessary after Brexit)")}
												></dc-tooltip>
											</div>
										</div>
								  </div>` : ""}
						</div>
					</div>` : ""} `;
  };
  const htmlVatCountryServices = () => {
    return html`<div class="space-y-8 mt-2">
			${getSelectedVatServices(vatServices).map(
      (vatCountry) => {
        var _a, _b;
        return html`<div>
						<order-vat-country-service
							.isAdmin=${props.isAdmin}
							.isSignup=${props.isSignup}
							.label=${(_b = (_a = props.worldCountries) == null ? void 0 : _a.find((wc) => wc.Code === vatCountry.code)) == null ? void 0 : _b.Name}
							.vatCountry=${vatCountry}
							.priceConfig=${props.priceConfig}
							.currentCompanyServices=${props.currentCompanyServices}
							@change=${onChangeVatCountry}
						></order-vat-country-service>
					</div>`;
      }
    )}
		</div>`;
  };
  const htmlVatServices = () => {
    return html`<div>${htmlEori()}${getSelectedVatServices(vatServices).length > 0 ? html`${templateTitle(i18next.t("VAT"))}${htmlVatCountryServices()}` : ""}</div>`;
  };
  return {
    htmlVatServices,
    vatServices,
    ukEori,
    euEori,
    isValidVatServices
  };
};
