var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { NewFrequencyConfig } from "./NewFrequencyConfig";
export class FrequenciesChangeRequest {
  constructor(_data) {
    /**
     */
    __publicField(this, "FrequencyConfigsToBeRemoved");
    /**
     */
    __publicField(this, "NewFrequencyConfigs");
    /**
     */
    __publicField(this, "ReturnType");
    /**
     */
    __publicField(this, "SendEmail");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof FrequenciesChangeRequest
     */
    __publicField(this, "$type", "V1.Company.VatRegistration.Frequency.FrequenciesChangeRequest");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["FrequencyConfigsToBeRemoved"] && _data["FrequencyConfigsToBeRemoved"].constructor === Array) {
        this.FrequencyConfigsToBeRemoved = [];
        for (let item of _data["FrequencyConfigsToBeRemoved"]) {
          this.FrequencyConfigsToBeRemoved.push(item);
        }
      }
      if (_data["NewFrequencyConfigs"] && _data["NewFrequencyConfigs"].constructor === Array) {
        this.NewFrequencyConfigs = [];
        for (let item of _data["NewFrequencyConfigs"]) {
          const newFrequencyConfigs_ = new NewFrequencyConfig();
          this.NewFrequencyConfigs.push(newFrequencyConfigs_.init(item));
        }
      }
      this.ReturnType = FrequenciesChangeRequestReturnType[_data["ReturnType"]];
      this.SendEmail = _data["SendEmail"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.FrequencyConfigsToBeRemoved && this.FrequencyConfigsToBeRemoved.constructor === Array) {
      _data["FrequencyConfigsToBeRemoved"] = [];
      for (let item of this.FrequencyConfigsToBeRemoved) {
        _data["FrequencyConfigsToBeRemoved"].push(item);
      }
    }
    if (this.NewFrequencyConfigs && this.NewFrequencyConfigs.constructor === Array) {
      _data["NewFrequencyConfigs"] = [];
      for (let item of this.NewFrequencyConfigs) {
        _data["NewFrequencyConfigs"].push(item.toJSON());
      }
    }
    _data["ReturnType"] = FrequenciesChangeRequestReturnType[this.ReturnType];
    _data["SendEmail"] = this.SendEmail;
    return _data;
  }
}
export var FrequenciesChangeRequestReturnType = /* @__PURE__ */ ((FrequenciesChangeRequestReturnType2) => {
  FrequenciesChangeRequestReturnType2["PrimaryVAT"] = "PrimaryVAT";
  FrequenciesChangeRequestReturnType2["SecondaryVAT"] = "SecondaryVAT";
  FrequenciesChangeRequestReturnType2["ESL"] = "ESL";
  FrequenciesChangeRequestReturnType2["VLS"] = "VLS";
  FrequenciesChangeRequestReturnType2["IOSS"] = "IOSS";
  FrequenciesChangeRequestReturnType2["Intrastat"] = "Intrastat";
  FrequenciesChangeRequestReturnType2["OSS"] = "OSS";
  FrequenciesChangeRequestReturnType2["LocalListing"] = "LocalListing";
  return FrequenciesChangeRequestReturnType2;
})(FrequenciesChangeRequestReturnType || {});
