var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class StatusResult {
  constructor(_data) {
    /**
     */
    __publicField(this, "ComplianceUploadStatus");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof StatusResult
     */
    __publicField(this, "$type", "V1.ComplianceUploads.StatusResult");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ComplianceUploadStatus = StatusResultComplianceUploadStatus[_data["ComplianceUploadStatus"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ComplianceUploadStatus"] = StatusResultComplianceUploadStatus[this.ComplianceUploadStatus];
    return _data;
  }
}
export var StatusResultComplianceUploadStatus = /* @__PURE__ */ ((StatusResultComplianceUploadStatus2) => {
  StatusResultComplianceUploadStatus2["New"] = "New";
  StatusResultComplianceUploadStatus2["WaitingForFinalize"] = "WaitingForFinalize";
  StatusResultComplianceUploadStatus2["InvalidData"] = "InvalidData";
  StatusResultComplianceUploadStatus2["SaftCompliance"] = "SaftCompliance";
  StatusResultComplianceUploadStatus2["MasterDataSetup"] = "MasterDataSetup";
  StatusResultComplianceUploadStatus2["DataUploadFinalized"] = "DataUploadFinalized";
  StatusResultComplianceUploadStatus2["SentToReturnGeneration"] = "SentToReturnGeneration";
  StatusResultComplianceUploadStatus2["DataVatGenerated"] = "DataVatGenerated";
  StatusResultComplianceUploadStatus2["InsufficientCredit"] = "InsufficientCredit";
  StatusResultComplianceUploadStatus2["UploadProcessed"] = "UploadProcessed";
  StatusResultComplianceUploadStatus2["Error"] = "Error";
  return StatusResultComplianceUploadStatus2;
})(StatusResultComplianceUploadStatus || {});
