import { SubmissionSummary } from "./SubmissionSummary";
import { CorrectionSubmissionSummary } from "./CorrectionSubmissionSummary";
import { NormalSubmissionSummary } from "./NormalSubmissionSummary";
export class SubmissionSummaryFactory {
  static create($type) {
    if ($type === "V1.Cesop.Periods.SubmissionSummary") {
      const result = new SubmissionSummary();
      return result;
    }
    if ($type === "V1.Cesop.Periods.CorrectionSubmissionSummary") {
      const result = new CorrectionSubmissionSummary();
      return result;
    }
    if ($type === "V1.Cesop.Periods.NormalSubmissionSummary") {
      const result = new NormalSubmissionSummary();
      return result;
    }
    throw new Error($type + " not found");
  }
}
