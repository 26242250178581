export var PayeeValidationErrorGroupCode = /* @__PURE__ */ ((PayeeValidationErrorGroupCode2) => {
  PayeeValidationErrorGroupCode2["MissingData"] = "MissingData";
  PayeeValidationErrorGroupCode2["InvalidEnumValue"] = "InvalidEnumValue";
  PayeeValidationErrorGroupCode2["InvalidCountryCode"] = "InvalidCountryCode";
  PayeeValidationErrorGroupCode2["InvalidEmail"] = "InvalidEmail";
  PayeeValidationErrorGroupCode2["InvalidVatId"] = "InvalidVatId";
  PayeeValidationErrorGroupCode2["InvalidTaxId"] = "InvalidTaxId";
  PayeeValidationErrorGroupCode2["TooLong"] = "TooLong";
  PayeeValidationErrorGroupCode2["DuplicatedValue"] = "DuplicatedValue";
  PayeeValidationErrorGroupCode2["AccountIdAndRepresentativeProvided"] = "AccountIdAndRepresentativeProvided";
  PayeeValidationErrorGroupCode2["AccountIdAndRepresentativeMissing"] = "AccountIdAndRepresentativeMissing";
  PayeeValidationErrorGroupCode2["InvalidSeparator"] = "InvalidSeparator";
  PayeeValidationErrorGroupCode2["InvalidHeader"] = "InvalidHeader";
  PayeeValidationErrorGroupCode2["UnprocessableFile"] = "UnprocessableFile";
  PayeeValidationErrorGroupCode2["InvalidColumnCount"] = "InvalidColumnCount";
  PayeeValidationErrorGroupCode2["InvalidIban"] = "InvalidIban";
  PayeeValidationErrorGroupCode2["InvalidGuid"] = "InvalidGuid";
  PayeeValidationErrorGroupCode2["InvalidMessageId"] = "InvalidMessageId";
  PayeeValidationErrorGroupCode2["InvalidDocRefId"] = "InvalidDocRefId";
  PayeeValidationErrorGroupCode2["MigratedError"] = "MigratedError";
  return PayeeValidationErrorGroupCode2;
})(PayeeValidationErrorGroupCode || {});
export var PayeeValidationErrorGroupColumn = /* @__PURE__ */ ((PayeeValidationErrorGroupColumn2) => {
  PayeeValidationErrorGroupColumn2["PayeeAccountId"] = "PayeeAccountId";
  PayeeValidationErrorGroupColumn2["NameType"] = "NameType";
  PayeeValidationErrorGroupColumn2["NameValue"] = "NameValue";
  PayeeValidationErrorGroupColumn2["BusinessCountry"] = "BusinessCountry";
  PayeeValidationErrorGroupColumn2["LegalAddressType"] = "LegalAddressType";
  PayeeValidationErrorGroupColumn2["AddressCountryCode"] = "AddressCountryCode";
  PayeeValidationErrorGroupColumn2["Street"] = "Street";
  PayeeValidationErrorGroupColumn2["BuildingIdentifier"] = "BuildingIdentifier";
  PayeeValidationErrorGroupColumn2["DistrictName"] = "DistrictName";
  PayeeValidationErrorGroupColumn2["PostCode"] = "PostCode";
  PayeeValidationErrorGroupColumn2["City"] = "City";
  PayeeValidationErrorGroupColumn2["EmailAddress"] = "EmailAddress";
  PayeeValidationErrorGroupColumn2["WebPage"] = "WebPage";
  PayeeValidationErrorGroupColumn2["VatIdIssuedBy"] = "VatIdIssuedBy";
  PayeeValidationErrorGroupColumn2["VatIdValue"] = "VatIdValue";
  PayeeValidationErrorGroupColumn2["TaxIdIssuedBy"] = "TaxIdIssuedBy";
  PayeeValidationErrorGroupColumn2["TaxIdType"] = "TaxIdType";
  PayeeValidationErrorGroupColumn2["TaxIdValue"] = "TaxIdValue";
  PayeeValidationErrorGroupColumn2["AccountIdentifierCountryCode"] = "AccountIdentifierCountryCode";
  PayeeValidationErrorGroupColumn2["AccountIdentifierType"] = "AccountIdentifierType";
  PayeeValidationErrorGroupColumn2["AccountIdentifierValue"] = "AccountIdentifierValue";
  PayeeValidationErrorGroupColumn2["RepresentativePspIdType"] = "RepresentativePspIdType";
  PayeeValidationErrorGroupColumn2["RepresentativePspValue"] = "RepresentativePspValue";
  PayeeValidationErrorGroupColumn2["RepresentativeNameType"] = "RepresentativeNameType";
  PayeeValidationErrorGroupColumn2["RepresentativeValue"] = "RepresentativeValue";
  PayeeValidationErrorGroupColumn2["PayeeLocation"] = "PayeeLocation";
  PayeeValidationErrorGroupColumn2["Action"] = "Action";
  PayeeValidationErrorGroupColumn2["Issues"] = "Issues";
  PayeeValidationErrorGroupColumn2["MessageId"] = "MessageId";
  PayeeValidationErrorGroupColumn2["DocRefId"] = "DocRefId";
  return PayeeValidationErrorGroupColumn2;
})(PayeeValidationErrorGroupColumn || {});
