var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Answer } from "./../Answer";
export class MultiStringAnswer extends Answer {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "Value");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MultiStringAnswer
     */
    __publicField(this, "$type", "V1.CompanyInfo.Questionnaire.Answers.MultiStringAnswer");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["Value"] && _data["Value"].constructor === Array) {
        this.Value = [];
        for (let item of _data["Value"]) {
          this.Value.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    if (this.Value && this.Value.constructor === Array) {
      _data["Value"] = [];
      for (let item of this.Value) {
        _data["Value"].push(item);
      }
    }
    return _data;
  }
}
