export const CLIENT_SOURCES = ["TaxDesk", "Geopost", "Partner"];
export const UserCompaniesColumnNames = {
  Email: "Email",
  Companies: "Companies",
  Details: "Details"
};
export const UserPspsColumnNames = {
  Email: "Email",
  Psps: "Psps",
  Details: "Details"
};
