var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class AmazonValidationMessage {
  constructor(_data) {
    /**
     */
    __publicField(this, "ColumnName");
    /**
     */
    __publicField(this, "IsWarning");
    /**
     */
    __publicField(this, "Parameters");
    /**
     */
    __publicField(this, "RowNumber");
    /**
     */
    __publicField(this, "TotalCount");
    /**
     */
    __publicField(this, "ValidationCode");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof AmazonValidationMessage
     */
    __publicField(this, "$type", "V1.ComplianceUploads.AmazonValidationMessage");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ColumnName = AmazonValidationMessageColumnName[_data["ColumnName"]];
      this.IsWarning = _data["IsWarning"];
      if (_data["Parameters"] && _data["Parameters"].constructor === Array) {
        this.Parameters = [];
        for (let item of _data["Parameters"]) {
          this.Parameters.push(item);
        }
      }
      this.RowNumber = _data["RowNumber"];
      this.TotalCount = _data["TotalCount"];
      this.ValidationCode = AmazonValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ColumnName"] = AmazonValidationMessageColumnName[this.ColumnName];
    _data["IsWarning"] = this.IsWarning;
    if (this.Parameters && this.Parameters.constructor === Array) {
      _data["Parameters"] = [];
      for (let item of this.Parameters) {
        _data["Parameters"].push(item);
      }
    }
    _data["RowNumber"] = this.RowNumber;
    _data["TotalCount"] = this.TotalCount;
    _data["ValidationCode"] = AmazonValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var AmazonValidationMessageColumnName = /* @__PURE__ */ ((AmazonValidationMessageColumnName2) => {
  AmazonValidationMessageColumnName2["UNIQUE_ACCOUNT_IDENTIFIER"] = "UNIQUE_ACCOUNT_IDENTIFIER";
  AmazonValidationMessageColumnName2["ACTIVITY_PERIOD"] = "ACTIVITY_PERIOD";
  AmazonValidationMessageColumnName2["SALES_CHANNEL"] = "SALES_CHANNEL";
  AmazonValidationMessageColumnName2["MARKETPLACE"] = "MARKETPLACE";
  AmazonValidationMessageColumnName2["PROGRAM_TYPE"] = "PROGRAM_TYPE";
  AmazonValidationMessageColumnName2["TRANSACTION_TYPE"] = "TRANSACTION_TYPE";
  AmazonValidationMessageColumnName2["TRANSACTION_EVENT_ID"] = "TRANSACTION_EVENT_ID";
  AmazonValidationMessageColumnName2["ACTIVITY_TRANSACTION_ID"] = "ACTIVITY_TRANSACTION_ID";
  AmazonValidationMessageColumnName2["TAX_CALCULATION_DATE"] = "TAX_CALCULATION_DATE";
  AmazonValidationMessageColumnName2["TRANSACTION_DEPART_DATE"] = "TRANSACTION_DEPART_DATE";
  AmazonValidationMessageColumnName2["TRANSACTION_ARRIVAL_DATE"] = "TRANSACTION_ARRIVAL_DATE";
  AmazonValidationMessageColumnName2["TRANSACTION_COMPLETE_DATE"] = "TRANSACTION_COMPLETE_DATE";
  AmazonValidationMessageColumnName2["SELLER_SKU"] = "SELLER_SKU";
  AmazonValidationMessageColumnName2["ASIN"] = "ASIN";
  AmazonValidationMessageColumnName2["ITEM_DESCRIPTION"] = "ITEM_DESCRIPTION";
  AmazonValidationMessageColumnName2["ITEM_MANUFACTURE_COUNTRY"] = "ITEM_MANUFACTURE_COUNTRY";
  AmazonValidationMessageColumnName2["QTY"] = "QTY";
  AmazonValidationMessageColumnName2["ITEM_WEIGHT"] = "ITEM_WEIGHT";
  AmazonValidationMessageColumnName2["TOTAL_ACTIVITY_WEIGHT"] = "TOTAL_ACTIVITY_WEIGHT";
  AmazonValidationMessageColumnName2["COST_PRICE_OF_ITEMS"] = "COST_PRICE_OF_ITEMS";
  AmazonValidationMessageColumnName2["PRICE_OF_ITEMS_AMT_VAT_EXCL"] = "PRICE_OF_ITEMS_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["PROMO_PRICE_OF_ITEMS_AMT_VAT_EXCL"] = "PROMO_PRICE_OF_ITEMS_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["TOTAL_PRICE_OF_ITEMS_AMT_VAT_EXCL"] = "TOTAL_PRICE_OF_ITEMS_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["SHIP_CHARGE_AMT_VAT_EXCL"] = "SHIP_CHARGE_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["PROMO_SHIP_CHARGE_AMT_VAT_EXCL"] = "PROMO_SHIP_CHARGE_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["TOTAL_SHIP_CHARGE_AMT_VAT_EXCL"] = "TOTAL_SHIP_CHARGE_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["GIFT_WRAP_AMT_VAT_EXCL"] = "GIFT_WRAP_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["PROMO_GIFT_WRAP_AMT_VAT_EXCL"] = "PROMO_GIFT_WRAP_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["TOTAL_GIFT_WRAP_AMT_VAT_EXCL"] = "TOTAL_GIFT_WRAP_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["TOTAL_ACTIVITY_VALUE_AMT_VAT_EXCL"] = "TOTAL_ACTIVITY_VALUE_AMT_VAT_EXCL";
  AmazonValidationMessageColumnName2["PRICE_OF_ITEMS_VAT_RATE_PERCENT"] = "PRICE_OF_ITEMS_VAT_RATE_PERCENT";
  AmazonValidationMessageColumnName2["PRICE_OF_ITEMS_VAT_AMT"] = "PRICE_OF_ITEMS_VAT_AMT";
  AmazonValidationMessageColumnName2["PROMO_PRICE_OF_ITEMS_VAT_AMT"] = "PROMO_PRICE_OF_ITEMS_VAT_AMT";
  AmazonValidationMessageColumnName2["TOTAL_PRICE_OF_ITEMS_VAT_AMT"] = "TOTAL_PRICE_OF_ITEMS_VAT_AMT";
  AmazonValidationMessageColumnName2["SHIP_CHARGE_VAT_RATE_PERCENT"] = "SHIP_CHARGE_VAT_RATE_PERCENT";
  AmazonValidationMessageColumnName2["SHIP_CHARGE_VAT_AMT"] = "SHIP_CHARGE_VAT_AMT";
  AmazonValidationMessageColumnName2["PROMO_SHIP_CHARGE_VAT_AMT"] = "PROMO_SHIP_CHARGE_VAT_AMT";
  AmazonValidationMessageColumnName2["TOTAL_SHIP_CHARGE_VAT_AMT"] = "TOTAL_SHIP_CHARGE_VAT_AMT";
  AmazonValidationMessageColumnName2["GIFT_WRAP_VAT_RATE_PERCENT"] = "GIFT_WRAP_VAT_RATE_PERCENT";
  AmazonValidationMessageColumnName2["GIFT_WRAP_VAT_AMT"] = "GIFT_WRAP_VAT_AMT";
  AmazonValidationMessageColumnName2["PROMO_GIFT_WRAP_VAT_AMT"] = "PROMO_GIFT_WRAP_VAT_AMT";
  AmazonValidationMessageColumnName2["TOTAL_GIFT_WRAP_VAT_AMT"] = "TOTAL_GIFT_WRAP_VAT_AMT";
  AmazonValidationMessageColumnName2["TOTAL_ACTIVITY_VALUE_VAT_AMT"] = "TOTAL_ACTIVITY_VALUE_VAT_AMT";
  AmazonValidationMessageColumnName2["PRICE_OF_ITEMS_AMT_VAT_INCL"] = "PRICE_OF_ITEMS_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["PROMO_PRICE_OF_ITEMS_AMT_VAT_INCL"] = "PROMO_PRICE_OF_ITEMS_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["TOTAL_PRICE_OF_ITEMS_AMT_VAT_INCL"] = "TOTAL_PRICE_OF_ITEMS_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["SHIP_CHARGE_AMT_VAT_INCL"] = "SHIP_CHARGE_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["PROMO_SHIP_CHARGE_AMT_VAT_INCL"] = "PROMO_SHIP_CHARGE_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["TOTAL_SHIP_CHARGE_AMT_VAT_INCL"] = "TOTAL_SHIP_CHARGE_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["GIFT_WRAP_AMT_VAT_INCL"] = "GIFT_WRAP_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["PROMO_GIFT_WRAP_AMT_VAT_INCL"] = "PROMO_GIFT_WRAP_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["TOTAL_GIFT_WRAP_AMT_VAT_INCL"] = "TOTAL_GIFT_WRAP_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["TOTAL_ACTIVITY_VALUE_AMT_VAT_INCL"] = "TOTAL_ACTIVITY_VALUE_AMT_VAT_INCL";
  AmazonValidationMessageColumnName2["TRANSACTION_CURRENCY_CODE"] = "TRANSACTION_CURRENCY_CODE";
  AmazonValidationMessageColumnName2["COMMODITY_CODE"] = "COMMODITY_CODE";
  AmazonValidationMessageColumnName2["STATISTICAL_CODE_DEPART"] = "STATISTICAL_CODE_DEPART";
  AmazonValidationMessageColumnName2["STATISTICAL_CODE_ARRIVAL"] = "STATISTICAL_CODE_ARRIVAL";
  AmazonValidationMessageColumnName2["COMMODITY_CODE_SUPPLEMENTARY_UNIT"] = "COMMODITY_CODE_SUPPLEMENTARY_UNIT";
  AmazonValidationMessageColumnName2["ITEM_QTY_SUPPLEMENTARY_UNIT"] = "ITEM_QTY_SUPPLEMENTARY_UNIT";
  AmazonValidationMessageColumnName2["TOTAL_ACTIVITY_SUPPLEMENTARY_UNIT"] = "TOTAL_ACTIVITY_SUPPLEMENTARY_UNIT";
  AmazonValidationMessageColumnName2["PRODUCT_TAX_CODE"] = "PRODUCT_TAX_CODE";
  AmazonValidationMessageColumnName2["DEPATURE_CITY"] = "DEPATURE_CITY";
  AmazonValidationMessageColumnName2["DEPARTURE_COUNTRY"] = "DEPARTURE_COUNTRY";
  AmazonValidationMessageColumnName2["DEPARTURE_POST_CODE"] = "DEPARTURE_POST_CODE";
  AmazonValidationMessageColumnName2["ARRIVAL_CITY"] = "ARRIVAL_CITY";
  AmazonValidationMessageColumnName2["ARRIVAL_COUNTRY"] = "ARRIVAL_COUNTRY";
  AmazonValidationMessageColumnName2["ARRIVAL_POST_CODE"] = "ARRIVAL_POST_CODE";
  AmazonValidationMessageColumnName2["SALE_DEPART_COUNTRY"] = "SALE_DEPART_COUNTRY";
  AmazonValidationMessageColumnName2["SALE_ARRIVAL_COUNTRY"] = "SALE_ARRIVAL_COUNTRY";
  AmazonValidationMessageColumnName2["TRANSPORTATION_MODE"] = "TRANSPORTATION_MODE";
  AmazonValidationMessageColumnName2["DELIVERY_CONDITIONS"] = "DELIVERY_CONDITIONS";
  AmazonValidationMessageColumnName2["SELLER_DEPART_VAT_NUMBER_COUNTRY"] = "SELLER_DEPART_VAT_NUMBER_COUNTRY";
  AmazonValidationMessageColumnName2["SELLER_DEPART_COUNTRY_VAT_NUMBER"] = "SELLER_DEPART_COUNTRY_VAT_NUMBER";
  AmazonValidationMessageColumnName2["SELLER_ARRIVAL_VAT_NUMBER_COUNTRY"] = "SELLER_ARRIVAL_VAT_NUMBER_COUNTRY";
  AmazonValidationMessageColumnName2["SELLER_ARRIVAL_COUNTRY_VAT_NUMBER"] = "SELLER_ARRIVAL_COUNTRY_VAT_NUMBER";
  AmazonValidationMessageColumnName2["TRANSACTION_SELLER_VAT_NUMBER_COUNTRY"] = "TRANSACTION_SELLER_VAT_NUMBER_COUNTRY";
  AmazonValidationMessageColumnName2["TRANSACTION_SELLER_VAT_NUMBER"] = "TRANSACTION_SELLER_VAT_NUMBER";
  AmazonValidationMessageColumnName2["BUYER_VAT_NUMBER_COUNTRY"] = "BUYER_VAT_NUMBER_COUNTRY";
  AmazonValidationMessageColumnName2["BUYER_VAT_NUMBER"] = "BUYER_VAT_NUMBER";
  AmazonValidationMessageColumnName2["VAT_CALCULATION_IMPUTATION_COUNTRY"] = "VAT_CALCULATION_IMPUTATION_COUNTRY";
  AmazonValidationMessageColumnName2["TAXABLE_JURISDICTION"] = "TAXABLE_JURISDICTION";
  AmazonValidationMessageColumnName2["TAXABLE_JURISDICTION_LEVEL"] = "TAXABLE_JURISDICTION_LEVEL";
  AmazonValidationMessageColumnName2["VAT_INV_NUMBER"] = "VAT_INV_NUMBER";
  AmazonValidationMessageColumnName2["VAT_INV_CONVERTED_AMT"] = "VAT_INV_CONVERTED_AMT";
  AmazonValidationMessageColumnName2["VAT_INV_CURRENCY_CODE"] = "VAT_INV_CURRENCY_CODE";
  AmazonValidationMessageColumnName2["VAT_INV_EXCHANGE_RATE"] = "VAT_INV_EXCHANGE_RATE";
  AmazonValidationMessageColumnName2["VAT_INV_EXCHANGE_RATE_DATE"] = "VAT_INV_EXCHANGE_RATE_DATE";
  AmazonValidationMessageColumnName2["EXPORT_OUTSIDE_EU"] = "EXPORT_OUTSIDE_EU";
  AmazonValidationMessageColumnName2["INVOICE_URL"] = "INVOICE_URL";
  AmazonValidationMessageColumnName2["BUYER_NAME"] = "BUYER_NAME";
  AmazonValidationMessageColumnName2["ARRIVAL_ADDRESS"] = "ARRIVAL_ADDRESS";
  AmazonValidationMessageColumnName2["SUPPLIER_NAME"] = "SUPPLIER_NAME";
  AmazonValidationMessageColumnName2["SUPPLIER_VAT_NUMBER"] = "SUPPLIER_VAT_NUMBER";
  AmazonValidationMessageColumnName2["TAX_REPORTING_SCHEME"] = "TAX_REPORTING_SCHEME";
  AmazonValidationMessageColumnName2["TAX_COLLECTION_RESPONSIBILITY"] = "TAX_COLLECTION_RESPONSIBILITY";
  return AmazonValidationMessageColumnName2;
})(AmazonValidationMessageColumnName || {});
export var AmazonValidationMessageValidationCode = /* @__PURE__ */ ((AmazonValidationMessageValidationCode2) => {
  AmazonValidationMessageValidationCode2["WrongTemplateColumns"] = "WrongTemplateColumns";
  AmazonValidationMessageValidationCode2["WrongHeader"] = "WrongHeader";
  AmazonValidationMessageValidationCode2["TaxableBasisReportingExceededTheLimit"] = "TaxableBasisReportingExceededTheLimit";
  AmazonValidationMessageValidationCode2["OutOfPeriodTransactions"] = "OutOfPeriodTransactions";
  AmazonValidationMessageValidationCode2["Required"] = "Required";
  AmazonValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  AmazonValidationMessageValidationCode2["RequiredReportingFields"] = "RequiredReportingFields";
  AmazonValidationMessageValidationCode2["WrongExchangeRate"] = "WrongExchangeRate";
  AmazonValidationMessageValidationCode2["InvalidData"] = "InvalidData";
  AmazonValidationMessageValidationCode2["InvalidDecimal"] = "InvalidDecimal";
  AmazonValidationMessageValidationCode2["ConstraintNotFulfilled"] = "ConstraintNotFulfilled";
  AmazonValidationMessageValidationCode2["TransactionIsInTheFuture"] = "TransactionIsInTheFuture";
  AmazonValidationMessageValidationCode2["TransactionIsBeforeService"] = "TransactionIsBeforeService";
  AmazonValidationMessageValidationCode2["UnsupportedCurrency"] = "UnsupportedCurrency";
  AmazonValidationMessageValidationCode2["Empty"] = "Empty";
  AmazonValidationMessageValidationCode2["IntrastatEmpty"] = "IntrastatEmpty";
  return AmazonValidationMessageValidationCode2;
})(AmazonValidationMessageValidationCode || {});
