import i18next from "i18next";
import { QUESTIONS } from "./questions";
export const QuestionnaireStepLabels = {
  "business-details": i18next.t("Business Details"),
  "tax-details": i18next.t("Tax Details"),
  "country-details": i18next.t("Country Details"),
  "personnel-details": i18next.t("Personnel Details"),
  "banking-information": i18next.t("Banking Information")
};
export const DEFAULT_QUESTIONNAIRE_STEPS = [
  { stepName: QuestionnaireStepLabels["business-details"], validity: "dont-know" },
  { stepName: QuestionnaireStepLabels["tax-details"], validity: "dont-know" },
  { stepName: QuestionnaireStepLabels["country-details"], validity: "dont-know" },
  { stepName: QuestionnaireStepLabels["personnel-details"], validity: "dont-know" },
  { stepName: QuestionnaireStepLabels["banking-information"], validity: "dont-know" }
];
export const getGroupsAndValidationsByStep = (step, failures) => {
  const failedQuestionIds = failures.filter((failure) => QUESTIONS.filter((q) => q.Step === step).find((question) => question.Id === failure.propertyName)).map((f) => f.propertyName);
  return Array.from(new Set(QUESTIONS.filter((q) => failedQuestionIds.some((id) => id === q.Id)).map((question) => question.GroupName)));
};
export const getGroupsAndValidations = (failures) => {
  const groupsAndValidationsByStep = /* @__PURE__ */ new Map();
  groupsAndValidationsByStep.set("business-details", getGroupsAndValidationsByStep("business-details", failures));
  groupsAndValidationsByStep.set("tax-details", getGroupsAndValidationsByStep("tax-details", failures));
  groupsAndValidationsByStep.set("country-details", getGroupsAndValidationsByStep("country-details", failures));
  groupsAndValidationsByStep.set("personnel-details", getGroupsAndValidationsByStep("personnel-details", failures));
  groupsAndValidationsByStep.set("banking-information", getGroupsAndValidationsByStep("banking-information", failures));
  return groupsAndValidationsByStep;
};
