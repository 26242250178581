import "./dc-fileupload2.scss";
import { html } from "lit-html";
import { useMemo, useState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { newUniqueId } from "GlobalShared/helpers/stringHelper";
import { validateExtension } from "./uiHelper";
import classNames from "classnames";
import { uploadIcon } from "GlobalShared/templates/commons";
const DEFAULTS = {
  label: i18next.t("Choose file"),
  tooltip: "",
  accept: [],
  readonly: false,
  fileName: "",
  fileReference: "",
  multiple: false,
  showFileLink: true,
  loaderPosition: "left"
};
export class UploadEvent extends CustomEvent {
  constructor(detail) {
    super("upload", { detail });
  }
}
export const Component = (host) => {
  var _a, _b;
  const getFileDownloadLink = (reference) => {
    if (props.clientId) {
      return `${window.location.origin}/api/v1/Questionnaire/Company/${props.clientId}/Files/${reference}`;
    }
    return `${window.location.origin}/api/v1/Questionnaire/Files/${reference}`;
  };
  const props = {
    label: host.label !== void 0 ? host.label : DEFAULTS.label,
    accept: host.accept !== void 0 ? host.accept : DEFAULTS.accept,
    validationMessage: host.validationMessage,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    fileName: host.fileName !== void 0 ? host.fileName : DEFAULTS.fileName,
    tooltip: host.tooltip !== void 0 ? host.tooltip : DEFAULTS.tooltip,
    fileReference: host.fileReference !== void 0 ? host.fileReference : DEFAULTS.fileReference,
    multiple: host.multiple !== void 0 ? host.multiple : DEFAULTS.multiple,
    clientId: host.clientId,
    withLoader: host.withLoader,
    loaderPosition: (_a = host.loaderPosition) != null ? _a : DEFAULTS.loaderPosition,
    linkGenerator: host.linkGenerator !== void 0 ? host.linkGenerator : getFileDownloadLink,
    showFileLink: host.showFileLink !== void 0 ? host.showFileLink : DEFAULTS.showFileLink,
    customClass: host.customClass,
    withUploadIcon: (_b = host.withUploadIcon) != null ? _b : false
  };
  const onChange = (e) => {
    e.stopPropagation();
    if (!props.readonly) {
      const files2 = e.currentTarget.files;
      let allValid = true;
      for (let i = 0; i < files2.length; i++) {
        const file = files2[i];
        if (!validateExtension(file, props.accept)) {
          setFileValidationResult(`The file you are trying to upload is not allowed. <br/> Accepted file formats: ${props.accept.join(", ")}`);
          allValid = false;
        }
      }
      if (allValid) {
        setFiles(files2);
        host.dispatchEvent(
          new UploadEvent({
            files: files2
          })
        );
      }
    }
  };
  const [files, setFiles] = useState(void 0);
  const [fileValidationResult, setFileValidationResult] = useState(void 0);
  const uniqueId = useMemo(() => newUniqueId(), []);
  return html`
		<div class=${props.customClass ? props.customClass : "flex flex-col items-end "}>
			${props.label !== void 0 ? html`
						<div class=${classNames("flex items-center", { "justify-center gap-1": props.withLoader })}>
							${props.loaderPosition === "left" ? html`<dc-loader2 class="mr-3" .isLoading=${props.withLoader}></dc-loader2>` : ""}
							<label
								class=${classNames(
    "h-10 border-0 font-bold text-sm text-white items-center px-6 py-2 rounded-md cursor-pointer bg-brand-primary hover:bg-brand-primary-dark flex flex-row",
    {
      "pointer-events-none opacity-50": props.readonly
    }
  )}
								for=${uniqueId}
							>
								${props.withUploadIcon ? html`<div class="mr-2">${uploadIcon()}</div>` : ""}
								<div>${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""}</div>
							</label>
							${props.loaderPosition === "right" ? html`<dc-loader2 class="ml-3" .isLoading=${props.withLoader}></dc-loader2>` : ""}
						</div>
				  ` : ""}
			<div class="flex flex-col items-end">
				${props.readonly ? "" : html`
							<input
								type="file"
								class="hidden"
								accept=${props.accept.join(",")}
								files=${files}
								?multiple=${props.multiple}
								id=${uniqueId}
								@change=${onChange}
								@click=${function() {
    this.value = null;
  }}
							/>
					  `}
				${props.showFileLink && props.fileName.length > 0 ? html`<div>
							<a class="link" download=${props.fileName} href=${props.linkGenerator(props.fileReference)}
								>${props.fileName.substring(0, 20)}${props.fileName.length >= 20 ? "..." : ""}</a
							>
					  </div>` : ""}
			</div>
		</div>
		${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
		${fileValidationResult ? html` <div class="validation-result">${unsafeHTML(fileValidationResult)}</div> ` : ""}
	`;
};
customElements.define(
  "dc-fileupload2",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: ["label", "accept", "validationMessage", "readonly", "fileName", "fileReference"]
  })
);
