import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import cookie from "js-cookie";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { toBoolean } from "GlobalShared/helpers/booleanHelper";
export const name = "ww-cookie-consent";
export const Component = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  useEffect(() => {
    const cookieConsentResult = cookie.get("cookie-consent-tracking-allowed");
    if (cookieConsentResult !== void 0 && toBoolean(cookieConsentResult)) {
      setShowCookieConsent(false);
    } else {
      setShowCookieConsent(true);
    }
  }, []);
  const handleCookieConsentClick = () => {
    setShowCookieConsent(false);
    cookie.set("cookie-consent-tracking-allowed", "true", { expires: 365, secure: true, sameSite: "strict" });
  };
  return showCookieConsent ? html`
				<div class="transition fixed z-100 bottom-0 inset-x-0 pb-2 sm:pb-5 opacity-100 scale-100 translate-y-0 ease-out duration-500">
					<div class="max-w-screen-xl mx-auto px-2 sm:px-4">
						<div class="p-2 rounded-lg bg-white border-2 border-solid border-brand-primary shadow-lg sm:p-3">
							<div class="flex items-center justify-between flex-wrap">
								<div class="w-0 flex-1 flex items-center">
									<p class="ml-3 font-medium text-black">
										${unsafeHTML(
    i18next.t(
      "cookie-consent-description",
      "This website uses cookies to provide necessary site functionality and improve your online experience. By using this website, you agree to use of cookies as outlines in {{- link_start}}Tax Desk's online privacy statement{{- link_end}}.",
      {
        link_start: '<a class="underline text-brand-primary cursor-pointer" href="https://www.taxdesk.com/privacy-policy/" target="_blank" >',
        link_end: "</a>"
      }
    )
  )}
									</p>
								</div>
								<div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
									<button type="button" class="-mr-1 ml-2 flex p-2 rounded-md text-white bg-orange-500 hover:bg-gray-800" @click="${() => handleCookieConsentClick()}">
										${i18next.t("I Understand")}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
		  ` : html``;
};
