import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { useState, useEffect, component } from "haunted";
import "./dc-multiswitch.scss";
import { html } from "lit-html";
import { useId } from "GlobalShared/haunted/CustomHooks";
import classNames from "classnames";
const DEFAULTS = {
  selectedItem: void 0,
  dataSource: [],
  readonly: false
};
export class ChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail });
  }
}
export const Component = (host) => {
  const props = {
    selectedItem: host.selectedItem !== void 0 ? host.selectedItem : DEFAULTS.selectedItem,
    dataSource: host.dataSource !== void 0 ? host.dataSource : DEFAULTS.dataSource,
    label: host.label,
    tooltip: host.tooltip,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    validationMessage: host.validationMessage,
    customLabelClass: host.customLabelClass
  };
  const convertToMultiSwitchItem = (inputItems) => {
    if (inputItems === void 0) {
      return void 0;
    }
    return inputItems.map((selVal) => typeof selVal === "string" ? { label: selVal, value: selVal } : selVal);
  };
  const onSelected = (index) => {
    if (props.readonly || index === selectedIndex)
      return;
    setSelectedIndex(index);
    const selectedItem = convertedDataSource[index];
    host.dispatchEvent(
      new ChangeEvent({
        value: selectedItem.value
      })
    );
  };
  const [convertedDataSource, setConvertedDataSource] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const uniqueId = useId();
  useEffect(() => {
    if (props.dataSource) {
      const temp = convertToMultiSwitchItem(props.dataSource);
      const newIndex = temp.findIndex((d) => d.value === props.selectedItem);
      setSelectedIndex(newIndex);
      setConvertedDataSource(temp);
    }
  }, [props.selectedItem, props.dataSource]);
  const labelTemplate = () => props.label !== void 0 ? html`
					<div class="flex gap-1 pb-2">
						<label class=${classNames({ [props.customLabelClass]: props.customLabelClass, "form-label my-auto": !props.customLabelClass })}>${props.label}</label>
						${!isEmpty(props.tooltip) ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""}
					</div>
			  ` : "";
  return html`
		<div>
			${labelTemplate()}
			<div class="flex border border-brand-primary rounded-l-xl rounded-r-xl max-w-max h-10">
				${convertedDataSource.map((item, index) => {
    const id = `${uniqueId}_${item.label}`;
    return html` <input
							type="radio"
							id=${id}
							name=${uniqueId}
							@change=${(e) => {
      e.stopPropagation();
      onSelected(index);
    }}
							?checked=${index === selectedIndex}
							?disabled=${props.readonly}
						/>
						<label
							class=${classNames("selector-label", {
      "cursor-pointer": !props.readonly,
      "border-r border-brand-primary": index !== convertedDataSource.length - 1,
      "bg-red-200": props.validationMessage
    })}
							for=${id}
						>
							${item.label}
						</label>`;
  })}
			</div>
			${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
		</div>
	`;
};
customElements.define(
  "dc-multiswitch",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
