import "./dc-accordion.scss";
import { html } from "lit-html";
import { ref } from "../../shared/haunted/HooksHelpers";
import { component, useState } from "haunted";
import classNames from "classnames";
import { useEffect, useRef } from "GlobalShared/haunted/CustomHooks";
export const Component = (host) => {
  const props = {
    isOpen: host.isOpen,
    headerTitle: host.headerTitle,
    onClick: host.onClick,
    contentTemplate: host.contentTemplate ? host.contentTemplate : () => html``
  };
  const elem = useRef(void 0);
  const [openedHeight, setOpenedHeight] = useState(0);
  useEffect(() => setOpenedHeight(props.isOpen && elem.current ? elem.current.scrollHeight + 48 : 0), [props.contentTemplate, props.isOpen]);
  const headerClassNames = classNames("dc-accordion-header select-none p-4 flex relative cursor-pointer text-2xl", {
    open: props.isOpen
  });
  return html`
		<div class="accordion-container bg-white shadow-lg">
			<div class=${headerClassNames} @click=${props.onClick}>
				<div class="w-full">${props.headerTitle}</div>
				<div class="accordion-arrow w-4 h-4 absolute border border-solid border-gray-800 common-transition border-r-0 border-b-0 border-t-2 border-l-2"></div>
			</div>
			<div ref=${ref(elem)} class="accordion-content" style=${`max-height: ${openedHeight}px`}>${props.contentTemplate()}</div>
		</div>
	`;
};
customElements.define(
  "dc-accordion",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
