var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useLoadingReducer, useMemo, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { templateTitle } from "GlobalShared/templates/commons";
import { useEUCountries, useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import {
  mapSubmissionConfig
} from "GlobalShared/models/ServicesModels";
import {
  Company as CompanyClient,
  Companies as CompaniesClient,
  VatRegistration as VatRegistrationClient,
  IossRegistration as IossRegistrationClient,
  SubmissionPeriods as SubmissionPeriodsClient,
  Orders as OrdersClient,
  Questionnaire as QuestionnaireClient,
  Subscriptions as SubscriptionsClient,
  OssRegistration as OssRegistrationClient
} from "WebServices/APIs/self/clients";
import { mapCompanyDetailToCompanyServicesModel } from "GlobalShared/mappers/ServicesMapper";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { currentLocale, getRouteByKey } from "../../globals";
import { useServices } from "GlobalShared/components/services/services";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { addHandler, navigateTo, removeOptionals } from "GlobalShared/router";
import { useManageServices } from "./admin-services-manage";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
import { VatRegistrationSubscriptionEventType } from "WebServices/APIs/self/models/V1/Subscriptions/VatRegistrationSubscriptionAbstraction";
import { VatRegistrationSubscription } from "WebServices/APIs/self/models/V1/Subscriptions";
import classNames from "classnames";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { CompanyDetailClientSource } from "WebServices/APIs/self/models/V1/Companies/CompanyInfo/CompanyDetail";
export function AdminServices() {
  const isManageMode = () => {
    return currentPathname.includes("manage") && !(ClientContext == null ? void 0 : ClientContext.IsPartner);
  };
  const manageServices = () => {
    navigateTo(`${removeOptionals(getRouteByKey("admin/services(/manage)"))}/manage`);
  };
  const loadPredefinedBankAccounts = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield vatRegistrationClient.getPredefinedBankAccounts();
        const config = response.data.map((pre) => {
          const vm = {
            Id: pre.Id,
            BankAddress: pre.BankAddress,
            BankName: pre.BankName,
            BeneficiaryAddress: pre.BeneficiaryAddress,
            BeneficiaryName: pre.BeneficiaryName,
            CountryCode: pre.CountryCode,
            EstablishmentCountryIsInEU: pre.EstablishmentCountryIsInEU,
            Iban: pre.Iban,
            Swift: pre.Swift
          };
          return vm;
        });
        setPredefinedBankAccounts(config);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const loadSubmissionConfig = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield submissionPeriodsClient.getEffectiveSubmissionConfig();
        setSubmissionConfig(mapSubmissionConfig(response.data));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const worldCountries = useWorldCountries(currentLocale);
  const [currentCompanyDetail, setCurrentCompanyDetail] = useState(void 0);
  const currentCompanyServices = useMemo(() => {
    return currentCompanyDetail ? mapCompanyDetailToCompanyServicesModel(worldCountries, currentCompanyDetail) : void 0;
  }, [currentCompanyDetail]);
  const callbackHandler = useMemo(
    () => ({
      downloadVatRegDocument: (vatRegId, templateId, type) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.downloadVatRegDocument({
              vatRegId,
              templateId,
              type
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadVatDeregistrationDocument: (vatRegId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.downloadDeregistrationDocument({
              vatRegId
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadIossDeregistrationDocument: (_registrationId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield iossRegistrationClient.downloadDeregistrationDocument();
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadOssDeregistrationDocument: (_registrationId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield ossRegistrationClient.downloadDeregistrationDocument();
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadPoaRevokedConfirmationDocument: (vatRegId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.downloadPoaRevokeConfirmationDocument({
              vatRegId
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      uploadVatDocument: (_registrationId) => __async(this, null, function* () {
        return "";
      }),
      uploadIossDocument: (_registrationId) => __async(this, null, function* () {
        return "";
      }),
      uploadOssDocument: (_registrationId) => __async(this, null, function* () {
        return "";
      }),
      downloadVatCertificate: (vatRegId, certType) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield vatRegistrationClient.downloadVatCertificate({
              vatRegId,
              certificateType: certType
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      uploadSignedVatRegDocument: (vatRegId, templateId, file) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            yield vatRegistrationClient.uploadSignedVatRegDocument({
              vatRegId,
              templateId,
              file
            });
            yield getCompanyServices();
            notifySuccess(i18next.t("Document uploaded successfully"));
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      uploadVatCertificate: (_vatRegId, _file) => __async(this, null, function* () {
        return "";
      }),
      generateVatRegDocument: (_vatRegId, _templateId) => __async(this, null, function* () {
      }),
      changeVatRegStatus: (_vatRegId, _newStatus) => __async(this, null, function* () {
      }),
      finalizeVatRegUpload: (vatRegId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            yield vatRegistrationClient.finalizeUpload({
              vatRegId
            });
            yield getCompanyServices();
            notifySuccess(i18next.t("Upload finalized successfully"));
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      updateClientAcceptance: (_status, _completed) => __async(this, null, function* () {
      }),
      changeIossRegistrationStatus: (_registrationId, _newStatus) => __async(this, null, function* () {
      }),
      finalizeIOSSUpload: (_registrationId) => __async(this, null, function* () {
        withErrorHandling(
          () => __async(this, null, function* () {
            yield iossRegistrationClient.finalizeUpload({});
            yield getCompanyServices();
            notifySuccess("IOSS Registration Status Changed!");
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      uploadIOSSCertificate: (_registrationId, _file) => __async(this, null, function* () {
        return "";
      }),
      uploadIOSSSignedRegistrationDocument: (_registrationId, documentId, file) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const result = yield iossRegistrationClient.uploadSignedRegistrationDocument({
              documentId,
              file
            });
            yield getCompanyServices();
            notifySuccess("Signed document uploaded");
            return result.data;
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadIOSSCertificate: (_registrationId) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield iossRegistrationClient.downloadCertificate({});
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      downloadIOSSRegistrationDocument: (_registrationId, documentId, type) => __async(this, null, function* () {
        return withErrorHandling(
          () => __async(this, null, function* () {
            const response = yield iossRegistrationClient.downloadRegistrationDocument({
              type,
              documentId
            });
            simulateDownload(response.data);
          }),
          (error) => {
            notifyError(error);
          }
        );
      }),
      getPossibleIossStatuses: () => {
        return [];
      },
      getPossibleOssStatuses: () => {
        return [];
      },
      getPossibleVatStatuses: () => {
        return [];
      },
      saveCompanyDetails: (_companyDetails) => __async(this, null, function* () {
      }),
      changeBankAccountConfig: (_vatRegId, _bankAccountConfig) => __async(this, null, function* () {
      }),
      changePaymentReference: (_vatRegId, _paymentReference) => __async(this, null, function* () {
      }),
      changeFrequencies: (_vatRegId, _sendEmail, _returnType, _newFrequencyConfigs, _frequencyConfigIdsToBeRemoved) => __async(this, null, function* () {
      }),
      cancelSubscription: (_subscriptionId, _cancellationDate) => __async(this, null, function* () {
      }),
      updateInvoicing: (_isEnabled) => __async(this, null, function* () {
      }),
      saveAnswer: (_companyId, answer) => __async(this, null, function* () {
        yield questionnaireClient.saveModification({ body: [answer] });
      }),
      getQuestionnaire: (_companyId) => __async(this, null, function* () {
        return (yield questionnaireClient.getQuestionnaire()).data;
      }),
      generatePeriods: (_companyId) => __async(this, null, function* () {
      }),
      searchRegNotificationSubscriptions: (_companyId, _vatRegistrationId, _eventType) => __async(this, null, function* () {
        return void 0;
      }),
      subscribeRegNotification: (_companyId, vatRegistrationId, eventType, _userId) => __async(this, null, function* () {
        const searchEventType = eventType === "ESL" ? VatRegistrationSubscriptionEventType.ESLTaxReturnType : eventType === "VLS" ? VatRegistrationSubscriptionEventType.VLSTaxReturnType : void 0;
        if (searchEventType === void 0) {
          throw new Error(`Unknown event type ${eventType}`);
        }
        yield subscriptionsClient.subscribe({
          body: new VatRegistrationSubscription({
            VatRegistrationId: vatRegistrationId,
            EventType: searchEventType
          })
        });
      }),
      unsubscribeRegNotification: (_companyId, vatRegistrationId, eventType, _userId) => __async(this, null, function* () {
        const searchEventType = eventType === "ESL" ? VatRegistrationSubscriptionEventType.ESLTaxReturnType : eventType === "VLS" ? VatRegistrationSubscriptionEventType.VLSTaxReturnType : void 0;
        if (searchEventType === void 0) {
          throw new Error(`Unknown event type ${eventType}`);
        }
        yield subscriptionsClient.unSubscribe({
          body: new VatRegistrationSubscription({
            VatRegistrationId: vatRegistrationId,
            EventType: searchEventType
          })
        });
      }),
      changeTaxIdentifier: (_companyId, _vatRegistrationId, _taxIdentifier) => __async(this, null, function* () {
      }),
      changeTaxOfficeId: (_companyId, _vatRegistrationId, _taxOfficeId) => __async(this, null, function* () {
      }),
      changeOssPaymentReference: (_companyId, _ossRegistrationId, _paymentReference) => __async(this, null, function* () {
      }),
      addVatDataUploadMethod: (_companyId, _vatDataUploadMethod, _templates) => __async(this, null, function* () {
      })
    }),
    [currentCompanyServices]
  );
  const getCompanyServices = () => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        dispatchLoading("inc");
        const companyDetailResult = yield companyClient.getCompanyDetail({ locale: currentLocale });
        setCurrentCompanyDetail(companyDetailResult.data);
      }),
      (error) => {
        notifyError(error);
      },
      () => dispatchLoading("dec")
    );
  });
  const canCreateNewOrderFunc = () => __async(this, null, function* () {
    try {
      dispatchLoading("inc");
      const pendingOrder = yield ordersClient.canCreateNewOrder();
      return pendingOrder.data;
    } catch (error) {
      notifyError(error);
      return false;
    } finally {
      dispatchLoading("dec");
    }
  });
  const companyClient = useClient(CompanyClient);
  const companiesClient = useClient(CompaniesClient);
  const ordersClient = useClient(OrdersClient);
  const vatRegistrationClient = useClient(VatRegistrationClient);
  const iossRegistrationClient = useClient(IossRegistrationClient);
  const ossRegistrationClient = useClient(OssRegistrationClient);
  const submissionPeriodsClient = useClient(SubmissionPeriodsClient);
  const questionnaireClient = useClient(QuestionnaireClient);
  const subscriptionsClient = useClient(SubscriptionsClient);
  const euCountries = useEUCountries(currentLocale);
  const [currentPathname, setCurrentPathname] = useState(window.location.pathname);
  const [loading, dispatchLoading] = useLoadingReducer();
  const [predefinedBankAccounts, setPredefinedBankAccounts] = useState(void 0);
  const [submissionConfig, setSubmissionConfig] = useState(void 0);
  const [canCreateNewOrder, setCanCreateNewOrder] = useState(void 0);
  const servicesHook = useServices({
    predefinedBankAccounts,
    submissionConfig,
    companyDetail: currentCompanyDetail,
    companyServicesModel: currentCompanyServices,
    callbackHandler,
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    worldCountries,
    euCountries
  });
  const init = () => __async(this, null, function* () {
    loadPredefinedBankAccounts();
    if (!isManageMode()) {
      yield getCompanyServices();
    }
    loadSubmissionConfig();
    return addHandler("services", (location) => {
      setCurrentPathname(location.pathname);
    });
  });
  useEffect(init, []);
  useEffect(() => __async(this, null, function* () {
    if (isManageMode()) {
      const result = yield canCreateNewOrderFunc();
      setCanCreateNewOrder(result);
      if (result) {
        yield companiesClient.reValidateVatNumber();
        getCompanyServices();
      }
    } else {
      setCanCreateNewOrder(void 0);
    }
  }), [currentPathname]);
  const manageServicesHook = useManageServices({
    currentCompanyServices,
    euCountries,
    worldCountries,
    isManageMode: isManageMode(),
    canCreateOrder: canCreateNewOrder
  });
  const loadTemplate = () => html`
			<div class=${classNames("font-bold flex justify-between")}>
				${templateTitle(i18next.t("Services"))}
				${PrimaryButton({ onClick: manageServices, class: (currentCompanyDetail == null ? void 0 : currentCompanyDetail.ClientSource) === CompanyDetailClientSource.Partner ? "hidden" : "", text: i18next.t("Manage Services") })}
			</div>
			${servicesHook.mainTemplate()}
		`;
  return html`
		<div class="my-2 mb-16 mt-8 mx-auto max-w-5xl">
			${isManageMode() ? html`
						${templateTitle(i18next.t("Manage services"))}
						<div>${manageServicesHook.htmlMain()}</div>
				  ` : html`${loading.count > 0 || servicesHook.isLoading ? html`<dc-loader .isLoading=${true}></dc-loader>` : loadTemplate()}`}
		</div>
	`;
}
