var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { TypeOfGoods } from "./../models/V1/ComplianceMasterData/TypeOfGoods";
export class TaxCodeMasterData {
  /**
   * Creates an instance of TaxCodeMasterData.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof TaxCodeMasterData
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetTypeOfGoodsRq_ _getTypeOfGoodsRq
   * @returns Promise<GetTypeOfGoodsRq_>
   * @memberof TaxCodeMasterData
   */
  getTypeOfGoods(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/TaxCodeMasterData/TypeOfGoods", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: TypeOfGoods, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
