var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { leftPad } from "GlobalShared/helpers/stringHelper";
import { useState } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { useEffect, useLoadingReducer, useRef } from "GlobalShared/haunted/CustomHooks";
import { ref } from "GlobalShared/haunted/HooksHelpers";
const RADIO_PAYMENT_INPUT_NAME = "radio-payment-input";
const RADIO_PAYMENT_INPUT_ID_PREFIX = "radio_payment_input_";
let moduleScopeTotalPrice;
export const useOrderSummary = (props) => {
  const radioPaymentInputId = (index) => `${RADIO_PAYMENT_INPUT_ID_PREFIX}${index}`;
  const canConfirmOrder = () => {
    return selectedPayment === "BankTransferPayment" && !paymentPending && isValidSummary && !confirmOrderActionInProgress;
  };
  const isSimpleConfirmButtonVisible = () => {
    return selectedPayment === "BankTransferPayment" || !selectedPayment;
  };
  const resetSummary = () => {
    setShowErrorMessages(false);
    setSelectedPayment(void 0);
    setContractDownloaded(false);
    setContractUploaded(false);
    setTAndCAccepted(false);
    setGdprAccepted(false);
    setContractErrorMessage(void 0);
    setGdprErrorMessage(void 0);
  };
  const isPayPalConfirmButtonVisible = () => {
    return selectedPayment === "PayPalPayment" && isValidSummary;
  };
  const payWithBankTransfer = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a;
        if (!canConfirmOrder()) {
          setShowErrorMessages(true);
          if (!tAndCAccepted) {
            setContractErrorMessage(i18next.t("Please accept the contract"));
          }
          if (!gdprAccepted) {
            setGdprErrorMessage(i18next.t("Please consent to process and keep your personal data"));
          }
          return;
        }
        if (selectedPayment && selectedPayment === "BankTransferPayment" && canConfirmOrder()) {
          setShowErrorMessages(false);
          setContractErrorMessage(void 0);
          setGdprErrorMessage(void 0);
          if (!paymentPending) {
            setPaymentPending(true);
            const tempPayment = {
              type: "BankTransferPayment",
              quotedPrice: paymentInfo.totalPrice
            };
            setConfirmOrderActionInProgress(true);
            yield props.payAndConfirm(tempPayment, (_a = props.order) == null ? void 0 : _a.Id);
          }
        }
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        setConfirmOrderActionInProgress(false);
      }
    );
  });
  const payWithPayPal = (reference) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        var _a;
        if (!paymentPending) {
          setPaymentPending(true);
          const tempPayment = {
            type: "PayPalPayment",
            quotedPrice: paymentInfo.totalPrice,
            reference
          };
          yield props.payAndConfirm(tempPayment, (_a = props.order) == null ? void 0 : _a.Id);
        }
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const uploadContract = (blob, fileName) => __async(void 0, null, function* () {
    if (!contractDownloaded) {
      const file = new File([blob], `${fileName}.pdf`, { type: blob.type });
      withErrorHandling(
        () => __async(void 0, null, function* () {
          var _a;
          yield props.uploadContract((_a = props.order) == null ? void 0 : _a.Id, file);
          setContractUploaded(true);
        }),
        (error) => {
          notifyError(error);
        }
      );
    }
  });
  const handleGdprCheckboxChange = (e) => {
    e.preventDefault();
    setGdprErrorMessage(void 0);
    setGdprAccepted(e.detail.checked);
  };
  const handleTermsAndConditionsCheckboxChange = (e) => {
    e.preventDefault();
    if (!contractDownloaded) {
      setContractErrorMessage(i18next.t("Please download and read the contract"));
      return;
    }
    setContractErrorMessage(void 0);
    setTAndCAccepted(e.detail.checked);
  };
  const handleDownloadContract = () => __async(void 0, null, function* () {
    var _a, _b;
    if (((_a = props.order) == null ? void 0 : _a.Id) === void 0)
      return;
    if (props.isSignup) {
      const { blob, fileName } = yield props.generateContractPdf();
      setContractDownloaded(true);
      uploadContract(blob, fileName);
    } else {
      if (props.hasContractReference) {
        props.downloadContract((_b = props.order) == null ? void 0 : _b.Id);
        setContractDownloaded(true);
      } else {
        const { blob, fileName } = yield props.generateContractPdf();
        setContractDownloaded(true);
        uploadContract(blob, fileName);
      }
    }
    setContractErrorMessage(void 0);
  });
  const handlePayPalConfirmButtonClick = () => {
    if (!isValidSummary) {
      setShowErrorMessages(true);
      if (!tAndCAccepted) {
        setContractErrorMessage(i18next.t("Please accept the contract"));
      }
      if (!gdprAccepted) {
        setGdprErrorMessage(i18next.t("Please consent to process and keep your personal data"));
      }
      return;
    }
    setShowErrorMessages(false);
  };
  const [selectedPayment, setSelectedPayment] = useState(void 0);
  const [tAndCAccepted, setTAndCAccepted] = useState(false);
  const [gdprAccepted, setGdprAccepted] = useState(false);
  const [contractDownloaded, setContractDownloaded] = useState(false);
  const [contractUploaded, setContractUploaded] = useState(false);
  const [paymentPending, setPaymentPending] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(void 0);
  const [loading, dispatchLoading] = useLoadingReducer();
  const payPalButtonContainer = useRef(void 0);
  const [isValidSummary, setIsValidSummary] = useState(false);
  const [confirmOrderActionInProgress, setConfirmOrderActionInProgress] = useState(false);
  const [showErrorMessages, setShowErrorMessages] = useState(false);
  const [contractErrorMessage, setContractErrorMessage] = useState(void 0);
  const [gdprErrorMessage, setGdprErrorMessage] = useState(void 0);
  const previousPayPalButtonContainer = useRef(void 0);
  useEffect(() => {
    setIsValidSummary(props.isParentValid && tAndCAccepted && gdprAccepted && contractDownloaded && (props.hasContractReference || contractUploaded));
  }, [props.isParentValid, tAndCAccepted, gdprAccepted, contractDownloaded, props.hasContractReference, contractUploaded]);
  useEffect(() => {
    if ((paymentInfo == null ? void 0 : paymentInfo.availablePaymentTypes.some((s) => s === "PayPalPayment")) && selectedPayment === "PayPalPayment" && isValidSummary && payPalButtonContainer.current !== void 0 && previousPayPalButtonContainer.current !== payPalButtonContainer.current) {
      const ppbc = document.getElementById("paypal-button-container");
      if (ppbc !== null) {
        window.paypal.Buttons({
          createOrder: (_data, actions) => {
            dispatchLoading("inc");
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: moduleScopeTotalPrice.Currency,
                    value: moduleScopeTotalPrice.Value
                  }
                }
              ]
            });
          },
          onApprove: (_data, actions) => {
            return actions.order.capture().then((details) => {
              var _a, _b, _c;
              const transactionId = (_c = (_b = (_a = details == null ? void 0 : details.purchase_units[0]) == null ? void 0 : _a.payments) == null ? void 0 : _b.captures[0]) == null ? void 0 : _c.id;
              dispatchLoading("dec");
              notifySuccess(i18next.t("Payment successful! We will redirect you in a few seconds."));
              payWithPayPal(transactionId);
            });
          },
          onCancel: (_data) => {
            dispatchLoading("dec");
          },
          onError: (err) => {
            dispatchLoading("dec");
            notifyError(err);
          }
        }).render("#paypal-button-container");
        previousPayPalButtonContainer.current = payPalButtonContainer.current;
      }
    }
  }, [paymentInfo, payPalButtonContainer.current, selectedPayment, isValidSummary]);
  useEffect(() => {
    if (paymentInfo) {
      moduleScopeTotalPrice = paymentInfo.totalPrice;
    }
  }, [paymentInfo]);
  useEffect(() => __async(void 0, null, function* () {
    var _a, _b;
    if ((_a = props.order) == null ? void 0 : _a.Id) {
      try {
        dispatchLoading("inc");
        const pi = yield props.getPaymentInfo((_b = props.order) == null ? void 0 : _b.Id);
        setPaymentInfo(pi);
      } catch (error) {
        if (error.statusCode !== 409) {
          notifyError(error);
        }
      } finally {
        dispatchLoading("dec");
      }
    }
  }), [props.order]);
  const confirmButtonTemplate = () => {
    return html`
			${isSimpleConfirmButtonVisible() ? html`
						<button
							class=${"btn btn-primary btn-full h-12 ml-8 mt-8"}
							@click=${() => {
      payWithBankTransfer();
    }}
						>
							${confirmOrderActionInProgress ? `${i18next.t("Saving")}...` : i18next.t("Confirm Order")}
						</button>
				  ` : ""}
			${isPayPalConfirmButtonVisible() ? html`
						<div class="mt-4 text-sm">
							<span>${i18next.t("Confirm order and pay with")}</span>
							<div ref=${ref(payPalButtonContainer)} id="paypal-button-container"></div>
						</div>
				  ` : ""}
			${!isValidSummary && selectedPayment === "PayPalPayment" ? html` <button class=${"btn btn-primary btn-full h-12 ml-8 mt-8"} @click=${handlePayPalConfirmButtonClick}>${i18next.t("Confirm Order")}</button> ` : ""}
		`;
  };
  const mainTemplate = () => {
    return html`<div class="text-2xl font-bold pt-4">${i18next.t("Pay with")}</div>
			<div class="md:flex md:justify-between m-auto">
				<div class="lg:w-3/4">
					${paymentInfo === void 0 ? html`<div>${i18next.t("Loading")}...</div>` : ""}
					<label for=${radioPaymentInputId(4)} class="lg:w-3/4">
						${(paymentInfo == null ? void 0 : paymentInfo.availablePaymentTypes.some((s) => s === "BankTransferPayment")) ? html`<div
									class="btn-radio tall mt-4 ${selectedPayment && selectedPayment === "BankTransferPayment" ? "selected" : ""}"
									@click=${() => {
      setSelectedPayment("BankTransferPayment");
    }}
							  >
									<div class="flex items-center">
										<input type="radio" name=${RADIO_PAYMENT_INPUT_NAME} ?checked=${selectedPayment === "BankTransferPayment"} id=${radioPaymentInputId(4)} />
										<div class="ml-4 flex items-center">
											<svg
												aria-hidden="true"
												focusable="false"
												data-prefix="fas"
												data-icon="money-check-alt"
												class="h-6"
												role="img"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 640 512"
											>
												<path
													fill="currentColor"
													d="M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zM176 327.88V344c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V152c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07zM416 312c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h112c4.42 0 8 3.58 8 8v16zm160 0c0 4.42-3.58 8-8 8h-80c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16zm0-96c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h272c4.42 0 8 3.58 8 8v16z"
												></path>
											</svg>
											<span class="ml-4">${i18next.t("Bank Transfer")}</span>
										</div>
									</div>
							  </div>` : ""}
					</label>

					<label for=${radioPaymentInputId(5)} class="lg:w-3/4">
						${(paymentInfo == null ? void 0 : paymentInfo.availablePaymentTypes.some((s) => s === "PayPalPayment")) ? html`<div
									class="btn-radio tall mt-4 ${selectedPayment && selectedPayment === "PayPalPayment" ? "selected" : ""}"
									@click=${() => {
      setSelectedPayment("PayPalPayment");
    }}
							  >
									<div class="flex items-center">
										<input type="radio" name=${RADIO_PAYMENT_INPUT_NAME} ?checked=${selectedPayment === "PayPalPayment"} id=${radioPaymentInputId(5)} />
										<div class="ml-4 flex items-center">
											<img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" alt="PayPal Logo" />
										</div>
									</div>
							  </div>` : ""}
					</label>

					${showErrorMessages && !selectedPayment ? html` <div class="text-red-400 text-sm font-bold mt-2">${i18next.t("Please select a payment method")}</div>` : ""}
					${selectedPayment && selectedPayment === "BankTransferPayment" ? html`
								<div class="lg:w-3/4 mt-8 p-4 rounded-xl bg-gray-200">
									<p>${i18next.t("Bank account holder name")}: Tax International Services Kft.</p>
									<p>${i18next.t("Bank account holder address")}: 1068 Budapest Dózsa György út 84/B.</p>
									<p>${i18next.t("IBAN")}: HU61 1200 1008 0184 1342 0020 0005</p>
									<p>${i18next.t("SWIFT")}: UBRTHUHB</p>
									<p>${i18next.t("Bank name")}: Raiffeisen Bank</p>
									<p>${i18next.t("Payment reference")}: ${leftPad(props.companyId, 6, "0")}</p>
								</div>
						  ` : ""}

					<div class="mt-8">
						<div class="flex items-center gap-4 mt-8">
							<div>
								<dc-checkbox
									.checked=${tAndCAccepted}
									.label=${i18next.t("I accept the Contract")}
									@change=${(e) => {
      handleTermsAndConditionsCheckboxChange(e);
    }}
								></dc-checkbox>
							</div>
							<span class="text-sm hover:underline cursor-pointer" @click=${handleDownloadContract}>${i18next.t("Download Contract")}</span>
						</div>
						${contractErrorMessage && (!contractDownloaded || !tAndCAccepted) ? html`<div class="text-red-400 font-bold text-sm my-2">${contractErrorMessage}</div> ` : ""}
						<dc-checkbox
							.checked=${gdprAccepted}
							.label="${i18next.t("I ask for and I explicitly consent to the following")}:"
							@change=${(e) => handleGdprCheckboxChange(e)}
						></dc-checkbox>
						<div class="mt-2 italic text-sm pl-8">
							${i18next.t(
      "I unconditionally give my consent to Tax Desk to process and keep my personal data, as listed or referred to in the Data Protection terms, beyond my entity's current registration with the Tax Authorities, in order to enable Tax Desk to use such data for future registration(s), but for no longer a period of time than the official expiry/invalidity of such data or for a maximum of 10 years."
    )}
						</div>
						${showErrorMessages && !gdprAccepted && gdprErrorMessage ? html`<div class="text-red-400 text-sm font-bold mt-2">${gdprErrorMessage}</div>` : ""}
					</div>
				</div>
				<div class="lg:w-1/4 md:ml-8 pt-8 lg:pt-1">
					<div style="min-width:260px" class="border border-gray-400 p-4">
						${props.priceBreakdownTemplate()}
						<div>${confirmButtonTemplate()}</div>
					</div>
				</div>
				${loading.count > 0 ? html`<dc-loader></dc-loader>` : ""}
			</div>`;
  };
  return { mainTemplate, resetSummary };
};
