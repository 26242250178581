var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import i18next from "i18next";
import { useState } from "GlobalShared/haunted/CustomHooks";
import { QuestionnaireContext } from "GlobalShared/components/q11e/QuestionnaireContext";
import { QUESTIONS } from "GlobalShared/components/q11e/questions";
import { convertAnswerEvent } from "GlobalShared/components/q11e/answer-utils";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
export const useVatNumbersModal = (props) => {
  const onAnswerChanged = (e) => __async(void 0, null, function* () {
    const answer = convertAnswerEvent(e);
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.saveAnswer(props.companyDetail.Id, answer);
        setQ11eContext(q11eContext.getNewContextWithSetAnswer(answer));
        notifySuccess("Saved successfully");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [showModal, setShowModal] = useState(false);
  const [q11eContext, setQ11eContext] = useState(void 0);
  const show = () => __async(void 0, null, function* () {
    setQ11eContext(
      new QuestionnaireContext(
        yield props.getQuestionnaire(props.companyDetail.Id),
        props.isAdmin,
        props.euCountries,
        QUESTIONS,
        props.companyDetail,
        props.permissionManager.hasPermission("Client_Modify")
      )
    );
    setShowModal(true);
  });
  const template = () => {
    return showModal ? html`
					<div>
						<dc-modal
							.visible=${showModal}
							@close=${() => setShowModal(false)}
							.header=${i18next.t("Vat Numbers")}
							.content=${html`<div style="height: 50vh">
								<ww-answer-existing-vat-numbers
									.q11e=${q11eContext}
									@change=${onAnswerChanged}
									.questionId=${"exs_vats"}
									.euCountries=${props.euCountries}
								></ww-answer-existing-vat-numbers>
							</div>`}
						>
						</dc-modal>
					</div>
			  ` : html``;
  };
  return { show, template };
};
