var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../../converters/DateConverter";
export class Registration {
  constructor(_data) {
    /**
     */
    __publicField(this, "Country");
    /**
     */
    __publicField(this, "CreatedAt");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Identifier");
    /**
     */
    __publicField(this, "IdentifierType");
    /**
     */
    __publicField(this, "PspId");
    /**
     */
    __publicField(this, "PspIdentifier");
    /**
     */
    __publicField(this, "PspIdentifierType");
    /**
     */
    __publicField(this, "PspName");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "SubscriptionEnabled");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Registration
     */
    __publicField(this, "$type", "V1.Cesop.Registrations.Search.Registration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Country = _data["Country"];
      this.CreatedAt = DateConverter.from(_data["CreatedAt"]);
      this.Id = _data["Id"];
      this.Identifier = _data["Identifier"];
      this.IdentifierType = RegistrationIdentifierType[_data["IdentifierType"]];
      this.PspId = _data["PspId"];
      this.PspIdentifier = _data["PspIdentifier"];
      this.PspIdentifierType = RegistrationPspIdentifierType[_data["PspIdentifierType"]];
      this.PspName = _data["PspName"];
      this.Status = RegistrationStatus[_data["Status"]];
      this.SubscriptionEnabled = _data["SubscriptionEnabled"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Country"] = this.Country;
    _data["CreatedAt"] = DateConverter.toUtcDateString(this.CreatedAt);
    _data["Id"] = this.Id;
    _data["Identifier"] = this.Identifier;
    _data["IdentifierType"] = RegistrationIdentifierType[this.IdentifierType];
    _data["PspId"] = this.PspId;
    _data["PspIdentifier"] = this.PspIdentifier;
    _data["PspIdentifierType"] = RegistrationPspIdentifierType[this.PspIdentifierType];
    _data["PspName"] = this.PspName;
    _data["Status"] = RegistrationStatus[this.Status];
    _data["SubscriptionEnabled"] = this.SubscriptionEnabled;
    return _data;
  }
}
export var RegistrationIdentifierType = /* @__PURE__ */ ((RegistrationIdentifierType2) => {
  RegistrationIdentifierType2["Bic"] = "Bic";
  RegistrationIdentifierType2["Other"] = "Other";
  return RegistrationIdentifierType2;
})(RegistrationIdentifierType || {});
export var RegistrationPspIdentifierType = /* @__PURE__ */ ((RegistrationPspIdentifierType2) => {
  RegistrationPspIdentifierType2["Bic"] = "Bic";
  RegistrationPspIdentifierType2["Other"] = "Other";
  return RegistrationPspIdentifierType2;
})(RegistrationPspIdentifierType || {});
export var RegistrationStatus = /* @__PURE__ */ ((RegistrationStatus2) => {
  RegistrationStatus2["New"] = "New";
  RegistrationStatus2["InProgress"] = "InProgress";
  RegistrationStatus2["Completed"] = "Completed";
  return RegistrationStatus2;
})(RegistrationStatus || {});
