var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import * as redux from "redux";
import { combineReducers } from "redux-immutable";
const _ReduxContext = class _ReduxContext {
  static dispatch(action) {
    _ReduxContext.store.dispatch(action);
  }
  static registerReducer(key, reducer) {
    _ReduxContext.reducerRegistry[key] = reducer;
  }
  static init() {
    const w = window;
    const rootReducer = combineReducers(_ReduxContext.reducerRegistry);
    _ReduxContext.store = redux.createStore(rootReducer, w.__REDUX_DEVTOOLS_EXTENSION__ && w.__REDUX_DEVTOOLS_EXTENSION__());
  }
};
__publicField(_ReduxContext, "store");
__publicField(_ReduxContext, "reducerRegistry", {});
export let ReduxContext = _ReduxContext;
