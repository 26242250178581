var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { File } from "./File";
import { DateConverter } from "./../../../../converters/DateConverter";
export class SourceFile {
  constructor(_data) {
    /**
     */
    __publicField(this, "FileInfo");
    /**
     */
    __publicField(this, "FileName");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "UploadedAtUtc");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SourceFile
     */
    __publicField(this, "$type", "V1.Cesop.Periods.SourceFile");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["FileInfo"]) {
        const fileInfo_ = new File();
        this.FileInfo = fileInfo_.init(_data["FileInfo"]);
      }
      this.FileName = _data["FileName"];
      this.Id = _data["Id"];
      this.Status = SourceFileStatus[_data["Status"]];
      this.UploadedAtUtc = DateConverter.from(_data["UploadedAtUtc"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["FileInfo"] = this.FileInfo ? this.FileInfo.toJSON() : void 0;
    _data["FileName"] = this.FileName;
    _data["Id"] = this.Id;
    _data["Status"] = SourceFileStatus[this.Status];
    _data["UploadedAtUtc"] = DateConverter.toIsoUtcString(this.UploadedAtUtc);
    return _data;
  }
}
export var SourceFileStatus = /* @__PURE__ */ ((SourceFileStatus2) => {
  SourceFileStatus2["New"] = "New";
  SourceFileStatus2["Uploaded"] = "Uploaded";
  SourceFileStatus2["Processing"] = "Processing";
  SourceFileStatus2["Validated"] = "Validated";
  SourceFileStatus2["ValidationError"] = "ValidationError";
  return SourceFileStatus2;
})(SourceFileStatus || {});
