var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { getCurrentFullPath } from "GlobalShared/router";
import { Profile as ProfileClient } from "WebServices/APIs/self/clients";
import i18next from "i18next";
import "./profile-select.scss";
import { extractFirstErrorMessage, notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { useProfileEditor } from "./profile-editor";
import { UpdateProfile } from "WebServices/APIs/self/models/V1/Profiles";
export const observedAttributes = [];
export const useShadowDOM = false;
export const name = "ww-profile-select";
export const Component = (host) => {
  const onToggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };
  const getProfile = () => __async(void 0, null, function* () {
    const response = yield profileClient.getProfile();
    setProfile(response.data);
    return response.data;
  });
  const onSaveProfile = (model) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => {
        profileClient.updateProfile({
          body: new UpdateProfile({
            NewEmail: model.Email,
            TwoFactorEnabled: model.TwoFactorEnabled
          })
        });
        if (model.Email && model.Email !== profile.Email) {
          notifySuccess(i18next.t("Email change request sent to your new email address. Please check your new inbox"));
        } else {
          notifySuccess(i18next.t("Profile updated successfully"));
        }
        profileEditor.close();
      },
      (error) => {
        if (error.statusCode === 409) {
          notifyError(i18next.t("Email already exists"));
        } else {
          if (error.statusCode >= 400 && error.statusCode < 500) {
            notifyWarning(extractFirstErrorMessage(error, i18next.t("Error occured")));
          } else {
            notifyError(error);
          }
        }
      }
    );
  });
  const openProfile = () => __async(void 0, null, function* () {
    const resp = yield getProfile();
    setProfileMenuOpen(false);
    setProfileEditorProps({
      model: resp,
      onSave: onSaveProfile
    });
    profileEditor.open();
  });
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [profile, setProfile] = useState(void 0);
  const profileClient = useClient(ProfileClient);
  const [profileEditorProps, setProfileEditorProps] = useState();
  const profileEditor = useProfileEditor(profileEditorProps);
  const init = () => __async(void 0, null, function* () {
    const onClickedOutside = (e) => {
      if (!host.contains(e.target)) {
        setProfileMenuOpen(false);
      }
    };
    const onCloseOnEscKey = (e) => {
      if (e.key === "Escape") {
        setProfileMenuOpen(false);
      }
    };
    document.addEventListener("keyup", onCloseOnEscKey, true);
    document.addEventListener("click", onClickedOutside);
    yield getProfile();
    return () => {
      document.removeEventListener("keyup", onCloseOnEscKey);
      document.removeEventListener("click", onClickedOutside);
    };
  });
  useEffect(init, []);
  return html`<div class="relative bg-white w-full ${!ClientContext.UserName ? "hidden" : ""}">
		<a @click=${onToggleProfileMenu} class="text-gray-800 font-semibold cursor-pointer px-4 flex justify-between select-none">
			${ClientContext.UserName}
			<div class="${profileMenuOpen ? "-rotate-90" : "rotate-90"} block transform ml-2">&#10095;</div>
		</a>
		<div class="${profileMenuOpen ? "block  shadow-lg" : "hidden"} absolute bg-white w-full border border-t-1 border-gray-300">
			<div class="relative px-4 py-2">
				<form method="POST" action="/account/logout">
					<input type="hidden" name="returnUrl" value="${getCurrentFullPath()}" />
					<button class="w-full text-left" type="submit">${i18next.t("Logout")}</button>
				</form>
			</div>
			<div class="px-4 py-2">
				<button
					class="w-full text-left"
					@click=${() => {
    openProfile();
  }}
				>
					${i18next.t("My Profile")}
				</button>
			</div>
		</div>
		${profileEditor.template}
	</div>`;
};
