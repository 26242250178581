var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { BusinessDetailFactory } from "./../BusinessDetailFactory";
export class CreateCompanyRequest {
  constructor(_data) {
    /**
     */
    __publicField(this, "BusinessDetail");
    /**
     */
    __publicField(this, "BusinessType");
    /**
     */
    __publicField(this, "PreferredLanguage");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    __publicField(this, "$type", "V1.Companies.Create.CreateCompanyRequest");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      const $typeBusinessDetail = _data["BusinessDetail"] && _data["BusinessDetail"].$type;
      if ($typeBusinessDetail) {
        const businessDetail_ = BusinessDetailFactory.create($typeBusinessDetail);
        this.BusinessDetail = businessDetail_.init(_data["BusinessDetail"]);
      } else {
        this.BusinessDetail = _data["BusinessDetail"];
      }
      this.BusinessType = CreateCompanyRequestBusinessType[_data["BusinessType"]];
      this.PreferredLanguage = _data["PreferredLanguage"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["BusinessDetail"] = this.BusinessDetail ? this.BusinessDetail.toJSON() : void 0;
    _data["BusinessType"] = CreateCompanyRequestBusinessType[this.BusinessType];
    _data["PreferredLanguage"] = this.PreferredLanguage;
    return _data;
  }
}
export var CreateCompanyRequestBusinessType = /* @__PURE__ */ ((CreateCompanyRequestBusinessType2) => {
  CreateCompanyRequestBusinessType2["Marketplace"] = "Marketplace";
  CreateCompanyRequestBusinessType2["Enterprise"] = "Enterprise";
  return CreateCompanyRequestBusinessType2;
})(CreateCompanyRequestBusinessType || {});
