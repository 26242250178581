var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  addMonths,
  formatDate,
  getEndOfQuarterByDate,
  getFirstDayOfMonth,
  getFirstDayOfNextMonth,
  getLastDayOfMonth,
  getLastDayOfPreviousMonth,
  getStartOfNextQuarter
} from "GlobalShared/helpers/dateHelper";
export const CreatedServiceFactory = (isAdmin) => ({
  createCountryBasedSubscriptionService: (code, country, startDate, endDate) => {
    if (!isAdmin && code === "VatMonthlyCompliance") {
      throw new Error("Service not supported on web: " + code);
    }
    return {
      Type: "Country",
      Code: code,
      Country: country,
      StartDate: startDate,
      EndDate: endDate
    };
  },
  createSimpleService: (code) => {
    if (!isAdmin && (code === "GlobalChangeCompanyDetail" || code === "GlobalCommunicatingWithTaxAuthorities")) {
      throw new Error("Service not supported on web: " + code);
    }
    return {
      Type: "Simple",
      Code: code
    };
  },
  createHourlyService: (code, hours) => {
    if (!isAdmin && code === "GlobalGeneralVatAdvice") {
      throw new Error("Service not supported on web: " + code);
    }
    return {
      Type: "Hourly",
      Code: code,
      Hours: hours
    };
  },
  createCustomPricedService: (code, price) => {
    if (!isAdmin && code === "GlobalSpecialistVatAdvice") {
      throw new Error("Service not supported on web: " + code);
    }
    return {
      Type: "CustomPriced",
      Code: code,
      Price: price
    };
  },
  createGlobalSubscriptionService: (code, startDate, endDate) => {
    return {
      Type: "GlobalSubscription",
      Code: code,
      StartDate: startDate,
      EndDate: endDate
    };
  }
});
export const mapToCreatedServices = (isAdmin, today, priceConfig, services) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const result = [];
  const currentPeriodStartDate = getFirstDayOfMonth(today);
  const serviceFactory = CreatedServiceFactory(isAdmin);
  getSelectedVatServices(services.vatServices).forEach((vatCountry) => {
    const countryPriceConfig = priceConfig.perCountry[vatCountry.code];
    if (vatCountry.selected && vatCountry.vatStartingDate && isNewRegistration(vatCountry)) {
      if ((countryPriceConfig == null ? void 0 : countryPriceConfig.registration) === void 0) {
        throw new Error(`Registration fee is missing for country: ${vatCountry.code}`);
      }
      const vatReg = serviceFactory.createCountryBasedSubscriptionService("VatRegistration", vatCountry.code, vatCountry.vatStartingDate);
      result.push(vatReg);
    }
    if (vatCountry.selected && vatCountry.vatStartingDate && !isNewRegistration(vatCountry)) {
      if ((countryPriceConfig == null ? void 0 : countryPriceConfig.takeOver) === void 0) {
        throw new Error(`Takeover fee is missing for country: ${vatCountry.code}`);
      }
      const vatReg = serviceFactory.createCountryBasedSubscriptionService("VatTakeOver", vatCountry.code, vatCountry.vatStartingDate);
      result.push(vatReg);
    }
    const { effectiveComplianceStartDate, newComplianceStartDate } = calcNewComplianceStartDate(vatCountry, currentPeriodStartDate);
    const newComplianceEndDate = calcNewComplianceEndDate(newComplianceStartDate);
    if (vatCountry.selected && !vatCountry.monthlyCompliance) {
      if ((countryPriceConfig == null ? void 0 : countryPriceConfig.compliance) === void 0) {
        throw new Error(`Compliance fee is missing for country: ${vatCountry.code}`);
      }
      const vatCompliance = serviceFactory.createCountryBasedSubscriptionService("VatCompliance", vatCountry.code, newComplianceStartDate, newComplianceEndDate);
      if (priceConfig.perCountry[vatCountry.code].homeCountryCompliance) {
        const vatHomeCountryCompliance = serviceFactory.createCountryBasedSubscriptionService("VatHomeCountryCompliance", vatCountry.code, newComplianceStartDate, newComplianceEndDate);
        result.push(vatHomeCountryCompliance);
      }
      result.push(vatCompliance);
    }
    if (vatCountry.selected && !vatCountry.monthlyCompliance && effectiveComplianceStartDate < currentPeriodStartDate) {
      if ((countryPriceConfig == null ? void 0 : countryPriceConfig.retro) === void 0) {
        throw new Error(`Retrospective fee is missing for country: ${vatCountry.code}`);
      }
      const vatRetrospectiveReturn = serviceFactory.createCountryBasedSubscriptionService(
        "VatRetrospectiveReturn",
        vatCountry.code,
        effectiveComplianceStartDate,
        getLastDayOfPreviousMonth(currentPeriodStartDate)
      );
      result.push(vatRetrospectiveReturn);
    }
    if (vatCountry.retrospective && vatCountry.retrospectiveStartDate !== void 0) {
      if ((countryPriceConfig == null ? void 0 : countryPriceConfig.retro) === void 0) {
        throw new Error(`Retrospective fee is missing for country: ${vatCountry.code}`);
      }
      const vatRetrospectiveReturn = serviceFactory.createCountryBasedSubscriptionService(
        "VatRetrospectiveReturn",
        vatCountry.code,
        getFirstDayOfMonth(vatCountry.retrospectiveStartDate),
        getLastDayOfPreviousMonth(vatCountry.origComplianceStartDate)
      );
      result.push(vatRetrospectiveReturn);
    }
    if (isAdmin && vatCountry.selected && vatCountry.monthlyCompliance && vatCountry.monthlyComplianceEndDate) {
      if ((countryPriceConfig == null ? void 0 : countryPriceConfig.monthlyCompliance) === void 0) {
        throw new Error(`Monthly compliance fee is missing for country: ${vatCountry.code}`);
      }
      if (!vatCountry.origComplianceEndDate) {
        throw new Error(`Monthly compliance is only available in addition for a compliance: ${vatCountry.code}`);
      }
      const vatMonhtlyCompliance = serviceFactory.createCountryBasedSubscriptionService(
        "VatMonthlyCompliance",
        vatCountry.code,
        getFirstDayOfNextMonth(vatCountry.origComplianceEndDate),
        getLastDayOfMonth(vatCountry.monthlyComplianceEndDate)
      );
      result.push(vatMonhtlyCompliance);
    }
    if (vatCountry.intrastatIn) {
      const intrastatIn = serviceFactory.createCountryBasedSubscriptionService(
        "VatIntrastatIn",
        vatCountry.code,
        canSetIntrastatStartDate(vatCountry.intrastatIn, vatCountry.origComplianceStartDate, vatCountry.origComplianceEndDate, vatCountry.origIntrastatInEndDate) ? getFirstDayOfMonth(vatCountry.intrastatInDate) : vatCountry.origIntrastatInEndDate !== void 0 ? getFirstDayOfNextMonth(vatCountry.origIntrastatInEndDate) : effectiveComplianceStartDate,
        calcIntrastatEndDate(vatCountry, newComplianceEndDate)
      );
      result.push(intrastatIn);
    }
    if (vatCountry.intrastatOut) {
      const intrastatOut = serviceFactory.createCountryBasedSubscriptionService(
        "VatIntrastatOut",
        vatCountry.code,
        canSetIntrastatStartDate(vatCountry.intrastatOut, vatCountry.origComplianceStartDate, vatCountry.origComplianceEndDate, vatCountry.origIntrastatOutEndDate) ? getFirstDayOfMonth(vatCountry.intrastatOutDate) : vatCountry.origIntrastatOutEndDate !== void 0 ? getFirstDayOfNextMonth(vatCountry.origIntrastatOutEndDate) : effectiveComplianceStartDate,
        calcIntrastatEndDate(vatCountry, newComplianceEndDate)
      );
      result.push(intrastatOut);
    }
    if (vatCountry.selected && priceConfig.perCountry[vatCountry.code].fiscalRep && !vatCountry.monthlyCompliance) {
      const vatFiscalRepresentative = serviceFactory.createCountryBasedSubscriptionService("VatFiscalRepresentative", vatCountry.code, today);
      result.push(vatFiscalRepresentative);
    }
    if (vatCountry.selected && priceConfig.perCountry[vatCountry.code].filingAgent && !vatCountry.monthlyCompliance) {
      const filingAgent = serviceFactory.createCountryBasedSubscriptionService("VatFilingAgent", vatCountry.code, today);
      result.push(filingAgent);
    }
    if (vatCountry.selfRevision) {
      const selfRevision = serviceFactory.createCountryBasedSubscriptionService("VatSelfRevision", vatCountry.code, vatCountry.selfRevisionStartDate, vatCountry.selfRevisionEndDate);
      result.push(selfRevision);
    }
    if (vatCountry.deregistration) {
      const deregistration = serviceFactory.createCountryBasedSubscriptionService("VatDeregistration", vatCountry.code, today, void 0);
      result.push(deregistration);
    }
  });
  if (services.euEori) {
    if ((priceConfig == null ? void 0 : priceConfig.eoriWithoutUK) === void 0) {
      throw new Error("EU EORI fee is missing");
    }
    const euEoriService = serviceFactory.createSimpleService("GlobalEoriEu");
    result.push(euEoriService);
  }
  if (services.ukEori) {
    if ((priceConfig == null ? void 0 : priceConfig.eoriWithUK) === void 0) {
      throw new Error("UK EORI fee is missing");
    }
    const ukEoriService = serviceFactory.createSimpleService("GlobalEoriUk");
    result.push(ukEoriService);
  }
  if (isAdmin && ((_a = services.globalService) == null ? void 0 : _a.changeCompanyDetail)) {
    if ((priceConfig == null ? void 0 : priceConfig.changeCompanyDetail) === void 0) {
      throw new Error("Change company detail fee is missing");
    }
    const changeCompanyDetail = serviceFactory.createSimpleService("GlobalChangeCompanyDetail");
    result.push(changeCompanyDetail);
  }
  if (isAdmin && ((_b = services.globalService) == null ? void 0 : _b.generalVatAdvice) && ((_c = services.globalService) == null ? void 0 : _c.generalVatAdviceHours)) {
    if ((priceConfig == null ? void 0 : priceConfig.generalVatAdviceHourlyFee) === void 0) {
      throw new Error("General VAT advice fee is missing");
    }
    const generalVatAdvice = serviceFactory.createHourlyService("GlobalGeneralVatAdvice", services.globalService.generalVatAdviceHours);
    result.push(generalVatAdvice);
  }
  if (isAdmin && ((_d = services.globalService) == null ? void 0 : _d.specialistVatAdvice) && ((_f = (_e = services.globalService) == null ? void 0 : _e.specialistVatAdvicePrice) == null ? void 0 : _f.Value)) {
    if ((priceConfig == null ? void 0 : priceConfig.specialistVatAdvice) === void 0) {
      throw new Error("Specialist VAT advice fee is missing");
    }
    const specialistVatAdvice = serviceFactory.createCustomPricedService("GlobalSpecialistVatAdvice", {
      Currency: services.globalService.specialistVatAdvicePrice.Currency,
      Value: services.globalService.specialistVatAdvicePrice.Value
    });
    result.push(specialistVatAdvice);
  }
  if (isAdmin && ((_g = services.globalService) == null ? void 0 : _g.communicatingWithTaxAuthorities)) {
    if ((priceConfig == null ? void 0 : priceConfig.communicatingWithTaxAuthorities) === void 0) {
      throw new Error("Communicating with Tax Auth fee is missing");
    }
    const communicatingWithTaxAuthorities = serviceFactory.createSimpleService("GlobalCommunicatingWithTaxAuthorities");
    result.push(communicatingWithTaxAuthorities);
  }
  if (((_h = services.iossSelection) == null ? void 0 : _h.tier) !== void 0) {
    if (services.iossService.origTier === void 0 && services.iossService) {
      const iossReg = serviceFactory.createGlobalSubscriptionService(services.iossService.hasIossNumber ? "IossTakeOver" : "IossRegistration", today);
      result.push(iossReg);
    }
    const iossCompliance = serviceFactory.createGlobalSubscriptionService(
      getIossComplianceServiceTier(services.iossSelection.tier),
      today,
      services.iossSelection.tier === "Tier0" ? addMonths(currentPeriodStartDate, 12) : void 0
    );
    result.push(iossCompliance);
  }
  if ((_i = services.iossService) == null ? void 0 : _i.deregistration) {
    if (((_j = priceConfig == null ? void 0 : priceConfig.ioss) == null ? void 0 : _j.deregistration) === void 0) {
      throw new Error("IOSS deregistration fee is missing");
    }
    const deregistration = serviceFactory.createGlobalSubscriptionService("IossDeregistration", today);
    result.push(deregistration);
  }
  if ((_k = services.ossSelection) == null ? void 0 : _k.countryCode) {
    if (services.ossService.hasOssNumber !== void 0) {
      const ossReg = serviceFactory.createGlobalSubscriptionService(services.ossService.hasOssNumber ? "OssTakeOver" : "OssRegistration", today);
      const ossRegObj = __spreadProps(__spreadValues({}, ossReg), {
        Country: services.ossSelection.countryCode
      });
      result.push(ossRegObj);
    }
    if (services.ossService.selectedQuarterOption) {
      const renewedQuarterStart = getStartOfNextQuarter(services.ossService.currentExpirationDate);
      const renewedQuarterEnd = getEndOfQuarterByDate(services.ossSelection.quarterlyComplianceEndDate);
      const ossQuarterlyCompliance = serviceFactory.createGlobalSubscriptionService("OssQuarterlyCompliance", renewedQuarterStart, renewedQuarterEnd);
      const ossQuarterlyComplianceObj = __spreadProps(__spreadValues({}, ossQuarterlyCompliance), {
        Country: services.ossSelection.countryCode
      });
      result.push(ossQuarterlyComplianceObj);
    } else {
      const ossCompliance = serviceFactory.createGlobalSubscriptionService("OssCompliance", today);
      const ossComplianceObj = __spreadProps(__spreadValues({}, ossCompliance), {
        Country: services.ossSelection.countryCode
      });
      result.push(ossComplianceObj);
    }
  }
  return result;
};
export const getIossComplianceServiceTier = (iossTier) => {
  if (iossTier === "Tier0")
    return "IossComplianceTier0";
  if (iossTier === "Tier1")
    return "IossComplianceTier1";
  if (iossTier === "Tier2")
    return "IossComplianceTier2";
  if (iossTier === "Tier3")
    return "IossComplianceTier3";
  return void 0;
};
export const getSelectedVatServices = (vatCountryServices) => {
  var _a;
  return (_a = vatCountryServices == null ? void 0 : vatCountryServices.filter((vatCountryService) => vatCountryService.selected || vatCountryService.origComplianceEndDate !== void 0)) != null ? _a : [];
};
const isNewRegistration = (vatCountry) => {
  return vatCountry.hasVatNumber === false;
};
export const calcNewComplianceStartDate = (vatCountry, currentPeriodStartDate) => {
  var _a, _b;
  const effectiveComplianceStartDate = getFirstDayOfMonth(
    (_b = (_a = vatCountry.restartComplianceStartDate) != null ? _a : vatCountry.vatStartingDate) != null ? _b : vatCountry.origComplianceEndDate === void 0 ? currentPeriodStartDate : getFirstDayOfNextMonth(vatCountry.origComplianceEndDate)
  );
  const newComplianceStartDate = effectiveComplianceStartDate < currentPeriodStartDate ? currentPeriodStartDate : effectiveComplianceStartDate;
  return { effectiveComplianceStartDate, newComplianceStartDate };
};
export const calcNewComplianceEndDate = (complianceStartDate) => {
  return getLastDayOfMonth(addMonths(complianceStartDate, 11));
};
export const calcEffectiveComplianceEndDate = (vatCountry, today) => {
  if (!vatCountry.selected) {
    return vatCountry.origComplianceEndDate;
  }
  if (vatCountry.monthlyCompliance) {
    return getLastDayOfMonth(vatCountry.monthlyComplianceEndDate);
  } else {
    const currentPeriodStartDate = getFirstDayOfMonth(today);
    const { effectiveComplianceStartDate } = calcNewComplianceStartDate(vatCountry, currentPeriodStartDate);
    return calcNewComplianceEndDate(effectiveComplianceStartDate);
  }
};
const calcIntrastatEndDate = (vatCountry, newComplianceEndDate) => {
  let intrastatEndDate = vatCountry.origComplianceEndDate;
  if (vatCountry.selected) {
    if (vatCountry.monthlyCompliance) {
      intrastatEndDate = getLastDayOfMonth(vatCountry.monthlyComplianceEndDate);
    } else {
      intrastatEndDate = newComplianceEndDate;
    }
  }
  return intrastatEndDate;
};
export const canSetIntrastatStartDate = (instrastatSelected, origComplianceStartDate, origComplianceEndDate, origIntrastatEndDate) => {
  return instrastatSelected && (origComplianceEndDate === void 0 || origIntrastatEndDate === void 0 || formatDate(getLastDayOfMonth(origComplianceEndDate)) !== formatDate(getLastDayOfMonth(origIntrastatEndDate)) && getFirstDayOfMonth(origComplianceStartDate) > getLastDayOfMonth(origIntrastatEndDate));
};
