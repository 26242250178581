var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import jsPDF from "jspdf";
import * as PDFHelper from "./pdfHelper";
export class TextOptions {
  constructor() {
    __publicField(this, "size");
    __publicField(this, "isBold");
  }
}
export class PdfContext {
  constructor(pdfModel) {
    __publicField(this, "_doc");
    __publicField(this, "_model");
    // Alignment Constants in [mm]
    __publicField(this, "defaultFontSize", 9);
    __publicField(this, "defaultLineWidth", 0.1);
    __publicField(this, "sideMargin", 12);
    __publicField(this, "topMargin", 12);
    __publicField(this, "topStart", 20);
    __publicField(this, "marginBetweenColumns", 7);
    __publicField(this, "footerY", 290);
    // Columns
    __publicField(this, "indent", 5);
    __publicField(this, "textGap", 1.6);
    __publicField(this, "lineHeight", 6);
    __publicField(this, "textLineHeight", 4.4);
    __publicField(this, "firstNumberedX", this.sideMargin);
    __publicField(this, "firstBulletedX", this.sideMargin + this.indent);
    __publicField(this, "preTextOptions", (options) => {
      if (options == null ? void 0 : options.size) {
        this._doc.setFontSize(options == null ? void 0 : options.size);
      }
      if (options == null ? void 0 : options.isBold) {
        this._doc.setFont(this.doc.getFont().toString(), "bold");
      }
    });
    __publicField(this, "postTextOptions", (options) => {
      if (options == null ? void 0 : options.size) {
        this._doc.setFontSize(this.defaultFontSize);
      }
      if (options == null ? void 0 : options.isBold) {
        this._doc.setFont(this.doc.getFont().toString(), "normal");
      }
    });
    __publicField(this, "text", (text, x, y, options) => {
      this.preTextOptions(options);
      this._doc.text(text, x, y);
      this.postTextOptions(options);
    });
    __publicField(this, "textCentered", (text, y, options) => {
      this.preTextOptions(options);
      PDFHelper.textCentered(this._doc, text, y);
      this.postTextOptions(options);
    });
    __publicField(this, "horizontalLine", (x1, x2, y) => {
      this._doc.line(x1, y, x2, y);
    });
    __publicField(this, "verticalLine", (y1, y2, x) => {
      this._doc.line(x, y1, x, y2);
    });
    __publicField(this, "appendixLink", (text, pageNum, x, y) => {
      PDFHelper.appendixLink(text, pageNum, "", x, y, this._doc);
    });
    __publicField(this, "linkWithBrackets", (url, x, y, maxWidth) => {
      PDFHelper.linkWithBrackets(url, x, y, maxWidth, this._doc);
    });
    __publicField(this, "numberedText", (num, text, x, y) => {
      if (num === void 0) {
        PDFHelper.justifiedText(text, x, y, this.numberedTextWidth, this._doc);
      } else {
        PDFHelper.numberedText(num, text, x, y, this.numberedTextWidth, this._doc);
      }
    });
    __publicField(this, "numberedTextFirst", (num, text, y) => {
      this.numberedText(num, text, this.firstNumberedX, y);
    });
    __publicField(this, "numberedTextSecond", (num, text, y) => {
      this.numberedText(num, text, this.secondNumberedX, y);
    });
    __publicField(this, "bulletedText", (text, x, y, skipNum = false) => {
      if (skipNum) {
        PDFHelper.justifiedText(text, x, y, this.bulletedTextWidth, this._doc);
      } else {
        PDFHelper.bulletedText(text, x, y, this.bulletedTextWidth, this._doc);
      }
    });
    __publicField(this, "bulletedTextFirst", (text, y, skipNum = false) => {
      this.bulletedText(text, this.firstBulletedX, y, skipNum);
    });
    __publicField(this, "bulletedTextSecond", (text, y, skipNum = false) => {
      this.bulletedText(text, this.secondBulletedX, y, skipNum);
    });
    this._doc = new jsPDF({ unit: "mm" });
    this._model = pdfModel;
    this._doc.setFontSize(this.defaultFontSize);
    this._doc.setLineWidth(this.defaultLineWidth);
  }
  get doc() {
    return this._doc;
  }
  get model() {
    return this._model;
  }
  get pageWidth() {
    return this._doc.internal.pageSize.width;
  }
  get pageCenter() {
    return this.pageWidth / 2;
  }
  get contentWidth() {
    return this.pageWidth - 2 * this.sideMargin;
  }
  get secondNumberedX() {
    return this.pageCenter + this.marginBetweenColumns / 2;
  }
  get secondBulletedX() {
    return this.secondNumberedX + this.indent;
  }
  get numberedTextWidth() {
    return this.pageCenter - this.marginBetweenColumns / 2 - this.sideMargin - this.indent;
  }
  get bulletedTextWidth() {
    return this.pageCenter - this.marginBetweenColumns / 2 - this.sideMargin - 2 * this.indent;
  }
}
