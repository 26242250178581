var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { DataProcessingJobResult } from "./../models/V1/DataProcessingJobs/DataProcessingJobResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { CreateResult } from "./../models/V1/DataProcessingJobs/CreateResult";
import { SearchResult } from "./../models/V1/DataProcessingJobs/Search/SearchResult";
export class DataProcessingJobs {
  /**
   * Creates an instance of DataProcessingJobs.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof DataProcessingJobs
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetStatusRq_ _getStatusRq
   * @returns Promise<GetStatusRq_>
   * @memberof DataProcessingJobs
   */
  getStatus(pGetStatusRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/DataProcessingJobs/{id}", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "id", value: pGetStatusRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: DataProcessingJobResult },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteRq_ _deleteRq
   * @returns Promise<DeleteRq_>
   * @memberof DataProcessingJobs
   */
  delete(pDeleteRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/DataProcessingJobs/{id}", "DELETE", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "id", value: pDeleteRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreateRq_ _createRq
   * @returns Promise<CreateRq_>
   * @memberof DataProcessingJobs
   */
  create(pCreateRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/DataProcessingJobs/{complianceUploadId}", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "complianceUploadId", value: pCreateRq.complianceUploadId, required: true }]);
    client.addBody({ value: pCreateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CreateResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadFinishedRq_ _uploadFinishedRq
   * @returns Promise<UploadFinishedRq_>
   * @memberof DataProcessingJobs
   */
  uploadFinished(pUploadFinishedRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/DataProcessingJobs/{jobId}", "PATCH", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addQueryParams([{ name: "correlationId", value: pUploadFinishedRq.correlationId, required: true }]);
    client.addPathParams([{ name: "jobId", value: pUploadFinishedRq.jobId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SuppressWarningsRq_ _suppressWarningsRq
   * @returns Promise<SuppressWarningsRq_>
   * @memberof DataProcessingJobs
   */
  suppressWarnings(pSuppressWarningsRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/DataProcessingJobs/{id}/SuppressWarnings",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([{ name: "id", value: pSuppressWarningsRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchDataProcessingJobsRq_ _searchDataProcessingJobsRq
   * @returns Promise<SearchDataProcessingJobsRq_>
   * @memberof DataProcessingJobs
   */
  searchDataProcessingJobs(pSearchDataProcessingJobsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/DataProcessingJobs/search", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSearchDataProcessingJobsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof DataProcessingJobs
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/DataProcessingJobs/{id}/File",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/json",
      options
    );
    client.addPathParams([{ name: "id", value: pDownloadFileRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
