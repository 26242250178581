var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
import { Message } from "./Message";
import { SourceFile } from "./SourceFile";
import { SubmissionSummaryFactory } from "./SubmissionSummaryFactory";
import { ArchiveFileInfo } from "./ArchiveFileInfo";
import { SubmissionProofFile } from "./SubmissionProofFile";
import { CorrectionFileReferences } from "./CorrectionFileReferences";
export class PeriodVersion {
  constructor(_data) {
    /**
     */
    __publicField(this, "ArchiveFile");
    /**
     */
    __publicField(this, "CorrectionDeadline");
    /**
     */
    __publicField(this, "CorrectionFiles");
    /**
     */
    __publicField(this, "CreatedAtUtc");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Messages");
    /**
     */
    __publicField(this, "Payees");
    /**
     */
    __publicField(this, "SourceFiles");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "SubmissionProofFiles");
    /**
     */
    __publicField(this, "SubmittedAt");
    /**
     */
    __publicField(this, "Summary");
    /**
     */
    __publicField(this, "UploadType");
    /**
     */
    __publicField(this, "Version");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PeriodVersion
     */
    __publicField(this, "$type", "V1.Cesop.Periods.PeriodVersion");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["ArchiveFile"]) {
        const archiveFile_ = new ArchiveFileInfo();
        this.ArchiveFile = archiveFile_.init(_data["ArchiveFile"]);
      }
      this.CorrectionDeadline = DateConverter.from(_data["CorrectionDeadline"]);
      if (_data["CorrectionFiles"]) {
        const correctionFiles_ = new CorrectionFileReferences();
        this.CorrectionFiles = correctionFiles_.init(_data["CorrectionFiles"]);
      }
      this.CreatedAtUtc = DateConverter.from(_data["CreatedAtUtc"]);
      this.Id = _data["Id"];
      if (_data["Messages"] && _data["Messages"].constructor === Array) {
        this.Messages = [];
        for (let item of _data["Messages"]) {
          const messages_ = new Message();
          this.Messages.push(messages_.init(item));
        }
      }
      if (_data["Payees"]) {
        const payees_ = new SourceFile();
        this.Payees = payees_.init(_data["Payees"]);
      }
      if (_data["SourceFiles"] && _data["SourceFiles"].constructor === Array) {
        this.SourceFiles = [];
        for (let item of _data["SourceFiles"]) {
          const sourceFiles_ = new SourceFile();
          this.SourceFiles.push(sourceFiles_.init(item));
        }
      }
      this.Status = PeriodVersionStatus[_data["Status"]];
      if (_data["SubmissionProofFiles"] && _data["SubmissionProofFiles"].constructor === Array) {
        this.SubmissionProofFiles = [];
        for (let item of _data["SubmissionProofFiles"]) {
          const submissionProofFiles_ = new SubmissionProofFile();
          this.SubmissionProofFiles.push(submissionProofFiles_.init(item));
        }
      }
      this.SubmittedAt = DateConverter.from(_data["SubmittedAt"]);
      const $typeSummary = _data["Summary"] && _data["Summary"].$type;
      if ($typeSummary) {
        const summary_ = SubmissionSummaryFactory.create($typeSummary);
        this.Summary = summary_.init(_data["Summary"]);
      } else {
        this.Summary = _data["Summary"];
      }
      this.UploadType = PeriodVersionUploadType[_data["UploadType"]];
      this.Version = _data["Version"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ArchiveFile"] = this.ArchiveFile ? this.ArchiveFile.toJSON() : void 0;
    _data["CorrectionDeadline"] = DateConverter.toIsoUtcString(this.CorrectionDeadline);
    _data["CorrectionFiles"] = this.CorrectionFiles ? this.CorrectionFiles.toJSON() : void 0;
    _data["CreatedAtUtc"] = DateConverter.toIsoUtcString(this.CreatedAtUtc);
    _data["Id"] = this.Id;
    if (this.Messages && this.Messages.constructor === Array) {
      _data["Messages"] = [];
      for (let item of this.Messages) {
        _data["Messages"].push(item.toJSON());
      }
    }
    _data["Payees"] = this.Payees ? this.Payees.toJSON() : void 0;
    if (this.SourceFiles && this.SourceFiles.constructor === Array) {
      _data["SourceFiles"] = [];
      for (let item of this.SourceFiles) {
        _data["SourceFiles"].push(item.toJSON());
      }
    }
    _data["Status"] = PeriodVersionStatus[this.Status];
    if (this.SubmissionProofFiles && this.SubmissionProofFiles.constructor === Array) {
      _data["SubmissionProofFiles"] = [];
      for (let item of this.SubmissionProofFiles) {
        _data["SubmissionProofFiles"].push(item.toJSON());
      }
    }
    _data["SubmittedAt"] = DateConverter.toUtcDateString(this.SubmittedAt);
    _data["Summary"] = this.Summary ? this.Summary.toJSON() : void 0;
    _data["UploadType"] = PeriodVersionUploadType[this.UploadType];
    _data["Version"] = this.Version;
    return _data;
  }
}
export var PeriodVersionStatus = /* @__PURE__ */ ((PeriodVersionStatus2) => {
  PeriodVersionStatus2["AwaitingData"] = "AwaitingData";
  PeriodVersionStatus2["DataUploaded"] = "DataUploaded";
  PeriodVersionStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodVersionStatus2["ReadyToSubmit"] = "ReadyToSubmit";
  PeriodVersionStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodVersionStatus2["SubmittedToTa"] = "SubmittedToTa";
  PeriodVersionStatus2["AcceptedByCesop"] = "AcceptedByCesop";
  PeriodVersionStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  PeriodVersionStatus2["PreparingCorrection"] = "PreparingCorrection";
  return PeriodVersionStatus2;
})(PeriodVersionStatus || {});
export var PeriodVersionUploadType = /* @__PURE__ */ ((PeriodVersionUploadType2) => {
  PeriodVersionUploadType2["Csv"] = "Csv";
  PeriodVersionUploadType2["Xml"] = "Xml";
  PeriodVersionUploadType2["Empty"] = "Empty";
  return PeriodVersionUploadType2;
})(PeriodVersionUploadType || {});
