import { COUNTRY_CODES_FOR_CALC, HUNGARIAN_VAT } from "../business-details/BusinessDetailsData";
import { roundMoney } from "GlobalShared/helpers/numberHelper";
import { isEuCountry } from "GlobalShared/helpers/countryHelper";
import { getDay, diffInMonthForMonthlyCalc } from "GlobalShared/helpers/dateHelper";
import { getSelectedVatServices, mapToCreatedServices } from "./CreateServiceModels";
const getServiceCountryMonthlyPrice = (createdServices, code, vatCountry, price) => {
  if (price === void 0) {
    return 0;
  }
  const monthlyCountryService = createdServices.find((cs) => cs.Code === code && cs.Country === vatCountry.code);
  if (monthlyCountryService === void 0) {
    return 0;
  }
  return price * diffInMonthForMonthlyCalc(monthlyCountryService.StartDate, monthlyCountryService.EndDate);
};
const getServiceCountryPrice = (createdServices, code, vatCountry, price) => {
  return createdServices.find((cs) => cs.Code === code && cs.Country === vatCountry.code) && price !== void 0 ? price : 0;
};
const getServicePrice = (createdServices, code, price) => {
  return createdServices.find((cs) => cs.Code === code) && price !== void 0 ? price : 0;
};
export const getFeeSummary = (isAdmin, today, priceConfig, countryOfEstablishment, vatEUValid, services, euCountries, serviceCountries) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
  let createdServices = [];
  try {
    createdServices = mapToCreatedServices(isAdmin, today, priceConfig, services);
  } catch (err) {
  }
  const eoriPrice = getServicePrice(createdServices, "GlobalEoriEu", priceConfig.eoriWithoutUK);
  const ukEoriPrice = getServicePrice(createdServices, "GlobalEoriUk", priceConfig.eoriWithUK);
  const changeCompanyDetailPrice = getServicePrice(createdServices, "GlobalChangeCompanyDetail", priceConfig.changeCompanyDetail);
  const generalVatAdvicePrice = getServicePrice(createdServices, "GlobalGeneralVatAdvice", ((_a = services.globalService) == null ? void 0 : _a.generalVatAdviceHours) * priceConfig.generalVatAdviceHourlyFee);
  const specialistVatAdvicePrice = getServicePrice(createdServices, "GlobalSpecialistVatAdvice", (_c = (_b = services.globalService) == null ? void 0 : _b.specialistVatAdvicePrice) == null ? void 0 : _c.Value);
  const communicatingWithTaxAuthoritiesPrice = getServicePrice(createdServices, "GlobalCommunicatingWithTaxAuthorities", priceConfig.communicatingWithTaxAuthorities);
  const iossPrice = services.iossSelection ? calculateIossPrice(priceConfig, services.iossSelection, services.iossService) : 0;
  const ossPrice = services.ossSelection ? calculateOssPrice(priceConfig, services.ossSelection) : 0;
  const iossDeregistationPrice = getServicePrice(createdServices, "IossDeregistration", (_d = priceConfig.ioss) == null ? void 0 : _d.deregistration);
  const countries = getSelectedVatServices(services.vatServices).map((vatCountry) => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2, _j2, _k2, _l2, _m2;
    const intrastat = getServiceCountryMonthlyPrice(createdServices, "VatIntrastatIn", vatCountry, (_a2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _a2.intrastatIn) + getServiceCountryMonthlyPrice(createdServices, "VatIntrastatOut", vatCountry, (_b2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _b2.intrastatOut);
    const retro = getServiceCountryMonthlyPrice(createdServices, "VatRetrospectiveReturn", vatCountry, (_c2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _c2.retro);
    const fiscalRep = getServiceCountryPrice(createdServices, "VatFiscalRepresentative", vatCountry, (_d2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _d2.fiscalRep);
    const filingAgent = getServiceCountryPrice(createdServices, "VatFilingAgent", vatCountry, (_e2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _e2.filingAgent);
    const homeCountryCompliance = getServiceCountryPrice(createdServices, "VatHomeCountryCompliance", vatCountry, (_f2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _f2.homeCountryCompliance);
    const monthlyCompliance = getServiceCountryMonthlyPrice(createdServices, "VatMonthlyCompliance", vatCountry, (_g2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _g2.monthlyCompliance);
    const selfRevision = getServiceCountryMonthlyPrice(createdServices, "VatSelfRevision", vatCountry, (_h2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _h2.selfRevision);
    const registrationAndCompliancePrice = getServiceCountryPrice(createdServices, "VatRegistration", vatCountry, (_i2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _i2.registration) + getServiceCountryPrice(createdServices, "VatTakeOver", vatCountry, (_j2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _j2.takeOver) + getServiceCountryPrice(createdServices, "VatCompliance", vatCountry, (_k2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _k2.compliance);
    const deregistration = getServiceCountryPrice(createdServices, "VatDeregistration", vatCountry, (_l2 = priceConfig.perCountry[vatCountry.code]) == null ? void 0 : _l2.deregistration);
    const total = registrationAndCompliancePrice + fiscalRep + retro + intrastat + homeCountryCompliance + monthlyCompliance + selfRevision + filingAgent + deregistration;
    const summary = {
      filingAgent,
      fiscalRep,
      retro,
      homeCountryCompliance,
      monthlyCompliance,
      intrastat,
      label: (_m2 = serviceCountries == null ? void 0 : serviceCountries.find((sc) => sc.Code === vatCountry.code)) == null ? void 0 : _m2.Name,
      selfRevision,
      deregistration,
      total,
      countryCode: vatCountry.code
    };
    return summary;
  });
  const ossSummary = {
    quarterlyCompliance: getServicePrice(
      createdServices,
      "OssQuarterlyCompliance",
      ((_e = services.ossSelection) == null ? void 0 : _e.renewByQuarters) * ((_g = (_f = priceConfig.oss) == null ? void 0 : _f.find((oss) => {
        var _a2;
        return oss.countryCode === ((_a2 = services.ossSelection) == null ? void 0 : _a2.countryCode);
      })) == null ? void 0 : _g.price.Value) / 4
    ),
    yearlyCompliance: getServicePrice(createdServices, "OssCompliance", (_i = (_h = priceConfig.oss) == null ? void 0 : _h.find((oss) => {
      var _a2;
      return oss.countryCode === ((_a2 = services.ossSelection) == null ? void 0 : _a2.countryCode);
    })) == null ? void 0 : _i.price.Value),
    deregistration: getServicePrice(createdServices, "OssDeregistration", (_k = (_j = priceConfig.oss) == null ? void 0 : _j.find((oss) => {
      var _a2;
      return oss.countryCode === ((_a2 = services.ossSelection) == null ? void 0 : _a2.countryCode);
    })) == null ? void 0 : _k.price.Value),
    registration: getServicePrice(createdServices, "OssRegistration", (_m = (_l = priceConfig.oss) == null ? void 0 : _l.find((oss) => {
      var _a2;
      return oss.countryCode === ((_a2 = services.ossSelection) == null ? void 0 : _a2.countryCode);
    })) == null ? void 0 : _m.price.Value),
    countryName: (_n = euCountries.find((c) => {
      var _a2;
      return c.Code === ((_a2 = services.ossSelection) == null ? void 0 : _a2.countryCode);
    })) == null ? void 0 : _n.Name,
    total: ossPrice
  };
  const subTotal = ((_o = countries == null ? void 0 : countries.reduce((aggr, curr) => aggr + curr.total, 0)) != null ? _o : 0) + eoriPrice + ukEoriPrice + iossPrice + ossPrice + iossDeregistationPrice + changeCompanyDetailPrice + generalVatAdvicePrice + specialistVatAdvicePrice + communicatingWithTaxAuthoritiesPrice;
  const vat = getVatPrice(subTotal, countryOfEstablishment, euCountries, vatEUValid);
  const result = {
    countries,
    eori: eoriPrice,
    ukEori: ukEoriPrice,
    changeCompanyDetail: changeCompanyDetailPrice,
    generalVatAdvice: generalVatAdvicePrice,
    specialistVatAdvice: specialistVatAdvicePrice,
    communicatingWithTaxAuthorities: communicatingWithTaxAuthoritiesPrice,
    ioss: iossPrice,
    ossSummary,
    isIossTier0: ((_p = services.iossSelection) == null ? void 0 : _p.tier) === "Tier0",
    iossDeregistration: iossDeregistationPrice,
    subTotal,
    vat,
    total: subTotal + vat
  };
  return result;
};
export const getFeeSummaryByOrder = (countryOfEstablishment, vatEUValid, order, euCountries, serviceCountries) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _Y, _Z, __, _$, _aa, _ba, _ca, _da, _ea, _fa, _ga, _ha, _ia, _ja, _ka, _la;
  const eoriPrice = (_c = (_b = (_a = order.Services.find((s) => s.Code === "GlobalEoriEu")) == null ? void 0 : _a.Price) == null ? void 0 : _b.Value) != null ? _c : 0;
  const ukEoriPrice = (_f = (_e = (_d = order.Services.find((s) => s.Code === "GlobalEoriUk")) == null ? void 0 : _d.Price) == null ? void 0 : _e.Value) != null ? _f : 0;
  const changeCompanyDetailPrice = (_i = (_h = (_g = order.Services.find((s) => s.Code === "GlobalChangeCompanyDetail")) == null ? void 0 : _g.Price) == null ? void 0 : _h.Value) != null ? _i : 0;
  const generalVatAdvicePrice = (_l = (_k = (_j = order.Services.find((s) => s.Code === "GlobalGeneralVatAdvice")) == null ? void 0 : _j.Price) == null ? void 0 : _k.Value) != null ? _l : 0;
  const specialistVatAdvicePrice = (_o = (_n = (_m = order.Services.find((s) => s.Code === "GlobalSpecialistVatAdvice")) == null ? void 0 : _m.Price) == null ? void 0 : _n.Value) != null ? _o : 0;
  const communicatingWithTaxAuthoritiesPrice = (_r = (_q = (_p = order.Services.find((s) => s.Code === "GlobalCommunicatingWithTaxAuthorities")) == null ? void 0 : _p.Price) == null ? void 0 : _q.Value) != null ? _r : 0;
  const iossPrice = ((_u = (_t = (_s = order.Services.find((s) => s.Code === "IossComplianceTier0")) == null ? void 0 : _s.Price) == null ? void 0 : _t.Value) != null ? _u : 0) + ((_x = (_w = (_v = order.Services.find((s) => s.Code === "IossComplianceTier1")) == null ? void 0 : _v.Price) == null ? void 0 : _w.Value) != null ? _x : 0) + ((_A = (_z = (_y = order.Services.find((s) => s.Code === "IossComplianceTier2")) == null ? void 0 : _y.Price) == null ? void 0 : _z.Value) != null ? _A : 0) + ((_D = (_C = (_B = order.Services.find((s) => s.Code === "IossComplianceTier3")) == null ? void 0 : _B.Price) == null ? void 0 : _C.Value) != null ? _D : 0) + ((_G = (_F = (_E = order.Services.find((s) => s.Code === "IossRegistration")) == null ? void 0 : _E.Price) == null ? void 0 : _F.Value) != null ? _G : 0) + ((_J = (_I = (_H = order.Services.find((s) => s.Code === "IossTakeOver")) == null ? void 0 : _H.Price) == null ? void 0 : _I.Value) != null ? _J : 0);
  const ossPrice = ((_M = (_L = (_K = order.Services.find((s) => s.Code === "OssCompliance")) == null ? void 0 : _K.Price) == null ? void 0 : _L.Value) != null ? _M : 0) + ((_P = (_O = (_N = order.Services.find((s) => s.Code === "OssRegistration")) == null ? void 0 : _N.Price) == null ? void 0 : _O.Value) != null ? _P : 0) + ((_S = (_R = (_Q = order.Services.find((s) => s.Code === "OssTakeOver")) == null ? void 0 : _Q.Price) == null ? void 0 : _R.Value) != null ? _S : 0) + ((_V = (_U = (_T = order.Services.find((s) => s.Code === "OssQuarterlyCompliance")) == null ? void 0 : _T.Price) == null ? void 0 : _U.Value) != null ? _V : 0) + ((_Y = (_X = (_W = order.Services.find((s) => s.Code === "OssDeregistration")) == null ? void 0 : _W.Price) == null ? void 0 : _X.Value) != null ? _Y : 0);
  const iossDeregistationPrice = (_$ = (__ = (_Z = order.Services.find((s) => s.Code === "IossDeregistration")) == null ? void 0 : _Z.Price) == null ? void 0 : __.Value) != null ? _$ : 0;
  const ossDeregistrationPrice = (_ca = (_ba = (_aa = order.Services.find((s) => s.Code === "OssDeregistration")) == null ? void 0 : _aa.Price) == null ? void 0 : _ba.Value) != null ? _ca : 0;
  const ossSummary = {
    quarterlyCompliance: (_ea = (_da = order.Services.find((s) => s.Code === "OssQuarterlyCompliance")) == null ? void 0 : _da.Price) == null ? void 0 : _ea.Value,
    yearlyCompliance: (_ga = (_fa = order.Services.find((s) => s.Code === "OssCompliance")) == null ? void 0 : _fa.Price) == null ? void 0 : _ga.Value,
    deregistration: (_ia = (_ha = order.Services.find((s) => s.Code === "OssDeregistration")) == null ? void 0 : _ha.Price) == null ? void 0 : _ia.Value,
    registration: (_ka = (_ja = order.Services.find((s) => s.Code === "OssRegistration")) == null ? void 0 : _ja.Price) == null ? void 0 : _ka.Value,
    total: ossPrice
  };
  const countryCodes = [...new Set(order.Services.filter((s) => s.Country !== void 0).map((s) => s.Country))];
  const countries = countryCodes.map((countryCode) => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2, _j2, _k2, _l2, _m2, _n2, _o2, _p2, _q2, _r2, _s2, _t2, _u2, _v2, _w2, _x2, _y2, _z2, _A2, _B2, _C2, _D2, _E2, _F2, _G2, _H2, _I2, _J2, _K2;
    const intrastatIn = (_c2 = (_b2 = (_a2 = order.Services.find((s) => s.Code === "VatIntrastatIn" && s.Country === countryCode)) == null ? void 0 : _a2.Price) == null ? void 0 : _b2.Value) != null ? _c2 : 0;
    const intrastatOut = (_f2 = (_e2 = (_d2 = order.Services.find((s) => s.Code === "VatIntrastatOut" && s.Country === countryCode)) == null ? void 0 : _d2.Price) == null ? void 0 : _e2.Value) != null ? _f2 : 0;
    const intrastat = intrastatIn + intrastatOut;
    const retro = (_i2 = (_h2 = (_g2 = order.Services.find((s) => s.Code === "VatRetrospectiveReturn" && s.Country === countryCode)) == null ? void 0 : _g2.Price) == null ? void 0 : _h2.Value) != null ? _i2 : 0;
    const fiscalRep = (_l2 = (_k2 = (_j2 = order.Services.find((s) => s.Code === "VatFiscalRepresentative" && s.Country === countryCode)) == null ? void 0 : _j2.Price) == null ? void 0 : _k2.Value) != null ? _l2 : 0;
    const filingAgent = (_o2 = (_n2 = (_m2 = order.Services.find((s) => s.Code === "VatFilingAgent" && s.Country === countryCode)) == null ? void 0 : _m2.Price) == null ? void 0 : _n2.Value) != null ? _o2 : 0;
    const homeCountryCompliance = (_r2 = (_q2 = (_p2 = order.Services.find((s) => s.Code === "VatHomeCountryCompliance" && s.Country === countryCode)) == null ? void 0 : _p2.Price) == null ? void 0 : _q2.Value) != null ? _r2 : 0;
    const monthlyCompliance = (_u2 = (_t2 = (_s2 = order.Services.find((s) => s.Code === "VatMonthlyCompliance" && s.Country === countryCode)) == null ? void 0 : _s2.Price) == null ? void 0 : _t2.Value) != null ? _u2 : 0;
    const selfRevision = (_x2 = (_w2 = (_v2 = order.Services.find((s) => s.Code === "VatSelfRevision" && s.Country === countryCode)) == null ? void 0 : _v2.Price) == null ? void 0 : _w2.Value) != null ? _x2 : 0;
    const registrationAndCompliancePrice = ((_A2 = (_z2 = (_y2 = order.Services.find((s) => s.Code === "VatCompliance" && s.Country === countryCode)) == null ? void 0 : _y2.Price) == null ? void 0 : _z2.Value) != null ? _A2 : 0) + ((_D2 = (_C2 = (_B2 = order.Services.find((s) => s.Code === "VatRegistration" && s.Country === countryCode)) == null ? void 0 : _B2.Price) == null ? void 0 : _C2.Value) != null ? _D2 : 0) + ((_G2 = (_F2 = (_E2 = order.Services.find((s) => s.Code === "VatTakeOver" && s.Country === countryCode)) == null ? void 0 : _E2.Price) == null ? void 0 : _F2.Value) != null ? _G2 : 0);
    const deregistration = (_J2 = (_I2 = (_H2 = order.Services.find((s) => s.Code === "VatDeregistration" && s.Country === countryCode)) == null ? void 0 : _H2.Price) == null ? void 0 : _I2.Value) != null ? _J2 : 0;
    const total = registrationAndCompliancePrice + fiscalRep + retro + intrastat + homeCountryCompliance + monthlyCompliance + selfRevision + filingAgent + deregistration;
    const summary = {
      filingAgent,
      fiscalRep,
      retro,
      homeCountryCompliance,
      monthlyCompliance,
      intrastat,
      label: (_K2 = serviceCountries == null ? void 0 : serviceCountries.find((sc) => sc.Code === countryCode)) == null ? void 0 : _K2.Name,
      selfRevision,
      deregistration,
      total,
      countryCode
    };
    return summary;
  });
  const subTotal = ((_la = countries == null ? void 0 : countries.reduce((aggr, curr) => aggr + curr.total, 0)) != null ? _la : 0) + eoriPrice + ukEoriPrice + iossPrice + ossPrice + iossDeregistationPrice + ossDeregistrationPrice + changeCompanyDetailPrice + generalVatAdvicePrice + specialistVatAdvicePrice + communicatingWithTaxAuthoritiesPrice;
  const vat = getVatPrice(subTotal, countryOfEstablishment, euCountries, vatEUValid);
  const result = {
    countries,
    eori: eoriPrice,
    ukEori: ukEoriPrice,
    changeCompanyDetail: changeCompanyDetailPrice,
    generalVatAdvice: generalVatAdvicePrice,
    specialistVatAdvice: specialistVatAdvicePrice,
    communicatingWithTaxAuthorities: communicatingWithTaxAuthoritiesPrice,
    ioss: iossPrice,
    ossSummary,
    isIossTier0: order.Services.some((s) => s.Code === "IossComplianceTier0"),
    iossDeregistration: iossDeregistationPrice,
    subTotal,
    vat,
    total: subTotal + vat
  };
  return result;
};
export const getVatPrice = (subTotal, countryOfEstablishment, euCountries, vatEUValid) => {
  const needVat = countryOfEstablishment === COUNTRY_CODES_FOR_CALC.Hungary || isEuCountry(euCountries, countryOfEstablishment) && !vatEUValid;
  return needVat ? roundMoney(subTotal * HUNGARIAN_VAT, 2) : 0;
};
const calculateIossPrice = (priceConfig, iossSelection, iossService) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const compliancePrice = (_c = (_b = (_a = priceConfig.ioss) == null ? void 0 : _a.tiers.find((tier) => tier.name === iossSelection.tier)) == null ? void 0 : _b.value) != null ? _c : 0;
  const registrationPrice = (iossService == null ? void 0 : iossService.hasIossNumber) !== void 0 ? iossService.hasIossNumber ? (_e = (_d = priceConfig.ioss) == null ? void 0 : _d.takeOver) != null ? _e : 0 : (_g = (_f = priceConfig.ioss) == null ? void 0 : _f.newReg) != null ? _g : 0 : Math.min((_i = (_h = priceConfig.ioss) == null ? void 0 : _h.takeOver) != null ? _i : 0, (_k = (_j = priceConfig.ioss) == null ? void 0 : _j.newReg) != null ? _k : 0);
  return compliancePrice + registrationPrice;
};
const calculateOssPrice = (priceConfig, ossSelection) => {
  var _a, _b, _c;
  const ossOneYearPrice = (_c = (_b = (_a = priceConfig.oss) == null ? void 0 : _a.find((oss) => oss.countryCode === ossSelection.countryCode)) == null ? void 0 : _b.price.Value) != null ? _c : 0;
  const ossQuarterlyPrice = ossOneYearPrice / 4;
  return ossSelection.renewByQuarters !== void 0 ? ossQuarterlyPrice * ossSelection.renewByQuarters : ossOneYearPrice;
};
export const getIossComplianceServiceTier = (iossTier) => {
  if (iossTier === "Tier0")
    return "IossComplianceTier0";
  if (iossTier === "Tier1")
    return "IossComplianceTier1";
  if (iossTier === "Tier2")
    return "IossComplianceTier2";
  if (iossTier === "Tier3")
    return "IossComplianceTier3";
  return void 0;
};
export const getAlreadySelectedServices = (options) => {
  var _a;
  const services = [];
  if ((_a = options.iossSelection) == null ? void 0 : _a.tier) {
    const tier = getIossComplianceServiceTier(options.iossSelection.tier);
    services.push({ ServiceCode: tier });
  }
  if (options.ossSelection) {
    services.push({ ServiceCode: "OssCompliance" });
  }
  if (options.vatCountrySelection) {
    options.vatCountrySelection.filter((t) => t.selected).map((tc) => {
      services.push({
        ServiceCode: "VatCompliance",
        CountryCode: tc.code
      });
    });
  }
  return services;
};
export const getOssServices = (availableServices, countries) => {
  return availableServices == null ? void 0 : availableServices.filter((as) => as.Code === "OssCompliance").map((as) => {
    var _a;
    return {
      countryCode: as.Country,
      countryName: (_a = countries.find((c) => c.Code === as.Country)) == null ? void 0 : _a.Name,
      price: as.Price
    };
  });
};
export const createPriceConfig = (availableServices, euCountries) => {
  const getService = (countryCode, code) => {
    return availableServices == null ? void 0 : availableServices.find((as) => as.Country === countryCode && as.Code === code);
  };
  const getGlobalPrice = (code) => {
    var _a;
    return (_a = availableServices == null ? void 0 : availableServices.find((as) => as.Code === code)) == null ? void 0 : _a.Price.Value;
  };
  const getCountryPrice = (countryCode, code) => {
    var _a;
    return (_a = getService(countryCode, code)) == null ? void 0 : _a.Price.Value;
  };
  const countries = [...new Set(availableServices == null ? void 0 : availableServices.filter((as) => as.Country !== void 0).map((as) => as.Country))];
  const perCountry = {};
  countries.forEach((country) => {
    perCountry[country] = {
      compliance: getCountryPrice(country, "VatCompliance"),
      registration: getCountryPrice(country, "VatRegistration"),
      takeOver: getCountryPrice(country, "VatTakeOver"),
      fiscalRep: getCountryPrice(country, "VatFiscalRepresentative"),
      filingAgent: getCountryPrice(country, "VatFilingAgent"),
      intrastatIn: getCountryPrice(country, "VatIntrastatIn"),
      intrastatOut: getCountryPrice(country, "VatIntrastatOut"),
      selfRevision: getCountryPrice(country, "VatSelfRevision"),
      retro: getCountryPrice(country, "VatRetrospectiveReturn"),
      homeCountryCompliance: getCountryPrice(country, "VatHomeCountryCompliance"),
      monthlyCompliance: getCountryPrice(country, "VatMonthlyCompliance"),
      deregistration: getCountryPrice(country, "VatDeregistration")
    };
  });
  const iossTiers = ["Tier0", "Tier1", "Tier2", "Tier3"].reduce(
    (aggr, curr) => {
      const value = getGlobalPrice(`IossCompliance${curr}`);
      return aggr.concat(value !== void 0 ? [{ name: curr, value }] : []);
    },
    []
  );
  const tempPriceConfig = {
    perCountry,
    eoriWithoutUK: getGlobalPrice("GlobalEoriEu"),
    eoriWithUK: getGlobalPrice("GlobalEoriUk"),
    changeCompanyDetail: getGlobalPrice("GlobalChangeCompanyDetail"),
    generalVatAdviceHourlyFee: getGlobalPrice("GlobalGeneralVatAdvice"),
    specialistVatAdvice: getGlobalPrice("GlobalSpecialistVatAdvice"),
    communicatingWithTaxAuthorities: getGlobalPrice("GlobalCommunicatingWithTaxAuthorities"),
    ioss: {
      newReg: getGlobalPrice("IossRegistration"),
      takeOver: getGlobalPrice("IossTakeOver"),
      deregistration: getGlobalPrice("IossDeregistration"),
      tiers: iossTiers
    },
    oss: getOssServices(availableServices, euCountries)
  };
  return tempPriceConfig;
};
export const hasComplianceExpired = (vatCountry) => {
  return vatCountry.origComplianceEndDate !== void 0 && getDay(vatCountry.origComplianceEndDate) < getDay(/* @__PURE__ */ new Date());
};
