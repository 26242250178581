export var PeriodVersionStatus = /* @__PURE__ */ ((PeriodVersionStatus2) => {
  PeriodVersionStatus2["AwaitingData"] = "AwaitingData";
  PeriodVersionStatus2["DataUploaded"] = "DataUploaded";
  PeriodVersionStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodVersionStatus2["ReadyToSubmit"] = "ReadyToSubmit";
  PeriodVersionStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodVersionStatus2["SubmittedToTa"] = "SubmittedToTa";
  PeriodVersionStatus2["AcceptedByCesop"] = "AcceptedByCesop";
  PeriodVersionStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  PeriodVersionStatus2["PreparingCorrection"] = "PreparingCorrection";
  return PeriodVersionStatus2;
})(PeriodVersionStatus || {});
export var PeriodVersionUploadType = /* @__PURE__ */ ((PeriodVersionUploadType2) => {
  PeriodVersionUploadType2["Csv"] = "Csv";
  PeriodVersionUploadType2["Xml"] = "Xml";
  PeriodVersionUploadType2["Empty"] = "Empty";
  return PeriodVersionUploadType2;
})(PeriodVersionUploadType || {});
