var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class AmazonSaftValidationMessage {
  constructor(_data) {
    /**
     */
    __publicField(this, "ColumnName");
    /**
     */
    __publicField(this, "IsWarning");
    /**
     */
    __publicField(this, "Parameters");
    /**
     */
    __publicField(this, "RowNumber");
    /**
     */
    __publicField(this, "TotalCount");
    /**
     */
    __publicField(this, "ValidationCode");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof AmazonSaftValidationMessage
     */
    __publicField(this, "$type", "V1.ComplianceUploads.AmazonSaftValidationMessage");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ColumnName = AmazonSaftValidationMessageColumnName[_data["ColumnName"]];
      this.IsWarning = _data["IsWarning"];
      if (_data["Parameters"] && _data["Parameters"].constructor === Array) {
        this.Parameters = [];
        for (let item of _data["Parameters"]) {
          this.Parameters.push(item);
        }
      }
      this.RowNumber = _data["RowNumber"];
      this.TotalCount = _data["TotalCount"];
      this.ValidationCode = AmazonSaftValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ColumnName"] = AmazonSaftValidationMessageColumnName[this.ColumnName];
    _data["IsWarning"] = this.IsWarning;
    if (this.Parameters && this.Parameters.constructor === Array) {
      _data["Parameters"] = [];
      for (let item of this.Parameters) {
        _data["Parameters"].push(item);
      }
    }
    _data["RowNumber"] = this.RowNumber;
    _data["TotalCount"] = this.TotalCount;
    _data["ValidationCode"] = AmazonSaftValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var AmazonSaftValidationMessageColumnName = /* @__PURE__ */ ((AmazonSaftValidationMessageColumnName2) => {
  AmazonSaftValidationMessageColumnName2["ID"] = "ID";
  AmazonSaftValidationMessageColumnName2["TRANSACTION_EVENT_ID"] = "TRANSACTION_EVENT_ID";
  AmazonSaftValidationMessageColumnName2["TRANSACTION_COMPLETE_DATE"] = "TRANSACTION_COMPLETE_DATE";
  AmazonSaftValidationMessageColumnName2["SELLER_SKU"] = "SELLER_SKU";
  AmazonSaftValidationMessageColumnName2["TRANSACTION_TYPE"] = "TRANSACTION_TYPE";
  AmazonSaftValidationMessageColumnName2["Sale_MPP"] = "Sale_MPP";
  AmazonSaftValidationMessageColumnName2["SW"] = "SW";
  AmazonSaftValidationMessageColumnName2["EE"] = "EE";
  AmazonSaftValidationMessageColumnName2["TP"] = "TP";
  AmazonSaftValidationMessageColumnName2["TT_WNT"] = "TT_WNT";
  AmazonSaftValidationMessageColumnName2["TT_D"] = "TT_D";
  AmazonSaftValidationMessageColumnName2["MR_T"] = "MR_T";
  AmazonSaftValidationMessageColumnName2["MR_UZ"] = "MR_UZ";
  AmazonSaftValidationMessageColumnName2["I_42"] = "I_42";
  AmazonSaftValidationMessageColumnName2["I_63"] = "I_63";
  AmazonSaftValidationMessageColumnName2["B_SPV"] = "B_SPV";
  AmazonSaftValidationMessageColumnName2["B_SPV_DOSTWA"] = "B_SPV_DOSTWA";
  AmazonSaftValidationMessageColumnName2["B_MPV_PROWIZJA"] = "B_MPV_PROWIZJA";
  AmazonSaftValidationMessageColumnName2["RO"] = "RO";
  AmazonSaftValidationMessageColumnName2["Sale_WEW"] = "Sale_WEW";
  AmazonSaftValidationMessageColumnName2["FP"] = "FP";
  AmazonSaftValidationMessageColumnName2["IMP"] = "IMP";
  AmazonSaftValidationMessageColumnName2["Purchase_MPP"] = "Purchase_MPP";
  AmazonSaftValidationMessageColumnName2["MK"] = "MK";
  AmazonSaftValidationMessageColumnName2["VAT_RR"] = "VAT_RR";
  AmazonSaftValidationMessageColumnName2["Purchase_WEW"] = "Purchase_WEW";
  return AmazonSaftValidationMessageColumnName2;
})(AmazonSaftValidationMessageColumnName || {});
export var AmazonSaftValidationMessageValidationCode = /* @__PURE__ */ ((AmazonSaftValidationMessageValidationCode2) => {
  AmazonSaftValidationMessageValidationCode2["WrongTemplateColumns"] = "WrongTemplateColumns";
  AmazonSaftValidationMessageValidationCode2["WrongHeader"] = "WrongHeader";
  AmazonSaftValidationMessageValidationCode2["TaxableBasisReportingExceededTheLimit"] = "TaxableBasisReportingExceededTheLimit";
  AmazonSaftValidationMessageValidationCode2["OutOfPeriodTransactions"] = "OutOfPeriodTransactions";
  AmazonSaftValidationMessageValidationCode2["Required"] = "Required";
  AmazonSaftValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  AmazonSaftValidationMessageValidationCode2["RequiredReportingFields"] = "RequiredReportingFields";
  AmazonSaftValidationMessageValidationCode2["WrongExchangeRate"] = "WrongExchangeRate";
  AmazonSaftValidationMessageValidationCode2["InvalidData"] = "InvalidData";
  AmazonSaftValidationMessageValidationCode2["InvalidDecimal"] = "InvalidDecimal";
  AmazonSaftValidationMessageValidationCode2["ConstraintNotFulfilled"] = "ConstraintNotFulfilled";
  AmazonSaftValidationMessageValidationCode2["TransactionIsInTheFuture"] = "TransactionIsInTheFuture";
  AmazonSaftValidationMessageValidationCode2["TransactionIsBeforeService"] = "TransactionIsBeforeService";
  AmazonSaftValidationMessageValidationCode2["UnsupportedCurrency"] = "UnsupportedCurrency";
  AmazonSaftValidationMessageValidationCode2["Empty"] = "Empty";
  AmazonSaftValidationMessageValidationCode2["IntrastatEmpty"] = "IntrastatEmpty";
  return AmazonSaftValidationMessageValidationCode2;
})(AmazonSaftValidationMessageValidationCode || {});
