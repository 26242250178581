var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { AttributePart, directive } from "lit-html";
export class DCValidationResult {
  constructor(validationResult) {
    __publicField(this, "validationResult");
    __publicField(this, "getFailures", () => {
      return this.validationResult ? this.validationResult.getFailures() : [];
    });
    __publicField(this, "getValidationMessage", (propertyName) => {
      if (this.validationResult !== void 0) {
        const failures = this.validationResult.getFailures().filter((f) => f.propertyName === propertyName);
        if (failures.length > 0) {
          return failures[0].message;
        }
      }
      return void 0;
    });
    if (validationResult) {
      this.validationResult = validationResult;
    }
  }
  isValid() {
    var _a;
    return (_a = this.validationResult) == null ? void 0 : _a.isValid();
  }
}
export function getRef(selector) {
  return document.querySelector(selector);
}
export function getRefAll(selector) {
  return Array.from(document.querySelectorAll(selector));
}
export const ref = directive((refInstance) => (part) => {
  if (!(part instanceof AttributePart)) {
    throw new Error("ref directive can only be used as an attribute");
  }
  refInstance.current = part.committer.element;
});
