import { useCountryNameResolver } from "GlobalShared/hooks/CountryNameResolver";
import { useIdentifierTypes } from "./useIdentifierTypes";
import { useNameTypes } from "./useNameTypes";
import { html } from "lit-html";
import i18next from "i18next";
import classNames from "classnames";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { RegistrationStatus } from "WebServices/APIs/self/models/V1/Cesop/Registrations/Registration";
export const CompanyDataTemplate = (props) => {
  var _a, _b, _c;
  const nameTypes = useNameTypes();
  const identifierTypes = useIdentifierTypes();
  const { countryNameResolver } = useCountryNameResolver({ countries: (_b = (_a = props.countries) == null ? void 0 : _a.map((c) => ({ code: c.code, name: c.name }))) != null ? _b : [] });
  const pspInfo = () => html`
		<div class="flex flex-wrap gap-3 my-6">
			<dc-input .label=${i18next.t("Name")} .name=${"Name"} .value=${props.model.Name.Value} .readonly=${true}></dc-input>
			<dc-select .label=${i18next.t("Name Type")} .dataSource=${nameTypes} .selectedValues=${props.model.Name.NameType} .readonly=${true}></dc-select>
			<dc-input .label=${i18next.t("Identifier")} .name=${"Identifier"} .readonly=${true} .value=${props.model.Identifier}></dc-input>
			<dc-select .label=${i18next.t("Identifier Type")} .dataSource=${identifierTypes} .selectedValues=${props.model.IdentifierType} .readonly=${true}></dc-select>
		</div>
	`;
  const addressInfo = () => html`
		<div class="my-6">
			<div class="font-bold text-primary text-base mb-3">${i18next.t("Address Information")}</div>
			<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
				<dc-select
					.dataSource=${props.countries.map((c) => ({ label: c.name, value: c.code }))}
					.label=${i18next.t("Country")}
					.selectedValues=${props.model.Address.CountryCode}
					.readonly=${true}
				></dc-select>
				<dc-input .label=${i18next.t("Street")} .name=${"Street"} .value=${props.model.Address.Street} .readonly=${true}></dc-input>
				<dc-input .label=${i18next.t("Zip")} .name=${"Zip"} .value=${props.model.Address.PostalCode} .readonly=${true}></dc-input>
				<dc-input .label=${i18next.t("City")} .name=${"City"} .value=${props.model.Address.City} .readonly=${true}></dc-input>
				<dc-input .label=${i18next.t("Building (optional)")} .name=${"Building"} .value=${props.model.Address.Building} .readonly=${true}></dc-input>
			</div>
		</div>
	`;
  const getRegistrationStatusLabel = (status) => {
    switch (status) {
      case RegistrationStatus.New:
        return i18next.t("New");
      case RegistrationStatus.InProgress:
        return i18next.t("In Progress");
      case RegistrationStatus.Completed:
        return i18next.t("Completed");
      default:
        return "";
    }
  };
  const countryTemplate = (registration) => html`
		<div class="flex flex-col w-[226px] h-16 items-start justify-center rounded-xl border-gray-200 border-solid border-2 px-3 py-2">
			<div class="flex gap-2 justify-between w-full">
				<div class="font-bold text-sm">${countryNameResolver.resolve(registration.Country)}</div>
				<div class="flex items-center gap-1 whitespace-nowrap">
					<div
						class=${classNames("w-2 h-2 rounded-full", {
    "bg-gray-500": registration.Status === RegistrationStatus.New,
    "bg-orange-300": registration.Status === RegistrationStatus.InProgress,
    "bg-green-500": registration.Status === RegistrationStatus.Completed
  })}
					></div>
					<span>${getRegistrationStatusLabel(registration.Status)}</span>
				</div>
			</div>

			<div class=${classNames("w-full truncate", { hidden: isEmpty(registration == null ? void 0 : registration.Identifier) })} title=${registration == null ? void 0 : registration.Identifier}>${registration == null ? void 0 : registration.Identifier}</div>
		</div>
	`;
  const enabledCountryRegistrationsTemplate = () => {
    var _a2, _b2, _c2, _d, _e, _f;
    const enabledCountries = (_c2 = (_b2 = (_a2 = props.model) == null ? void 0 : _a2.Subscriptions) == null ? void 0 : _b2.filter((s) => s.IsEnabled).map((e) => e.Country)) != null ? _c2 : [];
    const enabledRegistrations = (_f = (_e = (_d = props.model) == null ? void 0 : _d.Registrations) == null ? void 0 : _e.filter((r) => enabledCountries.includes(r.Country))) != null ? _f : [];
    return enabledRegistrations.map(countryTemplate);
  };
  const countrySelector = () => html`
		<div class="my-6">
			<div class="font-bold text-primary text-base mb-3">${i18next.t("Reporting Countries")}</div>
			<div class="flex flex-wrap gap-4">${enabledCountryRegistrationsTemplate()}</div>
		</div>
	`;
  return ((_c = props == null ? void 0 : props.model) == null ? void 0 : _c.Id) ? html` <div class="px-2 text-sm">${pspInfo()} ${addressInfo()}${countrySelector()}</div> ` : "";
};
