var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useMemo } from "GlobalShared/haunted/CustomHooks";
import { withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { getParameter, replaceTo } from "GlobalShared/router";
import { getRouteByKey } from "../globals";
import { Companies as CompaniesClient } from "WebServices/APIs/self/clients";
import { ChangeCompany } from "WebServices/APIs/self/models/V1/Companies";
import { html, useState } from "haunted";
import i18next from "i18next";
export function RedirectWithSetCompany() {
  const companyId = parseInt(getParameter("companyId"));
  const redirectUrl = getParameter("redirect");
  const currentCompanyId = useMemo(() => {
    var _a;
    return parseInt((_a = ClientContext.CompanyInformation) == null ? void 0 : _a.CompanyId);
  }, []);
  const companiesClient = useClient(CompaniesClient);
  const [displayRedirect, setDisplayRedirect] = useState(false);
  useEffect(() => __async(this, null, function* () {
    if (!companyId || !redirectUrl) {
      replaceTo(getRouteByKey("admin"));
    } else if (currentCompanyId !== companyId) {
      yield withErrorHandling(
        () => __async(this, null, function* () {
          yield companiesClient.changeCompany({ body: new ChangeCompany({ CompanyId: companyId }) });
          replaceTo(redirectUrl);
          window.location.reload();
        }),
        () => setDisplayRedirect(true)
      );
    } else {
      replaceTo(redirectUrl);
    }
  }), []);
  return displayRedirect ? html`
				<div class="flex justify-around mt-8">
					<div class="flex flex-col items-center space-y-2">
						<h2>${i18next.t("Company not found")}</h2>
						<button class="btn btn-primary" @click=${() => replaceTo(redirectUrl)}>${i18next.t("Ok")}</button>
					</div>
				</div>
		  ` : "";
}
