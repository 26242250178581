import "./dc-dropfield.scss";
import { html } from "lit-html";
import { component } from "haunted";
import classNames from "classnames";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
export const setDataTransfer = (e, item) => {
  e.dataTransfer.setData("payload", JSON.stringify(item));
  e.dataTransfer.setData(`type:${item.type}`, item.type);
};
export const Component = (host) => {
  const props = {
    label: host.label,
    value: host.value,
    validationMessage: host.validationMessage,
    readonly: host.readonly,
    tooltip: host.tooltip,
    onDrop: host.onDrop,
    onRemove: host.onRemove,
    class: host.class,
    removable: host.removable !== void 0 ? host.removable : false,
    allowedDragTypes: host.allowedDragTypes !== void 0 ? host.allowedDragTypes.map((type) => type.toLowerCase()) : [],
    emptyText: host.emptyText !== void 0 ? host.emptyText : "Drop item here"
  };
  const dragTypeAllowed = (e) => {
    if (props.allowedDragTypes.length > 0) {
      const [, dragType] = e.dataTransfer.types.find((type) => type.startsWith("type:")).split(":");
      return props.allowedDragTypes.includes(dragType);
    } else {
      return true;
    }
  };
  return html`
		<div>
			${props.label ? html`<label class="form-label">${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""}</label>` : ""}
			<div
				class=${classNames("flex items-center border", { "bg-yellow-400": props.value, [props.class]: !isEmpty(props.class) })}
				@dragover=${(e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    return false;
  }}
				@dragenter=${(e) => {
    if (dragTypeAllowed(e)) {
      e.target.classList.add("border-dashed", "border-black");
    }
  }}
				@dragleave=${(e) => {
    if (dragTypeAllowed(e)) {
      e.target.classList.remove("border-dashed", "border-black");
    }
  }}
				@drop=${(e) => {
    if (dragTypeAllowed(e)) {
      e.target.classList.remove("border-dashed", "border-black");
      props.onDrop(e);
    }
  }}
			>
				${props.value ? props.value : props.emptyText}
				${props.removable ? html`<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							class="ml-1 cursor-pointer"
							width="20px"
							height="20px"
							viewBox="0 0 24 24"
							stroke="currentColor"
							@click=${props.onRemove}
					  >
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
					  </svg>` : ""}
			</div>
			<div class="relative">${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}</div>
		</div>
	`;
};
customElements.define(
  "dc-dropfield",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
