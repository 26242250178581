import { PRIMARY_COLOR } from "GlobalShared/common/Constants";
import { component } from "haunted";
import { html } from "lit-html";
import i18next from "i18next";
const DEFAULTS = {
  type: "QuestionMark",
  label: void 0
};
export const Component = (host) => {
  const props = {
    type: host.type !== void 0 ? host.type : DEFAULTS.type,
    label: host.label
  };
  const templateQuestionMark = () => {
    return html`<div title=${props.label} class="inline" style="z-index: 2">
			<svg class="inline" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_240_557)">
					<path
						d="M12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24ZM7.95938 7.74844C8.32969 6.70313 9.32344 6 10.4344 6H13.1672C14.8031 6 16.125 7.32656 16.125 8.95781C16.125 10.0172 15.5578 10.9969 14.6391 11.5266L13.125 12.3937C13.1156 13.0031 12.6141 13.5 12 13.5C11.3766 13.5 10.875 12.9984 10.875 12.375V11.7422C10.875 11.3391 11.0906 10.9687 11.4422 10.7672L13.5187 9.57656C13.7391 9.45 13.875 9.21562 13.875 8.9625C13.875 8.56875 13.5562 8.25469 13.1672 8.25469H10.4344C10.275 8.25469 10.1344 8.35312 10.0828 8.50312L10.0641 8.55938C9.85781 9.14531 9.21094 9.45 8.62969 9.24375C8.04844 9.0375 7.73906 8.39063 7.94531 7.80938L7.96406 7.75312L7.95938 7.74844ZM13.5 16.5C13.5 17.3297 12.8297 18 12 18C11.1703 18 10.5 17.3297 10.5 16.5C10.5 15.6703 11.1703 15 12 15C12.8297 15 13.5 15.6703 13.5 16.5Z"
						fill=${PRIMARY_COLOR}
					/>
				</g>
				<defs>
					<clipPath id="clip0_240_557">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>`;
  };
  const noteboxTemplate = () => html`
		<div title=${`${i18next.t("Note")}: ${props.label}`} class="inline ml-4" style="z-index: 2">
			<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="inline" viewBox="0 0 16 16">
				<path
					d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"
				/>
				<path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
			</svg>
		</div>
	`;
  return html`${props.type === "QuestionMark" ? templateQuestionMark() : props.type === "Note" ? noteboxTemplate() : ""}`;
};
customElements.define(
  "dc-tooltip",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
