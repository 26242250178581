export const useCountryNameResolver = ({ countries }) => {
  const resolve = (code) => {
    var _a;
    return ((_a = countries.find((c) => c.code.toLowerCase() === code.toLowerCase())) == null ? void 0 : _a.name) || code;
  };
  return {
    countryNameResolver: {
      resolve
    }
  };
};
