var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class PredefinedBankAccount {
  constructor(_data) {
    /**
     */
    __publicField(this, "BankAddress");
    /**
     */
    __publicField(this, "BankName");
    /**
     */
    __publicField(this, "BeneficiaryAddress");
    /**
     */
    __publicField(this, "BeneficiaryName");
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "EstablishmentCountryIsInEU");
    /**
     */
    __publicField(this, "Iban");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Swift");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PredefinedBankAccount
     */
    __publicField(this, "$type", "V1.CompanyInfo.VatRegistration.PredefinedBankAccount");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.BankAddress = _data["BankAddress"];
      this.BankName = _data["BankName"];
      this.BeneficiaryAddress = _data["BeneficiaryAddress"];
      this.BeneficiaryName = _data["BeneficiaryName"];
      this.CountryCode = _data["CountryCode"];
      this.EstablishmentCountryIsInEU = _data["EstablishmentCountryIsInEU"];
      this.Iban = _data["Iban"];
      this.Id = _data["Id"];
      this.Swift = _data["Swift"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["BankAddress"] = this.BankAddress;
    _data["BankName"] = this.BankName;
    _data["BeneficiaryAddress"] = this.BeneficiaryAddress;
    _data["BeneficiaryName"] = this.BeneficiaryName;
    _data["CountryCode"] = this.CountryCode;
    _data["EstablishmentCountryIsInEU"] = this.EstablishmentCountryIsInEU;
    _data["Iban"] = this.Iban;
    _data["Id"] = this.Id;
    _data["Swift"] = this.Swift;
    return _data;
  }
}
