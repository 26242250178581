import { html } from "lit-html";
import { component } from "haunted";
import i18next from "i18next";
import classNames from "classnames";
export const Component = (host) => {
  var _a;
  const props = {
    disabled: host.disabled,
    size: (_a = host.size) != null ? _a : 18
  };
  const onClick = (e) => {
    e.stopPropagation();
    if (!props.disabled) {
      host.dispatchEvent(new CustomEvent("click"));
    }
  };
  return html`<div @click=${onClick} class="flex items-center justify-center w-6 h-6">
		<svg
			class=${classNames("stroke-brand-primary hover:stroke-brand-tertiary hover:cursor-pointer", { "text-gray-400 pointer-events-none hover:cursor-default": props.disabled })}
			width=${props.size}
			height=${props.size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.0015 0.904694L14.7327 3.17344L20.8265 9.26719L23.0952 6.99844C24.2671 5.82657 24.2671 3.92813 23.0952 2.75626L21.2484 0.904694C20.0765 -0.267181 18.178 -0.267181 17.0062 0.904694H17.0015ZM13.6734 4.23282L2.74679 15.1641C2.25929 15.6516 1.90304 16.2563 1.70617 16.9172L0.0467943 22.5563C-0.0703932 22.9547 0.0374193 23.3813 0.328044 23.6719C0.618669 23.9625 1.04523 24.0703 1.43898 23.9578L7.07804 22.2984C7.73898 22.1016 8.34367 21.7453 8.83117 21.2578L19.7671 10.3266L13.6734 4.23282Z"
				stroke-width="1.5"
			/>
			<title>${i18next.t("Edit")}</title>
		</svg>
	</div>`;
};
customElements.define(
  "dc-edit-button",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
