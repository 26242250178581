var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { File } from "./File";
import { MessageResponse } from "./MessageResponse";
export class ProofFile extends MessageResponse {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "FileInfo");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ProofFile
     */
    __publicField(this, "$type", "V1.Cesop.Periods.ProofFile");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["FileInfo"]) {
        const fileInfo_ = new File();
        this.FileInfo = fileInfo_.init(_data["FileInfo"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["FileInfo"] = this.FileInfo ? this.FileInfo.toJSON() : void 0;
    return _data;
  }
}
