var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import {
  ExistingVatNumbersAnswer as WebExistingVatNumbersAnswer,
  DatedVatIdentificationNumber as WebDatedVatIdentificationNumber
} from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import {
  ExistingVatNumbersAnswer as AdminExistingVatNumbersAnswer,
  DatedVatIdentificationNumber as AdminDatedVatIdentificationNumber
} from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import {
  ExistingVatNumbersValidator,
  ExistingVatNumberValidator
} from "./AnswerValidators";
import { newUniqueId } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
import { checkAnswerDate } from "./answer-helpers";
import { formatDate } from "GlobalShared/helpers/dateHelper";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import classNames from "classnames";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
export const observedAttributes = ["q11e", "questionId", "euCountries"];
export const useShadowDOM = false;
export const name = "ww-answer-existing-vat-numbers";
const DEFAULT_EXISTING_VAT_NUMBER = {
  CountryCode: "",
  VatNumber: ""
};
const DEFAULTS = {
  euCountries: []
};
export class ExistingVatNumbersChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    euCountries: host.euCountries ? host.euCountries : DEFAULTS.euCountries
  };
  const euCountriesWithoutEstablishmentCountry = () => {
    return props.euCountries.filter((country) => country.Code !== props.q11e.getEstablishmentCountry());
  };
  const initForm = (editedCountryCode) => {
    if (existingVatNumbers && existingVatNumbers.length > 0) {
      const forbiddenCountries = editedCountryCode === void 0 ? existingVatNumbers : existingVatNumbers.filter((vatNumber) => editedCountryCode !== vatNumber.CountryCode);
      setAvailableCountries(
        euCountriesWithoutEstablishmentCountry().filter(
          (e) => !props.q11e.getServiceCountryCodes().concat(forbiddenCountries.map((ev) => ev.CountryCode)).includes(e.Code)
        )
      );
    } else {
      setAvailableCountries(euCountriesWithoutEstablishmentCountry());
    }
    vatNumberValidationContext.clearFieldValidations();
  };
  const getAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminExistingVatNumbersAnswer({
        Value: existingVatNumbers.map(
          (existingVatNumber) => new AdminDatedVatIdentificationNumber({
            CountryCode: existingVatNumber.CountryCode,
            VatNumber: existingVatNumber.VatNumber,
            ValidFrom: existingVatNumber.ValidFrom,
            DeregistrationDate: existingVatNumber.DeregistrationDate
          })
        ),
        QuestionId: props.questionId
      });
    }
    return new WebExistingVatNumbersAnswer({
      Value: existingVatNumbers.map(
        (existingVatNumber) => new WebDatedVatIdentificationNumber({
          CountryCode: existingVatNumber.CountryCode,
          VatNumber: existingVatNumber.VatNumber,
          ValidFrom: existingVatNumber.ValidFrom,
          DeregistrationDate: existingVatNumber.DeregistrationDate
        })
      ),
      QuestionId: props.questionId
    });
  };
  const getViewModel = () => {
    return {
      value: existingVatNumbers
    };
  };
  const edit = (id) => {
    const existingVatNumber = existingVatNumbers.filter((evn) => evn.Id === id)[0];
    initForm(existingVatNumber.CountryCode);
    setCurrentExistingVatNumber(existingVatNumber);
  };
  const remove = (id) => {
    setDispatchIfValid(true);
    setExistingVatNumbers(existingVatNumbers.filter((existingVatNumber) => existingVatNumber.Id !== id));
  };
  const save = () => {
    setDispatchIfValid(true);
    if (currentExistingVatNumber.Id === void 0) {
      currentExistingVatNumber.Id = newUniqueId();
      setExistingVatNumbers([...existingVatNumbers, currentExistingVatNumber]);
    } else {
      setExistingVatNumbers(existingVatNumbers.map((existingVatNumber) => existingVatNumber.Id === currentExistingVatNumber.Id ? currentExistingVatNumber : existingVatNumber));
    }
  };
  const cancel = () => {
    setCurrentExistingVatNumber(void 0);
  };
  const startAdding = () => {
    initForm();
    setCurrentExistingVatNumber(DEFAULT_EXISTING_VAT_NUMBER);
  };
  const [existingVatNumbers, setExistingVatNumbers] = useState([]);
  const [currentExistingVatNumber, setCurrentExistingVatNumber] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const [availableCountries, setAvailableCountries] = useState([]);
  const validationContext = useValidationContext(new ExistingVatNumbersValidator(true));
  const vatNumberValidationContext = useValidationContext(new ExistingVatNumberValidator(false));
  const [dispatchVatNumberIfValid, setDispatchVatNumberIfValid] = useState(false);
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationContext.validationResult.isValid()) {
        host.dispatchEvent(
          new ExistingVatNumbersChangeEvent({
            answer: getAnswer()
          })
        );
        setCurrentExistingVatNumber(void 0);
      }
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (dispatchVatNumberIfValid) {
      setDispatchVatNumberIfValid(false);
      if (vatNumberValidationContext.validationResult.isValid()) {
        save();
      }
    }
  }, [vatNumberValidationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getViewModel());
  }, [existingVatNumbers]);
  useEffect(() => {
    vatNumberValidationContext.validate(currentExistingVatNumber);
  }, [currentExistingVatNumber]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebExistingVatNumbersAnswer || answer instanceof AdminExistingVatNumbersAnswer) {
        setExistingVatNumbers(
          answer.Value.map((vatNumber) => {
            const vm = {
              Id: newUniqueId(),
              CountryCode: vatNumber.CountryCode,
              VatNumber: vatNumber.VatNumber,
              ValidFrom: vatNumber.ValidFrom,
              DeregistrationDate: vatNumber.DeregistrationDate
            };
            return vm;
          })
        );
      }
    }
  }, [props.q11e, props.questionId]);
  const addNewVatNumberModalTemplate = () => html`
			<dc-modal
				.visible=${currentExistingVatNumber !== void 0}
				@close=${() => setCurrentExistingVatNumber(void 0)}
				.header=${i18next.t("Add new VAT ID")}
				.content=${existingVatNumberModalContentTemplate()}
			>
			</dc-modal>
		`;
  const existingVatNumberModalContentTemplate = () => {
    return currentExistingVatNumber !== void 0 ? html`
					<div class="px-8 pb-4">
						<div class="grid md:grid-cols-2 gap-8 mt-4">
							<dc-select
								.label=${`${i18next.t("Select country")}*`}
								.selectedValues=${[currentExistingVatNumber.CountryCode]}
								.dataSource=${availableCountries.map((country) => ({ label: country.Name, value: country.Code }))}
								.filterable=${true}
								.readonly=${props.q11e.isReadonly()}
								.validationMessage=${vatNumberValidationContext.getValidationMessage("countryCode")}
								@change=${(e) => {
      vatNumberValidationContext.validateField("countryCode");
      setCurrentExistingVatNumber(__spreadProps(__spreadValues({}, currentExistingVatNumber), {
        CountryCode: e.detail.selectedValue
      }));
    }}
							></dc-select>
							<div>
								<label class="form-label">${i18next.t("VAT Number")}*</label>
								<div class="flex">
									<dc-input class="w-16" .readonly=${true} .value=${currentExistingVatNumber.CountryCode}></dc-input>
									<dc-input
										class="w-full"
										.readonly=${props.q11e.isReadonly()}
										.value=${currentExistingVatNumber.VatNumber}
										.validationMessage=${vatNumberValidationContext.getValidationMessage("vatNumber")}
										@change=${(e) => {
      vatNumberValidationContext.validateField("vatNumber");
      setCurrentExistingVatNumber(__spreadProps(__spreadValues({}, currentExistingVatNumber), {
        VatNumber: e.detail.value
      }));
    }}
									></dc-input>
								</div>
							</div>
							<dc-datepicker
								.readonly=${props.q11e.isReadonly()}
								.label=${`${i18next.t("Valid from")}*`}
								.value=${currentExistingVatNumber.ValidFrom}
								.validationMessage=${vatNumberValidationContext.getValidationMessage("validFrom")}
								@change=${(e) => {
      if (checkAnswerDate(e.detail.value)) {
        vatNumberValidationContext.validateField("validFrom");
        setCurrentExistingVatNumber(__spreadProps(__spreadValues({}, currentExistingVatNumber), {
          ValidFrom: e.detail.value
        }));
      }
    }}
							></dc-datepicker>
							<dc-datepicker
								.readonly=${props.q11e.isReadonly()}
								.label=${i18next.t("Deregistration date")}
								.value=${currentExistingVatNumber.DeregistrationDate}
								.validationMessage=${vatNumberValidationContext.getValidationMessage("deregistrationDate")}
								@change=${(e) => {
      if (checkAnswerDate(e.detail.value)) {
        vatNumberValidationContext.validateField("deregistrationDate");
        setCurrentExistingVatNumber(__spreadProps(__spreadValues({}, currentExistingVatNumber), {
          DeregistrationDate: e.detail.value
        }));
      }
    }}
							></dc-datepicker>
						</div>
						<div class="mt-16 flex gap-4">
							${SecondaryButton({ class: "w-28", text: i18next.t("Cancel"), onClick: cancel })}
							${PrimaryButton({
      class: classNames("w-28", { hidden: props.q11e.isReadonly() }),
      text: i18next.t("Save"),
      onClick: () => {
        setCurrentExistingVatNumber(__spreadValues({}, currentExistingVatNumber));
        setDispatchVatNumberIfValid(true);
        vatNumberValidationContext.validateFields(["countryCode", "vatNumber", "validFrom", "deregistrationDate"]);
      }
    })}
						</div>
					</div>
			  ` : "";
  };
  const existingVatNumberTemplate = (existingVatNumber) => html`
		<div class="flex items-center justify-between bg-gray-100 px-8 py-4 rounded-xl cursor-pointer" @click=${() => edit(existingVatNumber.Id)}>
			<div class="flex flex-col gap-2">
				<div class="cursor-pointer hover:underline text-xl max-w-fit">${existingVatNumber.CountryCode}${existingVatNumber.VatNumber}</div>
				<div class="flex gap-1">
					<span>
						${existingVatNumber.ValidFrom ? html`${i18next.t("Valid from")}: ${formatDate(existingVatNumber.ValidFrom)} <span class=${classNames({ hidden: !existingVatNumber.DeregistrationDate })}>|</span>` : ""}
					</span>
					<span>${existingVatNumber.DeregistrationDate ? html`${i18next.t("Deregistration date")}: ${formatDate(existingVatNumber.DeregistrationDate)}` : ""}</span>
				</div>
			</div>

			${SecondaryButton({
    class: classNames("max-w-fit", { hidden: props.q11e.isReadonly() }),
    text: i18next.t("Remove"),
    onClick: () => remove(existingVatNumber.Id)
  })}
		</div>
	`;
  const existingVatNumbersTemplate = () => props.q11e !== void 0 && question !== void 0 ? html`
					<div class="mt-2">${PrimaryButton({ class: classNames("max-w-fit", { hidden: props.q11e.isReadonly() }), text: i18next.t("Add new VAT ID"), onClick: startAdding })}</div>
					<div class="my-4 flex flex-col gap-4">
						${existingVatNumbers.length > 0 ? existingVatNumbers.map(existingVatNumberTemplate) : html`${currentExistingVatNumber === void 0 ? html`<div class="italic mt-2">${i18next.t("No existing VAT number is added yet")}</div>` : ""}`}
					</div>
			  ` : "";
  return html` ${existingVatNumbersTemplate()} ${addNewVatNumberModalTemplate()} `;
};
