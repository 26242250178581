var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class Document {
  constructor(_data) {
    /**
     */
    __publicField(this, "Category");
    /**
     */
    __publicField(this, "DocumentReference");
    /**
     */
    __publicField(this, "ErrorCode");
    /**
     */
    __publicField(this, "SignedDocumentReference");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "TemplateId");
    /**
     */
    __publicField(this, "TemplateName");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Document
     */
    __publicField(this, "$type", "V1.CompanyInfo.Detail.Document");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Category = DocumentCategory[_data["Category"]];
      this.DocumentReference = _data["DocumentReference"];
      this.ErrorCode = _data["ErrorCode"];
      this.SignedDocumentReference = _data["SignedDocumentReference"];
      this.Status = DocumentStatus[_data["Status"]];
      this.TemplateId = _data["TemplateId"];
      this.TemplateName = _data["TemplateName"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Category"] = DocumentCategory[this.Category];
    _data["DocumentReference"] = this.DocumentReference;
    _data["ErrorCode"] = this.ErrorCode;
    _data["SignedDocumentReference"] = this.SignedDocumentReference;
    _data["Status"] = DocumentStatus[this.Status];
    _data["TemplateId"] = this.TemplateId;
    _data["TemplateName"] = this.TemplateName;
    return _data;
  }
}
export var DocumentCategory = /* @__PURE__ */ ((DocumentCategory2) => {
  DocumentCategory2["RequiredElectronicallyOnly"] = "RequiredElectronicallyOnly";
  DocumentCategory2["RequiredByPost"] = "RequiredByPost";
  DocumentCategory2["ForInformationOnly"] = "ForInformationOnly";
  return DocumentCategory2;
})(DocumentCategory || {});
export var DocumentStatus = /* @__PURE__ */ ((DocumentStatus2) => {
  DocumentStatus2["None"] = "None";
  DocumentStatus2["InProgress"] = "InProgress";
  DocumentStatus2["Generated"] = "Generated";
  DocumentStatus2["Error"] = "Error";
  return DocumentStatus2;
})(DocumentStatus || {});
