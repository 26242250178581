var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useLoadingReducer, useMemo, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { DEFAULT_SERVICES_MODEL } from "./OrderServiceModels";
import { OssSelectorValidator, OssServiceValidator } from "./OrderServiceValidator";
import { mapBooleanToState } from "GlobalShared/helpers/booleanHelper";
import { isEuCountry } from "GlobalShared/helpers/countryHelper";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { formatDate, formatQuarter, getEndOfSubsequentQuarter, parseDate } from "GlobalShared/helpers/dateHelper";
const RADIO_OSS_COUNTRY_INPUT_NAME = "radio-oss-country-input";
const RADIO_OSS_COUNTRY_INPUT_ID_PREFIX = "radio_oss_country_input_";
export const useOssServices = (props) => {
  var _a, _b, _c;
  const getOssNonEUVatValidationMessage = () => {
    return (ossService == null ? void 0 : ossService.hasVatNumberInSelectedCountry) !== void 0 && !(ossService == null ? void 0 : ossService.hasVatNumberInSelectedCountry) ? i18next.t("You need to have a valid VAT number in the country otherwise you cannot register for OSS. We can support you in the VAT registration process, please contact us.") : void 0;
  };
  const getOssInventoryValidationMessage = () => {
    return (ossService == null ? void 0 : ossService.hasInventoryInSelectedCountry) !== void 0 && !(ossService == null ? void 0 : ossService.hasInventoryInSelectedCountry) ? i18next.t("You must keep inventory in the country where you want to register for OSS.") : void 0;
  };
  const radioOSSCountryInputId = (index) => `${RADIO_OSS_COUNTRY_INPUT_ID_PREFIX}${index}`;
  const [ossService, setOssService] = useState({});
  const [ossSelection, setOssSelection] = useState(void 0);
  const [isValidOssSelection, setIsValidOssSelection] = useState(false);
  const [isValidOssService, setIsValidOssService] = useState(false);
  const [loading, dispatchLoading] = useLoadingReducer();
  const nextQuarterItems = useMemo(() => {
    var _a2, _b2, _c2;
    const result = [];
    const numberOfQuarters = 8;
    for (let i = 1; i <= numberOfQuarters; i++) {
      const quarterEnd = getEndOfSubsequentQuarter((_c2 = (_b2 = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.GlobalServices) == null ? void 0 : _b2.OssService) == null ? void 0 : _c2.ComplianceValidTo, i);
      result.push({ value: formatDate(quarterEnd), label: formatQuarter(quarterEnd) });
    }
    return result;
  }, [(_c = (_b = (_a = props.currentCompanyServices) == null ? void 0 : _a.GlobalServices) == null ? void 0 : _b.OssService) == null ? void 0 : _c.ComplianceValidTo]);
  const ossServiceValidationContext = useValidationContext(
    new OssServiceValidator(isEuCountry(props.euCountries, props.countryOfEstablishment), ossSelection, props.currentCompanyServices)
  );
  const ossSelectorValidationContext = useValidationContext(new OssSelectorValidator());
  const init = () => {
    if (props.devMode) {
      setOssService(DEFAULT_SERVICES_MODEL.ossService);
    } else {
      setOssService({
        isSignupFlow: props.isSignup
      });
    }
  };
  useEffect(init, []);
  useEffect(() => __async(void 0, null, function* () {
    setIsValidOssService(ossServiceValidationContext.validationResult.isValid());
  }), [ossServiceValidationContext.validationResult]);
  useEffect(() => {
    if (ossService) {
      ossServiceValidationContext.validate(ossService);
    }
  }, [ossService, ossSelection]);
  useEffect(() => __async(void 0, null, function* () {
    setIsValidOssSelection(ossSelectorValidationContext.validationResult.isValid());
  }), [ossSelectorValidationContext.validationResult]);
  useEffect(() => {
    if (ossSelection) {
      ossSelectorValidationContext.validate(ossSelection);
    }
  }, [ossSelection]);
  useEffect(() => {
    var _a2, _b2;
    if (props.currentCompanyServices) {
      setOssService({
        countryCode: (_b2 = (_a2 = props.currentCompanyServices.GlobalServices.OssService) == null ? void 0 : _a2.Country) == null ? void 0 : _b2.Code
      });
      setOssSelection(void 0);
    }
  }, [props.currentCompanyServices]);
  useEffect(() => __async(void 0, null, function* () {
    if (!isEmpty(ossService == null ? void 0 : ossService.vatNumberOfSelectedCountry)) {
      withErrorHandling(
        () => __async(void 0, null, function* () {
          dispatchLoading("inc");
          const result = yield props.checkVatNumber(`${ossSelection == null ? void 0 : ossSelection.countryCode}${ossService == null ? void 0 : ossService.vatNumberOfSelectedCountry}`);
          setOssService(__spreadProps(__spreadValues({}, ossService), { isVatOfSelectedCountryValid: result }));
        }),
        (error) => {
          notifyError(error);
        },
        () => {
          dispatchLoading("dec");
        }
      );
    } else {
      setOssService(__spreadProps(__spreadValues({}, ossService), { isVatOfSelectedCountryValid: false }));
    }
  }), [ossService == null ? void 0 : ossService.vatNumberOfSelectedCountry]);
  const htmlOssSelector = () => {
    var _a2, _b2, _c2, _d, _e, _f;
    return ((_d = (_c2 = (_b2 = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.GlobalServices) == null ? void 0 : _b2.OssService) == null ? void 0 : _c2.Country) == null ? void 0 : _d.Code) === void 0 ? html`
					<div class="space-y-4 mt-4">
						<div>
							<div class="font-bold mt-4">${i18next.t("Select Country")}</div>
							<div>${i18next.t("Select the country where you would like to cooperate with Tax Desk")}</div>
						</div>

						${(_f = (_e = props.priceConfig) == null ? void 0 : _e.oss) == null ? void 0 : _f.sort((a, b) => a.countryName.localeCompare(b.countryName)).map((os, i) => {
      return html`<label
									for=${radioOSSCountryInputId(i)}
									class="btn-radio order-services-ioss-selector-container lg:w-2/4 ${(ossSelection == null ? void 0 : ossSelection.countryCode) === os.countryCode ? "selected" : ""}"
								>
									<div class="flex items-center">
										<input
											type="radio"
											name=${RADIO_OSS_COUNTRY_INPUT_NAME}
											id=${radioOSSCountryInputId(i)}
											?checked=${(ossSelection == null ? void 0 : ossSelection.countryCode) === os.countryCode}
											class="cursor-pointer"
											@click=${(_e2) => {
        if ((ossSelection == null ? void 0 : ossSelection.countryCode) === os.countryCode) {
          const elem = document.getElementById(`${radioOSSCountryInputId(i)}`);
          if (elem) {
            elem.checked = false;
          }
        }
        setOssSelection(__spreadProps(__spreadValues({}, ossSelection), {
          countryCode: (ossSelection == null ? void 0 : ossSelection.countryCode) === os.countryCode ? void 0 : os.countryCode
        }));
      }}
										/>
										<div class="ml-2">${os.countryName}</div>
									</div>
								</label>`;
    })}
					</div>
			  ` : htmlHasActiveOssService();
  };
  const htmlHasActiveOssService = () => {
    var _a2, _b2, _c2, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    return ((_c2 = (_b2 = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.GlobalServices) == null ? void 0 : _b2.OssService) == null ? void 0 : _c2.ComplianceValidTo) !== void 0 ? html`
					<div class="flex space-x-2 items-center mt-4">
						<div>${i18next.t("Active country")}:</div>
						<div class="font-semibold">${(_g = (_f = (_e = (_d = props.currentCompanyServices) == null ? void 0 : _d.GlobalServices) == null ? void 0 : _e.OssService) == null ? void 0 : _f.Country) == null ? void 0 : _g.Name}</div>
					</div>
					<div class="font-semibold">${i18next.t("Expires")}: ${formatDate((_i = (_h = props.currentCompanyServices) == null ? void 0 : _h.GlobalServices) == null ? void 0 : _i.OssService.ComplianceValidTo)}</div>
					<div class="space-y-4 mt-4">
						<div>
							<div class="font-bold mt-4">${i18next.t("Renew Country")}</div>
							<div>${i18next.t("Select the country if you would like to extend the cooperation with Tax Desk by 1 more year")}</div>
						</div>
						<label
							for=${radioOSSCountryInputId(0)}
							class="btn-radio order-services-ioss-selector-container lg:w-2/4 ${(ossSelection == null ? void 0 : ossSelection.countryCode) === ((_m = (_l = (_k = (_j = props.currentCompanyServices) == null ? void 0 : _j.GlobalServices) == null ? void 0 : _k.OssService) == null ? void 0 : _l.Country) == null ? void 0 : _m.Code) ? "selected" : ""}"
						>
							<div class="flex items-center">
								<input
									type="radio"
									name=${RADIO_OSS_COUNTRY_INPUT_NAME}
									id=${radioOSSCountryInputId(0)}
									?checked=${(ossSelection == null ? void 0 : ossSelection.countryCode) === ((_q = (_p = (_o = (_n = props.currentCompanyServices) == null ? void 0 : _n.GlobalServices) == null ? void 0 : _o.OssService) == null ? void 0 : _p.Country) == null ? void 0 : _q.Code)}
									class="cursor-pointer"
									@click=${(_e2) => {
      var _a3, _b3, _c3, _d2, _e3, _f2, _g2, _h2;
      if ((ossSelection == null ? void 0 : ossSelection.countryCode) === ((_d2 = (_c3 = (_b3 = (_a3 = props.currentCompanyServices) == null ? void 0 : _a3.GlobalServices) == null ? void 0 : _b3.OssService) == null ? void 0 : _c3.Country) == null ? void 0 : _d2.Code)) {
        const elem = document.getElementById(`${radioOSSCountryInputId(0)}`);
        if (elem) {
          elem.checked = false;
          setOssService(__spreadProps(__spreadValues({}, ossSelection), { renewForOneYear: false }));
          setOssSelection(__spreadProps(__spreadValues({}, ossSelection), { countryCode: void 0 }));
          return;
        }
      }
      setOssService(__spreadProps(__spreadValues({}, ossService), { renewForOneYear: true }));
      setOssSelection(__spreadProps(__spreadValues({}, ossSelection), { countryCode: (_h2 = (_g2 = (_f2 = (_e3 = props.currentCompanyServices) == null ? void 0 : _e3.GlobalServices) == null ? void 0 : _f2.OssService) == null ? void 0 : _g2.Country) == null ? void 0 : _h2.Code }));
    }}
								/>
								<div class="ml-2">${(_u = (_t = (_s = (_r = props.currentCompanyServices) == null ? void 0 : _r.GlobalServices) == null ? void 0 : _s.OssService) == null ? void 0 : _t.Country) == null ? void 0 : _u.Name}</div>
							</div>
						</label>
					</div>
			  ` : html`
					<div class="flex space-x-2 items-center mt-4">
						<div>${i18next.t("Active country")}:</div>
						<div class="font-semibold">${(_y = (_x = (_w = (_v = props.currentCompanyServices) == null ? void 0 : _v.GlobalServices) == null ? void 0 : _w.OssService) == null ? void 0 : _x.Country) == null ? void 0 : _y.Name}</div>
					</div>
			  `;
  };
  const htmlHasOssNumberToggle = () => {
    var _a2, _b2, _c2, _d;
    return (ossSelection == null ? void 0 : ossSelection.countryCode) && ((_d = (_c2 = (_b2 = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.GlobalServices) == null ? void 0 : _b2.OssService) == null ? void 0 : _c2.Country) == null ? void 0 : _d.Code) === void 0 ? html`
					<div class="flex justify-between my-4">
						<div>
							<div class="form-label">${i18next.t("Do you have an OSS number in the selected country?")}</div>
							<dc-toggle
								.state=${mapBooleanToState(ossService == null ? void 0 : ossService.hasOssNumber)}
								@change=${(e) => {
      setOssService(__spreadProps(__spreadValues({}, ossService), { hasOssNumber: e.detail.state === "Yes" }));
    }}
							></dc-toggle>
						</div>
					</div>
			  ` : "";
  };
  const htmlVatNumberInput = () => {
    var _a2;
    return (ossService == null ? void 0 : ossService.hasVatNumberInSelectedCountry) ? html`
					<div class="mb-4">
						<label class="form-label">
							${i18next.t("VAT Number")}*<dc-tooltip
								.label=${i18next.t("EU based businesses must provide a valid VAT ID in the country of establishment, otherwise we will charge VAT for our services.")}
							></dc-tooltip>
						</label>
						<div class="relative">
							<div class="flex">
								<dc-input class="w-16" .readonly=${true} .value=${ossSelection.countryCode}></dc-input>
								<dc-input
									class="w-2/5"
									.value=${(_a2 = ossService == null ? void 0 : ossService.vatNumberOfSelectedCountry) != null ? _a2 : ""}
									@change=${(e) => {
      const tempVal = e.detail.value;
      setOssService(__spreadProps(__spreadValues({}, ossService), { vatNumberOfSelectedCountry: tempVal }));
    }}
								></dc-input>
							</div>
							${ossService.vatNumberOfSelectedCountry && !ossService.isVatOfSelectedCountryValid && loading.count === 0 ? html`
										<div class="text-orange-600 my-4 h-16">
											${i18next.t(
      "The VAT number you provided is not valid. You need to have a valid VAT number in the country otherwise you cannot register for OSS. We can support you in the VAT number registration process, please contact us."
    )}
										</div>
								  ` : ""}
						</div>
					</div>
			  ` : "";
  };
  const htmlInventoryInSelectedCountryToggle = () => html`
		<div class="mb-4">
			<div class="relative">
				<div class="form-label">${i18next.t("Do you keep inventory in the selected country?")}</div>
				<dc-toggle
					.state=${mapBooleanToState(ossService == null ? void 0 : ossService.hasInventoryInSelectedCountry)}
					@change=${(e) => {
    setOssService(__spreadProps(__spreadValues({}, ossService), { hasInventoryInSelectedCountry: e.detail.state === "Yes" }));
  }}
				></dc-toggle>
			</div>
			<div class="text-orange-600 ${getOssInventoryValidationMessage() === void 0 ? "invisible" : "mt-4 h-8"}">${getOssInventoryValidationMessage()}</div>
		</div>
	`;
  const htmlAdditionalServicesNonEUToggles = () => {
    var _a2, _b2, _c2, _d;
    return (ossSelection == null ? void 0 : ossSelection.countryCode) && !isEuCountry(props.euCountries, props.countryOfEstablishment) && ((_d = (_c2 = (_b2 = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.GlobalServices) == null ? void 0 : _b2.OssService) == null ? void 0 : _c2.Country) == null ? void 0 : _d.Code) === void 0 ? html`
					<div class="mb-4">
						<div class="relative">
							<div class="form-label">${i18next.t("Do you have VAT Number in the selected country?")}</div>
							<dc-toggle
								.state=${mapBooleanToState(ossService == null ? void 0 : ossService.hasVatNumberInSelectedCountry)}
								@change=${(e) => {
      setOssService(__spreadProps(__spreadValues({}, ossService), { hasVatNumberInSelectedCountry: e.detail.state === "Yes" }));
    }}
							></dc-toggle>
						</div>
						<div class="text-orange-600  ${getOssNonEUVatValidationMessage() === void 0 ? "invisible" : "my-4 h-12"}">${getOssNonEUVatValidationMessage()}</div>
					</div>
					${htmlVatNumberInput()}${htmlInventoryInSelectedCountryToggle()}
			  ` : "";
  };
  const htmlCurrentOssService = () => {
    var _a2, _b2, _c2, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
    return ((_d = (_c2 = (_b2 = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.GlobalServices) == null ? void 0 : _b2.OssService) == null ? void 0 : _c2.Country) == null ? void 0 : _d.Code) !== void 0 ? html`
					<div><span>${i18next.t("Active country")}:</span> <span class="font-semibold">${i18next.t((_h = (_g = (_f = (_e = props.currentCompanyServices) == null ? void 0 : _e.GlobalServices) == null ? void 0 : _f.OssService) == null ? void 0 : _g.Country) == null ? void 0 : _h.Name)}</span></div>
					<div>
						${i18next.t("Started")}: ${formatDate((_k = (_j = (_i = props.currentCompanyServices) == null ? void 0 : _i.GlobalServices) == null ? void 0 : _j.OssService) == null ? void 0 : _k.ValidFrom)} - ${i18next.t("Expires")}:
						${formatDate((_n = (_m = (_l = props.currentCompanyServices) == null ? void 0 : _l.GlobalServices) == null ? void 0 : _m.OssService) == null ? void 0 : _n.ComplianceValidTo)}
					</div>
			  ` : "";
  };
  const htmlRenewalForOneYearSelected = () => {
    var _a2, _b2, _c2, _d, _e, _f, _g;
    return (ossSelection == null ? void 0 : ossSelection.countryCode) !== void 0 && ((_d = (_c2 = (_b2 = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.GlobalServices) == null ? void 0 : _b2.OssService) == null ? void 0 : _c2.Country) == null ? void 0 : _d.Code) !== void 0 && ((_g = (_f = (_e = props.currentCompanyServices) == null ? void 0 : _e.GlobalServices) == null ? void 0 : _f.OssService) == null ? void 0 : _g.ComplianceValidTo) !== void 0 ? html`
					<div
						class="btn-toggle mt-6 mb-1 lg:w-1/2 ${(ossService == null ? void 0 : ossService.renewForOneYear) ? "selected" : ""}"
						@click=${() => {
      if (ossService == null ? void 0 : ossService.renewForOneYear) {
        return;
      }
      setOssService(__spreadProps(__spreadValues({}, ossService), { renewForOneYear: true, selectedQuarterOption: void 0, extendByQuarter: false, currentExpirationDate: void 0 }));
      setOssSelection(__spreadProps(__spreadValues({}, ossSelection), { quarterlyComplianceEndDate: void 0, renewByQuarters: void 0 }));
    }}
					>
						<div class="flex justify-between">
							<div class="mr-2">${i18next.t("Renew for one year")}</div>
						</div>
					</div>
			  ` : "";
  };
  const htmlExtendByQuarter = () => {
    var _a2, _b2, _c2, _d, _e, _f, _g;
    return props.isAdmin && ((_c2 = (_b2 = (_a2 = props.currentCompanyServices) == null ? void 0 : _a2.GlobalServices) == null ? void 0 : _b2.OssService) == null ? void 0 : _c2.ComplianceValidTo) !== void 0 && (ossSelection == null ? void 0 : ossSelection.countryCode) && ((_g = (_f = (_e = (_d = props.currentCompanyServices) == null ? void 0 : _d.GlobalServices) == null ? void 0 : _e.OssService) == null ? void 0 : _f.Country) == null ? void 0 : _g.Code) !== void 0 ? html`
					<div class="lg:flex lg:justify-between">
						<div
							class="btn-toggle mr-2 mt-6 mb-1 lg:w-1/2 ${(ossService == null ? void 0 : ossService.extendByQuarter) ? "selected" : ""}"
							@click=${() => {
      setOssService(__spreadProps(__spreadValues({}, ossService), { renewForOneYear: !(ossService == null ? void 0 : ossService.renewForOneYear), extendByQuarter: !(ossService == null ? void 0 : ossService.extendByQuarter) }));
      setOssSelection(__spreadProps(__spreadValues({}, ossSelection), { quarterlyComplianceEndDate: void 0, renewByQuarters: void 0 }));
    }}
						>
							<div class="flex justify-between">
								<div class="mr-2">${i18next.t("Extend by quarter")}</div>
							</div>
						</div>
						<div>
							<div class=${(ossService == null ? void 0 : ossService.extendByQuarter) ? "block" : "hidden"}>
								<dc-select
									.dataSource=${nextQuarterItems}
									.selectedValues=${ossService == null ? void 0 : ossService.selectedQuarterOption}
									.label=${"Last Period"}
									@change=${(e) => {
      var _a3, _b3, _c3;
      setOssService(__spreadProps(__spreadValues({}, ossService), {
        renewForOneYear: false,
        selectedQuarterOption: e.detail.selectedValue,
        currentExpirationDate: (_c3 = (_b3 = (_a3 = props.currentCompanyServices) == null ? void 0 : _a3.GlobalServices) == null ? void 0 : _b3.OssService) == null ? void 0 : _c3.ComplianceValidTo
      }));
      setOssSelection(__spreadProps(__spreadValues({}, ossSelection), { quarterlyComplianceEndDate: parseDate(e.detail.selectedValue).toDate(), renewByQuarters: e.detail.selectedIndex + 1 }));
    }}
								></dc-select>
							</div>
						</div>
					</div>
			  ` : "";
  };
  const htmlOssDetails = () => {
    return html`
			<div class="bg-gray-100 rounded-xl p-2">
				<div class="ml-4">${htmlCurrentOssService()} ${htmlRenewalForOneYearSelected()} ${htmlExtendByQuarter()} ${htmlAdditionalServicesNonEUToggles()}${htmlHasOssNumberToggle()}</div>
			</div>
		`;
  };
  return {
    htmlOssSelector,
    htmlOssDetails,
    ossSelection,
    ossService,
    isValidOssSelection,
    isValidOssService
  };
};
