import { html } from "lit-html";
import { resolveCountryName, uploadFileToQuestion } from "GlobalShared/helpers/questionnaireHelper";
import i18next from "i18next";
import { circleCheckedIcon, triangleExclamationIcon } from "GlobalShared/templates/commons";
import { DIGITS } from "GlobalShared/common/Constants";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
export const observedAttributes = ["q11e", "worldCountries", "euCountries", "invalidAccordions"];
export const useShadowDOM = false;
export const name = "ww-q11e-country-details";
const DEFAULTS = {
  worldCountries: [],
  euCountries: []
};
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    worldCountries: host.worldCountries ? host.worldCountries : DEFAULTS.worldCountries,
    euCountries: host.euCountries ? host.euCountries : DEFAULTS.euCountries,
    invalidAccordions: host.invalidAccordions
  };
  const getCurrency = (countryPrefix) => {
    switch (countryPrefix) {
      case "no":
        return "NOK";
      case "ch":
        return "CHF";
      default:
        return "EUR";
    }
  };
  const handleAccordionClick = (e, groupName) => {
    e.preventDefault();
    e.stopPropagation();
    if (openAccordionGroupNames.includes(groupName)) {
      setOpenAccordionGroupNames(openAccordionGroupNames.filter((openGroupName) => openGroupName !== groupName));
      return;
    }
    setOpenAccordionGroupNames([...openAccordionGroupNames, groupName]);
  };
  const getAccordionIcon = (groupName) => {
    var _a;
    return ((_a = props.invalidAccordions) == null ? void 0 : _a.includes(groupName)) ? triangleExclamationIcon(24, 24, "fill-orange-400") : circleCheckedIcon();
  };
  const [openAccordionGroupNames, setOpenAccordionGroupNames] = useState([]);
  useEffect(() => {
    if (props.invalidAccordions !== void 0) {
      setOpenAccordionGroupNames([...openAccordionGroupNames, ...props.invalidAccordions]);
    }
  }, [props.invalidAccordions]);
  const templateVatCountry = (countryCode) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _Y, _Z, __, _$, _aa, _ba, _ca, _da, _ea, _fa, _ga, _ha, _ia, _ja, _ka, _la, _ma, _na, _oa, _pa, _qa;
    const countryPrefix = `${countryCode.toLowerCase() === "gb" ? "uk" : countryCode.toLowerCase()}`;
    return html`<div class="grid md:grid-cols-2 gap-4 mt-4 items-start">
			<ww-answer-money
				class=${((_a = props.q11e) == null ? void 0 : _a.isVisible(`${countryPrefix}_annual`)) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${`${countryPrefix}_annual`}
				.validation=${{ required: true }}
				.currency=${getCurrency(countryPrefix)}
			></ww-answer-money>
			<div class=${`mb-2 w-64 ${((_b = props.q11e) == null ? void 0 : _b.isVisible(`${countryPrefix}_vat`)) ? "" : "hidden"}`}>
				<div>
					<ww-answer-vatnumber .q11e=${props.q11e} .nolabel=${true} .questionId=${`${countryPrefix}_vat`} .validation=${{ required: true }} .countryCode=${countryCode}>
					</ww-answer-vatnumber>
				</div>
			</div>
			${countryPrefix === "de" ? html`<ww-answer-string
							class=${((_c = props.q11e) == null ? void 0 : _c.isVisible("de_storage_address")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_storage_address"}
							.validation=${{ required: true }}
						></ww-answer-string>
						<ww-answer-string
							class=${((_d = props.q11e) == null ? void 0 : _d.isVisible("de_local_tax")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_local_tax"}
							.validation=${{
      required: true,
      regEx: DIGITS,
      regExValidationMessage: i18next.t("Please use only numbers.")
    }}
						></ww-answer-string>
						<ww-answer-date
							class=${((_e = props.q11e) == null ? void 0 : _e.isVisible("de_store_start")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_store_start"}
							.validation=${{ required: true }}
						></ww-answer-date>
						<ww-answer-country
							class=${((_f = props.q11e) == null ? void 0 : _f.isVisible("de_import_country")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_import_country"}
							.validation=${{ required: true }}
							.countries=${props.euCountries}
						></ww-answer-country>
						<ww-answer-money
							class=${((_g = props.q11e) == null ? void 0 : _g.isVisible("de_est_profit")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_est_profit"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_h = props.q11e) == null ? void 0 : _h.isVisible("de_est_vat")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"de_est_vat"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-producttype class=${((_i = props.q11e) == null ? void 0 : _i.isVisible("de_product_type")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"de_product_type"}></ww-answer-producttype>` : ""}
			${countryPrefix === "at" ? html` <ww-answer-string
							class=${((_j = props.q11e) == null ? void 0 : _j.isVisible("at_local_tax")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"at_local_tax"}
							.validation=${{
      required: true,
      regEx: DIGITS,
      regExValidationMessage: i18next.t("Please use only numbers.")
    }}
						></ww-answer-string>
						<ww-answer-producttype class=${((_k = props.q11e) == null ? void 0 : _k.isVisible("at_product_type")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"at_product_type"}></ww-answer-producttype>` : ""}
			${countryPrefix === "fr" ? html`<ww-answer-bool
							class=${((_l = props.q11e) == null ? void 0 : _l.isVisible("fr_vat_liability")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_vat_liability"}
							.validation=${{ required: true }}
						></ww-answer-bool
						><ww-answer-bool
							class=${((_m = props.q11e) == null ? void 0 : _m.isVisible("fr_supplies")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_supplies"}
							.validation=${{ required: true }}
						></ww-answer-bool
						><ww-answer-bool
							class=${((_n = props.q11e) == null ? void 0 : _n.isVisible("fr_acquisitions")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_acquisitions"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-multicountry
							class=${((_o = props.q11e) == null ? void 0 : _o.isVisible("fr_cntry_disp")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_cntry_disp"}
							.validation=${{ required: true }}
							.countries=${props.worldCountries}
						></ww-answer-multicountry>
						<ww-answer-multicountry
							class=${((_p = props.q11e) == null ? void 0 : _p.isVisible("fr_cntry_inv")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"fr_cntry_inv"}
							.validation=${{ required: true }}
							.countries=${props.worldCountries}
						></ww-answer-multicountry>
						<ww-answer-string
							class=${((_q = props.q11e) == null ? void 0 : _q.isVisible("prev_fisc_rep")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"prev_fisc_rep"}
							.validation=${{ required: true }}
						></ww-answer-string>
						<div class="lg:col-span-2 ${((_r = props.q11e) == null ? void 0 : _r.isVisible("prev_fisc_rep")) ? "" : "hidden"}">
							<label class="text-lg font-bold"> ${i18next.t("Add the address of the previous fiscal representative in France")} </label>
							<ww-answer-address
								class=${((_s = props.q11e) == null ? void 0 : _s.isVisible("fisc_rep_address")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"fisc_rep_address"}
								.withCountry=${false}
								.validation=${{ required: true }}
							></ww-answer-address>
						</div> ` : ""}
			${countryPrefix === "it" ? html`<ww-answer-bool class=${((_t = props.q11e) == null ? void 0 : _t.isVisible("it_emp")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"it_emp"} .validation=${{ required: true }}></ww-answer-bool>
						<ww-answer-legalentitytype class=${((_u = props.q11e) == null ? void 0 : _u.isVisible("leg_ent_type")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"leg_ent_type"}></ww-answer-legalentitytype>
						<ww-answer-clientbase class=${((_v = props.q11e) == null ? void 0 : _v.isVisible("client_base")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"client_base"}></ww-answer-clientbase>
						<ww-answer-bool class=${((_w = props.q11e) == null ? void 0 : _w.isVisible("it_sales")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"it_sales"} .validation=${{ required: true }}></ww-answer-bool>
						<ww-answer-bool
							class=${((_x = props.q11e) == null ? void 0 : _x.isVisible("it_dist_sales")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"it_dist_sales"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-turnovers class=${((_y = props.q11e) == null ? void 0 : _y.isVisible("it_turnovers")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"it_turnovers"}></ww-answer-turnovers>
						<ww-answer-fileref
							class=${((_z = props.q11e) == null ? void 0 : _z.isVisible("it_vat_cert")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"it_vat_cert"}
							.validation=${{ required: true }}
							.onUpload=${uploadFileToQuestion}
						></ww-answer-fileref>` : ""}
			${countryPrefix === "pl" ? html`
						<ww-answer-accountinglocation class=${((_A = props.q11e) == null ? void 0 : _A.isVisible("account_loc")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"account_loc"}></ww-answer-accountinglocation>
						<div class="lg:col-span-2">
							<div class="text-lg font-bold mb-4">${i18next.t("Please add the address where your accounting records are kept.")}</div>
							<ww-answer-address
								class=${((_B = props.q11e) == null ? void 0 : _B.isVisible("account_addrs")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"account_addrs"}
								.worldCountries=${props.worldCountries}
								.withCountry=${true}
								.validation=${{ required: true }}
							></ww-answer-address>
						</div>
						<ww-answer-string
							class=${((_C = props.q11e) == null ? void 0 : _C.isVisible("commercial_reg_num")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"commercial_reg_num"}
							.validation=${{ required: true }}
						></ww-answer-string>
						<ww-answer-date
							class=${((_D = props.q11e) == null ? void 0 : _D.isVisible("commercial_reg_date")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"commercial_reg_date"}
							.validation=${{ required: true }}
						></ww-answer-date>
						<ww-answer-string
							class=${((_E = props.q11e) == null ? void 0 : _E.isVisible("name_of_com_auth")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"name_of_com_auth"}
							.validation=${{ required: true }}
						></ww-answer-string>
						<ww-answer-legalentityform class=${((_F = props.q11e) == null ? void 0 : _F.isVisible("leg_ent_from")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"leg_ent_from"}></ww-answer-legalentityform>
				  ` : ""}
			${countryPrefix === "no" ? html`
						<ww-answer-producttype class=${((_G = props.q11e) == null ? void 0 : _G.isVisible("no_product_type")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"no_product_type"}></ww-answer-producttype>
						<ww-answer-bool
							class=${((_H = props.q11e) == null ? void 0 : _H.isVisible("no_voec_scheme")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"no_voec_scheme"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-bool
							class=${((_I = props.q11e) == null ? void 0 : _I.isVisible("no_employees")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"no_employees"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-organisationtype class=${((_J = props.q11e) == null ? void 0 : _J.isVisible("no_org_type")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"no_org_type"}></ww-answer-organisationtype>
						<ww-answer-bool
							class=${((_K = props.q11e) == null ? void 0 : _K.isVisible("no_owned_by")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"no_owned_by"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-bool
							class=${((_L = props.q11e) == null ? void 0 : _L.isVisible("no_exclusive_operate")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"no_exclusive_operate"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<div class="lg:col-span-2 ${((_M = props.q11e) == null ? void 0 : _M.isVisible("no_premises_address")) ? "" : "hidden"}">
							<label class="text-lg font-bold"> ${i18next.t("Premises in Norway")} </label>
							<ww-answer-bool
								class=${((_N = props.q11e) == null ? void 0 : _N.isVisible("no_premises")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"no_premises"}
								.validation=${{ required: true }}
							></ww-answer-bool>
							<ww-answer-address
								class=${((_O = props.q11e) == null ? void 0 : _O.isVisible("no_premises_address")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"no_premises_address"}
								.withCountry=${false}
								.validation=${{ required: true }}
							></ww-answer-address>
						</div>
						<div class="lg:col-span-2 ${((_P = props.q11e) == null ? void 0 : _P.isVisible("no_register_address")) ? "" : "hidden"}">
							<label class="text-lg font-bold"> ${i18next.t("Business Register")} </label>
							<ww-answer-string
								class=${((_Q = props.q11e) == null ? void 0 : _Q.isVisible("no_register_name")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"no_register_name"}
								.validation=${{ required: true }}
							></ww-answer-string>
							<ww-answer-address
								class=${((_R = props.q11e) == null ? void 0 : _R.isVisible("no_register_address")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"no_register_address"}
								.worldCountries=${props.worldCountries}
								.withCountry=${true}
								.validation=${{ required: true }}
							></ww-answer-address>
						</div>
						<ww-answer-fileref
							class=${((_S = props.q11e) == null ? void 0 : _S.isVisible("no_vat_cert")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"no_vat_cert"}
							.validation=${{ required: true }}
							.onUpload=${uploadFileToQuestion}
						></ww-answer-fileref>
				  ` : ""}
			${countryPrefix === "ch" ? html` <ww-answer-bool
							class=${((_T = props.q11e) == null ? void 0 : _T.isVisible("ch_is_over_taken")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_is_over_taken"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<div class="lg:col-span-2 ${((_U = props.q11e) == null ? void 0 : _U.isVisible("ch_over_taken_name")) ? "" : "hidden"}">
							<label class="text-lg font-bold"> ${i18next.t("Take over information")} </label>
							<ww-answer-string
								class=${((_V = props.q11e) == null ? void 0 : _V.isVisible("ch_over_taken_name")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"ch_over_taken_name"}
								.validation=${{ required: true }}
							></ww-answer-string>
							<ww-answer-address
								class=${((_W = props.q11e) == null ? void 0 : _W.isVisible("ch_over_taken_address")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"ch_over_taken_address"}
								.worldCountries=${props.worldCountries}
								.withCountry=${true}
								.validation=${{ required: true }}
							></ww-answer-address>
							<ww-answer-percentage
								class=${((_X = props.q11e) == null ? void 0 : _X.isVisible("ch_take_over_perc")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.questionId=${"ch_take_over_perc"}
								.validation=${{ required: true }}
							></ww-answer-percentage>
							<ww-answer-vatnumber
								class=${((_Y = props.q11e) == null ? void 0 : _Y.isVisible("ch_over_taken_vat")) ? "" : "hidden"}
								.q11e=${props.q11e}
								.nolabel=${true}
								.questionId=${"ch_over_taken_vat"}
								.validation=${{ required: true }}
								.countryCode=${countryCode}
							></ww-answer-vatnumber>
						</div>
						<ww-answer-date
							class=${((_Z = props.q11e) == null ? void 0 : _Z.isVisible("ch_first_supply")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_first_supply"}
							.validation=${{ required: true }}
						></ww-answer-date>
						<ww-answer-bool
							class=${((__ = props.q11e) == null ? void 0 : __.isVisible("ch_perm_est")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_perm_est"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-money
							class=${((_$ = props.q11e) == null ? void 0 : _$.isVisible("ch_turn_work")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_turn_work"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_aa = props.q11e) == null ? void 0 : _aa.isVisible("ch_turn_dom")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_turn_dom"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_ba = props.q11e) == null ? void 0 : _ba.isVisible("ch_turn_tcom")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_turn_tcom"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_ca = props.q11e) == null ? void 0 : _ca.isVisible("ch_turn_taxex")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_turn_taxex"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_da = props.q11e) == null ? void 0 : _da.isVisible("ch_turn_other")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_turn_other"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_ea = props.q11e) == null ? void 0 : _ea.isVisible("ch_delivery")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_delivery"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_fa = props.q11e) == null ? void 0 : _fa.isVisible("ch_dist_sls")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_dist_sls"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_ga = props.q11e) == null ? void 0 : _ga.isVisible("ch_other_sup")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_other_sup"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-money
							class=${((_ha = props.q11e) == null ? void 0 : _ha.isVisible("ch_turn_all")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_turn_all"}
							.validation=${{ required: true }}
						></ww-answer-money>
						<ww-answer-bool
							class=${((_ia = props.q11e) == null ? void 0 : _ia.isVisible("ch_comp_act")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_comp_act"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-bool class=${((_ja = props.q11e) == null ? void 0 : _ja.isVisible("ch_volun")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"ch_volun"} .validation=${{ required: true }}></ww-answer-bool>
						<ww-answer-date
							class=${((_ka = props.q11e) == null ? void 0 : _ka.isVisible("ch_start_volun")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_start_volun"}
							.validation=${{ required: true }}
						></ww-answer-date>
						<ww-answer-bool
							class=${((_la = props.q11e) == null ? void 0 : _la.isVisible("ch_gen_calc")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_gen_calc"}
							.validation=${{ required: true }}
						></ww-answer-bool>
						<ww-answer-multistring
							class=${((_ma = props.q11e) == null ? void 0 : _ma.isVisible("ch_act_home")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_act_home"}
							.validation=${{ required: true }}
						></ww-answer-multistring>
						<ww-answer-multistring
							class=${((_na = props.q11e) == null ? void 0 : _na.isVisible("ch_act_ch")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_act_ch"}
							.validation=${{ required: true }}
						></ww-answer-multistring>
						<ww-answer-fileref
							class=${((_oa = props.q11e) == null ? void 0 : _oa.isVisible("ch_vat_cert")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_vat_cert"}
							.validation=${{ required: true }}
							.onUpload=${uploadFileToQuestion}
						></ww-answer-fileref>
						<ww-answer-date
							class=${((_pa = props.q11e) == null ? void 0 : _pa.isVisible("ch_acc_per_from")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_acc_per_from"}
							.validation=${{ required: true }}
						></ww-answer-date>
						<ww-answer-date
							class=${((_qa = props.q11e) == null ? void 0 : _qa.isVisible("ch_acc_per_to")) ? "" : "hidden"}
							.q11e=${props.q11e}
							.questionId=${"ch_acc_per_to"}
							.validation=${{ required: true }}
						></ww-answer-date>` : ""}
		</div>`;
  };
  const accordionHeaderTitleTemplate = (title, groupName) => html`
		<div class="flex gap-4 items-center"><span class="text-lg font-bold">${title}</span><span>${props.invalidAccordions !== void 0 ? getAccordionIcon(groupName) : ""}</span></div>
	`;
  const addAccordionsToArray = (accordions) => {
    var _a, _b, _c;
    return ((_a = props.q11e) == null ? void 0 : _a.hasVatService()) ? (_c = (_b = props.q11e) == null ? void 0 : _b.getServiceCountryCodes()) == null ? void 0 : _c.map((country) => {
      const countryPrefix = country.toLowerCase() === "gb" ? "uk" : country.toLowerCase();
      accordions.push({
        title: accordionHeaderTitleTemplate(resolveCountryName(props.worldCountries, country), countryPrefix),
        visible: true,
        isValid: true,
        groupName: country.toLowerCase(),
        content: () => html` <div class="px-8 pb-8">${templateVatCountry(country)}</div> `
      });
    }) : accordions.push({
      title: accordionHeaderTitleTemplate(i18next.t("Countries"), "countries"),
      visible: true,
      isValid: true,
      groupName: "countries",
      content: () => html` <div class="px-8 pb-8">${i18next.t("q11e-countries-information-nocountriesvatinformation")}</div> `
    });
  };
  const accordionsTemplate = () => {
    const accordions = [];
    addAccordionsToArray(accordions);
    return html`
			<div class="mt-8 flex flex-col gap-8">
				${accordions.map((accordion) => {
      return accordion.visible ? html`
								<dc-accordion
									.contentTemplate=${accordion.content}
									.isOpen=${openAccordionGroupNames.includes(accordion.groupName)}
									.headerTitle=${accordion.title}
									.onClick=${(e) => handleAccordionClick(e, accordion.groupName)}
								></dc-accordion>
						  ` : "";
    })}
			</div>
		`;
  };
  return accordionsTemplate();
};
