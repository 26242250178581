var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { FieldMapping } from "./FieldMapping";
export class CustomSourceMapping {
  constructor(_data) {
    /**
     */
    __publicField(this, "CommodityCode");
    /**
     */
    __publicField(this, "CountryOfOrigin");
    /**
     */
    __publicField(this, "Currency");
    /**
     */
    __publicField(this, "CurrencyReporting");
    /**
     */
    __publicField(this, "CustomerCountry");
    /**
     */
    __publicField(this, "CustomerCountryVatNumberUsed");
    /**
     */
    __publicField(this, "CustomerName");
    /**
     */
    __publicField(this, "CustomerProvinceCode");
    /**
     */
    __publicField(this, "CustomerVatNumberUsed");
    /**
     */
    __publicField(this, "DeliveryCondition");
    /**
     */
    __publicField(this, "DocumentIndicator");
    /**
     */
    __publicField(this, "ExchangeRate");
    /**
     */
    __publicField(this, "InvoiceDate");
    /**
     */
    __publicField(this, "InvoiceNumber");
    /**
     */
    __publicField(this, "ItemIdentifier");
    /**
     */
    __publicField(this, "ModeOfTransport");
    /**
     */
    __publicField(this, "ProductIdentifier");
    /**
     */
    __publicField(this, "Quantity");
    /**
     */
    __publicField(this, "ReportingCountry");
    /**
     */
    __publicField(this, "SupplierCountry");
    /**
     */
    __publicField(this, "SupplierCountryVatNumberUsed");
    /**
     */
    __publicField(this, "SupplierName");
    /**
     */
    __publicField(this, "SupplierProvinceCode");
    /**
     */
    __publicField(this, "SupplierVatNumberUsed");
    /**
     */
    __publicField(this, "TaxableBasis");
    /**
     */
    __publicField(this, "TotalValueLine");
    /**
     */
    __publicField(this, "TransactionDate");
    /**
     */
    __publicField(this, "TransactionType");
    /**
     */
    __publicField(this, "ValueVat");
    /**
     */
    __publicField(this, "VatCode");
    /**
     */
    __publicField(this, "Weight");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CustomSourceMapping
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.CustomSourceMapping");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["CommodityCode"]) {
        const commodityCode_ = new FieldMapping();
        this.CommodityCode = commodityCode_.init(_data["CommodityCode"]);
      }
      if (_data["CountryOfOrigin"]) {
        const countryOfOrigin_ = new FieldMapping();
        this.CountryOfOrigin = countryOfOrigin_.init(_data["CountryOfOrigin"]);
      }
      if (_data["Currency"]) {
        const currency_ = new FieldMapping();
        this.Currency = currency_.init(_data["Currency"]);
      }
      if (_data["CurrencyReporting"]) {
        const currencyReporting_ = new FieldMapping();
        this.CurrencyReporting = currencyReporting_.init(_data["CurrencyReporting"]);
      }
      if (_data["CustomerCountry"]) {
        const customerCountry_ = new FieldMapping();
        this.CustomerCountry = customerCountry_.init(_data["CustomerCountry"]);
      }
      if (_data["CustomerCountryVatNumberUsed"]) {
        const customerCountryVatNumberUsed_ = new FieldMapping();
        this.CustomerCountryVatNumberUsed = customerCountryVatNumberUsed_.init(_data["CustomerCountryVatNumberUsed"]);
      }
      if (_data["CustomerName"]) {
        const customerName_ = new FieldMapping();
        this.CustomerName = customerName_.init(_data["CustomerName"]);
      }
      if (_data["CustomerProvinceCode"]) {
        const customerProvinceCode_ = new FieldMapping();
        this.CustomerProvinceCode = customerProvinceCode_.init(_data["CustomerProvinceCode"]);
      }
      if (_data["CustomerVatNumberUsed"]) {
        const customerVatNumberUsed_ = new FieldMapping();
        this.CustomerVatNumberUsed = customerVatNumberUsed_.init(_data["CustomerVatNumberUsed"]);
      }
      if (_data["DeliveryCondition"]) {
        const deliveryCondition_ = new FieldMapping();
        this.DeliveryCondition = deliveryCondition_.init(_data["DeliveryCondition"]);
      }
      if (_data["DocumentIndicator"]) {
        const documentIndicator_ = new FieldMapping();
        this.DocumentIndicator = documentIndicator_.init(_data["DocumentIndicator"]);
      }
      if (_data["ExchangeRate"]) {
        const exchangeRate_ = new FieldMapping();
        this.ExchangeRate = exchangeRate_.init(_data["ExchangeRate"]);
      }
      if (_data["InvoiceDate"]) {
        const invoiceDate_ = new FieldMapping();
        this.InvoiceDate = invoiceDate_.init(_data["InvoiceDate"]);
      }
      if (_data["InvoiceNumber"]) {
        const invoiceNumber_ = new FieldMapping();
        this.InvoiceNumber = invoiceNumber_.init(_data["InvoiceNumber"]);
      }
      if (_data["ItemIdentifier"]) {
        const itemIdentifier_ = new FieldMapping();
        this.ItemIdentifier = itemIdentifier_.init(_data["ItemIdentifier"]);
      }
      if (_data["ModeOfTransport"]) {
        const modeOfTransport_ = new FieldMapping();
        this.ModeOfTransport = modeOfTransport_.init(_data["ModeOfTransport"]);
      }
      if (_data["ProductIdentifier"]) {
        const productIdentifier_ = new FieldMapping();
        this.ProductIdentifier = productIdentifier_.init(_data["ProductIdentifier"]);
      }
      if (_data["Quantity"]) {
        const quantity_ = new FieldMapping();
        this.Quantity = quantity_.init(_data["Quantity"]);
      }
      if (_data["ReportingCountry"]) {
        const reportingCountry_ = new FieldMapping();
        this.ReportingCountry = reportingCountry_.init(_data["ReportingCountry"]);
      }
      if (_data["SupplierCountry"]) {
        const supplierCountry_ = new FieldMapping();
        this.SupplierCountry = supplierCountry_.init(_data["SupplierCountry"]);
      }
      if (_data["SupplierCountryVatNumberUsed"]) {
        const supplierCountryVatNumberUsed_ = new FieldMapping();
        this.SupplierCountryVatNumberUsed = supplierCountryVatNumberUsed_.init(_data["SupplierCountryVatNumberUsed"]);
      }
      if (_data["SupplierName"]) {
        const supplierName_ = new FieldMapping();
        this.SupplierName = supplierName_.init(_data["SupplierName"]);
      }
      if (_data["SupplierProvinceCode"]) {
        const supplierProvinceCode_ = new FieldMapping();
        this.SupplierProvinceCode = supplierProvinceCode_.init(_data["SupplierProvinceCode"]);
      }
      if (_data["SupplierVatNumberUsed"]) {
        const supplierVatNumberUsed_ = new FieldMapping();
        this.SupplierVatNumberUsed = supplierVatNumberUsed_.init(_data["SupplierVatNumberUsed"]);
      }
      if (_data["TaxableBasis"]) {
        const taxableBasis_ = new FieldMapping();
        this.TaxableBasis = taxableBasis_.init(_data["TaxableBasis"]);
      }
      if (_data["TotalValueLine"]) {
        const totalValueLine_ = new FieldMapping();
        this.TotalValueLine = totalValueLine_.init(_data["TotalValueLine"]);
      }
      if (_data["TransactionDate"]) {
        const transactionDate_ = new FieldMapping();
        this.TransactionDate = transactionDate_.init(_data["TransactionDate"]);
      }
      if (_data["TransactionType"]) {
        const transactionType_ = new FieldMapping();
        this.TransactionType = transactionType_.init(_data["TransactionType"]);
      }
      if (_data["ValueVat"]) {
        const valueVat_ = new FieldMapping();
        this.ValueVat = valueVat_.init(_data["ValueVat"]);
      }
      if (_data["VatCode"]) {
        const vatCode_ = new FieldMapping();
        this.VatCode = vatCode_.init(_data["VatCode"]);
      }
      if (_data["Weight"]) {
        const weight_ = new FieldMapping();
        this.Weight = weight_.init(_data["Weight"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CommodityCode"] = this.CommodityCode ? this.CommodityCode.toJSON() : void 0;
    _data["CountryOfOrigin"] = this.CountryOfOrigin ? this.CountryOfOrigin.toJSON() : void 0;
    _data["Currency"] = this.Currency ? this.Currency.toJSON() : void 0;
    _data["CurrencyReporting"] = this.CurrencyReporting ? this.CurrencyReporting.toJSON() : void 0;
    _data["CustomerCountry"] = this.CustomerCountry ? this.CustomerCountry.toJSON() : void 0;
    _data["CustomerCountryVatNumberUsed"] = this.CustomerCountryVatNumberUsed ? this.CustomerCountryVatNumberUsed.toJSON() : void 0;
    _data["CustomerName"] = this.CustomerName ? this.CustomerName.toJSON() : void 0;
    _data["CustomerProvinceCode"] = this.CustomerProvinceCode ? this.CustomerProvinceCode.toJSON() : void 0;
    _data["CustomerVatNumberUsed"] = this.CustomerVatNumberUsed ? this.CustomerVatNumberUsed.toJSON() : void 0;
    _data["DeliveryCondition"] = this.DeliveryCondition ? this.DeliveryCondition.toJSON() : void 0;
    _data["DocumentIndicator"] = this.DocumentIndicator ? this.DocumentIndicator.toJSON() : void 0;
    _data["ExchangeRate"] = this.ExchangeRate ? this.ExchangeRate.toJSON() : void 0;
    _data["InvoiceDate"] = this.InvoiceDate ? this.InvoiceDate.toJSON() : void 0;
    _data["InvoiceNumber"] = this.InvoiceNumber ? this.InvoiceNumber.toJSON() : void 0;
    _data["ItemIdentifier"] = this.ItemIdentifier ? this.ItemIdentifier.toJSON() : void 0;
    _data["ModeOfTransport"] = this.ModeOfTransport ? this.ModeOfTransport.toJSON() : void 0;
    _data["ProductIdentifier"] = this.ProductIdentifier ? this.ProductIdentifier.toJSON() : void 0;
    _data["Quantity"] = this.Quantity ? this.Quantity.toJSON() : void 0;
    _data["ReportingCountry"] = this.ReportingCountry ? this.ReportingCountry.toJSON() : void 0;
    _data["SupplierCountry"] = this.SupplierCountry ? this.SupplierCountry.toJSON() : void 0;
    _data["SupplierCountryVatNumberUsed"] = this.SupplierCountryVatNumberUsed ? this.SupplierCountryVatNumberUsed.toJSON() : void 0;
    _data["SupplierName"] = this.SupplierName ? this.SupplierName.toJSON() : void 0;
    _data["SupplierProvinceCode"] = this.SupplierProvinceCode ? this.SupplierProvinceCode.toJSON() : void 0;
    _data["SupplierVatNumberUsed"] = this.SupplierVatNumberUsed ? this.SupplierVatNumberUsed.toJSON() : void 0;
    _data["TaxableBasis"] = this.TaxableBasis ? this.TaxableBasis.toJSON() : void 0;
    _data["TotalValueLine"] = this.TotalValueLine ? this.TotalValueLine.toJSON() : void 0;
    _data["TransactionDate"] = this.TransactionDate ? this.TransactionDate.toJSON() : void 0;
    _data["TransactionType"] = this.TransactionType ? this.TransactionType.toJSON() : void 0;
    _data["ValueVat"] = this.ValueVat ? this.ValueVat.toJSON() : void 0;
    _data["VatCode"] = this.VatCode ? this.VatCode.toJSON() : void 0;
    _data["Weight"] = this.Weight ? this.Weight.toJSON() : void 0;
    return _data;
  }
}
