var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { MAX_DATE, MIN_DATE, formatDate } from "GlobalShared/helpers/dateHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { getWebLanguageByLocale } from "GlobalShared/helpers/webLocaleHelper";
import {
  getClientAcceptanceStatusLabel,
  ClientAcceptanceLabels
} from "GlobalShared/models/ServicesModels";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { useBusinessDetailsEditor } from "../company-details/company-details";
import { useIossService } from "./ioss-service";
import { useVatService } from "./vat-service";
import { BUSINESS_TYPES, LEGAL_STATUS } from "../orders/business-details/BusinessDetailsData";
import { useVatNumbersModal } from "./vat-numbers-modal";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { useConfirmModal } from "../modals/commonModals";
import { useClientNoteForAdmin } from "./client-note";
import { useOssService } from "./oss-service";
import { downloadPDFIcon } from "GlobalShared/templates/commons";
import { Validation, useAwesomeValidator } from "GlobalShared/validation/AwesomeValidator";
import classNames from "classnames";
export const useServices = (props) => {
  var _a, _b;
  const hasGlobalService = (globalServices) => {
    return globalServices.EuEori !== void 0 || globalServices.UkEori !== void 0 || globalServices.IossService !== void 0 || globalServices.OssService !== void 0;
  };
  const getContactName = (companyDetail) => {
    return `${companyDetail.contactTitle} ${companyDetail.contactFirstName} ${companyDetail.contactLastName}`;
  };
  const flipSubscriptionStatus = (vatRegistrationId, eventType, subscribe) => {
    setCompanyServicesModel(__spreadProps(__spreadValues({}, companyServicesModel), {
      VatRegistrations: companyServicesModel.VatRegistrations.map((vr) => {
        if (vr.Id === vatRegistrationId) {
          return __spreadProps(__spreadValues({}, vr), {
            RegNotificationSubscriptions: vr.RegNotificationSubscriptions.map((rns) => {
              return rns.eventType === eventType ? __spreadProps(__spreadValues({}, rns), { isSubscribed: subscribe }) : rns;
            })
          });
        } else {
          return vr;
        }
      })
    }));
  };
  const isClientAcceptanceValid = () => {
    if (currentClientAcceptance !== void 0 && !["LowRisk", "MediumRisk", "HighRisk"].includes(currentClientAcceptance))
      return true;
    return currentClientAcceptanceValidTo !== void 0 && currentClientAcceptanceValidTo > MIN_DATE && currentClientAcceptanceValidTo <= MAX_DATE;
  };
  const updateRegNotificationSubscription = (companyId, vatRegistrationId, eventType, userId, subscribe) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        if (subscribe) {
          yield props.callbackHandler.subscribeRegNotification(companyId, vatRegistrationId, eventType, userId);
        } else {
          yield props.callbackHandler.unsubscribeRegNotification(companyId, vatRegistrationId, eventType, userId);
        }
        if (!props.isAdmin) {
          flipSubscriptionStatus(vatRegistrationId, eventType, subscribe);
        }
        notifySuccess(i18next.t("Saved successfully"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateClientAcceptance = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.updateClientAcceptance(currentClientAcceptance, currentClientAcceptanceValidTo);
        notifySuccess("Client acceptance Updated!");
        setShowClientAcceptanceModal(false);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const generateLines = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.generatePeriods(props.companyServicesModel.Id);
        notifySuccess("Lines generated successfully");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const updateAmlInfo = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.callbackHandler.updateAmlInfo(companyServicesModel.Id, currentAmlInfo.AmlReceivedAt, currentAmlInfo.AmlFileReference);
        notifySuccess("AML information updated successfully");
        setShowAmlModal(false);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [companyServicesModel, setCompanyServicesModel] = useState(void 0);
  const [currentClientAcceptance, setCurrentClientAcceptance] = useState("Pending");
  const [currentClientAcceptanceValidTo, setCurrentClientAcceptanceValidTo] = useState(void 0);
  const [currentAmlInfo, setCurrentAmlInfo] = useState({});
  const [showCancelledServices, setShowCancelledServices] = useState(false);
  const [showBusinessDetailsModal, setShowBusinessDetailsModal] = useState(false);
  const [showClientAcceptanceModal, setShowClientAcceptanceModal] = useState(false);
  const [showAmlModal, setShowAmlModal] = useState(false);
  const [generationConfiguration, setGenerationConfiguration] = useState(void 0);
  const amlValidator = useAwesomeValidator(
    [Validation.ruleFor("AmlReceivedAt", (c) => c == null ? void 0 : c.AmlReceivedAt).isRequired(), Validation.ruleFor("AmlFileReference", (c) => c == null ? void 0 : c.AmlFileReference).isRequired()],
    () => updateAmlInfo()
  );
  const companyDetailsHook = useBusinessDetailsEditor({
    hasIossService: ((_a = props.companyServicesModel) == null ? void 0 : _a.GlobalServices.IossService) !== void 0,
    companyDetails: (_b = props.companyServicesModel) == null ? void 0 : _b.CompanyDetails,
    worldCountries: props.worldCountries,
    handlers: {
      close: () => setShowBusinessDetailsModal(false),
      save: (companyDetails) => __async(void 0, null, function* () {
        yield props.callbackHandler.saveCompanyDetails(companyDetails);
        setShowBusinessDetailsModal(false);
      })
    }
  });
  const confirmModal = useConfirmModal();
  const iossServiceHook = useIossService({
    isAdmin: props.isAdmin,
    permissionManager: props.permissionManager,
    showCancelledServices,
    callbackHandler: props.callbackHandler,
    companyId: companyServicesModel == null ? void 0 : companyServicesModel.Id,
    companyServices: companyServicesModel
  });
  const ossServiceHook = useOssService({
    isAdmin: props.isAdmin,
    permissionManager: props.permissionManager,
    showCancelledServices,
    callbackHandler: props.callbackHandler,
    companyId: companyServicesModel == null ? void 0 : companyServicesModel.Id,
    companyServices: companyServicesModel
  });
  const vatServiceHook = useVatService({
    isAdmin: props.isAdmin,
    permissionManager: props.permissionManager,
    showCancelledServices,
    callbackHandler: props.callbackHandler,
    predefinedBankAccounts: props.predefinedBankAccounts,
    submissionConfig: props.submissionConfig,
    countrySubmissionConfigurations: props.countrySubmissionConfigurations,
    euCountries: props.euCountries,
    companyServices: companyServicesModel,
    updateRegNotificationSubscription,
    updateCountrySubmissionConfig: props.updateCountrySubmissionConfig
  });
  const vatNumbersModal = useVatNumbersModal({
    isAdmin: props.isAdmin,
    permissionManager: props.permissionManager,
    euCountries: props.euCountries,
    companyDetail: props.companyDetail,
    saveAnswer: props.callbackHandler.saveAnswer,
    getQuestionnaire: props.callbackHandler.getQuestionnaire
  });
  const clientNoteForAdmin = useClientNoteForAdmin({
    isAdmin: props.isAdmin,
    companyDetail: props.companyDetail,
    updateNotes: props.callbackHandler.updateNotes
  });
  useEffect(() => __async(void 0, null, function* () {
    if (props.companyServicesModel) {
      setCompanyServicesModel(props.companyServicesModel);
      if (props.isAdmin) {
        setGenerationConfiguration(yield props.callbackHandler.getGenerationConfiguration());
      }
    }
  }), [props.companyServicesModel]);
  const htmlBusinessDetailsModal = () => {
    if (showBusinessDetailsModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showBusinessDetailsModal}
						@close=${() => setShowBusinessDetailsModal(false)}
						.header=${"Edit business details"}
						.content=${html`<div>${companyDetailsHook.template()}</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlClientAcceptanceModal = () => {
    if (showClientAcceptanceModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showClientAcceptanceModal}
						.overflowH=${false}
						.overflowW=${false}
						@close=${() => setShowClientAcceptanceModal(false)}
						.header=${"Update Client acceptance Information"}
						.content=${html`<div class="client-acceptance-modal">
							<div class="max-w-64">
								<dc-dropdown
									.isStatusLike=${true}
									.buttonText=${getClientAcceptanceStatusLabel(currentClientAcceptance)}
									.selectedValues=${[currentClientAcceptance]}
									.dataSource=${ClientAcceptanceLabels}
									@change=${(e) => {
        const newStatus = e.detail.selectedValue;
        setCurrentClientAcceptance(newStatus);
        if (!["LowRisk", "MediumRisk", "HighRisk"].includes(newStatus)) {
          setCurrentClientAcceptanceValidTo(void 0);
        }
      }}
								></dc-dropdown>
							</div>
							${["LowRisk", "MediumRisk", "HighRisk"].includes(currentClientAcceptance) ? html`
										<dc-datepicker
											class="w-56 mt-4"
											.label=${"Valid to"}
											.min=${MIN_DATE}
											.max=${MAX_DATE}
											.value=${currentClientAcceptanceValidTo}
											.validationMessage=${!isClientAcceptanceValid() ? "This field is required" : void 0}
											@change=${(e) => {
        setCurrentClientAcceptanceValidTo(e.detail.value);
      }}
										></dc-datepicker>
								  ` : html``}
							<div class="mt-8 flex space-x-4">
								<button class="btn btn-primary" @click=${() => setShowClientAcceptanceModal(false)}>Cancel</button>
								<button
									class="btn btn-primary"
									@click=${() => __async(void 0, null, function* () {
        if (!isClientAcceptanceValid()) {
          return;
        }
        yield updateClientAcceptance();
      })}
								>
									Save
								</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlAmlModal = () => {
    if (showAmlModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showAmlModal}
						@close=${() => setShowAmlModal(false)}
						.header=${"Update AML Information"}
						.content=${html`<div>
							<div>
								<dc-fileupload
									class="w-56 mt-4"
									.label=${"Upload AML form"}
									.validationMessage=${amlValidator.getMessage("AmlFileReference")}
									@upload=${(e) => __async(void 0, null, function* () {
        var _a2;
        if (((_a2 = e.detail) == null ? void 0 : _a2.files) && e.detail.files.length > 0) {
          const fileId = yield props.callbackHandler.uploadAmlDocument(companyServicesModel.Id, e.detail.files[0]);
          setCurrentAmlInfo(__spreadProps(__spreadValues({}, currentAmlInfo), { AmlFileReference: fileId }));
          notifySuccess("AML document uploaded successfully");
        }
      })}
								></dc-fileupload>
							</div>
							<div>
								<dc-datepicker
									class="w-56 mt-4"
									.label=${"AML Received At"}
									.min=${MIN_DATE}
									.max=${MAX_DATE}
									.value=${currentAmlInfo.AmlReceivedAt}
									.validationMessage=${amlValidator.getMessage("AmlReceivedAt")}
									@change=${(e) => {
        setCurrentAmlInfo(__spreadProps(__spreadValues({}, currentAmlInfo), { AmlReceivedAt: e.detail.value }));
      }}
								></dc-datepicker>
							</div>
							<div class="mt-6 flex space-x-4">
								<button class="btn btn-primary" @click=${() => setShowAmlModal(false)}>Cancel</button>
								<button
									class="btn btn-primary"
									@click=${() => {
        amlValidator.validateAndDispatch(currentAmlInfo);
      }}
								>
									Save
								</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlGlobalServices = (globalServices) => {
    return html`
			${globalServices.UkEori && globalServices.UkEori.ValidFrom ? html` <div class="mt-8 shadow border px-6 py-4 rounded-xl">
						<div class="font-bold text-lg mt-1 mb-3">${i18next.t("EORI number application in the UK")}</div>
						<div class="text-sm">${i18next.t("Service start date")}: ${formatDate(globalServices.UkEori.ValidFrom)}</div>
				  </div>` : ""}
			${globalServices.EuEori && globalServices.EuEori.ValidFrom ? html` <div class="mt-8 shadow border px-6 py-4 rounded-xl">
						<div class="font-bold text-lg mt-1 mb-3">${i18next.t("EORI number application in the EU")}</div>
						<div class="text-sm">${i18next.t("Service start date")}: ${formatDate(globalServices.EuEori.ValidFrom)}</div>
				  </div>` : ""}
			${iossServiceHook.mainTemplate} ${ossServiceHook.mainTemplate}
		`;
  };
  const htmlBusinessMainDetails = (servicesModel) => {
    var _a2, _b2;
    return html`<div class=${classNames("mt-8 px-6 bg-gray-200 w-full py-6 rounded-t-xl", { "rounded-b-xl": !props.isAdmin })}>
			<div class="xl:mx-auto">
				<div class="flex flex-wrap justify-between mb-3">
					<div class="flex flex-wrap gap-3">
						<span class="bg-gray-300 border rounded-full mb-1 py-2 px-4 h-9 text-xs uppercase font-semibold">
							${LEGAL_STATUS.find((ls) => ls.value === servicesModel.CompanyDetails.legalStatus).label}</span
						>
						<span class="bg-gray-300 border rounded-full mb-1 py-2 px-4 h-9 text-xs uppercase font-semibold">
							${BUSINESS_TYPES.find((ls) => ls.value === servicesModel.CompanyDetails.businessType).label}</span
						>
						<div class="flex gap-2">
							<span class="bg-gray-300 border rounded-full mb-1 py-2 px-4 min-h-9 text-xs uppercase font-semibold">
								${servicesModel.EstablishmentCountryName}, ${servicesModel.CompanyDetails.addressZip}, ${servicesModel.CompanyDetails.addressProvince}
								${servicesModel.CompanyDetails.addressCity}, ${servicesModel.CompanyDetails.addressStreet} ${servicesModel.CompanyDetails.addressStreetNo}
							</span>
							${props.isAdmin && props.permissionManager.hasPermission("Company_Details_Modify") ? html` <dc-edit-button
										class="flex items-center self-start"
										@click=${() => {
      setShowBusinessDetailsModal(true);
    }}
								  ></dc-edit-button>` : ""}
						</div>
					</div>
					<div class="flex items-center gap-1">
						<span class=${classNames("bg-gray-300 border rounded-full py-2 px-4 h-9 text-xs font-semibold", { hidden: !props.isAdmin })}>${props.companyDetail.ClientSource} </span>
						<div class="text-xs uppercase font-semibold">${i18next.t("Id")}: ${servicesModel.Id}</div>
					</div>
				</div>
				<div class="flex justify-between font-bold text-lg mb-2">
					<div>${servicesModel.FullName}</div>
					<div>${servicesModel.CompanyDetails.countryOfEstablishment}${servicesModel.CompanyDetails.vatNumber}</div>
				</div>
				<div class="flex justify-between text-sm space-y-4">
					<div class="flex flex-start">${servicesModel.CompanyDetails.phone} | ${getContactName(servicesModel.CompanyDetails)}</div>
					<div class="flex justify-between text-sm space-x-2">
						${getWebLanguageByLocale(servicesModel.CompanyDetails.preferredLanguage) !== void 0 ? html`
									<span>${(_a2 = getWebLanguageByLocale(servicesModel.CompanyDetails.preferredLanguage)) == null ? void 0 : _a2.lang.toUpperCase()} </span>
									:
									<img height="20" width="20" src="/images/${(_b2 = getWebLanguageByLocale(servicesModel.CompanyDetails.preferredLanguage)) == null ? void 0 : _b2.lang.toLocaleLowerCase()}_flag.svg" />
							  ` : ""}
					</div>
				</div>
				<div class="flex justify-start text-sm">
					<div class=${`flex justify-center text-sm ${props.isAdmin ? "" : "hidden"}`}>
						<div class="flex items-center space-x-3 mr-4">
							<div class="font-bold">
								${"Client acceptance:"} ${getClientAcceptanceStatusLabel(servicesModel.ClientAcceptance)}
								${servicesModel.ClientAcceptanceValidTo ? html`(${formatDate(servicesModel.ClientAcceptanceValidTo)})` : ""}
							</div>
							${props.permissionManager.hasPermission("Company_Additional_Modify") ? html`<dc-edit-button
										@click=${() => {
      setCurrentClientAcceptance(companyServicesModel.ClientAcceptance);
      setCurrentClientAcceptanceValidTo(companyServicesModel.ClientAcceptanceValidTo);
      setShowClientAcceptanceModal(true);
    }}
								  >
								  </dc-edit-button>` : ""}
						</div>
					</div>
				</div>
				${props.isAdmin ? html` <div class="flex text-sm font-bold space-x-3">
							${"AML:"} ${servicesModel.AmlReceivedAt ? html`(${formatDate(servicesModel.AmlReceivedAt)})` : "Pending"}
							${servicesModel.AmlFileReference ? html` <div
										class="cursor-pointer ml-3"
										@click=${() => __async(void 0, null, function* () {
      yield props.callbackHandler.downloadAmlDocument(servicesModel.Id);
    })}
								  >
										${downloadPDFIcon()}
								  </div>` : ""}
							${props.permissionManager.hasPermission("Company_Additional_Modify") ? html`<dc-edit-button
										@click=${() => {
      setCurrentAmlInfo({ AmlReceivedAt: companyServicesModel.AmlReceivedAt, AmlFileReference: companyServicesModel.AmlFileReference });
      setShowAmlModal(true);
    }}
								  >
								  </dc-edit-button>` : ""}
					  </div>` : ""}

				<div class="flex justify-start text-sm">
					<!-- Todo DANI: Add proper readonly status after backend is ready -->
					<dc-checkbox2
						class=${classNames({ hidden: !props.isAdmin })}
						.checked=${generationConfiguration == null ? void 0 : generationConfiguration.IsCustomSource}
						.label=${i18next.t("Custom source client")}
						.readonly=${!props.permissionManager.hasPermission("Company_Details_Modify")}
						@change=${(e) => __async(void 0, null, function* () {
      yield props.callbackHandler.updateCustomSourceStatus(companyServicesModel.Id, e.detail.checked);
    })}
					></dc-checkbox2>
				</div>

				<div class="flex justify-between text-sm">
					${props.isAdmin ? html`<dc-checkbox2
								.checked=${servicesModel.IsInvoicingEnabled}
								.label=${"Client invoicing"}
								.readonly=${!props.permissionManager.hasPermission("Company_Details_Modify")}
								@change=${(e) => {
      (() => __async(void 0, null, function* () {
        props.callbackHandler.updateInvoicing(e.detail.checked);
      }))();
      e.preventDefault();
    }}
						  ></dc-checkbox2>` : html`<div></div>`}
					<div class="flex space-x-4">
						${props.isAdmin && props.permissionManager.hasPermission("Compliance_Upload_Generate") && props.permissionManager.hasPermission("Submission_Period_Generate") ? html`<div>
									<button
										class="btn btn-sm btn-primary whitespace-nowrap"
										@click=${() => __async(void 0, null, function* () {
      if (yield confirmModal.confirm("Are you sure you would like to generate the Data Upload and Return Library lines for the Client?")) {
        generateLines();
      }
    })}
									>
										Generate Lines
									</button>
							  </div>` : ""}
						<div>
							<button class="btn btn-sm btn-primary whitespace-nowrap" @click=${() => vatNumbersModal.show()}>${i18next.t("VAT Numbers")}</button>
						</div>
					</div>
				</div>
			</div>
		</div>`;
  };
  const mainTemplate = () => {
    return companyServicesModel ? html`${htmlBusinessMainDetails(companyServicesModel)} ${clientNoteForAdmin.template()}
					<div class="flex justify-between mt-2">
						<div></div>
						<div>
							<dc-checkbox2
								.checked=${showCancelledServices}
								.label=${i18next.t("Show inactive services")}
								@change=${(e) => {
      setShowCancelledServices(e.detail.checked);
    }}
							></dc-checkbox2>
						</div>
					</div>
					${companyServicesModel && hasGlobalService(companyServicesModel.GlobalServices) ? htmlGlobalServices(companyServicesModel.GlobalServices) : ""} ${iossServiceHook.htmlModals}
					${ossServiceHook.htmlModals} ${vatServiceHook.mainTemplate} ${vatServiceHook.htmlModals} ${htmlClientAcceptanceModal()} ${htmlBusinessDetailsModal()} ${vatNumbersModal.template()}
					${htmlAmlModal()} ${confirmModal.mainTemplate()}` : html`<dc-loader .isLoading=${true}></dc-loader>`;
  };
  return {
    mainTemplate,
    isLoading: !companyServicesModel
  };
};
