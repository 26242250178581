var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { CustomDataTemplate } from "GlobalShared/components/custom-data-template/CustomDataTemplate";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import {
  GenerationConfiguration as GenerationConfigurationClient,
  ComplianceMasterData as ComplianceMasterDataClient,
  TransactionTypes as TransactionTypesClient
} from "WebServices/APIs/self/clients";
import {
  AddCustomSourceConfiguration,
  CustomSourceMapping as WebCustomSourceMapping,
  DefaultFormatSettings,
  DsvFormatSettings,
  OneCustomSourceReference,
  SeparateCustomSourceReference,
  UpdateCustomSourceConfiguration,
  UpdateOneCustomSourceMapping,
  UpdatePurchaseCustomSourceMapping,
  UpdateSaleCustomSourceMapping,
  FieldMapping,
  OneCustomSourceConfiguration,
  SeparateCustomSourceConfiguration
} from "WebServices/APIs/self/models/V1/GenerationConfiguration";
import { AddCustomSourceConfigurationMode } from "WebServices/APIs/self/models/V1/GenerationConfiguration/AddCustomSourceConfiguration";
import { UpdateCustomSourceConfigurationMode } from "WebServices/APIs/self/models/V1/GenerationConfiguration/UpdateCustomSourceConfiguration";
import { AddTaxCode, UpdateTaxCode } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode";
import { SearchRequest } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search/SearchRequest";
import {
  mapTypeOfGoods,
  mapVatRateTypeSetting,
  TaxCodeFieldNames
} from "GlobalShared/components/taxcodes/TaxCodeModels";
import { Filter, Pager, Field } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search";
import { FieldOrder } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search/Field";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import i18next from "i18next";
import { currentLocale } from "../../globals";
export function AdminCustomDataTemplate() {
  const getFilter = (searchRequest) => {
    const clientFilter = new Filter();
    if (searchRequest.gridState.appliedFilters.length > 0) {
      searchRequest.gridState.appliedFilters.forEach((filter) => {
        if (filter.filterProps.selectedValues) {
          if (filter.field === TaxCodeFieldNames.ClientTaxCode) {
            clientFilter.ClientTaxCode = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.Country) {
            clientFilter.CountryCode = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.TransactionType) {
            clientFilter.TransactionTypeCodes = [...filter.filterProps.selectedValues];
          } else if (filter.field === TaxCodeFieldNames.VatRate) {
            clientFilter.VatRateTypes = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.TypeOfGoods) {
            clientFilter.TypeOfGoods = filter.filterProps.selectedValues;
          } else if (filter.field === TaxCodeFieldNames.ReverseCharge) {
            clientFilter.ReverseCharge = filter.filterProps.selectedValues;
          }
        }
      });
    }
    clientFilter.OnlyMissing = searchRequest.onlyMissing ? true : void 0;
    return clientFilter;
  };
  const searchTaxCodes = (searchRequest, loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield complianceMasterDataClient.searchTaxCodes({
          body: new SearchRequest({
            Filter: getFilter(searchRequest),
            Pager: new Pager({
              Skip: searchRequest.gridState.pageIndex * searchRequest.gridState.pageSize,
              Top: searchRequest.gridState.pageSize,
              OrderBy: [
                new Field({
                  Order: searchRequest.gridState.orderDir === "asc" ? FieldOrder.Ascending : FieldOrder.Descending,
                  OrderByField: searchRequest.gridState.orderBy
                })
              ]
            })
          })
        });
        return result.data;
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const getTypeOfGoods = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        const result = yield complianceMasterDataClient.getTypeOfGoods();
        return (_a = result.data) == null ? void 0 : _a.map((t) => mapTypeOfGoods(t));
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const getVATRateTypeSettings = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        const result = yield complianceMasterDataClient.getVATRateTypeSettings();
        return (_a = result.data) == null ? void 0 : _a.map((t) => mapVatRateTypeSetting(t));
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const addTaxCode = (taxCode, loader) => __async(this, null, function* () {
    const bodyToPost = new AddTaxCode({
      ClientTaxCode: taxCode.clientTaxCode,
      CountryCode: taxCode.countryCode,
      ReverseCharge: taxCode.reverseCharge,
      TransactionTypeCode: taxCode.transactionTypeCode,
      TypeOfGoods: taxCode.typeOfGoods,
      VatRateType: taxCode.vatRateType
    });
    return withErrorHandling(
      () => __async(this, null, function* () {
        const result = yield complianceMasterDataClient.addTaxCode({ body: bodyToPost });
        notifySuccess(i18next.t("Tax code added successfully"));
        return result.data;
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const updateTaxCode = (id, taxCode, loader) => __async(this, null, function* () {
    const bodyToPost = new UpdateTaxCode({
      ClientTaxCode: taxCode.clientTaxCode,
      CountryCode: taxCode.countryCode,
      ReverseCharge: taxCode.reverseCharge,
      TransactionTypeCode: taxCode.transactionTypeCode,
      TypeOfGoods: taxCode.typeOfGoods,
      VatRateType: taxCode.vatRateType
    });
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.updateTaxCode({ id, body: bodyToPost });
        notifySuccess(i18next.t("Tax code updated successfully"));
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const deleteTaxCode = (id, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.deleteTaxCode({ id });
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const getTransactionTypes = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        var _a;
        const result = yield transactionTypesClient.get();
        return (_a = result.data) == null ? void 0 : _a.map((t) => ({ code: t.Code, description: t.Description }));
      }),
      (error) => notifyError(error),
      void 0,
      loader
    );
  });
  const createCustomDataTemplate = (createDataStepInfo, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const body = new AddCustomSourceConfiguration({
          AvailableTemplate: createDataStepInfo.templateType,
          Mode: createDataStepInfo.isAllCountryInOneFile ? AddCustomSourceConfigurationMode.AllCountryInOne : AddCustomSourceConfigurationMode.PerCountry,
          SalePurchaseSeparately: !createDataStepInfo.isSaleAndPurchaseInOneFile,
          FormatSettings: createDataStepInfo.templateType === "Dsv" ? new DsvFormatSettings({
            DecimalSeparator: createDataStepInfo.decimalSeparator,
            IncludesHeader: createDataStepInfo.hasHeaderLine,
            Delimiter: createDataStepInfo.delimitier
          }) : new DefaultFormatSettings({ DecimalSeparator: createDataStepInfo.decimalSeparator, IncludesHeader: createDataStepInfo.hasHeaderLine })
        });
        yield generationConfigurationClient.create({ body });
        notifySuccess(i18next.t("Custom data template created successfully"));
      }),
      (error) => {
        notifyError(error);
      },
      void 0,
      loader
    );
  });
  const updateCustomDataTemplate = (createDataStepInfo, loader) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        const availableTemplate = createDataStepInfo.templateType;
        const mode = createDataStepInfo.isAllCountryInOneFile ? UpdateCustomSourceConfigurationMode.AllCountryInOne : UpdateCustomSourceConfigurationMode.PerCountry;
        const body = new UpdateCustomSourceConfiguration({
          AvailableTemplate: availableTemplate,
          Mode: availableTemplate === "SAPText" ? UpdateCustomSourceConfigurationMode.PerCountry : mode,
          SalePurchaseSeparately: !createDataStepInfo.isSaleAndPurchaseInOneFile,
          FormatSettings: createDataStepInfo.templateType === "Dsv" ? new DsvFormatSettings({
            DecimalSeparator: createDataStepInfo.decimalSeparator,
            IncludesHeader: createDataStepInfo.hasHeaderLine,
            Delimiter: createDataStepInfo.delimitier
          }) : new DefaultFormatSettings({ DecimalSeparator: createDataStepInfo.decimalSeparator, IncludesHeader: createDataStepInfo.hasHeaderLine })
        });
        yield generationConfigurationClient.update({ body });
        notifySuccess(i18next.t("Custom data template updated successfully"));
      }),
      (error) => {
        notifyError(error);
      },
      void 0,
      loader
    );
  });
  const getGenerationConfiguration = (loader) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield generationConfigurationClient.get();
        return mapGenerationConfiguration(response.data);
      }),
      (error) => {
        notifyError(error);
      },
      void 0,
      loader
    );
  });
  const getMetaDataConfiguration = (loader, uploadType) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield generationConfigurationClient.getFieldsMetadata({ uploadType });
        return response.data;
      }),
      (error) => {
        notifyError(error);
      },
      void 0,
      loader
    );
  });
  const updateTemplateReference = (uploadType, file) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield generationConfigurationClient.updateTemplateReference({ uploadType, file });
        notifySuccess(i18next.t("File uploaded successfully"));
      }),
      (error) => {
        notifyError(error);
      },
      void 0
    );
  });
  const getWebSourceMapping = (mapping) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _Y, _Z, __, _$, _aa, _ba, _ca, _da, _ea, _fa, _ga, _ha, _ia, _ja, _ka, _la, _ma, _na, _oa, _pa, _qa, _ra, _sa, _ta, _ua, _va, _wa, _xa, _ya, _za, _Aa, _Ba, _Ca, _Da, _Ea, _Fa, _Ga, _Ha, _Ia, _Ja, _Ka, _La, _Ma;
    return new WebCustomSourceMapping({
      CommodityCode: ((_a = mapping.CommodityCode) == null ? void 0 : _a.Source) !== void 0 ? new FieldMapping({ Source: (_b = mapping.CommodityCode) == null ? void 0 : _b.Source, FallbackSources: (_c = mapping.CommodityCode) == null ? void 0 : _c.FallbackSources }) : void 0,
      CountryOfOrigin: ((_d = mapping.CountryOfOrigin) == null ? void 0 : _d.Source) !== void 0 ? new FieldMapping({ Source: (_e = mapping.CountryOfOrigin) == null ? void 0 : _e.Source, FallbackSources: (_f = mapping.CountryOfOrigin) == null ? void 0 : _f.FallbackSources }) : void 0,
      Currency: ((_g = mapping.Currency) == null ? void 0 : _g.Source) !== void 0 ? new FieldMapping({ Source: (_h = mapping.Currency) == null ? void 0 : _h.Source, FallbackSources: (_i = mapping.Currency) == null ? void 0 : _i.FallbackSources }) : void 0,
      CurrencyReporting: ((_j = mapping.CurrencyReporting) == null ? void 0 : _j.Source) !== void 0 ? new FieldMapping({ Source: (_k = mapping.CurrencyReporting) == null ? void 0 : _k.Source, FallbackSources: (_l = mapping.CurrencyReporting) == null ? void 0 : _l.FallbackSources }) : void 0,
      CustomerCountry: ((_m = mapping.CustomerCountry) == null ? void 0 : _m.Source) !== void 0 ? new FieldMapping({ Source: (_n = mapping.CustomerCountry) == null ? void 0 : _n.Source, FallbackSources: (_o = mapping.CustomerCountry) == null ? void 0 : _o.FallbackSources }) : void 0,
      CustomerCountryVatNumberUsed: ((_p = mapping.CustomerCountryVatNumberUsed) == null ? void 0 : _p.Source) ? new FieldMapping({ Source: (_q = mapping.CustomerCountryVatNumberUsed) == null ? void 0 : _q.Source, FallbackSources: (_r = mapping.CustomerCountryVatNumberUsed) == null ? void 0 : _r.FallbackSources }) : void 0,
      CustomerName: ((_s = mapping.CustomerName) == null ? void 0 : _s.Source) !== void 0 ? new FieldMapping({ Source: (_t = mapping.CustomerName) == null ? void 0 : _t.Source, FallbackSources: (_u = mapping.CustomerName) == null ? void 0 : _u.FallbackSources }) : void 0,
      CustomerProvinceCode: ((_v = mapping.CustomerProvinceCode) == null ? void 0 : _v.Source) !== void 0 ? new FieldMapping({ Source: (_w = mapping.CustomerProvinceCode) == null ? void 0 : _w.Source, FallbackSources: (_x = mapping.CustomerProvinceCode) == null ? void 0 : _x.FallbackSources }) : void 0,
      CustomerVatNumberUsed: ((_y = mapping.CustomerVatNumberUsed) == null ? void 0 : _y.Source) !== void 0 ? new FieldMapping({ Source: (_z = mapping.CustomerVatNumberUsed) == null ? void 0 : _z.Source, FallbackSources: (_A = mapping.CustomerVatNumberUsed) == null ? void 0 : _A.FallbackSources }) : void 0,
      DeliveryCondition: ((_B = mapping.DeliveryCondition) == null ? void 0 : _B.Source) !== void 0 ? new FieldMapping({ Source: (_C = mapping.DeliveryCondition) == null ? void 0 : _C.Source, FallbackSources: (_D = mapping.DeliveryCondition) == null ? void 0 : _D.FallbackSources }) : void 0,
      DocumentIndicator: ((_E = mapping.DocumentIndicator) == null ? void 0 : _E.Source) !== void 0 ? new FieldMapping({ Source: (_F = mapping.DocumentIndicator) == null ? void 0 : _F.Source, FallbackSources: (_G = mapping.DocumentIndicator) == null ? void 0 : _G.FallbackSources }) : void 0,
      ExchangeRate: ((_H = mapping.ExchangeRate) == null ? void 0 : _H.Source) !== void 0 ? new FieldMapping({ Source: (_I = mapping.ExchangeRate) == null ? void 0 : _I.Source, FallbackSources: (_J = mapping.ExchangeRate) == null ? void 0 : _J.FallbackSources }) : void 0,
      InvoiceDate: ((_K = mapping.InvoiceDate) == null ? void 0 : _K.Source) !== void 0 ? new FieldMapping({ Source: (_L = mapping.InvoiceDate) == null ? void 0 : _L.Source, FallbackSources: (_M = mapping.InvoiceDate) == null ? void 0 : _M.FallbackSources }) : void 0,
      InvoiceNumber: ((_N = mapping.InvoiceNumber) == null ? void 0 : _N.Source) !== void 0 ? new FieldMapping({ Source: (_O = mapping.InvoiceNumber) == null ? void 0 : _O.Source, FallbackSources: (_P = mapping.InvoiceNumber) == null ? void 0 : _P.FallbackSources }) : void 0,
      ItemIdentifier: ((_Q = mapping.ItemIdentifier) == null ? void 0 : _Q.Source) !== void 0 ? new FieldMapping({ Source: (_R = mapping.ItemIdentifier) == null ? void 0 : _R.Source, FallbackSources: (_S = mapping.ItemIdentifier) == null ? void 0 : _S.FallbackSources }) : void 0,
      ModeOfTransport: ((_T = mapping.ModeOfTransport) == null ? void 0 : _T.Source) !== void 0 ? new FieldMapping({ Source: (_U = mapping.ModeOfTransport) == null ? void 0 : _U.Source, FallbackSources: (_V = mapping.ModeOfTransport) == null ? void 0 : _V.FallbackSources }) : void 0,
      ProductIdentifier: ((_W = mapping.ProductIdentifier) == null ? void 0 : _W.Source) !== void 0 ? new FieldMapping({ Source: (_X = mapping.ProductIdentifier) == null ? void 0 : _X.Source, FallbackSources: (_Y = mapping.ProductIdentifier) == null ? void 0 : _Y.FallbackSources }) : void 0,
      Quantity: ((_Z = mapping.Quantity) == null ? void 0 : _Z.Source) !== void 0 ? new FieldMapping({ Source: (__ = mapping.Quantity) == null ? void 0 : __.Source, FallbackSources: (_$ = mapping.Quantity) == null ? void 0 : _$.FallbackSources }) : void 0,
      ReportingCountry: ((_aa = mapping.ReportingCountry) == null ? void 0 : _aa.Source) !== void 0 ? new FieldMapping({ Source: (_ba = mapping.ReportingCountry) == null ? void 0 : _ba.Source, FallbackSources: (_ca = mapping.ReportingCountry) == null ? void 0 : _ca.FallbackSources }) : void 0,
      SupplierCountry: ((_da = mapping.SupplierCountry) == null ? void 0 : _da.Source) !== void 0 ? new FieldMapping({ Source: (_ea = mapping.SupplierCountry) == null ? void 0 : _ea.Source, FallbackSources: (_fa = mapping.SupplierCountry) == null ? void 0 : _fa.FallbackSources }) : void 0,
      SupplierCountryVatNumberUsed: ((_ga = mapping.SupplierCountryVatNumberUsed) == null ? void 0 : _ga.Source) !== void 0 ? new FieldMapping({ Source: (_ha = mapping.SupplierCountryVatNumberUsed) == null ? void 0 : _ha.Source, FallbackSources: (_ia = mapping.SupplierCountryVatNumberUsed) == null ? void 0 : _ia.FallbackSources }) : void 0,
      SupplierName: ((_ja = mapping.SupplierName) == null ? void 0 : _ja.Source) !== void 0 ? new FieldMapping({ Source: (_ka = mapping.SupplierName) == null ? void 0 : _ka.Source, FallbackSources: (_la = mapping.SupplierName) == null ? void 0 : _la.FallbackSources }) : void 0,
      SupplierProvinceCode: ((_ma = mapping.SupplierProvinceCode) == null ? void 0 : _ma.Source) !== void 0 ? new FieldMapping({ Source: (_na = mapping.SupplierProvinceCode) == null ? void 0 : _na.Source, FallbackSources: (_oa = mapping.SupplierProvinceCode) == null ? void 0 : _oa.FallbackSources }) : void 0,
      SupplierVatNumberUsed: ((_pa = mapping.SupplierVatNumberUsed) == null ? void 0 : _pa.Source) !== void 0 ? new FieldMapping({ Source: (_qa = mapping.SupplierVatNumberUsed) == null ? void 0 : _qa.Source, FallbackSources: (_ra = mapping.SupplierVatNumberUsed) == null ? void 0 : _ra.FallbackSources }) : void 0,
      TaxableBasis: ((_sa = mapping.TaxableBasis) == null ? void 0 : _sa.Source) !== void 0 ? new FieldMapping({ Source: (_ta = mapping.TaxableBasis) == null ? void 0 : _ta.Source, FallbackSources: (_ua = mapping.TaxableBasis) == null ? void 0 : _ua.FallbackSources }) : void 0,
      TotalValueLine: ((_va = mapping.TotalValueLine) == null ? void 0 : _va.Source) !== void 0 ? new FieldMapping({ Source: (_wa = mapping.TotalValueLine) == null ? void 0 : _wa.Source, FallbackSources: (_xa = mapping.TotalValueLine) == null ? void 0 : _xa.FallbackSources }) : void 0,
      TransactionDate: ((_ya = mapping.TransactionDate) == null ? void 0 : _ya.Source) !== void 0 ? new FieldMapping({ Source: (_za = mapping.TransactionDate) == null ? void 0 : _za.Source, FallbackSources: (_Aa = mapping.TransactionDate) == null ? void 0 : _Aa.FallbackSources }) : void 0,
      TransactionType: ((_Ba = mapping.TransactionType) == null ? void 0 : _Ba.Source) !== void 0 ? new FieldMapping({ Source: (_Ca = mapping.TransactionType) == null ? void 0 : _Ca.Source, FallbackSources: (_Da = mapping.TransactionType) == null ? void 0 : _Da.FallbackSources }) : void 0,
      ValueVat: ((_Ea = mapping.ValueVat) == null ? void 0 : _Ea.Source) !== void 0 ? new FieldMapping({ Source: (_Fa = mapping.ValueVat) == null ? void 0 : _Fa.Source, FallbackSources: (_Ga = mapping.ValueVat) == null ? void 0 : _Ga.FallbackSources }) : void 0,
      VatCode: ((_Ha = mapping.VatCode) == null ? void 0 : _Ha.Source) !== void 0 ? new FieldMapping({ Source: (_Ia = mapping.VatCode) == null ? void 0 : _Ia.Source, FallbackSources: (_Ja = mapping.VatCode) == null ? void 0 : _Ja.FallbackSources }) : void 0,
      Weight: ((_Ka = mapping.Weight) == null ? void 0 : _Ka.Source) !== void 0 ? new FieldMapping({ Source: (_La = mapping.Weight) == null ? void 0 : _La.Source, FallbackSources: (_Ma = mapping.Weight) == null ? void 0 : _Ma.FallbackSources }) : void 0
    });
  };
  const updateCustomSourceMapping = (mapping, loader, uploadType) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        if (uploadType === "AllInOne") {
          yield generationConfigurationClient.updateCustomSourceMapping({
            body: new UpdateOneCustomSourceMapping({
              Mapping: getWebSourceMapping(mapping)
            })
          });
        } else if (uploadType === "Purchase") {
          yield generationConfigurationClient.updateCustomSourceMapping({
            body: new UpdatePurchaseCustomSourceMapping({ Mapping: getWebSourceMapping(mapping) })
          });
        } else if (uploadType === "Sale") {
          yield generationConfigurationClient.updateCustomSourceMapping({ body: new UpdateSaleCustomSourceMapping({ Mapping: getWebSourceMapping(mapping) }) });
        }
        notifySuccess(i18next.t("Mapping saved successfully"));
      }),
      (error) => {
        notifyError(error);
      },
      void 0,
      loader
    );
  });
  const getValidationResult = (uploadType, file, loader) => {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield generationConfigurationClient.getValidationResult({ uploadType, file });
        return mapValidationResult(response.data);
      }),
      (error) => {
        notifyError(error);
      },
      void 0,
      loader
    );
  };
  const mapValidationResult = (validationResult) => ({
    IsSuccess: validationResult.IsSuccess,
    NumberOfTotalLinesWithError: validationResult.NumberOfTotalLinesWithError,
    ValidationMessages: validationResult.ValidationMessages.map((v) => ({
      FieldName: v.FieldName,
      RowNumber: v.RowNumber,
      TotalCount: v.TotalCount,
      ValidationCode: v.ValidationCode
    }))
  });
  const mapTemplateFileReference = (generationConfiguration) => {
    if (generationConfiguration.TemplateFileReference instanceof OneCustomSourceReference) {
      return {
        Reference: generationConfiguration.TemplateFileReference.Reference,
        FileName: generationConfiguration.TemplateFileReference.FileName,
        TransactionDataFields: generationConfiguration.TemplateFileReference.TransactionData.Fields
      };
    } else if (generationConfiguration.TemplateFileReference instanceof SeparateCustomSourceReference) {
      return {
        SaleFileName: generationConfiguration.TemplateFileReference.SaleFileName,
        PurchaseFileName: generationConfiguration.TemplateFileReference.PurchaseFileName,
        PurchaseReference: generationConfiguration.TemplateFileReference.PurchaseReference,
        SaleReference: generationConfiguration.TemplateFileReference.SaleReference,
        SeparateTransactionData: generationConfiguration.TemplateFileReference.TransactionData
      };
    }
    return {};
  };
  const mapCustomSourceConfiguration = (generationConfiguration) => {
    if (generationConfiguration.CustomSourceConfiguration instanceof OneCustomSourceConfiguration) {
      return {
        OneMapping: generationConfiguration.CustomSourceConfiguration.Mapping
      };
    } else if (generationConfiguration.CustomSourceConfiguration instanceof SeparateCustomSourceConfiguration) {
      return {
        SaleMapping: generationConfiguration.CustomSourceConfiguration.SaleMapping,
        PurchaseMapping: generationConfiguration.CustomSourceConfiguration.PurchaseMapping
      };
    }
    return void 0;
  };
  const mapGenerationConfiguration = (generationConfiguration) => ({
    AvailableTemplates: generationConfiguration.AvailableTemplates,
    ComplianceUploadGenerationMode: generationConfiguration.ComplianceUploadGenerationMode,
    FormatSettings: generationConfiguration.FormatSettings,
    IsCustomSource: generationConfiguration.IsCustomSource,
    SalePurchaseSeparately: generationConfiguration.SalePurchaseSeparately,
    TemplateFileReference: generationConfiguration.TemplateFileReference ? mapTemplateFileReference(generationConfiguration) : void 0,
    CustomSourceConfiguration: generationConfiguration.CustomSourceConfiguration ? mapCustomSourceConfiguration(generationConfiguration) : void 0
  });
  const generationConfigurationClient = useClient(GenerationConfigurationClient);
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const transactionTypesClient = useClient(TransactionTypesClient);
  const reportingCountries = useWorldCountries(currentLocale);
  return CustomDataTemplate({
    reportingCountries,
    createCustomDataTemplate,
    getGenerationConfiguration,
    updateCustomDataTemplate,
    getMetaDataConfiguration,
    updateTemplateReference,
    searchTaxCodes,
    getTypeOfGoods,
    getVATRateTypeSettings,
    addTaxCode,
    updateTaxCode,
    deleteTaxCode,
    getTransactionTypes,
    updateCustomSourceMapping,
    getValidationResult
  });
}
