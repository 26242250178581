var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class MpValidationMessage {
  constructor(_data) {
    /**
     */
    __publicField(this, "ColumnCode");
    /**
     */
    __publicField(this, "ColumnName");
    /**
     */
    __publicField(this, "IsWarning");
    /**
     */
    __publicField(this, "Parameters");
    /**
     */
    __publicField(this, "RowNumber");
    /**
     */
    __publicField(this, "TotalCount");
    /**
     */
    __publicField(this, "ValidationCode");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MpValidationMessage
     */
    __publicField(this, "$type", "V1.ComplianceUploads.MpValidationMessage");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ColumnCode = MpValidationMessageColumnCode[_data["ColumnCode"]];
      this.ColumnName = MpValidationMessageColumnName[_data["ColumnName"]];
      this.IsWarning = _data["IsWarning"];
      if (_data["Parameters"] && _data["Parameters"].constructor === Array) {
        this.Parameters = [];
        for (let item of _data["Parameters"]) {
          this.Parameters.push(item);
        }
      }
      this.RowNumber = _data["RowNumber"];
      this.TotalCount = _data["TotalCount"];
      this.ValidationCode = MpValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ColumnCode"] = MpValidationMessageColumnCode[this.ColumnCode];
    _data["ColumnName"] = MpValidationMessageColumnName[this.ColumnName];
    _data["IsWarning"] = this.IsWarning;
    if (this.Parameters && this.Parameters.constructor === Array) {
      _data["Parameters"] = [];
      for (let item of this.Parameters) {
        _data["Parameters"].push(item);
      }
    }
    _data["RowNumber"] = this.RowNumber;
    _data["TotalCount"] = this.TotalCount;
    _data["ValidationCode"] = MpValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var MpValidationMessageColumnCode = /* @__PURE__ */ ((MpValidationMessageColumnCode2) => {
  MpValidationMessageColumnCode2["A"] = "A";
  MpValidationMessageColumnCode2["B"] = "B";
  MpValidationMessageColumnCode2["C"] = "C";
  MpValidationMessageColumnCode2["D"] = "D";
  MpValidationMessageColumnCode2["E"] = "E";
  MpValidationMessageColumnCode2["F"] = "F";
  MpValidationMessageColumnCode2["G"] = "G";
  MpValidationMessageColumnCode2["H"] = "H";
  MpValidationMessageColumnCode2["I"] = "I";
  MpValidationMessageColumnCode2["J"] = "J";
  MpValidationMessageColumnCode2["K"] = "K";
  MpValidationMessageColumnCode2["L"] = "L";
  MpValidationMessageColumnCode2["M"] = "M";
  MpValidationMessageColumnCode2["N"] = "N";
  MpValidationMessageColumnCode2["O"] = "O";
  MpValidationMessageColumnCode2["P"] = "P";
  MpValidationMessageColumnCode2["Q"] = "Q";
  MpValidationMessageColumnCode2["R"] = "R";
  MpValidationMessageColumnCode2["S"] = "S";
  MpValidationMessageColumnCode2["T"] = "T";
  MpValidationMessageColumnCode2["U"] = "U";
  MpValidationMessageColumnCode2["V"] = "V";
  MpValidationMessageColumnCode2["W"] = "W";
  MpValidationMessageColumnCode2["X"] = "X";
  MpValidationMessageColumnCode2["Y"] = "Y";
  MpValidationMessageColumnCode2["Z"] = "Z";
  MpValidationMessageColumnCode2["AA"] = "AA";
  MpValidationMessageColumnCode2["AB"] = "AB";
  MpValidationMessageColumnCode2["AC"] = "AC";
  MpValidationMessageColumnCode2["AD"] = "AD";
  MpValidationMessageColumnCode2["AE"] = "AE";
  MpValidationMessageColumnCode2["AF"] = "AF";
  MpValidationMessageColumnCode2["AG"] = "AG";
  MpValidationMessageColumnCode2["AH"] = "AH";
  MpValidationMessageColumnCode2["AI"] = "AI";
  MpValidationMessageColumnCode2["AJ"] = "AJ";
  MpValidationMessageColumnCode2["AK"] = "AK";
  MpValidationMessageColumnCode2["AL"] = "AL";
  MpValidationMessageColumnCode2["AM"] = "AM";
  MpValidationMessageColumnCode2["AN"] = "AN";
  MpValidationMessageColumnCode2["AO"] = "AO";
  MpValidationMessageColumnCode2["AP"] = "AP";
  MpValidationMessageColumnCode2["AQ"] = "AQ";
  MpValidationMessageColumnCode2["AR"] = "AR";
  MpValidationMessageColumnCode2["AS"] = "AS";
  MpValidationMessageColumnCode2["AT"] = "AT";
  MpValidationMessageColumnCode2["AU"] = "AU";
  MpValidationMessageColumnCode2["AV"] = "AV";
  MpValidationMessageColumnCode2["AW"] = "AW";
  MpValidationMessageColumnCode2["AX"] = "AX";
  MpValidationMessageColumnCode2["AY"] = "AY";
  MpValidationMessageColumnCode2["AZ"] = "AZ";
  MpValidationMessageColumnCode2["BA"] = "BA";
  MpValidationMessageColumnCode2["BB"] = "BB";
  MpValidationMessageColumnCode2["BC"] = "BC";
  MpValidationMessageColumnCode2["BD"] = "BD";
  MpValidationMessageColumnCode2["BE"] = "BE";
  MpValidationMessageColumnCode2["BF"] = "BF";
  MpValidationMessageColumnCode2["BG"] = "BG";
  MpValidationMessageColumnCode2["BH"] = "BH";
  MpValidationMessageColumnCode2["BI"] = "BI";
  MpValidationMessageColumnCode2["BJ"] = "BJ";
  MpValidationMessageColumnCode2["BK"] = "BK";
  MpValidationMessageColumnCode2["BL"] = "BL";
  return MpValidationMessageColumnCode2;
})(MpValidationMessageColumnCode || {});
export var MpValidationMessageColumnName = /* @__PURE__ */ ((MpValidationMessageColumnName2) => {
  MpValidationMessageColumnName2["TransactionType"] = "TransactionType";
  MpValidationMessageColumnName2["TypeOfSale"] = "TypeOfSale";
  MpValidationMessageColumnName2["TypeOfPartner"] = "TypeOfPartner";
  MpValidationMessageColumnName2["VatNumber"] = "VatNumber";
  MpValidationMessageColumnName2["TransactionDate"] = "TransactionDate";
  MpValidationMessageColumnName2["InvoiceNumber"] = "InvoiceNumber";
  MpValidationMessageColumnName2["ShippingFrom"] = "ShippingFrom";
  MpValidationMessageColumnName2["CountryOfCustomer"] = "CountryOfCustomer";
  MpValidationMessageColumnName2["Currency"] = "Currency";
  MpValidationMessageColumnName2["GrossAmount"] = "GrossAmount";
  MpValidationMessageColumnName2["VatReportingCountry"] = "VatReportingCountry";
  MpValidationMessageColumnName2["VatRate"] = "VatRate";
  MpValidationMessageColumnName2["TaxableBasis"] = "TaxableBasis";
  MpValidationMessageColumnName2["VatAmount"] = "VatAmount";
  MpValidationMessageColumnName2["InvoiceDate"] = "InvoiceDate";
  MpValidationMessageColumnName2["TransactionIdentifier"] = "TransactionIdentifier";
  MpValidationMessageColumnName2["DateOfSupply"] = "DateOfSupply";
  MpValidationMessageColumnName2["PaymentDate"] = "PaymentDate";
  MpValidationMessageColumnName2["Consignment"] = "Consignment";
  MpValidationMessageColumnName2["OnlineMarketplace"] = "OnlineMarketplace";
  MpValidationMessageColumnName2["PostponedAccounting"] = "PostponedAccounting";
  MpValidationMessageColumnName2["TaxCollectionResponsibility"] = "TaxCollectionResponsibility";
  MpValidationMessageColumnName2["CountryOfOrigin"] = "CountryOfOrigin";
  MpValidationMessageColumnName2["Weight"] = "Weight";
  MpValidationMessageColumnName2["Unit"] = "Unit";
  MpValidationMessageColumnName2["Quantity"] = "Quantity";
  MpValidationMessageColumnName2["DepartureZipCode"] = "DepartureZipCode";
  MpValidationMessageColumnName2["DeliveryConditions"] = "DeliveryConditions";
  MpValidationMessageColumnName2["NatureOfTransaction"] = "NatureOfTransaction";
  MpValidationMessageColumnName2["CommodityCode"] = "CommodityCode";
  MpValidationMessageColumnName2["ModeOfTransport"] = "ModeOfTransport";
  MpValidationMessageColumnName2["ArrivalZipCode"] = "ArrivalZipCode";
  MpValidationMessageColumnName2["Sale_MPP"] = "Sale_MPP";
  MpValidationMessageColumnName2["SW"] = "SW";
  MpValidationMessageColumnName2["EE"] = "EE";
  MpValidationMessageColumnName2["TP"] = "TP";
  MpValidationMessageColumnName2["TT_WNT"] = "TT_WNT";
  MpValidationMessageColumnName2["TT_D"] = "TT_D";
  MpValidationMessageColumnName2["MR_T"] = "MR_T";
  MpValidationMessageColumnName2["MR_UZ"] = "MR_UZ";
  MpValidationMessageColumnName2["I_42"] = "I_42";
  MpValidationMessageColumnName2["I_63"] = "I_63";
  MpValidationMessageColumnName2["B_SPV"] = "B_SPV";
  MpValidationMessageColumnName2["B_SPV_DOSTWA"] = "B_SPV_DOSTWA";
  MpValidationMessageColumnName2["B_MPV_PROWIZJA"] = "B_MPV_PROWIZJA";
  MpValidationMessageColumnName2["RO"] = "RO";
  MpValidationMessageColumnName2["Sale_WEW"] = "Sale_WEW";
  MpValidationMessageColumnName2["FP"] = "FP";
  MpValidationMessageColumnName2["IMP"] = "IMP";
  MpValidationMessageColumnName2["Purchase_MPP"] = "Purchase_MPP";
  MpValidationMessageColumnName2["MK"] = "MK";
  MpValidationMessageColumnName2["VAT_RR"] = "VAT_RR";
  MpValidationMessageColumnName2["Purchase_WEW"] = "Purchase_WEW";
  MpValidationMessageColumnName2["GTU_01"] = "GTU_01";
  MpValidationMessageColumnName2["GTU_02"] = "GTU_02";
  MpValidationMessageColumnName2["GTU_03"] = "GTU_03";
  MpValidationMessageColumnName2["GTU_04"] = "GTU_04";
  MpValidationMessageColumnName2["GTU_05"] = "GTU_05";
  MpValidationMessageColumnName2["GTU_06"] = "GTU_06";
  MpValidationMessageColumnName2["GTU_07"] = "GTU_07";
  MpValidationMessageColumnName2["GTU_08"] = "GTU_08";
  MpValidationMessageColumnName2["GTU_09"] = "GTU_09";
  MpValidationMessageColumnName2["GTU_10"] = "GTU_10";
  MpValidationMessageColumnName2["GTU_11"] = "GTU_11";
  MpValidationMessageColumnName2["GTU_12"] = "GTU_12";
  MpValidationMessageColumnName2["GTU_13"] = "GTU_13";
  MpValidationMessageColumnName2["PartnerName"] = "PartnerName";
  return MpValidationMessageColumnName2;
})(MpValidationMessageColumnName || {});
export var MpValidationMessageValidationCode = /* @__PURE__ */ ((MpValidationMessageValidationCode2) => {
  MpValidationMessageValidationCode2["WrongTemplateColumns"] = "WrongTemplateColumns";
  MpValidationMessageValidationCode2["WrongHeader"] = "WrongHeader";
  MpValidationMessageValidationCode2["TaxableBasisReportingExceededTheLimit"] = "TaxableBasisReportingExceededTheLimit";
  MpValidationMessageValidationCode2["OutOfPeriodTransactions"] = "OutOfPeriodTransactions";
  MpValidationMessageValidationCode2["Required"] = "Required";
  MpValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  MpValidationMessageValidationCode2["RequiredReportingFields"] = "RequiredReportingFields";
  MpValidationMessageValidationCode2["WrongExchangeRate"] = "WrongExchangeRate";
  MpValidationMessageValidationCode2["InvalidData"] = "InvalidData";
  MpValidationMessageValidationCode2["InvalidDecimal"] = "InvalidDecimal";
  MpValidationMessageValidationCode2["ConstraintNotFulfilled"] = "ConstraintNotFulfilled";
  MpValidationMessageValidationCode2["TransactionIsInTheFuture"] = "TransactionIsInTheFuture";
  MpValidationMessageValidationCode2["TransactionIsBeforeService"] = "TransactionIsBeforeService";
  MpValidationMessageValidationCode2["UnsupportedCurrency"] = "UnsupportedCurrency";
  MpValidationMessageValidationCode2["Empty"] = "Empty";
  MpValidationMessageValidationCode2["IntrastatEmpty"] = "IntrastatEmpty";
  return MpValidationMessageValidationCode2;
})(MpValidationMessageValidationCode || {});
