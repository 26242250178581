var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class DataSourceField {
  constructor(_data) {
    /**
     */
    __publicField(this, "FieldType");
    /**
     */
    __publicField(this, "Name");
    /**
     */
    __publicField(this, "Value");
    /**
     */
    __publicField(this, "Values");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof DataSourceField
     */
    __publicField(this, "$type", "V1.DocGenerator.DataSourceField");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.FieldType = DataSourceFieldFieldType[_data["FieldType"]];
      this.Name = _data["Name"];
      this.Value = _data["Value"];
      if (_data["Values"] && _data["Values"].constructor === Array) {
        this.Values = [];
        for (let item of _data["Values"]) {
          this.Values.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["FieldType"] = DataSourceFieldFieldType[this.FieldType];
    _data["Name"] = this.Name;
    _data["Value"] = this.Value;
    if (this.Values && this.Values.constructor === Array) {
      _data["Values"] = [];
      for (let item of this.Values) {
        _data["Values"].push(item);
      }
    }
    return _data;
  }
}
export var DataSourceFieldFieldType = /* @__PURE__ */ ((DataSourceFieldFieldType2) => {
  DataSourceFieldFieldType2["String"] = "String";
  DataSourceFieldFieldType2["Decimal"] = "Decimal";
  DataSourceFieldFieldType2["Int"] = "Int";
  DataSourceFieldFieldType2["DateTime"] = "DateTime";
  DataSourceFieldFieldType2["Enum"] = "Enum";
  DataSourceFieldFieldType2["Boolean"] = "Boolean";
  DataSourceFieldFieldType2["Unsupported"] = "Unsupported";
  return DataSourceFieldFieldType2;
})(DataSourceFieldFieldType || {});
