var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { CustomSourceMapping } from "./CustomSourceMapping";
import { CustomSourceConfiguration } from "./CustomSourceConfiguration";
export class SeparateCustomSourceConfiguration extends CustomSourceConfiguration {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "PurchaseMapping");
    /**
     */
    __publicField(this, "SaleMapping");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SeparateCustomSourceConfiguration
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.SeparateCustomSourceConfiguration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["PurchaseMapping"]) {
        const purchaseMapping_ = new CustomSourceMapping();
        this.PurchaseMapping = purchaseMapping_.init(_data["PurchaseMapping"]);
      }
      if (_data["SaleMapping"]) {
        const saleMapping_ = new CustomSourceMapping();
        this.SaleMapping = saleMapping_.init(_data["SaleMapping"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["PurchaseMapping"] = this.PurchaseMapping ? this.PurchaseMapping.toJSON() : void 0;
    _data["SaleMapping"] = this.SaleMapping ? this.SaleMapping.toJSON() : void 0;
    return _data;
  }
}
