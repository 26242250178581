import i18next from "i18next";
import { COMPLIANCE_UPLOAD_TYPES } from "./ComplianceUploadModels";
export const Statuses = [
  {
    label: i18next.t("New"),
    value: "New"
  },
  {
    label: i18next.t("Invalid Data"),
    value: "InvalidData"
  },
  {
    label: i18next.t("Waiting For Finalize"),
    value: "WaitingForFinalize"
  },
  {
    label: i18next.t("SAF-T Compliance"),
    value: "SaftCompliance"
  },
  {
    label: i18next.t("Master Data Setup"),
    value: "MasterDataSetup"
  },
  {
    label: i18next.t("Data Upload Finalized"),
    value: "DataUploadFinalized"
  },
  {
    label: i18next.t("Error"),
    value: "Error"
  },
  {
    label: i18next.t("Sent To Return Generation"),
    value: "SentToReturnGeneration"
  },
  {
    label: i18next.t("Upload processed"),
    value: "UploadProcessed"
  },
  {
    label: i18next.t("Insufficient credit"),
    value: "InsufficientCredit"
  },
  {
    label: i18next.t("DataVAT generated"),
    value: "DataVatGenerated"
  }
];
export const DEFAULT_FILTERS = [];
export const DEFAULT_VAT_CONFIG = {
  useAmazonTemplate: false,
  useMpTemplate: false,
  amazonConfiguration: {
    sellerEntities: [],
    useAmazonRates: false
  }
};
export const INITIAL_POLL_TIMEOUT_IN_MILLIS = 1e3;
export const MAX_POLL_TIMEOUT_IN_MILLIS = 1e3 * 60 * 60;
//!!! Temporary disabled because of TDTP-184
export const AMAZON_AUTOMATION_ENABLED = false;
export const getStatusLabel = (status) => {
  const item = Statuses.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const getTypeFilterDataSource = () => {
  const labelMap = {
    VAT: i18next.t("VAT/OSS")
  };
  return COMPLIANCE_UPLOAD_TYPES.map((type) => ({
    label: labelMap[type] || type,
    value: type
  }));
};
export const getType = (type) => {
  switch (type) {
    case "VAT":
      return i18next.t("VAT/OSS");
    case "IOSS":
      return i18next.t("IOSS");
    default:
      return "";
  }
};
export const isFinalizedStatusLike = (status) => status === "DataUploadFinalized" || status === "Error" || status === "SentToReturnGeneration" || status === "UploadProcessed" || status === "InsufficientCredit" || status === "DataVatGenerated";
