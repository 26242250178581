import { getLocalizedUrl, alreadySignedUp, questionnaireCompleted } from "../../globals";
import { html } from "lit-html";
import i18next from "i18next";
import { useQueryState } from "GlobalShared/haunted/CustomHooks";
export function AdminHome() {
  const [isNewCompany] = useQueryState("newcompany", false);
  const welcomeTemplate = () => {
    return html`<div class="flex flex-col items-center">
			<div class="text-2xl font-bold text-brand-primary py-8">${i18next.t("Fill out the Questionnaire")}</div>
			<div class="mb-2 text-left w-full px-8 max-w-screen-lg font-semibold leading-8">${i18next.t("Thank you for signing up for Tax Desk registration and compliance services.")}</div>
			<div class="mb-2 text-left w-full px-8 max-w-screen-lg font-semibold leading-8">
				${i18next.t("You will shortly receive a welcome e-mail from your dedicated account manager; please feel free to contact them for any questions you might have.")}
			</div>
			<div class="mb-2 text-left w-full px-8 max-w-screen-lg font-semibold leading-8">
				${i18next.t(
      "To get started, you should provide details of your business by completing the Seller questionnaire on the next pages. This information is required to set you up as a Tax Desk client, issue your invoice as well as to register you. Each page must be completed before progressing to the next page and you can review your answers at the end before submitting them."
    )}
			</div>
			<div class="mb-2 text-left w-full px-8 max-w-screen-lg font-semibold leading-8">
				${i18next.t(
      "We estimate that the questionnaire will take approximately 20 minutes to complete. To be able to answer all questions, we suggest having the following documents to hand:"
    )}
			</div>
			<ul class="ml-6 px-8" style="list-style-type:disc;">
				<li class="mb-2 text-left w-full max-w-screen-lg font-semibold leading-8">${i18next.t("Business incorporation documents (if appropriate)")}</li>
				<li class="mb-2 text-left w-full max-w-screen-lg font-semibold leading-8">${i18next.t("Passport/ID of the legal representative of the business")}</li>
				<li class="mb-2 text-left w-full max-w-screen-lg font-semibold leading-8">${i18next.t("VAT registration certificate for your home country (if appropriate)")}</li>
				<li class="mb-2 text-left w-full max-w-screen-lg font-semibold leading-8">${i18next.t("A bank statement showing your bank account information (if appropriate)")}</li>
			</ul>
			<div class="mb-2 text-left w-full px-8 max-w-screen-lg font-semibold leading-8">
				${i18next.t("If you are unsure how to answer a question, please contact us on {{- contactEmail}} e-mail address.", { contactEmail: ClientContext.ContactEmailAddress })}
			</div>
			<button
				class="btn btn-primary mt-8"
				@click=${() => {
      window.location.href = getLocalizedUrl("/admin/business-details");
    }}
			>
				${i18next.t("Start Questionnaire")}
			</button>
		</div>`;
  };
  const signupTemplate = () => {
    return html`<ww-admin-signup></ww-admin-signup>`;
  };
  return html`<div class="m-2">
		${isNewCompany ? signupTemplate() : html`${alreadySignedUp() ? questionnaireCompleted() ? window.location.href = getLocalizedUrl("/admin/services") : welcomeTemplate() : signupTemplate()}`}
	</div>`;
}
