var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { ChangeUploadType } from "WebServices/APIs/self/models/V1/Cesop/Periods";
import { PeriodField, PeriodFilter, PeriodPager, PeriodSearchRequest, QuarterIdentifier } from "WebServices/APIs/self/models/V1/Cesop/Periods/Search";
import { PeriodFieldOrder } from "WebServices/APIs/self/models/V1/Cesop/Periods/Search/PeriodFieldAbstraction";
import { useClient, useMemo } from "GlobalShared/haunted/CustomHooks";
import { Periods as PeriodsClient, Psps as PspsClient, CountryConfigurations as CountryConfigurationsClient } from "WebServices/APIs/self/clients";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { PeriodFilterStatuses } from "WebServices/APIs/self/models/V1/Cesop/Periods/Search/PeriodFilterAbstraction";
import { FieldNames, TAB_NAMES } from "./PeriodModels";
import { useUploadManager } from "./upload-manager";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
const getFilter = (gridState, selectedTab) => {
  const periodFilter = new PeriodFilter();
  const isStatusFilterApplied = gridState.appliedFilters.some((filter) => filter.field === FieldNames.Status);
  if (gridState.appliedFilters.length > 0) {
    gridState.appliedFilters.forEach((filter) => {
      if (filter.field === FieldNames.PspName) {
        periodFilter.PspName = filter.filterProps.selectedValues;
      } else if (filter.field === FieldNames.StartDate) {
        periodFilter.Quarters = Array.from(filter.filterProps.selectedValues).map((v) => {
          const [year, quarter] = v.split("-");
          return new QuarterIdentifier({
            Quarter: parseInt(quarter),
            Year: parseInt(year)
          });
        });
      } else if (filter.field === FieldNames.ReportingCountry) {
        periodFilter.ReportingCountries = filter.filterProps.selectedValues;
      } else if (filter.field === FieldNames.Status) {
        selectedTab === TAB_NAMES.submitted ? periodFilter.Statuses = [PeriodFilterStatuses.AcceptedByCesop] : periodFilter.Statuses = filter.filterProps.selectedValues;
      } else if (filter.field === FieldNames.Version && (filter.filterProps.min || filter.filterProps.max)) {
        periodFilter.VersionMin = filter.filterProps.min;
        periodFilter.VersionMax = filter.filterProps.max;
      }
    });
  }
  if (!isStatusFilterApplied) {
    selectedTab === TAB_NAMES.submitted ? periodFilter.Statuses = [PeriodFilterStatuses.AcceptedByCesop] : periodFilter.Statuses = Object.values(PeriodFilterStatuses).filter((status) => status !== PeriodFilterStatuses.AcceptedByCesop);
  }
  return periodFilter;
};
const getPager = (gridState) => {
  return new PeriodPager({
    Skip: gridState.pageIndex * gridState.pageSize,
    Top: gridState.pageSize,
    OrderBy: [
      new PeriodField({
        Order: gridState.orderDir === "asc" ? PeriodFieldOrder.Ascending : PeriodFieldOrder.Descending,
        Field: gridState.orderBy
      })
    ]
  });
};
const execute = (action, loader) => {
  return withErrorHandling(
    () => __async(void 0, null, function* () {
      loader == null ? void 0 : loader.start();
      return action();
    }),
    (e) => notifyError(e),
    () => loader == null ? void 0 : loader.stop()
  );
};
export const usePeriodManager = (props) => {
  const periodsClient = useClient(PeriodsClient);
  const pspsClient = useClient(PspsClient);
  const countryConfigurationsClient = useClient(CountryConfigurationsClient);
  const uploadManager = useUploadManager();
  return useMemo(() => {
    return {
      get: (id, withLoader) => __async(void 0, null, function* () {
        return execute(
          () => __async(void 0, null, function* () {
            return (yield periodsClient.getPeriod({ id })).data;
          }),
          withLoader ? props.loader : void 0
        );
      }),
      search: (state, selectedTab) => __async(void 0, null, function* () {
        const filter = getFilter(state, selectedTab);
        const pager = getPager(state);
        const request = new PeriodSearchRequest({
          Filter: filter,
          Pager: pager
        });
        return execute(() => __async(void 0, null, function* () {
          return (yield periodsClient.search({ body: request })).data;
        }), props.loader);
      }),
      approveSummary: (period) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          yield periodsClient.approveSummary({ id: period.Id });
          notifySuccess("Summary approved");
        }), props.loader);
      }),
      rejectSummary: (period) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          yield periodsClient.rejectSummary({ id: period.Id });
          notifySuccess("Summary rejected");
        }), props.loader);
      }),
      finalizeUpload: (period) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          yield periodsClient.finalizeUpload({ id: period.Id });
          notifySuccess("Period finalized successfully");
        }), props.loader);
      }),
      changeUploadType: (uploadType, period) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          const request = new ChangeUploadType({ UploadType: uploadType });
          yield periodsClient.changeUploadType({ id: period.Id, body: request });
        }), props.loader);
      }),
      uploadTransactions: (period, file) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          yield uploadManager.uploadTransactions(period.Id, file);
        }), props.loader);
      }),
      deleteSourceFile: (sourceFile) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          yield uploadManager.delete(sourceFile.Id);
        }), props.loader);
      }),
      downloadFile: (period, fileReference) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          const response = yield periodsClient.downloadFile({ id: period.Id, fileReference });
          simulateDownload(response.data);
        }), props.loader);
      }),
      uploadPayees: (period, file) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          yield uploadManager.uploadPayees(period.Id, file);
        }), props.loader);
      }),
      deletePayees: (period) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          yield uploadManager.deletePayees(period.Id);
        }), props.loader);
      }),
      getPayeeValidation: (period) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          return (yield periodsClient.getPayeeValidationErrors({ id: period.Id })).data;
        }), props.loader);
      }),
      getTransactionValidation: (period, sourceFile) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          return (yield periodsClient.getTransactionValidationErrors({ periodId: period.Id, sourceFileId: sourceFile.Id })).data;
        }), props.loader);
      }),
      getCountryConfiguration: (period) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          return (yield countryConfigurationsClient.getConfiguration({ countryCode: period.ReportingCountry })).data;
        }), props.loader);
      }),
      downloadAllProofs: (periodId) => __async(void 0, null, function* () {
        return execute(() => __async(void 0, null, function* () {
          const response = yield periodsClient.downloadProofFiles({ id: periodId });
          simulateDownload(response.data);
        }), props.loader);
      })
    };
  }, [periodsClient, pspsClient]);
};
