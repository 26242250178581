var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export const usePeriodHandlers = ({ period, periodManager, reload, refresh }) => {
  const handleChangeUploadType = (ut) => __async(void 0, null, function* () {
    yield periodManager.changeUploadType(ut, period);
    yield reload();
  });
  const handleTransactionUpload = (file) => __async(void 0, null, function* () {
    yield periodManager.uploadTransactions(period, file);
    yield reload();
  });
  const handlePayeesUpload = (file) => __async(void 0, null, function* () {
    yield periodManager.uploadPayees(period, file);
    yield reload();
  });
  const handleDeletePayees = () => __async(void 0, null, function* () {
    yield periodManager.deletePayees(period);
    yield reload();
  });
  const handleDeleteSourceFile = (sourceFile) => __async(void 0, null, function* () {
    yield periodManager.deleteSourceFile(sourceFile);
    yield reload();
  });
  const handleRejectSummary = () => __async(void 0, null, function* () {
    yield periodManager.rejectSummary(period);
    yield reload();
    yield refresh();
  });
  const handleApproveSummary = () => __async(void 0, null, function* () {
    yield periodManager.approveSummary(period);
    yield reload();
    yield refresh();
  });
  const onPayeesFileUpload = (files) => __async(void 0, null, function* () {
    if (files && files.length > 0) {
      yield handlePayeesUpload(files[0]);
    }
    return false;
  });
  const onTransactionsFilesUpload = (files) => __async(void 0, null, function* () {
    if (files && files.length > 0) {
      Array.from(files).forEach((file) => __async(void 0, null, function* () {
        yield handleTransactionUpload(file);
      }));
    }
    return false;
  });
  const onFileDownload = (file) => {
    if (!file) {
      return;
    }
    periodManager.downloadFile(period, file.Reference);
  };
  return {
    handleChangeUploadType,
    handleDeletePayees,
    handleDeleteSourceFile,
    handleRejectSummary,
    handleApproveSummary,
    onPayeesFileUpload,
    onTransactionsFilesUpload,
    onFileDownload
  };
};
