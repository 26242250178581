import { ValidationErrorGroup } from "./ValidationErrorGroup";
import { PayeeValidationErrorGroup } from "./Payee/PayeeValidationErrorGroup";
import { TransactionValidationErrorGroup } from "./Transaction/TransactionValidationErrorGroup";
export class ValidationErrorGroupFactory {
  static create($type) {
    if ($type === "V1.Cesop.Periods.Validation.Source.ValidationErrorGroup") {
      const result = new ValidationErrorGroup();
      return result;
    }
    if ($type === "V1.Cesop.Periods.Validation.Source.Payee.PayeeValidationErrorGroup") {
      const result = new PayeeValidationErrorGroup();
      return result;
    }
    if ($type === "V1.Cesop.Periods.Validation.Source.Transaction.TransactionValidationErrorGroup") {
      const result = new TransactionValidationErrorGroup();
      return result;
    }
    throw new Error($type + " not found");
  }
}
