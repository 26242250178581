var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./dc-select.scss";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ifDefined } from "lit-html/directives/if-defined";
import { useEffect, useRef, useState } from "GlobalShared/haunted/CustomHooks";
import { arrayEquals } from "GlobalShared/helpers/collectionHelper";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
import { component } from "haunted";
import classNames from "classnames";
const DEFAULTS = {
  multiSelect: false,
  dataSource: [],
  selectedValues: void 0,
  selectedIndices: void 0,
  filterable: false,
  debounceMs: 150,
  filterProps: { type: "startsWith", ignoreCase: true },
  readonly: false,
  tooltip: "",
  class: "",
  customSelectItemsClass: "",
  showItemTitle: false
};
export class SelectChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, cancelable: true });
  }
}
export const Component = (host) => {
  var _a;
  const props = {
    multiSelect: host.multiSelect !== void 0 ? host.multiSelect : DEFAULTS.multiSelect,
    label: host.label,
    filterable: host.filterable !== void 0 ? host.filterable : DEFAULTS.filterable,
    dataSource: host.dataSource !== void 0 ? host.dataSource : DEFAULTS.dataSource,
    validationMessage: host.validationMessage,
    selectedValues: host.selectedValues !== void 0 ? host.selectedValues : DEFAULTS.selectedValues,
    selectedIndices: host.selectedIndices !== void 0 ? host.selectedIndices : DEFAULTS.selectedIndices,
    filterProps: host.filterProps !== void 0 ? {
      type: host.filterProps.type !== void 0 ? host.filterProps.type : DEFAULTS.filterProps.type,
      ignoreCase: host.filterProps.ignoreCase !== void 0 ? host.filterProps.ignoreCase : DEFAULTS.filterProps.ignoreCase
    } : DEFAULTS.filterProps,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    tooltip: host.tooltip !== void 0 ? host.tooltip : DEFAULTS.tooltip,
    debounceMs: host.debounceMs !== void 0 ? host.debounceMs : DEFAULTS.debounceMs,
    placeholder: host.placeholder !== void 0 ? host.placeholder : DEFAULTS.placeholder,
    name: host.name,
    class: host.class !== void 0 ? host.class : DEFAULTS.class,
    customSelectItemsClass: host.customSelectItemsClass !== void 0 ? host.customSelectItemsClass : DEFAULTS.customSelectItemsClass,
    showItemTitle: host.showItemTitle !== void 0 ? host.showItemTitle : DEFAULTS.showItemTitle,
    onAddNewOptionClick: host.onAddNewOptionClick
  };
  const getTextValue = () => {
    if (filterText) {
      return filterText;
    } else {
      return selectedIndices.some((selInd) => dataSourceResult[selInd] !== void 0) ? selectedIndices.filter((selInd) => dataSourceResult[selInd].pseudo === void 0 || !dataSourceResult[selInd].pseudo).map((selInd) => dataSourceResult[selInd].label).join(", ") : "";
    }
  };
  const getSelectedValues = () => {
    if (props.selectedValues === void 0) {
      return [];
    }
    let tempSelectedValues;
    if (typeof props.selectedValues === "string") {
      tempSelectedValues = [props.selectedValues];
    } else if (typeof props.selectedValues === "number") {
      tempSelectedValues = [props.selectedValues.toString()];
    } else {
      tempSelectedValues = props.selectedValues.map((selVal) => typeof selVal === "number" ? selVal.toString() : selVal);
    }
    return tempSelectedValues;
  };
  const getDataSourceResult = (dataSource, setSelectedIndexToo) => __async(void 0, null, function* () {
    const now = Date.now();
    lastCall.current.ts = now;
    let currentResult;
    if (typeof dataSource === "function") {
      const tempResult = dataSource(filterText);
      if (tempResult instanceof Promise) {
        const loadingTimer = window.setTimeout(() => {
          if (lastCall.current.ts === now) {
            setResultMessage(`${i18next.t("Loading")}...`);
          }
        }, 500);
        currentResult = yield tempResult;
        window.clearTimeout(loadingTimer);
      } else {
        currentResult = tempResult;
      }
    } else {
      currentResult = dataSource;
    }
    if (lastCall.current.ts === now) {
      setSelectedIndices([]);
      let newResult = [];
      if (typeof currentResult === "string") {
        setResultMessage(currentResult);
      } else {
        newResult = currentResult.map((item) => {
          let newItem;
          if (typeof item === "string") {
            newItem = { label: item, value: item };
          } else if (typeof item === "number") {
            newItem = { label: item.toString(), value: item.toString() };
          } else if (item.hasOwnProperty("label")) {
            newItem = { label: item.label, value: item.value };
          } else {
            newItem = item;
          }
          let enabled = true;
          if (typeof dataSource !== "function" && filterText !== void 0) {
            enabled = props.filterProps.ignoreCase ? props.filterProps.type === "includes" ? newItem.label.toLowerCase().includes(filterText.toLowerCase()) : newItem.label.toLowerCase().startsWith(filterText.toLowerCase()) : props.filterProps.type === "includes" ? newItem.label.includes(filterText) : newItem.label.startsWith(filterText);
          }
          return __spreadProps(__spreadValues({}, newItem), { enabled, keyboardSelected: false });
        });
        setResultMessage(void 0);
        if (props.filterable) {
          newResult = newResult.map((item, i) => {
            item.keyboardSelected = i === 0;
            return item;
          });
        }
        setDataSourceResult(newResult);
        if (setSelectedIndexToo) {
          setSelectedIndicesByResult(newResult);
        }
      }
    }
  });
  const setSelectedIndicesByResult = (result) => {
    if (props.selectedValues) {
      const tempSelectedValues = getSelectedValues();
      setSelectedIndices(
        result.reduce((aggr, item, currentIndex) => {
          return aggr.concat(tempSelectedValues.some((selVal) => selVal === item.value) ? [currentIndex] : []);
        }, [])
      );
    } else {
      setSelectedIndices(props.selectedIndices || []);
    }
  };
  const [dataSourceResult, setDataSourceResult] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [opened, setOpened] = useState(false);
  const [filterText, setFilterText] = useState(void 0);
  const [debounceTimer, setDebounceTimer] = useState(void 0);
  const lastCall = useRef({ ts: void 0 });
  const [resultMessage, setResultMessage] = useState(void 0);
  const [tabPressed, setTabPressed] = useState(false);
  const init = () => {
    const onClickedOutside = (e) => {
      if (!host.contains(e.target)) {
        setOpened(false);
      }
    };
    const onCloseOnEscKey = (e) => {
      if (e.key === "Escape") {
        setOpened(false);
      }
    };
    const onTabPressed = (e) => {
      if (e.key === "Tab") {
        setTabPressed(true);
      }
    };
    document.addEventListener("keyup", onCloseOnEscKey, true);
    document.addEventListener("click", onClickedOutside, true);
    document.addEventListener("keydown", onTabPressed, true);
    return () => {
      document.removeEventListener("keyup", onCloseOnEscKey);
      document.removeEventListener("click", onClickedOutside);
      document.removeEventListener("keydown", onTabPressed);
    };
  };
  useEffect(init, []);
  useEffect(() => {
    if (typeof props.dataSource !== "function" && !(props.dataSource instanceof Promise)) {
      getDataSourceResult(props.dataSource, true);
    }
  }, [props.dataSource]);
  useEffect(() => {
    getDataSourceResult(props.dataSource, true);
  }, [(_a = props.selectedIndices) == null ? void 0 : _a.join("|"), getSelectedValues().join("|")]);
  useEffect(() => {
    if (filterText !== void 0) {
      open();
      debounce(() => {
        getDataSourceResult(props.dataSource, false);
      });
    }
  }, [filterText]);
  const handleOptionSelection = (index) => {
    setOpened(false);
    if (host.dispatchEvent(
      new SelectChangeEvent({
        selectedIndex: index,
        selectedValue: dataSourceResult[index].value
      })
    )) {
      selectIndex(index);
    }
  };
  const open = () => {
    if (!opened) {
      setOpened(true);
      setDataSourceResult(
        dataSourceResult.map((item, i) => {
          if (props.filterable) {
            item.keyboardSelected = i === 0;
          }
          return item;
        })
      );
    }
  };
  const handleKeyUp = (e) => {
    if (props.readonly) {
      return;
    }
    if (e.key === "Escape") {
      setOpened(false);
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      const firstEnabledItemIndex = dataSourceResult.reduce((index, currVal, i) => currVal.enabled && index === -1 ? i : index, -1);
      const lastEnabledItemIndex = dataSourceResult.reduce((index, currVal, i) => currVal.enabled && index < i ? i : index, -1);
      const keyboardSelectedIndex = dataSourceResult.reduce((index, currVal, i) => currVal.keyboardSelected ? i : index, -1);
      if (e.key === "Enter" && keyboardSelectedIndex > -1) {
        handleOptionSelection(keyboardSelectedIndex);
        e.target.blur();
      } else if (e.key === "Enter" && dataSourceResult.filter((item) => item.enabled).length === 1) {
        selectIndex(lastEnabledItemIndex);
        e.target.blur();
      } else if (e.key === "ArrowDown" && keyboardSelectedIndex < lastEnabledItemIndex) {
        let alreadySet = false;
        setDataSourceResult(
          dataSourceResult.map((item, i) => {
            if (item.enabled && i > keyboardSelectedIndex && !alreadySet) {
              item.keyboardSelected = true;
              alreadySet = true;
            } else {
              item.keyboardSelected = false;
            }
            return item;
          })
        );
      } else if (e.key === "ArrowUp" && keyboardSelectedIndex > firstEnabledItemIndex) {
        let alreadySet = false;
        setDataSourceResult(
          dataSourceResult.reverse().map((item, i) => {
            if (item.enabled && i > dataSourceResult.length - keyboardSelectedIndex - 1 && !alreadySet) {
              item.keyboardSelected = true;
              alreadySet = true;
            } else {
              item.keyboardSelected = false;
            }
            return item;
          }).reverse()
        );
      }
    } else {
      open();
      if (props.filterable) {
        setFilterText(e.currentTarget.value);
      }
    }
  };
  const selectIndex = (index) => {
    let newIndices = [];
    if (props.multiSelect) {
      newIndices = selectedIndices.some((i) => i === index) ? selectedIndices.filter((i) => i !== index) : selectedIndices.concat([index]);
      newIndices.sort((a, b) => {
        return a < b ? -1 : a > b ? 1 : 0;
      });
    } else {
      if (index === void 0) {
        newIndices = [];
      } else {
        newIndices = [index];
      }
    }
    setSelectedIndices(newIndices);
    if (!props.multiSelect) {
      setOpened(false);
    }
    setFilterText(void 0);
    if (!arrayEquals(newIndices, selectedIndices)) {
      if (props.multiSelect) {
        host.dispatchEvent(
          new SelectChangeEvent({
            selectedIndices: newIndices,
            selectedValues: newIndices.map((i) => dataSourceResult[i].value)
          })
        );
      } else {
        host.dispatchEvent(
          new SelectChangeEvent({
            selectedIndex: index,
            selectedValue: index !== void 0 ? dataSourceResult[index].value : void 0
          })
        );
      }
    }
  };
  const debounce = (func, wait = props.debounceMs) => {
    window.clearTimeout(debounceTimer);
    setDebounceTimer(window.setTimeout(func, wait));
  };
  const labelTemplate = () => props.label !== void 0 ? html` <label class="form-label"> ${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""} </label> ` : "";
  const inputTemplate = () => html`
			<input
				class=${classNames("rounded-md select-input", {
    [props.class]: !isEmpty(props.class),
    "readonly": props.readonly,
    "invalid": props.validationMessage,
    "rounded-b-none": opened,
    "rounded-r-none": props.onAddNewOptionClick
  })}
				?readonly=${props.readonly || !props.filterable}
				.value=${getTextValue()}
				name=${ifDefined(props.name)}
				autocomplete="please_dont_autofill"
				placeholder=${ifDefined(props.placeholder)}
				@keyup=${handleKeyUp}
				@focus=${(e) => {
    if (props.filterable && !props.readonly) {
      e.target.select();
      getDataSourceResult(props.dataSource, true);
      setOpened(true);
    }
  }}
				@blur=${() => {
    if (props.filterable) {
      const enabledItems = dataSourceResult.filter((item) => item.enabled);
      if (enabledItems.length === 1 && tabPressed) {
        selectIndex(dataSourceResult.reduce((index, currVal, i) => currVal.enabled && index < i ? i : index, -1));
        setDataSourceResult([]);
        setTabPressed(false);
      }
    }
  }}
				@change=${(e) => {
    e.stopPropagation();
  }}
			/>
		`;
  const addNewOptionTemplate = () => props.onAddNewOptionClick ? html`
					<div
						class="flex items-center justify-center font-semibold text-sm border bg-gray-50 border-solid border-gray-600 rounded-r-md border-l-0 cursor-pointer w-36 text-center hover:bg-gray-100"
						@click=${() => {
    setOpened(false);
    props.onAddNewOptionClick();
  }}
					>
						+ ${i18next.t("Add New")}
					</div>
			  ` : "";
  return html`
		${labelTemplate()}
		<div class="flex">
			<div
				class=${classMap({
    "dc-select": true,
    opened
  })}
				@click=${() => {
    if (!props.readonly && (!props.filterable || isEmpty(filterText))) {
      open();
    }
  }}
			>
				${inputTemplate()} ${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
				${opened && !resultMessage ? html`
							<div
								class=${classNames("select-items rounded-b-md", {
    [props.customSelectItemsClass]: props.customSelectItemsClass
  })}
							>
								${dataSourceResult.map(
    (item, index) => item.enabled ? html`
												<div
													@click=${(e) => {
      e.stopPropagation();
      handleOptionSelection(index);
    }}
													class=${classMap({
      "item": true,
      "selected": selectedIndices.some((i) => i === index),
      "keyboard-selected": item.keyboardSelected
    })}
													title=${props.showItemTitle ? item.label : ""}
												>
													${item.label}
												</div>
										  ` : ""
  )}
							</div>
					  ` : ""}
				${opened && resultMessage ? html`
							<div class="select-items">
								<div>${resultMessage}</div>
							</div>
					  ` : ""}
			</div>
			${addNewOptionTemplate()}
		</div>
	`;
};
customElements.define(
  "dc-select",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
