var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { FixedEstablishmentsAnswer as WebFixedEstablishmentsAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { FixedEstablishmentsAnswer as AdminFixedEstablishmentsAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import {
  FixedEstablishmentsValidator,
  FixedEstablishmentValidator
} from "./AnswerValidators";
import { newUniqueId } from "GlobalShared/helpers/stringHelper";
import i18next from "i18next";
import { Address } from "WebServices/APIs/self/models/V1/Common/Address";
import { templateTitle } from "GlobalShared/templates/commons";
import { FixedEstablishment as WebFixedEstablishment } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/FixedEstablishment";
import { FixedEstablishment as AdminFixedEstablishment } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/FixedEstablishment";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
export const observedAttributes = ["q11e", "questionId", "euCountries"];
export const useShadowDOM = false;
export const name = "ww-answer-fixed-establishments";
const DEFAULT_FIXED_ESTABLISHMENT = {
  Id: void 0,
  AddressCountry: "",
  Address: new Address({
    City: "",
    Street: "",
    StreetNumber: "",
    Zip: ""
  }),
  TradingName: ""
};
const DEFAULTS = {
  validation: {
    required: false
  },
  euCountries: []
};
export class FixedEstablishmentChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation,
    euCountries: host.euCountries ? host.euCountries : DEFAULTS.euCountries
  };
  const getLabel = (fieldName, required = false) => {
    var _a;
    return ((_a = getSubQuestion(fieldName)) == null ? void 0 : _a.Label) + (required ? "*" : "");
  };
  const getSubQuestion = (questionId) => {
    const filteredSubQuestions = question == null ? void 0 : question.SubQuestions.filter((subQuestion) => subQuestion.Id === questionId);
    if ((filteredSubQuestions == null ? void 0 : filteredSubQuestions.length) === 1) {
      return filteredSubQuestions[0];
    } else {
      return void 0;
    }
  };
  const initForm = () => {
    fixedEstablishmentValidationContext.clearFieldValidations();
  };
  const getAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminFixedEstablishmentsAnswer({
        Value: fixedEstablishments.map((fixedEstablishmentViewModel) => new AdminFixedEstablishment(__spreadValues({}, fixedEstablishmentViewModel))),
        QuestionId: props.questionId
      });
    }
    return new WebFixedEstablishmentsAnswer({
      Value: fixedEstablishments.map((fixedEstablishmentViewModel) => new WebFixedEstablishment(__spreadValues({}, fixedEstablishmentViewModel))),
      QuestionId: props.questionId
    });
  };
  const editFixedEstablishment = (id) => {
    initForm();
    setCurrentFixedEstablishment(fixedEstablishments.find((f) => f.Id === id));
  };
  const removeFixedEstablishment = (id) => {
    setDispatchIfValid(true);
    setFixedEstablishments(fixedEstablishments.filter((f) => f.Id !== id));
  };
  const saveFixedEstablishment = () => {
    if (fixedEstablishmentValidationContext.validationResult.isValid()) {
      setDispatchIfValid(true);
      if (currentFixedEstablishment.Id === void 0) {
        currentFixedEstablishment.Id = newUniqueId();
        setFixedEstablishments([...fixedEstablishments, currentFixedEstablishment]);
      } else {
        setFixedEstablishments(fixedEstablishments.map((f) => f.Id === currentFixedEstablishment.Id ? currentFixedEstablishment : f));
      }
      setCurrentFixedEstablishment(void 0);
    } else {
      fixedEstablishmentValidationContext.validateFields(["address_city", "address_country", "address_street", "address_street_number", "address_zip", "trading_name"]);
      fixedEstablishmentValidationContext.validate(new WebFixedEstablishment(__spreadValues({}, currentFixedEstablishment)));
      setCurrentFixedEstablishment(__spreadValues({}, currentFixedEstablishment));
    }
  };
  const cancelFixedEstablishment = () => {
    setCurrentFixedEstablishment(void 0);
  };
  const startAddingNewFixedEstablishment = () => {
    initForm();
    setCurrentFixedEstablishment(__spreadValues({}, DEFAULT_FIXED_ESTABLISHMENT));
  };
  const [fixedEstablishments, setFixedEstablishments] = useState([]);
  const [currentFixedEstablishment, setCurrentFixedEstablishment] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(
    new FixedEstablishmentsValidator(props.validation, props.q11e)
  );
  const fixedEstablishmentValidationContext = useValidationContext(
    new FixedEstablishmentValidator(props.q11e)
  );
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new FixedEstablishmentChangeEvent({
          answer: getAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(getAnswer());
  }, [fixedEstablishments]);
  useEffect(() => {
    fixedEstablishmentValidationContext.validate(new WebFixedEstablishment(__spreadValues({}, currentFixedEstablishment)));
  }, [currentFixedEstablishment]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebFixedEstablishmentsAnswer || answer instanceof AdminFixedEstablishmentsAnswer) {
        setFixedEstablishments(
          answer.Value.map((fixedEstablishment) => __spreadProps(__spreadValues({}, fixedEstablishment), {
            Id: newUniqueId()
          }))
        );
      }
    }
  }, [props.q11e, props.questionId]);
  const templateBasicInfo = () => {
    var _a;
    return html`
			<dc-input
				.readonly=${(_a = props.q11e) == null ? void 0 : _a.isReadonly()}
				.label=${getLabel("trading_name", true)}
				.value=${currentFixedEstablishment == null ? void 0 : currentFixedEstablishment.TradingName}
				.validationMessage=${fixedEstablishmentValidationContext.getValidationMessage("trading_name")}
				@change=${(e) => {
      var _a2;
      fixedEstablishmentValidationContext.validateField("trading_name");
      setCurrentFixedEstablishment(__spreadProps(__spreadValues({}, currentFixedEstablishment), { TradingName: (_a2 = e.detail) == null ? void 0 : _a2.value }));
    }}
			></dc-input>
		`;
  };
  const templateAddress = () => {
    var _a, _b, _c, _d, _e;
    return html`
			<div class="lg:col-span-2 grid md:grid-cols-2 gap-8">
				<dc-select
					.label=${getLabel("address_country", true)}
					.selectedValues=${[currentFixedEstablishment == null ? void 0 : currentFixedEstablishment.AddressCountry]}
					.dataSource=${props.euCountries.map((country) => ({ label: country.Name, value: country.Code }))}
					.readonly=${(_a = props.q11e) == null ? void 0 : _a.isReadonly()}
					.filterable=${true}
					.validationMessage=${fixedEstablishmentValidationContext.getValidationMessage("address_country")}
					@change=${(e) => {
      fixedEstablishmentValidationContext.validateField("address_country");
      setCurrentFixedEstablishment(__spreadProps(__spreadValues({}, currentFixedEstablishment), {
        AddressCountry: e.detail.selectedValue
      }));
    }}
				></dc-select>
				<dc-input
					.readonly=${(_b = props.q11e) == null ? void 0 : _b.isReadonly()}
					.label=${getLabel("address_city", true)}
					.value=${currentFixedEstablishment == null ? void 0 : currentFixedEstablishment.Address.City}
					.validationMessage=${fixedEstablishmentValidationContext.getValidationMessage("address_city")}
					@change=${(e) => {
      var _a2;
      fixedEstablishmentValidationContext.validateField("address_city");
      setCurrentFixedEstablishment(__spreadProps(__spreadValues({}, currentFixedEstablishment), {
        Address: new Address(__spreadProps(__spreadValues({}, currentFixedEstablishment.Address), { City: (_a2 = e.detail) == null ? void 0 : _a2.value }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${(_c = props.q11e) == null ? void 0 : _c.isReadonly()}
					.label=${getLabel("address_street", true)}
					.value=${currentFixedEstablishment == null ? void 0 : currentFixedEstablishment.Address.Street}
					.validationMessage=${fixedEstablishmentValidationContext.getValidationMessage("address_street")}
					@change=${(e) => {
      var _a2;
      fixedEstablishmentValidationContext.validateField("address_street");
      setCurrentFixedEstablishment(__spreadProps(__spreadValues({}, currentFixedEstablishment), {
        Address: new Address(__spreadProps(__spreadValues({}, currentFixedEstablishment.Address), { Street: (_a2 = e.detail) == null ? void 0 : _a2.value }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${(_d = props.q11e) == null ? void 0 : _d.isReadonly()}
					.label=${getLabel("address_street_number", true)}
					.value=${currentFixedEstablishment == null ? void 0 : currentFixedEstablishment.Address.StreetNumber}
					.validationMessage=${fixedEstablishmentValidationContext.getValidationMessage("address_street_number")}
					@change=${(e) => {
      var _a2;
      fixedEstablishmentValidationContext.validateField("address_street_number");
      setCurrentFixedEstablishment(__spreadProps(__spreadValues({}, currentFixedEstablishment), {
        Address: new Address(__spreadProps(__spreadValues({}, currentFixedEstablishment.Address), {
          StreetNumber: (_a2 = e.detail) == null ? void 0 : _a2.value
        }))
      }));
    }}
				></dc-input>
				<dc-input
					.readonly=${(_e = props.q11e) == null ? void 0 : _e.isReadonly()}
					.label=${getLabel("address_zip", true)}
					.value=${currentFixedEstablishment == null ? void 0 : currentFixedEstablishment.Address.Zip}
					.validationMessage=${fixedEstablishmentValidationContext.getValidationMessage("address_zip")}
					@change=${(e) => {
      var _a2;
      fixedEstablishmentValidationContext.validateField("address_zip");
      setCurrentFixedEstablishment(__spreadProps(__spreadValues({}, currentFixedEstablishment), {
        Address: new Address(__spreadProps(__spreadValues({}, currentFixedEstablishment.Address), { Zip: (_a2 = e.detail) == null ? void 0 : _a2.value }))
      }));
    }}
				></dc-input>
			</div>
		`;
  };
  const htmlFixedEstablishmentModalContent = () => {
    var _a;
    return html`
			<div class="px-8 py-4">
				<div class="grid md:grid-cols-2 gap-8">${templateBasicInfo()} ${templateAddress()}</div>
				<div class="mt-8 flex gap-4">
					${SecondaryButton({ class: "max-w-fit", text: i18next.t("Cancel"), onClick: cancelFixedEstablishment })}
					${((_a = props.q11e) == null ? void 0 : _a.isReadonly()) ? "" : PrimaryButton({ class: "max-w-fit", text: i18next.t("Save"), onClick: saveFixedEstablishment })}
				</div>
			</div>
		`;
  };
  const htmlAddNewFixedEstablishmentModal = () => html`
			<dc-modal
				.visible=${currentFixedEstablishment !== void 0}
				@close=${() => setCurrentFixedEstablishment(void 0)}
				.header=${i18next.t("Add new VAT ID")}
				.content=${htmlFixedEstablishmentModalContent()}
			>
			</dc-modal>
		`;
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<div>
						<div class="my-4">${templateTitle(i18next.t("Fixed Establishments"))}</div>
						${fixedEstablishments.length > 0 ? fixedEstablishments.map(
    (fixedEstablishment) => html`<div class="flex justify-between py-2">
												<div>
													<a class="link" href="javascript:void(0);" @click=${() => editFixedEstablishment(fixedEstablishment.Id)}
														>${fixedEstablishment.AddressCountry}, ${fixedEstablishment.TradingName}</a
													>
												</div>
												${props.q11e.isReadonly() ? "" : html`<div>
															<a class="link" href="javascript:void(0);" @click=${() => removeFixedEstablishment(fixedEstablishment.Id)}>${i18next.t("Remove")}</a>
													  </div>`}
											</div>
											<hr />`
  ) : html`${currentFixedEstablishment === void 0 ? html`<div class="italic mb-10">${i18next.t("Please add at least one fixed establishment.")}</div>` : ""}`}
					</div>
					${props.q11e.isReadonly() ? "" : PrimaryButton({ class: "max-w-fit mt-8 mb-4", text: i18next.t("Add new Fixed Establishment"), onClick: startAddingNewFixedEstablishment })} ` : ""}
		${htmlAddNewFixedEstablishmentModal()}
	`;
};
