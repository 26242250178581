import {
  OneCustomSourceConfiguration,
  OneCustomSourceReference,
  SeparateCustomSourceConfiguration,
  SeparateCustomSourceReference
} from "WebServices/APIs/self/models/V1/GenerationConfiguration";
export const mapValidationResult = (validationResult) => ({
  IsSuccess: validationResult.IsSuccess,
  NumberOfTotalLinesWithError: validationResult.NumberOfTotalLinesWithError,
  ValidationMessages: validationResult.ValidationMessages.map((v) => ({
    FieldName: v.FieldName,
    RowNumber: v.RowNumber,
    TotalCount: v.TotalCount,
    ValidationCode: v.ValidationCode
  }))
});
const mapTemplateFileReference = (generationConfiguration) => {
  if (generationConfiguration.TemplateFileReference instanceof OneCustomSourceReference) {
    return {
      Reference: generationConfiguration.TemplateFileReference.Reference,
      TransactionDataFields: generationConfiguration.TemplateFileReference.TransactionData.Fields,
      FileName: generationConfiguration.TemplateFileReference.FileName
    };
  } else if (generationConfiguration.TemplateFileReference instanceof SeparateCustomSourceReference) {
    return {
      PurchaseReference: generationConfiguration.TemplateFileReference.PurchaseReference,
      SaleReference: generationConfiguration.TemplateFileReference.SaleReference,
      SeparateTransactionData: generationConfiguration.TemplateFileReference.TransactionData,
      PurchaseFileName: generationConfiguration.TemplateFileReference.PurchaseFileName,
      SaleFileName: generationConfiguration.TemplateFileReference.SaleFileName
    };
  }
  return {};
};
const mapCustomSourceConfiguration = (generationConfiguration) => {
  if (generationConfiguration.CustomSourceConfiguration instanceof OneCustomSourceConfiguration) {
    return {
      OneMapping: generationConfiguration.CustomSourceConfiguration.Mapping
    };
  } else if (generationConfiguration.CustomSourceConfiguration instanceof SeparateCustomSourceConfiguration) {
    return {
      SaleMapping: generationConfiguration.CustomSourceConfiguration.SaleMapping,
      PurchaseMapping: generationConfiguration.CustomSourceConfiguration.PurchaseMapping
    };
  }
  return void 0;
};
export const mapGenerationConfiguration = (generationConfiguration) => ({
  AvailableTemplates: generationConfiguration.AvailableTemplates,
  ComplianceUploadGenerationMode: generationConfiguration.ComplianceUploadGenerationMode,
  FormatSettings: generationConfiguration.FormatSettings,
  IsCustomSource: generationConfiguration.IsCustomSource,
  SalePurchaseSeparately: generationConfiguration.SalePurchaseSeparately,
  TemplateFileReference: generationConfiguration.TemplateFileReference ? mapTemplateFileReference(generationConfiguration) : void 0,
  CustomSourceConfiguration: generationConfiguration.CustomSourceConfiguration ? mapCustomSourceConfiguration(generationConfiguration) : void 0
});
