import { UpdateCustomSourceMapping } from "./UpdateCustomSourceMapping";
import { UpdateOneCustomSourceMapping } from "./UpdateOneCustomSourceMapping";
import { UpdatePurchaseCustomSourceMapping } from "./UpdatePurchaseCustomSourceMapping";
import { UpdateSaleCustomSourceMapping } from "./UpdateSaleCustomSourceMapping";
export class UpdateCustomSourceMappingFactory {
  static create($type) {
    if ($type === "V1.GenerationConfiguration.UpdateCustomSourceMapping") {
      const result = new UpdateCustomSourceMapping();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.UpdateOneCustomSourceMapping") {
      const result = new UpdateOneCustomSourceMapping();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.UpdatePurchaseCustomSourceMapping") {
      const result = new UpdatePurchaseCustomSourceMapping();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.UpdateSaleCustomSourceMapping") {
      const result = new UpdateSaleCustomSourceMapping();
      return result;
    }
    throw new Error($type + " not found");
  }
}
