var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useLoadingReducer, useQueryState, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { useCreateDataTemplateStep } from "./useCreateDataTemplateStep";
import { useOneFileConfiguration } from "./useOneFileConfiguration";
import { useSetupTaxCodeStep } from "../taxcodes/useSetupTaxCodeStep";
import { useSeparatedFileConfiguration } from "./useSalePurchaseSeparately";
import { useTestStep } from "./useTestStep";
const CREATE_DATA_TEMPLATE_STEPS = [i18next.t("Create Data Template"), i18next.t("Configure Fields"), i18next.t("Setup tax codes"), i18next.t("Test")];
export function CustomDataTemplate(props) {
  const getAndSetGenerationConfiguration = () => __async(this, null, function* () {
    const generationConfiguration2 = yield props.getGenerationConfiguration(loader);
    setGenerationConfiguration(generationConfiguration2);
  });
  const [loading, dispatchLoading] = useLoadingReducer();
  const [generationConfiguration, setGenerationConfiguration] = useState(void 0);
  const loader = {
    start: () => dispatchLoading("inc"),
    stop: () => dispatchLoading("dec")
  };
  const [currentStep, setCurrentStep] = useQueryState("step", 0);
  const createDataTemplateStep = useCreateDataTemplateStep({
    generationConfiguration,
    loader,
    setStep: setCurrentStep,
    createCustomDataTemplate: props.createCustomDataTemplate,
    getGenerationConfiguration: props.getGenerationConfiguration,
    updateCustomDataTemplate: props.updateCustomDataTemplate
  });
  const oneFileConfigurationStep = useOneFileConfiguration({
    generationConfiguration,
    loader,
    currentStep,
    setStep: setCurrentStep,
    getMetaDataConfiguration: props.getMetaDataConfiguration,
    updateTemplateReference: props.updateTemplateReference,
    updateCustomSourceMapping: props.updateCustomSourceMapping
  });
  const separatedFileConfigurationStep = useSeparatedFileConfiguration({
    generationConfiguration,
    loader,
    currentStep,
    setStep: setCurrentStep,
    getMetaDataConfiguration: props.getMetaDataConfiguration,
    updateTemplateReference: props.updateTemplateReference,
    updateCustomSourceMapping: props.updateCustomSourceMapping,
    getAndSetGenerationConfiguration
  });
  const setupTaxCodeStep = useSetupTaxCodeStep({
    reportingCountries: props.reportingCountries,
    setStep: setCurrentStep,
    searchTaxCodes: props.searchTaxCodes,
    getTypeOfGoods: props.getTypeOfGoods,
    getVATRateTypeSettings: props.getVATRateTypeSettings,
    addTaxCode: props.addTaxCode,
    updateTaxCode: props.updateTaxCode,
    deleteTaxCode: props.deleteTaxCode,
    getTransactionTypes: props.getTransactionTypes
  });
  const testStep = useTestStep({
    generationConfiguration,
    loader,
    getValidationResult: props.getValidationResult,
    setStep: setCurrentStep
  });
  useEffect(() => {
    getAndSetGenerationConfiguration();
  }, [currentStep, oneFileConfigurationStep.uploadedFile, separatedFileConfigurationStep.uploadedFile]);
  return html`
		<div class="mt-8 flex flex-col">
			<dc-step-progressbar
				.items=${CREATE_DATA_TEMPLATE_STEPS}
				.activeIndex=${currentStep}
				@click=${(e) => {
    if (e.detail.step) {
      setCurrentStep(e.detail.step);
    }
  }}
			></dc-step-progressbar>
			${currentStep === 0 ? createDataTemplateStep.template() : ""}
			${currentStep === 1 ? (generationConfiguration == null ? void 0 : generationConfiguration.SalePurchaseSeparately) ? separatedFileConfigurationStep.template() : oneFileConfigurationStep.template() : ""}
			${currentStep === 2 ? setupTaxCodeStep.template() : ""} ${currentStep === 3 ? testStep.template() : ""}
		</div>
		${loading.count > 0 ? html`<dc-loader></dc-loader>` : ""}
	`;
}
