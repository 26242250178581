var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifySuccess, withErrorHandling, notifyErrorWithWarning } from "GlobalShared/helpers/errorHelper";
import { html } from "lit-html";
import i18next from "i18next";
import { useConfirmModal } from "../modals/commonModals";
import "./compliance-uploads.scss";
import { useVatNumberValidationModal } from "../modals/vat-number-validation-modal";
import { useComplianceUploadGrid } from "./useComplianceUploadGrid";
import { useComplianceUploadModal } from "./useComplianceUploadModal";
import { templateTitle } from "GlobalShared/templates/commons";
import { DEFAULT_FILTERS } from "./ComplianceUploadsHelper";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
export const useComplianceUploads = (props) => {
  var _a, _b, _c, _d, _e, _f;
  const loadComplianceUploads = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield props.searchComplianceUploads(complianceUploadGrid.gridState, showAll);
        setSearchResult(result);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const openUploadModal = (uploadId, readOnlyMode) => __async(void 0, null, function* () {
    const complianceUpload = yield props.getCompliance(uploadId);
    complianceUploadModal.setCurrentComplianceUpload(complianceUpload);
    setIsTransactionalModalReadonlyMode(readOnlyMode);
    complianceUploadModal.setUploadModalMode("Opened");
  });
  const resendNotification = (complianceUploadId) => __async(void 0, null, function* () {
    yield props.resendNotification(complianceUploadId);
  });
  const regenerateDataVat = (complianceUploadId) => __async(void 0, null, function* () {
    yield props.regenerateDataVat(complianceUploadId);
  });
  const changeIgnoreOverdue = (complianceUploadId, isOverdue) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.changeIgnoreOverdue(complianceUploadId, isOverdue);
        notifySuccess(`Ignore data upload successfully ${isOverdue ? "enabled" : "disabled"}.`);
        loadComplianceUploads();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [fullScreenLoading, dispatchFullScreenLoading] = useLoadingReducer();
  const [searchResult, setSearchResult] = useState(void 0);
  const [showAll, setShowAll] = useState(false);
  const [isTransactionalModalReadonlyMode, setIsTransactionalModalReadonlyMode] = useState(false);
  const confirmModal = useConfirmModal();
  const complianceUploadModal = useComplianceUploadModal({
    isAdmin: props.isAdmin,
    isTransactionalModalReadonlyMode,
    createUploadJob: props.createUploadJob,
    finishBlobUpload: props.finishBlobUpload,
    updateDefaultCompanyConfiguration: props.updateDefaultCompanyConfiguration,
    finalizeComplianceUpload: props.finalizeComplianceUpload,
    loadComplianceUploads,
    searchUploadJobs: props.searchUploadJobs,
    suppressUploadJobWarnings: props.suppressUploadJobWarnings,
    getCompliance: props.getCompliance,
    removeUploadedVatTransactions: props.removeUploadedVatTransactions,
    getDefaultCompanyConfiguration: props.getDefaultCompanyConfiguration,
    removeUploadedAmazonTransactions: props.removeUploadedAmazonTransactions,
    getRouteByKey: props.getRouteByKey,
    generateSaftTemplate: props.generateSaftTemplate,
    deleteJob: props.deleteJob,
    confirmModal,
    dispatchFullScreenLoading,
    hasAnyIncompleteSKUs: props.hasAnyIncompleteSKUs,
    getCompanyServices: props.getCompanyServices,
    getGenerationConfiguration: props.getGenerationConfiguration,
    hasAnyIncompleteTaxCodes: props.hasAnyIncompleteTaxCodes
  });
  const vatNumberValidationModal = useVatNumberValidationModal({
    isAdmin: props.isAdmin,
    worldCountries: props.worldCountries,
    permissionManager: props.permissionManager,
    getCountryConfigurations: props.getCountryConfigurations,
    updateCountryConfigurations: props.updateCountryConfigurations
  });
  const complianceUploadGrid = useComplianceUploadGrid({
    isAdmin: props.isAdmin,
    serviceCountries: props.serviceCountries,
    worldCountries: props.worldCountries,
    searchResult,
    permissionManager: props.permissionManager,
    openUploadModal,
    setSearchResult,
    cancelUpload: props.cancelUpload,
    reconfirmUpload: props.reconfirmUpload,
    generateTextFile: props.generateTextFile,
    resendNotification,
    regenerateDataVat,
    changeIgnoreOverdue,
    exportComplianceUploads: props.exportComplianceUploads,
    getCountryConfigurations: props.getCountryConfigurations,
    updateCountryConfigurations: props.updateCountryConfigurations
  });
  useEffect(() => __async(void 0, null, function* () {
    loadComplianceUploads();
  }), [showAll, complianceUploadGrid.gridState]);
  const htmlGridTemplate = () => searchResult ? complianceUploadGrid.htmlTemplate() : `${i18next.t("Loading")}...`;
  const htmlLoader = () => fullScreenLoading.count > 0 ? html`<dc-loader></dc-loader>` : "";
  const mainTemplate = html`
		<div class="my-2 mb-16 mt-8 flex justify-between 2xl:w-3/4">
			<div class="grid grid-cols-1 mb-8 gap-8 w-full">
				<div class="md:col-span-${!props.isAdmin ? 2 : 3}">
					<div>${templateTitle(i18next.t("Data Upload"))}</div>
					<div class="flex flex-col justify-between relative my-4 h-32 xl:h-24">
						<div class="flex space-x-4">
							${props.isAdmin ? PrimaryButton({
    class: "max-w-fit whitespace-nowrap",
    text: "Export Table as CSV",
    onClick: () => props.exportComplianceUploads(__spreadProps(__spreadValues({}, complianceUploadGrid.gridState), { pageIndex: 0, pageSize: searchResult == null ? void 0 : searchResult.total }), showAll)
  }) : ""}
							<div class="flex space-x-4">
								${props.isAdmin || ((_b = (_a = complianceUploadModal.companyServices) == null ? void 0 : _a.GlobalServices) == null ? void 0 : _b.IossService) !== void 0 ? html`
											<div class="whitespace-nowrap">
												<button class="h-10 px-6 text-sm select-none rounded-md cursor-pointer bg-brand-primary text-white font-bold hover:bg-brand-primary-dark">
													<a href="/filetemplates/IOSS_Source Template_v1.2.xlsx" target="_blank" rel="noopener" download="IOSS_Template.xlsx"
														>${i18next.t("Download IOSS Template")}</a
													>
												</button>
											</div>
									  ` : ""}
								${props.isAdmin || ((_d = (_c = complianceUploadModal.companyServices) == null ? void 0 : _c.GlobalServices) == null ? void 0 : _d.OssService) !== void 0 || ((_e = complianceUploadModal.generationConfiguration) == null ? void 0 : _e.ComplianceUploadGenerationMode) === "AllCountryInOne" ? html`
											<div class="whitespace-nowrap">
												<button class="h-10 px-6 text-sm select-none rounded-md cursor-pointer bg-brand-primary text-white font-bold hover:bg-brand-primary-dark">
													<a href="/filetemplates/Transactional_Data_MP_Template.xlsx" target="_blank" rel="noopener" download="VAT_Template.xlsx"
														>${i18next.t("Download VAT/OSS Template")}</a
													>
												</button>
											</div>
									  ` : ""}
							</div>
							${props.isAdmin && props.permissionManager.hasPermission("EU_VAT_Validation_Read") ? html`
										<div class="absolute -left-4 top-14 xl:top-0 xl:right-0 xl:left-auto">
											${PrimaryButton({ class: "max-w-fit whitespace-nowrap", text: "VAT Number Validation", onClick: () => vatNumberValidationModal.setShowModal(true) })}
										</div>
								  ` : ""}
						</div>
						<div class="flex space-x-4 mt-16 xl:mt-0">
							<dc-checkbox2 .checked=${showAll} .label=${i18next.t("Show All")} @change=${(e) => setShowAll(e.detail.checked)}></dc-checkbox2>
							${((_f = complianceUploadGrid.gridState.appliedFilters) == null ? void 0 : _f.length) > 0 ? html`
										<div class="whitespace-nowrap">
											${PrimaryButton({
    class: "max-w-fit whitespace-nowrap",
    text: "Reset filters",
    onClick: () => complianceUploadGrid.setGridState(__spreadProps(__spreadValues({}, complianceUploadGrid.gridState), { pageIndex: 0, appliedFilters: DEFAULT_FILTERS }))
  })}
										</div>
								  ` : ""}
						</div>
					</div>
					<div class="mt-8 xl:mt-0">${htmlGridTemplate()}</div>
				</div>
			</div>
			${complianceUploadModal.htmlTemplate()}${confirmModal.mainTemplate()} ${vatNumberValidationModal.mainTemplate()} ${htmlLoader()}
		</div>
	`;
  return { mainTemplate };
};
