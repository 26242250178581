var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Address } from "./../Common/Address";
export class BusinessDetail {
  constructor(_data) {
    /**
     */
    __publicField(this, "Address");
    /**
     */
    __publicField(this, "ContactPosition");
    /**
     */
    __publicField(this, "EstablishmentCountryCode");
    /**
     */
    __publicField(this, "Phone");
    /**
     */
    __publicField(this, "VatNumber");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BusinessDetail
     */
    __publicField(this, "$type", "V1.Companies.BusinessDetail");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Address"]) {
        const address_ = new Address();
        this.Address = address_.init(_data["Address"]);
      }
      this.ContactPosition = _data["ContactPosition"];
      this.EstablishmentCountryCode = _data["EstablishmentCountryCode"];
      this.Phone = _data["Phone"];
      this.VatNumber = _data["VatNumber"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["$type"] = this.$type;
    _data["Address"] = this.Address ? this.Address.toJSON() : void 0;
    _data["ContactPosition"] = this.ContactPosition;
    _data["EstablishmentCountryCode"] = this.EstablishmentCountryCode;
    _data["Phone"] = this.Phone;
    _data["VatNumber"] = this.VatNumber;
    return _data;
  }
}
