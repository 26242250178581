var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateRange } from "./DateRange";
export class TaxCodeTerm {
  constructor(_data) {
    /**
     */
    __publicField(this, "Code");
    /**
     */
    __publicField(this, "DateRange");
    /**
     */
    __publicField(this, "MultiSelectVal");
    /**
     */
    __publicField(this, "NumberVal");
    /**
     */
    __publicField(this, "Operation");
    /**
     */
    __publicField(this, "SelectVal");
    /**
     */
    __publicField(this, "Type");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TaxCodeTerm
     */
    __publicField(this, "$type", "V1.TaxEngine.TaxCodeTerm");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Code = TaxCodeTermCode[_data["Code"]];
      if (_data["DateRange"]) {
        const dateRange_ = new DateRange();
        this.DateRange = dateRange_.init(_data["DateRange"]);
      }
      if (_data["MultiSelectVal"] && _data["MultiSelectVal"].constructor === Array) {
        this.MultiSelectVal = [];
        for (let item of _data["MultiSelectVal"]) {
          this.MultiSelectVal.push(item);
        }
      }
      this.NumberVal = _data["NumberVal"];
      this.Operation = TaxCodeTermOperation[_data["Operation"]];
      this.SelectVal = _data["SelectVal"];
      this.Type = TaxCodeTermType[_data["Type"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Code"] = TaxCodeTermCode[this.Code];
    _data["DateRange"] = this.DateRange ? this.DateRange.toJSON() : void 0;
    if (this.MultiSelectVal && this.MultiSelectVal.constructor === Array) {
      _data["MultiSelectVal"] = [];
      for (let item of this.MultiSelectVal) {
        _data["MultiSelectVal"].push(item);
      }
    }
    _data["NumberVal"] = this.NumberVal;
    _data["Operation"] = TaxCodeTermOperation[this.Operation];
    _data["SelectVal"] = this.SelectVal;
    _data["Type"] = TaxCodeTermType[this.Type];
    return _data;
  }
}
export var TaxCodeTermCode = /* @__PURE__ */ ((TaxCodeTermCode2) => {
  TaxCodeTermCode2["TransactionType"] = "TransactionType";
  TaxCodeTermCode2["EstablishmentCountry"] = "EstablishmentCountry";
  TaxCodeTermCode2["DepartureCountry"] = "DepartureCountry";
  TaxCodeTermCode2["ArrivalCountry"] = "ArrivalCountry";
  TaxCodeTermCode2["CustomerType"] = "CustomerType";
  TaxCodeTermCode2["OnlineMarketplace"] = "OnlineMarketplace";
  TaxCodeTermCode2["NetConsignmentVal"] = "NetConsignmentVal";
  TaxCodeTermCode2["TaxCollectionResponsibility"] = "TaxCollectionResponsibility";
  TaxCodeTermCode2["DeliveryCondition"] = "DeliveryCondition";
  TaxCodeTermCode2["TransactionDate"] = "TransactionDate";
  TaxCodeTermCode2["SourceType"] = "SourceType";
  TaxCodeTermCode2["IsRegisteredInArrivalCountry"] = "IsRegisteredInArrivalCountry";
  TaxCodeTermCode2["DepartureAndArrivalCountry"] = "DepartureAndArrivalCountry";
  TaxCodeTermCode2["AccountWithReverseCharge"] = "AccountWithReverseCharge";
  TaxCodeTermCode2["ExportOutsideEU"] = "ExportOutsideEU";
  TaxCodeTermCode2["TypeOfSale"] = "TypeOfSale";
  TaxCodeTermCode2["PriceOfItem"] = "PriceOfItem";
  TaxCodeTermCode2["ClientHasOSSNumber"] = "ClientHasOSSNumber";
  TaxCodeTermCode2["OSSRegistrationCountry"] = "OSSRegistrationCountry";
  return TaxCodeTermCode2;
})(TaxCodeTermCode || {});
export var TaxCodeTermOperation = /* @__PURE__ */ ((TaxCodeTermOperation2) => {
  TaxCodeTermOperation2["Equals"] = "Equals";
  TaxCodeTermOperation2["NotEquals"] = "NotEquals";
  TaxCodeTermOperation2["LessThan"] = "LessThan";
  TaxCodeTermOperation2["GreaterThan"] = "GreaterThan";
  TaxCodeTermOperation2["LessThanEquals"] = "LessThanEquals";
  TaxCodeTermOperation2["GreaterThanEquals"] = "GreaterThanEquals";
  TaxCodeTermOperation2["In"] = "In";
  TaxCodeTermOperation2["NotIn"] = "NotIn";
  TaxCodeTermOperation2["Is"] = "Is";
  TaxCodeTermOperation2["IsBetween"] = "IsBetween";
  return TaxCodeTermOperation2;
})(TaxCodeTermOperation || {});
export var TaxCodeTermType = /* @__PURE__ */ ((TaxCodeTermType2) => {
  TaxCodeTermType2["Select"] = "Select";
  TaxCodeTermType2["Multiselect"] = "Multiselect";
  TaxCodeTermType2["Number"] = "Number";
  TaxCodeTermType2["DateRange"] = "DateRange";
  return TaxCodeTermType2;
})(TaxCodeTermType || {});
