var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
export class Subscription {
  constructor(_data) {
    /**
     */
    __publicField(this, "CancellationDate");
    /**
     */
    __publicField(this, "EndDate");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "StartDate");
    /**
     */
    __publicField(this, "Status");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Subscription
     */
    __publicField(this, "$type", "V1.CompanyInfo.Subscriptions.Subscription");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CancellationDate = DateConverter.from(_data["CancellationDate"]);
      this.EndDate = DateConverter.from(_data["EndDate"]);
      this.Id = _data["Id"];
      this.StartDate = DateConverter.from(_data["StartDate"]);
      this.Status = SubscriptionStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["$type"] = this.$type;
    _data["CancellationDate"] = DateConverter.toUtcDateString(this.CancellationDate);
    _data["EndDate"] = DateConverter.toUtcDateString(this.EndDate);
    _data["Id"] = this.Id;
    _data["StartDate"] = DateConverter.toUtcDateString(this.StartDate);
    _data["Status"] = SubscriptionStatus[this.Status];
    return _data;
  }
}
export var SubscriptionStatus = /* @__PURE__ */ ((SubscriptionStatus2) => {
  SubscriptionStatus2["Active"] = "Active";
  SubscriptionStatus2["Cancelled"] = "Cancelled";
  SubscriptionStatus2["Pending"] = "Pending";
  return SubscriptionStatus2;
})(SubscriptionStatus || {});
