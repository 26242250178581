var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import i18next from "i18next";
import { useEffect, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
export const useSubscriptionModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState(void 0);
  const [loading, dispatchLoading] = useLoadingReducer();
  useEffect(() => __async(void 0, null, function* () {
    if (props == null ? void 0 : props.search) {
      try {
        dispatchLoading("inc");
        setSubscriptions(yield props.search());
      } finally {
        dispatchLoading("dec");
      }
    }
  }), [props == null ? void 0 : props.search]);
  const show = () => {
    setShowModal(true);
  };
  const template = () => {
    return html`${showModal ? html`
					<div>
						<dc-modal
							.visible=${showModal}
							@close=${() => setShowModal(false)}
							.header=${i18next.t(`Edit ${props.eventType} subscriptions`)}
							.content=${html` <div style="height: 50vh">
								<div class="mt-4">
									<div class="my-4 xl:w-1/2">
										${loading.count > 0 ? `${i18next.t("Loading")}...` : html` <div class="flex flex-col space-y-4">
													${(subscriptions == null ? void 0 : subscriptions.length) === 0 ? html`<div class="font-bold">There are no users assigned to this company.</div>` : subscriptions == null ? void 0 : subscriptions.map((sub) => {
      return html` <div>
																	<dc-checkbox2
																		.checked=${sub.isSubscribed}
																		.label=${sub.email}
																		.labelnowrap=${true}
																		@change=${(_e) => {
        if (sub.isSubscribed) {
          props.unsubscribe(sub.userId);
        } else {
          props.subscribe(sub.userId);
        }
      }}
																	></dc-checkbox2>
																</div>`;
    })}
											  </div>`}
									</div>
								</div>
							</div>`}
						>
						</dc-modal>
					</div>
			  ` : html``}`;
  };
  return { show, template };
};
