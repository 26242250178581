var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { SearchResult as SearchResult0_ } from "./../models/V1/ComplianceMasterData/TaxCode/Search/SearchResult";
import { SearchResult as SearchResult1_ } from "./../models/V1/ComplianceMasterData/SearchSku/SearchResult";
import { UploadResult } from "./../models/V1/ComplianceMasterData/Upload/UploadResult";
import { DefaultVatRateType } from "./../models/V1/ComplianceMasterData/DefaultVatRateType";
import { VatRateTypeSetting } from "./../models/V1/ComplianceMasterData/VatRateTypeSetting";
import { TypeOfGoods } from "./../models/V1/ComplianceMasterData/TypeOfGoods";
import { GTUCode } from "./../models/V1/ComplianceMasterData/GTUCode";
import { Currency } from "./../models/V1/ComplianceMasterData/Currency";
import { CommodityCodes } from "./../models/V1/ComplianceMasterData/CommodityCodes";
export class ComplianceMasterData {
  /**
   * Creates an instance of ComplianceMasterData.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof ComplianceMasterData
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param AddTaxCodeRq_ _addTaxCodeRq
   * @returns Promise<AddTaxCodeRq_>
   * @memberof ComplianceMasterData
   */
  addTaxCode(pAddTaxCodeRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/TaxCodes", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pAddTaxCodeRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 201 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateTaxCodeRq_ _updateTaxCodeRq
   * @returns Promise<UpdateTaxCodeRq_>
   * @memberof ComplianceMasterData
   */
  updateTaxCode(pUpdateTaxCodeRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/TaxCodes/{id}", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "id", value: pUpdateTaxCodeRq.id, required: true }]);
    client.addBody({ value: pUpdateTaxCodeRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteTaxCodeRq_ _deleteTaxCodeRq
   * @returns Promise<DeleteTaxCodeRq_>
   * @memberof ComplianceMasterData
   */
  deleteTaxCode(pDeleteTaxCodeRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/TaxCodes/{id}", "DELETE", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "id", value: pDeleteTaxCodeRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param HasAnyIncompleteTaxCodeRq_ _hasAnyIncompleteTaxCodeRq
   * @returns Promise<HasAnyIncompleteTaxCodeRq_>
   * @memberof ComplianceMasterData
   */
  hasAnyIncompleteTaxCode(pHasAnyIncompleteTaxCodeRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/TaxCodes/Any", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pHasAnyIncompleteTaxCodeRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200 }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchTaxCodesRq_ _searchTaxCodesRq
   * @returns Promise<SearchTaxCodesRq_>
   * @memberof ComplianceMasterData
   */
  searchTaxCodes(pSearchTaxCodesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/TaxCodes/Search", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSearchTaxCodesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult0_ },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchSKUsRq_ _searchSKUsRq
   * @returns Promise<SearchSKUsRq_>
   * @memberof ComplianceMasterData
   */
  searchSKUs(pSearchSKUsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/Skus/Search", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSearchSKUsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult1_ },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportSkusRq_ _exportSkusRq
   * @returns Promise<ExportSkusRq_>
   * @memberof ComplianceMasterData
   */
  exportSkus(pExportSkusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/Skus/Export",
      "POST",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      options
    );
    client.addBody({ value: pExportSkusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, isFile: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSkusRq_ _uploadSkusRq
   * @returns Promise<UploadSkusRq_>
   * @memberof ComplianceMasterData
   */
  uploadSkus(pUploadSkusRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/Skus/Upload", "POST", "multipart/form-data", "text/plain, application/json, text/json", options);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadSkusRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: UploadResult },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param HasAnyIncompleteSKUsRq_ _hasAnyIncompleteSKUsRq
   * @returns Promise<HasAnyIncompleteSKUsRq_>
   * @memberof ComplianceMasterData
   */
  hasAnyIncompleteSKUs(pHasAnyIncompleteSKUsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/Skus/Any", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pHasAnyIncompleteSKUsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddSkuRq_ _addSkuRq
   * @returns Promise<AddSkuRq_>
   * @memberof ComplianceMasterData
   */
  addSku(pAddSkuRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/Skus", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pAddSkuRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 201 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateSkuRq_ _updateSkuRq
   * @returns Promise<UpdateSkuRq_>
   * @memberof ComplianceMasterData
   */
  updateSku(pUpdateSkuRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/Skus/{id}", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "id", value: pUpdateSkuRq.id, required: true }]);
    client.addBody({ value: pUpdateSkuRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param RemoveSkuRq_ _removeSkuRq
   * @returns Promise<RemoveSkuRq_>
   * @memberof ComplianceMasterData
   */
  removeSku(pRemoveSkuRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/Skus/{id}", "DELETE", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "id", value: pRemoveSkuRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetDefaultVatRateTypesRq_ _getDefaultVatRateTypesRq
   * @returns Promise<GetDefaultVatRateTypesRq_>
   * @memberof ComplianceMasterData
   */
  getDefaultVatRateTypes(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/DefaultVatRateTypes", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: DefaultVatRateType, isArray: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SetDefaultVatRateTypesRq_ _setDefaultVatRateTypesRq
   * @returns Promise<SetDefaultVatRateTypesRq_>
   * @memberof ComplianceMasterData
   */
  setDefaultVatRateTypes(pSetDefaultVatRateTypesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/DefaultVatRateTypes", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSetDefaultVatRateTypesRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetVATRateTypeSettingsRq_ _getVATRateTypeSettingsRq
   * @returns Promise<GetVATRateTypeSettingsRq_>
   * @memberof ComplianceMasterData
   */
  getVATRateTypeSettings(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/VATRateTypeSettings", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: VatRateTypeSetting, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetTypeOfGoodsRq_ _getTypeOfGoodsRq
   * @returns Promise<GetTypeOfGoodsRq_>
   * @memberof ComplianceMasterData
   */
  getTypeOfGoods(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/TypeOfGoods", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: TypeOfGoods, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetGTUCodesRq_ _getGTUCodesRq
   * @returns Promise<GetGTUCodesRq_>
   * @memberof ComplianceMasterData
   */
  getGTUCodes(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/GTUCodes", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: GTUCode, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetAvailableCurrenciesRq_ _getAvailableCurrenciesRq
   * @returns Promise<GetAvailableCurrenciesRq_>
   * @memberof ComplianceMasterData
   */
  getAvailableCurrencies(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/ComplianceMasterData/AvailableCurrencies", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: Currency, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportCurrenciesRq_ _exportCurrenciesRq
   * @returns Promise<ExportCurrenciesRq_>
   * @memberof ComplianceMasterData
   */
  exportCurrencies(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/ExportCurrencies",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, isFile: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetCommodityCodesRq_ _getCommodityCodesRq
   * @returns Promise<GetCommodityCodesRq_>
   * @memberof ComplianceMasterData
   */
  getCommodityCodes(pGetCommodityCodesRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/CommodityCodes/Search",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addBody({ value: pGetCommodityCodesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CommodityCodes, isArray: true },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportCommodityCodesRq_ _exportCommodityCodesRq
   * @returns Promise<ExportCommodityCodesRq_>
   * @memberof ComplianceMasterData
   */
  exportCommodityCodes(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/ComplianceMasterData/ExportCommodityCodes",
      "GET",
      "application/json; charset=UTF-8",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, isFile: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
