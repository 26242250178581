var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class CustomSaftValidationMessage {
  constructor(_data) {
    /**
     */
    __publicField(this, "ColumnName");
    /**
     */
    __publicField(this, "IsWarning");
    /**
     */
    __publicField(this, "Parameters");
    /**
     */
    __publicField(this, "RowNumber");
    /**
     */
    __publicField(this, "TotalCount");
    /**
     */
    __publicField(this, "ValidationCode");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CustomSaftValidationMessage
     */
    __publicField(this, "$type", "V1.ComplianceUploads.CustomSaftValidationMessage");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ColumnName = CustomSaftValidationMessageColumnName[_data["ColumnName"]];
      this.IsWarning = _data["IsWarning"];
      if (_data["Parameters"] && _data["Parameters"].constructor === Array) {
        this.Parameters = [];
        for (let item of _data["Parameters"]) {
          this.Parameters.push(item);
        }
      }
      this.RowNumber = _data["RowNumber"];
      this.TotalCount = _data["TotalCount"];
      this.ValidationCode = CustomSaftValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ColumnName"] = CustomSaftValidationMessageColumnName[this.ColumnName];
    _data["IsWarning"] = this.IsWarning;
    if (this.Parameters && this.Parameters.constructor === Array) {
      _data["Parameters"] = [];
      for (let item of this.Parameters) {
        _data["Parameters"].push(item);
      }
    }
    _data["RowNumber"] = this.RowNumber;
    _data["TotalCount"] = this.TotalCount;
    _data["ValidationCode"] = CustomSaftValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var CustomSaftValidationMessageColumnName = /* @__PURE__ */ ((CustomSaftValidationMessageColumnName2) => {
  CustomSaftValidationMessageColumnName2["ID"] = "ID";
  CustomSaftValidationMessageColumnName2["TRANSACTION_EVENT_ID"] = "TRANSACTION_EVENT_ID";
  CustomSaftValidationMessageColumnName2["TRANSACTION_COMPLETE_DATE"] = "TRANSACTION_COMPLETE_DATE";
  CustomSaftValidationMessageColumnName2["TRANSACTION_TYPE"] = "TRANSACTION_TYPE";
  CustomSaftValidationMessageColumnName2["ItemIdentifier"] = "ItemIdentifier";
  CustomSaftValidationMessageColumnName2["Sale_MPP"] = "Sale_MPP";
  CustomSaftValidationMessageColumnName2["SW"] = "SW";
  CustomSaftValidationMessageColumnName2["EE"] = "EE";
  CustomSaftValidationMessageColumnName2["TP"] = "TP";
  CustomSaftValidationMessageColumnName2["TT_WNT"] = "TT_WNT";
  CustomSaftValidationMessageColumnName2["TT_D"] = "TT_D";
  CustomSaftValidationMessageColumnName2["MR_T"] = "MR_T";
  CustomSaftValidationMessageColumnName2["MR_UZ"] = "MR_UZ";
  CustomSaftValidationMessageColumnName2["I_42"] = "I_42";
  CustomSaftValidationMessageColumnName2["I_63"] = "I_63";
  CustomSaftValidationMessageColumnName2["B_SPV"] = "B_SPV";
  CustomSaftValidationMessageColumnName2["B_SPV_DOSTWA"] = "B_SPV_DOSTWA";
  CustomSaftValidationMessageColumnName2["B_MPV_PROWIZJA"] = "B_MPV_PROWIZJA";
  CustomSaftValidationMessageColumnName2["RO"] = "RO";
  CustomSaftValidationMessageColumnName2["Sale_WEW"] = "Sale_WEW";
  CustomSaftValidationMessageColumnName2["FP"] = "FP";
  CustomSaftValidationMessageColumnName2["IMP"] = "IMP";
  CustomSaftValidationMessageColumnName2["Purchase_MPP"] = "Purchase_MPP";
  CustomSaftValidationMessageColumnName2["MK"] = "MK";
  CustomSaftValidationMessageColumnName2["VAT_RR"] = "VAT_RR";
  CustomSaftValidationMessageColumnName2["Purchase_WEW"] = "Purchase_WEW";
  return CustomSaftValidationMessageColumnName2;
})(CustomSaftValidationMessageColumnName || {});
export var CustomSaftValidationMessageValidationCode = /* @__PURE__ */ ((CustomSaftValidationMessageValidationCode2) => {
  CustomSaftValidationMessageValidationCode2["WrongTemplateColumns"] = "WrongTemplateColumns";
  CustomSaftValidationMessageValidationCode2["WrongHeader"] = "WrongHeader";
  CustomSaftValidationMessageValidationCode2["TaxableBasisReportingExceededTheLimit"] = "TaxableBasisReportingExceededTheLimit";
  CustomSaftValidationMessageValidationCode2["OutOfPeriodTransactions"] = "OutOfPeriodTransactions";
  CustomSaftValidationMessageValidationCode2["Required"] = "Required";
  CustomSaftValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  CustomSaftValidationMessageValidationCode2["RequiredReportingFields"] = "RequiredReportingFields";
  CustomSaftValidationMessageValidationCode2["WrongExchangeRate"] = "WrongExchangeRate";
  CustomSaftValidationMessageValidationCode2["InvalidData"] = "InvalidData";
  CustomSaftValidationMessageValidationCode2["InvalidDecimal"] = "InvalidDecimal";
  CustomSaftValidationMessageValidationCode2["ConstraintNotFulfilled"] = "ConstraintNotFulfilled";
  CustomSaftValidationMessageValidationCode2["TransactionIsInTheFuture"] = "TransactionIsInTheFuture";
  CustomSaftValidationMessageValidationCode2["TransactionIsBeforeService"] = "TransactionIsBeforeService";
  CustomSaftValidationMessageValidationCode2["UnsupportedCurrency"] = "UnsupportedCurrency";
  CustomSaftValidationMessageValidationCode2["Empty"] = "Empty";
  CustomSaftValidationMessageValidationCode2["IntrastatEmpty"] = "IntrastatEmpty";
  return CustomSaftValidationMessageValidationCode2;
})(CustomSaftValidationMessageValidationCode || {});
