import { PRIMARY_COLOR } from "GlobalShared/common/Constants";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import classNames from "classnames";
import i18next from "i18next";
import { html, svg } from "lit-html";
export const templateTitle = (text) => html`<div class="text-2xl font-bold">${text}</div>`;
export const headerTemplate = () => html`
	<header class="h-16 bg-white w-full border-b border-solid border-gray-400">
		<div class="container h-16 px-8 flex items-center justify-between relative">
			<a href="/" data-routing="server">
				<img src="/images/logo.png" alt="Tax Desk logo" class="h-10" />
			</a>
			<input type="checkbox" class="client-hamburger" id="hamburger" />
			<label for="hamburger" class="client-hamburger-label"></label>
			<div class="client-menu">
				<ww-language-select></ww-language-select>
			</div>
		</div>
	</header>
`;
export const removeIcon = (width = 18, height = 18) => svg`<svg class="fill-brand-primary hover:fill-brand-tertiary" width=${width} height=${height} viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_240_545)">
			<path
				d="M6.3375 0.829688C6.59062 0.31875 7.11094 0 7.67813 0H13.3219C13.8891 0 14.4094 0.31875 14.6625 0.829688L15 1.5H19.5C20.3297 1.5 21 2.17031 21 3C21 3.82969 20.3297 4.5 19.5 4.5H1.5C0.670312 4.5 0 3.82969 0 3C0 2.17031 0.670312 1.5 1.5 1.5H6L6.3375 0.829688ZM1.5 6H19.5V21C19.5 22.6547 18.1547 24 16.5 24H4.5C2.84531 24 1.5 22.6547 1.5 21V6ZM6 9C5.5875 9 5.25 9.3375 5.25 9.75V20.25C5.25 20.6625 5.5875 21 6 21C6.4125 21 6.75 20.6625 6.75 20.25V9.75C6.75 9.3375 6.4125 9 6 9ZM10.5 9C10.0875 9 9.75 9.3375 9.75 9.75V20.25C9.75 20.6625 10.0875 21 10.5 21C10.9125 21 11.25 20.6625 11.25 20.25V9.75C11.25 9.3375 10.9125 9 10.5 9ZM15 9C14.5875 9 14.25 9.3375 14.25 9.75V20.25C14.25 20.6625 14.5875 21 15 21C15.4125 21 15.75 20.6625 15.75 20.25V9.75C15.75 9.3375 15.4125 9 15 9Z"
                stroke-width="1.5"
			/>
		</g>
        <title>${i18next.t("Delete")}</title>
		<defs>
			<clipPath id="clip0_240_545">
				<rect width="21" height="24"/>
			</clipPath>
		</defs>
	</svg>`;
export const downloadPDFIcon = (title, width = 18, height = 24) => svg`
	<svg class="fill-brand-primary hover:fill-brand-tertiary" width=${width} height=${height} viewBox="0 0 18 24" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_240_546)">
			<path
				d="M3 0C1.34531 0 0 1.34531 0 3V21C0 22.6547 1.34531 24 3 24H15C16.6547 24 18 22.6547 18 21V7.5H12C11.1703 7.5 10.5 6.82969 10.5 6V0H3ZM12 0V6H18L12 0ZM5.25 12H12.75C13.1625 12 13.5 12.3375 13.5 12.75C13.5 13.1625 13.1625 13.5 12.75 13.5H5.25C4.8375 13.5 4.5 13.1625 4.5 12.75C4.5 12.3375 4.8375 12 5.25 12ZM5.25 15H12.75C13.1625 15 13.5 15.3375 13.5 15.75C13.5 16.1625 13.1625 16.5 12.75 16.5H5.25C4.8375 16.5 4.5 16.1625 4.5 15.75C4.5 15.3375 4.8375 15 5.25 15ZM5.25 18H12.75C13.1625 18 13.5 18.3375 13.5 18.75C13.5 19.1625 13.1625 19.5 12.75 19.5H5.25C4.8375 19.5 4.5 19.1625 4.5 18.75C4.5 18.3375 4.8375 18 5.25 18Z"
			/>
		</g>
        <title>${title != null ? title : i18next.t("Download PDF")}</title>
		<defs>
			<clipPath id="clip0_240_546">
				<rect width="18" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
`;
export const footerLinksTemplate = () => html`
	<div class="text-xs font-semibold text-center uppercase mt-2 md:mt-4">
		<a class="hover:underline" target="_blank" rel="noopener" href="https://www.taxdesk.com/privacy-policy/">${i18next.t("Privacy")}</a>
		&nbsp;|&nbsp;
		<a class="hover:underline" target="_blank" rel="noopener" href="https://www.taxdesk.com/terms-conditions/">${i18next.t("T&Cs")}</a>
	</div>
`;
export const footerTextTemplate = () => html` <div class="text-sm text-center m-auto max-w-screen-xl">&copy; ${(/* @__PURE__ */ new Date()).getFullYear()} ${i18next.t("layout-footer")}</div> `;
export const subscribeIcon = (fillType) => {
  const fillTypeClasses = fillType === "Active" ? "stroke-brand-tertiary fill-brand-tertiary" : fillType === "Inactive" ? "stroke-brand-tertiary-dark fill-none" : "stroke-brand-primary fill-none";
  return html`<div class="subscribe-icon">
		<svg class="${fillTypeClasses} cursor-pointer hover:stroke-brand-tertiary hover:fill-none" width="20" height="24" viewBox="0 0 302 346">
			<path
				d="M172.562 21.625V34.6C221.769 44.6218 258.843 88.257 258.843 140.562V153.267C258.843 185.096 270.504 215.709 291.535 239.497L296.523 245.106C302.185 251.526 303.601 260.581 300.096 268.353C296.591 276.124 288.906 281.125 280.413 281.125H21.5701C13.0768 281.125 5.37285 276.124 1.8933 268.353C-1.58625 260.581 -0.194496 251.526 5.44902 245.106L10.4479 239.497C31.5059 215.709 43.1403 185.096 43.1403 153.267V140.562C43.1403 88.257 79.6075 44.6218 129.421 34.6V21.625C129.421 9.68395 139.06 0 150.991 0C162.923 0 172.562 9.68395 172.562 21.625ZM150.991 346C139.532 346 128.545 341.472 120.456 333.363C112.367 325.254 107.851 313.63 107.851 302.75H194.132C194.132 313.63 189.616 325.254 181.527 333.363C173.438 341.472 161.844 346 150.991 346Z"
				stroke-width="20"
			/>
		</svg>
	</div>`;
};
export const pageStartIcon = () => svg`
	<svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 4a1 1 0 011 1v10a1 1 0 11-2 0V5a1 1 0 011-1zm7.219.376a1 1 0 111.562 1.249L11.28 10l3.5 4.375a1 1 0 11-1.562 1.249l-4-5a1 1 0 010-1.25l4-5z" fill="${PRIMARY_COLOR}"/></svg>
`;
export const pageEndIcon = () => svg`
	<svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14 4a1 1 0 011 1v10a1 1 0 11-2 0V5a1 1 0 011-1zm-7.219.376l4 5a1 1 0 010 1.249l-4 5a1 1 0 11-1.562-1.25l3.5-4.374-3.5-4.376a1 1 0 111.562-1.25z" fill="${PRIMARY_COLOR}"/></svg>
`;
export const pagePreviousIcon = () => svg`
	<svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12 16a.997.997 0 01-.707-.293l-5-5a.999.999 0 010-1.414l5-5a.999.999 0 111.414 1.414L8.414 10l4.293 4.293A.999.999 0 0112 16z" fill="${PRIMARY_COLOR}"/></svg>
`;
export const pageNextIcon = () => svg`
	<svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 16a.999.999 0 01-.707-1.707L11.586 10 7.293 5.707a.999.999 0 111.414-1.414l5 5a.999.999 0 010 1.414l-5 5A.997.997 0 018 16z" fill="${PRIMARY_COLOR}"/></svg>
`;
export const searchIcon = () => svg`
	<svg xmlns="http://www.w3.org/2000/svg" fill="gray-800" width="16" height="16" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
`;
export const uploadCloudIcon = () => svg`
	<svg xmlns="http://www.w3.org/2000/svg" fill="gray-800" width="32" height="25.6" viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"/></svg>
`;
export const triangleExclamationIcon = (width = 22, height = 22, fillColorClass = "fill-red-500") => svg`
<svg class=${fillColorClass} xmlns="http://www.w3.org/2000/svg" width=${width} height=${height} viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>`;
export const circleCheckedIcon = (width = 22, height = 22) => svg`<svg class="fill-green-600" xmlns="http://www.w3.org/2000/svg" width=${width} height=${height} viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>`;
export const closeIcon = (customClass) => svg`
        <svg class=${classNames({
  "w-4 h-4 cursor-pointer fill-white": isEmpty(customClass),
  [customClass]: !isEmpty(customClass)
})} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/></svg>
    `;
export const uploadIcon = (width = 17, height = 17) => svg`

        <svg width=${width} height=${height} viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M98.1996 109.87V95.113C100.308 95.113 102.387 95.113 104.475 95.113C115.681 95.113 126.887 95.0629 138.092 95.123C151.417 95.2031 159.781 103.433 159.922 116.618C160.002 124.126 160.052 131.635 159.911 139.144C159.67 151.468 151.306 159.887 138.906 159.917C99.5952 160.028 60.2849 160.028 20.9744 159.917C8.55374 159.877 0.269884 151.438 0.0891462 139.103C-0.0213045 131.264 -0.0413767 123.415 0.099197 115.576C0.310058 104.334 7.66007 95.8338 18.8758 95.3432C32.873 94.7225 46.9304 95.193 61.4396 95.193V109.87C57.9152 109.87 54.3205 109.87 50.7158 109.87C41.0162 109.87 31.3166 109.85 21.607 109.87C15.7933 109.89 14.3775 111.221 14.3273 116.808C14.2671 124.146 14.3574 131.495 14.2972 138.833C14.267 143.188 16.2351 145.221 20.6832 145.221C60.1643 145.18 99.6354 145.17 139.117 145.221C143.545 145.221 145.653 143.258 145.663 138.943C145.683 131.435 145.653 123.926 145.683 116.417C145.703 111.872 143.625 109.77 138.936 109.81C125.561 109.93 112.187 109.85 98.1895 109.85L98.1996 109.87Z" fill="white"/>
            <path d="M71.4705 25.8935C63.3473 34.0129 55.2342 42.1422 47.101 50.2515C45.3237 52.0236 43.6268 53.9057 41.6387 55.4075C38.6063 57.7001 35.3129 57.67 32.5115 55.027C29.7301 52.404 29.449 49.1703 31.4973 45.9366C32.2906 44.6851 33.3951 43.6239 34.4594 42.5627C47.2215 29.7981 59.9936 17.0434 72.7858 4.31878C78.5494 -1.41781 81.3307 -1.43784 87.044 4.24869C99.9567 17.0934 112.839 29.9682 125.742 42.843C126.334 43.4337 126.947 44.0044 127.479 44.6451C130.471 48.2693 130.491 52.0636 127.539 54.977C124.758 57.7201 120.511 57.7501 117.227 54.5565C108.723 46.287 100.388 37.8373 91.9641 29.4777C90.729 28.2463 89.4137 27.0949 87.034 24.8724V32.1407C87.034 57.0093 87.034 81.8779 87.034 106.756C87.034 108.088 87.0641 109.43 87.0239 110.761C86.8532 116.498 84.1422 119.882 79.8145 119.771C75.4768 119.661 72.8461 116.297 72.836 110.491C72.7959 84.2806 72.816 58.0805 72.816 31.8704C72.816 30.0784 72.816 28.2963 72.816 26.5043C72.3641 26.304 71.9123 26.0938 71.4604 25.8935H71.4705Z" fill="white"/>
            <path d="M134.739 127.731C134.628 131.455 131.214 134.839 127.6 134.799C123.633 134.759 120.46 131.225 120.701 127.1C120.932 123.185 124.256 120.082 127.981 120.302C131.646 120.522 134.849 124.036 134.739 127.731Z" fill="white"/>
        </svg>

    `;
export const infoIcon = (width = 22, height = 22) => svg`
        <svg class="hover:fill-gray-300" fill="white" width=${width} height=${height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
    `;
export const fileIcon = (fileName) => {
  let extension = "";
  let lastIndex;
  if (!isEmpty(fileName)) {
    lastIndex = fileName.lastIndexOf(".");
    extension = fileName.substring(lastIndex + 1);
  }
  return extension.length > 3 ? svg`
            <svg class="hover:stroke-brand-tertiary" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path fill="#000" fill-rule="evenodd" d="M4.5 3A1.5 1.5 0 0 1 6 1.5h9a.5.5 0 0 1 0 1H6a.5.5 0 0 0-.5.5v9a.5.5 0 0 1-1 0V3ZM21 7.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8a.5.5 0 0 1 .5-.5Zm0 11a.5.5 0 0 1 .5.5v2a1.5 1.5 0 0 1-1.5 1.5H7a.5.5 0 0 1 0-1h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 .5-.5ZM14.809 1.538a.5.5 0 0 1 .545.108l6 6A.5.5 0 0 1 21 8.5h-5A1.5 1.5 0 0 1 14.5 7V2a.5.5 0 0 1 .309-.462zm.691 1.67V7a.5.5 0 0 0 .5.5h3.793L15.5 3.207Z"/>
                <text class="select-none" x="0" y="20" fill="#000" font-family="Open sans, sans-serif" font-size="7.5" font-weight="bold">${extension.toUpperCase()}</text>
            </svg>
        ` : svg`
            <svg class="hover:stroke-brand-tertiary" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path fill="#000" fill-rule="evenodd" d="M4.5 3A1.5 1.5 0 0 1 6 1.5h9a.5.5 0 0 1 0 1H6a.5.5 0 0 0-.5.5v9a.5.5 0 0 1-1 0V3ZM21 7.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8a.5.5 0 0 1 .5-.5Zm0 11a.5.5 0 0 1 .5.5v2a1.5 1.5 0 0 1-1.5 1.5H7a.5.5 0 0 1 0-1h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 .5-.5ZM14.809 1.538a.5.5 0 0 1 .545.108l6 6A.5.5 0 0 1 21 8.5h-5A1.5 1.5 0 0 1 14.5 7V2a.5.5 0 0 1 .309-.462zm.691 1.67V7a.5.5 0 0 0 .5.5h3.793L15.5 3.207Z"/>
                <text class="select-none" x="2" y="20" fill="#000" font-family="Open sans, sans-serif" font-size="7.5" font-weight="bold">${extension.toUpperCase()}</text>
            </svg>
        `;
};
export const folderIcon = () => svg`
       <svg class="inline mr-2" width="24" height="24" viewBox="0 0 500 600" fill="text-brand-primary-dark">
			<path d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"/>
	   </svg>
    `;
export const listIcon = () => svg`
       <svg class="inline mr-2" width="24" height="24" viewBox="0 0 500 600" fill="text-brand-primary-dark">
			<path d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z"/>
	   </svg>
    `;
