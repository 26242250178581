var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { TYPE_AND_RATE_LABELS } from "./DefaultVatRateModels";
const NOT_SET = "NotSet";
export const useDefaultVatRates = (props) => {
  const getVatRateTypeDataSource = (typeAndRate) => {
    const standardTypeAndRate = typeAndRate.find((tr) => tr.Type === "Standard");
    const ds = [
      {
        value: NOT_SET,
        label: `<${standardTypeAndRate === void 0 ? i18next.t("Default") : i18next.t("Standard") + " (" + standardTypeAndRate.Rate + ")"}>`
      }
    ];
    return ds.concat(
      typeAndRate.map((tro) => ({
        value: tro.Type.toString(),
        label: `${TYPE_AND_RATE_LABELS[tro.Type]} (${tro.Rate})`
      }))
    );
  };
  const loadDefaultVatRates = () => __async(void 0, null, function* () {
    const createDefaultVatRates = (defs) => {
      setDefaultVatRates(
        props.serviceCountries.map((sc) => {
          const def = defs.find((d) => d.CountryCode === sc.Code);
          if (def) {
            return def;
          } else {
            return { CountryCode: sc.Code, Type: void 0 };
          }
        })
      );
    };
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const response = yield props.getDefaultVatRateTypes();
        createDefaultVatRates(response);
      }),
      (error) => {
        if (error.statusCode === 404) {
          createDefaultVatRates([]);
        } else {
          notifyError(error);
        }
      }
    );
  });
  const loadVatRateTypeSettings = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const response = yield props.getVATRateTypeSettings();
        setVatRateTypeSettings(response);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getDefaultVatRate = (vr) => {
    var _a;
    const defType = (_a = defaultVatRates.find((dvr) => dvr.CountryCode === vr.CountryCode)) == null ? void 0 : _a.Type;
    return defType !== void 0 ? defType.toString() : NOT_SET;
  };
  const saveDefaultVatRateTypes = () => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.saveDefaultVatRateTypes(defaultVatRates.filter((dvr) => dvr.Type !== void 0));
        notifySuccess(i18next.t("Default vat rate types successfully saved"));
      }),
      (error) => {
        notifyError(error);
      }
    );
  };
  const [defaultVatRates, setDefaultVatRates] = useState(void 0);
  const [vatRateTypeSettings, setVatRateTypeSettings] = useState(void 0);
  useEffect(() => __async(void 0, null, function* () {
    var _a;
    if (((_a = props.serviceCountries) == null ? void 0 : _a.length) > 0) {
      yield loadDefaultVatRates();
      yield loadVatRateTypeSettings();
    }
  }), [props.serviceCountries]);
  const htmlRates = () => {
    return html`<div class="mt-4">
			<div class="flex justify-between mb-4 items-center">
				<div>
					<div>${templateTitle(i18next.t("Vat Rates"))}</div>
					<table class="mt-4">
						<thead>
							<th class="border px-4">${i18next.t("Country")}</th>
							<th class="border px-4">${i18next.t("Rate type")}</th>
						</thead>
						<tbody>
							${props.serviceCountries.map((sc) => {
      const vr = vatRateTypeSettings.find((vrts) => vrts.CountryCode === sc.Code);
      return html`<tr>
									<td class="border p-2">${sc.Name}</td>
									<td class="border p-2">
										${vr !== void 0 ? html`<dc-select
													.dataSource=${getVatRateTypeDataSource(vr.TypeRateOptions)}
													.selectedValues=${getDefaultVatRate(vr)}
													.readonly=${!props.permissionManager.hasPermission("Company_VAT_Rates_Modify")}
													@change=${(e) => {
        const newDefaultVatRate = e.detail.selectedValue === NOT_SET ? void 0 : e.detail.selectedValue;
        setDefaultVatRates(
          defaultVatRates.map((dvr) => {
            if (dvr.CountryCode === vr.CountryCode) {
              return __spreadProps(__spreadValues({}, dvr), {
                Type: newDefaultVatRate
              });
            } else {
              return dvr;
            }
          })
        );
      }}
											  ></dc-select>` : "Default VAT rate not found"}
									</td>
								</tr>`;
    })}
						</tbody>
					</table>
					<div class="mt-4 ${props.permissionManager.hasPermission("Company_VAT_Rates_Modify") ? "" : "hidden"}">
						<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
      return saveDefaultVatRateTypes();
    })}>${i18next.t("Save")}</button>
					</div>
				</div>
			</div>
		</div>`;
  };
  const mainTemplate = html`<div class="my-2 mb-16 mt-8 flex justify-between">
		<div class="grid grid-cols-1 gap-2 mb-8">${defaultVatRates && vatRateTypeSettings ? htmlRates() : `${i18next.t("Loading")}...`}</div>
	</div>`;
  return { mainTemplate };
};
