import { AdditionalInfo } from "./AdditionalInfo";
import { DutchAdditionalInfo } from "./DutchAdditionalInfo";
import { HungarianAdditionalInfo } from "./HungarianAdditionalInfo";
import { MalteseAdditionalInfo } from "./MalteseAdditionalInfo";
import { SpanishAdditionalInfo } from "./SpanishAdditionalInfo";
export class AdditionalInfoFactory {
  static create($type) {
    if ($type === "V1.Cesop.Psps.Extensions.AdditionalInfo") {
      const result = new AdditionalInfo();
      return result;
    }
    if ($type === "V1.Cesop.Psps.Extensions.DutchAdditionalInfo") {
      const result = new DutchAdditionalInfo();
      return result;
    }
    if ($type === "V1.Cesop.Psps.Extensions.HungarianAdditionalInfo") {
      const result = new HungarianAdditionalInfo();
      return result;
    }
    if ($type === "V1.Cesop.Psps.Extensions.MalteseAdditionalInfo") {
      const result = new MalteseAdditionalInfo();
      return result;
    }
    if ($type === "V1.Cesop.Psps.Extensions.SpanishAdditionalInfo") {
      const result = new SpanishAdditionalInfo();
      return result;
    }
    throw new Error($type + " not found");
  }
}
