import { DataProcessingJobCreate } from "./DataProcessingJobCreate";
import { AmazonDataProcessingJobCreate } from "./AmazonDataProcessingJobCreate";
import { AmazonSaftDataProcessingJobCreate } from "./AmazonSaftDataProcessingJobCreate";
import { CustomSaftDataProcessingJobCreate } from "./CustomSaftDataProcessingJobCreate";
import { CustomSourceDataProcessingJobCreate } from "./CustomSourceDataProcessingJobCreate";
import { IossDataProcessingJobCreate } from "./IossDataProcessingJobCreate";
import { SapExcelDataProcessingJobCreate } from "./SapExcelDataProcessingJobCreate";
import { SapTextDataProcessingJobCreate } from "./SapTextDataProcessingJobCreate";
import { VatDataProcessingJobCreate } from "./VatDataProcessingJobCreate";
export class DataProcessingJobCreateFactory {
  static create($type) {
    if ($type === "V1.DataProcessingJobs.DataProcessingJobCreate") {
      const result = new DataProcessingJobCreate();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.AmazonDataProcessingJobCreate") {
      const result = new AmazonDataProcessingJobCreate();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.AmazonSaftDataProcessingJobCreate") {
      const result = new AmazonSaftDataProcessingJobCreate();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.CustomSaftDataProcessingJobCreate") {
      const result = new CustomSaftDataProcessingJobCreate();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.CustomSourceDataProcessingJobCreate") {
      const result = new CustomSourceDataProcessingJobCreate();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.IossDataProcessingJobCreate") {
      const result = new IossDataProcessingJobCreate();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.SapExcelDataProcessingJobCreate") {
      const result = new SapExcelDataProcessingJobCreate();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.SapTextDataProcessingJobCreate") {
      const result = new SapTextDataProcessingJobCreate();
      return result;
    }
    if ($type === "V1.DataProcessingJobs.VatDataProcessingJobCreate") {
      const result = new VatDataProcessingJobCreate();
      return result;
    }
    throw new Error($type + " not found");
  }
}
