import { FileLimit } from "./FileLimit";
import { FileSizeLimit } from "./FileSizeLimit";
import { TransactionCountLimit } from "./TransactionCountLimit";
import { TransactionCountOrFileSizeLimit } from "./TransactionCountOrFileSizeLimit";
import { ZippedFileSizeLimit } from "./ZippedFileSizeLimit";
export class FileLimitFactory {
  static create($type) {
    if ($type === "V1.Cesop.Configuration.FileLimit") {
      const result = new FileLimit();
      return result;
    }
    if ($type === "V1.Cesop.Configuration.FileSizeLimit") {
      const result = new FileSizeLimit();
      return result;
    }
    if ($type === "V1.Cesop.Configuration.TransactionCountLimit") {
      const result = new TransactionCountLimit();
      return result;
    }
    if ($type === "V1.Cesop.Configuration.TransactionCountOrFileSizeLimit") {
      const result = new TransactionCountOrFileSizeLimit();
      return result;
    }
    if ($type === "V1.Cesop.Configuration.ZippedFileSizeLimit") {
      const result = new ZippedFileSizeLimit();
      return result;
    }
    throw new Error($type + " not found");
  }
}
