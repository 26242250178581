import { MessageResponse } from "./MessageResponse";
import { CesopErrorFile } from "./CesopErrorFile";
import { ErrorMessage } from "./ErrorMessage";
import { ProofFile } from "./ProofFile";
import { ValidCesopResponseFile } from "./ValidCesopResponseFile";
export class MessageResponseFactory {
  static create($type) {
    if ($type === "V1.Cesop.Periods.MessageResponse") {
      const result = new MessageResponse();
      return result;
    }
    if ($type === "V1.Cesop.Periods.CesopErrorFile") {
      const result = new CesopErrorFile();
      return result;
    }
    if ($type === "V1.Cesop.Periods.ErrorMessage") {
      const result = new ErrorMessage();
      return result;
    }
    if ($type === "V1.Cesop.Periods.ProofFile") {
      const result = new ProofFile();
      return result;
    }
    if ($type === "V1.Cesop.Periods.ValidCesopResponseFile") {
      const result = new ValidCesopResponseFile();
      return result;
    }
    throw new Error($type + " not found");
  }
}
