export function formatPrice(data) {
  data.leadingSign = data.leadingSign === void 0 ? true : data.leadingSign;
  const currenciesWithDecimals = ["usd", "eur"];
  const culturesWithDot = ["en-gb", "es-es", "de-de", "fr-fr", "it-it", "zh-cn"];
  if (data.currency) {
    const useDot = culturesWithDot.indexOf(data.culture.toLowerCase()) > -1;
    if (data.currency && currenciesWithDecimals.indexOf(data.currency.toLowerCase()) > -1) {
      data.amount = Math.round(data.amount * 100) / 100;
      let str = data.amount.toFixed(2);
      if (!useDot) {
        str = str.replace(".", ",");
      }
      str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return data.leadingSign ? `$ ${str}` : str;
    } else {
      data.amount = Math.round(data.amount);
      let str = data.amount.toString();
      str = useDot ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : str.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return data.leadingSign ? `$ ${str}` : str;
    }
  } else {
    return data.amount.toString();
  }
}
export const roundMoney = (num, digit) => {
  const multiplier = digit * 10;
  return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
};
export const toSafeNumber = (val) => {
  return val !== "" && !isNaN(Number(val)) ? Number(val) : void 0;
};
