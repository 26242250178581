var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { File } from "./File";
export class CorrectionFileReferences {
  constructor(_data) {
    /**
     */
    __publicField(this, "PayeeCorrectionFile");
    /**
     */
    __publicField(this, "TransactionCorrectionArchiveFile");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CorrectionFileReferences
     */
    __publicField(this, "$type", "V1.Cesop.Periods.CorrectionFileReferences");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["PayeeCorrectionFile"]) {
        const payeeCorrectionFile_ = new File();
        this.PayeeCorrectionFile = payeeCorrectionFile_.init(_data["PayeeCorrectionFile"]);
      }
      if (_data["TransactionCorrectionArchiveFile"]) {
        const transactionCorrectionArchiveFile_ = new File();
        this.TransactionCorrectionArchiveFile = transactionCorrectionArchiveFile_.init(_data["TransactionCorrectionArchiveFile"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["PayeeCorrectionFile"] = this.PayeeCorrectionFile ? this.PayeeCorrectionFile.toJSON() : void 0;
    _data["TransactionCorrectionArchiveFile"] = this.TransactionCorrectionArchiveFile ? this.TransactionCorrectionArchiveFile.toJSON() : void 0;
    return _data;
  }
}
