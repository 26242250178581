var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
import { Document } from "./../../CompanyInfo/Detail/IossRegistration/Document";
import { Country } from "./../../Common/MasterData/Country";
export class IossRegistration {
  constructor(_data) {
    /**
     */
    __publicField(this, "CertificatesReferenceId");
    /**
     */
    __publicField(this, "CountryOfRegistration");
    /**
     */
    __publicField(this, "DateOfDeregistration");
    /**
     */
    __publicField(this, "DeregistrationConfirmationDocumentId");
    /**
     */
    __publicField(this, "Documents");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "RegistrationDate");
    /**
     */
    __publicField(this, "RegistrationNumber");
    /**
     */
    __publicField(this, "RegistrationType");
    /**
     */
    __publicField(this, "StartDate");
    /**
     */
    __publicField(this, "Status");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof IossRegistration
     */
    __publicField(this, "$type", "V1.Companies.CompanyInfo.IossRegistration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.CertificatesReferenceId = _data["CertificatesReferenceId"];
      if (_data["CountryOfRegistration"]) {
        const countryOfRegistration_ = new Country();
        this.CountryOfRegistration = countryOfRegistration_.init(_data["CountryOfRegistration"]);
      }
      this.DateOfDeregistration = DateConverter.from(_data["DateOfDeregistration"]);
      this.DeregistrationConfirmationDocumentId = _data["DeregistrationConfirmationDocumentId"];
      if (_data["Documents"] && _data["Documents"].constructor === Array) {
        this.Documents = [];
        for (let item of _data["Documents"]) {
          const documents_ = new Document();
          this.Documents.push(documents_.init(item));
        }
      }
      this.Id = _data["Id"];
      this.RegistrationDate = DateConverter.from(_data["RegistrationDate"]);
      this.RegistrationNumber = _data["RegistrationNumber"];
      this.RegistrationType = IossRegistrationRegistrationType[_data["RegistrationType"]];
      this.StartDate = DateConverter.from(_data["StartDate"]);
      this.Status = IossRegistrationStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["CertificatesReferenceId"] = this.CertificatesReferenceId;
    _data["CountryOfRegistration"] = this.CountryOfRegistration ? this.CountryOfRegistration.toJSON() : void 0;
    _data["DateOfDeregistration"] = DateConverter.toUtcDateString(this.DateOfDeregistration);
    _data["DeregistrationConfirmationDocumentId"] = this.DeregistrationConfirmationDocumentId;
    if (this.Documents && this.Documents.constructor === Array) {
      _data["Documents"] = [];
      for (let item of this.Documents) {
        _data["Documents"].push(item.toJSON());
      }
    }
    _data["Id"] = this.Id;
    _data["RegistrationDate"] = DateConverter.toUtcDateString(this.RegistrationDate);
    _data["RegistrationNumber"] = this.RegistrationNumber;
    _data["RegistrationType"] = IossRegistrationRegistrationType[this.RegistrationType];
    _data["StartDate"] = DateConverter.toUtcDateString(this.StartDate);
    _data["Status"] = IossRegistrationStatus[this.Status];
    return _data;
  }
}
export var IossRegistrationRegistrationType = /* @__PURE__ */ ((IossRegistrationRegistrationType2) => {
  IossRegistrationRegistrationType2["NewRegistration"] = "NewRegistration";
  IossRegistrationRegistrationType2["TakeOver"] = "TakeOver";
  return IossRegistrationRegistrationType2;
})(IossRegistrationRegistrationType || {});
export var IossRegistrationStatus = /* @__PURE__ */ ((IossRegistrationStatus2) => {
  IossRegistrationStatus2["Pending"] = "Pending";
  IossRegistrationStatus2["WaitingForSignature"] = "WaitingForSignature";
  IossRegistrationStatus2["DocumentsSigned"] = "DocumentsSigned";
  IossRegistrationStatus2["SentToTA"] = "SentToTA";
  IossRegistrationStatus2["RegistrationCompleted"] = "RegistrationCompleted";
  IossRegistrationStatus2["Failed"] = "Failed";
  IossRegistrationStatus2["Declined"] = "Declined";
  IossRegistrationStatus2["Deregistered"] = "Deregistered";
  IossRegistrationStatus2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return IossRegistrationStatus2;
})(IossRegistrationStatus || {});
