var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import * as Immutable from "immutable";
import { createAction, createReducer } from "redux-act";
import { ReduxContext } from "GlobalShared/ReduxContext";
import { nameof } from "GlobalShared/common/Nameof";
const defaultState = {
  notifications: []
};
const _DcNotifierReducer = class _DcNotifierReducer {
  static getPath(key) {
    return `${_DcNotifierReducer.REDUCER_KEY}.${key}`;
  }
};
__publicField(_DcNotifierReducer, "REDUCER_KEY", "dc-notifier");
__publicField(_DcNotifierReducer, "ADD_NOTIFICATION", "DcNotifierReducer::ADD_NOTIFICATION");
__publicField(_DcNotifierReducer, "CHANGE_NOTIFICATION", "DcNotifierReducer::CHANGE_NOTIFICATION");
__publicField(_DcNotifierReducer, "REMOVE_NOTIFICATION", "DcNotifierReducer::REMOVE_NOTIFICATION");
__publicField(_DcNotifierReducer, "addNotification", createAction(_DcNotifierReducer.ADD_NOTIFICATION));
__publicField(_DcNotifierReducer, "changeNotifications", createAction(_DcNotifierReducer.CHANGE_NOTIFICATION));
__publicField(_DcNotifierReducer, "removeNotification", createAction(_DcNotifierReducer.REMOVE_NOTIFICATION));
export let DcNotifierReducer = _DcNotifierReducer;
ReduxContext.registerReducer(
  DcNotifierReducer.REDUCER_KEY,
  createReducer(
    {
      [DcNotifierReducer.addNotification.toString()]: (state, item) => {
        const newList = [...state.get(nameof("notifications")), item];
        return state.updateIn([nameof("notifications")], (_) => Immutable.fromJS(newList));
      },
      [DcNotifierReducer.changeNotifications.toString()]: (state, mapper) => {
        return state.updateIn([nameof("notifications")], (notifications) => Immutable.fromJS(notifications.toJS().map(mapper)));
      },
      [DcNotifierReducer.removeNotification.toString()]: (state, id) => {
        return state.updateIn([nameof("notifications")], (notifications) => Immutable.fromJS(notifications.toJS().filter((note) => note.id !== id)));
      }
    },
    Immutable.fromJS(defaultState)
  )
);
