var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import i18next from "i18next";
import { html } from "lit-html";
import { ONLY_XLSX_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
export const useSaftUpload = (props) => {
  const htmlSaftUploadButton = () => {
    var _a, _b;
    return html`
		<dc-fileupload2
			.label=${i18next.t("Upload")}
			.fileReference=${(_a = props.file) == null ? void 0 : _a.Reference}
			.fileName=${(_b = props.file) == null ? void 0 : _b.Name}
			.accept=${ONLY_XLSX_ACCEPT_LIST}
			.readonly=${props.hasOngoingDataProcessingJob(props.uploadEntityType)}
			.linkGenerator=${(reference) => props.linkGenerator(props.currentComplianceUpload.Id, reference)}
			.withLoader=${props.isDataProcessingJobOngoingForUploadType(props.uploadEntityType)}
			.showFileLink=${false}
			.withUploadIcon=${true}
			@upload=${(event) => props.uploadFileToBlob(event.detail.files[0], props.uploadEntityType)}
		></dc-fileupload2>
	`;
  };
  const htmlSaftStep = () => {
    var _a, _b, _c, _d;
    const currentSaftUploadJob = (_a = props.currentUploadJobs) == null ? void 0 : _a.find((job) => job.type === props.uploadEntityType);
    const isErrorFileSameAsValidFile = ((_b = props.file) == null ? void 0 : _b.Reference) === ((_c = currentSaftUploadJob == null ? void 0 : currentSaftUploadJob.latestUploadedFile) == null ? void 0 : _c.Reference);
    const isFileSuccessFullyUploaded = ((_d = props.file) == null ? void 0 : _d.Reference) && !isErrorFileSameAsValidFile;
    return html`
			<div class="p-4">
				<div>
					<div class="font-bold text-xl">${i18next.t("SAF-T Compliance Settings")}</div>
					<div class="form-label mt-2">
						${i18next.t("You are required to declare SAF-T data. You can download the template below and with adding necessary fields please upload it back.")}
					</div>
				</div>
				<div class="flex justify-between mr-4 mt-8">
					<a
						class="border-0 font-semibold text-sm bg-brand-primary text-white items-center px-6 py-2 rounded-md cursor-pointer h-fit hover:bg-brand-primary-light"
						href="javascript:void(0);"
						@click=${() => __async(void 0, null, function* () {
      try {
        props.dispatchFullScreenLoading("inc");
        yield props.generateSaftTemplate(props.currentComplianceUpload.Id);
      } finally {
        props.dispatchFullScreenLoading("dec");
      }
    })}
						>${i18next.t("Download SAF-T Template")}</a
					>
					${htmlSaftUploadButton()}
				</div>
				${props.htmlFileUploadStatus(props.uploadEntityType, isErrorFileSameAsValidFile, props.file, isFileSuccessFullyUploaded)}
				<div class="mt-16 flex space-x-4">
					${SecondaryButton({ class: "max-w-fit", text: i18next.t("Cancel"), onClick: () => props.setUploadModalMode("Closed") })}
					${!props.isFinalizeButtonEnabled() ? PrimaryButton({ class: "max-w-fit", text: i18next.t("Confirm without adding SAF-T data"), onClick: () => __async(void 0, null, function* () {
      return props.finalizeUpload(props.currentComplianceUpload, true);
    }) }) : ""}
					${PrimaryButton({
      class: "max-w-fit",
      disabled: !props.isFinalizeButtonEnabled(),
      text: i18next.t("Confirm and Generate Return"),
      onClick: () => __async(void 0, null, function* () {
        return props.finalizeUpload(props.currentComplianceUpload);
      })
    })}
				</div>
			</div>
		`;
  };
  const htmlSaftConfirmStep = () => html`
			<div class="p-4">
				<div>
					<div>
						${props.isAdmin ? unsafeHTML(i18next.t("saft-acknowledgement-admin")) : unsafeHTML(
    i18next.t("saft-acknowledgement-web", {
      link_start: `<a class=underline text-brand-primary cursor-pointer href=${props.getRouteByKey("guides-and-tutorials")}>`,
      link_end: "</a>"
    })
  )}
					</div>
				</div>
				<div>
					<div class="mt-8 flex space-x-4">
						<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
    return props.finalizeUpload(props.currentComplianceUpload);
  })}>${i18next.t("Acknowledge")}</button>
					</div>
				</div>
			</div>
		`;
  return {
    htmlSaftStep,
    htmlSaftConfirmStep
  };
};
