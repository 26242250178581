var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { NewManualBankAccount } from "./NewManualBankAccount";
export class BankAccountChangeRequest {
  constructor(_data) {
    /**
     */
    __publicField(this, "BankAccountRef");
    /**
     */
    __publicField(this, "BankAccountUrl");
    /**
     */
    __publicField(this, "ManualBankAccount");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BankAccountChangeRequest
     */
    __publicField(this, "$type", "V1.Company.VatRegistration.BankAccountChangeRequest");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.BankAccountRef = _data["BankAccountRef"];
      this.BankAccountUrl = _data["BankAccountUrl"];
      if (_data["ManualBankAccount"]) {
        const manualBankAccount_ = new NewManualBankAccount();
        this.ManualBankAccount = manualBankAccount_.init(_data["ManualBankAccount"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["BankAccountRef"] = this.BankAccountRef;
    _data["BankAccountUrl"] = this.BankAccountUrl;
    _data["ManualBankAccount"] = this.ManualBankAccount ? this.ManualBankAccount.toJSON() : void 0;
    return _data;
  }
}
