import { CountryBasedSubscriptionService, SubscriptionService, SimpleService, OssSubscriptionService } from "WebServices/APIs/self/models/V1/Orders/Create/Request";
const createCountryBasedSubscriptionService = (cs) => {
  return new CountryBasedSubscriptionService({
    Code: cs.Code,
    Country: cs.Country,
    StartDate: cs.StartDate,
    EndDate: cs.EndDate
  });
};
const createSimpleService = (cs) => {
  return new SimpleService({
    Code: cs.Code
  });
};
const createOssSubscriptionService = (cs) => {
  return new OssSubscriptionService({
    Code: cs.Code,
    StartDate: cs.StartDate,
    Country: cs.Country
  });
};
const createSubscriptionService = (cs) => {
  return new SubscriptionService({
    Code: cs.Code,
    StartDate: cs.StartDate,
    EndDate: cs.EndDate
  });
};
export const mapCreatedServices = (createdServices) => {
  return createdServices.map((cs) => {
    switch (cs.Type) {
      case "Country":
        return createCountryBasedSubscriptionService(cs);
      case "Simple":
        return createSimpleService(cs);
      case "GlobalSubscription":
        if (cs.Code === "OssCompliance" || cs.Code === "OssRegistration" || cs.Code === "OssTakeOver" || cs.Code === "OssDeregistration") {
          return createOssSubscriptionService(cs);
        }
        return createSubscriptionService(cs);
      default:
        throw new Error("Unknown created service: " + cs.Code);
    }
  });
};
