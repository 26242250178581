var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { BusinessDetailFactory } from "./../../CompanyInfo/BusinessDetailFactory";
import { VatRegistration } from "./VatRegistration";
import { IossRegistration } from "./IossRegistration";
import { OssRegistration } from "./OssRegistration";
import { DateConverter } from "./../../../../converters/DateConverter";
import { SubscriptionFactory } from "./../../CompanyInfo/Subscriptions/SubscriptionFactory";
import { EoriRegistration } from "./../../CompanyInfo/Detail/EoriRegistrations/EoriRegistration";
import { AntiMoneyLaundering } from "./AntiMoneyLaundering";
export class CompanyDetail {
  constructor(_data) {
    /**
     */
    __publicField(this, "AntiMoneyLaundering");
    /**
     */
    __publicField(this, "BusinessDetail");
    /**
     */
    __publicField(this, "BusinessType");
    /**
     */
    __publicField(this, "ClientAcceptance");
    /**
     */
    __publicField(this, "ClientAcceptanceValidTo");
    /**
     */
    __publicField(this, "ClientSource");
    /**
     */
    __publicField(this, "ContactEmail");
    /**
     */
    __publicField(this, "ContactLanguage");
    /**
     */
    __publicField(this, "ContactPhone");
    /**
     */
    __publicField(this, "EoriRegistrations");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "IossRegistrations");
    /**
     */
    __publicField(this, "IsInvoicingEnabled");
    /**
     */
    __publicField(this, "IsVatNumberValid");
    /**
     */
    __publicField(this, "Notes");
    /**
     */
    __publicField(this, "OssRegistration");
    /**
     */
    __publicField(this, "QuestionnaireStatus");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "Subscriptions");
    /**
     */
    __publicField(this, "VatRegistrations");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof CompanyDetail
     */
    __publicField(this, "$type", "V1.Company.CompanyInfo.CompanyDetail");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AntiMoneyLaundering"]) {
        const antiMoneyLaundering_ = new AntiMoneyLaundering();
        this.AntiMoneyLaundering = antiMoneyLaundering_.init(_data["AntiMoneyLaundering"]);
      }
      const $typeBusinessDetail = _data["BusinessDetail"] && _data["BusinessDetail"].$type;
      if ($typeBusinessDetail) {
        const businessDetail_ = BusinessDetailFactory.create($typeBusinessDetail);
        this.BusinessDetail = businessDetail_.init(_data["BusinessDetail"]);
      } else {
        this.BusinessDetail = _data["BusinessDetail"];
      }
      this.BusinessType = CompanyDetailBusinessType[_data["BusinessType"]];
      this.ClientAcceptance = CompanyDetailClientAcceptance[_data["ClientAcceptance"]];
      this.ClientAcceptanceValidTo = DateConverter.from(_data["ClientAcceptanceValidTo"]);
      this.ClientSource = CompanyDetailClientSource[_data["ClientSource"]];
      this.ContactEmail = _data["ContactEmail"];
      this.ContactLanguage = _data["ContactLanguage"];
      this.ContactPhone = _data["ContactPhone"];
      if (_data["EoriRegistrations"] && _data["EoriRegistrations"].constructor === Array) {
        this.EoriRegistrations = [];
        for (let item of _data["EoriRegistrations"]) {
          const eoriRegistrations_ = new EoriRegistration();
          this.EoriRegistrations.push(eoriRegistrations_.init(item));
        }
      }
      this.Id = _data["Id"];
      if (_data["IossRegistrations"] && _data["IossRegistrations"].constructor === Array) {
        this.IossRegistrations = [];
        for (let item of _data["IossRegistrations"]) {
          const iossRegistrations_ = new IossRegistration();
          this.IossRegistrations.push(iossRegistrations_.init(item));
        }
      }
      this.IsInvoicingEnabled = _data["IsInvoicingEnabled"];
      this.IsVatNumberValid = _data["IsVatNumberValid"];
      this.Notes = _data["Notes"];
      if (_data["OssRegistration"]) {
        const ossRegistration_ = new OssRegistration();
        this.OssRegistration = ossRegistration_.init(_data["OssRegistration"]);
      }
      this.QuestionnaireStatus = CompanyDetailQuestionnaireStatus[_data["QuestionnaireStatus"]];
      this.Status = CompanyDetailStatus[_data["Status"]];
      if (_data["Subscriptions"] && _data["Subscriptions"].constructor === Array) {
        this.Subscriptions = [];
        for (let item of _data["Subscriptions"]) {
          const $typeSubscriptions = item && item.$type;
          if ($typeSubscriptions) {
            const subscriptions_ = SubscriptionFactory.create($typeSubscriptions);
            this.Subscriptions.push(subscriptions_.init(item));
          }
        }
      }
      if (_data["VatRegistrations"] && _data["VatRegistrations"].constructor === Array) {
        this.VatRegistrations = [];
        for (let item of _data["VatRegistrations"]) {
          const vatRegistrations_ = new VatRegistration();
          this.VatRegistrations.push(vatRegistrations_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["AntiMoneyLaundering"] = this.AntiMoneyLaundering ? this.AntiMoneyLaundering.toJSON() : void 0;
    _data["BusinessDetail"] = this.BusinessDetail ? this.BusinessDetail.toJSON() : void 0;
    _data["BusinessType"] = CompanyDetailBusinessType[this.BusinessType];
    _data["ClientAcceptance"] = CompanyDetailClientAcceptance[this.ClientAcceptance];
    _data["ClientAcceptanceValidTo"] = DateConverter.toUtcDateString(this.ClientAcceptanceValidTo);
    _data["ClientSource"] = CompanyDetailClientSource[this.ClientSource];
    _data["ContactEmail"] = this.ContactEmail;
    _data["ContactLanguage"] = this.ContactLanguage;
    _data["ContactPhone"] = this.ContactPhone;
    if (this.EoriRegistrations && this.EoriRegistrations.constructor === Array) {
      _data["EoriRegistrations"] = [];
      for (let item of this.EoriRegistrations) {
        _data["EoriRegistrations"].push(item.toJSON());
      }
    }
    _data["Id"] = this.Id;
    if (this.IossRegistrations && this.IossRegistrations.constructor === Array) {
      _data["IossRegistrations"] = [];
      for (let item of this.IossRegistrations) {
        _data["IossRegistrations"].push(item.toJSON());
      }
    }
    _data["IsInvoicingEnabled"] = this.IsInvoicingEnabled;
    _data["IsVatNumberValid"] = this.IsVatNumberValid;
    _data["Notes"] = this.Notes;
    _data["OssRegistration"] = this.OssRegistration ? this.OssRegistration.toJSON() : void 0;
    _data["QuestionnaireStatus"] = CompanyDetailQuestionnaireStatus[this.QuestionnaireStatus];
    _data["Status"] = CompanyDetailStatus[this.Status];
    if (this.Subscriptions && this.Subscriptions.constructor === Array) {
      _data["Subscriptions"] = [];
      for (let item of this.Subscriptions) {
        _data["Subscriptions"].push(item.toJSON());
      }
    }
    if (this.VatRegistrations && this.VatRegistrations.constructor === Array) {
      _data["VatRegistrations"] = [];
      for (let item of this.VatRegistrations) {
        _data["VatRegistrations"].push(item.toJSON());
      }
    }
    return _data;
  }
}
export var CompanyDetailBusinessType = /* @__PURE__ */ ((CompanyDetailBusinessType2) => {
  CompanyDetailBusinessType2["Marketplace"] = "Marketplace";
  CompanyDetailBusinessType2["Enterprise"] = "Enterprise";
  return CompanyDetailBusinessType2;
})(CompanyDetailBusinessType || {});
export var CompanyDetailClientAcceptance = /* @__PURE__ */ ((CompanyDetailClientAcceptance2) => {
  CompanyDetailClientAcceptance2["Pending"] = "Pending";
  CompanyDetailClientAcceptance2["Failed"] = "Failed";
  CompanyDetailClientAcceptance2["LowRisk"] = "LowRisk";
  CompanyDetailClientAcceptance2["MediumRisk"] = "MediumRisk";
  CompanyDetailClientAcceptance2["HighRisk"] = "HighRisk";
  return CompanyDetailClientAcceptance2;
})(CompanyDetailClientAcceptance || {});
export var CompanyDetailClientSource = /* @__PURE__ */ ((CompanyDetailClientSource2) => {
  CompanyDetailClientSource2["TaxDesk"] = "TaxDesk";
  CompanyDetailClientSource2["Geopost"] = "Geopost";
  CompanyDetailClientSource2["Partner"] = "Partner";
  CompanyDetailClientSource2["Hellotax"] = "Hellotax";
  return CompanyDetailClientSource2;
})(CompanyDetailClientSource || {});
export var CompanyDetailQuestionnaireStatus = /* @__PURE__ */ ((CompanyDetailQuestionnaireStatus2) => {
  CompanyDetailQuestionnaireStatus2["Incomplete"] = "Incomplete";
  CompanyDetailQuestionnaireStatus2["Completed"] = "Completed";
  CompanyDetailQuestionnaireStatus2["Approved"] = "Approved";
  CompanyDetailQuestionnaireStatus2["DataVerified"] = "DataVerified";
  return CompanyDetailQuestionnaireStatus2;
})(CompanyDetailQuestionnaireStatus || {});
export var CompanyDetailStatus = /* @__PURE__ */ ((CompanyDetailStatus2) => {
  CompanyDetailStatus2["Active"] = "Active";
  CompanyDetailStatus2["Rejected"] = "Rejected";
  CompanyDetailStatus2["Inactive"] = "Inactive";
  return CompanyDetailStatus2;
})(CompanyDetailStatus || {});
