export const mapPairsToCustomSourceMapping = (pairs) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H, _I, _J, _K, _L, _M, _N, _O, _P, _Q, _R, _S, _T, _U, _V, _W, _X, _Y, _Z, __, _$, _aa, _ba, _ca, _da, _ea, _fa, _ga, _ha;
  const mapping = {
    CommodityCode: ((_a = pairs.get("CommodityCode")) == null ? void 0 : _a.dragField) ? { Source: pairs.get("CommodityCode").dragField.id, FallbackSources: pairs.get("CommodityCode").fallBackField ? [(_b = pairs.get("CommodityCode").fallBackField) == null ? void 0 : _b.id] : void 0 } : void 0,
    CountryOfOrigin: ((_c = pairs.get("CountryOfOrigin")) == null ? void 0 : _c.dragField) ? { Source: pairs.get("CountryOfOrigin").dragField.id, FallbackSources: pairs.get("CountryOfOrigin").fallBackField ? [(_d = pairs.get("CountryOfOrigin").fallBackField) == null ? void 0 : _d.id] : void 0 } : void 0,
    Currency: ((_e = pairs.get("Currency")) == null ? void 0 : _e.dragField) ? { Source: pairs.get("Currency").dragField.id, FallbackSources: pairs.get("Currency").fallBackField ? [(_f = pairs.get("Currency").fallBackField) == null ? void 0 : _f.id] : void 0 } : void 0,
    CurrencyReporting: ((_g = pairs.get("CurrencyReporting")) == null ? void 0 : _g.dragField) ? { Source: pairs.get("CurrencyReporting").dragField.id, FallbackSources: pairs.get("CurrencyReporting").fallBackField ? [(_h = pairs.get("CurrencyReporting").fallBackField) == null ? void 0 : _h.id] : void 0 } : void 0,
    CustomerCountry: ((_i = pairs.get("CustomerCountry")) == null ? void 0 : _i.dragField) ? { Source: pairs.get("CustomerCountry").dragField.id, FallbackSources: pairs.get("CustomerCountry").fallBackField ? [(_j = pairs.get("CustomerCountry").fallBackField) == null ? void 0 : _j.id] : void 0 } : void 0,
    CustomerCountryVatNumberUsed: ((_k = pairs.get("CustomerCountryVatNumberUsed")) == null ? void 0 : _k.dragField) ? {
      Source: pairs.get("CustomerCountryVatNumberUsed").dragField.id,
      FallbackSources: pairs.get("CustomerCountryVatNumberUsed").fallBackField ? [(_l = pairs.get("CustomerCountryVatNumberUsed").fallBackField) == null ? void 0 : _l.id] : void 0
    } : void 0,
    CustomerName: ((_m = pairs.get("CustomerName")) == null ? void 0 : _m.dragField) ? { Source: pairs.get("CustomerName").dragField.id, FallbackSources: pairs.get("CustomerName").fallBackField ? [(_n = pairs.get("CustomerName").fallBackField) == null ? void 0 : _n.id] : void 0 } : void 0,
    CustomerProvinceCode: ((_o = pairs.get("CustomerProvinceCode")) == null ? void 0 : _o.dragField) ? {
      Source: pairs.get("CustomerProvinceCode").dragField.id,
      FallbackSources: pairs.get("CustomerProvinceCode").fallBackField ? [(_p = pairs.get("CustomerProvinceCode").fallBackField) == null ? void 0 : _p.id] : void 0
    } : void 0,
    CustomerVatNumberUsed: ((_q = pairs.get("CustomerVatNumberUsed")) == null ? void 0 : _q.dragField) ? {
      Source: pairs.get("CustomerVatNumberUsed").dragField.id,
      FallbackSources: pairs.get("CustomerVatNumberUsed").fallBackField ? [(_r = pairs.get("CustomerVatNumberUsed").fallBackField) == null ? void 0 : _r.id] : void 0
    } : void 0,
    DeliveryCondition: ((_s = pairs.get("DeliveryCondition")) == null ? void 0 : _s.dragField) ? { Source: pairs.get("DeliveryCondition").dragField.id, FallbackSources: pairs.get("DeliveryCondition").fallBackField ? [(_t = pairs.get("DeliveryCondition").fallBackField) == null ? void 0 : _t.id] : void 0 } : void 0,
    DocumentIndicator: ((_u = pairs.get("DocumentIndicator")) == null ? void 0 : _u.dragField) ? { Source: pairs.get("DocumentIndicator").dragField.id, FallbackSources: pairs.get("DocumentIndicator").fallBackField ? [(_v = pairs.get("DocumentIndicator").fallBackField) == null ? void 0 : _v.id] : void 0 } : void 0,
    ExchangeRate: ((_w = pairs.get("ExchangeRate")) == null ? void 0 : _w.dragField) ? { Source: pairs.get("ExchangeRate").dragField.id, FallbackSources: pairs.get("ExchangeRate").fallBackField ? [(_x = pairs.get("ExchangeRate").fallBackField) == null ? void 0 : _x.id] : void 0 } : void 0,
    InvoiceDate: ((_y = pairs.get("InvoiceDate")) == null ? void 0 : _y.dragField) ? { Source: pairs.get("InvoiceDate").dragField.id, FallbackSources: pairs.get("InvoiceDate").fallBackField ? [(_z = pairs.get("InvoiceDate").fallBackField) == null ? void 0 : _z.id] : void 0 } : void 0,
    InvoiceNumber: ((_A = pairs.get("InvoiceNumber")) == null ? void 0 : _A.dragField) ? { Source: pairs.get("InvoiceNumber").dragField.id, FallbackSources: pairs.get("InvoiceNumber").fallBackField ? [(_B = pairs.get("InvoiceNumber").fallBackField) == null ? void 0 : _B.id] : void 0 } : void 0,
    ItemIdentifier: ((_C = pairs.get("ItemIdentifier")) == null ? void 0 : _C.dragField) ? { Source: pairs.get("ItemIdentifier").dragField.id, FallbackSources: pairs.get("ItemIdentifier").fallBackField ? [(_D = pairs.get("ItemIdentifier").fallBackField) == null ? void 0 : _D.id] : void 0 } : void 0,
    ModeOfTransport: ((_E = pairs.get("ModeOfTransport")) == null ? void 0 : _E.dragField) ? { Source: pairs.get("ModeOfTransport").dragField.id, FallbackSources: pairs.get("ModeOfTransport").fallBackField ? [(_F = pairs.get("ModeOfTransport").fallBackField) == null ? void 0 : _F.id] : void 0 } : void 0,
    ProductIdentifier: ((_G = pairs.get("ProductIdentifier")) == null ? void 0 : _G.dragField) ? { Source: pairs.get("ProductIdentifier").dragField.id, FallbackSources: pairs.get("ProductIdentifier").fallBackField ? [(_H = pairs.get("ProductIdentifier").fallBackField) == null ? void 0 : _H.id] : void 0 } : void 0,
    Quantity: ((_I = pairs.get("Quantity")) == null ? void 0 : _I.dragField) ? { Source: pairs.get("Quantity").dragField.id, FallbackSources: pairs.get("Quantity").fallBackField ? [(_J = pairs.get("Quantity").fallBackField) == null ? void 0 : _J.id] : void 0 } : void 0,
    ReportingCountry: ((_K = pairs.get("ReportingCountry")) == null ? void 0 : _K.dragField) ? { Source: pairs.get("ReportingCountry").dragField.id, FallbackSources: pairs.get("ReportingCountry").fallBackField ? [(_L = pairs.get("ReportingCountry").fallBackField) == null ? void 0 : _L.id] : void 0 } : void 0,
    SupplierCountry: ((_M = pairs.get("SupplierCountry")) == null ? void 0 : _M.dragField) ? { Source: pairs.get("SupplierCountry").dragField.id, FallbackSources: pairs.get("SupplierCountry").fallBackField ? [(_N = pairs.get("SupplierCountry").fallBackField) == null ? void 0 : _N.id] : void 0 } : void 0,
    SupplierCountryVatNumberUsed: ((_O = pairs.get("SupplierCountryVatNumberUsed")) == null ? void 0 : _O.dragField) ? {
      Source: pairs.get("SupplierCountryVatNumberUsed").dragField.id,
      FallbackSources: pairs.get("SupplierCountryVatNumberUsed").fallBackField ? [(_P = pairs.get("SupplierCountryVatNumberUsed").fallBackField) == null ? void 0 : _P.id] : void 0
    } : void 0,
    SupplierName: ((_Q = pairs.get("SupplierName")) == null ? void 0 : _Q.dragField) ? { Source: pairs.get("SupplierName").dragField.id, FallbackSources: pairs.get("SupplierName").fallBackField ? [(_R = pairs.get("SupplierName").fallBackField) == null ? void 0 : _R.id] : void 0 } : void 0,
    SupplierProvinceCode: ((_S = pairs.get("SupplierProvinceCode")) == null ? void 0 : _S.dragField) ? {
      Source: pairs.get("SupplierProvinceCode").dragField.id,
      FallbackSources: pairs.get("SupplierProvinceCode").fallBackField ? [(_T = pairs.get("SupplierProvinceCode").fallBackField) == null ? void 0 : _T.id] : void 0
    } : void 0,
    SupplierVatNumberUsed: ((_U = pairs.get("SupplierVatNumberUsed")) == null ? void 0 : _U.dragField) ? {
      Source: pairs.get("SupplierVatNumberUsed").dragField.id,
      FallbackSources: pairs.get("SupplierVatNumberUsed").fallBackField ? [(_V = pairs.get("SupplierVatNumberUsed").fallBackField) == null ? void 0 : _V.id] : void 0
    } : void 0,
    TaxableBasis: ((_W = pairs.get("TaxableBasis")) == null ? void 0 : _W.dragField) ? { Source: pairs.get("TaxableBasis").dragField.id, FallbackSources: pairs.get("TaxableBasis").fallBackField ? [(_X = pairs.get("TaxableBasis").fallBackField) == null ? void 0 : _X.id] : void 0 } : void 0,
    TotalValueLine: ((_Y = pairs.get("TotalValueLine")) == null ? void 0 : _Y.dragField) ? { Source: pairs.get("TotalValueLine").dragField.id, FallbackSources: pairs.get("TotalValueLine").fallBackField ? [(_Z = pairs.get("TotalValueLine").fallBackField) == null ? void 0 : _Z.id] : void 0 } : void 0,
    TransactionDate: ((__ = pairs.get("TransactionDate")) == null ? void 0 : __.dragField) ? { Source: pairs.get("TransactionDate").dragField.id, FallbackSources: pairs.get("TransactionDate").fallBackField ? [(_$ = pairs.get("TransactionDate").fallBackField) == null ? void 0 : _$.id] : void 0 } : void 0,
    TransactionType: ((_aa = pairs.get("TransactionType")) == null ? void 0 : _aa.dragField) ? { Source: pairs.get("TransactionType").dragField.id, FallbackSources: pairs.get("TransactionType").fallBackField ? [(_ba = pairs.get("TransactionType").fallBackField) == null ? void 0 : _ba.id] : void 0 } : void 0,
    ValueVat: ((_ca = pairs.get("ValueVat")) == null ? void 0 : _ca.dragField) ? { Source: pairs.get("ValueVat").dragField.id, FallbackSources: pairs.get("ValueVat").fallBackField ? [(_da = pairs.get("ValueVat").fallBackField) == null ? void 0 : _da.id] : void 0 } : void 0,
    VatCode: ((_ea = pairs.get("VatCode")) == null ? void 0 : _ea.dragField) ? { Source: pairs.get("VatCode").dragField.id, FallbackSources: pairs.get("VatCode").fallBackField ? [(_fa = pairs.get("VatCode").fallBackField) == null ? void 0 : _fa.id] : void 0 } : void 0,
    Weight: ((_ga = pairs.get("Weight")) == null ? void 0 : _ga.dragField) ? { Source: pairs.get("Weight").dragField.id, FallbackSources: pairs.get("Weight").fallBackField ? [(_ha = pairs.get("Weight").fallBackField) == null ? void 0 : _ha.id] : void 0 } : void 0
  };
  return mapping;
};
