var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { PossibleMessageStatusChange } from "./PossibleMessageStatusChange";
export class MessageActions {
  constructor(_data) {
    /**
     */
    __publicField(this, "AvailableStatusChanges");
    /**
     */
    __publicField(this, "CanResend");
    /**
     */
    __publicField(this, "CanSend");
    /**
     */
    __publicField(this, "IsEnvironmentAvailable");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MessageActions
     */
    __publicField(this, "$type", "V1.Cesop.Periods.MessageActions");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AvailableStatusChanges"] && _data["AvailableStatusChanges"].constructor === Array) {
        this.AvailableStatusChanges = [];
        for (let item of _data["AvailableStatusChanges"]) {
          const availableStatusChanges_ = new PossibleMessageStatusChange();
          this.AvailableStatusChanges.push(availableStatusChanges_.init(item));
        }
      }
      this.CanResend = _data["CanResend"];
      this.CanSend = _data["CanSend"];
      this.IsEnvironmentAvailable = _data["IsEnvironmentAvailable"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.AvailableStatusChanges && this.AvailableStatusChanges.constructor === Array) {
      _data["AvailableStatusChanges"] = [];
      for (let item of this.AvailableStatusChanges) {
        _data["AvailableStatusChanges"].push(item.toJSON());
      }
    }
    _data["CanResend"] = this.CanResend;
    _data["CanSend"] = this.CanSend;
    _data["IsEnvironmentAvailable"] = this.IsEnvironmentAvailable;
    return _data;
  }
}
