import "./dc-fileupload.scss";
import { html } from "lit-html";
import { useState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { validateExtension } from "./uiHelper";
import classNames from "classnames";
const DEFAULTS = {
  label: "",
  tooltip: "",
  accept: [],
  readonly: false,
  fileName: "",
  fileReference: "",
  multiple: false
};
export class UploadEvent extends CustomEvent {
  constructor(detail) {
    super("upload", { detail });
  }
}
export const Component = (host) => {
  const props = {
    label: host.label !== void 0 ? host.label : DEFAULTS.label,
    accept: host.accept !== void 0 ? host.accept : DEFAULTS.accept,
    validationMessage: host.validationMessage,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    fileName: host.fileName !== void 0 ? host.fileName : DEFAULTS.fileName,
    tooltip: host.tooltip !== void 0 ? host.tooltip : DEFAULTS.tooltip,
    fileReference: host.fileReference !== void 0 ? host.fileReference : DEFAULTS.fileReference,
    multiple: host.multiple !== void 0 ? host.multiple : DEFAULTS.multiple,
    clientId: host.clientId,
    linkGenerator: host.linkGenerator
  };
  const onChange = (e) => {
    e.stopPropagation();
    if (!props.readonly) {
      const files2 = e.currentTarget.files;
      let allValid = true;
      for (let i = 0; i < files2.length; i++) {
        const file = files2[i];
        if (!validateExtension(file, props.accept)) {
          setFileValidationResult(`The file you are trying to upload is not allowed. <br/> Accepted file formats: ${props.accept.join(", ")}`);
          allValid = false;
        }
      }
      if (allValid) {
        setFiles(files2);
        host.dispatchEvent(
          new UploadEvent({
            files: files2
          })
        );
      }
    }
  };
  const [files, setFiles] = useState(void 0);
  const [fileValidationResult, setFileValidationResult] = useState(void 0);
  return html`
		${props.label !== void 0 ? html`<label class="form-label">${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""}</label>` : ""}
		<div>
			${props.readonly ? "" : html`
						<input
							type="file"
							class=${classNames("rounded-md", { invalid: props.validationMessage })}
							accept=${props.accept.join(",")}
							files=${files}
							?multiple=${props.multiple}
							@change=${onChange}
							@click=${function() {
    this.value = null;
  }}
						/>
				  `}
			${props.fileName.length > 0 && files === void 0 && props.linkGenerator ? html`<div>
						<a class="link" download=${props.fileName} href=${props.linkGenerator(props.fileReference)}>${props.fileName}</a>
				  </div>` : ""}
		</div>
		${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
		${fileValidationResult ? html` <div class="validation-result">${unsafeHTML(fileValidationResult)}</div> ` : ""}
	`;
};
customElements.define(
  "dc-fileupload",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: ["label", "accept", "validationMessage", "readonly", "fileName", "fileReference"]
  })
);
