import { html } from "lit-html";
import { uploadFileToQuestion } from "GlobalShared/helpers/questionnaireHelper";
import i18next from "i18next";
import "./q11e-add-modal.scss";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { circleCheckedIcon, triangleExclamationIcon } from "GlobalShared/templates/commons";
import classNames from "classnames";
export const observedAttributes = ["q11e", "worldCountries", "euCountries", "invalidAccordions"];
export const useShadowDOM = false;
export const name = "ww-q11e-tax-details";
const DEFAULTS = {
  worldCountries: [],
  euCountries: []
};
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    worldCountries: host.worldCountries ? host.worldCountries : DEFAULTS.worldCountries,
    euCountries: host.euCountries ? host.euCountries : DEFAULTS.euCountries,
    invalidAccordions: host.invalidAccordions
  };
  const getEstablishmentVatNumber = () => {
    var _a, _b, _c;
    const detail = ((_a = props.q11e) == null ? void 0 : _a.isIndividual()) ? (_b = props.q11e) == null ? void 0 : _b.getIndividualDetail() : (_c = props.q11e) == null ? void 0 : _c.getCompanyDetail();
    if (!detail) {
      return "";
    }
    const fullVatNumber = detail.VatNumber;
    if (fullVatNumber.startsWith(detail.EstablishmentCountryCode)) {
      return fullVatNumber.substring(2);
    }
    return fullVatNumber;
  };
  const handleAccordionClick = (e, groupName) => {
    e.preventDefault();
    e.stopPropagation();
    if (openAccordionGroupNames.includes(groupName)) {
      setOpenAccordionGroupNames(openAccordionGroupNames.filter((openGroupName) => openGroupName !== groupName));
      return;
    }
    setOpenAccordionGroupNames([...openAccordionGroupNames, groupName]);
  };
  const getAccordionIcon = (groupName) => {
    var _a;
    return ((_a = props.invalidAccordions) == null ? void 0 : _a.includes(groupName)) ? triangleExclamationIcon(24, 24, "fill-orange-400") : circleCheckedIcon();
  };
  const isOSSAccordionVisible = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    return ((_a = props.q11e) == null ? void 0 : _a.hasVatService()) && !((_b = props.q11e) == null ? void 0 : _b.hasOssService()) || ((_c = props.q11e) == null ? void 0 : _c.isOssTakeOver()) || ((_d = props.q11e) == null ? void 0 : _d.isVisible("oss_has_registration")) || ((_e = props.q11e) == null ? void 0 : _e.isVisible("oss_details")) || ((_f = props.q11e) == null ? void 0 : _f.isVisible("oss_takeover_registration_number")) || ((_g = props.q11e) == null ? void 0 : _g.isVisible("oss_takeover_registration_date"));
  };
  const [openAccordionGroupNames, setOpenAccordionGroupNames] = useState([]);
  useEffect(() => {
    if (props.invalidAccordions !== void 0) {
      setOpenAccordionGroupNames([...openAccordionGroupNames, ...props.invalidAccordions]);
    }
  }, [props.invalidAccordions]);
  const vatIdentificationAccordionContentTemplate = () => {
    var _a, _b, _c, _d, _e;
    return html`
		<div class="flex flex-col gap-4 px-8 pb-4">
			<div class=${((_a = props.q11e) == null ? void 0 : _a.hasVatService()) ? "" : " hidden"}>
				<label class="form-label"
					>${i18next.t("VAT Number of country of incorporation")}
					<dc-tooltip .label=${i18next.t("Businesses outside of the EU should provide a local Tax ID in the country of establishment.")}></dc-tooltip>
				</label>

				<div class="flex">
					<dc-input class="w-16" .readonly=${true} .value=${(_b = props.q11e) == null ? void 0 : _b.getEstablishmentCountry()}></dc-input>
					<dc-input class="lg:col-span-2" .readonly=${true} .value=${getEstablishmentVatNumber()}></dc-input>
				</div>
			</div>
			<ww-answer-fileref
				class=${((_c = props.q11e) == null ? void 0 : _c.hasVatService()) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"estab_vat_cert"}
				.validation=${{ required: (_d = props.q11e) == null ? void 0 : _d.isVisible("estab_vat_cert") }}
				.onUpload=${uploadFileToQuestion}
			></ww-answer-fileref>
			<ww-answer-bool class=${((_e = props.q11e) == null ? void 0 : _e.isVisible("other_vats")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"other_vats"} .validation=${{ required: true }}></ww-answer-bool>
			<ww-answer-existing-vat-numbers .q11e=${props.q11e} .questionId=${"exs_vats"} .euCountries=${props.euCountries}></ww-answer-existing-vat-numbers>
		</div>
	`;
  };
  const taxAuthoityAccordionContentTemplate = () => {
    var _a, _b;
    return html`
		<div class="flex flex-col gap-4 px-8 pb-8">
			<ww-answer-string
				class=${((_a = props.q11e) == null ? void 0 : _a.isVisible("tax_auth_name")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"tax_auth_name"}
				.validation=${{ required: (_b = props.q11e) == null ? void 0 : _b.isVisible("tax_auth_name") }}
			></ww-answer-string>
		</div>
	`;
  };
  const stockMarketListingAccordionContentTemplate = () => {
    var _a, _b;
    return html`
		<div class="flex flex-col gap-4 px-8 pb-8">
			<ww-answer-bool .q11e=${props.q11e} .questionId=${"is_on_stock"} .validation=${{ required: true }}></ww-answer-bool>
			<ww-answer-string class=${((_a = props.q11e) == null ? void 0 : _a.isVisible("stock_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"stock_name"} .validation=${{ required: true }}></ww-answer-string>
			<ww-answer-string class=${((_b = props.q11e) == null ? void 0 : _b.isVisible("ticker_symbol")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"ticker_symbol"} .validation=${{ required: true }}></ww-answer-string>
		</div>
	`;
  };
  const onlineMarketplaceAccordionContentTemplate = () => {
    var _a, _b, _c, _d;
    return html`
		<div class="flex flex-col gap-4 px-8 pb-8">
			<ww-answer-string class=${((_a = props.q11e) == null ? void 0 : _a.isVisible("mp_store_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"mp_store_name"} .validation=${{ required: true }}></ww-answer-string>
			<ww-answer-string class=${((_b = props.q11e) == null ? void 0 : _b.isVisible("sell_id")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"sell_id"} .validation=${{ required: true }}></ww-answer-string>
			<ww-answer-multistring class=${((_c = props.q11e) == null ? void 0 : _c.isVisible("mp_name")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"mp_name"} .validation=${{ required: true }}></ww-answer-multistring>
			<ww-answer-string class=${((_d = props.q11e) == null ? void 0 : _d.isVisible("web_link")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"web_link"} .validation=${{ required: true }}></ww-answer-string>
		</div>
	`;
  };
  const iossAccordionContentTemplate = () => {
    var _a, _b, _c, _d, _e, _f;
    return html`
			<div class="flex flex-col gap-4 px-8 pb-8">
				<ww-answer-string
					class=${((_a = props.q11e) == null ? void 0 : _a.isVisible("ioss_web_link")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"ioss_web_link"}
					.validation=${{ required: (_b = props.q11e) == null ? void 0 : _b.isVisible("ioss_web_link") }}
				></ww-answer-string>
				<ww-answer-string
					class=${((_c = props.q11e) == null ? void 0 : _c.isVisible("trading_name")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"trading_name"}
					.validation=${{ required: false }}
				></ww-answer-string>
				<ww-answer-multistring
					class=${((_d = props.q11e) == null ? void 0 : _d.isVisible("ioss_exs_ioss")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"ioss_exs_ioss"}
					.validation=${{ required: (_e = props.q11e) == null ? void 0 : _e.isVisible("ioss_exs_ioss") }}
				></ww-answer-multistring>
				<ww-answer-fixed-establishments
					class=${((_f = props.q11e) == null ? void 0 : _f.isVisible("ioss_fixed_estab")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.euCountries=${props.euCountries}
					.questionId=${"ioss_fixed_estab"}
				></ww-answer-fixed-establishments>
			</div>
		`;
  };
  const ossAccordionContentTemplate = () => {
    var _a, _b, _c, _d, _e, _f;
    return html`
			<div class=${classNames("flex flex-col gap-4 px-8", { "pb-24": (_a = props.q11e) == null ? void 0 : _a.isVisible("oss_details"), "pb-8": !((_b = props.q11e) == null ? void 0 : _b.isVisible("oss_details")) })}>
				<ww-answer-bool
					class=${((_c = props.q11e) == null ? void 0 : _c.isVisible("oss_has_registration")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"oss_has_registration"}
					.validation=${{ required: true }}
				></ww-answer-bool>
				<ww-answer-oss-details
					class=${((_d = props.q11e) == null ? void 0 : _d.isVisible("oss_details")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.euCountries=${props.euCountries}
					.questionId=${"oss_details"}
				></ww-answer-oss-details>

				<ww-answer-string
					class=${((_e = props.q11e) == null ? void 0 : _e.isVisible("oss_takeover_registration_number")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"oss_takeover_registration_number"}
					.validation=${{ required: true }}
				></ww-answer-string>
				<ww-answer-date
					class=${((_f = props.q11e) == null ? void 0 : _f.isVisible("oss_takeover_registration_date")) ? "" : "hidden"}
					.q11e=${props.q11e}
					.questionId=${"oss_takeover_registration_date"}
					.validation=${{ required: true }}
				></ww-answer-date>
			</div>
		`;
  };
  const accordionHeaderTitleTemplate = (title, groupName) => html`
		<div class="flex gap-4 items-center"><span class="text-lg font-bold">${title}</span><span>${props.invalidAccordions !== void 0 ? getAccordionIcon(groupName) : ""}</span></div>
	`;
  const accordionsTemplate = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
    const accordions = [
      {
        title: accordionHeaderTitleTemplate(i18next.t("VAT Identification"), "vat_identification"),
        visible: ((_a = props.q11e) == null ? void 0 : _a.hasVatService()) || ((_b = props.q11e) == null ? void 0 : _b.isVisible("other_vats")),
        groupName: "vat_identification",
        isValid: !((_c = props.invalidAccordions) == null ? void 0 : _c.includes("vat_identification")),
        content: vatIdentificationAccordionContentTemplate
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("Tax Authority Information"), "tax_authority"),
        visible: (_d = props.q11e) == null ? void 0 : _d.isVisible("tax_auth_name"),
        groupName: "tax_authority",
        isValid: !((_e = props.invalidAccordions) == null ? void 0 : _e.includes("tax_authority")),
        content: taxAuthoityAccordionContentTemplate
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("Stock Market Listing"), "stock_market_listing"),
        visible: true,
        groupName: "stock_market_listing",
        isValid: !((_f = props.invalidAccordions) == null ? void 0 : _f.includes("stock_market_listing")),
        content: stockMarketListingAccordionContentTemplate
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("Online Marketplace Information"), "online_marketplace"),
        visible: ((_g = props.q11e) == null ? void 0 : _g.isVisible("mp_store_name")) || ((_h = props.q11e) == null ? void 0 : _h.isVisible("sell_id")) || ((_i = props.q11e) == null ? void 0 : _i.isVisible("mp_name")) || ((_j = props.q11e) == null ? void 0 : _j.isVisible("web_link")),
        groupName: "online_marketplace",
        isValid: !((_k = props.invalidAccordions) == null ? void 0 : _k.includes("online_marketplace")),
        content: onlineMarketplaceAccordionContentTemplate
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("IOSS Registration Related Questions"), "ioss"),
        visible: (_l = props.q11e) == null ? void 0 : _l.hasIossService(),
        groupName: "ioss",
        isValid: !((_m = props.invalidAccordions) == null ? void 0 : _m.includes("ioss")),
        content: iossAccordionContentTemplate
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("OSS Information"), "oss"),
        visible: isOSSAccordionVisible(),
        groupName: "oss",
        isValid: !((_n = props.invalidAccordions) == null ? void 0 : _n.includes("oss")),
        content: ossAccordionContentTemplate
      }
    ];
    return accordions.map((accordion) => {
      return accordion.visible ? html`
						<dc-accordion
							.contentTemplate=${accordion.content}
							.isOpen=${openAccordionGroupNames.includes(accordion.groupName)}
							.headerTitle=${accordion.title}
							.onClick=${(e) => handleAccordionClick(e, accordion.groupName)}
						></dc-accordion>
				  ` : "";
    });
  };
  return html` <div class="mt-8 flex flex-col gap-8">${accordionsTemplate()}</div> `;
};
