import i18next from "i18next";
import { html } from "lit-html";
import { ONLY_XLSX_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
export const useIossUpload = (props) => {
  const htmlIossFileUploadButton = (fromSnapshotAndReadOnly) => {
    var _a, _b, _c, _d;
    return html`
			<dc-fileupload2
				.label=${i18next.t("Upload")}
				.fileReference=${(_b = (_a = props.currentComplianceUpload) == null ? void 0 : _a.IossFile) == null ? void 0 : _b.Reference}
				.fileName=${(_d = (_c = props.currentComplianceUpload) == null ? void 0 : _c.IossFile) == null ? void 0 : _d.Name}
				.accept=${ONLY_XLSX_ACCEPT_LIST}
				.linkGenerator=${(reference) => props.linkGenerator(props.currentComplianceUpload.Id, reference)}
				.readonly=${props.hasOngoingDataProcessingJob("Ioss") || fromSnapshotAndReadOnly}
				.withLoader=${props.isDataProcessingJobOngoingForUploadType("Ioss")}
				.showFileLink=${false}
				.withUploadIcon=${true}
				.customClass=${"flex flex-col items-start mt-4"}
				.loaderPosition=${"right"}
				@upload=${(event) => props.uploadFileToBlob(event.detail.files[0], "Ioss")}
			></dc-fileupload2>
		`;
  };
  const htmlIossUpload = (mode) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const currentIossUploadJob = (_a = props.currentUploadJobs) == null ? void 0 : _a.find((job) => job.type === "Ioss");
    const isErrorFileSameAsValidFile = ((_c = (_b = props.currentComplianceUpload) == null ? void 0 : _b.IossFile) == null ? void 0 : _c.Reference) === ((_d = currentIossUploadJob == null ? void 0 : currentIossUploadJob.latestUploadedFile) == null ? void 0 : _d.Reference);
    const isFileSuccessFullyUploaded = ((_f = (_e = props.currentComplianceUpload) == null ? void 0 : _e.IossFile) == null ? void 0 : _f.Reference) && !isErrorFileSameAsValidFile;
    return html`
			<div class="mb-4">
				<div>
					<div class="font-bold text-xl">${i18next.t("IOSS Compliance Settings")}</div>
					<div class="form-label mt-2">${i18next.t("Upload IOSS Transactions")}</div>
				</div>
				<div class="flex flex-col">
					${htmlIossFileUploadButton(mode === "Readonly")}
					${props.htmlFileUploadStatus("Ioss", isErrorFileSameAsValidFile, (_g = props.currentComplianceUpload) == null ? void 0 : _g.IossFile, isFileSuccessFullyUploaded)}
				</div>
			</div>
		`;
  };
  return {
    htmlIossUpload
  };
};
