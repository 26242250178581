var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { File } from "./File";
export class DataProcessingJob {
  constructor(_data) {
    /**
     */
    __publicField(this, "BlobName");
    /**
     */
    __publicField(this, "ComplianceUploadId");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "LatestUploadedFile");
    /**
     */
    __publicField(this, "Status");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof DataProcessingJob
     */
    __publicField(this, "$type", "V1.DataProcessingJobs.DataProcessingJob");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.BlobName = _data["BlobName"];
      this.ComplianceUploadId = _data["ComplianceUploadId"];
      this.Id = _data["Id"];
      if (_data["LatestUploadedFile"]) {
        const latestUploadedFile_ = new File();
        this.LatestUploadedFile = latestUploadedFile_.init(_data["LatestUploadedFile"]);
      }
      this.Status = DataProcessingJobStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["$type"] = this.$type;
    _data["BlobName"] = this.BlobName;
    _data["ComplianceUploadId"] = this.ComplianceUploadId;
    _data["Id"] = this.Id;
    _data["LatestUploadedFile"] = this.LatestUploadedFile ? this.LatestUploadedFile.toJSON() : void 0;
    _data["Status"] = DataProcessingJobStatus[this.Status];
    return _data;
  }
}
export var DataProcessingJobStatus = /* @__PURE__ */ ((DataProcessingJobStatus2) => {
  DataProcessingJobStatus2["New"] = "New";
  DataProcessingJobStatus2["UploadCompleted"] = "UploadCompleted";
  DataProcessingJobStatus2["Pending"] = "Pending";
  DataProcessingJobStatus2["ValidationError"] = "ValidationError";
  DataProcessingJobStatus2["Success"] = "Success";
  DataProcessingJobStatus2["Error"] = "Error";
  return DataProcessingJobStatus2;
})(DataProcessingJobStatus || {});
