var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { Agent } from "./../models/V1/Agents/Agent";
import { SearchResult } from "./../models/V1/Agents/Search/SearchResult";
export class Agents {
  /**
   * Creates an instance of Agents.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Agents
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param AddRolesRq_ _addRolesRq
   * @returns Promise<AddRolesRq_>
   * @memberof Agents
   */
  addRoles(pAddRolesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Agents/{agentId}/roles", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "agentId", value: pAddRolesRq.agentId, required: true }]);
    client.addBody({ value: pAddRolesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteRolesRq_ _deleteRolesRq
   * @returns Promise<DeleteRolesRq_>
   * @memberof Agents
   */
  deleteRoles(pDeleteRolesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Agents/{agentId}/roles", "DELETE", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "agentId", value: pDeleteRolesRq.agentId, required: true }]);
    client.addBody({ value: pDeleteRolesRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param CreateInvitationRq_ _createInvitationRq
   * @returns Promise<CreateInvitationRq_>
   * @memberof Agents
   */
  createInvitation(pCreateInvitationRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Agents/Invitation", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pCreateInvitationRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DisableRq_ _disableRq
   * @returns Promise<DisableRq_>
   * @memberof Agents
   */
  disable(pDisableRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Agents/{agentId}/Disable", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "agentId", value: pDisableRq.agentId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param EnableRq_ _enableRq
   * @returns Promise<EnableRq_>
   * @memberof Agents
   */
  enable(pEnableRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Agents/{agentId}/Enable", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "agentId", value: pEnableRq.agentId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetRq_ _getRq
   * @returns Promise<GetRq_>
   * @memberof Agents
   */
  get(pGetRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Agents/{agentId}", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "agentId", value: pGetRq.agentId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: Agent },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ModifyRq_ _modifyRq
   * @returns Promise<ModifyRq_>
   * @memberof Agents
   */
  modify(pModifyRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Agents/{agentId}", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "agentId", value: pModifyRq.agentId, required: true }]);
    client.addBody({ value: pModifyRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof Agents
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Agents/search", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: SearchResult }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
