import { downloadPDFIcon } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
const SECTION_CONFIG = {
  BankInformationForGermanVat: [
    {
      urlSafeName: "\u5FB7\u56FD\u7A0E\u52A1\u5C40\u94F6\u884C\u5E10\u53F7",
      downloadName: "\u5FB7\u56FD\u7A0E\u52A1\u5C40\u94F6\u884C\u5E10\u53F7"
    },
    {
      urlSafeName: "German Tax Authorities' Bank Accounts",
      downloadName: "German Tax Authorities' Bank Accounts"
    }
  ],
  Ioss: [
    {
      urlSafeName: "Tax Desk\u7528\u6237\u624B\u518C - IOSS\u6570\u636E\u6A21\u677F",
      downloadName: "Tax Desk\u7528\u6237\u624B\u518C - IOSS\u6570\u636E\u6A21\u677F"
    },
    {
      urlSafeName: "Tax Desk User Guide - IOSS data template",
      downloadName: "Tax Desk User Guide - IOSS data template"
    }
  ],
  VatOss: [
    {
      urlSafeName: "Tax Desk\u7528\u6237\u624B\u518C - \u5982\u4F55\u586B\u5199\u589E\u503C\u7A0EOSS \u6A21\u677F",
      downloadName: "Tax Desk\u7528\u6237\u624B\u518C - \u5982\u4F55\u586B\u5199\u589E\u503C\u7A0EOSS \u6A21\u677F"
    },
    {
      urlSafeName: "Tax Desk User Guide - How to complete the VAT OSS template",
      downloadName: "Tax Desk User Guide - How to complete the VAT OSS template"
    },
    {
      urlSafeName: "Tax Desk Benutzerhandbuch - Wie vervollst\xE4ndigen Sie die USt. OSS-Vorlage",
      downloadName: "Tax Desk Benutzerhandbuch - Wie vervollst\xE4ndigen Sie die USt. OSS-Vorlage"
    }
  ],
  Apostille: [
    {
      urlSafeName: "Apostille_public_documents_spanish",
      downloadName: "Apostilla - C\xF3mo garantizar el reconocimiento de sus documentos p\xFAblicos en el extranjero"
    },
    {
      urlSafeName: "Apostille_public_documents_french",
      downloadName: "Apostille - Garantir la reconnaissance de vos actes publics \xE1 l'\xE8tranger"
    },
    {
      urlSafeName: "Apostille_public_documents_english",
      downloadName: "Apostille - How to ensure that your public documents will be recognised abroad"
    }
  ],
  SaftReporting: [
    {
      urlSafeName: "Saft_Reporting_categories_guide_de",
      downloadName: "[DE] SAF-T Categories Guide"
    },
    {
      urlSafeName: "Saft_Reporting_information_guide_de",
      downloadName: "[DE] SAF-T Guide"
    },
    {
      urlSafeName: "Saft_Reporting_information_guide_en",
      downloadName: "[EN] SAF-T Guide"
    },
    {
      urlSafeName: "Saft_Reporting_Item_Identifier_Guide_de",
      downloadName: "[DE] Item Identifier Guide - Special sale goods and services"
    },
    {
      urlSafeName: "Saft_Reporting_Item_Identifier_Guide_en",
      downloadName: "[EN] Item Identifier Guide - Special sale goods and services"
    }
  ],
  IntrastatReporting: [
    {
      urlSafeName: "Intrastat_Reporting_Thresholds_2020",
      downloadName: "Intrastat Reporting Thresholds - 2020"
    },
    {
      urlSafeName: "Intrastat_Reporting_Thresholds_2021",
      downloadName: "Intrastat Reporting Thresholds - 2021"
    },
    {
      urlSafeName: "Intrastat_Reporting_Thresholds_2022",
      downloadName: "Intrastat Reporting Thresholds - 2022"
    }
  ],
  IossDataTemplate: [
    {
      urlSafeName: "IOSS_data_template_en",
      downloadName: "[EN] IOSS data template"
    },
    {
      urlSafeName: "IOSS_data_template_cn",
      downloadName: "[CN] IOSS data template"
    },
    {
      urlSafeName: "IOSS_data_template_fr",
      downloadName: "[FR] IOSS data template"
    }
  ],
  TaxDeskVATComplianceSignup: [
    {
      urlSafeName: "\u589E\u503C\u7A0E\u5408\u89C4\u6CE8\u518C\u6D41\u7A0B\u7528\u6237\u624B\u518C",
      downloadName: "\u589E\u503C\u7A0E\u5408\u89C4\u6CE8\u518C\u6D41\u7A0B\u7528\u6237\u624B\u518C"
    },
    {
      urlSafeName: "Tax Desk Benutzerhandbuch - Anmeldeprozess zum Umsatzsteuer-Compliance-Service",
      downloadName: "Tax Desk Benutzerhandbuch - Anmeldeprozess zum Umsatzsteuer-Compliance-Service"
    },
    {
      urlSafeName: "Tax Desk User Guide - VAT Compliance Sign-up",
      downloadName: "Tax Desk User Guide - VAT Compliance Sign-up"
    }
  ],
  TaxDeskManagingUserAccounts: [
    {
      urlSafeName: "Tax Desk \u7528\u6237\u6307\u5357- \u7528\u6237\u8D26\u53F7\u7BA1\u7406",
      downloadName: "Tax Desk \u7528\u6237\u6307\u5357- \u7528\u6237\u8D26\u53F7\u7BA1\u7406"
    },
    {
      urlSafeName: "Tax Desk Benutzerhandbuch - Verwaltung von Benutzerkonten",
      downloadName: "Tax Desk Benutzerhandbuch - Verwaltung von Benutzerkonten"
    },
    {
      urlSafeName: "Tax Desk User Guide - Managing User Accounts",
      downloadName: "Tax Desk User Guide - Managing User Accounts"
    }
  ],
  TaxDeskServiceRenewal: [
    {
      urlSafeName: "Tax Desk \u7528\u6237\u624B\u518C - \u670D\u52A1\u7EED\u8D39 (52bd0738-e8a8-4bc0-82f5-135d534777c9)",
      downloadName: "Tax Desk \u7528\u6237\u624B\u518C - \u670D\u52A1\u7EED\u8D39"
    },
    {
      urlSafeName: "Tax Desk Benutzerhandbuch - Erneuerung von Dienstleistungen (e2b6673c-6709-4cc1-b548-2b49d5a753f5)",
      downloadName: "Tax Desk Benutzerhandbuch - Erneuerung von Dienstleistungen"
    },
    {
      urlSafeName: "Tax Desk User Guide - Service Renewal (7db4d25d-c111-4055-860a-c5ea1e4b819c)",
      downloadName: "Tax Desk User Guide - Service Renewal"
    }
  ],
  TaxDeskAdditionalServices: [
    {
      urlSafeName: "Tax Desk \u7528\u6237\u624B\u518C- \u989D\u5916\u670D\u52A1",
      downloadName: "Tax Desk \u7528\u6237\u624B\u518C- \u989D\u5916\u670D\u52A1"
    },
    {
      urlSafeName: "Tax Desk Benutzerhandbuch - Zus\xE4tzliche Dienstleistungen",
      downloadName: "Tax Desk Benutzerhandbuch - Zus\xE4tzliche Dienstleistungen"
    },
    {
      urlSafeName: "Tax Desk User Guide - Additional Services",
      downloadName: "Tax Desk User Guide - Additional Services"
    }
  ]
};
export function GuidesAndTutorials() {
  const htmlSection = (links, sectionName) => {
    return html` <div class="text-lg mt-16 font-bold border-b-2 border-gray-300">${sectionName}</div>
			${links.map((link) => {
      return html`
					<a
						class="guides-link block mt-3 hover:underline hover:text-brand-primary"
						href=${`${ClientContext.CDNUrl}/${link.urlSafeName}.pdf`}
						target="_blank"
						rel="noopener"
						download=${`${link.downloadName}.pdf`}
					>
						<div class="flex space-x-4">
							<div>${link.downloadName}</div>
							<div>${downloadPDFIcon()}</div>
						</div>
					</a>
				`;
    })}`;
  };
  return html`
		<div class="mx-auto max-w-5xl my-12">
			<h1 class="text-2xl font-semibold my-4">${i18next.t("Welcome to Guides and Tutorials")}</h1>
			<h2>${i18next.t("The materials available here cover multiple topics in a detailed way.")}</h2>
			${htmlSection(SECTION_CONFIG.BankInformationForGermanVat, i18next.t("Banking information for the VAT payment in Germany"))} ${htmlSection(SECTION_CONFIG.Ioss, i18next.t("IOSS"))}
			${htmlSection(SECTION_CONFIG.VatOss, i18next.t("VAT/OSS template"))}
			<!--
            ${htmlSection(SECTION_CONFIG.Apostille, i18next.t("Apostille"))}
			${htmlSection(SECTION_CONFIG.SaftReporting, i18next.t("SAF-T reporting"))}
			${htmlSection(SECTION_CONFIG.IntrastatReporting, i18next.t("Intrastat Reporting"))}
			${htmlSection(SECTION_CONFIG.IossDataTemplate, i18next.t("IOSS data template"))}

			<h1 class="text-2xl font-semibold mb-4 mt-16">${i18next.t("Tax Desk User Guides")}</h1>
			${htmlSection(SECTION_CONFIG.TaxDeskVATComplianceSignup, i18next.t("VAT Compliance Sign-up"))}
			${htmlSection(SECTION_CONFIG.TaxDeskManagingUserAccounts, i18next.t("Managing User Accounts"))}
			${htmlSection(SECTION_CONFIG.TaxDeskServiceRenewal, i18next.t("Service Renewal"))}
			${htmlSection(SECTION_CONFIG.TaxDeskAdditionalServices, i18next.t("Additional Services"))}
            -->
			<div class="py-12">
				<p class="leading-relaxed text-xs">${i18next.t("fx-legal")}</p>
			</div>
		</div>
	`;
}
