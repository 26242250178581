export const SERVICE_COUNTRIES = {
  UnitedKingdom: "GB",
  Germany: "DE",
  Poland: "PL",
  CzechRepublic: "CZ",
  Italy: "IT",
  Spain: "ES",
  France: "FR",
  Hungary: "HU",
  Austria: "AT",
  Belgium: "BE",
  Ireland: "IE",
  Netherlands: "NL",
  Denmark: "DK",
  Romania: "RO",
  Sweden: "SE",
  Portugal: "PT",
  Slovakia: "SK",
  Finland: "FI",
  Greece: "EL",
  Luxemburg: "LU",
  Bulgaria: "BG",
  Croatia: "HR",
  Cyprus: "CY",
  Estonia: "EE",
  Lithuania: "LT",
  Latvia: "LV",
  Malta: "MT",
  Slovenia: "SI",
  Norway: "NO",
  Switzerland: "CH"
};
export const isEuCountry = (euCountries, countryCode, treatGbAsEu = false) => {
  if (treatGbAsEu) {
    return countryCode === "GB" || isEuCountryInternal(euCountries, countryCode);
  }
  return isEuCountryInternal(euCountries, countryCode);
};
const isEuCountryInternal = (euCountries, countryCode) => {
  return euCountries && euCountries.some((eu) => eu.Code === countryCode);
};
export const EU_CURRENCIES = ["BGN", "CHF", "CZK", "DKK", "EUR", "GBP", "HRK", "HUF", "NOK", "PLN", "RON", "SEK", "TRY"];
