var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../../converters/DateConverter";
export class VatRegFilter {
  constructor(_data) {
    /**
     */
    __publicField(this, "AccountManager");
    /**
     */
    __publicField(this, "ClientSource");
    /**
     */
    __publicField(this, "CompanyId");
    /**
     */
    __publicField(this, "CompanyName");
    /**
     */
    __publicField(this, "CompanyStatus");
    /**
     */
    __publicField(this, "Country");
    /**
     */
    __publicField(this, "IsAssignedToMe");
    /**
     */
    __publicField(this, "LastStatusChangeFrom");
    /**
     */
    __publicField(this, "LastStatusChangeTo");
    /**
     */
    __publicField(this, "QuestionnaireStatuses");
    /**
     */
    __publicField(this, "RegType");
    /**
     */
    __publicField(this, "ResponsibleEmailPart");
    /**
     */
    __publicField(this, "SignupDateFrom");
    /**
     */
    __publicField(this, "SignupDateTo");
    /**
     */
    __publicField(this, "SubscriptionStatus");
    /**
     */
    __publicField(this, "SubscriptionValidToFrom");
    /**
     */
    __publicField(this, "SubscriptionValidToTo");
    /**
     */
    __publicField(this, "VatRegistrationStatuses");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegFilter
     */
    __publicField(this, "$type", "V1.Company.VatRegistration.Search.VatRegFilter");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.AccountManager = _data["AccountManager"];
      this.ClientSource = VatRegFilterClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.CompanyName = _data["CompanyName"];
      this.CompanyStatus = VatRegFilterCompanyStatus[_data["CompanyStatus"]];
      this.Country = _data["Country"];
      this.IsAssignedToMe = _data["IsAssignedToMe"];
      this.LastStatusChangeFrom = DateConverter.from(_data["LastStatusChangeFrom"]);
      this.LastStatusChangeTo = DateConverter.from(_data["LastStatusChangeTo"]);
      if (_data["QuestionnaireStatuses"] && _data["QuestionnaireStatuses"].constructor === Array) {
        this.QuestionnaireStatuses = [];
        for (let item of _data["QuestionnaireStatuses"]) {
          this.QuestionnaireStatuses.push(VatRegFilterQuestionnaireStatuses[item]);
        }
      }
      this.RegType = VatRegFilterRegType[_data["RegType"]];
      this.ResponsibleEmailPart = _data["ResponsibleEmailPart"];
      this.SignupDateFrom = DateConverter.from(_data["SignupDateFrom"]);
      this.SignupDateTo = DateConverter.from(_data["SignupDateTo"]);
      this.SubscriptionStatus = VatRegFilterSubscriptionStatus[_data["SubscriptionStatus"]];
      this.SubscriptionValidToFrom = DateConverter.from(_data["SubscriptionValidToFrom"]);
      this.SubscriptionValidToTo = DateConverter.from(_data["SubscriptionValidToTo"]);
      if (_data["VatRegistrationStatuses"] && _data["VatRegistrationStatuses"].constructor === Array) {
        this.VatRegistrationStatuses = [];
        for (let item of _data["VatRegistrationStatuses"]) {
          this.VatRegistrationStatuses.push(VatRegFilterVatRegistrationStatuses[item]);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["AccountManager"] = this.AccountManager;
    _data["ClientSource"] = VatRegFilterClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["CompanyName"] = this.CompanyName;
    _data["CompanyStatus"] = VatRegFilterCompanyStatus[this.CompanyStatus];
    _data["Country"] = this.Country;
    _data["IsAssignedToMe"] = this.IsAssignedToMe;
    _data["LastStatusChangeFrom"] = DateConverter.toIsoUtcString(this.LastStatusChangeFrom);
    _data["LastStatusChangeTo"] = DateConverter.toIsoUtcString(this.LastStatusChangeTo);
    if (this.QuestionnaireStatuses && this.QuestionnaireStatuses.constructor === Array) {
      _data["QuestionnaireStatuses"] = [];
      for (let item of this.QuestionnaireStatuses) {
        _data["QuestionnaireStatuses"].push(VatRegFilterQuestionnaireStatuses[item]);
      }
    }
    _data["RegType"] = VatRegFilterRegType[this.RegType];
    _data["ResponsibleEmailPart"] = this.ResponsibleEmailPart;
    _data["SignupDateFrom"] = DateConverter.toUtcDateString(this.SignupDateFrom);
    _data["SignupDateTo"] = DateConverter.toUtcDateString(this.SignupDateTo);
    _data["SubscriptionStatus"] = VatRegFilterSubscriptionStatus[this.SubscriptionStatus];
    _data["SubscriptionValidToFrom"] = DateConverter.toUtcDateString(this.SubscriptionValidToFrom);
    _data["SubscriptionValidToTo"] = DateConverter.toUtcDateString(this.SubscriptionValidToTo);
    if (this.VatRegistrationStatuses && this.VatRegistrationStatuses.constructor === Array) {
      _data["VatRegistrationStatuses"] = [];
      for (let item of this.VatRegistrationStatuses) {
        _data["VatRegistrationStatuses"].push(VatRegFilterVatRegistrationStatuses[item]);
      }
    }
    return _data;
  }
}
export var VatRegFilterClientSource = /* @__PURE__ */ ((VatRegFilterClientSource2) => {
  VatRegFilterClientSource2["TaxDesk"] = "TaxDesk";
  VatRegFilterClientSource2["Geopost"] = "Geopost";
  VatRegFilterClientSource2["Partner"] = "Partner";
  VatRegFilterClientSource2["Hellotax"] = "Hellotax";
  return VatRegFilterClientSource2;
})(VatRegFilterClientSource || {});
export var VatRegFilterCompanyStatus = /* @__PURE__ */ ((VatRegFilterCompanyStatus2) => {
  VatRegFilterCompanyStatus2["Active"] = "Active";
  VatRegFilterCompanyStatus2["Rejected"] = "Rejected";
  VatRegFilterCompanyStatus2["Inactive"] = "Inactive";
  return VatRegFilterCompanyStatus2;
})(VatRegFilterCompanyStatus || {});
export var VatRegFilterQuestionnaireStatuses = /* @__PURE__ */ ((VatRegFilterQuestionnaireStatuses2) => {
  VatRegFilterQuestionnaireStatuses2["Incomplete"] = "Incomplete";
  VatRegFilterQuestionnaireStatuses2["Completed"] = "Completed";
  VatRegFilterQuestionnaireStatuses2["Approved"] = "Approved";
  VatRegFilterQuestionnaireStatuses2["DataVerified"] = "DataVerified";
  return VatRegFilterQuestionnaireStatuses2;
})(VatRegFilterQuestionnaireStatuses || {});
export var VatRegFilterRegType = /* @__PURE__ */ ((VatRegFilterRegType2) => {
  VatRegFilterRegType2["NewReg"] = "NewReg";
  VatRegFilterRegType2["TakOver"] = "TakOver";
  return VatRegFilterRegType2;
})(VatRegFilterRegType || {});
export var VatRegFilterSubscriptionStatus = /* @__PURE__ */ ((VatRegFilterSubscriptionStatus2) => {
  VatRegFilterSubscriptionStatus2["Active"] = "Active";
  VatRegFilterSubscriptionStatus2["Cancelled"] = "Cancelled";
  VatRegFilterSubscriptionStatus2["Expired"] = "Expired";
  VatRegFilterSubscriptionStatus2["Pending"] = "Pending";
  return VatRegFilterSubscriptionStatus2;
})(VatRegFilterSubscriptionStatus || {});
export var VatRegFilterVatRegistrationStatuses = /* @__PURE__ */ ((VatRegFilterVatRegistrationStatuses2) => {
  VatRegFilterVatRegistrationStatuses2["Pending"] = "Pending";
  VatRegFilterVatRegistrationStatuses2["WaitingForSignature"] = "WaitingForSignature";
  VatRegFilterVatRegistrationStatuses2["DocumentsSigned"] = "DocumentsSigned";
  VatRegFilterVatRegistrationStatuses2["TranslationInProgress"] = "TranslationInProgress";
  VatRegFilterVatRegistrationStatuses2["SentToTA"] = "SentToTA";
  VatRegFilterVatRegistrationStatuses2["LocalTaxReceived"] = "LocalTaxReceived";
  VatRegFilterVatRegistrationStatuses2["RegistrationCompleted"] = "RegistrationCompleted";
  VatRegFilterVatRegistrationStatuses2["Deregistered"] = "Deregistered";
  VatRegFilterVatRegistrationStatuses2["DeregistrationInProgress"] = "DeregistrationInProgress";
  VatRegFilterVatRegistrationStatuses2["PoARevoked"] = "PoARevoked";
  VatRegFilterVatRegistrationStatuses2["RevokingPoA"] = "RevokingPoA";
  return VatRegFilterVatRegistrationStatuses2;
})(VatRegFilterVatRegistrationStatuses || {});
