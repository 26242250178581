var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import i18next from "i18next";
import { html } from "lit-html";
import { notifyError } from "GlobalShared/helpers/errorHelper";
import { ONLY_XLSX_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
export const useVatOssUpload = (props) => {
  const handleMpTemplateToggleChange = (e) => __async(void 0, null, function* () {
    var _a, _b;
    e.preventDefault();
    const useMpTemplate = e.detail.state === "Yes";
    if (!props.isToggleStateChanged((_a = props.defaultCompanyConfiguration) == null ? void 0 : _a.useMpTemplate, useMpTemplate)) {
      return;
    }
    if (useMpTemplate) {
      props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
        useMpTemplate: true
      }));
      const complianceUpload = yield props.getCompliance((_b = props.currentComplianceUpload) == null ? void 0 : _b.Id);
      props.setCurrentComplianceUpload(complianceUpload);
      return;
    }
    if (!props.isAdmin && props.uploadModalState === "Closed") {
      props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
        useMpTemplate
      }));
      return;
    }
    const isConfirmed = yield props.confirmModal.confirm(i18next.t("Please note, all uploaded files will be removed if you proceed. Do you wish to continue?"));
    if (isConfirmed) {
      try {
        props.dispatchFullScreenLoading("inc");
        yield props.removeUploadedVatTransactions(props.currentComplianceUpload.Id);
        yield props.loadComplianceUploads();
        props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
          useMpTemplate: false
        }));
        yield props.deleteJobs("Vat");
      } catch (error) {
        notifyError(error);
      } finally {
        props.dispatchFullScreenLoading("dec");
      }
    }
  });
  const htmlVatFileUploadButton = () => {
    var _a, _b, _c, _d;
    return html`
			<dc-fileupload2
				.label=${i18next.t("Upload")}
				.fileReference=${(_b = (_a = props.currentComplianceUpload) == null ? void 0 : _a.MpTemplateFile) == null ? void 0 : _b.Reference}
				.fileName=${(_d = (_c = props.currentComplianceUpload) == null ? void 0 : _c.MpTemplateFile) == null ? void 0 : _d.Name}
				.accept=${ONLY_XLSX_ACCEPT_LIST}
				.linkGenerator=${(reference) => props.linkGenerator(props.currentComplianceUpload.Id, reference)}
				.readonly=${props.hasOngoingDataProcessingJob("Vat")}
				.withLoader=${props.isDataProcessingJobOngoingForUploadType("Vat")}
				.showFileLink=${false}
				.withUploadIcon=${true}
				@upload=${(event) => props.uploadFileToBlob(event.detail.files[0], "Vat")}
			></dc-fileupload2>
		`;
  };
  const htmlVatOssUpload = (mode, companyConfiguration) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const currentVatUploadJob = (_a = props.currentUploadJobs) == null ? void 0 : _a.find((job) => job.type === "Vat");
    const isErrorFileSameAsValidFile = ((_c = (_b = props.currentComplianceUpload) == null ? void 0 : _b.MpTemplateFile) == null ? void 0 : _c.Reference) === ((_d = currentVatUploadJob == null ? void 0 : currentVatUploadJob.latestUploadedFile) == null ? void 0 : _d.Reference);
    const isFileSuccessFullyUploaded = ((_f = (_e = props.currentComplianceUpload) == null ? void 0 : _e.MpTemplateFile) == null ? void 0 : _f.Reference) && ((_g = props.defaultCompanyConfiguration) == null ? void 0 : _g.useMpTemplate) && !isErrorFileSameAsValidFile;
    return html`
			<div class="form-label mt-2">${i18next.t("Use VAT/OSS template to upload data")}</div>
			<div class="flex flex-wrap gap-4 justify-between items-end">
				<dc-toggle
					.state=${(companyConfiguration == null ? void 0 : companyConfiguration.useMpTemplate) ? "Yes" : "No"}
					.readonly=${props.hasOngoingDataProcessingJob("Vat") || mode === "Readonly"}
					.customClass=${"mt-4"}
					@change=${handleMpTemplateToggleChange}
				></dc-toggle>
				${mode === "Default" && companyConfiguration.useMpTemplate ? html` <div class="flex flex-col items-end">${htmlVatFileUploadButton()}</div> ` : ""}
			</div>
			${props.htmlFileUploadStatus("Vat", isErrorFileSameAsValidFile, (_h = props.currentComplianceUpload) == null ? void 0 : _h.MpTemplateFile, isFileSuccessFullyUploaded)}
		`;
  };
  return { htmlVatOssUpload };
};
