import { AbstractValidator } from "fluent-ts-validator";
import { DCValidationResult } from "GlobalShared/haunted/HooksHelpers";
import { canHavePaymentReference, getFrequencyDataSource } from "../services/service-helpers";
export const getLocalVatChangeFields = () => {
  return [
    "LocalVATCertificateReferenceId",
    "LocalVatNumber",
    "TaxIdentifier",
    "LocalVatRegistrationDate",
    "PaymentReference",
    "PrimaryVatFrequency",
    "SecondaryVatFrequency",
    "VlsFrequency",
    "EslFrequency",
    "LocalListingFrequency",
    "IntrastatFrequency",
    "PrimaryVatEFiling",
    "SecondaryVatEFiling",
    "PrimaryVatFilingExtension",
    "SecondaryVatFilingExtension",
    "VlsEFiling",
    "VlsFilingExtension",
    "EslEFiling",
    "EslFilingExtension",
    "LocalListingEFiling",
    "LocalListingFilingExtension",
    "IntrastatEFiling",
    "IntrastatFilingExtension",
    "TaxOfficeId"
  ];
};
export class LocalVatChangeValidator extends AbstractValidator {
  constructor(submissionConfig, serviceCountryCode, hasIntrastatSubscription) {
    super();
    this.validateIfString((s) => s.LocalVATCertificateReferenceId).isNotEmpty().withPropertyName("LocalVATCertificateReferenceId").withFailureMessage("Required");
    this.validateIfString((s) => s.LocalVatNumber).isNotEmpty().withPropertyName("LocalVatNumber").withFailureMessage("Required");
    this.validateIfString((s) => s.TaxIdentifier).isNotEmpty().withPropertyName("TaxIdentifier").withFailureMessage("Required").when((_) => serviceCountryCode === "IT");
    this.validateIfString((s) => s.TaxIdentifier).hasMaxLength(50).withPropertyName("TaxIdentifier").withFailureMessage("Maximum length is 50 characters").when((_) => serviceCountryCode === "IT");
    this.validateIfDate((s) => s.LocalVatRegistrationDate).isNotEmpty().withPropertyName("LocalVatRegistrationDate").withFailureMessage("Required");
    this.validateIfString((s) => s.PaymentReference).isNotEmpty().withPropertyName("PaymentReference").withFailureMessage("Required").when((_) => canHavePaymentReference(serviceCountryCode));
    this.validateIfString((s) => s.PrimaryVATFrequencyType).isNotEmpty().withPropertyName("PrimaryVatFrequency").withFailureMessage("Required").when((_) => getFrequencyDataSource(submissionConfig, serviceCountryCode, "PrimaryVAT").length > 0);
    this.validateIfString((s) => s.SecondaryVATFrequencyType).isNotEmpty().withPropertyName("SecondaryVatFrequency").withFailureMessage("Required").when((_) => getFrequencyDataSource(submissionConfig, serviceCountryCode, "SecondaryVAT").length > 0);
    this.validateIfString((s) => s.VLSFrequencyType).isNotEmpty().withPropertyName("VlsFrequency").withFailureMessage("Required").when((_) => getFrequencyDataSource(submissionConfig, serviceCountryCode, "VLS").length > 0);
    this.validateIfString((s) => s.ESLFrequencyType).isNotEmpty().withPropertyName("EslFrequency").withFailureMessage("Required").when((_) => getFrequencyDataSource(submissionConfig, serviceCountryCode, "ESL").length > 0);
    this.validateIfString((s) => s.LocalListingFrequencyType).isNotEmpty().withPropertyName("LocalListingFrequency").withFailureMessage("Required").when((_) => getFrequencyDataSource(submissionConfig, serviceCountryCode, "LocalListing").length > 0);
    this.validateIfString((s) => s.IntrastatFrequencyType).isNotEmpty().withPropertyName("IntrastatFrequency").withFailureMessage("Required").when((_) => hasIntrastatSubscription && getFrequencyDataSource(submissionConfig, serviceCountryCode, "Intrastat").length > 0);
    this.validateIf((s) => s.PrimaryVATEFiling).isNotEmpty().withPropertyName("PrimaryVatEFiling").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.primaryVatEFilingOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.PrimaryVATFilingExtension).isNotEmpty().withPropertyName("PrimaryVatFilingExtension").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.primaryVatFilingExtensionOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.SecondaryVATFilingExtension).isNotEmpty().withPropertyName("SecondaryVatFilingExtension").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.secondaryVatFilingExtensionOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.SecondaryVATEFiling).isNotEmpty().withPropertyName("SecondaryVatEFiling").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.secondaryVatEFilingOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.VLSEFiling).isNotEmpty().withPropertyName("VlsEFiling").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.vlsEFilingOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.VLSFilingExtension).isNotEmpty().withPropertyName("VlsFilingExtension").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.vlsFilingExtensionOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.ESLEFiling).isNotEmpty().withPropertyName("ESLEFiling").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.eslEFilingOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.ESLFilingExtension).isNotEmpty().withPropertyName("ESLFilingExtension").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.eslFilingExtensionOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.IntrastatEFiling).isNotEmpty().withPropertyName("IntrastatEFiling").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.intrastatEFilingOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIf((s) => s.IntrastatFilingExtension).isNotEmpty().withPropertyName("IntrastatFilingExtension").withFailureMessage("Required").when((s) => {
      var _a;
      return ((_a = s.intrastatFilingExtensionOptions) == null ? void 0 : _a.length) > 0;
    });
    this.validateIfString((s) => s.TaxOfficeId).isNotEmpty().withPropertyName("TaxOfficeId").withFailureMessage("Required").when((_) => serviceCountryCode === "DE");
    this.validateIfString((s) => s.TaxOfficeId).hasMinLength(4).hasMaxLength(4).isNumericString().withPropertyName("TaxOfficeId").withFailureMessage("Must be 4 digits").when((_) => serviceCountryCode === "DE");
  }
  validateAllFields(item) {
    return new DCValidationResult(super.validate(item));
  }
}
