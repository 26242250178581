var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { BlockBlobClient, AnonymousCredential, BaseRequestPolicy, newPipeline } from "@azure/storage-blob";
class SasStore {
  constructor(acquireSasCallback) {
    __publicField(this, "sasCache", {});
    __publicField(this, "acquireSasCallback");
    this.sasCache = {};
    this.acquireSasCallback = acquireSasCallback;
  }
  // Get a valid SAS for blob
  getValidSASForBlob(blobURL) {
    return __async(this, null, function* () {
      if (this.sasCache[blobURL] && this.isSasStillValidInNext2Mins(this.sasCache[blobURL])) {
        return this.sasCache[blobURL];
      } else {
        return this.sasCache[blobURL] = yield this.getNewSasForBlob(blobURL);
      }
    });
  }
  // Return true if "se" section in SAS is still valid in next 2 mins
  isSasStillValidInNext2Mins(sas) {
    const expiryStringInSas = new URL(`http://hostname${sas}`).searchParams.get("se");
    return new Date(expiryStringInSas).getTime() - (/* @__PURE__ */ new Date()).getTime() >= 2 * 60 * 1e3;
  }
  // Get a new SAS for blob, we assume a SAS starts with a "?"
  getNewSasForBlob(blobURL) {
    return __async(this, null, function* () {
      return this.acquireSasCallback(blobURL);
    });
  }
}
class SasUpdatePolicyFactory {
  constructor(sasStore) {
    __publicField(this, "sasStore");
    this.sasStore = sasStore;
  }
  create(nextPolicy, options) {
    return new SasUpdatePolicy(nextPolicy, options, this.sasStore);
  }
}
class SasUpdatePolicy extends BaseRequestPolicy {
  constructor(nextPolicy, options, sasStore) {
    super(nextPolicy, options);
    __publicField(this, "sasStore");
    this.sasStore = sasStore;
  }
  sendRequest(request) {
    return __async(this, null, function* () {
      const urlObj = new URL(request.url);
      const sas = yield this.sasStore.getValidSASForBlob(`${urlObj.origin}${urlObj.pathname}`);
      new URL(`http://hostname${sas}`).searchParams.forEach((value, key) => {
        urlObj.searchParams.set(key, value);
      });
      request.url = urlObj.toString();
      return this._nextPolicy.sendRequest(request);
    });
  }
}
export function uploadFileToAzureStorage(file, blobUrl, acquireSasCallback) {
  return __async(this, null, function* () {
    const sasStore = new SasStore(acquireSasCallback);
    const pipeline = newPipeline(new AnonymousCredential());
    pipeline.factories.unshift(new SasUpdatePolicyFactory(sasStore));
    const blockBlobClient = new BlockBlobClient(
      `${blobUrl}${yield sasStore.getValidSASForBlob(blobUrl)}`,
      // A SAS should start with "?"
      pipeline
    );
    const resp = yield blockBlobClient.uploadData(file, {
      maxSingleShotSize: 50 * 1024 * 1024,
      // 50 MB
      blobHTTPHeaders: { blobContentType: file.type }
      // set mimetype
    });
    return resp._response.status;
  });
}
