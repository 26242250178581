var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./dc-multiselect.scss";
import { html } from "lit-html";
import { component, useEffect } from "haunted";
import { useMemo, useRef, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { isEmpty, newUniqueId } from "GlobalShared/helpers/stringHelper";
const DEFAULTS = {
  selectedItems: void 0,
  dataSource: [],
  filterProps: { type: "startsWith", ignoreCase: true },
  canAddNew: false,
  readonly: false
};
const NEW_LABEL_ID = "79e63307-4ef6-4589-ad67-b7b32457efa9";
export class MultiSelectChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail });
  }
}
export class MultiSelectNewEvent extends CustomEvent {
  constructor(detail) {
    super("new", { detail });
  }
}
export const Component = (host) => {
  const props = {
    selectedItems: host.selectedItems !== void 0 ? host.selectedItems : DEFAULTS.selectedItems,
    dataSource: host.dataSource !== void 0 ? host.dataSource : DEFAULTS.dataSource,
    filterProps: host.filterProps !== void 0 ? {
      type: host.filterProps.type !== void 0 ? host.filterProps.type : DEFAULTS.filterProps.type,
      ignoreCase: host.filterProps.ignoreCase !== void 0 ? host.filterProps.ignoreCase : DEFAULTS.filterProps.ignoreCase
    } : DEFAULTS.filterProps,
    label: host.label,
    tooltip: host.tooltip,
    validationMessage: host.validationMessage,
    canAddNew: host.canAddNew,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly
  };
  const convertToMultiSelect = (inputItems) => {
    if (inputItems === void 0) {
      return void 0;
    }
    let tempSelectedItems;
    if (typeof inputItems === "string") {
      tempSelectedItems = [{ label: inputItems, value: inputItems }];
    } else if (typeof inputItems === "number") {
      tempSelectedItems = [{ label: inputItems.toString(), value: inputItems.toString() }];
    } else {
      tempSelectedItems = inputItems.map(
        (selVal) => typeof selVal === "number" ? {
          label: selVal.toString(),
          value: selVal.toString()
        } : typeof selVal === "string" ? { label: selVal, value: selVal } : selVal
      );
    }
    return tempSelectedItems;
  };
  const refreshDataSourceResult = (dataSource) => __async(void 0, null, function* () {
    const now = Date.now();
    lastCall.current.ts = now;
    let currentResult;
    if (typeof dataSource === "function") {
      const tempResult = dataSource(editedText);
      if (tempResult instanceof Promise) {
        const loadingTimer = window.setTimeout(() => {
          if (lastCall.current.ts === now) {
            setSearchResultMessage(`${i18next.t("Loading")}...`);
          }
        }, 500);
        currentResult = convertToMultiSelect(yield tempResult);
        window.clearTimeout(loadingTimer);
      } else {
        currentResult = convertToMultiSelect(tempResult);
      }
    } else {
      currentResult = convertToMultiSelect(dataSource);
    }
    if (lastCall.current.ts === now) {
      setSelectedIndex(0);
      let newResult = [];
      if (typeof currentResult === "string") {
        setSearchResultMessage(currentResult);
      } else {
        newResult = currentResult.map((item) => {
          const newItem = __spreadValues({}, item);
          let enabled = true;
          if (typeof dataSource !== "function" && editedText !== void 0) {
            enabled = props.filterProps.ignoreCase ? props.filterProps.type === "includes" ? newItem.label.toLowerCase().includes(editedText.toLowerCase()) : newItem.label.toLowerCase().startsWith(editedText.toLowerCase()) : props.filterProps.type === "includes" ? newItem.label.includes(editedText) : newItem.label.startsWith(editedText);
          }
          return __spreadProps(__spreadValues({}, newItem), { enabled, keyboardSelected: false });
        });
        setSelectedIndex(0);
        setDataSourceResult(newResult.filter((item) => selectedItems.filter((selItem) => selItem.value === item.value).length === 0));
      }
    }
  });
  const addToSelectedItems = (item) => {
    if (item.value === NEW_LABEL_ID) {
      host.dispatchEvent(
        new MultiSelectNewEvent({
          newLabel: editedText
        })
      );
    } else {
      const newSelectedItems = selectedItems.concat([item]);
      host.dispatchEvent(
        new MultiSelectChangeEvent({
          values: newSelectedItems.map((item2) => item2.value),
          items: newSelectedItems
        })
      );
    }
  };
  const removeFromSelectedItems = (item) => {
    const newSelectedItems = selectedItems.filter((selItem) => selItem.value !== item.value);
    host.dispatchEvent(
      new MultiSelectChangeEvent({
        values: newSelectedItems.map((item2) => item2.value),
        items: newSelectedItems
      })
    );
  };
  const changeEditedText = (text) => {
    setEditedText(text);
    const div = getEditableDivElement();
    if (div) {
      div.innerText = text != null ? text : "";
    }
  };
  const getEditableDivElement = () => {
    const elem = document.getElementById(uniqueId);
    if (elem !== null) {
      return elem;
    }
    return void 0;
  };
  const getEditedText = () => {
    var _a, _b;
    return (_b = (_a = getEditableDivElement()) == null ? void 0 : _a.innerText) != null ? _b : "";
  };
  const onFocus = () => {
    if (!editMode && !props.readonly) {
      setEditMode(true);
      changeEditedText(void 0);
    }
  };
  const [dataSourceResult, setDataSourceResult] = useState([]);
  const lastCall = useRef({ ts: void 0 });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchResultMessage, setSearchResultMessage] = useState(void 0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedText, setEditedText] = useState(void 0);
  const uniqueId = useMemo(() => newUniqueId(), []);
  useEffect(() => {
    var _a;
    if (editMode) {
      (_a = getEditableDivElement()) == null ? void 0 : _a.focus();
    }
  }, [editMode]);
  useEffect(() => {
    refreshDataSourceResult(props.dataSource);
  }, [editedText]);
  useEffect(() => {
    setSelectedItems(convertToMultiSelect(props.selectedItems));
    changeEditedText(void 0);
  }, [props.selectedItems]);
  useEffect(() => {
    refreshDataSourceResult(props.dataSource);
  }, [selectedItems]);
  useEffect(() => {
    if (editMode) {
      refreshDataSourceResult(props.dataSource);
      const onClickedOutside = (e) => {
        var _a;
        if (!host.contains(e.target)) {
          setEditMode(false);
          changeEditedText(void 0);
          (_a = getEditableDivElement()) == null ? void 0 : _a.blur();
        }
      };
      const onCloseOnEscKey = (e) => {
        var _a;
        if (e.key === "Escape") {
          setEditMode(false);
          changeEditedText(void 0);
          (_a = getEditableDivElement()) == null ? void 0 : _a.blur();
        }
      };
      document.addEventListener("keyup", onCloseOnEscKey, true);
      document.addEventListener("click", onClickedOutside, true);
      return () => {
        document.removeEventListener("keyup", onCloseOnEscKey);
        document.removeEventListener("click", onClickedOutside);
      };
    }
    return void 0;
  }, [editMode]);
  const labelTemplate = () => props.label !== void 0 ? html`<label class="form-label"> ${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""} </label>` : "";
  return html`<div class="w-full">
		${labelTemplate()}
		<div
			class="contentbox rounded-md ${props.readonly ? "readonly" : ""}"
			@click=${onFocus}
			@blur=${(e) => {
    e.target.innerText = "";
    changeEditedText(void 0);
  }}
		>
			${selectedItems.map(
    (item) => html`<div class="selectedItem ${editMode ? "xxs" : "xs"}">
						<div class="selectedItemLabel">${item.label}</div>

						<div
							class="${editMode ? "" : "hidden"} deleteSelectedItem"
							@click=${() => {
      removeFromSelectedItems(item);
    }}
						>
							x
						</div>
					</div>`
  )}
			<div
				id=${uniqueId}
				class="focus:outline-none"
				?contenteditable=${!props.readonly && editMode}
				@focus=${onFocus}
				@keypress=${(e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      e.preventDefault();
      if (dataSourceResult.length > 0) {
        addToSelectedItems(dataSourceResult[selectedIndex]);
      } else if (!dataSourceResult.concat(selectedItems).some((item) => item.label.toLowerCase() === editedText.toLowerCase())) {
        addToSelectedItems({ value: NEW_LABEL_ID, label: editedText });
      }
    } else {
      window.setTimeout(() => {
        var _a;
        return setEditedText((_a = getEditedText()) == null ? void 0 : _a.trim());
      }, 0);
    }
  }}
				@keydown=${(e) => {
    e.stopPropagation();
    if (e.key === "ArrowDown" && selectedIndex < dataSourceResult.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    } else if (e.key === "ArrowUp" && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    } else if (e.key === "Backspace") {
      if (!isEmpty(editedText)) {
        window.setTimeout(() => {
          var _a;
          return setEditedText((_a = getEditedText()) == null ? void 0 : _a.trim());
        }, 0);
      } else if (selectedItems.length > 0) {
        e.preventDefault();
        removeFromSelectedItems(selectedItems[selectedItems.length - 1]);
      }
    } else {
      window.setTimeout(() => {
        var _a;
        return setEditedText((_a = getEditedText()) == null ? void 0 : _a.trim());
      }, 0);
    }
  }}
			></div>
		</div>
		${editMode ? html`<div class="selectbox rounded-md">
					${searchResultMessage ? html`<div class="text-gray-400">${searchResultMessage}</div>` : dataSourceResult.length === 0 && (isEmpty(editedText) || selectedItems.some((item) => item.label.toLowerCase() === editedText.toLowerCase())) ? html`<div class="text-gray-400">${i18next.t("(Empty)")}</div>` : dataSourceResult.concat(
    editedText && dataSourceResult.concat(selectedItems).filter((item) => item.label.toLowerCase() === editedText.toLowerCase()).length === 0 ? props.canAddNew ? [
      {
        value: NEW_LABEL_ID,
        label: `${editedText} (${i18next.t("New label")})`
      }
    ] : [] : []
  ).map(
    (item, i) => html`<div
											@mouseover=${() => {
      setSelectedIndex(i);
    }}
											@click=${() => {
      addToSelectedItems(item);
    }}
											class="${selectedIndex === i ? "bg-brand-primary text-white" : ""} cursor-pointer px-1"
										>
											${item.label}
										</div>`
  )}
			  </div>` : ""}
		${props.validationMessage ? html`<div class="validation-result">${props.validationMessage}</div>` : ""}
	</div>`;
};
customElements.define(
  "dc-multiselect",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
