var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class Migration {
  /**
   * Creates an instance of Migration.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Migration
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param CompaniesRq_ _companiesRq
   * @returns Promise<CompaniesRq_>
   * @memberof Migration
   */
  companies(pCompaniesRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Migration/companies", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pCompaniesRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param IossRq_ _iossRq
   * @returns Promise<IossRq_>
   * @memberof Migration
   */
  ioss(pIossRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Migration/ioss", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pIossRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param OssRq_ _ossRq
   * @returns Promise<OssRq_>
   * @memberof Migration
   */
  oss(pOssRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Migration/oss", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pOssRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param OtherVatsRq_ _otherVatsRq
   * @returns Promise<OtherVatsRq_>
   * @memberof Migration
   */
  otherVats(pOtherVatsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Migration/other-vats", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pOtherVatsRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SellerAccountsRq_ _sellerAccountsRq
   * @returns Promise<SellerAccountsRq_>
   * @memberof Migration
   */
  sellerAccounts(pSellerAccountsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Migration/seller-accounts", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSellerAccountsRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SubscriptionsRq_ _subscriptionsRq
   * @returns Promise<SubscriptionsRq_>
   * @memberof Migration
   */
  subscriptions(pSubscriptionsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Migration/subscriptions", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSubscriptionsRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
