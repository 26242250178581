import autoTable from "jspdf-autotable";
export const COLOR_WHITE = [255, 255, 255];
export const COLOR_BLACK = [0, 0, 0];
export const COLOR_DARK_GRAY = [166, 166, 166];
export const COLOR_LIGHT_GRAY = [224, 224, 224];
export const COLOR_URL_BLUE = [22, 7, 255];
export const COLOR_RED = [209, 52, 56];
export const MAIN_WEBSITE = "www.taxdesk.com";
export const IOSS_TERMS_AND_CONDITIONS = "https://www.taxdesk.com/terms-conditions/";
export const PRIVACY_POLICY = "https://www.taxdesk.com/privacy-policy/";
export const vatSection = (sectionLetter, top, ctx) => {
  var _a;
  const vatSubTotal = ctx.model.summary.subTotal - ctx.model.summary.ioss - ctx.model.summary.ossSummary.total;
  ctx.text(`${sectionLetter}) Core Tax Compliance Services \u2013 Country specific VAT registration(s)`, ctx.sideMargin, top.val, { isBold: true });
  const body = [];
  const identedRows = [];
  let rowNum = 0;
  const addRow = (label, value, idented = false) => {
    if (label === void 0 && value === void 0) {
      body.push(["", ""]);
      return;
    }
    body.push([label, `${value} EUR`]);
    if (idented) {
      identedRows.push(rowNum);
    }
    rowNum++;
  };
  (_a = ctx.model.summary.countries) == null ? void 0 : _a.map((country) => {
    addRow(country.label, country.total);
    if (country.fiscalRep > 0) {
      addRow("Fiscal representation", country.fiscalRep, true);
    }
    if (country.filingAgent > 0) {
      addRow("Filing agent", country.filingAgent, true);
    }
    if (country.retro > 0) {
      addRow("Retrospective Return", country.retro, true);
    }
    if (country.monthlyCompliance > 0) {
      addRow("Monthly country compliance", country.monthlyCompliance, true);
    }
    if (country.selfRevision > 0) {
      addRow("Self Revision / Retrospective Returns", country.selfRevision, true);
    }
    if (country.intrastat > 0) {
      addRow("Intrastat Report", country.intrastat, true);
    }
    if (country.deregistration > 0) {
      addRow("Deregistration", country.deregistration, true);
    }
  });
  if (ctx.model.summary.eori > 0) {
    addRow("EORI number application in the EU", ctx.model.summary.eori);
  }
  if (ctx.model.summary.ukEori > 0) {
    addRow("EORI number application in the UK", ctx.model.summary.ukEori);
  }
  if (ctx.model.summary.changeCompanyDetail > 0) {
    addRow("Change Company Detail", ctx.model.summary.changeCompanyDetail);
  }
  if (ctx.model.summary.iossDeregistration > 0) {
    addRow("IOSS Deregistration", ctx.model.summary.iossDeregistration);
  }
  if (ctx.model.summary.communicatingWithTaxAuthorities > 0) {
    addRow("Communicating with Tax Authorities", ctx.model.summary.communicatingWithTaxAuthorities);
  }
  if (ctx.model.summary.generalVatAdvice > 0) {
    addRow("General VAT Advice", ctx.model.summary.generalVatAdvice);
  }
  if (ctx.model.summary.specialistVatAdvice > 0) {
    addRow("Specialist VAT Advice", ctx.model.summary.specialistVatAdvice);
  }
  addRow(void 0, void 0);
  addRow("Sub-total", vatSubTotal);
  addRow("VAT", ctx.model.summary.vat);
  addRow("Total", vatSubTotal + ctx.model.summary.vat);
  autoTable(ctx.doc, {
    head: [["Country specific VAT registration and compliance in the following countries:", "Service Fee"]],
    body,
    theme: "grid",
    startY: top.val + 6,
    margin: { left: 55, right: 55 },
    styles: {
      textColor: COLOR_BLACK,
      overflow: "linebreak",
      font: "times",
      fontSize: 8,
      lineColor: COLOR_LIGHT_GRAY,
      valign: "middle",
      cellPadding: { top: 1, bottom: 1, horizontal: 1 }
    },
    headStyles: {
      fillColor: COLOR_DARK_GRAY,
      textColor: COLOR_WHITE
    },
    columnStyles: {
      0: { cellWidth: 70 },
      1: { cellWidth: 30 }
    },
    didParseCell: (data) => {
      if (identedRows.includes(data.row.index) && data.column.index === 0) {
        data.cell.styles.cellPadding = { left: 6, right: 1, vertical: 1 };
      }
      if (data.row.index === body.length - 1) {
        data.cell.styles.fontSize = 11;
        data.cell.styles.fontStyle = "bold";
      }
    }
  });
  top.val = ctx.doc.autoTable.previous.finalY + ctx.lineHeight;
};
export const ossSection = (sectionLetter, top, ctx) => {
  top.val += 8;
  ctx.text(`${sectionLetter}) Core Tax Compliance Services \u2013 EU One-Stop-Shop`, ctx.sideMargin, top.val, { isBold: true });
  top.val += ctx.lineHeight;
  const body = [];
  if (ctx.model.summary.ossSummary.yearlyCompliance > 0) {
    body.push([`OSS Registration and Compliance - ${ctx.model.summary.ossSummary.countryName}`, `${ctx.model.summary.ossSummary.yearlyCompliance} EUR`]);
  } else if (ctx.model.summary.ossSummary.deregistration > 0) {
    body.push([`OSS Deregistration - ${ctx.model.summary.ossSummary.countryName}`, `${ctx.model.summary.ossSummary.deregistration} EUR`]);
  } else if (ctx.model.summary.ossSummary.quarterlyCompliance > 0) {
    body.push([`OSS Quarterly compliance - ${ctx.model.summary.ossSummary.countryName}`, `${ctx.model.summary.ossSummary.quarterlyCompliance} EUR`]);
  }
  body.push(["Total", `${ctx.model.summary.ossSummary.total} EUR`]);
  autoTable(ctx.doc, {
    head: [["OSS Services:", "Service Fee"]],
    body,
    theme: "grid",
    startY: top.val + 6,
    margin: { left: 55, right: 55 },
    styles: {
      textColor: COLOR_BLACK,
      overflow: "linebreak",
      font: "times",
      fontSize: 8,
      lineColor: COLOR_LIGHT_GRAY,
      valign: "middle",
      cellPadding: { top: 1, bottom: 1, horizontal: 1 }
    },
    headStyles: {
      fillColor: COLOR_DARK_GRAY,
      textColor: COLOR_WHITE
    },
    columnStyles: {
      0: { cellWidth: 70 },
      1: { cellWidth: 30 }
    },
    didParseCell: (data) => {
      if (data.row.index === body.length - 1) {
        data.cell.styles.fontSize = 11;
        data.cell.styles.fontStyle = "bold";
      }
    }
  });
  top.val = ctx.doc.autoTable.previous.finalY + ctx.lineHeight;
};
export const iossSection = (sectionLetter, top, ctx, tierPriceDescription) => {
  top.val += 8;
  ctx.text(`${sectionLetter}) Core Tax Compliance Services \u2013 EU Import-One-Stop-Shop VAT registration`, ctx.sideMargin, top.val, { isBold: true });
  top.val += ctx.lineHeight;
  ctx.text(`The Core Tax Compliance Services Fee for the chosen IOSS compliance services is as follows:`, ctx.sideMargin, top.val);
  top.val += ctx.lineHeight;
  autoTable(ctx.doc, {
    head: [["Name of the Services", "One time registration/takeover fee", "Core Tax Compliance Services Fee, including VAT intermediary services"]],
    body: tierPriceDescription.body,
    theme: "grid",
    startY: top.val,
    styles: {
      textColor: COLOR_BLACK,
      overflow: "linebreak",
      font: "times",
      fontSize: 9,
      lineColor: COLOR_LIGHT_GRAY,
      valign: "middle",
      cellPadding: { top: 2, horizontal: 2, bottom: 3 }
    },
    headStyles: {
      fillColor: COLOR_DARK_GRAY,
      textColor: COLOR_WHITE
    },
    columnStyles: {
      0: { cellWidth: 32 },
      1: { cellWidth: 50 }
    },
    didParseCell: (data) => {
      if (data.row.index === getIossTierRowIndex(ctx.model.iossTier)) {
        data.cell.styles.fontStyle = "bold";
      }
    }
  });
  top.val = ctx.doc.autoTable.previous.finalY + ctx.lineHeight;
};
export const getIossTierRowIndex = (iossTier) => {
  switch (iossTier) {
    case "Tier1":
      return 0;
    case "Tier2":
      return 1;
    case "Tier3":
      return 2;
    default:
      return -1;
  }
};
