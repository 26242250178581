var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { SeparateTransactionData } from "./SeparateTransactionData";
import { CustomSourceReference } from "./CustomSourceReference";
export class SeparateCustomSourceReference extends CustomSourceReference {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "PurchaseFileName");
    /**
     */
    __publicField(this, "PurchaseReference");
    /**
     */
    __publicField(this, "SaleFileName");
    /**
     */
    __publicField(this, "SaleReference");
    /**
     */
    __publicField(this, "TransactionData");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SeparateCustomSourceReference
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.SeparateCustomSourceReference");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.PurchaseFileName = _data["PurchaseFileName"];
      this.PurchaseReference = _data["PurchaseReference"];
      this.SaleFileName = _data["SaleFileName"];
      this.SaleReference = _data["SaleReference"];
      if (_data["TransactionData"]) {
        const transactionData_ = new SeparateTransactionData();
        this.TransactionData = transactionData_.init(_data["TransactionData"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["PurchaseFileName"] = this.PurchaseFileName;
    _data["PurchaseReference"] = this.PurchaseReference;
    _data["SaleFileName"] = this.SaleFileName;
    _data["SaleReference"] = this.SaleReference;
    _data["TransactionData"] = this.TransactionData ? this.TransactionData.toJSON() : void 0;
    return _data;
  }
}
