var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { CustomParamBase } from "./CustomParamBase";
export class VatRegCustomParam extends CustomParamBase {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "Category");
    /**
     */
    __publicField(this, "CompanyType");
    /**
     */
    __publicField(this, "Country");
    /**
     */
    __publicField(this, "EstablishmentCountries");
    /**
     */
    __publicField(this, "RegType");
    /**
     */
    __publicField(this, "Trigger");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegCustomParam
     */
    __publicField(this, "$type", "V1.TemplateMapping.VatRegCustomParam");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.Category = VatRegCustomParamCategory[_data["Category"]];
      this.CompanyType = VatRegCustomParamCompanyType[_data["CompanyType"]];
      this.Country = _data["Country"];
      if (_data["EstablishmentCountries"] && _data["EstablishmentCountries"].constructor === Array) {
        this.EstablishmentCountries = [];
        for (let item of _data["EstablishmentCountries"]) {
          this.EstablishmentCountries.push(item);
        }
      }
      this.RegType = VatRegCustomParamRegType[_data["RegType"]];
      this.Trigger = VatRegCustomParamTrigger[_data["Trigger"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["Category"] = VatRegCustomParamCategory[this.Category];
    _data["CompanyType"] = VatRegCustomParamCompanyType[this.CompanyType];
    _data["Country"] = this.Country;
    if (this.EstablishmentCountries && this.EstablishmentCountries.constructor === Array) {
      _data["EstablishmentCountries"] = [];
      for (let item of this.EstablishmentCountries) {
        _data["EstablishmentCountries"].push(item);
      }
    }
    _data["RegType"] = VatRegCustomParamRegType[this.RegType];
    _data["Trigger"] = VatRegCustomParamTrigger[this.Trigger];
    return _data;
  }
}
export var VatRegCustomParamCategory = /* @__PURE__ */ ((VatRegCustomParamCategory2) => {
  VatRegCustomParamCategory2["RequiredElectronicallyOnly"] = "RequiredElectronicallyOnly";
  VatRegCustomParamCategory2["RequiredByPost"] = "RequiredByPost";
  VatRegCustomParamCategory2["ForInformationOnly"] = "ForInformationOnly";
  return VatRegCustomParamCategory2;
})(VatRegCustomParamCategory || {});
export var VatRegCustomParamCompanyType = /* @__PURE__ */ ((VatRegCustomParamCompanyType2) => {
  VatRegCustomParamCompanyType2["Individual"] = "Individual";
  VatRegCustomParamCompanyType2["Company"] = "Company";
  return VatRegCustomParamCompanyType2;
})(VatRegCustomParamCompanyType || {});
export var VatRegCustomParamRegType = /* @__PURE__ */ ((VatRegCustomParamRegType2) => {
  VatRegCustomParamRegType2["NewReg"] = "NewReg";
  VatRegCustomParamRegType2["TakeOver"] = "TakeOver";
  return VatRegCustomParamRegType2;
})(VatRegCustomParamRegType || {});
export var VatRegCustomParamTrigger = /* @__PURE__ */ ((VatRegCustomParamTrigger2) => {
  VatRegCustomParamTrigger2["Q11EApproved"] = "Q11EApproved";
  VatRegCustomParamTrigger2["Q11ECompleted"] = "Q11ECompleted";
  VatRegCustomParamTrigger2["Q11EIncomplete"] = "Q11EIncomplete";
  VatRegCustomParamTrigger2["Q11EVerified"] = "Q11EVerified";
  VatRegCustomParamTrigger2["CompanyCreated"] = "CompanyCreated";
  VatRegCustomParamTrigger2["CompanyRejected"] = "CompanyRejected";
  VatRegCustomParamTrigger2["VatRegPending"] = "VatRegPending";
  VatRegCustomParamTrigger2["VatRegWaitingForSignature"] = "VatRegWaitingForSignature";
  VatRegCustomParamTrigger2["VatRegDocumentsSigned"] = "VatRegDocumentsSigned";
  VatRegCustomParamTrigger2["VatRegTranslationInProgress"] = "VatRegTranslationInProgress";
  VatRegCustomParamTrigger2["VatRegSentToTA"] = "VatRegSentToTA";
  VatRegCustomParamTrigger2["VatRegLocalTaxReceived"] = "VatRegLocalTaxReceived";
  VatRegCustomParamTrigger2["VatRegRegistrationCompleted"] = "VatRegRegistrationCompleted";
  return VatRegCustomParamTrigger2;
})(VatRegCustomParamTrigger || {});
