var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { Validation, useAwesomeValidator } from "GlobalShared/validation/AwesomeValidator";
import { Profile } from "WebServices/APIs/self/models/V1/Profiles/Profile";
import i18next from "i18next";
import { html } from "lit-html";
export const useProfileEditor = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [model, setModel] = useState();
  useEffect(() => {
    setModel(new Profile(__spreadProps(__spreadValues({}, props == null ? void 0 : props.model), { Email: void 0 })));
  }, [props == null ? void 0 : props.model]);
  const validator = useAwesomeValidator([Validation.ruleFor("Email", (m) => m.Email).isEmail(i18next.t("Invalid email format"))], () => props.onSave(model));
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const modalContent = () => html` <div class="p-3 flex flex-col gap-3">
		<dc-input
			.class=${"w-1/3"}
			.label=${i18next.t("New Email")}
			.value=${(model == null ? void 0 : model.Email) || ""}
			.validationMessage=${validator.getMessage("Email")}
			@change=${(e) => {
    setModel(new Profile(__spreadProps(__spreadValues({}, model), { Email: e.detail.value })));
  }}
		></dc-input>

		<dc-toggle
			.label=${i18next.t("Two-factor authentication")}
			.state=${(model == null ? void 0 : model.TwoFactorEnabled) ? "Yes" : "No"}
			@change=${(e) => {
    setModel(new Profile(__spreadProps(__spreadValues({}, model), { TwoFactorEnabled: e.detail.state === "Yes" })));
  }}
		></dc-toggle>

		<div class="flex gap-2 mt-3">
			<button class="btn btn-tertiary" @click=${() => setIsOpen(false)}>${i18next.t("Cancel")}</button>
			<button class="btn btn-primary" @click=${() => validator.validateAndDispatch(model)}>${i18next.t("Save")}</button>
		</div>
	</div>`;
  const template = model ? html`<dc-modal .customClass=${"changing-email-modal"} .visible=${isOpen} @close=${() => setIsOpen(false)} .header=${i18next.t("My Profile")} .content=${modalContent()}> </dc-modal> ` : "";
  return {
    open,
    close,
    template
  };
};
