var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../../converters/DateConverter";
import { Country } from "./../../../Common/MasterData/Country";
export class VatRegSearchContent {
  constructor(_data) {
    /**
     */
    __publicField(this, "AccountManager");
    /**
     */
    __publicField(this, "ClientSource");
    /**
     */
    __publicField(this, "CompanyId");
    /**
     */
    __publicField(this, "CompanyName");
    /**
     */
    __publicField(this, "CompanyStatus");
    /**
     */
    __publicField(this, "Country");
    /**
     */
    __publicField(this, "EstablishmentCountryCode");
    /**
     */
    __publicField(this, "LastStatusChange");
    /**
     */
    __publicField(this, "QuestionnaireStatus");
    /**
     */
    __publicField(this, "RegType");
    /**
     */
    __publicField(this, "ResponsibleEmail");
    /**
     */
    __publicField(this, "SignUpDate");
    /**
     */
    __publicField(this, "SubscriptionStatus");
    /**
     */
    __publicField(this, "SubscriptionValidTo");
    /**
     */
    __publicField(this, "VatRegId");
    /**
     */
    __publicField(this, "VatRegistrationStatus");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegSearchContent
     */
    __publicField(this, "$type", "V1.Company.VatRegistration.Search.VatRegSearchContent");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.AccountManager = _data["AccountManager"];
      this.ClientSource = VatRegSearchContentClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.CompanyName = _data["CompanyName"];
      this.CompanyStatus = VatRegSearchContentCompanyStatus[_data["CompanyStatus"]];
      if (_data["Country"]) {
        const country_ = new Country();
        this.Country = country_.init(_data["Country"]);
      }
      this.EstablishmentCountryCode = _data["EstablishmentCountryCode"];
      this.LastStatusChange = DateConverter.from(_data["LastStatusChange"]);
      this.QuestionnaireStatus = VatRegSearchContentQuestionnaireStatus[_data["QuestionnaireStatus"]];
      this.RegType = VatRegSearchContentRegType[_data["RegType"]];
      this.ResponsibleEmail = _data["ResponsibleEmail"];
      this.SignUpDate = DateConverter.from(_data["SignUpDate"]);
      this.SubscriptionStatus = VatRegSearchContentSubscriptionStatus[_data["SubscriptionStatus"]];
      this.SubscriptionValidTo = DateConverter.from(_data["SubscriptionValidTo"]);
      this.VatRegId = _data["VatRegId"];
      this.VatRegistrationStatus = VatRegSearchContentVatRegistrationStatus[_data["VatRegistrationStatus"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["AccountManager"] = this.AccountManager;
    _data["ClientSource"] = VatRegSearchContentClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["CompanyName"] = this.CompanyName;
    _data["CompanyStatus"] = VatRegSearchContentCompanyStatus[this.CompanyStatus];
    _data["Country"] = this.Country ? this.Country.toJSON() : void 0;
    _data["EstablishmentCountryCode"] = this.EstablishmentCountryCode;
    _data["LastStatusChange"] = DateConverter.toIsoUtcString(this.LastStatusChange);
    _data["QuestionnaireStatus"] = VatRegSearchContentQuestionnaireStatus[this.QuestionnaireStatus];
    _data["RegType"] = VatRegSearchContentRegType[this.RegType];
    _data["ResponsibleEmail"] = this.ResponsibleEmail;
    _data["SignUpDate"] = DateConverter.toIsoUtcString(this.SignUpDate);
    _data["SubscriptionStatus"] = VatRegSearchContentSubscriptionStatus[this.SubscriptionStatus];
    _data["SubscriptionValidTo"] = DateConverter.toUtcDateString(this.SubscriptionValidTo);
    _data["VatRegId"] = this.VatRegId;
    _data["VatRegistrationStatus"] = VatRegSearchContentVatRegistrationStatus[this.VatRegistrationStatus];
    return _data;
  }
}
export var VatRegSearchContentClientSource = /* @__PURE__ */ ((VatRegSearchContentClientSource2) => {
  VatRegSearchContentClientSource2["TaxDesk"] = "TaxDesk";
  VatRegSearchContentClientSource2["Geopost"] = "Geopost";
  VatRegSearchContentClientSource2["Partner"] = "Partner";
  VatRegSearchContentClientSource2["Hellotax"] = "Hellotax";
  return VatRegSearchContentClientSource2;
})(VatRegSearchContentClientSource || {});
export var VatRegSearchContentCompanyStatus = /* @__PURE__ */ ((VatRegSearchContentCompanyStatus2) => {
  VatRegSearchContentCompanyStatus2["Active"] = "Active";
  VatRegSearchContentCompanyStatus2["Rejected"] = "Rejected";
  VatRegSearchContentCompanyStatus2["Inactive"] = "Inactive";
  return VatRegSearchContentCompanyStatus2;
})(VatRegSearchContentCompanyStatus || {});
export var VatRegSearchContentQuestionnaireStatus = /* @__PURE__ */ ((VatRegSearchContentQuestionnaireStatus2) => {
  VatRegSearchContentQuestionnaireStatus2["Incomplete"] = "Incomplete";
  VatRegSearchContentQuestionnaireStatus2["Completed"] = "Completed";
  VatRegSearchContentQuestionnaireStatus2["Approved"] = "Approved";
  VatRegSearchContentQuestionnaireStatus2["DataVerified"] = "DataVerified";
  return VatRegSearchContentQuestionnaireStatus2;
})(VatRegSearchContentQuestionnaireStatus || {});
export var VatRegSearchContentRegType = /* @__PURE__ */ ((VatRegSearchContentRegType2) => {
  VatRegSearchContentRegType2["NewReg"] = "NewReg";
  VatRegSearchContentRegType2["TakOver"] = "TakOver";
  return VatRegSearchContentRegType2;
})(VatRegSearchContentRegType || {});
export var VatRegSearchContentSubscriptionStatus = /* @__PURE__ */ ((VatRegSearchContentSubscriptionStatus2) => {
  VatRegSearchContentSubscriptionStatus2["Active"] = "Active";
  VatRegSearchContentSubscriptionStatus2["Cancelled"] = "Cancelled";
  VatRegSearchContentSubscriptionStatus2["Expired"] = "Expired";
  VatRegSearchContentSubscriptionStatus2["Pending"] = "Pending";
  return VatRegSearchContentSubscriptionStatus2;
})(VatRegSearchContentSubscriptionStatus || {});
export var VatRegSearchContentVatRegistrationStatus = /* @__PURE__ */ ((VatRegSearchContentVatRegistrationStatus2) => {
  VatRegSearchContentVatRegistrationStatus2["Pending"] = "Pending";
  VatRegSearchContentVatRegistrationStatus2["WaitingForSignature"] = "WaitingForSignature";
  VatRegSearchContentVatRegistrationStatus2["DocumentsSigned"] = "DocumentsSigned";
  VatRegSearchContentVatRegistrationStatus2["TranslationInProgress"] = "TranslationInProgress";
  VatRegSearchContentVatRegistrationStatus2["SentToTA"] = "SentToTA";
  VatRegSearchContentVatRegistrationStatus2["LocalTaxReceived"] = "LocalTaxReceived";
  VatRegSearchContentVatRegistrationStatus2["RegistrationCompleted"] = "RegistrationCompleted";
  VatRegSearchContentVatRegistrationStatus2["Deregistered"] = "Deregistered";
  VatRegSearchContentVatRegistrationStatus2["DeregistrationInProgress"] = "DeregistrationInProgress";
  VatRegSearchContentVatRegistrationStatus2["PoARevoked"] = "PoARevoked";
  VatRegSearchContentVatRegistrationStatus2["RevokingPoA"] = "RevokingPoA";
  return VatRegSearchContentVatRegistrationStatus2;
})(VatRegSearchContentVatRegistrationStatus || {});
