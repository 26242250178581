var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { Countries as CountriesClient } from "WebServices/APIs/self/clients";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { currentLocale } from "../../../globals";
export const useReportingCountries = () => {
  const reportingCountriesClient = useClient(CountriesClient);
  const worldCountries = useWorldCountries(currentLocale);
  const search = () => __async(void 0, null, function* () {
    const response = yield reportingCountriesClient.getAvailableCountries();
    const reporting = response.data.map((c) => {
      var _a;
      return { code: c, name: ((_a = worldCountries.find((w) => w.Code === c)) == null ? void 0 : _a.Name) || c };
    });
    setCountries(reporting);
  });
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    if (worldCountries)
      search();
  }, [worldCountries]);
  return countries;
};
