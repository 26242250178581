var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { CompanyDetail as AdminCompanyDetail } from "AdminServices/APIs/self/models/V1/Company/CompanyInfo/CompanyDetail";
import i18next from "i18next";
export const useClientNoteForAdmin = (props) => {
  const handleNoteInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditedNotes(e.target.value);
  };
  const handleEditClick = () => {
    if (!(props.companyDetail instanceof AdminCompanyDetail)) {
      return;
    }
    setIsEditing(true);
    setEditedNotes(props.companyDetail.Notes);
  };
  const handleSaveClick = () => __async(void 0, null, function* () {
    setIsEditing(false);
    try {
      dispatchLoading("inc");
      yield props.updateNotes(props.companyDetail.Id, editedNotes);
    } finally {
      dispatchLoading("dec");
    }
  });
  const [loading, dispatchLoading] = useLoadingReducer();
  const [isEditing, setIsEditing] = useState(false);
  const [editedNotes, setEditedNotes] = useState("");
  const noteTextTemplate = () => {
    const noteText = props.companyDetail instanceof AdminCompanyDetail && props.companyDetail.Notes ? props.companyDetail.Notes : i18next.t("Add a note here");
    return loading.count === 0 ? html`<div class="w-full text-sm">${noteText}</div>
					<div class="flex justify-center ml-4 w-10"><dc-edit-button @click=${() => handleEditClick()} .size="${18}"></dc-edit-button></div>` : "";
  };
  const editNoteModalContentTemplate = () => html`
		<div>
			<textarea
				class="w-full text-sm resize-none h-48 py-3 px-5 border-solid border-2 border-brand-tertiary-light"
				maxlength="1000"
				placeholder=${i18next.t("Add a note here")}
				.value=${editedNotes != null ? editedNotes : ""}
				@input=${handleNoteInput}
			></textarea>
		</div>
		<div class="mt-8 space-x-4">
			<button class="btn btn-primary" @click=${() => setIsEditing(false)}>Cancel</button>
			<button class="btn btn-primary" @click=${handleSaveClick}>Save</button>
		</div>
	`;
  const editNoteModalTemplate = () => isEditing && loading.count === 0 ? html`
					<div>
						<dc-modal .visible=${isEditing} @close=${() => setIsEditing(false)} .header=${i18next.t("Edit note")} .content=${editNoteModalContentTemplate()}> </dc-modal>
					</div>
			  ` : "";
  const loaderTemplate = () => loading.count > 0 ? html`<div class="flex justify-center items-center w-full">
					<dc-loader2 .isLoading=${loading.count > 0} .progress=${void 0}></dc-loader2>
			  </div>` : "";
  const template = () => props.isAdmin ? html` <div class="flex w-full px-6 py-4 bg-brand-tertiary-light rounded-b-xl">${loaderTemplate()} ${noteTextTemplate()} ${editNoteModalTemplate()}</div> ` : "";
  return { template };
};
