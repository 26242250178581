var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCompanyClientAccounts } from "GlobalShared/components/client-accounts/company-client-accounts";
import { useClient, useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { Account as AccountClient, Psps as PspsClient } from "WebServices/APIs/self/clients";
import { PatchCompanies } from "WebServices/APIs/self/models/V1/Account/PatchCompanies";
import { InviteUser, PatchPsps } from "WebServices/APIs/self/models/V1/Account";
import {
  SearchRequest as AccountSearchRequest,
  Field as AccountSearchField,
  CompanyFilter as AccountSearchCompanyFilter,
  PspFilter as AccountSearchPspFilter,
  Pager as AccountSearchPager
} from "WebServices/APIs/self/models/V1/Account/Search";
import { FieldOrder as AccountSearchFieldOrder } from "WebServices/APIs/self/models/V1/Account/Search/Field";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
import { usePspClientAccounts } from "GlobalShared/components/client-accounts/psp-client-accounts";
export function AdminClientAccounts() {
  const searchUserCompanies = (gridState, emailFragment, companyNameFragment) => __async(this, null, function* () {
    const filter = new AccountSearchCompanyFilter();
    filter.EmailPart = emailFragment;
    filter.Company = companyNameFragment;
    const userSearchResult = yield accountClient.searchUsers({
      body: new AccountSearchRequest({
        Filter: filter,
        Pager: new AccountSearchPager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new AccountSearchField({
              Order: gridState.orderDir === "asc" ? AccountSearchFieldOrder.Ascending : AccountSearchFieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    return {
      users: userSearchResult.data.Users.map((u) => {
        const ret = {
          userId: u.Id,
          email: u.Email,
          companies: u.Companies.map((c) => ({
            id: c.Id,
            name: c.Name
          })),
          twoFactorEnabled: void 0,
          isReadOnly: u.IsReadOnly
        };
        return ret;
      }),
      total: userSearchResult.data.Count
    };
  });
  const searchUserPsps = (gridState, emailFragment, pspNameFragment) => __async(this, null, function* () {
    const filter = new AccountSearchPspFilter();
    filter.EmailPart = emailFragment;
    filter.Psp = pspNameFragment;
    const userSearchResult = yield accountClient.searchUsers({
      body: new AccountSearchRequest({
        Filter: filter,
        Pager: new AccountSearchPager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [
            new AccountSearchField({
              Order: gridState.orderDir === "asc" ? AccountSearchFieldOrder.Ascending : AccountSearchFieldOrder.Descending,
              OrderByField: gridState.orderBy
            })
          ]
        })
      })
    });
    return {
      users: userSearchResult.data.Users.map((u) => {
        const ret = {
          userId: u.Id,
          email: u.Email,
          psps: u.Psps.map((c) => ({
            id: c.Id,
            name: c.Name
          })),
          twoFactorEnabled: void 0,
          isReadOnly: u.IsReadOnly
        };
        return ret;
      }),
      total: userSearchResult.data.Count
    };
  });
  const searchCompanies = (companyNameFragment) => __async(this, null, function* () {
    var _a, _b;
    return (_b = (_a = ClientContext.CompanyInformation) == null ? void 0 : _a.Companies.filter((c) => c.Name.toLowerCase().includes(companyNameFragment.toLowerCase())).map((c) => ({ id: toSafeNumber(c.Id), name: c.Name }))) != null ? _b : [];
  });
  const searchPsps = (pspNameFragment) => __async(this, null, function* () {
    var _a;
    return (_a = psps.filter((c) => c.Name.Value.toLowerCase().includes(pspNameFragment.toLowerCase())).map((c) => ({ id: c.Id, name: c.Name.Value }))) != null ? _a : [];
  });
  const inviteCompanyUser = (email, companies) => __async(this, null, function* () {
    yield inviteInternal(email, companies);
  });
  const invitePspUser = (email, psps2) => __async(this, null, function* () {
    yield inviteInternal(email, void 0, psps2);
  });
  const inviteInternal = (email, companies, psps2) => __async(this, null, function* () {
    yield accountClient.inviteUser({
      body: new InviteUser({ Email: email, CompanyIds: companies == null ? void 0 : companies.map((c) => c.id), PspIds: psps2 == null ? void 0 : psps2.map((p) => p.id) })
    });
  });
  const assignCompanies = (userId, addedCompanies, removedCompanies) => __async(this, null, function* () {
    yield accountClient.patchCompanies({
      userId,
      body: new PatchCompanies({
        AddedCompanies: addedCompanies.map((c) => c.id),
        RemovedCompanies: removedCompanies.map((c) => c.id)
      })
    });
  });
  const assignPsps = (userId, addedPsps, removedPsps) => __async(this, null, function* () {
    yield accountClient.patchPsps({
      userId,
      body: new PatchPsps({
        AddedPsps: addedPsps.map((c) => c.id),
        RemovedPsps: removedPsps.map((c) => c.id)
      })
    });
  });
  const changeEmail = (_userId, _newEmail) => __async(this, null, function* () {
  });
  const updateUser = (_userId, _twoFactorEnabled) => __async(this, null, function* () {
  });
  const getMyPsps = () => __async(this, null, function* () {
    const response = yield pspsClient.getMyPsps();
    setPsps(response.data);
  });
  const accountClient = useClient(AccountClient);
  const pspsClient = useClient(PspsClient);
  const [psps, setPsps] = useState([]);
  const companyClientAccounts = useCompanyClientAccounts({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    searchUserCompanies,
    searchCompanies,
    inviteUser: inviteCompanyUser,
    assignCompanies,
    changeEmail
  });
  const pspClientAccounts = usePspClientAccounts({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    searchUserPsps,
    searchPsps,
    inviteUser: invitePspUser,
    assignPsps,
    changeEmail,
    updateUser
  });
  useEffect(() => {
    if (ClientContext.IsPspUser) {
      getMyPsps();
    }
  }, []);
  return ClientContext.IsPspUser ? pspClientAccounts.mainTemplate : companyClientAccounts.mainTemplate;
}
