var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { SapExcelValidationMessage } from "./SapExcelValidationMessage";
export class SapExcelUploadResult {
  constructor(_data) {
    /**
     */
    __publicField(this, "IsSuccess");
    /**
     */
    __publicField(this, "NumberOfTotalLinesWithError");
    /**
     */
    __publicField(this, "ValidationMessages");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SapExcelUploadResult
     */
    __publicField(this, "$type", "V1.ComplianceUploads.SapExcelUploadResult");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.IsSuccess = _data["IsSuccess"];
      this.NumberOfTotalLinesWithError = _data["NumberOfTotalLinesWithError"];
      if (_data["ValidationMessages"] && _data["ValidationMessages"].constructor === Array) {
        this.ValidationMessages = [];
        for (let item of _data["ValidationMessages"]) {
          const validationMessages_ = new SapExcelValidationMessage();
          this.ValidationMessages.push(validationMessages_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["IsSuccess"] = this.IsSuccess;
    _data["NumberOfTotalLinesWithError"] = this.NumberOfTotalLinesWithError;
    if (this.ValidationMessages && this.ValidationMessages.constructor === Array) {
      _data["ValidationMessages"] = [];
      for (let item of this.ValidationMessages) {
        _data["ValidationMessages"].push(item.toJSON());
      }
    }
    return _data;
  }
}
