var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { ValidationErrorGroup } from "./../ValidationErrorGroup";
export class PayeeValidationErrorGroup extends ValidationErrorGroup {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "Code");
    /**
     */
    __publicField(this, "Column");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PayeeValidationErrorGroup
     */
    __publicField(this, "$type", "V1.Cesop.Periods.Validation.Source.Payee.PayeeValidationErrorGroup");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.Code = PayeeValidationErrorGroupCode[_data["Code"]];
      this.Column = PayeeValidationErrorGroupColumn[_data["Column"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["Code"] = PayeeValidationErrorGroupCode[this.Code];
    _data["Column"] = PayeeValidationErrorGroupColumn[this.Column];
    return _data;
  }
}
export var PayeeValidationErrorGroupCode = /* @__PURE__ */ ((PayeeValidationErrorGroupCode2) => {
  PayeeValidationErrorGroupCode2["MissingData"] = "MissingData";
  PayeeValidationErrorGroupCode2["InvalidEnumValue"] = "InvalidEnumValue";
  PayeeValidationErrorGroupCode2["InvalidCountryCode"] = "InvalidCountryCode";
  PayeeValidationErrorGroupCode2["InvalidEmail"] = "InvalidEmail";
  PayeeValidationErrorGroupCode2["InvalidVatId"] = "InvalidVatId";
  PayeeValidationErrorGroupCode2["InvalidTaxId"] = "InvalidTaxId";
  PayeeValidationErrorGroupCode2["TooLong"] = "TooLong";
  PayeeValidationErrorGroupCode2["DuplicatedValue"] = "DuplicatedValue";
  PayeeValidationErrorGroupCode2["AccountIdAndRepresentativeProvided"] = "AccountIdAndRepresentativeProvided";
  PayeeValidationErrorGroupCode2["AccountIdAndRepresentativeMissing"] = "AccountIdAndRepresentativeMissing";
  PayeeValidationErrorGroupCode2["InvalidSeparator"] = "InvalidSeparator";
  PayeeValidationErrorGroupCode2["InvalidHeader"] = "InvalidHeader";
  PayeeValidationErrorGroupCode2["UnprocessableFile"] = "UnprocessableFile";
  PayeeValidationErrorGroupCode2["InvalidColumnCount"] = "InvalidColumnCount";
  PayeeValidationErrorGroupCode2["InvalidIban"] = "InvalidIban";
  PayeeValidationErrorGroupCode2["InvalidGuid"] = "InvalidGuid";
  PayeeValidationErrorGroupCode2["InvalidMessageId"] = "InvalidMessageId";
  PayeeValidationErrorGroupCode2["InvalidDocRefId"] = "InvalidDocRefId";
  PayeeValidationErrorGroupCode2["MigratedError"] = "MigratedError";
  return PayeeValidationErrorGroupCode2;
})(PayeeValidationErrorGroupCode || {});
export var PayeeValidationErrorGroupColumn = /* @__PURE__ */ ((PayeeValidationErrorGroupColumn2) => {
  PayeeValidationErrorGroupColumn2["PayeeAccountId"] = "PayeeAccountId";
  PayeeValidationErrorGroupColumn2["NameType"] = "NameType";
  PayeeValidationErrorGroupColumn2["NameValue"] = "NameValue";
  PayeeValidationErrorGroupColumn2["BusinessCountry"] = "BusinessCountry";
  PayeeValidationErrorGroupColumn2["LegalAddressType"] = "LegalAddressType";
  PayeeValidationErrorGroupColumn2["AddressCountryCode"] = "AddressCountryCode";
  PayeeValidationErrorGroupColumn2["Street"] = "Street";
  PayeeValidationErrorGroupColumn2["BuildingIdentifier"] = "BuildingIdentifier";
  PayeeValidationErrorGroupColumn2["DistrictName"] = "DistrictName";
  PayeeValidationErrorGroupColumn2["PostCode"] = "PostCode";
  PayeeValidationErrorGroupColumn2["City"] = "City";
  PayeeValidationErrorGroupColumn2["EmailAddress"] = "EmailAddress";
  PayeeValidationErrorGroupColumn2["WebPage"] = "WebPage";
  PayeeValidationErrorGroupColumn2["VatIdIssuedBy"] = "VatIdIssuedBy";
  PayeeValidationErrorGroupColumn2["VatIdValue"] = "VatIdValue";
  PayeeValidationErrorGroupColumn2["TaxIdIssuedBy"] = "TaxIdIssuedBy";
  PayeeValidationErrorGroupColumn2["TaxIdType"] = "TaxIdType";
  PayeeValidationErrorGroupColumn2["TaxIdValue"] = "TaxIdValue";
  PayeeValidationErrorGroupColumn2["AccountIdentifierCountryCode"] = "AccountIdentifierCountryCode";
  PayeeValidationErrorGroupColumn2["AccountIdentifierType"] = "AccountIdentifierType";
  PayeeValidationErrorGroupColumn2["AccountIdentifierValue"] = "AccountIdentifierValue";
  PayeeValidationErrorGroupColumn2["RepresentativePspIdType"] = "RepresentativePspIdType";
  PayeeValidationErrorGroupColumn2["RepresentativePspValue"] = "RepresentativePspValue";
  PayeeValidationErrorGroupColumn2["RepresentativeNameType"] = "RepresentativeNameType";
  PayeeValidationErrorGroupColumn2["RepresentativeValue"] = "RepresentativeValue";
  PayeeValidationErrorGroupColumn2["PayeeLocation"] = "PayeeLocation";
  PayeeValidationErrorGroupColumn2["Action"] = "Action";
  PayeeValidationErrorGroupColumn2["Issues"] = "Issues";
  PayeeValidationErrorGroupColumn2["MessageId"] = "MessageId";
  PayeeValidationErrorGroupColumn2["DocRefId"] = "DocRefId";
  return PayeeValidationErrorGroupColumn2;
})(PayeeValidationErrorGroupColumn || {});
