import "./dc-input.scss";
import { html } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
import { useEffect, useMemo, useState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
import { isEmpty, newUniqueId } from "GlobalShared/helpers/stringHelper";
import classNames from "classnames";
const DEFAULTS = {
  label: "",
  value: void 0,
  name: void 0,
  readonly: false,
  type: "text",
  class: "",
  placeholder: void 0,
  autocomplete: void 0,
  tooltip: "",
  onInputDebounceMs: void 0,
  min: void 0,
  max: void 0
};
export class ChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail });
  }
}
export class InputEvent extends CustomEvent {
  constructor(detail) {
    super("input", { detail });
  }
}
export const Component = (host) => {
  var _a, _b;
  const props = {
    label: host.label !== void 0 ? host.label : DEFAULTS.label,
    value: host.value !== void 0 ? host.value : DEFAULTS.value,
    min: (_a = host.min) != null ? _a : DEFAULTS.min,
    max: (_b = host.max) != null ? _b : DEFAULTS.max,
    validationMessage: host.validationMessage,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    type: host.type !== void 0 ? host.type : DEFAULTS.type,
    class: host.class !== void 0 ? host.class : DEFAULTS.class,
    placeholder: host.placeholder !== void 0 ? host.placeholder : DEFAULTS.placeholder,
    autocomplete: host.autocomplete !== void 0 ? host.autocomplete : DEFAULTS.autocomplete,
    name: host.name !== void 0 ? host.name : DEFAULTS.name,
    tooltip: host.tooltip !== void 0 ? host.tooltip : DEFAULTS.tooltip,
    onInputDebounceMs: host.onInputDebounceMs !== void 0 ? host.onInputDebounceMs : DEFAULTS.onInputDebounceMs
  };
  const debounce = (func, wait) => {
    if (wait !== void 0) {
      window.clearTimeout(debounceTimer);
      setDebounceTimer(window.setTimeout(func, wait));
    } else {
      func();
    }
  };
  const [value, setValue] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(void 0);
  const uniqueId = useMemo(() => newUniqueId(), []);
  useEffect(() => {
    const newVal = isEmpty(props.value) ? "" : props.value;
    setValue(newVal);
    const elem = document.getElementById(uniqueId);
    if (elem !== null) {
      elem.value = newVal;
    }
  }, [props.value]);
  const onChange = (e) => {
    if (!props.readonly) {
      e.stopPropagation();
      host.dispatchEvent(
        new ChangeEvent({
          value: e.currentTarget.value
        })
      );
    }
  };
  const onInput = (e) => {
    if (!props.readonly) {
      e.stopPropagation();
      debounce(() => {
        host.dispatchEvent(
          new InputEvent({
            value: e.target.value
          })
        );
      }, props.onInputDebounceMs);
    }
  };
  return html`
		${props.label ? html`<label class="form-label">${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""}</label>` : ""}
		<input
			id=${uniqueId}
			class=${classNames("rounded-md", { [props.class]: !isEmpty(props.class), invalid: props.validationMessage })}
			type=${props.type}
			value=${value}
			name=${ifDefined(props.name)}
			placeholder=${ifDefined(props.placeholder)}
			autocomplete=${ifDefined(props.autocomplete)}
			?readonly=${props.readonly}
			@change=${onChange}
			@input=${onInput}
			min=${props.min}
			max=${props.max}
		/>
		${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
	`;
};
customElements.define(
  "dc-input",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
