var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
export class VATDeclaration {
  constructor(_data) {
    /**
     */
    __publicField(this, "LocalVatNumber");
    /**
     * Total value of intra-community acquisitions of goods and related costs (excluding VAT) from EU Member States to Northern Ireland
     */
    __publicField(this, "NetECAcquisitions");
    /**
     * Total value of intra-community dispatches of goods and related costs (excluding VAT) from Northern Ireland to EU Member States
     */
    __publicField(this, "NetECSupplies");
    /**
     * Total value of purchases and other expenses, excluding VAT
     */
    __publicField(this, "NetPurchasesAndInputs");
    /**
     * Total value of sales and other supplies, excluding VAT
     */
    __publicField(this, "NetSalesAndOutputs");
    /**
     * Return total
     */
    __publicField(this, "NetVAT");
    /**
     */
    __publicField(this, "PeriodEndDate");
    /**
     */
    __publicField(this, "PeriodStartDate");
    /**
     * VAT you owe before deductions (this is the total of box 1 and 2)
     */
    __publicField(this, "TotalVAT");
    /**
     * VAT due in this period on intra-community acquisitions of goods made in Northern Ireland from EU Member States
     */
    __publicField(this, "VATDueOnECAcquisitions");
    /**
     * VAT you charged on sales and other supplies
     */
    __publicField(this, "VATDueOnOutputs");
    /**
     * VAT you have claimed back
     */
    __publicField(this, "VATReclaimedOnInputs");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VATDeclaration
     */
    __publicField(this, "$type", "V1.Submission.UK.VATDeclaration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.LocalVatNumber = _data["LocalVatNumber"];
      this.NetECAcquisitions = _data["NetECAcquisitions"];
      this.NetECSupplies = _data["NetECSupplies"];
      this.NetPurchasesAndInputs = _data["NetPurchasesAndInputs"];
      this.NetSalesAndOutputs = _data["NetSalesAndOutputs"];
      this.NetVAT = _data["NetVAT"];
      this.PeriodEndDate = DateConverter.from(_data["PeriodEndDate"]);
      this.PeriodStartDate = DateConverter.from(_data["PeriodStartDate"]);
      this.TotalVAT = _data["TotalVAT"];
      this.VATDueOnECAcquisitions = _data["VATDueOnECAcquisitions"];
      this.VATDueOnOutputs = _data["VATDueOnOutputs"];
      this.VATReclaimedOnInputs = _data["VATReclaimedOnInputs"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["LocalVatNumber"] = this.LocalVatNumber;
    _data["NetECAcquisitions"] = this.NetECAcquisitions;
    _data["NetECSupplies"] = this.NetECSupplies;
    _data["NetPurchasesAndInputs"] = this.NetPurchasesAndInputs;
    _data["NetSalesAndOutputs"] = this.NetSalesAndOutputs;
    _data["NetVAT"] = this.NetVAT;
    _data["PeriodEndDate"] = DateConverter.toUtcDateString(this.PeriodEndDate);
    _data["PeriodStartDate"] = DateConverter.toUtcDateString(this.PeriodStartDate);
    _data["TotalVAT"] = this.TotalVAT;
    _data["VATDueOnECAcquisitions"] = this.VATDueOnECAcquisitions;
    _data["VATDueOnOutputs"] = this.VATDueOnOutputs;
    _data["VATReclaimedOnInputs"] = this.VATReclaimedOnInputs;
    return _data;
  }
}
