var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./client-accounts.scss";
import { useEffect, useLoadingReducer, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { extractFirstErrorMessage, notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { html } from "lit-html";
import { validateEmail } from "GlobalShared/validators/email-validator";
import i18next from "i18next";
import { templateTitle } from "GlobalShared/templates/commons";
import { UserPspsColumnNames } from "./ClientAccountsModels";
import { PspInvitationValidator, getAllPspInvitationFields } from "./InvitationValidator";
import { useConfirmModal } from "../modals/commonModals";
import { InviteUserClientSource } from "AdminServices/APIs/self/models/V1/Users/InviteUserAbstraction";
const DEFAULT_FILTERS = [];
const DEFAULT_INVITATION_MODEL = {
  email: "",
  clientSource: InviteUserClientSource.TaxDesk,
  psps: []
};
export const usePspClientAccounts = (props) => {
  const loadUserPsps = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        dispatchLoading("inc");
        if ((emailSearchFragment == null ? void 0 : emailSearchFragment.length) > 1 || (pspSearchFragment == null ? void 0 : pspSearchFragment.length) > 1) {
          const result = yield props.searchUserPsps(gridState, emailSearchFragment, pspSearchFragment);
          setUsersSearchResult(result);
        } else {
          setUsersSearchResult(void 0);
        }
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const inviteUser = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        dispatchSending("inc");
        yield props.inviteUser(currentInvitation.email, currentInvitation.psps, currentInvitation.clientSource);
        notifySuccess(i18next.t("user-successfully-invited", "User successfully invited"));
        setCurrentInvitation(DEFAULT_INVITATION_MODEL);
        setPspsForInvitation(void 0);
        validationContext.clearFieldValidations();
      }),
      (error) => {
        if (error.statusCode === 409) {
          notifyWarning("User has been already invited.");
        } else {
          notifyError(error);
        }
      },
      () => {
        dispatchSending("dec");
      }
    );
  });
  const toSelectItem = (psp) => {
    return {
      value: psp.id,
      label: psp.name
    };
  };
  const onSortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: OrderByValue,
      orderDir: OrderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const removePspFromInvitedPsps = (psp) => {
    setCurrentInvitation(__spreadProps(__spreadValues({}, currentInvitation), {
      psps: currentInvitation.psps.filter((c2) => c2.id !== psp.id)
    }));
  };
  const removePspFromAssignments = (psp) => {
    setCurrentUser(__spreadProps(__spreadValues({}, currentUser), {
      changedPsps: currentUser.changedPsps.filter((c2) => c2.id !== psp.id)
    }));
  };
  const addPspToAssignments = (psp) => {
    if (!currentUser.changedPsps.some((c) => c.id === psp.id)) {
      setCurrentUser(__spreadProps(__spreadValues({}, currentUser), {
        changedPsps: currentUser.changedPsps.concat(psp)
      }));
    }
  };
  const save = (userId, addedPsps, removedPsps, twoFactorEnabled) => __async(void 0, null, function* () {
    yield savePspAssignments(userId, addedPsps, removedPsps);
    yield updateUser(userId, twoFactorEnabled);
    notifySuccess(i18next.t("Saved successfully"));
    setShowEditModal(false);
    yield loadUserPsps();
  });
  const savePspAssignments = (userId, addedPsps, removedPsps) => __async(void 0, null, function* () {
    if (userId !== void 0 && ((addedPsps == null ? void 0 : addedPsps.length) > 0 || (removedPsps == null ? void 0 : removedPsps.length) > 0)) {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          yield props.assignPsps(userId, addedPsps, removedPsps);
        }),
        (error) => {
          if (error.statusCode === 409) {
            const errorMessage = extractFirstErrorMessage(error, i18next.t("Invalid data"));
            if (errorMessage.startsWith("The last user of the payment company cannot be removed")) {
              notifyWarning(i18next.t("The last user of the payment company cannot be removed"));
            } else {
              notifyWarning(errorMessage);
            }
          } else {
            notifyError(error);
          }
        }
      );
    }
  });
  const updateUser = (userId, twoFactorEnabled) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.updateUser(userId, twoFactorEnabled);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const saveNewEmail = () => __async(void 0, null, function* () {
    setEmailValidationMessage("");
    if (validateEmail(currentChangeEmailModel.newEmail)) {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          yield props.changeEmail(currentChangeEmailModel.userId, currentChangeEmailModel.newEmail);
          notifySuccess("Email change request sent to your new email address. Please check your new inbox");
        }),
        (error) => {
          if (error.statusCode === 409) {
            notifyError("Email already exists");
          } else {
            if (error.statusCode >= 400 && error.statusCode < 500) {
              notifyWarning(extractFirstErrorMessage(error, "Error occured"));
            } else {
              notifyError(error);
            }
          }
        },
        () => {
          setShowEmailChangeModal(false);
        }
      );
    } else {
      setEmailValidationMessage("Invalid email format");
    }
  });
  const [gridState, setGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_FILTERS,
    pageSize: 20,
    orderBy: "Email",
    orderDir: "asc"
  });
  const [currentInvitation, setCurrentInvitation] = useState(DEFAULT_INVITATION_MODEL);
  const [selectedPspForInvitation, setSelectedPspForInvitation] = useState(void 0);
  const [pspsForInvitation, setPspsForInvitation] = useState(void 0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [emailSearchFragment, setEmailSearchFragment] = useState("");
  const [pspSearchFragment, setPspSearchFragment] = useState("");
  const [userssSearchResult, setUsersSearchResult] = useState(void 0);
  const [loading, dispatchLoading] = useLoadingReducer();
  const [sending, dispatchSending] = useLoadingReducer();
  const [currentUser, setCurrentUser] = useState(void 0);
  const [selectedPspForAssignment, setSelectedPspForAssignment] = useState(void 0);
  const [pspsForAssignment, setPspsForAssignment] = useState(void 0);
  const [currentChangeEmailModel, setCurrentChangeEmailModel] = useState(void 0);
  const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  const validationContext = useValidationContext(new PspInvitationValidator(props.isAdmin));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  const confirmModal = useConfirmModal();
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationContext.validationResult.isValid()) {
        yield inviteUser();
      }
    }
  }), [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(currentInvitation);
  }, [currentInvitation]);
  useEffect(() => __async(void 0, null, function* () {
    loadUserPsps();
  }), [emailSearchFragment, pspSearchFragment, gridState]);
  useEffect(() => {
    setSelectedPspForAssignment(void 0);
    setPspsForAssignment(void 0);
  }, [showEditModal]);
  useEffect(() => {
    if (selectedPspForInvitation !== void 0) {
      if (!currentInvitation.psps.some((c) => c.id === selectedPspForInvitation.id)) {
        setCurrentInvitation(__spreadProps(__spreadValues({}, currentInvitation), {
          psps: currentInvitation.psps.concat(selectedPspForInvitation)
        }));
      }
      setSelectedPspForInvitation(void 0);
    }
  }, [selectedPspForInvitation]);
  useEffect(() => {
    if (selectedPspForAssignment !== void 0) {
      addPspToAssignments(selectedPspForAssignment);
      setSelectedPspForAssignment(void 0);
    }
  }, [selectedPspForAssignment]);
  const htmlEmailChangeModal = () => {
    if (showEmailChangeModal) {
      return html`
				<div>
					<dc-modal
						.visible=${showEmailChangeModal}
						@close=${() => {
        setShowEmailChangeModal(false);
      }}
						.header=${"Change email"}
						.content=${html`<div>
							<dc-input
								class="w-56"
								.label=${"New email"}
								.value=${currentChangeEmailModel.newEmail}
								.validationMessage=${emailValidationMessage}
								@change=${(e) => {
        setCurrentChangeEmailModel(__spreadProps(__spreadValues({}, currentChangeEmailModel), {
          newEmail: e.detail.value
        }));
      }}
							></dc-input>
							<div class="mt-4 flex space-x-4">
								<button class="btn btn-primary" @click=${() => __async(void 0, null, function* () {
        return saveNewEmail();
      })}>Save</button>
								<button
									class="btn btn-primary"
									@click=${() => {
        setShowEmailChangeModal(false);
      }}
								>
									Cancel
								</button>
							</div>
						</div>`}
					>
					</dc-modal>
				</div>
			`;
    } else {
      return html``;
    }
  };
  const htmlInviteClient = () => {
    var _a;
    return html`
			<div>${templateTitle(i18next.t("Invite Client"))}</div>
			<div class="grid xl:grid-cols-2 gap-4 mt-2">
				<div class="flex space-x-4">
					<dc-input
						.name=${"Email"}
						.label=${i18next.t("Email")}
						.value=${currentInvitation.email}
						.validationMessage=${validationContext.getValidationMessage("Email")}
						@change=${(e) => {
      validationContext.validateField("Email");
      setCurrentInvitation(__spreadProps(__spreadValues({}, currentInvitation), { email: e.detail.value }));
    }}
					></dc-input>
					<dc-select
						class="w-64"
						.label=${i18next.t("Payment Company")}
						.dataSource=${(text) => __async(void 0, null, function* () {
      if (text === void 0) {
        return i18next.t("Type at least 2 characters");
      } else if (text.length < 2) {
        return i18next.t("Type at least 2 characters");
      } else {
        const result = yield props.searchPsps(text);
        if (result.length > 0) {
          setPspsForInvitation(result);
          return result.map(toSelectItem);
        } else {
          return i18next.t("No result found");
        }
      }
    })}
						.filterable=${true}
						.debounceMs=${150}
						.selectedValues=${selectedPspForInvitation == null ? void 0 : selectedPspForInvitation.id}
						@change=${(e) => {
      const pspId = e.detail.selectedValue;
      const psp = pspsForInvitation.find((cr) => cr.id === pspId);
      setSelectedPspForInvitation(psp);
    }}
					></dc-select>
				</div>
				<div>
					${(currentInvitation == null ? void 0 : currentInvitation.psps) ? html`<div>
								<div class="font-bold whitespace-nowrap text-sm">${i18next.t("Selected payment companies")}</div>
								<div class="flex flex-wrap mt-1">${(_a = currentInvitation == null ? void 0 : currentInvitation.psps) == null ? void 0 : _a.map((p) => html`<div class="mr-4">${htmlPspChip(p, false, () => removePspFromInvitedPsps(p))}</div>`)}</div>
								${validationContext.getValidationMessage("Psps") ? html`<div class="text-red-400">${validationContext.getValidationMessage("Psps")}</div>` : ""}
						  </div>` : ""}
				</div>
			</div>

			<div>
				<button
					type="button"
					class="btn btn-primary whitespace-nowrap md:mt-6 mr-5"
					?disabled=${sending.count > 0}
					@click=${() => {
      setCurrentInvitation(__spreadValues({}, currentInvitation));
      validationContext.validateFields(getAllPspInvitationFields());
      setDispatchIfValid(true);
    }}
				>
					${i18next.t("Send invitation")}
				</button>
			</div>
		`;
  };
  const htmlPspChip = (psp, isReadOnly, removeHandler) => {
    return html`<span class="mb-2 mr-2 py-2 px-4 rounded-full text-xs border border-solid border-brand-primary whitespace-nowrap inline-flex items-center bg-white text-brand-primary">
			${psp.name}
			${!isReadOnly && props.permissionManager.hasPermission("Cesop_Client_Modify") ? html`<span class="ml-2 text-xs cursor-pointer font-semibold text-black bg-gray-400 rounded-full px-1" @click="${() => removeHandler(psp)}">&#10005;</span>` : ""}
		</span>`;
  };
  const htmlEditUserModal = () => {
    return html`<dc-modal
			.visible=${showEditModal}
			@close=${() => setShowEditModal(false)}
			.header=${i18next.t("Edit")}
			.content=${html`<div>
				<div class="flex space-x-4">
					<dc-select
						class="w-64"
						.label=${i18next.t("Payment Company")}
						.dataSource=${(text) => __async(void 0, null, function* () {
      if (text === void 0) {
        return i18next.t("Type at least 2 characters");
      } else if (text.length < 2) {
        return i18next.t("Type at least 2 characters");
      } else {
        const result = yield props.searchPsps(text);
        if (result.length > 0) {
          setPspsForAssignment(result);
          return result.map(toSelectItem);
        } else {
          return i18next.t("No result found");
        }
      }
    })}
						.filterable=${true}
						.debounceMs=${150}
						.selectedValues=${selectedPspForAssignment == null ? void 0 : selectedPspForAssignment.id}
						@change=${(e) => {
      setSelectedPspForAssignment(pspsForAssignment.find((cr) => cr.id === e.detail.selectedValue));
    }}
					></dc-select>
				</div>
				<div class="space-x-2 my-4">${currentUser == null ? void 0 : currentUser.changedPsps.map((psp) => htmlPspChip(psp, false, removePspFromAssignments))}</div>
				${props.isAdmin ? html`
							<div class="my-3">
								<dc-toggle
									.label=${i18next.t("Two-factor authentication")}
									.state=${(currentUser == null ? void 0 : currentUser.twoFactorEnabled) ? "Yes" : "No"}
									@change=${(e) => {
      setCurrentUser(__spreadProps(__spreadValues({}, currentUser), { twoFactorEnabled: e.detail.state === "Yes" }));
    }}
								></dc-toggle>
							</div>
					  ` : ""}
				<div>
					<button
						class="btn btn-primary m-auto"
						@click=${() => {
      setShowEditModal(false);
      const addedPsps = currentUser.changedPsps.filter((p) => !currentUser.origPsps.some((op) => op.id === p.id));
      const removedPsps = currentUser.origPsps.filter((c) => !currentUser.origPsps.some((op) => op.id === c.id));
      save(currentUser.userId, addedPsps, removedPsps, currentUser.twoFactorEnabled);
    }}
					>
						${i18next.t("Save")}
					</button>
				</div>
			</div>`}
		>
		</dc-modal>`;
  };
  const htmlUserPspsGrid = () => {
    var _a;
    const columns = [
      {
        field: UserPspsColumnNames.Email,
        label: i18next.t("Email"),
        sortable: true,
        filterable: false,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: UserPspsColumnNames.Psps,
        label: i18next.t("Psps"),
        filterable: false,
        sortable: false,
        filterDescriptor: { type: "string" },
        columnType: "string",
        columnClass: "w-2/3"
      },
      {
        field: UserPspsColumnNames.Details,
        columnClass: "w-48"
      }
    ];
    const vm = {
      columns,
      data: userssSearchResult == null ? void 0 : userssSearchResult.users,
      cellTemplate: (index, field) => {
        const item = userssSearchResult == null ? void 0 : userssSearchResult.users[index];
        if (field === UserPspsColumnNames.Email) {
          return item.email;
        } else if (field === UserPspsColumnNames.Psps) {
          return html`
						<div class="flex flex-wrap mt-2">
							${(item == null ? void 0 : item.psps) ? item.psps.map(
            (p) => htmlPspChip(p, item.isReadOnly, (p2) => __async(void 0, null, function* () {
              if (yield confirmModal.confirm(i18next.t("Are you sure to revoke access to this payment company?"))) {
                savePspAssignments(item.userId, [], [p2]);
              }
            }))
          ) : ""}
						</div>
					`;
        } else if (field === UserPspsColumnNames.Details) {
          return html`
						<div>
							<div class="flex space-x-4">
								<div class=${!item.isReadOnly && (!props.isAdmin || props.permissionManager.hasPermission("Cesop_Client_Modify")) ? "" : "hidden"}>
									<button
										class="btn btn-primary btn-sm"
										@click=${() => {
            setCurrentUser({
              userId: item.userId,
              twoFactorEnabled: item.twoFactorEnabled,
              origPsps: item.psps,
              changedPsps: item.psps
            });
            setShowEditModal(true);
          }}
									>
										${i18next.t("Edit")}
									</button>
								</div>
								${props.isAdmin && props.permissionManager.hasPermission("Cesop_Client_Modify_Change_Email") ? html`<div>
											<button
												class="btn btn-primary btn-sm"
												@click=${() => {
            setCurrentChangeEmailModel({ userId: item.userId, newEmail: "" });
            setShowEmailChangeModal(true);
          }}
											>
												${i18next.t("Change email")}
											</button>
									  </div>` : ""}
							</div>
						</div>
					`;
        } else {
          return "";
        }
      },
      cellClass: (_index, field) => {
        if (field === UserPspsColumnNames.Email || field === UserPspsColumnNames.Psps) {
          return "actions";
        }
        return "";
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: userssSearchResult == null ? void 0 : userssSearchResult.total
      }
    };
    return html`<div class="mt-4">
			<div class="flex justify-between mb-3 items-center">
				<div class="flex space-x-4">
					<div>${templateTitle(i18next.t("Existing Users"))}</div>
				</div>
				<div class="flex space-x-4 items-end">
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`<div>
								<button class="btn btn-primary whitespace-nowrap" @click=${() => setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: DEFAULT_FILTERS }))}>
									${i18next.t("Reset filters")}
								</button>
						  </div>` : ""}
				</div>
			</div>
			<div class="flex justify-start space-x-4">
				<dc-input
					.label=${i18next.t("Email")}
					.onInputDebounceMs=${250}
					@input=${(e) => {
      setEmailSearchFragment(e.detail.value);
    }}
				></dc-input
				><dc-input
					.label=${i18next.t("Payment Company")}
					.onInputDebounceMs=${250}
					@input=${(e) => {
      setPspSearchFragment(e.detail.value);
    }}
				></dc-input>
			</div>
			<div class="my-2 text-xs">${i18next.t("Type at least two characters for either email or payment company")}</div>
			${loading.count === 0 ? userssSearchResult ? html`<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>` : "" : `${i18next.t("Loading")}...`}
		</div>`;
  };
  const mainTemplate = html`
		<div class="mt-8">
			${!props.isAdmin || props.permissionManager.hasPermission("Cesop_Client_Invite") ? htmlInviteClient() : ""} ${htmlUserPspsGrid()} ${htmlEditUserModal()} ${htmlEmailChangeModal()}
			${confirmModal.mainTemplate()}
		</div>
	`;
  return { mainTemplate };
};
