export var PeriodSearchResultStatus = /* @__PURE__ */ ((PeriodSearchResultStatus2) => {
  PeriodSearchResultStatus2["AwaitingData"] = "AwaitingData";
  PeriodSearchResultStatus2["DataUploaded"] = "DataUploaded";
  PeriodSearchResultStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodSearchResultStatus2["ReadyToSubmit"] = "ReadyToSubmit";
  PeriodSearchResultStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodSearchResultStatus2["SubmittedToTa"] = "SubmittedToTa";
  PeriodSearchResultStatus2["AcceptedByCesop"] = "AcceptedByCesop";
  PeriodSearchResultStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  PeriodSearchResultStatus2["PreparingCorrection"] = "PreparingCorrection";
  return PeriodSearchResultStatus2;
})(PeriodSearchResultStatus || {});
export var PeriodSearchResultUploadType = /* @__PURE__ */ ((PeriodSearchResultUploadType2) => {
  PeriodSearchResultUploadType2["Csv"] = "Csv";
  PeriodSearchResultUploadType2["Xml"] = "Xml";
  PeriodSearchResultUploadType2["Empty"] = "Empty";
  return PeriodSearchResultUploadType2;
})(PeriodSearchResultUploadType || {});
