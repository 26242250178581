var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Name } from "./../Name";
import { Address } from "./Address";
import { AdditionalInfoFactory } from "./Extensions/AdditionalInfoFactory";
import { Registration } from "./../Registrations/Registration";
import { Subscription } from "./../Subscriptions/Subscription";
export class Psp {
  constructor(_data) {
    /**
     */
    __publicField(this, "AdditionalInfo");
    /**
     */
    __publicField(this, "Address");
    /**
     */
    __publicField(this, "Countries");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Identifier");
    /**
     */
    __publicField(this, "IdentifierType");
    /**
     */
    __publicField(this, "Name");
    /**
     */
    __publicField(this, "Registrations");
    /**
     */
    __publicField(this, "Subscriptions");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Psp
     */
    __publicField(this, "$type", "V1.Cesop.Psps.Psp");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AdditionalInfo"] && _data["AdditionalInfo"].constructor === Array) {
        this.AdditionalInfo = [];
        for (let item of _data["AdditionalInfo"]) {
          const $typeAdditionalInfo = item && item.$type;
          if ($typeAdditionalInfo) {
            const additionalInfo_ = AdditionalInfoFactory.create($typeAdditionalInfo);
            this.AdditionalInfo.push(additionalInfo_.init(item));
          }
        }
      }
      if (_data["Address"]) {
        const address_ = new Address();
        this.Address = address_.init(_data["Address"]);
      }
      if (_data["Countries"] && _data["Countries"].constructor === Array) {
        this.Countries = [];
        for (let item of _data["Countries"]) {
          this.Countries.push(item);
        }
      }
      this.Id = _data["Id"];
      this.Identifier = _data["Identifier"];
      this.IdentifierType = PspIdentifierType[_data["IdentifierType"]];
      if (_data["Name"]) {
        const name_ = new Name();
        this.Name = name_.init(_data["Name"]);
      }
      if (_data["Registrations"] && _data["Registrations"].constructor === Array) {
        this.Registrations = [];
        for (let item of _data["Registrations"]) {
          const registrations_ = new Registration();
          this.Registrations.push(registrations_.init(item));
        }
      }
      if (_data["Subscriptions"] && _data["Subscriptions"].constructor === Array) {
        this.Subscriptions = [];
        for (let item of _data["Subscriptions"]) {
          const subscriptions_ = new Subscription();
          this.Subscriptions.push(subscriptions_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.AdditionalInfo && this.AdditionalInfo.constructor === Array) {
      _data["AdditionalInfo"] = [];
      for (let item of this.AdditionalInfo) {
        _data["AdditionalInfo"].push(item.toJSON());
      }
    }
    _data["Address"] = this.Address ? this.Address.toJSON() : void 0;
    if (this.Countries && this.Countries.constructor === Array) {
      _data["Countries"] = [];
      for (let item of this.Countries) {
        _data["Countries"].push(item);
      }
    }
    _data["Id"] = this.Id;
    _data["Identifier"] = this.Identifier;
    _data["IdentifierType"] = PspIdentifierType[this.IdentifierType];
    _data["Name"] = this.Name ? this.Name.toJSON() : void 0;
    if (this.Registrations && this.Registrations.constructor === Array) {
      _data["Registrations"] = [];
      for (let item of this.Registrations) {
        _data["Registrations"].push(item.toJSON());
      }
    }
    if (this.Subscriptions && this.Subscriptions.constructor === Array) {
      _data["Subscriptions"] = [];
      for (let item of this.Subscriptions) {
        _data["Subscriptions"].push(item.toJSON());
      }
    }
    return _data;
  }
}
export var PspIdentifierType = /* @__PURE__ */ ((PspIdentifierType2) => {
  PspIdentifierType2["Bic"] = "Bic";
  PspIdentifierType2["Other"] = "Other";
  return PspIdentifierType2;
})(PspIdentifierType || {});
