var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { QuarterIdentifier } from "./QuarterIdentifier";
export class PeriodFilter {
  constructor(_data) {
    /**
     */
    __publicField(this, "PspName");
    /**
     */
    __publicField(this, "Quarters");
    /**
     */
    __publicField(this, "RegistrationIdentifier");
    /**
     */
    __publicField(this, "ReportingCountries");
    /**
     */
    __publicField(this, "Statuses");
    /**
     */
    __publicField(this, "VersionMax");
    /**
     */
    __publicField(this, "VersionMin");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PeriodFilter
     */
    __publicField(this, "$type", "V1.Cesop.Periods.Search.PeriodFilter");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.PspName = _data["PspName"];
      if (_data["Quarters"] && _data["Quarters"].constructor === Array) {
        this.Quarters = [];
        for (let item of _data["Quarters"]) {
          const quarters_ = new QuarterIdentifier();
          this.Quarters.push(quarters_.init(item));
        }
      }
      this.RegistrationIdentifier = _data["RegistrationIdentifier"];
      if (_data["ReportingCountries"] && _data["ReportingCountries"].constructor === Array) {
        this.ReportingCountries = [];
        for (let item of _data["ReportingCountries"]) {
          this.ReportingCountries.push(item);
        }
      }
      if (_data["Statuses"] && _data["Statuses"].constructor === Array) {
        this.Statuses = [];
        for (let item of _data["Statuses"]) {
          this.Statuses.push(PeriodFilterStatuses[item]);
        }
      }
      this.VersionMax = _data["VersionMax"];
      this.VersionMin = _data["VersionMin"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["PspName"] = this.PspName;
    if (this.Quarters && this.Quarters.constructor === Array) {
      _data["Quarters"] = [];
      for (let item of this.Quarters) {
        _data["Quarters"].push(item.toJSON());
      }
    }
    _data["RegistrationIdentifier"] = this.RegistrationIdentifier;
    if (this.ReportingCountries && this.ReportingCountries.constructor === Array) {
      _data["ReportingCountries"] = [];
      for (let item of this.ReportingCountries) {
        _data["ReportingCountries"].push(item);
      }
    }
    if (this.Statuses && this.Statuses.constructor === Array) {
      _data["Statuses"] = [];
      for (let item of this.Statuses) {
        _data["Statuses"].push(PeriodFilterStatuses[item]);
      }
    }
    _data["VersionMax"] = this.VersionMax;
    _data["VersionMin"] = this.VersionMin;
    return _data;
  }
}
export var PeriodFilterStatuses = /* @__PURE__ */ ((PeriodFilterStatuses2) => {
  PeriodFilterStatuses2["AwaitingData"] = "AwaitingData";
  PeriodFilterStatuses2["DataUploaded"] = "DataUploaded";
  PeriodFilterStatuses2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodFilterStatuses2["ReadyToSubmit"] = "ReadyToSubmit";
  PeriodFilterStatuses2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodFilterStatuses2["SubmittedToTa"] = "SubmittedToTa";
  PeriodFilterStatuses2["AcceptedByCesop"] = "AcceptedByCesop";
  PeriodFilterStatuses2["CorrectionNeeded"] = "CorrectionNeeded";
  PeriodFilterStatuses2["PreparingCorrection"] = "PreparingCorrection";
  return PeriodFilterStatuses2;
})(PeriodFilterStatuses || {});
