var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { FormatSettingsFactory } from "./FormatSettingsFactory";
import { CustomSourceReferenceFactory } from "./CustomSourceReferenceFactory";
import { CustomSourceConfigurationFactory } from "./CustomSourceConfigurationFactory";
export class GenerationConfiguration {
  constructor(_data) {
    /**
     */
    __publicField(this, "AvailableTemplates");
    /**
     */
    __publicField(this, "CompanyId");
    /**
     */
    __publicField(this, "ComplianceUploadGenerationMode");
    /**
     */
    __publicField(this, "CustomSourceConfiguration");
    /**
     */
    __publicField(this, "FormatSettings");
    /**
     */
    __publicField(this, "IsCustomSource");
    /**
     */
    __publicField(this, "SalePurchaseSeparately");
    /**
     */
    __publicField(this, "TemplateFileReference");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof GenerationConfiguration
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.GenerationConfiguration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AvailableTemplates"] && _data["AvailableTemplates"].constructor === Array) {
        this.AvailableTemplates = [];
        for (let item of _data["AvailableTemplates"]) {
          this.AvailableTemplates.push(GenerationConfigurationAvailableTemplates[item]);
        }
      }
      this.CompanyId = _data["CompanyId"];
      this.ComplianceUploadGenerationMode = GenerationConfigurationComplianceUploadGenerationMode[_data["ComplianceUploadGenerationMode"]];
      const $typeCustomSourceConfiguration = _data["CustomSourceConfiguration"] && _data["CustomSourceConfiguration"].$type;
      if ($typeCustomSourceConfiguration) {
        const customSourceConfiguration_ = CustomSourceConfigurationFactory.create($typeCustomSourceConfiguration);
        this.CustomSourceConfiguration = customSourceConfiguration_.init(_data["CustomSourceConfiguration"]);
      } else {
        this.CustomSourceConfiguration = _data["CustomSourceConfiguration"];
      }
      const $typeFormatSettings = _data["FormatSettings"] && _data["FormatSettings"].$type;
      if ($typeFormatSettings) {
        const formatSettings_ = FormatSettingsFactory.create($typeFormatSettings);
        this.FormatSettings = formatSettings_.init(_data["FormatSettings"]);
      } else {
        this.FormatSettings = _data["FormatSettings"];
      }
      this.IsCustomSource = _data["IsCustomSource"];
      this.SalePurchaseSeparately = _data["SalePurchaseSeparately"];
      const $typeTemplateFileReference = _data["TemplateFileReference"] && _data["TemplateFileReference"].$type;
      if ($typeTemplateFileReference) {
        const templateFileReference_ = CustomSourceReferenceFactory.create($typeTemplateFileReference);
        this.TemplateFileReference = templateFileReference_.init(_data["TemplateFileReference"]);
      } else {
        this.TemplateFileReference = _data["TemplateFileReference"];
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.AvailableTemplates && this.AvailableTemplates.constructor === Array) {
      _data["AvailableTemplates"] = [];
      for (let item of this.AvailableTemplates) {
        _data["AvailableTemplates"].push(GenerationConfigurationAvailableTemplates[item]);
      }
    }
    _data["CompanyId"] = this.CompanyId;
    _data["ComplianceUploadGenerationMode"] = GenerationConfigurationComplianceUploadGenerationMode[this.ComplianceUploadGenerationMode];
    _data["CustomSourceConfiguration"] = this.CustomSourceConfiguration ? this.CustomSourceConfiguration.toJSON() : void 0;
    _data["FormatSettings"] = this.FormatSettings ? this.FormatSettings.toJSON() : void 0;
    _data["IsCustomSource"] = this.IsCustomSource;
    _data["SalePurchaseSeparately"] = this.SalePurchaseSeparately;
    _data["TemplateFileReference"] = this.TemplateFileReference ? this.TemplateFileReference.toJSON() : void 0;
    return _data;
  }
}
export var GenerationConfigurationAvailableTemplates = /* @__PURE__ */ ((GenerationConfigurationAvailableTemplates2) => {
  GenerationConfigurationAvailableTemplates2["Amazon"] = "Amazon";
  GenerationConfigurationAvailableTemplates2["Mp"] = "Mp";
  GenerationConfigurationAvailableTemplates2["CustomTemplate1"] = "CustomTemplate1";
  GenerationConfigurationAvailableTemplates2["CustomTemplate2"] = "CustomTemplate2";
  GenerationConfigurationAvailableTemplates2["Csv"] = "Csv";
  GenerationConfigurationAvailableTemplates2["Dsv"] = "Dsv";
  GenerationConfigurationAvailableTemplates2["Excel"] = "Excel";
  GenerationConfigurationAvailableTemplates2["SAPText"] = "SAPText";
  return GenerationConfigurationAvailableTemplates2;
})(GenerationConfigurationAvailableTemplates || {});
export var GenerationConfigurationComplianceUploadGenerationMode = /* @__PURE__ */ ((GenerationConfigurationComplianceUploadGenerationMode2) => {
  GenerationConfigurationComplianceUploadGenerationMode2["PerCountry"] = "PerCountry";
  GenerationConfigurationComplianceUploadGenerationMode2["AllCountryInOne"] = "AllCountryInOne";
  return GenerationConfigurationComplianceUploadGenerationMode2;
})(GenerationConfigurationComplianceUploadGenerationMode || {});
