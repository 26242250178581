var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TaxCodeTerm } from "./TaxCodeTerm";
export class Condition {
  constructor(_data) {
    /**
     */
    __publicField(this, "Terms");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Condition
     */
    __publicField(this, "$type", "V1.TaxEngine.Condition");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Terms"] && _data["Terms"].constructor === Array) {
        this.Terms = [];
        for (let item of _data["Terms"]) {
          const terms_ = new TaxCodeTerm();
          this.Terms.push(terms_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.Terms && this.Terms.constructor === Array) {
      _data["Terms"] = [];
      for (let item of this.Terms) {
        _data["Terms"].push(item.toJSON());
      }
    }
    return _data;
  }
}
