import { formatDate } from "GlobalShared/helpers/dateHelper";
import moment from "moment";
const YEARLY_PERIOD_COUNT = 5;
const INTERVALS = {
  Monthly: 1,
  Yearly: 12,
  BiMonthly: 2,
  Quarterly: 3,
  QuarterlyShifted1: 3,
  QuarterlyShifted2: 3,
  FourMonthly: 4,
  SixMonthly: 6
};
export const generateMonthlyPeriods = () => {
  const result = [];
  const actualYear = (/* @__PURE__ */ new Date()).getFullYear();
  const actualMonth = (/* @__PURE__ */ new Date()).getMonth();
  const startingDate = moment(new Date(actualYear - YEARLY_PERIOD_COUNT, 0, 1));
  let currentDate = moment(new Date(actualYear, actualMonth, 1));
  while (startingDate <= currentDate) {
    result.push({ startDate: currentDate, freq: "Monthly" });
    currentDate = moment(currentDate).subtract(1, "months");
  }
  return result.map((p) => ({
    label: formatPeriod(p.startDate, p.freq),
    value: `${formatDate(p.startDate)},${p.freq}`
  }));
};
const getLastMonthlyPeriod = (actualYear, actualMonthIndex) => {
  if (actualMonthIndex - INTERVALS.Monthly < 0) {
    return formatPeriod(moment(new Date(actualYear - 1, 11, 1)), "Monthly");
  }
  return formatPeriod(moment(new Date(actualYear, actualMonthIndex, 1)).subtract(1, "month"), "Monthly");
};
const getLastBiMonthlyPeriod = (actualYear, actualMonthIndex) => {
  if (actualMonthIndex === 0 || actualMonthIndex === 1) {
    return formatPeriod(moment(new Date(actualYear - 1, 10, 1)), "BiMonthly");
  } else {
    if (actualMonthIndex % INTERVALS.BiMonthly === 0) {
      return formatPeriod(moment(new Date(actualYear, actualMonthIndex - INTERVALS.BiMonthly, 1)), "BiMonthly");
    } else {
      return formatPeriod(moment(new Date(actualYear, actualMonthIndex - 3, 1)), "BiMonthly");
    }
  }
};
const getLastQuarterlyPeriod = (actualYear, actualMonthIndex) => formatPeriod(moment(new Date(actualYear, actualMonthIndex, 1)).subtract(INTERVALS.Quarterly, "months").startOf("quarter"), "Quarterly");
const getLastQuarterlyShifted1Period = (actualYear, actualMonthIndex) => formatPeriod(moment(new Date(actualYear, actualMonthIndex, 1)).subtract(INTERVALS.Quarterly, "months").startOf("quarter").subtract(1, "month"), "Quarterly");
const getLastQuarterlyShifted2Period = (actualYear, actualMonthIndex) => formatPeriod(moment(new Date(actualYear, actualMonthIndex, 1)).subtract(INTERVALS.Quarterly, "months").startOf("quarter").subtract(2, "month"), "Quarterly");
const getLastFourMonthlyPeriod = (actualYear, actualMonthIndex) => {
  if (actualMonthIndex < 4) {
    return formatPeriod(moment(new Date(actualYear - 1, 8, 1)), "FourMonthly");
  } else if (actualMonthIndex < 8) {
    return formatPeriod(moment(new Date(actualYear, 0, 1)), "FourMonthly");
  }
  return formatPeriod(moment(new Date(actualYear, 4, 1)), "FourMonthly");
};
const getLastSixMonthlyPeriod = (actualYear, actualMonthIndex) => {
  if (actualMonthIndex >= 6) {
    return formatPeriod(moment(new Date(actualYear, actualMonthIndex, 1)).subtract(INTERVALS.SixMonthly, "months").startOf("year"), "SixMonthly");
  } else {
    return formatPeriod(moment(new Date(actualYear - 1, 6, 1)), "SixMonthly");
  }
};
const getLastYearlyPeriod = (actualYear, actualMonthIndex) => formatPeriod(moment(new Date(actualYear, actualMonthIndex, 1)).subtract(1, "year"), "Yearly");
export const getlastPeriodFilters = () => {
  const result = [];
  const actualYear = (/* @__PURE__ */ new Date()).getFullYear();
  const actualMonthIndex = (/* @__PURE__ */ new Date()).getMonth();
  result.push(getLastMonthlyPeriod(actualYear, actualMonthIndex));
  result.push(getLastBiMonthlyPeriod(actualYear, actualMonthIndex));
  result.push(getLastQuarterlyPeriod(actualYear, actualMonthIndex));
  result.push(getLastQuarterlyShifted1Period(actualYear, actualMonthIndex));
  result.push(getLastQuarterlyShifted2Period(actualYear, actualMonthIndex));
  result.push(getLastFourMonthlyPeriod(actualYear, actualMonthIndex));
  result.push(getLastSixMonthlyPeriod(actualYear, actualMonthIndex));
  result.push(getLastYearlyPeriod(actualYear, actualMonthIndex));
  return result;
};
export const generateAllPeriods = () => {
  const result = [];
  const actualYear = (/* @__PURE__ */ new Date()).getFullYear();
  const actualMonth = (/* @__PURE__ */ new Date()).getMonth();
  const startingDate = moment(new Date(actualYear - YEARLY_PERIOD_COUNT, 0, 1));
  let currentDate = moment(new Date(actualYear, actualMonth, 1));
  while (startingDate <= currentDate) {
    const currentMonth = currentDate.month();
    result.push({ startDate: currentDate, freq: "Monthly" });
    if (currentMonth % INTERVALS.Yearly === 0) {
      result.push({ startDate: currentDate, freq: "Yearly" });
    }
    if (currentMonth % INTERVALS.BiMonthly === 0) {
      result.push({ startDate: currentDate, freq: "BiMonthly" });
    }
    if (currentMonth % INTERVALS.Quarterly === 0) {
      result.push({ startDate: currentDate, freq: "Quarterly" });
    }
    if ((currentMonth - 1) % INTERVALS.QuarterlyShifted1 === 0) {
      result.push({ startDate: currentDate, freq: "QuarterlyShifted1" });
    }
    if ((currentMonth - 2) % INTERVALS.QuarterlyShifted2 === 0) {
      result.push({ startDate: currentDate, freq: "QuarterlyShifted2" });
    }
    if (currentMonth % INTERVALS.FourMonthly === 0) {
      result.push({ startDate: currentDate, freq: "FourMonthly" });
    }
    if (currentMonth % INTERVALS.SixMonthly === 0) {
      result.push({ startDate: currentDate, freq: "SixMonthly" });
    }
    currentDate = moment(currentDate).subtract(1, "months");
  }
  return result.map((p) => ({
    label: formatPeriod(p.startDate, p.freq),
    value: formatPeriod(moment(p.startDate).locale("en"), p.freq)
  }));
};
export const formatPeriod = (startDate, freq) => {
  if (freq === "Monthly") {
    return startDate.format("MMM-YYYY");
  } else if (freq === "Yearly") {
    return startDate.format("YYYY");
  }
  const endDate = getPeriodEndDate(startDate, freq);
  return `${startDate.format("MMM")}-${endDate.format("MMM")}-${startDate.format("YYYY")}`;
};
const getPeriodEndDate = (startDate, freq) => {
  return moment(startDate).add(INTERVALS[freq], "months").add(-1, "day");
};
