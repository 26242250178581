var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Q11eValidator } from "GlobalShared/components/q11e/Q11eValidator";
import { useState, useQueryState, useEffect, useClient, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { useEUCountries, useWorldCountries, useBirthCountries } from "WebComponents/hooks/WebCustomHooks";
import { Questionnaire as QuestionnaireClient, Company as CompanyClient } from "WebServices/APIs/self/clients";
import { QUESTIONS } from "GlobalShared/components/q11e/questions";
import { QuestionnaireContext } from "GlobalShared/components/q11e/QuestionnaireContext";
import { convertAnswerEvent } from "GlobalShared/components/q11e/answer-utils";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { currentLocale } from "../../globals";
import { useConfirmModal } from "GlobalShared/components/modals/commonModals";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import classNames from "classnames";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { DEFAULT_QUESTIONNAIRE_STEPS, getGroupsAndValidations, QuestionnaireStepLabels } from "GlobalShared/components/q11e/QuestionnaireHelper";
export function AdminQuestionnaire() {
  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };
  const previousStep = () => {
    setCurrentStep(currentStep > 0 ? currentStep - 1 : currentStep);
  };
  const onAnswerChanged = (e) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const answer = convertAnswerEvent(e);
        yield questionnaireClient.saveModification({ body: [answer] });
        setContext(context.getNewContextWithSetAnswer(answer));
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const confirmFinalization = () => __async(this, null, function* () {
    const isConfirmed = yield confirmModal.confirm(i18next.t("questionnaire-finalization-confirmation"));
    return isConfirmed;
  });
  const finalize = () => __async(this, null, function* () {
    if (!(context == null ? void 0 : context.isReadonly())) {
      yield questionnaireClient.saveModification({ body: context.getAnswers() });
      window.setTimeout(() => {
        if (q11eValidationContext.validate(context).isValid()) {
          withErrorHandling(
            () => __async(this, null, function* () {
              const isConfirmed = yield confirmFinalization();
              if (!isConfirmed) {
                return;
              }
              yield questionnaireClient.finalizeQuestionnaire();
              setShowSuccessNotification(true);
              window.location.reload();
            }),
            (error) => {
              notifyError(error);
            }
          );
        } else {
          setShowValidationModal(true);
        }
      }, 100);
    }
  });
  const isStepValid = (step, invalidAccordionsByStep) => {
    var _a, _b;
    const questionnaireStep = (_a = Object.entries(QuestionnaireStepLabels).find(([_, value]) => value === step.stepName)) == null ? void 0 : _a[0];
    return !((_b = invalidAccordionsByStep == null ? void 0 : invalidAccordionsByStep.get(questionnaireStep)) == null ? void 0 : _b.length);
  };
  const hasFailureMessage = (step) => q11eValidationContext.validationResult.getFailures().filter((failure) => QUESTIONS.find((question) => question.Id === failure.propertyName && question.Step === step)).length > 0;
  const confirmModal = useConfirmModal({
    confirmText: i18next.t("Finalize Questionnaire"),
    cancelText: i18next.t("Cancel")
  });
  const [questionnaire, setQuestionnaire] = useState(void 0);
  const [company, setCompany] = useState(void 0);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [context, setContext] = useState(void 0);
  const worldCountries = useWorldCountries(currentLocale);
  const euCountries = useEUCountries(currentLocale);
  const birthCountries = useBirthCountries(currentLocale);
  const [currentStep, setCurrentStep] = useQueryState("step", 0);
  const questionnaireClient = useClient(QuestionnaireClient);
  const companyClient = useClient(CompanyClient);
  const [q11eValidator, setQ11eValidator] = useState(new Q11eValidator(context));
  const q11eValidationContext = useValidationContext(q11eValidator);
  const [showSuccessNotification, setShowSuccessNotification] = useQueryState("qSuccess", false);
  const [groupsAndValidations, setGroupsAndValidations] = useState(void 0);
  const [questionnaireSteps, setQuestionnaireSteps] = useState(DEFAULT_QUESTIONNAIRE_STEPS);
  const init = () => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        const questionnaireResponse = yield questionnaireClient.getQuestionnaire();
        setQuestionnaire(questionnaireResponse.data);
        const companyResponse = yield companyClient.getCompanyDetail({
          locale: currentLocale
        });
        setCompany(companyResponse.data);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  useEffect(init, []);
  useEffect(() => {
    if (showSuccessNotification) {
      notifySuccess(i18next.t("questionnaire-finalization-success"));
      setShowSuccessNotification(false);
    }
  }, []);
  useEffect(() => {
    q11eValidationContext.validate(context);
  }, [q11eValidator]);
  useEffect(() => {
    if (context !== void 0) {
      const groupsAndValidationsArray = getGroupsAndValidations(q11eValidationContext.validationResult.getFailures());
      setGroupsAndValidations(groupsAndValidationsArray);
      setQuestionnaireSteps(questionnaireSteps.map((step) => __spreadProps(__spreadValues({}, step), { validity: isStepValid(step, groupsAndValidationsArray) ? "valid" : "invalid" })));
    }
  }, [q11eValidationContext.validationResult]);
  useEffect(() => {
    if (context !== void 0) {
      setQ11eValidator(new Q11eValidator(context));
    }
  }, [context]);
  useEffect(() => {
    if (questionnaire && euCountries && company) {
      setContext(new QuestionnaireContext(questionnaire, false, euCountries, QUESTIONS, company, false));
    }
  }, [questionnaire, euCountries, company]);
  const getValidationResultTemplateByStep = (step) => hasFailureMessage(step) ? html`
					<div class="flex flex-col gap-4">
						<div class="font-lg font-bold">${`${QuestionnaireStepLabels[step]}`}</div>
						<ul class="flex flex-col gap-2 list-disc marker:text-brand-primary">
							${q11eValidationContext.validationResult.getFailures().map((failure) => {
    var _a;
    const questionLabel = (_a = QUESTIONS.find((question) => question.Id === failure.propertyName && question.Step === step)) == null ? void 0 : _a.Label;
    return questionLabel ? html`<li class="text-red-400 ml-8">${questionLabel}: ${failure.message}</li>` : "";
  })}
						</ul>
					</div>
			  ` : "";
  const templateValidationModalContent = () => html`
			<div class="flex flex-col gap-4 px-8 pb-4">
				${getValidationResultTemplateByStep("business-details")} ${getValidationResultTemplateByStep("tax-details")} ${getValidationResultTemplateByStep("country-details")}
				${getValidationResultTemplateByStep("personnel-details")} ${getValidationResultTemplateByStep("banking-information")}
			</div>
		`;
  const templateValidationModal = () => html`
			<div style="max-width: 300px">
				<dc-modal .visible=${showValidationModal} @close=${() => setShowValidationModal(false)} .header=${i18next.t("Validation errors")} .content=${templateValidationModalContent()}>
				</dc-modal>
			</div>
		`;
  const templateBusinessDetails = () => html`
			<ww-q11e-business-details
				.q11e=${context}
				.worldCountries=${worldCountries}
				.euCountries=${euCountries}
				.invalidAccordions=${groupsAndValidations == null ? void 0 : groupsAndValidations.get("business-details")}
				@change=${onAnswerChanged}
			></ww-q11e-business-details>
		`;
  const templateTaxDetails = () => html`
			<ww-q11e-tax-details
				.q11e=${context}
				.worldCountries=${worldCountries}
				.euCountries=${euCountries}
				.invalidAccordions=${groupsAndValidations == null ? void 0 : groupsAndValidations.get("tax-details")}
				@change=${onAnswerChanged}
			></ww-q11e-tax-details>
		`;
  const templateCountryDetails = () => html`
			<ww-q11e-country-details
				.q11e=${context}
				.worldCountries=${worldCountries}
				.euCountries=${euCountries}
				.invalidAccordions=${groupsAndValidations == null ? void 0 : groupsAndValidations.get("country-details")}
				@change=${onAnswerChanged}
			></ww-q11e-country-details>
		`;
  const templatePersonnelDetails = () => html`
			<ww-q11e-personnel-details
				.q11e=${context}
				.birthCountries=${birthCountries}
				.worldCountries=${worldCountries}
				.invalidAccordions=${groupsAndValidations == null ? void 0 : groupsAndValidations.get("personnel-details")}
				.onAnswerChanged=${onAnswerChanged}
			></ww-q11e-personnel-details>
		`;
  const templateBankingInformation = () => html`
			<ww-q11e-banking-information
				.q11e=${context}
				.worldCountries=${worldCountries}
				.invalidAccordions=${groupsAndValidations == null ? void 0 : groupsAndValidations.get("banking-information")}
				@change=${onAnswerChanged}
			></ww-q11e-banking-information>
		`;
  const finalizeButtonTemplate = () => PrimaryButton({
    text: (context == null ? void 0 : context.isReadonly()) ? i18next.t("Finalized") : i18next.t("Confirm and Finalize"),
    onClick: finalize,
    class: "max-w-fit ml-auto mt-8",
    disabled: context == null ? void 0 : context.isReadonly()
  });
  return context ? html`
				<div class="my-2">
					<div class="m-auto max-w-5xl">
						<div class="flex justify-around w-full mt-12 mb-4">
							<dc-step-progressbar
								.items=${questionnaireSteps}
								.activeIndex=${currentStep}
								.inactiveClickable=${true}
								.showValidationIcons=${true}
								@click=${(e) => {
    setCurrentStep(e.detail.step);
  }}
							></dc-step-progressbar>
						</div>
						${finalizeButtonTemplate()} ${currentStep === 0 ? templateBusinessDetails() : ""} ${currentStep === 1 ? templateTaxDetails() : ""}
						${currentStep === 2 ? templateCountryDetails() : ""} ${currentStep === 3 ? templatePersonnelDetails() : ""} ${currentStep === 4 ? templateBankingInformation() : ""}
						<div class="my-8 flex w-full justify-between">
							${SecondaryButton({ text: i18next.t("Previous"), onClick: previousStep, class: classNames("w-28", { invisible: currentStep === 0 }) })}
							${PrimaryButton({ text: i18next.t("Next"), onClick: nextStep, class: classNames("w-28", { invisible: currentStep === 4 }) })}
						</div>
					</div>
					${templateValidationModal()} ${confirmModal.mainTemplate()}
				</div>
		  ` : html`<dc-loader .loading=${true}></dc-loader>`;
}
