export var PeriodStatus = /* @__PURE__ */ ((PeriodStatus2) => {
  PeriodStatus2["AwaitingData"] = "AwaitingData";
  PeriodStatus2["DataUploaded"] = "DataUploaded";
  PeriodStatus2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodStatus2["ReadyToSubmit"] = "ReadyToSubmit";
  PeriodStatus2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodStatus2["SubmittedToTa"] = "SubmittedToTa";
  PeriodStatus2["AcceptedByCesop"] = "AcceptedByCesop";
  PeriodStatus2["CorrectionNeeded"] = "CorrectionNeeded";
  PeriodStatus2["PreparingCorrection"] = "PreparingCorrection";
  return PeriodStatus2;
})(PeriodStatus || {});
export var PeriodRegistrationIdentifierType = /* @__PURE__ */ ((PeriodRegistrationIdentifierType2) => {
  PeriodRegistrationIdentifierType2["Bic"] = "Bic";
  PeriodRegistrationIdentifierType2["Other"] = "Other";
  return PeriodRegistrationIdentifierType2;
})(PeriodRegistrationIdentifierType || {});
