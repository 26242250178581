import { PeriodVersionUploadType } from "WebServices/APIs/self/models/V1/Cesop/Periods/PeriodVersionAbstraction";
import { useMemo } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
export const useUploadTypes = () => {
  return useMemo(() => {
    return [
      { label: i18next.t("CSV"), value: PeriodVersionUploadType.Csv },
      { label: i18next.t("XML"), value: PeriodVersionUploadType.Xml },
      { label: i18next.t("No transactions"), value: PeriodVersionUploadType.Empty }
    ];
  }, []);
};
