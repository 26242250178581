var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import "./dc-dropdown.scss";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
import classNames from "classnames";
const DEFAULTS = {
  dataSource: [],
  selectedValues: void 0,
  selectedIndices: void 0,
  readonly: false,
  align: "left",
  tooltip: "",
  isStatusLike: false,
  buttonText: "",
  class: "",
  borderless: false
};
export class DropdownChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail });
  }
}
export const Component = (host) => {
  const props = {
    label: host.label,
    dataSource: host.dataSource !== void 0 ? host.dataSource : DEFAULTS.dataSource,
    validationMessage: host.validationMessage,
    selectedValues: host.selectedValues !== void 0 ? host.selectedValues : DEFAULTS.selectedValues,
    selectedIndices: host.selectedIndices !== void 0 ? host.selectedIndices : DEFAULTS.selectedIndices,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly,
    align: host.align !== void 0 ? host.align : DEFAULTS.align,
    tooltip: host.tooltip !== void 0 ? host.tooltip : DEFAULTS.tooltip,
    placeholder: host.placeholder !== void 0 ? host.placeholder : DEFAULTS.placeholder,
    name: host.name,
    isStatusLike: host.isStatusLike !== void 0 ? host.isStatusLike : DEFAULTS.isStatusLike,
    buttonText: host.buttonText !== void 0 ? host.buttonText : DEFAULTS.buttonText,
    class: host.class !== void 0 ? host.class : DEFAULTS.class,
    borderless: host.borderless !== void 0 ? host.borderless : DEFAULTS.borderless
  };
  const getSelectedValues = () => {
    var _a;
    let tempSelectedValues;
    if (typeof props.selectedValues === "string") {
      tempSelectedValues = [props.selectedValues];
    } else if (typeof props.selectedValues === "number") {
      tempSelectedValues = [props.selectedValues.toString()];
    } else {
      tempSelectedValues = ((_a = props.selectedValues) != null ? _a : []).map((selVal) => typeof selVal === "number" ? selVal.toString() : selVal);
    }
    return tempSelectedValues;
  };
  const getClasses = (defaultClass, customClasses) => {
    const classes = Array.isArray(customClasses) ? customClasses : [customClasses];
    return classes.filter((e) => e).reduce(
      (previousValue, currentValue) => {
        previousValue[currentValue] = true;
        return previousValue;
      },
      { [defaultClass]: true }
    );
  };
  const [dataSource, setDataSource] = useState([]);
  const [opened, setOpened] = useState(false);
  const init = () => {
    const onClickedOutside = (e) => {
      if (!host.contains(e.target)) {
        setOpened(false);
      }
    };
    const onCloseOnEscKey = (e) => {
      if (e.key === "Escape") {
        setOpened(false);
      }
    };
    document.addEventListener("keyup", onCloseOnEscKey, true);
    document.addEventListener("click", onClickedOutside, true);
    return () => {
      document.removeEventListener("keyup", onCloseOnEscKey);
      document.removeEventListener("click", onClickedOutside);
    };
  };
  useEffect(init, []);
  useEffect(() => {
    if (typeof props.dataSource !== "function" && !(props.dataSource instanceof Promise)) {
      const tempDataSource = props.dataSource.map((item) => {
        let tempItem;
        if (typeof item === "string") {
          tempItem = { label: item, value: item };
        } else if (typeof item === "number") {
          tempItem = { label: item.toString(), value: item.toString() };
        } else if (item.hasOwnProperty("label")) {
          tempItem = { label: item.label, value: item.value };
        } else {
          tempItem = item;
        }
        return tempItem;
      });
      setDataSource(tempDataSource);
    }
  }, [props.dataSource]);
  const buttonClicked = (index) => {
    setOpened(false);
    if (!getSelectedValues().includes(dataSource[index].value)) {
      host.dispatchEvent(
        new DropdownChangeEvent({
          selectedIndex: index,
          selectedValue: index !== void 0 ? dataSource[index].value : void 0
        })
      );
    }
  };
  const labelTemplate = () => props.label !== void 0 ? html` <label class="form-label"> ${props.label}${props.tooltip !== void 0 && props.tooltip.length > 0 ? html`<dc-tooltip .label=${props.tooltip}></dc-tooltip>` : ""} </label> ` : "";
  const inputTemplate = () => html`
			<button class=${classNames("select-button", { "text-brand-primary font-semibold hover:text-brand-primary-dark": props.borderless })} ?disabled=${props.readonly}>
				${props.buttonText}
			</button>
		`;
  return html`
		${labelTemplate()}
		<div
			class=${classNames(__spreadProps(__spreadValues({}, getClasses("dc-dropdown rounded-md", props.class)), {
    opened,
    "readonly": props.readonly,
    "status-like": props.isStatusLike,
    "borderless flex gap-2 relative": props.borderless
  }))}
			@click=${(e) => {
    e.stopPropagation();
    if (!props.readonly) {
      setOpened(!opened);
    }
  }}
		>
			${inputTemplate()} ${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
			${opened ? html`
						<div class="select-items ${props.align === "left" ? "left-align" : "right-align"}">
							${dataSource.map(
    (item, index) => html`
									<div
										@click=${(e) => {
      e.stopPropagation();
      buttonClicked(index);
    }}
										class="item"
									>
										${item.label}
									</div>
								`
  )}
						</div>
				  ` : ""}
			${props.borderless ? html`<div class="accordion-arrow absolute w-2.5 h-2.5 border border-solid border-gray-800 common-transition border-r-0 border-b-0 border-t-2 border-l-2"></div>` : ""}
		</div>
	`;
};
customElements.define(
  "dc-dropdown",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
