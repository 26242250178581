var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateTimeUtcRange } from "./../../Common/DateTime/DateTimeUtcRange";
import { AmountRange } from "./../../AmountRange";
export class Filter {
  constructor(_data) {
    /**
     */
    __publicField(this, "ConfirmedAt");
    /**
     */
    __publicField(this, "ContractId");
    /**
     */
    __publicField(this, "CreatedAt");
    /**
     */
    __publicField(this, "OrderPaymentStatuses");
    /**
     */
    __publicField(this, "Statuses");
    /**
     */
    __publicField(this, "TotalPrice");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Filter
     */
    __publicField(this, "$type", "V1.Orders.Search.Filter");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["ConfirmedAt"]) {
        const confirmedAt_ = new DateTimeUtcRange();
        this.ConfirmedAt = confirmedAt_.init(_data["ConfirmedAt"]);
      }
      this.ContractId = _data["ContractId"];
      if (_data["CreatedAt"]) {
        const createdAt_ = new DateTimeUtcRange();
        this.CreatedAt = createdAt_.init(_data["CreatedAt"]);
      }
      if (_data["OrderPaymentStatuses"] && _data["OrderPaymentStatuses"].constructor === Array) {
        this.OrderPaymentStatuses = [];
        for (let item of _data["OrderPaymentStatuses"]) {
          this.OrderPaymentStatuses.push(FilterOrderPaymentStatuses[item]);
        }
      }
      if (_data["Statuses"] && _data["Statuses"].constructor === Array) {
        this.Statuses = [];
        for (let item of _data["Statuses"]) {
          this.Statuses.push(FilterStatuses[item]);
        }
      }
      if (_data["TotalPrice"]) {
        const totalPrice_ = new AmountRange();
        this.TotalPrice = totalPrice_.init(_data["TotalPrice"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ConfirmedAt"] = this.ConfirmedAt ? this.ConfirmedAt.toJSON() : void 0;
    _data["ContractId"] = this.ContractId;
    _data["CreatedAt"] = this.CreatedAt ? this.CreatedAt.toJSON() : void 0;
    if (this.OrderPaymentStatuses && this.OrderPaymentStatuses.constructor === Array) {
      _data["OrderPaymentStatuses"] = [];
      for (let item of this.OrderPaymentStatuses) {
        _data["OrderPaymentStatuses"].push(FilterOrderPaymentStatuses[item]);
      }
    }
    if (this.Statuses && this.Statuses.constructor === Array) {
      _data["Statuses"] = [];
      for (let item of this.Statuses) {
        _data["Statuses"].push(FilterStatuses[item]);
      }
    }
    _data["TotalPrice"] = this.TotalPrice ? this.TotalPrice.toJSON() : void 0;
    return _data;
  }
}
export var FilterOrderPaymentStatuses = /* @__PURE__ */ ((FilterOrderPaymentStatuses2) => {
  FilterOrderPaymentStatuses2["None"] = "None";
  FilterOrderPaymentStatuses2["Pending"] = "Pending";
  FilterOrderPaymentStatuses2["Approved"] = "Approved";
  return FilterOrderPaymentStatuses2;
})(FilterOrderPaymentStatuses || {});
export var FilterStatuses = /* @__PURE__ */ ((FilterStatuses2) => {
  FilterStatuses2["Pending"] = "Pending";
  FilterStatuses2["Cancelled"] = "Cancelled";
  FilterStatuses2["Draft"] = "Draft";
  FilterStatuses2["Confirmed"] = "Confirmed";
  FilterStatuses2["Abandoned"] = "Abandoned";
  return FilterStatuses2;
})(FilterStatuses || {});
