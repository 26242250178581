import * as AC from "GlobalShared/components/q11e/all-components";
export const convertAnswerEvent = (e) => {
  let newAnswer;
  if (e instanceof AC.AnswerString.StringChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerBool.BoolChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerAddress.AddressChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerMoney.MoneyChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerDate.DateChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerFileRef.FileRefChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerMultiString.MultiStringChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerName.NameChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerExistingVatNumbers.ExistingVatNumbersChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerLegalRepresentatives.LegalRepsChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerBusinessOwners.BusinessOwnersChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerBeneficialOwners.BeneficialOwnersChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerBankInformation.BankInformationChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerCountry.CountryChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerVatNumber.VatNumberChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerFixedEstablishments.FixedEstablishmentChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerOssDetail.OssDetailChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerMultiCountry.MultiCountryChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerAccountingLocation.AccountingLocationChangeChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerLegalEntityForm.LegalEntityFormChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerLegalEntityType.LegalEntityTypeChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerProductType.LegalEntityFormChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerClientBase.ClientBaseChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerTurnOvers.TurnOversChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerOrganisationType.OrganisationTypeChangeEvent) {
    newAnswer = e.detail.answer;
  } else if (e instanceof AC.AnswerPercentage.PercentageChangeEvent) {
    newAnswer = e.detail.answer;
  }
  return newAnswer;
};
