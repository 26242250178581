var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { MovingSubReason } from "./MovingSubReason";
import { LeavingReason } from "./LeavingReason";
export class MovingReason extends LeavingReason {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "Reasons");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof MovingReason
     */
    __publicField(this, "$type", "V1.Companies.Subscriptions.Feedbacks.MovingReason");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["Reasons"] && _data["Reasons"].constructor === Array) {
        this.Reasons = [];
        for (let item of _data["Reasons"]) {
          const reasons_ = new MovingSubReason();
          this.Reasons.push(reasons_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    if (this.Reasons && this.Reasons.constructor === Array) {
      _data["Reasons"] = [];
      for (let item of this.Reasons) {
        _data["Reasons"].push(item.toJSON());
      }
    }
    return _data;
  }
}
