var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { SourceFile } from "./../models/V1/Cesop/Periods/SourceFile";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class SourceFiles {
  /**
   * Creates an instance of SourceFiles.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof SourceFiles
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetSourceFileRq_ _getSourceFileRq
   * @returns Promise<GetSourceFileRq_>
   * @memberof SourceFiles
   */
  getSourceFile(pGetSourceFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/SourceFiles/{id}", "GET", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pGetSourceFileRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SourceFile },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param FileUploadedRq_ _fileUploadedRq
   * @returns Promise<FileUploadedRq_>
   * @memberof SourceFiles
   */
  fileUploaded(pFileUploadedRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/SourceFiles/{id}", "PATCH", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pFileUploadedRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteFileRq_ _deleteFileRq
   * @returns Promise<DeleteFileRq_>
   * @memberof SourceFiles
   */
  deleteFile(pDeleteFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/cesop/SourceFiles/{id}", "DELETE", "application/json; charset=UTF-8", "application/json", options);
    client.addPathParams([{ name: "id", value: pDeleteFileRq.id, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
