export const ADDITIONAL_TAX_SERVICES_HEADER = [["Task", "Comments"]];
export const ADDITIONAL_TAX_SERVICES = [
  ["Country specific VAT compliance", "Preparation and submission of VAT returns, EC Sales and/or Purchase Lists and VAT local listings (where required)."],
  ["VAT registration/takeover", "Arranging the VAT registration/re-registration or takeover."],
  ["Fiscal representation", "Some Sellers have to appoint a fiscal representative in some certain countries. We can provide this service for a fixed annual fee."],
  [
    "Intrastat threshold check",
    "In case your company whish us to check if the Intrastat threshold for your company in a given country has been exceeded, we can offer it as an additional service. (Both directions included)"
  ],
  [
    "Intrastat reports",
    "In case your company exceeds an Intrastat threshold (please note that you have to notify Tax Desk in this regard, as Tax Desk is not responsible for monitoring the Intrastat threshold), Tax Desk can assist you with the preparation and submission of the required monthly reports. The reports for dispatches and arrivals are considered two separate reports and will be charged separately."
  ],
  ["Retrospective returns", "If you have to submit historic VAT returns, Tax Desk can assist you with its submission"],
  // [
  // 	"Filing agent fee in DE",
  // 	"Non-EU incorporated Sellers have to appoint a German established filing agent. We can provide this service for a fixed annual fee.",
  // ],
  // [
  // 	"EU One-Stop-Shop (OSS) VAT registration and compliance",
  // 	"EU OSS VAT registration (if required), preparation and submission of quarterly OSS VAT returns.",
  // ],
  // [
  // 	"EU Import-One-Stop-Shop (IOSS) VAT registration and compliance",
  // 	"EU  IOSS  VAT  registration  (if  required),  preparation  and  submission  of monthly  IOSS  VAT  returns,  including  VAT  intermediary  services  (if required)",
  // ],
  ["Self-revisions", "If you request any amendments in your VAT returns after it has been prepared or submitted, a self-revision has to be prepared. We can do this for a fixed fee."],
  ["VAT payment processing", "Required if we need to make VAT payments on your behalf in Spain."],
  ["Communicating with tax authorities", "Investigating/mitigating late payment penalties etc."],
  ["Changing company details", "Dealing with a change of company name, change of director etc."],
  ["General VAT advice and audit assistance", "Sellers will be notified in advance if work falls into this category."],
  [
    "Specialist VAT advice",
    "For specialist advice, we will work with our teams in local Tax Desk offices. Fees vary from country to country and you will be provided with the fees in advance of starting any work."
  ],
  [
    "VAT and IOSS Deregistration",
    "In case your contract expires before the deregistration happens, Tax Desk is not responsible for the submission of further (nil) returns to the tax authorities and penalties levied for any missing return. Additional VAT returns and associated reports (e.g. EC Sales List) after the contractual period are additional chargeable services. Tax Desk cannot guarantee a successful deregistration in case you have any outstanding liabilities in a given country."
  ],
  ["EORI number application", "Required in order to register as an importer."],
  [
    "Import VAT deferment account setup",
    "By applying for an import VAT deferment license you are not required to pay the VAT on import immediately. The VAT can be paid when you file your VAT return. As a foreign entrepreneur you usually must engage a fiscal representative to apply on your behalf."
  ],
  [
    "Sworn Translations",
    "Carrying out sworn translation is required to obtain the VAT registration numbers in some countries. Tax Desk may arrange for a third party to perform sworn translations on your behalf and charge the service fees to you, including \u20AC50 admin fee."
  ]
];
export const NUMBERED_TEXTS = (contactEmailAddress) => {
  return [
    {
      displayNumber: 1,
      text: `This engagement contract governs the services that Tax Desk International Services Ltd, Andr\xE1ssy \xFAt 100. 4. em., 1062 Budapest, Hungary, trading as Tax Desk (\u201CTax Desk\u201D or \u201Cwe\u201D) will provide to the contracting party indicated above (\u201Cyou\u201D).`
    },
    {
      displayNumber: 2,
      text: `Upon entering into this contract, you agree that Tax Desk will provide the Core Tax Compliance Services set out in paragraph 6 below in respect of the countries listed and for the Core Tax Compliance Services Fee set out in`
    },
    {
      displayNumber: 3,
      text: `In addition, upon your specific request, Tax Desk may provide you with Additional Tax Services for Additional Services Fee, which are set out in`
    },
    {
      displayNumber: 4,
      text: `this contract. Whenever the contract refers to working days, it is understood as working days in Hungary.`,
      firstLine: `(Tax Desk) form an integral part of`,
      linkTitle: `The General Terms of Business`
    },
    {
      displayNumber: 5,
      text: `Tax Desk has a process of  acceptance of new clients, which includes background verification and the assessment of our compliance with applicable Tax Desk and regulatory requirements. Tax Desk\u2019s acceptance of this contract is subject to the satisfactory completion of Tax Desk\u2019s pre-approval procedures and compliance with relevant  Anti-Money Laundering Law.`
    },
    {
      displayNumber: 6,
      text: `Core Tax Compliance Services include the following services:`
    },
    {
      displayNumber: 7,
      text: `For the avoidance of doubt, the following services are not included in the scope of the Core Tax Compliance Services, although they can be provided as Additional Tax Services upon payment of the respective Additional Services Fee:`
    },
    {
      displayNumber: 8,
      text: `Should we or you believe that there is a need to consult with a specialist indirect tax adviser before answering specific tax authority questions, we can arrange for a Tax Desk Firm in the relevant country to provide advice to you. Such assistance will be subject to a separate fee to be advised by the other Tax Desk firm. Depending on the nature of the advice, we can engage the Tax Desk firm as a subcontractor, or you can enter into a separate contract with that Tax Desk firm.`
    },
    {
      displayNumber: 9,
      text: `Tax Desk shall not accept any liability for errors arising from incomplete information or inaccuracies in the data provided to us. Tax Desk will not verify the data provided by you.`
    },
    {
      displayNumber: 10,
      text: `In order for us to prepare your VAT, EU OSS and associated returns (based on the requirements of the given country: EC Sales and Purchase Lists, Local listings, VAT Ledger Statements and SAF-T files), you must provide us either with the Amazon VAT Transactions Report (typically downloaded from your Amazon Seller Central account) in text format without modification or the correct Tax Desk VAT/OSS data template files available on the Tax Desk VAT Compliance Portal once you have subscribed to our services. For EU IOSS returns you must provide the standard Tax Desk IOSS data template available on the Tax Desk VAT Compliance Portal.  The Tax Desk VAT Compliance Portal has been tailored to process these specific data reports and requires these fixed formats. If you modify the data before uploading it to the Tax Desk VAT Compliance Portal, you do so at your own risk.`
    },
    {
      displayNumber: 11,
      text: `You must provide us with all required data to prepare the VAT, EU OSS, EU IOSS and associated returns for each calendar month by 17:00 Central European Time (\u201CCET\u201D) on the 6th calendar day following the end of the calendar month to be reported. If you do not use filing extension in Germany (The filing deadline from the 10th of the following month subsequent to the reporting period can be extended with one month if a prepayment is made to the tax authorities. The prepayment amounts to 1/11 of the expected VAT debt for the respective year and is due at the time the application is filed) , the source data must be provided to us by 4th calendar day following the end of the calendar month. If data is received late, we reserve the right to charge you a penalty fee of \u20AC50 per occurrence of your default/delay.`
    },
    {
      displayNumber: 12,
      text: `If no data is received by the statutory submission deadline for a particular return and you have not instructed us to submit nil returns, Tax Desk may reserve the right  not to submit a return to the tax authority for the particular period, unless it is in your interest that a nil return should be submitted. This may result in financial or other consequences from the tax authority, for which you will be fully liable. Tax Desk shall not be held liable for any financial or other consequences if your return was not submitted according to this paragraph.`
    },
    {
      displayNumber: 13,
      text: `In case you are trading on multiple platforms (for example, several marketplaces and/or through your own website), you must provide Tax Desk with the data needed to prepare the VAT returns in a format specified by Tax Desk (Tax Desk template files).`
    },
    {
      displayNumber: 14,
      text: `We will ask you to approve each return prior to us submitting it to the relevant tax authority. If we do not receive your explicit approval (or alternative instruction) by the earlier of 5 working days after we send the draft return to you or 10:00 CET on the submission deadline, we will assume that you agree with our calculations and that Tax Desk should submit the return to the tax authority, where permitted to do so. If you have failed to make the VAT payment to the fiscal representative, the VAT intermediary or the Tax Authorities on time, a nil return or no return may be submitted. Tax Desk and the fiscal representative or the VAT intermediary shall not be held liable for any financial or other consequences if your return was not submitted according to this paragraph.`
    },
    {
      displayNumber: 15,
      text: `In some countries, Tax Desk or its subcontractor is not authorised to file returns on behalf of another person or entity. In these cases, it will be your responsibility to sign the returns and submit them directly to the tax authority. Tax Desk will provide you with the return to be submitted together with the postal address where it should be sent or advise on e-filing option if available. You will be responsible for ensuring that the return is delivered.`
    },
    {
      displayNumber: 16,
      text: `Notwithstanding paragraph 17, Tax Desk will not make VAT payments on your behalf. We will provide you with the necessary payment instructions but the responsibility to make the payments will remain with you and you must make sure that the payment of VAT due has cleared to the given bank account by the deadline`
    },
    {
      displayNumber: 17,
      text: `In Spain, where VAT payments will be made on your behalf from Tax Desk\u2019s Spanish bank account as an Additional Tax Service we provided to you , , you must transfer the full amount of VAT that you owe to our Spanish bank account so that it is received by us at least 1 working day before the payment deadline. The amount received by Tax Desk (after the bank fees and similar charges) should equal exactly to the VAT amount due for the respective period and has to be made to the correct account as per our instructions in the cover letter sent to you. If the amount is received later or in the incorrect amount, Tax Desk cannot guarantee the timely payment of VAT to the tax authorities. Late VAT payment may have consequences to you that we cannot accept liabilities for. Also, amount transferred to the wrong account or incorrect amount will be sent back to you at your cost. Where you will be making VAT payments to the Spanish Tax Authorities directly using an overseas bank account, your return will need to be submitted first in order to generate a payment reference number. In this case, you must inform us the bank account from which you will make the payment and approve the return at least 3 working days before the submission deadline so that we can provide the payment reference number to you at least 1 working days before the submission deadline. If you have failed to inform us on the bank account and / or to approve the return on time or failed to make VAT payment on time after the payment reference number has been provided to you, you are solely responsible for any consequences of late VAT payment.`
    },
    {
      displayNumber: 18,
      text: `For the IOSS compliance where the corresponding VAT payment due (if there is one) must be paid via your VAT intermediary, the VAT payment must be received as cleared funds in the required currency by the appointed VAT intermediary at the latest  by 10.00 CET, 3 (three) working days before the due payment deadline in order for the payment to be made by the payment deadline. Should you wish to transfer the payment in a currency other than the required currency then you can if you wish to arrange an FX conversion through the VAT intermediary and will need to agree to the VAT intermediary\u2019s specific terms and conditions in respect of this service.`
    },
    {
      displayNumber: 19,
      text: `You shall retain responsibility for the correctness and completeness of all returns and other filings we submit on your behalf, and for the payment of your tax liabilities. In particular, Tax Desk is not responsible for accuracy of the data reported in the annual tax return, if the preliminary returns were prepared and submitted by another service provider, nor are we in the position to reconcile the annual filing with the preliminary returns.`
    },
    {
      displayNumber: 20,
      text: `Tax Desk shall not accept any responsibility for penalties and other surcharges imposed by tax authorities if returns are submitted late due to the late delivery of data to us or if you pay your VAT liabilities late. Tax Desk liability should be limited to the amount set in paragraph 34 of the engagement contract. In any case, we shall not be liable for your loss resulting from interest amount due on the VAT liability that was not settled in time with the respective tax authorities.`
    },
    {
      displayNumber: 21,
      text: `You shall send to us promptly any notices, assessments or determinations received by tax authorities regarding  your VAT returns prepared by Tax Desk for the service period covered by this agreement.`
    },
    {
      displayNumber: 22,
      text: `You shall retain responsibility for maintaining records and associated papers concerning your tax affairs in accordance with legal requirements in the country of your VAT/IOSS registrations and your country of establishment.`
    },
    {
      displayNumber: 23,
      text: `You shall provide a bank guarantee for the fiscal representative or the tax authorities if required (instructions will be provided by Tax Desk) which should be valid for the full period during which Tax Desk is providing services to you. We will confirm to you by writing once the bank guarantee is received either by the fiscal representative or the tax authorities.`
    },
    {
      displayNumber: 24,
      text: `All Core Tax Compliance Services Fees must be paid in advance as you are instructed during the signup process or the registration and compliance process through bank transfer, PayPal or any other means accepted on either the Tax Desk Portal or the VAT intermediary\u2019s system. For the Pay As You Ship Core Tax Compliance Services, the monthly fee must be paid before data upload deadline specified in paragraph 11. If any additional package fee is due, it should be paid, together with the VAT payment, before the deadline specified in paragraph 18.`
    },
    {
      displayNumber: 25,
      text: `Where you request us to provide Additional Tax Services, we will seek your approval for the estimated fees and disbursements in advance that should be provided before we start the respective service.`
    },
    {
      displayNumber: 26,
      text: `Tax Desk will issue an invoice electronically and send it to your  email address as recorded in your account on the Tax Desk VAT Compliance Portal) or make it available on the Tax Desk VAT Compliance Portal after payment of the respective fees. Tax Desk shall have no obligation to provide services if payment is not received in advance.`
    },
    {
      displayNumber: 27,
      text: `All fees are exclusive of VAT (where applicable) and any non-Hungarian taxes payable thereon or deductible therefrom and may be revised in the future.`
    },
    {
      displayNumber: 28,
      text: `Either party has the right to terminate this contract by providing 30 days written notice to ${contactEmailAddress} (if cancelled by you) or to the designated contact email address as recorded on your account on the Tax Desk VAT Compliance Portal (if cancelled by Tax Desk). If you do so:`
    },
    {
      displayNumber: 29,
      text: `Tax Desk may be required to terminate this contract for legal, regulatory or professional reasons. In these circumstances, the contract may be cancelled by Tax Desk immediately and we shall not be required to carry out any further work following termination nor shall we be responsible or liable for any consequences arising from such termination.`
    },
    {
      displayNumber: 30,
      text: `Termination with immediate effect by Tax Desk. Tax Desk or your appointed fiscal representative/VAT intermediary will notify you by sending an email to your email address as recorded in your account on the Tax Desk VAT Compliance Portal, if you fail to upload data or make VAT payment within the appropriate deadline. If you do not rectify the failure within 5 working days after the notification, Tax Desk reserves the right to terminate this contract with immediate effect. Tax Desk notes that it is not possible to submit an IOSS return retrospectively after the deadline. Therefore, we reserve the right to terminate our contract immediately if a correct IOSS return cannot be submitted before the deadline because you have not uploaded the data or made the VAT payment before the deadlines specified in paragraph 11 and paragraph 18 respectively. We can also terminate the contract immediately if you refrain from cooperation with the tax authorities during the tax audit or another similar proceedings. In these circumstances, the contract may be cancelled immediately. We shall not be required to carry out any further work following termination nor shall we be responsible or liable for any consequences arising from such termination.`
    },
    {
      displayNumber: 31,
      text: `Tax Desk may terminate this agreement with immediate effect if the Core Tax Compliance Services Fees have not been paid according to paragraph 24 and this breach is not remedied withing 2 business days after the receipt of Tax Desk\u2019s notice. This contract may be terminated by Tax Desk with immediate effect if you do not renew the Core Tax Compliance Services after the 12 month period has lapsed or the included packages credit (for Tier 1, Tier 2 or Tier 3 IOSS Core Tax Compliance Services) has been fully utilized.  For the Pay As You Ship IOSS Core Tax Compliance Services, we will not prepare your IOSS return if the monthly fee has not been paid. We will also terminate the contract with immediate effect if any additional package fee which is due together with the VAT payment, is not paid before the deadline, in accordance with paragraph 24.`
    },
    {
      displayNumber: 32,
      text: `If Tax Desk cancels this contract due to a material breach committed by you (such as the breaches mentioned in paragraph 30), no refund will be made of the Core Tax Compliance Services Fee. In case of cancellation by Tax Desk for any other reason, a refund of the Core Tax Compliance Services Fee will be paid pro-rata according to the number of completed months of services or the number of packages utilized (for Tier 1, Tier 2 and Tier 3 IOSS compliance services as defined in Appendix 1), whichever is smaller.`
    },
    {
      displayNumber: 33,
      text: `If you are required to provide a bank guarantee and this is not provided or is cancelled, Tax Desk is entitled to stop providing services immediately and no Core Tax Compliance Services Fee will be repaid.`
    },
    {
      displayNumber: 34,
      text: `Exclusions and limitations on Tax Desk\u2019s liability to you are set out in paragraphs 34 - 38 of the General Terms of Business. By signing this contract, the signatory parties of this contract mutually agree that Tax Desk\u2019s liability to you in connection with the services provided shall be limited, on the basis set out in Appendix 1 and Appendix 2, to a maximum of 1,5 times the Service Fee of the respective country or service.`
    },
    {
      displayNumber: 35,
      text: `The services defined under this contract require us to collect and process your personal  data and act as data processor. The website below contains our privacy statements and explains the way how we process your data and ensure appropriate security levels: `,
      linkTitle: `https://www.taxdesk.com/privacy-policy/`
    },
    {
      displayNumber: 36,
      text: `The Engagement Contract sets out the entire agreement and understanding between us in connection with the services and supersedes any prior agreements, understandings, arrangements, statements or representations (unless made fraudulently) relating to the services. The contract may be accepted via the Marketplace portal with online acceptance, and unless you request, Tax Desk will not prepare a signed copy of it, but the electronic version will guide the relationship. Any modifications or variations to the Engagement Contract shall be concluded with the same formalities as described above.`
    }
  ];
};
export const UNORDERED_TEXTS = [
  "Provide access to the Tax Desk VAT Compliance Portal",
  "Arrange the selected VAT registration (country specific VAT registration, EU One-Stop-Shop (OSS) VAT registration or EU Import-One-Stop-Shop (IOSS) VAT registration) on your behalf, unless you already possess the relevant VAT registration number. Tax Desk is not responsible for any delays and penalties caused by you (e.g. retrospective registration, delay in document provision, not correct documents provided) or by the tax authorities during the registration process.",
  "If required by law, appoint a fiscal representative (for country specific VAT registration) or a VAT intermediary (for EU IOSS VAT registration) on your behalf for the required VAT registration. Tax Desk may use a  subcontractor for the role of the fiscal representative or the VAT intermediary. You will be required to sign a separate agreement with the appointed fiscal representative or VAT intermediary.",
  "Prepare the VAT returns, EC Sales and/or Purchase Lists and VAT local listings (where required) for approval by you before submission. Tax Desk will prepare these returns based on data that you upload to the Tax Desk VAT Compliance Portal. For country specific VAT returns and/or EU OSS VAT returns, Tax Desk currently accepts (may be subject to change):",
  "the Amazon VAT Transactions Report; and/or",
  "the standard Tax Desk VAT/OSS data template available on the Tax Desk VAT Compliance Portal",
  "For EU IOSS returns, Tax Desk currently accepts (may be subject to change):",
  "the standard Tax Desk IOSS data template available on the Tax Desk VAT Compliance Portal.",
  "Automatically extract transactional data feed required for VAT reporting purposes (this is currently only available for Amazon Sellers).",
  "Where the necessary data is received according to the deadline set out in paragraph 11 and there are no additional queries on the data, Tax Desk will provide the draft returns to you at least 3 working days prior to the statutory submission deadline. For EU IOSS registration where VAT payments are required to be made via the VAT intermediary and not directly to the relevant Tax Authorities, we will provide the draft returns to you at least 7 working days prior to the statutory submission deadline. This paragraph does not apply if the timespan between the deadline set out in paragraph 11 and the statutory submission deadline is equal to or lower than 4 working days. In such cases, Tax Desk will undertake the best efforts to provide the draft returns to you as soon as possible.",
  "Submit returns to the appropriate tax authority where legally possible and confirm submission to you.",
  "Provide payment instructions via email (i.e. cover letter) or via the Tax Desk VAT Compliance Portal to you regarding your VAT liabilities.",
  "Act as your correspondence address for the tax authority where legally permitted during the contract duration, and inform you of any action related to your VAT reporting and liabilities as per correspondence received from the tax authority",
  "In case of contract termination, Tax Desk will not prepare and/or submit tax returns for tax reporting periods where the filing deadline falls after the termination date (as per paragrphs 28-33.) or inform you about actions required because of tax authorities\u2019 correspondence.",
  "Carrying out sworn translation or legalisation of documents that are required to obtain the VAT registration numbers in each country. Tax Desk may arrange for a third party to perform sworn translations on your behalf and charge the service fees to you.",
  "Preparing and submitting VAT and associated returns for tax reporting periods which ended before the date of this contract.",
  "Reviewing invoices and confirming that they meet the required regulatory requirements.",
  "Preparing your VAT returns (or any other return) based on data which is not provided in the correct format, unless specifically agreed with you.",
  "Auditing or verifying the correctness and completeness of the data you provide to us.",
  "The termination date of the contract will be the 30th day after the written notice was given to Tax Desk.",
  "Within the first 14 calendar days from the date of payment of the Core Tax Compliance Services Fee, you will be eligible for a proportionate refund of the fees paid as set out in Section 31 below, minus \u20AC200 administration fee to cover costs already incurred by Tax Desk with the exception of the Pas As You Ship IOSS Core Tax Compliance Services in which case you will not be eligible for the refund of any fee.",
  "After the first 14 calendar days, no refund of the Core Tax Compliance Services Fee will be paid."
];
export const IOSS_TIER_FEES = {
  body: [
    // [
    // 	"Pay As You Ship",
    // 	"EUR 70",
    // 	"EUR 10 per month, including up to 5 packages, plus EUR 2 per additional package if the number of packages exceeds 5, payable before the IOSS return submission for that month. ",
    // ],
    ["Tier 1", "None", "EUR 800 for up to 500 packages in a 12 month period, renewable after the 500 packages credit is used up or after 12 months."],
    ["Tier 2", "None", "EUR 2500 for up to 2500 packages in a 12 month period, renewable after the 2500 packages credit is used up or after 12 months."],
    ["Tier 3", "None", "EUR 4800 for unlimited packages in a 12 month period, renewable after 12 months."]
  ]
};
