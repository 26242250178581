var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class TemplateTypeDescription {
  constructor(_data) {
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Label");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TemplateTypeDescription
     */
    __publicField(this, "$type", "V1.TemplateMapping.TemplateTypeDescription");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Id = TemplateTypeDescriptionId[_data["Id"]];
      this.Label = _data["Label"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Id"] = TemplateTypeDescriptionId[this.Id];
    _data["Label"] = this.Label;
    return _data;
  }
}
export var TemplateTypeDescriptionId = /* @__PURE__ */ ((TemplateTypeDescriptionId2) => {
  TemplateTypeDescriptionId2["MPVatReg"] = "MPVatReg";
  TemplateTypeDescriptionId2["MPCompany"] = "MPCompany";
  return TemplateTypeDescriptionId2;
})(TemplateTypeDescriptionId || {});
