var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Name } from "./../../Name";
import { DateConverter } from "./../../../../../converters/DateConverter";
export class PspSearchResult {
  constructor(_data) {
    /**
     */
    __publicField(this, "Countries");
    /**
     */
    __publicField(this, "CreatedAtUtc");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Identifier");
    /**
     */
    __publicField(this, "IdentifierType");
    /**
     */
    __publicField(this, "Name");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PspSearchResult
     */
    __publicField(this, "$type", "V1.Cesop.Psps.Search.PspSearchResult");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Countries"] && _data["Countries"].constructor === Array) {
        this.Countries = [];
        for (let item of _data["Countries"]) {
          this.Countries.push(item);
        }
      }
      this.CreatedAtUtc = DateConverter.from(_data["CreatedAtUtc"]);
      this.Id = _data["Id"];
      this.Identifier = _data["Identifier"];
      this.IdentifierType = PspSearchResultIdentifierType[_data["IdentifierType"]];
      if (_data["Name"]) {
        const name_ = new Name();
        this.Name = name_.init(_data["Name"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.Countries && this.Countries.constructor === Array) {
      _data["Countries"] = [];
      for (let item of this.Countries) {
        _data["Countries"].push(item);
      }
    }
    _data["CreatedAtUtc"] = DateConverter.toIsoUtcString(this.CreatedAtUtc);
    _data["Id"] = this.Id;
    _data["Identifier"] = this.Identifier;
    _data["IdentifierType"] = PspSearchResultIdentifierType[this.IdentifierType];
    _data["Name"] = this.Name ? this.Name.toJSON() : void 0;
    return _data;
  }
}
export var PspSearchResultIdentifierType = /* @__PURE__ */ ((PspSearchResultIdentifierType2) => {
  PspSearchResultIdentifierType2["Bic"] = "Bic";
  PspSearchResultIdentifierType2["Other"] = "Other";
  return PspSearchResultIdentifierType2;
})(PspSearchResultIdentifierType || {});
