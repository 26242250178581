export const getCompanyName = (legalStatus, companyName, contactFirstName, contactLastName) => {
  return legalStatus === "Individual" ? `${contactFirstName}  ${contactLastName}` : companyName;
};
export const isValidOssTransitionForRegistrationType = (currentTransitionForType, ossRegistrationType) => currentTransitionForType === "NewReg" && ossRegistrationType === "NewRegistration" || currentTransitionForType === "TakeOver" && ossRegistrationType === "TakeOver" || currentTransitionForType === "All";
export const isVatRegistrationActiveAt = (vatRegVm, date) => {
  switch (vatRegVm.Status) {
    case "LocalTaxReceived":
    case "RegistrationCompleted":
    case "DeregistrationInProgress":
    case "RevokingPoA":
      return vatRegVm.StartDate <= date;
    case "Deregistered":
      if (!vatRegVm.DeregistrationInfo.DeregisteredAt) {
        return false;
      }
      return date <= vatRegVm.DeregistrationInfo.DeregisteredAt;
    case "PoARevoked":
      if (!vatRegVm.PoARevocationInfo.PoARevokedAt) {
        return false;
      }
      return date <= vatRegVm.PoARevocationInfo.PoARevokedAt;
    default:
      return false;
  }
};
