var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { FileLimit } from "./FileLimit";
export class TransactionCountOrFileSizeLimit extends FileLimit {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "MaxSize");
    /**
     */
    __publicField(this, "MaxTransactionCount");
    /**
     */
    __publicField(this, "Unit");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TransactionCountOrFileSizeLimit
     */
    __publicField(this, "$type", "V1.Cesop.Configuration.TransactionCountOrFileSizeLimit");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.MaxSize = _data["MaxSize"];
      this.MaxTransactionCount = _data["MaxTransactionCount"];
      this.Unit = TransactionCountOrFileSizeLimitUnit[_data["Unit"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["MaxSize"] = this.MaxSize;
    _data["MaxTransactionCount"] = this.MaxTransactionCount;
    _data["Unit"] = TransactionCountOrFileSizeLimitUnit[this.Unit];
    return _data;
  }
}
export var TransactionCountOrFileSizeLimitUnit = /* @__PURE__ */ ((TransactionCountOrFileSizeLimitUnit2) => {
  TransactionCountOrFileSizeLimitUnit2["MegaByte"] = "MegaByte";
  TransactionCountOrFileSizeLimitUnit2["GigaByte"] = "GigaByte";
  return TransactionCountOrFileSizeLimitUnit2;
})(TransactionCountOrFileSizeLimitUnit || {});
