var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../../converters/DateConverter";
import { Service } from "./Service";
export class OssSubscriptionService extends Service {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "Country");
    /**
     */
    __publicField(this, "EndDate");
    /**
     */
    __publicField(this, "StartDate");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof OssSubscriptionService
     */
    __publicField(this, "$type", "V1.Orders.Create.Request.OssSubscriptionService");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.Country = _data["Country"];
      this.EndDate = DateConverter.from(_data["EndDate"]);
      this.StartDate = DateConverter.from(_data["StartDate"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["Country"] = this.Country;
    _data["EndDate"] = DateConverter.toUtcDateString(this.EndDate);
    _data["StartDate"] = DateConverter.toUtcDateString(this.StartDate);
    return _data;
  }
}
