import { SourceFileStatus } from "WebServices/APIs/self/models/V1/Cesop/Periods/SourceFileAbstraction";
export const FieldNames = {
  PspName: "PspName",
  StartDate: "StartDate",
  Status: "Status",
  ReportingCountry: "ReportingCountry",
  Transactions: "Transactions",
  Actions: "Actions",
  RegistrationIdentifier: "RegistrationIdentifier",
  Version: "Version"
};
export const VALIDATION_ENDED_STATUSES = [SourceFileStatus.Validated, SourceFileStatus.ValidationError];
export const TAB_NAMES = {
  active: "active-periods",
  submitted: "submitted-periods"
};
export const TAB_QUERY_PREFIX = "tab";
