var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import {
  DEFAULT_CURRENCY_CODE,
  mapOrderService,
  mapOrderServicesToSummary
} from "GlobalShared/components/orders/OrderModels";
import { useClient } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { mapMoney } from "GlobalShared/mappers/MoneyMapper";
import { Orders as OrdersClient, Payments as PaymentsClient, Services as ServicesClient } from "WebServices/APIs/self/clients";
import { CreateOrderRequest } from "WebServices/APIs/self/models/V1/Orders/Create/Request/CreateOrderRequest";
import { mapCreatedServices } from "./admin-services-utils";
import { getLocalizedUrl } from "../../globals";
import { Money } from "WebServices/APIs/self/models/V1/Common";
import { mapServiceDescriptor } from "GlobalShared/components/orders/OrderHelpers";
import { GetServicesRequest, SelectedService } from "WebServices/APIs/self/models/V1/Services/V2";
import { getAlreadySelectedServices } from "GlobalShared/components/orders/services/order-services-utils";
import { BankTransferPayment, PayPalPayment } from "WebServices/APIs/self/models/V1/Payments/Pay";
export const useCommonCalls = () => {
  const createOrder = (_companyId, orderType, createdServices) => __async(void 0, null, function* () {
    if (createdServices.length === 0) {
      notifyWarning("Select at least one service");
      return void 0;
    }
    const orderResponse = (yield ordersClient.createOrder({
      body: new CreateOrderRequest({
        Currency: DEFAULT_CURRENCY_CODE,
        FlowType: orderType,
        Services: mapCreatedServices(createdServices)
      })
    })).data;
    const orderSummaryVM = orderResponse.Services ? {
      services: orderResponse.Services ? orderResponse.Services.map(mapOrderService).map(mapOrderServicesToSummary) : void 0,
      subTotal: mapMoney(orderResponse.SubTotal),
      total: mapMoney(orderResponse.Total),
      vat: mapMoney(orderResponse.Vat)
    } : void 0;
    return {
      Id: orderResponse.Id,
      ContractId: orderResponse.ContractId,
      ClientSource: orderResponse.ClientSource,
      OrderSummary: orderSummaryVM
    };
  });
  const getPaymentInfo = (orderId) => __async(void 0, null, function* () {
    return withErrorHandling(
      () => __async(void 0, null, function* () {
        const paymentInfoResponse = (yield paymentsClient.getPaymentInfo({ orderId })).data;
        const paymentInfo = {
          availablePaymentTypes: paymentInfoResponse.AvailablePaymentTypes.map((pt) => pt),
          totalPrice: mapMoney(paymentInfoResponse.TotalPrice)
        };
        return paymentInfo;
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const payAndConfirm = (payment, orderId) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        const quoted = new Money({
          Currency: payment.quotedPrice.Currency,
          Value: payment.quotedPrice.Value
        });
        const tempPayment = payment.type === "BankTransferPayment" ? new BankTransferPayment({
          Quoted: quoted
        }) : payment.type === "PayPalPayment" ? new PayPalPayment({
          Quoted: quoted,
          Reference: payment.reference
        }) : void 0;
        yield paymentsClient.pay({ body: tempPayment, orderId });
        yield ordersClient.confirmOrder({ orderId });
        window.location.href = getLocalizedUrl("/admin");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const uploadContract = (orderId, contract) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield ordersClient.uploadContract({
          orderId,
          file: contract
        });
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getAvailableServices = (currencyCode, vatCountrySelection, iossSelection, ossSelection) => __async(void 0, null, function* () {
    return withErrorHandling(
      () => __async(void 0, null, function* () {
        const resp = yield servicesClient.getAvailableServices({
          body: new GetServicesRequest({
            CurrencyCode: currencyCode,
            SelectedServices: getAlreadySelectedServices({
              vatCountrySelection,
              iossSelection,
              ossSelection
            }).map((s) => {
              return new SelectedService(__spreadProps(__spreadValues({}, s), {
                ServiceCode: s.ServiceCode
              }));
            })
          })
        });
        return resp.data.map(mapServiceDescriptor);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const ordersClient = useClient(OrdersClient);
  const paymentsClient = useClient(PaymentsClient);
  const servicesClient = useClient(ServicesClient);
  return {
    createOrder,
    getPaymentInfo,
    payAndConfirm,
    uploadContract,
    getAvailableServices
  };
};
