var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
export class VatRegistrationStatusChangeHistory {
  constructor(_data) {
    /**
     */
    __publicField(this, "From");
    /**
     */
    __publicField(this, "Timestamp");
    /**
     */
    __publicField(this, "To");
    /**
     */
    __publicField(this, "User");
    /**
     */
    __publicField(this, "VatRegistrationId");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof VatRegistrationStatusChangeHistory
     */
    __publicField(this, "$type", "V1.Company.VatRegistration.VatRegistrationStatusChangeHistory");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.From = VatRegistrationStatusChangeHistoryFrom[_data["From"]];
      this.Timestamp = DateConverter.from(_data["Timestamp"]);
      this.To = VatRegistrationStatusChangeHistoryTo[_data["To"]];
      this.User = _data["User"];
      this.VatRegistrationId = _data["VatRegistrationId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["From"] = VatRegistrationStatusChangeHistoryFrom[this.From];
    _data["Timestamp"] = DateConverter.toIsoUtcString(this.Timestamp);
    _data["To"] = VatRegistrationStatusChangeHistoryTo[this.To];
    _data["User"] = this.User;
    _data["VatRegistrationId"] = this.VatRegistrationId;
    return _data;
  }
}
export var VatRegistrationStatusChangeHistoryFrom = /* @__PURE__ */ ((VatRegistrationStatusChangeHistoryFrom2) => {
  VatRegistrationStatusChangeHistoryFrom2["Pending"] = "Pending";
  VatRegistrationStatusChangeHistoryFrom2["WaitingForSignature"] = "WaitingForSignature";
  VatRegistrationStatusChangeHistoryFrom2["DocumentsSigned"] = "DocumentsSigned";
  VatRegistrationStatusChangeHistoryFrom2["TranslationInProgress"] = "TranslationInProgress";
  VatRegistrationStatusChangeHistoryFrom2["SentToTA"] = "SentToTA";
  VatRegistrationStatusChangeHistoryFrom2["LocalTaxReceived"] = "LocalTaxReceived";
  VatRegistrationStatusChangeHistoryFrom2["RegistrationCompleted"] = "RegistrationCompleted";
  VatRegistrationStatusChangeHistoryFrom2["Deregistered"] = "Deregistered";
  VatRegistrationStatusChangeHistoryFrom2["DeregistrationInProgress"] = "DeregistrationInProgress";
  VatRegistrationStatusChangeHistoryFrom2["PoARevoked"] = "PoARevoked";
  VatRegistrationStatusChangeHistoryFrom2["RevokingPoA"] = "RevokingPoA";
  return VatRegistrationStatusChangeHistoryFrom2;
})(VatRegistrationStatusChangeHistoryFrom || {});
export var VatRegistrationStatusChangeHistoryTo = /* @__PURE__ */ ((VatRegistrationStatusChangeHistoryTo2) => {
  VatRegistrationStatusChangeHistoryTo2["Pending"] = "Pending";
  VatRegistrationStatusChangeHistoryTo2["WaitingForSignature"] = "WaitingForSignature";
  VatRegistrationStatusChangeHistoryTo2["DocumentsSigned"] = "DocumentsSigned";
  VatRegistrationStatusChangeHistoryTo2["TranslationInProgress"] = "TranslationInProgress";
  VatRegistrationStatusChangeHistoryTo2["SentToTA"] = "SentToTA";
  VatRegistrationStatusChangeHistoryTo2["LocalTaxReceived"] = "LocalTaxReceived";
  VatRegistrationStatusChangeHistoryTo2["RegistrationCompleted"] = "RegistrationCompleted";
  VatRegistrationStatusChangeHistoryTo2["Deregistered"] = "Deregistered";
  VatRegistrationStatusChangeHistoryTo2["DeregistrationInProgress"] = "DeregistrationInProgress";
  VatRegistrationStatusChangeHistoryTo2["PoARevoked"] = "PoARevoked";
  VatRegistrationStatusChangeHistoryTo2["RevokingPoA"] = "RevokingPoA";
  return VatRegistrationStatusChangeHistoryTo2;
})(VatRegistrationStatusChangeHistoryTo || {});
