import { OrganisationTypeAnswer as WebOrganisationTypeAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { OrganisationTypeAnswer as AdminOrganisationTypeAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { useEnumAnswer } from "./answer-enum";
import { OrganisationTypeAnswerValue } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers/OrganisationTypeAnswerAbstraction";
import i18next from "i18next";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-organisationtype";
export class OrganisationTypeChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId
  };
  const dispatch = (answer) => {
    host.dispatchEvent(
      new OrganisationTypeChangeEvent({
        answer: getAnswer(answer)
      })
    );
  };
  const getAnswer = (answer) => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminOrganisationTypeAnswer({ Value: answer.Value, QuestionId: props.questionId });
    }
    return new WebOrganisationTypeAnswer({ Value: answer.Value, QuestionId: props.questionId });
  };
  const enumHook = useEnumAnswer({
    dataSource: [
      { value: OrganisationTypeAnswerValue.LimitedLiability, label: i18next.t("Limited liability") },
      { value: OrganisationTypeAnswerValue.UnlimitedLiability, label: i18next.t("Unlimited liability") },
      { value: OrganisationTypeAnswerValue.SharedLiability, label: i18next.t("Shared liability") }
    ],
    isMultiSelect: false,
    questionId: props.questionId,
    q11e: props.q11e,
    dispatch
  });
  return enumHook.mainTemplate;
};
