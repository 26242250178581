import { html } from "lit-html";
import { setDataTransfer } from "GlobalShared/ui/dc-dropfield";
export function useSharedCustomDataTemplate() {
  const draggableItemTemplate = (label, info, id) => html`
		<div
			class="flex w-64 shadow-md cursor-move justify-between border-brand-primary bg-white border border-solid rounded-xl px-4 py-2"
			id=${`draggable-${id}`}
			draggable="true"
			@dragstart=${(e) => {
    const draggedItem = {
      type: "TemplateDataSource",
      id,
      label,
      info
    };
    setDataTransfer(e, draggedItem);
    const element = document.getElementById(`draggable-${id}`);
    const hideDragImage = element.cloneNode(true);
    hideDragImage.id = "hideDragImage-hide";
    const dragImage = element.cloneNode(true);
    dragImage.id = "draggeimage";
    hideDragImage.style.display = "none";
    dragImage.style.display = "none";
    dragImage.style.position = "absolute";
    document.body.appendChild(hideDragImage);
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(hideDragImage, 0, 0);
  }}
			@dragend=${() => {
    const hideDragImage = document.getElementById("hideDragImage-hide");
    const dragImage = document.getElementById("draggeimage");
    if (hideDragImage) {
      hideDragImage.remove();
    }
    if (dragImage) {
      dragImage.remove();
    }
  }}
			@drag=${(e) => {
    const dragImage = document.getElementById("draggeimage");
    if (dragImage) {
      dragImage.style.display = "block";
      dragImage.style.left = e.pageX + "px";
      dragImage.style.top = e.pageY + "px";
    }
  }}
		>
			<div class="truncate" title=${label}>${label}</div>
		</div>
	`;
  return { draggableItemTemplate };
}
