var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class BatchUploadResult {
  constructor(_data) {
    /**
     */
    __publicField(this, "ErrorReason");
    /**
     */
    __publicField(this, "FileName");
    /**
     */
    __publicField(this, "IsSuccess");
    /**
     */
    __publicField(this, "SubmissionPeriodId");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof BatchUploadResult
     */
    __publicField(this, "$type", "V1.Submission.BatchUploadResult");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ErrorReason = BatchUploadResultErrorReason[_data["ErrorReason"]];
      this.FileName = _data["FileName"];
      this.IsSuccess = _data["IsSuccess"];
      this.SubmissionPeriodId = _data["SubmissionPeriodId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ErrorReason"] = BatchUploadResultErrorReason[this.ErrorReason];
    _data["FileName"] = this.FileName;
    _data["IsSuccess"] = this.IsSuccess;
    _data["SubmissionPeriodId"] = this.SubmissionPeriodId;
    return _data;
  }
}
export var BatchUploadResultErrorReason = /* @__PURE__ */ ((BatchUploadResultErrorReason2) => {
  BatchUploadResultErrorReason2["Duplication"] = "Duplication";
  BatchUploadResultErrorReason2["IncorrectNaming"] = "IncorrectNaming";
  BatchUploadResultErrorReason2["IncorrectStatus"] = "IncorrectStatus";
  BatchUploadResultErrorReason2["SubmissionPeriodMissing"] = "SubmissionPeriodMissing";
  BatchUploadResultErrorReason2["SubmissionPeriodIsNotSubmissionOnly"] = "SubmissionPeriodIsNotSubmissionOnly";
  BatchUploadResultErrorReason2["Cancelled"] = "Cancelled";
  BatchUploadResultErrorReason2["Unknown"] = "Unknown";
  return BatchUploadResultErrorReason2;
})(BatchUploadResultErrorReason || {});
