var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
export class SubmissionProof {
  constructor(_data) {
    /**
     */
    __publicField(this, "Date");
    /**
     */
    __publicField(this, "Name");
    /**
     */
    __publicField(this, "ReferenceId");
    /**
     */
    __publicField(this, "Version");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SubmissionProof
     */
    __publicField(this, "$type", "V1.Submission.Search.SubmissionProof");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Date = DateConverter.from(_data["Date"]);
      this.Name = _data["Name"];
      this.ReferenceId = _data["ReferenceId"];
      this.Version = _data["Version"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Date"] = DateConverter.toIsoUtcString(this.Date);
    _data["Name"] = this.Name;
    _data["ReferenceId"] = this.ReferenceId;
    _data["Version"] = this.Version;
    return _data;
  }
}
