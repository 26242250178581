var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { OneTransactionData } from "./OneTransactionData";
import { CustomSourceReference } from "./CustomSourceReference";
export class OneCustomSourceReference extends CustomSourceReference {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "FileName");
    /**
     */
    __publicField(this, "Reference");
    /**
     */
    __publicField(this, "TransactionData");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof OneCustomSourceReference
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.OneCustomSourceReference");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.FileName = _data["FileName"];
      this.Reference = _data["Reference"];
      if (_data["TransactionData"]) {
        const transactionData_ = new OneTransactionData();
        this.TransactionData = transactionData_.init(_data["TransactionData"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["FileName"] = this.FileName;
    _data["Reference"] = this.Reference;
    _data["TransactionData"] = this.TransactionData ? this.TransactionData.toJSON() : void 0;
    return _data;
  }
}
