import { footerTextTemplate } from "../../shared/templates/commons";
import { component, useState } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { getLocalizedUrl } from "../globals";
export const name = "ww-login-twofactor";
export const Component = (host) => {
  const props = {
    token: host.token,
    error: host.error
  };
  const generateError = () => {
    if (props.error) {
      switch (props.error) {
        case "User account is locked out":
          return i18next.t("User account is locked out");
        case "Invalid token":
          return i18next.t("Invalid token");
        default:
          return props.error;
      }
    }
    return void 0;
  };
  const hasError = () => {
    return generateError() === void 0 ? false : true;
  };
  const [token, setToken] = useState(void 0);
  return html` <div class="w-full px-4 md:w-1/2 md:p-24">
		<div>
			<a href="${getLocalizedUrl("")}" data-routing="server">
				<img src="/images/logo.png" alt="Tax Desk logo" />
			</a>
		</div>
		<div class="bg-white bg-opacity-75 text-black rounded px-8 py-6 m-auto flex flex-col max-w-sm">
			<div class="text-3xl font-bold mt-6 mb-8">${i18next.t("One-time password")}</div>
			<div class="mb-8">
				<dc-input .label=${i18next.t("Token")} .name=${"Token"} .value=${token} .placeholder=${i18next.t("Token")} @change=${(e) => setToken(e.detail.value)}></dc-input>
			</div>
			${hasError() ? html`<div class="mb-6">
						<label class="text-red-400"> ${generateError()} </label>
				  </div>` : ""}
			<div class="w-full">
				<button class="btn btn-primary" type="submit">${i18next.t("Login")}</button>
			</div>
			<div class="mt-2">
				<span class="text-sm">${i18next.t("Haven't received the email?")}</span>
				<a class="link" href="javascript:void(0)" @click=${() => window.location.reload()} data-routing="server">${i18next.t("Resend here")}</a>
			</div>
		</div>
		<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">${footerTextTemplate()}</div>
	</div>`;
};
export const observedAttributes = ["error", "token"];
customElements.define(
  "ww-login-twofactor",
  component(Component, {
    useShadowDOM: false,
    observedAttributes
  })
);
