import { NameNameType } from "WebServices/APIs/self/models/V1/Cesop/NameAbstraction";
import { useMemo } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
export const useNameTypes = () => {
  return useMemo(
    () => [
      {
        value: NameNameType.Business,
        label: i18next.t("Business")
      },
      {
        value: NameNameType.Legal,
        label: i18next.t("Legal")
      },
      {
        value: NameNameType.Other,
        label: i18next.t("Other")
      },
      {
        value: NameNameType.Person,
        label: i18next.t("Person")
      },
      {
        value: NameNameType.Trade,
        label: i18next.t("Trade")
      }
    ],
    []
  );
};
