var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class Field {
  constructor(_data) {
    /**
     */
    __publicField(this, "Order");
    /**
     */
    __publicField(this, "OrderByField");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Field
     */
    __publicField(this, "$type", "V1.Submission.Search.Field");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Order = FieldOrder[_data["Order"]];
      this.OrderByField = FieldOrderByField[_data["OrderByField"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Order"] = FieldOrder[this.Order];
    _data["OrderByField"] = FieldOrderByField[this.OrderByField];
    return _data;
  }
}
export var FieldOrder = /* @__PURE__ */ ((FieldOrder2) => {
  FieldOrder2["Ascending"] = "Ascending";
  FieldOrder2["Descending"] = "Descending";
  return FieldOrder2;
})(FieldOrder || {});
export var FieldOrderByField = /* @__PURE__ */ ((FieldOrderByField2) => {
  FieldOrderByField2["CompanyId"] = "CompanyId";
  FieldOrderByField2["CompanyName"] = "CompanyName";
  FieldOrderByField2["TaxReturnType"] = "TaxReturnType";
  FieldOrderByField2["ServiceCountry"] = "ServiceCountry";
  FieldOrderByField2["PeriodStartDate"] = "PeriodStartDate";
  FieldOrderByField2["ReturnGenerationDueDate"] = "ReturnGenerationDueDate";
  FieldOrderByField2["ClientApprovalDueDate"] = "ClientApprovalDueDate";
  FieldOrderByField2["SubmissionDueDate"] = "SubmissionDueDate";
  FieldOrderByField2["PaymentDueDate"] = "PaymentDueDate";
  FieldOrderByField2["Status"] = "Status";
  FieldOrderByField2["PaymentStatus"] = "PaymentStatus";
  return FieldOrderByField2;
})(FieldOrderByField || {});
