var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class Filter {
  constructor(_data) {
    /**
     */
    __publicField(this, "ClientTaxCode");
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "OnlyMissing");
    /**
     */
    __publicField(this, "ReverseCharge");
    /**
     */
    __publicField(this, "TransactionTypeCodes");
    /**
     */
    __publicField(this, "TypeOfGoods");
    /**
     */
    __publicField(this, "VatRateTypes");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Filter
     */
    __publicField(this, "$type", "V1.ComplianceMasterData.TaxCode.Search.Filter");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientTaxCode = _data["ClientTaxCode"];
      this.CountryCode = _data["CountryCode"];
      this.OnlyMissing = _data["OnlyMissing"];
      this.ReverseCharge = _data["ReverseCharge"];
      if (_data["TransactionTypeCodes"] && _data["TransactionTypeCodes"].constructor === Array) {
        this.TransactionTypeCodes = [];
        for (let item of _data["TransactionTypeCodes"]) {
          this.TransactionTypeCodes.push(item);
        }
      }
      if (_data["TypeOfGoods"] && _data["TypeOfGoods"].constructor === Array) {
        this.TypeOfGoods = [];
        for (let item of _data["TypeOfGoods"]) {
          this.TypeOfGoods.push(item);
        }
      }
      if (_data["VatRateTypes"] && _data["VatRateTypes"].constructor === Array) {
        this.VatRateTypes = [];
        for (let item of _data["VatRateTypes"]) {
          this.VatRateTypes.push(FilterVatRateTypes[item]);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientTaxCode"] = this.ClientTaxCode;
    _data["CountryCode"] = this.CountryCode;
    _data["OnlyMissing"] = this.OnlyMissing;
    _data["ReverseCharge"] = this.ReverseCharge;
    if (this.TransactionTypeCodes && this.TransactionTypeCodes.constructor === Array) {
      _data["TransactionTypeCodes"] = [];
      for (let item of this.TransactionTypeCodes) {
        _data["TransactionTypeCodes"].push(item);
      }
    }
    if (this.TypeOfGoods && this.TypeOfGoods.constructor === Array) {
      _data["TypeOfGoods"] = [];
      for (let item of this.TypeOfGoods) {
        _data["TypeOfGoods"].push(item);
      }
    }
    if (this.VatRateTypes && this.VatRateTypes.constructor === Array) {
      _data["VatRateTypes"] = [];
      for (let item of this.VatRateTypes) {
        _data["VatRateTypes"].push(FilterVatRateTypes[item]);
      }
    }
    return _data;
  }
}
export var FilterVatRateTypes = /* @__PURE__ */ ((FilterVatRateTypes2) => {
  FilterVatRateTypes2["Standard"] = "Standard";
  FilterVatRateTypes2["Standard2"] = "Standard2";
  FilterVatRateTypes2["Reduced"] = "Reduced";
  FilterVatRateTypes2["Reduced2"] = "Reduced2";
  FilterVatRateTypes2["SuperReduced"] = "SuperReduced";
  FilterVatRateTypes2["SuperReduced2"] = "SuperReduced2";
  FilterVatRateTypes2["Exempt"] = "Exempt";
  FilterVatRateTypes2["ZeroRated"] = "ZeroRated";
  FilterVatRateTypes2["Middle"] = "Middle";
  return FilterVatRateTypes2;
})(FilterVatRateTypes || {});
