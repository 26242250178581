import { BankAccountChangeRequest, NewManualBankAccount } from "AdminServices/APIs/self/models/V1/Company/VatRegistration";
import i18next from "i18next";
export const mapBankAccountConfig = (bankAccountConfig) => {
  return new BankAccountChangeRequest({
    BankAccountRef: bankAccountConfig.BankAccountRef,
    BankAccountUrl: bankAccountConfig.BankAccountUrl,
    ManualBankAccount: bankAccountConfig.BankAccount ? new NewManualBankAccount({
      BankAccountId: bankAccountConfig.BankAccount.BankAccountId,
      Iban: bankAccountConfig.BankAccount.Iban
    }) : void 0
  });
};
export const mapSubmissionConfig = (options) => {
  const vm = {
    Frequencies: options.map((f) => {
      const fvm = {
        CountryCode: f.CountryCode,
        EFiling: f.EFiling,
        FilingExtension: f.FilingExtension,
        FrequencyType: f.FrequencyType,
        ReturnType: f.ReturnType
      };
      return fvm;
    })
  };
  return vm;
};
export const mapCountrySubmissionConfigurations = (option) => {
  const vm = {
    CompanyId: option.CompanyId,
    CountryConfiguration: option.CountryConfiguration.map((c) => {
      const csc = {
        CountryCode: c.CountryCode,
        IsActivated: c.IsActivated
      };
      return csc;
    })
  };
  return vm;
};
export const ClientAcceptanceLabels = [
  { label: "Pending", value: "Pending" },
  { label: "Low Risk", value: "LowRisk" },
  { label: "Medium Risk", value: "MediumRisk" },
  { label: "High Risk", value: "HighRisk" },
  { label: "Failed", value: "Failed" }
];
export const getClientAcceptanceStatusLabel = (status) => {
  const item = ClientAcceptanceLabels.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const COMPLIANCE_SUBSCRIPTION_STATUS_LABELS = {
  Active: i18next.t("Active"),
  Cancelled: i18next.t("Cancelled"),
  Pending: i18next.t("Pending")
};
export const SUBMISSION_FREQUENCY_TYPE_LABELS = {
  NotAvailable: i18next.t("Not Available"),
  Monthly: i18next.t("Monthly"),
  Quarterly: i18next.t("Quarterly"),
  QuarterlyShifted1: i18next.t("QuarterlyShifted1"),
  QuarterlyShifted2: i18next.t("QuarterlyShifted2"),
  BiMonthly: i18next.t("BiMonthly"),
  FourMonthly: i18next.t("FourMonthly"),
  SixMonthly: i18next.t("SixMonthly"),
  Yearly: i18next.t("Yearly")
};
export const VAT_DATA_UPLOAD_METHOD_LABELS = [
  { label: "All Country", value: "AllCountryInOne" },
  { label: "Per Country", value: "PerCountry" }
];
export const VAT_DATA_UPLOAD_ALLCOUNTRY_TEMPLATE_LABELS = [
  { label: "Amazon", value: "Amazon" },
  { label: "VAT/OSS", value: "Mp" }
];
export const VAT_DATA_UPLOAD_PERCOUNTRY_TEMPLATE_LABELS = [
  { label: "Test SAP Template (text)", value: "CustomTemplate1" },
  { label: "Test SAP Template (excel)", value: "CustomTemplate2" }
];
export const getVatDataUploadMethodLabel = (status) => {
  const item = VAT_DATA_UPLOAD_METHOD_LABELS.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const getVatDataUploadAllCountryTemplateLabel = (status) => {
  const item = VAT_DATA_UPLOAD_ALLCOUNTRY_TEMPLATE_LABELS.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
export const getVatDataUploadPerCountryTemplateLabel = (status) => {
  const item = VAT_DATA_UPLOAD_PERCOUNTRY_TEMPLATE_LABELS.find((s) => s.value === status);
  return item && item.label ? item.label : status;
};
