var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import {
  CorrectionSubmissionSummary,
  CesopErrorFile,
  ProofFile,
  ValidCesopResponseFile
} from "WebServices/APIs/self/models/V1/Cesop/Periods";
import { html } from "lit-html";
import { formatDate, formatDateTimeUTC } from "GlobalShared/helpers/dateHelper";
import { fileIcon, templateTitle } from "GlobalShared/templates/commons";
import classNames from "classnames";
import { useUploadTypes } from "./useUploadTypes";
import { PeriodStatus } from "WebServices/APIs/self/models/V1/Cesop/Periods/Period";
import { PeriodVersionUploadType } from "WebServices/APIs/self/models/V1/Cesop/Periods/PeriodVersion";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { SourceFileStatus } from "WebServices/APIs/self/models/V1/Cesop/Periods/SourceFile";
import { formatValidationError } from "./validation-error-formatter";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import i18next from "i18next";
import { currentLocale } from "../../../globals";
import { getMessageStatusLabel, getPeriodStatusLabel, resolveUnit } from "./PeriodsHelper";
import {
  FileSizeLimit,
  TransactionCountLimit,
  TransactionCountOrFileSizeLimit,
  ZippedFileSizeLimit
} from "WebServices/APIs/self/models/V1/Cesop/Configuration";
import { MessageStatus } from "WebServices/APIs/self/models/V1/Cesop/Periods/Message";
import { usePeriodHandlers } from "./usePeriodHandlers";
import { usePeriodFiles } from "./usePeriodFiles";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { TertiaryButton } from "GlobalShared/ui/TertiaryButton";
export const usePeriodEditorTemplates = ({ period, periodManager, reload, refresh, finalizeUpload }) => {
  const uploadTypes = useUploadTypes();
  const [uploadType, setUploadType] = useState(period == null ? void 0 : period.CurrentVersion.UploadType);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showViewFilesModal, setShowViewFilesModal] = useState(false);
  const [viewFilesModalMessage, setViewFilesModalMessage] = useState(void 0);
  const [currentValidationErrorCollection, setCurrentValidationErrorCollection] = useState();
  const [countryConfiguration, setCountryConfiguration] = useState();
  const [openedVersionDetails, setOpenedVersionDetails] = useState(/* @__PURE__ */ new Set());
  const countries = useWorldCountries(currentLocale);
  const periodHandlers = usePeriodHandlers({ period, periodManager, reload, refresh, finalizeUpload });
  const periodFiles = usePeriodFiles({ period });
  const isCorrectionFlow = (period == null ? void 0 : period.Versions.length) > 1;
  const getCountryConfiguration = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        const response = yield periodManager.getCountryConfiguration(period);
        setCountryConfiguration(response);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  useEffect(() => {
    setUploadType(period == null ? void 0 : period.CurrentVersion.UploadType);
  }, [period == null ? void 0 : period.CurrentVersion.UploadType]);
  useEffect(() => {
    if (period) {
      getCountryConfiguration();
    }
  }, [period == null ? void 0 : period.Id]);
  useEffect(() => {
    var _a;
    if (showViewFilesModal) {
      const newMessage = (_a = period == null ? void 0 : period.CurrentVersion.Messages) == null ? void 0 : _a.find((m) => m.Id === (viewFilesModalMessage == null ? void 0 : viewFilesModalMessage.Id));
      setViewFilesModalMessage(newMessage);
    }
  }, [period == null ? void 0 : period.CurrentVersion.Messages]);
  useEffect(() => {
    if ((period == null ? void 0 : period.Versions.length) > 0) {
      setOpenedVersionDetails(/* @__PURE__ */ new Set());
    }
  }, [period == null ? void 0 : period.Versions.length]);
  const closeErrorModal = () => {
    setCurrentValidationErrorCollection(void 0);
    setShowErrorModal(false);
  };
  const openPayeeErrorModal = () => __async(void 0, null, function* () {
    const validation = yield periodManager.getPayeeValidation(period);
    setCurrentValidationErrorCollection(validation);
    setShowErrorModal(true);
  });
  const openTransactionErrorModal = (sourceFile) => __async(void 0, null, function* () {
    const validation = yield periodManager.getTransactionValidation(period, sourceFile);
    setCurrentValidationErrorCollection(validation);
    setShowErrorModal(true);
  });
  const handleTogglePeriodVersionDetails = (periodVersion) => {
    const isVersionDetailsVisible = openedVersionDetails.has(periodVersion.Id);
    const newOpenedVersionDetailsSet = new Set(openedVersionDetails);
    isVersionDetailsVisible ? newOpenedVersionDetailsSet.delete(periodVersion.Id) : newOpenedVersionDetailsSet.add(periodVersion.Id);
    setOpenedVersionDetails(newOpenedVersionDetailsSet);
  };
  const onViewMessageFilesClick = (message) => __async(void 0, null, function* () {
    setShowViewFilesModal(true);
    setViewFilesModalMessage(message);
  });
  const getCesopResponse = (message) => {
    var _a, _b, _c;
    const error = (_a = message == null ? void 0 : message.Responses) == null ? void 0 : _a.find((response) => response instanceof CesopErrorFile);
    const validation = (_b = message == null ? void 0 : message.Responses) == null ? void 0 : _b.find((response) => response instanceof ValidCesopResponseFile);
    return (_c = error == null ? void 0 : error.FileInfo) != null ? _c : validation == null ? void 0 : validation.FileInfo;
  };
  const isCurrentVersion = (periodVersion) => (periodVersion == null ? void 0 : periodVersion.Version) === (period == null ? void 0 : period.CurrentVersion.Version);
  const sourceFileOrderer = (a, b) => {
    var _a, _b, _c, _d;
    return ((_b = (_a = a.FileInfo) == null ? void 0 : _a.Name) != null ? _b : a.FileName).localeCompare((_d = (_c = b.FileInfo) == null ? void 0 : _c.Name) != null ? _d : b.FileName);
  };
  const messageOrderer = (a, b) => {
    return a.FileName.localeCompare(b.FileName);
  };
  const submissionProofFilesTemplate = (periodVersion) => {
    var _a;
    return periodVersion.SubmissionProofFiles ? (_a = periodVersion.SubmissionProofFiles) == null ? void 0 : _a.map((proof) => periodFiles.submissionProofFileTemplate(proof, periodManager.downloadFile)) : "";
  };
  const buttonsTemplate = () => (period == null ? void 0 : period.Status) === PeriodStatus.DataUploaded ? html`
					<div class="flex gap-3 items-end mt-12">
						${PrimaryButton({
    class: "w-32",
    disabled: !(period == null ? void 0 : period.CurrentVersion.Summary),
    onClick: () => periodHandlers.handleApproveSummary(),
    text: "Approve"
  })}
						${SecondaryButton({
    class: "w-32",
    disabled: !(period == null ? void 0 : period.CurrentVersion.Summary),
    onClick: () => periodHandlers.handleRejectSummary(),
    text: "Reject"
  })}
					</div>
			  ` : "";
  const downloadXmlFilesTemplate = (periodVersion) => periodVersion.ArchiveFile !== void 0 ? html`
					<div class="flex flex-wrap gap-3 items-center">
						${TertiaryButton({ class: "text-sm", text: "Download XML Files", onClick: () => periodManager.downloadFile(period, periodVersion.ArchiveFile.Id) })}
						<span class="text-sm">(${i18next.t("Generated at")}: ${formatDateTimeUTC(periodVersion.ArchiveFile.GeneratedAt)})</span>
					</div>
			  ` : "";
  const messagesTemplate = (periodVersion) => periodVersion.Messages.sort(messageOrderer).map(
    (message) => html`
				<div class="flex flex-col">
					<div class="flex items-end gap-3">
						<div title=${message.FileName}>${fileIcon(message.FileName)}</div>
						<div class="text-sm truncate" title=${message.FileName}>${message.FileName}</div>
						<div class="text-sm whitespace-nowrap">(${formatDateTimeUTC(message.CreatedAtUtc)})</div>
					</div>
					<div class="flex gap-3">
						<div
							class=${classNames("text-sm ml-9", {
      "text-red-500": message.Status === MessageStatus.FullyRejected || message.Status === MessageStatus.PartiallyRejected,
      "text-green-600": message.Status === MessageStatus.AcceptedByCesop
    })}
						>
							${getMessageStatusLabel(message.Status)}
						</div>

						${message.Status !== MessageStatus.New ? html`
									<span
										class=${classNames("underline text-sm cursor-pointer", {
      "text-red-500": message.Status === MessageStatus.FullyRejected || message.Status === MessageStatus.PartiallyRejected
    })}
										@click=${() => onViewMessageFilesClick(message)}
										>${i18next.t("View files")}</span
									>
							  ` : ""}
					</div>
				</div>
			`
  );
  const normalSummaryTemplate = (periodVersion) => {
    var _a, _b, _c, _d;
    const summary = periodVersion.Summary;
    return html`
			${periodVersion.UploadType === "Csv" ? html`
						<div class="mb-3 text-sm">${i18next.t("Total number of transactions")}: <span class="font-semibold">${(_a = summary == null ? void 0 : summary.NumberOfTransactions) != null ? _a : i18next.t("No summary yet")}</span></div>
						<div class="mb-3 text-sm">${i18next.t("Total number of payees")}: <span class="font-semibold">${(_b = summary == null ? void 0 : summary.NumberOfPayees) != null ? _b : i18next.t("No summary yet")}</span></div>
				  ` : ""}
			${periodVersion.UploadType === "Xml" ? html` <div class="mb-3 text-sm">${i18next.t("Total number of files uploaded")}: <span class="font-semibold">${(_d = (_c = periodVersion.SourceFiles) == null ? void 0 : _c.length) != null ? _d : 0}</span></div> ` : ""}
		`;
  };
  const correctionSummaryTemplate = (periodVersion) => {
    var _a, _b, _c, _d, _e, _f;
    const summary = periodVersion.Summary;
    return html`
			${periodVersion.UploadType === "Csv" ? html`
						<div class="mb-3 text-sm">
							${i18next.t("Total number of corrected transactions")}: <span class="font-semibold">${(_a = summary == null ? void 0 : summary.NumberOfModifiedTransactions) != null ? _a : i18next.t("No summary yet")}</span>
						</div>
						<div class="mb-3 text-sm">
							${i18next.t("Total number of deleted transactions")}: <span class="font-semibold">${(_b = summary == null ? void 0 : summary.NumberOfDeletedTransactions) != null ? _b : i18next.t("No summary yet")}</span>
						</div>
						<div class="mb-3 text-sm">
							${i18next.t("Total number of corrected payees")}: <span class="font-semibold">${(_c = summary == null ? void 0 : summary.NumberOfModifiedPayees) != null ? _c : i18next.t("No summary yet")}</span>
						</div>
						<div class="mb-3 text-sm">
							${i18next.t("Total number of deleted payees")}: <span class="font-semibold">${(_d = summary == null ? void 0 : summary.NumberOfDeletedPayees) != null ? _d : i18next.t("No summary yet")}</span>
						</div>
				  ` : ""}
			${periodVersion.UploadType === "Xml" ? html`
						<div class="mb-3 text-sm">${i18next.t("Total number of correction files uploaded")}: <span class="font-semibold">${(_f = (_e = periodVersion.SourceFiles) == null ? void 0 : _e.length) != null ? _f : 0}</span></div>
				  ` : ""}
		`;
  };
  const summaryTemplate = (periodVersion) => periodVersion.Summary instanceof CorrectionSubmissionSummary ? correctionSummaryTemplate(periodVersion) : normalSummaryTemplate(periodVersion);
  const cesopUploadTitle = (periodVersion) => (periodVersion == null ? void 0 : periodVersion.Version) === 1 ? `${i18next.t("CESOP Upload")} - ${i18next.t("Initial upload")}` : `${i18next.t("CESOP Upload")} - ${(periodVersion == null ? void 0 : periodVersion.Version) - 1}. ${i18next.t("correction")}`;
  const uploadInfoTemplate = (periodVersion) => {
    var _a;
    return html`
		<div class=${classNames("flex flex-wrap lg:flex-nowrap justify-between", { "mt-6": isCurrentVersion(periodVersion) })}>
			<div class="flex w-full lg:w-1/2 flex-col gap-4">
				<div
					class=${classNames("text-xl font-bold", {
      hidden: !isCurrentVersion(periodVersion) || periodVersion.UploadType === "Empty" && (period == null ? void 0 : period.Status) === PeriodStatus.DocumentGenerationInProgress && (((_a = periodVersion.Messages) == null ? void 0 : _a.length) === 0 || periodVersion.Messages === void 0)
    })}
				>
					${cesopUploadTitle(periodVersion)}
					<div
						class=${classNames("flex items-center gap-2 text-sm text-gray-500 font-normal italic", {
      hidden: !(periodVersion == null ? void 0 : periodVersion.SubmittedAt)
    })}
					>
						<span class="truncate pr-1">${`Submitted to Tax Authority: ${formatDate(periodVersion == null ? void 0 : periodVersion.SubmittedAt)}`}</span>
					</div>
				</div>
				<div class=${classNames("flex flex-col gap-1", { hidden: periodVersion.UploadType === "Empty" && (period == null ? void 0 : period.Status) === PeriodStatus.DocumentGenerationInProgress })}>
					${summaryTemplate(periodVersion)} ${downloadXmlFilesTemplate(periodVersion)}
				</div>
				${periodVersion.Messages ? html`
							<div class="flex flex-col gap-4">
								<div class="text-xl font-bold">${i18next.t("Submission Files")}</div>
								${messagesTemplate(periodVersion)}
							</div>
					  ` : ""}
			</div>
			${summaryFilesTemplate(periodVersion)}
		</div>
	`;
  };
  const viewFilesModalContentTemplate = (message) => {
    var _a, _b;
    return html`
			<div class="flex flex-col gap-4 px-8 py-4">
				<div class="mb-2 w-full flex flex-col gap-2">
					<div class="whitespace-nowrap">${templateTitle(i18next.t("Submission File"))}</div>
					<div class="flex gap-2">
						<div class="truncate" title=${message == null ? void 0 : message.FileName}>${message == null ? void 0 : message.FileName}</div>
						<div class="whitespace-nowrap">(${formatDateTimeUTC(message == null ? void 0 : message.CreatedAtUtc)})</div>
					</div>
				</div>
				<div>
					<div class="mb-2">${templateTitle(i18next.t("Cesop Response File"))}</div>
					${getCesopResponse(message) ? periodFiles.cesopResponseFileTemplate(getCesopResponse(message), periodHandlers.onFileDownload) : i18next.t("No file found")}
				</div>
				<div class=${classNames({ hidden: ((_a = message == null ? void 0 : message.Responses) == null ? void 0 : _a.filter((response) => response instanceof ProofFile).length) === 0 })}>
					<div class="mb-2">${templateTitle(i18next.t("Other files"))}</div>
					${(_b = message == null ? void 0 : message.Responses) == null ? void 0 : _b.filter((response) => response instanceof ProofFile).map(
      (response) => periodFiles.messageProofFileTemplate(response, periodHandlers.onFileDownload)
    )}
				</div>
			</div>
		`;
  };
  const viewFilesModalTemplate = (header) => html`
			<dc-modal
				class="cesop-second-layer-modal"
				@close=${() => {
    setShowViewFilesModal(false);
    setViewFilesModalMessage(void 0);
  }}
				.visible=${viewFilesModalMessage && showViewFilesModal}
				.header=${header}
				.content=${viewFilesModalContentTemplate(viewFilesModalMessage)}
			></dc-modal>
		`;
  const statusTemplate = () => html`
		<div>
			<div class="flex items-center gap-3">
				<dc-dropdown
					.class=${classNames("w-80", {
    "status-color-orange": [
      PeriodStatus.SubmittedToTa,
      PeriodStatus.ReadyToSubmit,
      PeriodStatus.DataUploaded,
      PeriodStatus.DocumentGenerationInProgress,
      PeriodStatus.PreparingCorrection,
      PeriodStatus.CorrectionNeeded,
      PeriodStatus.SubmissionInProgress
    ].includes(period.Status),
    "status-color-green": period.Status === PeriodStatus.AcceptedByCesop
  })}
					.readonly=${true}
					.isStatusLike=${true}
					.buttonText=${getPeriodStatusLabel(period.Status)}
					.selectedValues=${[period.Status]}
				></dc-dropdown>
			</div>
			<div class="flex flex-col">
				${period.LastManualUpdateAtUtc && period.Status !== PeriodStatus.AwaitingData ? html`<span class="text-xs italic text-brand-primary">${i18next.t("Last changed")}: ${formatDateTimeUTC(period.LastManualUpdateAtUtc)}</span>` : ""}
			</div>
		</div>
	`;
  const toggleDetailsButton = (periodVersion) => html`
			<div
				class="px-2 py-1 hover:bg-brand-primary hover:text-white border border-brand-primary max-w-fit h-fit text-sm cursor-pointer rounded-md select-none"
				@click=${() => handleTogglePeriodVersionDetails(periodVersion)}
			>
				${openedVersionDetails.has(periodVersion.Id) ? i18next.t("Hide details") : i18next.t("Show details")}
			</div>
		`;
  const versionTemplate = (headerText, periodVersion) => html`
			<div class=${classNames("p-6", { "pb-0": isCorrectionFlow && (periodVersion.Version !== 1 || !openedVersionDetails.has(periodVersion.Id)) })}>
				<div
					class=${classNames("border-gray-300", {
    "pb-6 border-b border-solid": isCurrentVersion(periodVersion),
    "pt-6 border-t border-solid": !isCurrentVersion(periodVersion),
    "mb-6": openedVersionDetails.has(periodVersion.Id) || periodVersion.Version === 1
  })}
				>
					<div class="flex flex-wrap gap-4 justify-between">
						<div class="flex items-center gap-4">${titleTemplate(periodVersion)} ${!isCurrentVersion(periodVersion) ? toggleDetailsButton(periodVersion) : ""}</div>
						${isCurrentVersion(periodVersion) ? statusTemplate() : ""}
					</div>
					${isCurrentVersion(periodVersion) && headerText ? html`<div class="mt-3 font-semibold">${headerText}</div>` : ""}
				</div>
				${isCurrentVersion(periodVersion) || openedVersionDetails.has(periodVersion.Id) ? uploadInfoTemplate(periodVersion) : ""} ${isCurrentVersion(periodVersion) ? buttonsTemplate() : ""}
			</div>
		`;
  const dataUploadedTemplate = (periodVersion) => versionTemplate(
    i18next.t(
      "The below CESOP transactions have been uploaded successfully. Please review the below totals and approve if the summary looks correct. Alternatively, you can reject and start the upload process again."
    ),
    periodVersion
  );
  const documentGenerationTemplate = (periodVersion) => versionTemplate(
    html`
				<div class="flex gap-2">
					<div>${i18next.t("Document Generation In Progress")}...</div>
					<dc-loader2 .isLoading=${true} .size=${"small"}></dc-loader2>
				</div>
				<div>${i18next.t("This process may take a few minutes, you can close this window and check back later")}.</div>
			`,
    periodVersion
  );
  const documentsGeneratedTemplate = (periodVersion) => versionTemplate(void 0, periodVersion);
  const submissionInProgressTemplate = (periodVersion) => versionTemplate(i18next.t("Getting feedback from CESOP may take days, please check back later."), periodVersion);
  const preparingCorrectionTemplate = (periodVersion) => versionTemplate(i18next.t("Gathering information for all files."), periodVersion);
  const acceptedByTaTemplate = (periodVersion) => versionTemplate(void 0, periodVersion);
  const submittedTemplate = (periodVersion) => versionTemplate(void 0, periodVersion);
  const correctionNeededTemplate = (periodVersion) => versionTemplate(
    html`
				<div class="flex gap-2">
					<div>${i18next.t("Generating correction files")}...</div>
					<dc-loader2 .isLoading=${true} .size=${"small"}></dc-loader2>
				</div>
				<div>${i18next.t("This process may take a few minutes, you can close this window and check back later")}.</div>
			`,
    periodVersion
  );
  const errorMessage = (error) => {
    const message = formatValidationError(error);
    return html`<div>${message} (${i18next.t("Number of incorrect lines")}: ${error.TotalErrorCount})</div>`;
  };
  const errorModalContent = () => currentValidationErrorCollection ? html`<div class="p-6">
					<div
						class="text-sm hover:underline cursor-pointer my-3"
						@click=${() => {
    closeErrorModal();
  }}
					>
						${i18next.t("Close validation details")}
					</div>
					<div class="my-3">${currentValidationErrorCollection.Errors.map((error) => errorMessage(error))}</div>
			  </div>` : "";
  const errorModal = () => html`<dc-modal @close=${() => closeErrorModal()} .visible=${showErrorModal} .header=${i18next.t("Validation errors")} .content=${errorModalContent()}></dc-modal>`;
  const fileSizeLimit = (limit) => {
    if (limit instanceof FileSizeLimit) {
      return i18next.t("Maximum file size: {{size}}", { size: `${limit.MaxSize} ${resolveUnit(limit.Unit)}` });
    }
    if (limit instanceof TransactionCountLimit) {
      return i18next.t("Maximum number of transactions: {{count}}", { count: limit.MaxTransactionCount });
    }
    if (limit instanceof ZippedFileSizeLimit) {
      return i18next.t("Maximum zipped file size: {{size}}", { size: `${limit.MaxSize} ${resolveUnit(limit.Unit)}` });
    }
    if (limit instanceof TransactionCountOrFileSizeLimit) {
      return i18next.t("Maximum file size: {{size}} or maximum number of transactions: {{count}}", { size: `${limit.MaxSize} ${resolveUnit(limit.Unit)}`, count: limit.MaxTransactionCount });
    }
    return "";
  };
  const fileName = (name) => {
    return html`<div>
			<div>${i18next.t("File name format:")} ${name.Pattern}</div>
			<div class="text-xs italic">${i18next.t("eg")}.: ${name.Example}</div>
		</div>`;
  };
  const namespaceFormatter = (prefix, namespace) => i18next.t('please use "{{prefix}}" as prefix for "{{- namespace}}"', { prefix, namespace });
  const dutchXmlHint = () => period.ReportingCountry === "NL" ? html`<div class="text-sm my-2">
					<span>The file must contain explicit prefixes for the following namespaces:</span>
					<ul class="list-disc list-inside">
						<li>${namespaceFormatter("cesop:", "urn:ec.europa.eu:taxud:fiscalis:cesop:v1")}</li>
						<li>${namespaceFormatter("cm:", "urn:eu:taxud:commontypes:v1")}</li>
					</ul>
					<span>If the Netherlands specific format is used please include the following namesapces:</span>
					<ul class="list-disc list-inside">
						<li>${namespaceFormatter("idnl:", "http://xml.belastingdienst.nl/schemas/IDNL/1.0")}</li>
						<li>${namespaceFormatter("pspnl:", "http://xml.belastingdienst.nl/schemas/PSPNL/1.0")}</li>
					</ul>
			  </div>` : "";
  const countryXmlRequirements = () => countryConfiguration && html`
			<div class="mb-6 lg:my-6 lg:mt-16">
				<div class="text-sm my-1">${fileSizeLimit(countryConfiguration.FileSizeLimits)}</div>
				<div class="text-sm my-1">${fileName(countryConfiguration.FileNamePattern)}</div>
				${dutchXmlHint()}
			</div>
		`;
  const xmlTemplate = (periodVersion) => {
    var _a;
    return periodVersion.UploadType === PeriodVersionUploadType.Xml ? html`
					<div class="mt-6 lg:mb-6">
						<div class="text-xl font-bold mb-2">${i18next.t("Upload Files")}</div>
						<dc-dropzone-select
							class=${classNames("h-40 mb-3 rounded-xl bg-gray-100 block hover:border-2 hover:border-dashed hover:border-gray-500")}
							.multiple=${true}
							.accept=${[".xml"]}
							.uploadButtonLabel=${i18next.t("Select Files")}
							.note=${i18next.t("Please upload all your files")}
							.label=${i18next.t("Drop files here to upload")}
							.dragOverText=${i18next.t("Drop files here to upload")}
							.onFileUpload=${(files) => periodHandlers.onTransactionsFilesUpload(files)}
						></dc-dropzone-select>
						${(_a = periodVersion.SourceFiles) == null ? void 0 : _a.sort(sourceFileOrderer).map(
      (sourceFile) => periodFiles.sourceFileTemplate(sourceFile, periodHandlers.onFileDownload, openTransactionErrorModal, periodHandlers.handleDeleteSourceFile, isCurrentVersion(periodVersion))
    )}
					</div>
					${countryXmlRequirements()} ${errorModal()}
			  ` : "";
  };
  const uploadTextTemplate = () => {
    switch (uploadType) {
      case PeriodVersionUploadType.Csv:
        return html`<div class="text-sm italic">
					<span>
						${i18next.t(
          "Please use our upload templates and upload one csv template containing the reportable payees for the period and csv templates containing the reportable transactions for the period using the upload buttons below."
        )}
					</span>
					<span class="font-bold">${i18next.t("Please note that the payee file must be uploaded first.")}</span>
				</div>`;
      case PeriodVersionUploadType.Xml:
        return html` <div class="text-sm italic">
					${i18next.t(
          "Please upload the XML files containing reportable data for the period in the required format ready for submission to the tax authority. There is no need to encrypt your files as we will do that for you prior to submission."
        )}
				</div>`;
      case PeriodVersionUploadType.Empty:
        return html` <div class="text-sm italic mb-4">
					${i18next.t(
          "Please select Finalize if you have no reportable transactions for this country for this period. Please note you cannot undo this once Finalized and a NIL report will be submitted to the relevant tax authority."
        )}
				</div>`;
      default:
        return html``;
    }
  };
  const uploadTemplate = (periodVersion) => {
    var _a;
    return html`
		<div class=${classNames("p-6 flex flex-col", { "pb-0": isCorrectionFlow })}>
			<div class="flex justify-between">${titleTemplate(periodVersion)} ${statusTemplate()}</div>
			${!isCorrectionFlow ? html`
						<dc-multiswitch
							.label=${i18next.t("Please select how would you like to upload transactions.")}
							.customLabelClass=${i18next.t("block text-gray-800 text-xl font-bold mb-2")}
							.dataSource=${uploadTypes}
							.selectedItem=${uploadType}
							.readonly=${periodVersion.Payees || ((_a = periodVersion.SourceFiles) == null ? void 0 : _a.length)}
							@change=${(e) => periodHandlers.handleChangeUploadType(e.detail.value)}
						></dc-multiswitch>
				  ` : ""}
			${uploadTextTemplate()}
			<div class=${classNames("mt-6 text-xl font-bold", { hidden: !periodVersion.UploadType || periodVersion.UploadType === "Empty" })}>${cesopUploadTitle(periodVersion)}</div>
			<div class="grid grid-cols-1 lg:grid-cols-2 gap-8">${csvTemplate(periodVersion)} ${xmlTemplate(periodVersion)}</div>

			${periodVersion.UploadType !== void 0 ? html`
						${PrimaryButton({
      class: "max-w-fit",
      text: i18next.t("Finalize Data Upload"),
      onClick: finalizeUpload,
      disabled: periodVersion.UploadType !== PeriodVersionUploadType.Empty && (periodVersion.SourceFiles === void 0 || periodVersion.SourceFiles.length === 0 || periodVersion.SourceFiles.some((sf) => sf.Status !== SourceFileStatus.Validated))
    })}
				  ` : ""}
		</div>
	`;
  };
  const summaryFilesTemplate = (periodVersion) => {
    var _a, _b;
    return html`
		<div class="flex flex-col gap-2 w-full lg:w-1/2 lg:pl-16 mt-4 lg:mt-0">
			${periodVersion.UploadType === "Csv" ? html`
						<div class="flex flex-col gap-2 w-full">
							<div class="text-lg font-semibold">${i18next.t("Payee File")}</div>
							<div>
								${periodFiles.sourceFileTemplate(
      periodVersion.Payees,
      periodHandlers.onFileDownload,
      openTransactionErrorModal,
      periodHandlers.handleDeleteSourceFile,
      isCurrentVersion(periodVersion)
    )}
							</div>
						</div>
				  ` : ""}
			${periodVersion.UploadType !== "Empty" ? html`
						<div class="flex flex-col gap-2 w-full">
							<div class="text-lg font-semibold">${i18next.t("Transaction Files")}</div>
							<div>
								${(_a = periodVersion.SourceFiles) == null ? void 0 : _a.sort(sourceFileOrderer).map(
      (sourceFile) => periodFiles.sourceFileTemplate(
        sourceFile,
        periodHandlers.onFileDownload,
        openTransactionErrorModal,
        periodHandlers.handleDeleteSourceFile,
        isCurrentVersion(periodVersion)
      )
    )}
							</div>
						</div>
				  ` : ""}
			${((_b = periodVersion.SubmissionProofFiles) == null ? void 0 : _b.length) > 0 ? html`
						<div class="flex flex-col gap-2 items-start w-full">
							<div class="text-lg font-semibold">${i18next.t("Proofs")}</div>
							${submissionProofFilesTemplate(periodVersion)}
						</div>
				  ` : ""}
		</div>
	`;
  };
  const titleTemplate = (periodVersion) => html`
			<div class="flex items-center flex-wrap gap-4">
				<div class=${classNames("font-bold", { "text-2xl": isCurrentVersion(periodVersion), "text-xl": !isCurrentVersion(periodVersion) })}>
					${isCurrentVersion(periodVersion) ? countries.find((country) => country.Code === period.ReportingCountry).Name : cesopUploadTitle(periodVersion)}
				</div>
				<span
					class=${classNames("border rounded-full bg-gray-300 text-xs uppercase font-semibold py-2 px-4", {
    hidden: (period == null ? void 0 : period.Status) === PeriodStatus.AwaitingData || !isCurrentVersion(periodVersion)
  })}
				>
					${periodVersion.UploadType === "Empty" ? "Nil" : periodVersion.UploadType}
				</span>
			</div>
		`;
  const payeesFileUploaderTemplate = () => {
    var _a, _b;
    return html`
		<dc-dropzone-select
			class=${classNames("h-40 rounded-xl bg-gray-100 block mb-3", {
      "hover:border-2 hover:border-dashed hover:border-gray-500": !((_a = period.CurrentVersion.SourceFiles) == null ? void 0 : _a.length)
    })}
			.accept=${[".csv"]}
			.uploadButtonLabel=${"Select File"}
			.note=${isCorrectionFlow ? i18next.t("Please upload all your correction file") : i18next.t("Payees must be uploaded in a single file")}
			.label=${isCorrectionFlow ? i18next.t("Drop correction file here to upload") : i18next.t("Drop file here to upload")}
			.readonly=${(_b = period.CurrentVersion.SourceFiles) == null ? void 0 : _b.length}
			.dragOverText=${isCorrectionFlow ? i18next.t("Drop correction file here to upload") : i18next.t("Drop file here to upload")}
			.onFileUpload=${(files) => periodHandlers.onPayeesFileUpload(files)}
		></dc-dropzone-select>
		${period.CurrentVersion.Payees ? periodFiles.payeeSourceFileTemplate(period.CurrentVersion.Payees, periodHandlers.onFileDownload, openPayeeErrorModal, periodHandlers.handleDeletePayees) : ""}
	`;
  };
  const uploadPayeesCorrectionTemplate = () => html`
		<div class="mt-6">
			${PrimaryButton({
    class: "mb-4 max-w-fit",
    text: i18next.t("Download Payee Correction File"),
    onClick: () => {
      var _a;
      return periodManager.downloadFile(period, (_a = period == null ? void 0 : period.CurrentVersion.CorrectionFiles) == null ? void 0 : _a.PayeeCorrectionFile.Reference);
    }
  })}
			${payeesFileUploaderTemplate()}
		</div>
	`;
  const uploadPayeesTemplate = () => html`
		<div class="mt-6">
			<div class="flex justify-between items-end gap-8 mb-2">
				<div class="text-xl font-bold truncate">${i18next.t("Upload Payees")}</div>
				<a
					class="cursor-pointer underline text-gray-500 font-medium hover:text-brand-secondary truncate"
					href="/filetemplates/payee_template.csv"
					target="_blank"
					rel="noopener"
					download="Payees.csv"
					>${i18next.t("Download Template")}</a
				>
			</div>
			${payeesFileUploaderTemplate()}
		</div>
	`;
  const uploadTransactionFilesDropzoneTemplate = (periodVersion) => {
    var _a, _b, _c;
    return html`
		<dc-dropzone-select
			class=${classNames("h-40 rounded-xl bg-gray-100 block", {
      "hover:border-2 hover:border-dashed hover:border-gray-500": ((_a = periodVersion.Payees) == null ? void 0 : _a.Status) === SourceFileStatus.Validated
    })}
			.accept=${[".csv"]}
			.uploadButtonLabel=${"Select Files"}
			.note=${isCorrectionFlow ? i18next.t("Please upload all your correction files") : i18next.t("Please upload all your files")}
			.label=${isCorrectionFlow ? i18next.t("Drop correction files here to upload") : i18next.t("Drop files here to upload")}
			.readonly=${((_b = periodVersion.Payees) == null ? void 0 : _b.Status) !== SourceFileStatus.Validated}
			.dragOverText=${isCorrectionFlow ? i18next.t("Drop correction files here to upload") : i18next.t("Drop files here to upload")}
			.multiple=${true}
			.onFileUpload=${(files) => periodHandlers.onTransactionsFilesUpload(files)}
		></dc-dropzone-select>
		<div class="my-3 flex flex-col gap-4">
			${(_c = periodVersion.SourceFiles) == null ? void 0 : _c.sort(sourceFileOrderer).map(
      (sourceFile) => periodFiles.sourceFileTemplate(sourceFile, periodHandlers.onFileDownload, openTransactionErrorModal, periodHandlers.handleDeleteSourceFile, isCurrentVersion(periodVersion))
    )}
		</div>
	`;
  };
  const uploadTransactionCorrectionFilesTemplate = (periodVersion) => html`
		<div class="mt-6">
			${PrimaryButton({
    class: "mb-4 max-w-fit",
    text: i18next.t("Download Transaction Correction Files"),
    onClick: () => {
      var _a;
      return periodManager.downloadFile(period, (_a = periodVersion.CorrectionFiles) == null ? void 0 : _a.TransactionCorrectionArchiveFile.Reference);
    }
  })}
			${uploadTransactionFilesDropzoneTemplate(periodVersion)}
		</div>
		${errorModal()}
	`;
  const uploadTransactionsTemplate = (periodVersion) => html`
		<div class="mt-6">
			<div class="flex justify-between items-end gap-8 mb-2">
				<div class="text-xl font-bold truncate">${i18next.t("Upload Transactions")}</div>
				<a
					class="cursor-pointer underline text-gray-500 font-medium hover:text-brand-secondary truncate"
					href="/filetemplates/transaction_template.csv"
					target="_blank"
					rel="noopener"
					download="Transactions.csv"
					>${i18next.t("Download Template")}</a
				>
			</div>
			${uploadTransactionFilesDropzoneTemplate(periodVersion)}
		</div>
		${errorModal()}
	`;
  const csvCorrectionTemplate = (periodVersion) => html`${uploadPayeesCorrectionTemplate()} ${uploadTransactionCorrectionFilesTemplate(periodVersion)}`;
  const csvNoCorrectionTemplate = () => html`${uploadPayeesTemplate()} ${uploadTransactionsTemplate(period.CurrentVersion)}`;
  const csvTemplate = (periodVersion) => periodVersion.UploadType === PeriodVersionUploadType.Csv ? html` ${isCorrectionFlow ? csvCorrectionTemplate(periodVersion) : csvNoCorrectionTemplate()} ` : "";
  return {
    dataUploadedTemplate,
    documentGenerationTemplate,
    documentsGeneratedTemplate,
    submissionInProgressTemplate,
    preparingCorrectionTemplate,
    acceptedByTaTemplate,
    submittedTemplate,
    correctionNeededTemplate,
    uploadTemplate,
    titleTemplate,
    viewFilesModalTemplate
  };
};
