var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { MoneyAnswer as WebMoneyAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { MoneyAnswer as AdminMoneyAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { MoneyValidator } from "./AnswerValidators";
import { Money as WebMoney } from "WebServices/APIs/self/models/V1/Common";
import { Money as AdminMoney } from "AdminServices/APIs/self/models/V1/Common";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-money";
const DEFAULT_CURRENCY = "EUR";
const DEFAULTS = {
  validation: {
    required: false
  }
};
export class MoneyChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation,
    currency: host.currency !== void 0 ? host.currency : DEFAULT_CURRENCY
  };
  const getMoneyAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminMoneyAnswer({
        Value: new AdminMoney({ Value: currentVal, Currency: currency }),
        QuestionId: props.questionId
      });
    }
    return new WebMoneyAnswer({
      Value: new WebMoney({ Value: currentVal, Currency: currency }),
      QuestionId: props.questionId
    });
  };
  const [currentVal, setCurrentVal] = useState(void 0);
  const [currency] = useState(props.currency);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new MoneyValidator(props.validation));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new MoneyChangeEvent({
          answer: getMoneyAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof AdminMoneyAnswer || answer instanceof WebMoneyAnswer) {
        setCurrentVal(answer.Value.Value);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    validationContext.validate(getMoneyAnswer());
  }, [currentVal]);
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<dc-input
					.readonly=${props.q11e.isReadonly(props.questionId)}
					.label=${question.Label + (props.validation.required ? "*" : "")}
					.value=${currentVal}
					.tooltip=${question.Tooltip !== void 0 ? question.Tooltip : ""}
					.type=${"number"}
					.validationMessage=${validationContext.getValidationMessage("value")}
					@change=${(e) => {
    const tempVal = parseInt(e.detail.value, 10);
    setCurrentVal(isNaN(tempVal) ? void 0 : tempVal);
    setDispatchIfValid(true);
    validationContext.validateField("value");
  }}
			  ></dc-input>` : ""}
	`;
};
