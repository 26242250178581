var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
import classNames from "classnames";
import { uploadCloudIcon } from "GlobalShared/templates/commons";
import { validateExtension } from "./uiHelper";
const DEFAULTS = {
  note: "",
  label: "",
  uploadButtonLabel: "",
  readonly: false,
  dragOverText: "",
  multiple: false
};
export class DropEvent extends CustomEvent {
  constructor(detail) {
    super("drop", { detail });
  }
}
export const Component = (host) => {
  var _a, _b, _c, _d, _e, _f;
  const props = {
    note: (_a = host.note) != null ? _a : DEFAULTS.note,
    label: (_b = host.label) != null ? _b : DEFAULTS.label,
    uploadButtonLabel: (_c = host.uploadButtonLabel) != null ? _c : DEFAULTS.uploadButtonLabel,
    readonly: (_d = host.readonly) != null ? _d : DEFAULTS.readonly,
    accept: (_e = host.accept) != null ? _e : [],
    dragOverText: (_f = host.dragOverText) != null ? _f : DEFAULTS.dragOverText,
    multiple: host.multiple,
    onFileUpload: host.onFileUpload
  };
  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isActive) {
      return true;
    }
    if (props.readonly) {
      return false;
    }
    setIsActive(true);
    return true;
  };
  const onDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.readonly) {
      return false;
    }
    setIsActive(false);
    return true;
  };
  const onDrop = (e) => __async(void 0, null, function* () {
    var _a2;
    e.stopPropagation();
    e.preventDefault();
    setIsActive(false);
    if (props.readonly || Array.from(e.dataTransfer.files).some((f) => !validateExtension(f, props.accept))) {
      return false;
    }
    yield (_a2 = props.onFileUpload) == null ? void 0 : _a2.call(props, e.dataTransfer.files);
    return false;
  });
  const [isActive, setIsActive] = useState(false);
  const selectFileTemplate = () => html`
        <div class="flex items-center flex-col gap-2">
		<div class=${classNames("flex flex-col items-center justify-center", { "pointer-events-none": isActive, "text-gray-400": props.readonly })}>
			${props.label ? html`
							<div class="font-bold">${props.label}</div>
							<div>or</div>
					  ` : ""}
        <dc-fileupload2 .multiple=${props.multiple} .readonly=${props.readonly} .label=${props.uploadButtonLabel} .accept=${props.accept} @upload=${(e) => props.onFileUpload(e.detail.files)}>
        </dc-fileupload>
        </div>
            <div class=${classNames("text-sm", { "text-gray-400": props.readonly })}>${props.note}</div>
		</div>
	`;
  const dragOverTemplate = () => html`
			<div class="w-full h-full flex flex-col gap-2 items-center justify-center select-none pointer-events-none">
				<div>${uploadCloudIcon()}</div>
				<div class="font-bold">${props.dragOverText}</div>
			</div>
		`;
  return html`
		<div
			class=${classNames("w-full h-full flex items-center justify-around select-none", {
    "border-dashed border-2 rounded-xl border-brand-secondary-light bg-gray-50": isActive
  })}
			@dragover=${onDragOver}
			@dragleave=${onDragLeave}
			@drop=${onDrop}
		>
			${isActive ? dragOverTemplate() : selectFileTemplate()}
		</div>
	`;
};
customElements.define(
  "dc-dropzone-select",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
