var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./return-library.scss";
import { formatPeriod, generateAllPeriods } from "../../helpers/periodHelper";
import {
  ALL_ACTION_LABELS,
  ALL_STATUSES,
  ALL_TAX_RETURN_TYPES,
  ReturnLibraryFieldNames,
  getPaymentStatusLabel,
  getStatusLabel,
  PAYMENT_PROOF_DS,
  ALL_ESTABLISHMENT_COUNTRY_TYPES,
  ALL_TAX_RETURN_TYPE_LABELS,
  ALL_SUBMISSION_STATUS_LABELS,
  getWebStatuses
} from "./SubmissionModels";
import { useEffect, useLoadingReducer, useMemo, useQueryGridState, useState } from "GlobalShared/haunted/CustomHooks";
import { addDays, formatDate, formatDateTime, getDay } from "GlobalShared/helpers/dateHelper";
import { extractFirstError, notifyError, notifyErrorWithWarning, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { mapBooleanToState } from "GlobalShared/helpers/booleanHelper";
import { circleCheckedIcon, fileIcon, templateTitle, triangleExclamationIcon } from "GlobalShared/templates/commons";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import i18next from "i18next";
import { html } from "lit-html";
import { useReturnLibraryModals } from "./return-library-modals";
import moment from "moment";
import { useConfirmModal } from "../modals/commonModals";
import { mapBatchUploadErrorReason, SESSION_KEY_FOR_SUBMISSION_ONLY_SUBMISSION_PERIOD_IDS } from "./ReturnLibraryHelper";
import { SessionManagerStorage } from "GlobalShared/session/SessionManagerStorage";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import classNames from "classnames";
import { useHMRCModal } from "./useHMRCModal";
import { getParameter } from "GlobalShared/router";
const BATCH_UPLOAD_FILE_NAME_CONVENTION = "PROOF_Returntype_Country_Period_YearMonth_CompanyID_CompanyName_Version";
const SESSION_KEY_FOR_SUBMISSION_PERIOD_IDS = "returnLibrarySubmissionPeriodIds";
export const PERIOD_ID_QUERY_PARAM = "periodId";
const INITIAL_FILTERS = [];
const DEFAULT_FILTERS = [];
export const useReturnLibrary = (props) => {
  var _a;
  const loadSubmissions = (periodSubmissionIdsForFilter) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield props.searchSubmissions(
          {
            gridState,
            paymentProofFilter: selectedPaymentProofFilter,
            periodSubmissionIdsForFilter
          },
          onlyAssignedToMe,
          assigneeFragment,
          showLastPeriodsOnly
        );
        setSearchResult(result);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  });
  const onDocumentDownload = (submissionPeriodId, documentType, fileReference) => __async(void 0, null, function* () {
    yield props.downloadDocument(submissionPeriodId, documentType, fileReference);
  });
  const changeStatus = (submissionPeriodId, oldStatus, newStatus, comment, attachmentIds, isRevision, returnGeneratedModel, submittedModel) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.changeStatus(submissionPeriodId, oldStatus, newStatus, comment, attachmentIds, isRevision, returnGeneratedModel, submittedModel);
        notifySuccess(i18next.t("Status changed successfully"));
        yield loadSubmissions();
      }),
      (error) => {
        const firstError = extractFirstError(error);
        if ((firstError == null ? void 0 : firstError.code) === "SubmissionPeriodInvalidApproveAfterSubmissionDueDate") {
          notifyWarning(i18next.t("Return cannot be submitted after submission due date"));
        } else {
          notifyErrorWithWarning(error);
        }
      }
    );
  });
  const changePaymentStatus = (submissionPeriodId, newStatus) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.changePaymentStatus(submissionPeriodId, newStatus);
        notifySuccess(i18next.t("Payment status changed successfully"));
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        loadSubmissions();
      }
    );
  });
  const getNextAdminSubmissionOnlyStatuses = (item) => {
    let nextStatuses = [];
    const status = item.Status;
    if (status === "New") {
      nextStatuses = ["ReturnAvailable"];
    } else if (status === "ReturnAvailable") {
      nextStatuses = ["New", "SentToTA"];
    } else if (status === "ErrorAtTA") {
      nextStatuses = ["New", "ReturnAvailable", "Submitted"];
    } else if (status === "SentToTA") {
      nextStatuses = ["ReturnAvailable", "Submitted"];
    } else if (status === "Rejected") {
      nextStatuses = ["Submitted", "New"];
    } else if (status === "Submitted") {
      nextStatuses = ["New", "SentToTA", "Rejected"];
    }
    return nextStatuses;
  };
  const getNextAdminStatuses = (item) => {
    let nextStatuses = [];
    const status = item.Status;
    const isPaperFiling = !item.EFiling;
    if (status === "New") {
      nextStatuses = ["DataUploaded", "ReturnAvailable", "NotToBeSubmitted"];
    } else if (status === "DataUploaded") {
      nextStatuses = ["New", "ReturnAvailable"];
    } else if (status === "ErrorReturnGen") {
      nextStatuses = ["New", "DataUploaded", "ReturnAvailable"];
    } else if (status === "ReturnAvailable") {
      nextStatuses = ["New", "DataUploaded", "ApprovedByClient", "RefusedByClient", "NotToBeSubmitted"];
    } else if (status === "ApprovedByClient") {
      nextStatuses = ["ReturnAvailable", "RefusedByClient", "ErrorAtTA", "Submitted", "NotToBeSubmitted"];
      if (isPaperFiling) {
        nextStatuses = nextStatuses.concat(["SubmittedByClient"]);
      }
    } else if (status === "RefusedByClient") {
      nextStatuses = ["New", "ApprovedByClient", "ReturnAvailable", "NotToBeSubmitted"];
    } else if (status === "ErrorAtTA") {
      if (item.TaxReturnType === "IOSS") {
        nextStatuses = ["New", "ReturnAvailable", "ApprovedByClient"];
      } else {
        nextStatuses = ["New", "ReturnAvailable", "ApprovedByClient", "Submitted"];
      }
    } else if (status === "SentToTA") {
      nextStatuses = ["ErrorAtTA", "ReturnAvailable", "Rejected", "Submitted"];
    } else if (status === "Rejected") {
      nextStatuses = ["New", "SentToTA", "Submitted"];
    } else if (status === "Submitted") {
      nextStatuses = ["New", "SentToTA", "Rejected"];
    } else if (status === "SubmittedByClient") {
      nextStatuses = ["New", "ApprovedByClient"];
    } else if (status === "NotToBeSubmitted") {
      nextStatuses = ["New", "ReturnAvailable", "RefusedByClient", "ApprovedByClient"];
    }
    return nextStatuses;
  };
  const getNextPaymentStatuses = (paymentStatus) => {
    let nextStatuses = [];
    if (paymentStatus === "Due") {
      nextStatuses = ["Paid", "PaidByClient"];
    } else if (paymentStatus === "Paid") {
      nextStatuses = ["PaidByClient", "Due"];
    } else if (paymentStatus === "PaidByClient") {
      nextStatuses = ["Paid", "Due"];
    }
    return nextStatuses;
  };
  const getActionsDataSource = (item) => {
    if (props.isAdmin && !props.permissionManager.hasPermission("Submission_Period_Modify") || item.IsSubmissionOnly && item.Status !== "Submitted") {
      return [];
    }
    let actions = [];
    const isPaperFiling = !item.EFiling;
    if (!props.isAdmin) {
      if (item.Status === "ReturnAvailable") {
        actions = ["Approve", "Reject"];
      } else if (item.Status === "ApprovedByClient" && isPaperFiling) {
        actions = ["Submit"];
      } else if (item.Status === "RefusedByClient") {
        actions = (isPaperFiling ? ["Reopen"] : []).concat(["Approve"]);
      } else if (item.Status === "SubmittedByClient" && isPaperFiling) {
        actions = ["ReturnToApproved"];
      }
    } else {
      if (item.TaxReturnType !== "IOSS") {
        if (item.Status === "DataUploaded" || item.Status === "ErrorReturnGen") {
          actions = ["ManualUpload"];
        }
      }
    }
    const uploadPaymentProofStatuses = ["ApprovedByClient", "ErrorAtTA", "SentToTA", "Submitted", "SubmittedByClient"];
    if (props.isAdmin && item.Status === "NotToBeSubmitted") {
      actions.push("UploadNotToBeSubmittedProof");
    }
    if (uploadPaymentProofStatuses.includes(item.Status)) {
      if (item.TaxReturnType === "IOSS" || item.TaxReturnType === "PrimaryVAT" && item.PaymentCategory === "Payable") {
        actions.push("UploadPaymentProof");
      }
    }
    const payComplianceFeeStatuses = ["SentToTA", "Submitted", "SubmittedByClient"];
    if (payComplianceFeeStatuses.includes(item.Status) && item.TaxReturnType === "IOSS" && item.PaymentStatus === "Due" && item.PaymentUrl !== void 0) {
      actions.push("PayComplianceFee");
    }
    const submissionProofStatuses = ["Submitted", "SubmittedByClient"];
    if (submissionProofStatuses.includes(item.Status)) {
      if ((props.isAdmin || isPaperFiling) && item.TaxReturnType !== "Intrastat") {
        actions.push("UploadSubmissionProof");
      }
      if (props.isAdmin && item.TaxReturnType === "Intrastat") {
        actions.push("UploadIntrastatSubmissionProofs");
      }
    }
    return Object.keys(ALL_ACTION_LABELS).filter((a) => actions.includes(a)).map((a) => ({
      label: ALL_ACTION_LABELS[a],
      value: a
    }));
  };
  const onExportReturnClick = () => __async(void 0, null, function* () {
    yield props.exportReturns({
      gridState: __spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        pageSize: (searchResult == null ? void 0 : searchResult.total) === 0 ? 1 : searchResult == null ? void 0 : searchResult.total
      }),
      paymentProofFilter: selectedPaymentProofFilter
    });
  });
  const onBatchUpload = (files) => __async(void 0, null, function* () {
    const batchUploadResponse = yield props.proofBatchUpload(files, loader);
    if (batchUploadResponse && batchUploadResponse.length > 0) {
      setBatchUploadResult(batchUploadResponse);
      loadSubmissions();
    }
  });
  const onReturnUpload = (files) => __async(void 0, null, function* () {
    if ((files == null ? void 0 : files.length) > 0) {
      yield props.uploadReturnDocuments(returnUploadModalProps.submissionPeriodId, Array.from(files), loader);
      const submissionPeriodFiles = yield props.getSubmissionPeriodFiles(returnUploadModalProps.submissionPeriodId);
      setReturnUploadModalProps(__spreadProps(__spreadValues({}, returnUploadModalProps), { returnFiles: submissionPeriodFiles.ReturnFiles }));
    }
  });
  const onOpenReturnLibraryClick = (e, submissionPeriodId) => __async(void 0, null, function* () {
    var _a2;
    e.preventDefault();
    e.stopPropagation();
    let allErrorSubmissionPeriodIds;
    if (!submissionPeriodId) {
      allErrorSubmissionPeriodIds = batchUploadResult.filter((r) => r.SubmissionPeriodId && !r.IsSuccess).map((r) => r.SubmissionPeriodId);
    }
    sessionManagerStorage.setItem(SESSION_KEY_FOR_SUBMISSION_PERIOD_IDS, JSON.stringify(submissionPeriodId ? [submissionPeriodId] : allErrorSubmissionPeriodIds));
    const url = (_a2 = window.location.href.split("?")) == null ? void 0 : _a2[0];
    window.open(url, "_blank");
  });
  const onResetFilters = () => {
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS
    }));
    setShowNearDeadlines(false);
    setIsSubmissionPeriodFilterActive(false);
  };
  const onSortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: OrderByValue,
      orderDir: OrderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const onCloseBatchUploadModal = () => {
    if (sessionManagerStorage.getItem(SESSION_KEY_FOR_SUBMISSION_PERIOD_IDS)) {
      sessionManagerStorage.removeItem(SESSION_KEY_FOR_SUBMISSION_PERIOD_IDS);
    }
    setShowBatchUploadModal(false);
    setBatchUploadResult([]);
  };
  const onCloseUploadReturnModal = () => {
    setShowUploadReturnModal(false);
    setReturnUploadModalProps(void 0);
  };
  const handleSubmissionOnlyStatusDropdownChange = (e, item) => __async(void 0, null, function* () {
    const newSubmissionStatus = e.detail.selectedValue;
    yield changeStatus(item.Id, item.Status, newSubmissionStatus, void 0, void 0, false, void 0, void 0);
    if (newSubmissionStatus === "Submitted") {
      returnLibraryModalsHook.openUploadModal(item, "SubmissionProof");
    }
  });
  const isUKPrimaryVATSubmitButtonVisible = (item) => {
    var _a2;
    return props.isAdmin && (item.IsSubmissionOnly && item.Status === "ReturnAvailable" || !item.IsSubmissionOnly && item.Status === "ApprovedByClient") && props.permissionManager.hasPermission("Submission_Period_Submit") && ClientContext.FeatureFlags.UKSubmissionEnabled && ((_a2 = item.ServiceCountry) == null ? void 0 : _a2.Code) === "GB" && item.TaxReturnType === "PrimaryVAT";
  };
  const openHmrcModal = (submissionPeriodId) => __async(void 0, null, function* () {
    const vatDeclarationResponse = yield props.getUkVatDeclaration(submissionPeriodId, loader);
    setHmrcData({ vatDeclaration: vatDeclarationResponse, submissionPeriodId });
    hmrcModal.setIsUKPrimaryVATSubmissionModalOpen(true);
  });
  const [gridState, setGridState] = useQueryGridState({
    pageIndex: 0,
    appliedFilters: INITIAL_FILTERS,
    pageSize: 20,
    orderBy: "PeriodStartDate",
    orderDir: "desc"
  });
  const [searchResult, setSearchResult] = useState(void 0);
  const [selectedPaymentProofFilter, setSelectedPaymentProofFilter] = useState("All");
  const [onlyAssignedToMe, setOnlyAssignedToMe] = useState(false);
  const [showNearDeadlines, setShowNearDeadlines] = useState(false);
  const [showLastPeriodsOnly, setShowLastPeriodsOnly] = useState(false);
  const [assigneeFragment, setAssigneeFragment] = useState(void 0);
  const periodsDataSource = useMemo(() => generateAllPeriods(), []);
  const [showRejectionCommentModal, setShowRejectionCommentModal] = useState(false);
  const [showBatchUploadModal, setShowBatchUploadModal] = useState(false);
  const [showUploadReturnModal, setShowUploadReturnModal] = useState(false);
  const [rejectionComment, setRejectionComment] = useState(void 0);
  const [batchUploadResult, setBatchUploadResult] = useState(void 0);
  const [returnUploadModalProps, setReturnUploadModalProps] = useState(void 0);
  const [isSubmissionPeriodFilterActive, setIsSubmissionPeriodFilterActive] = useState(false);
  const [hmrcData, setHmrcData] = useState(void 0);
  const sessionManagerStorage = useMemo(() => new SessionManagerStorage(), []);
  const [loading, dispatchLoading] = useLoadingReducer();
  const loader = {
    start: () => dispatchLoading("inc"),
    stop: () => dispatchLoading("dec")
  };
  const confirmModal = useConfirmModal();
  const returnLibraryModalsHook = useReturnLibraryModals({
    availableCurrencies: props.availableCurrencies,
    loadSubmissions,
    changeStatus,
    uploadPaymentProof: props.uploadPaymentProof,
    uploadSubmissionProof: props.uploadSubmissionProof,
    uploadReturnDocuments: props.uploadReturnDocuments,
    downloadDocument: props.downloadDocument,
    uploadRejectionAttachments: props.uploadRejectionAttachments,
    uploadNotToBeSubmittedProof: props.uploadNotToBeSubmittedProof
  });
  const hmrcModal = useHMRCModal({
    hmrcData,
    submissionPeriodData: props.submissionPeriodData,
    loader,
    onSubmit: props.submitUKReturn,
    setSubmissionPeriodData: props.setSubmissionPeriodData
  });
  useEffect(() => __async(void 0, null, function* () {
    var _a2;
    if ((_a2 = props.submissionPeriodData) == null ? void 0 : _a2.successfullySubmitted) {
      yield loadSubmissions();
    }
  }), [(_a = props.submissionPeriodData) == null ? void 0 : _a.successfullySubmitted]);
  useEffect(() => __async(void 0, null, function* () {
    const submissionPeriodIds = JSON.parse(sessionManagerStorage.getItem(SESSION_KEY_FOR_SUBMISSION_PERIOD_IDS));
    const submissionOnlySubmissionPeriodIds = JSON.parse(localStorage.getItem(SESSION_KEY_FOR_SUBMISSION_ONLY_SUBMISSION_PERIOD_IDS));
    if (submissionPeriodIds) {
      yield loadSubmissions(submissionPeriodIds);
      sessionManagerStorage.removeItem(SESSION_KEY_FOR_SUBMISSION_PERIOD_IDS);
      return;
    }
    if (submissionOnlySubmissionPeriodIds) {
      yield loadSubmissions(submissionOnlySubmissionPeriodIds);
      localStorage.removeItem(SESSION_KEY_FOR_SUBMISSION_ONLY_SUBMISSION_PERIOD_IDS);
      setIsSubmissionPeriodFilterActive(true);
      return;
    } else {
      setIsSubmissionPeriodFilterActive(false);
    }
    yield loadSubmissions();
  }), [gridState, selectedPaymentProofFilter, onlyAssignedToMe, assigneeFragment, showLastPeriodsOnly]);
  useEffect(() => __async(void 0, null, function* () {
    if (showNearDeadlines) {
      const newStatuses = ["New", "DataUploaded", "ReturnAvailable", "ApprovedByClient", "RefusedByClient", "ErrorReturnGen"];
      setGridState(__spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        appliedFilters: mergeAppliedFilter(
          mergeAppliedFilter(gridState.appliedFilters, {
            field: ReturnLibraryFieldNames.SubmissionDueDate,
            filterProps: {
              operator: "in",
              min: /* @__PURE__ */ new Date(),
              max: addDays(/* @__PURE__ */ new Date(), 6)
            }
          }),
          {
            field: ReturnLibraryFieldNames.Status,
            filterProps: {
              operator: "in",
              selectedValues: newStatuses
            }
          }
        )
      }));
    } else {
      if (gridState.appliedFilters.length > 0) {
        setGridState(__spreadProps(__spreadValues({}, gridState), {
          pageIndex: 0,
          appliedFilters: gridState.appliedFilters.filter((f) => f.field !== ReturnLibraryFieldNames.Status && f.field !== ReturnLibraryFieldNames.SubmissionDueDate)
        }));
      }
    }
  }), [showNearDeadlines]);
  useEffect(() => __async(void 0, null, function* () {
    if (searchResult !== void 0) {
      yield withErrorHandling(
        () => __async(void 0, null, function* () {
          const newSubmissions = [];
          let changed = false;
          for (const sub of searchResult.submissions) {
            if (sub.DetailsOpen && sub.ReturnFiles === void 0) {
              const newSubmissionFiles = yield props.getSubmissionPeriodFiles(sub.Id);
              const newSubmission = __spreadValues(__spreadValues({}, sub), newSubmissionFiles);
              newSubmission.DetailsOpen = true;
              newSubmissions.push(newSubmission);
              changed = true;
            } else {
              newSubmissions.push(sub);
            }
          }
          if (changed) {
            setSearchResult(__spreadProps(__spreadValues({}, searchResult), { submissions: newSubmissions }));
          }
        }),
        (error) => {
          notifyError(error);
        },
        void 0,
        loader
      );
    }
  }), [searchResult]);
  useEffect(() => {
    if (window.location.search.includes(PERIOD_ID_QUERY_PARAM)) {
      const periodId = getParameter(PERIOD_ID_QUERY_PARAM);
      openHmrcModal(periodId);
    }
  }, []);
  const htmlSubmissionOnlyStatusDropdown = (item) => html`
			<dc-dropdown
				.isStatusLike=${true}
				.buttonText=${getStatusLabel(item.Status)}
				.selectedValues=${[item.Status]}
				.align=${"right"}
				.readonly=${!props.permissionManager.hasPermission("Submission_Period_Modify")}
				.dataSource=${getNextAdminSubmissionOnlyStatuses(item).map((status) => ({
    label: getStatusLabel(status),
    value: status
  }))}
				@change=${(e) => handleSubmissionOnlyStatusDropdownChange(e, item)}
			></dc-dropdown>
		`;
  const htmlFullComplianceStatusDropdown = (item) => html`
			<dc-dropdown
				.isStatusLike=${true}
				.buttonText=${getStatusLabel(item.Status)}
				.selectedValues=${[item.Status]}
				.align=${"right"}
				.readonly=${!props.permissionManager.hasPermission("Submission_Period_Modify")}
				.dataSource=${getNextAdminStatuses(item).map((status) => ({
    label: getStatusLabel(status),
    value: status
  }))}
				@change=${(e) => __async(void 0, null, function* () {
    var _a2;
    const newSubmissionStatus = e.detail.selectedValue;
    if (newSubmissionStatus === "New" && item.TaxReturnType !== "IOSS") {
      returnLibraryModalsHook.openStatusChangedToNewModal(item);
    } else if (newSubmissionStatus === "ReturnAvailable" && item.Status !== "ApprovedByClient" && (item.TaxReturnType === "Intrastat" || item.TaxReturnType === "PrimaryVAT" || item.TaxReturnType === "OSS")) {
      returnLibraryModalsHook.openStatusChangedToReturnGeneratedModal(item);
    } else if (item.TaxReturnType === "PrimaryVAT" && item.PaymentCategory === "Payable" && ((_a2 = item.ServiceCountry) == null ? void 0 : _a2.Code) === "ES" && (item.Status === "ApprovedByClient" || item.Status === "Rejected" || item.Status === "SentToTA") && newSubmissionStatus === "Submitted") {
      returnLibraryModalsHook.openESSubmissionModal(item);
    } else {
      if (newSubmissionStatus === "ApprovedByClient" && item.TaxReturnType === "IOSS" && item.PaymentDueDate.getDate() < Date.now()) {
        notifyWarning(i18next.t("Please note that your return will only be submitted if payment is received by the VAT intermediary before the submission deadline."));
      }
      const oldSubmissionStatus = item.Status;
      yield changeStatus(item.Id, oldSubmissionStatus, newSubmissionStatus, void 0, void 0, void 0, void 0, void 0);
      if (oldSubmissionStatus === "ApprovedByClient" && newSubmissionStatus === "Submitted") {
        returnLibraryModalsHook.openUploadModal(item, "SubmissionProof");
      }
    }
  })}
			></dc-dropdown>
		`;
  const htmlUKPrimaryVATSubmitButton = (item) => isUKPrimaryVATSubmitButtonVisible(item) ? PrimaryButton({ class: "max-w-fit", size: "small", text: "Submit", onClick: () => openHmrcModal(item.Id) }) : "";
  const htmlSubmissionDetails = (submission, index) => {
    var _a2, _b, _c;
    if (submission.ReturnFiles === void 0) {
      return html` <div>${i18next.t("Loading")}...</div>`;
    } else {
      return html` <div class="space-y-4">
				<div class=${classNames({ hidden: ClientContext.IsPartner })}>
					<div class="font-bold">${i18next.t("Transactional data")}</div>
					<div class="mt-2">
						${submission.TransactionFiles ? submission.TransactionFiles.map((f) => {
        return html` <div>
										<a @click=${() => onDocumentDownload(submission.Id, "Transaction", f.Reference)}>${f.Name}${f.CreatedAt ? html` (${formatDateTime(f.CreatedAt)})` : ""}</a>
									</div>`;
      }) : ""}
					</div>
				</div>
				${props.isAdmin ? html` <div>
								<div class="font-bold">DataVat</div>
								<div class="mt-2">
									${submission.DataVatFiles ? submission.DataVatFiles.map((f) => {
        return html` <div>
													<a @click=${() => onDocumentDownload(submission.Id, "DataVat", f.Reference)}
														>${f.Name}${f.CreatedAt ? html` (${formatDateTime(f.CreatedAt)})` : ""}</a
													>
												</div>`;
      }) : ""}
								</div>
							</div>
							<div>
								<div class="font-bold">Out of scope files</div>
								<div class="mt-2">
									${submission.OutOfScopeFiles ? submission.OutOfScopeFiles.map((f) => {
        return html` <div>
													<a @click=${() => onDocumentDownload(submission.Id, "OutOfScopeFile", f.Reference)}
														>${f.Name}${f.CreatedAt ? html` (${formatDateTime(f.CreatedAt)})` : ""}</a
													>
												</div>`;
      }) : ""}
								</div>
							</div>` : ""}
				<div class=${classNames({ hidden: ClientContext.IsPartner })}>
					<div class="font-bold">${i18next.t("Invalid validation reports")}</div>
					<div class="mt-2">
						${submission.InvalidVatNumbersReportFiles ? submission.InvalidVatNumbersReportFiles.map((f) => {
        return html` <div>
										<a @click=${() => onDocumentDownload(submission.Id, "InvalidVatNumbersReport", f.Reference)}
											>${f.Name}${f.CreatedAt ? html` (${formatDateTime(f.CreatedAt)})` : ""}</a
										>
									</div>`;
      }) : ""}
					</div>
				</div>
				<div>
					<div class="font-bold">${i18next.t("Return documents")}</div>
					<div class="mt-2">
						${submission.ReturnFiles ? submission.ReturnFiles.map((f) => {
        return html` <div>
										<a @click=${() => onDocumentDownload(submission.Id, "Return", f.Reference)}
											>${f.Name}${f.CreatedAt ? html` (V${f.Version} - ${formatDateTime(f.CreatedAt)})` : ""}</a
										>
									</div>`;
      }) : ""}
					</div>
				</div>
				${submission.PaymentProof ? html` <div>
							<div class="font-bold">${i18next.t("Payment proof")}</div>
							<div class="mt-2">
								<a @click=${() => onDocumentDownload(submission.Id, "PaymentProof", submission.PaymentProof.Reference)}>${submission.PaymentProof.Name}</a>
							</div>
					  </div>` : ""}
				${submission.SubmissionProofs && submission.SubmissionProofs.length > 0 ? html` <div>
							<div class="font-bold">${i18next.t("Submission proof")}</div>
							${submission.SubmissionProofs.map(
        (proof) => html` <div class="mt-2">
										<a @click=${() => onDocumentDownload(submission.Id, "SubmissionProof", proof.ReferenceId)}
											>${proof.Name} (v${proof.Version})${proof.Date ? html` (${formatDateTime(proof.Date)})` : ""}</a
										>
									</div>`
      )}
					  </div>` : ""}
				${submission.NotToBeSubmittedProof ? html` <div>
							<div class="font-bold">${i18next.t("Not to be submitted proof")}</div>
							<div class="mt-2">
								<a @click=${() => onDocumentDownload(submission.Id, "NotToBeSubmittedProof", submission.NotToBeSubmittedProof.Reference)}
									>${submission.NotToBeSubmittedProof.Name}${submission.NotToBeSubmittedProof.CreatedAt ? html`(${formatDateTime(submission.NotToBeSubmittedProof.CreatedAt)})` : ""}</a
								>
							</div>
					  </div>` : ""}
				${props.isAdmin && submission.PaymentReference ? html` <div class="flex space-x-1">
							<div class="font-bold">Reference:</div>
							<div>${submission.PaymentReference}${submission.SubmissionDate ? html` (${formatDate(submission.SubmissionDate)})` : ""}</div>
					  </div>` : ""}
				${props.isAdmin && ((_a2 = submission.Errors) == null ? void 0 : _a2.length) > 0 ? html` <div>
							<div class="font-bold text-red-500">${submission.TaxReturnType === "IOSS" ? "Desucla Errors" : "Submission Errors"}</div>
							<div class="mt-2">
								${submission.Errors.map((e) => {
        return html` <div class="text-red-500">${e}</div>`;
      })}
							</div>
					  </div>` : ""}
				${props.isAdmin && ((_b = submission.Warnings) == null ? void 0 : _b.length) > 0 ? html`
							<div class="flex flex-col gap-2">
								<div class="font-bold text-red-500">Submission Warnings</div>
								<dc-checkbox
									.checked=${submission.IgnoreWarnings}
									.label=${"Ignore warnings"}
									@change=${(e) => __async(void 0, null, function* () {
        setSearchResult(__spreadProps(__spreadValues({}, searchResult), {
          submissions: searchResult.submissions.map((o, i) => {
            if (i === index) {
              return __spreadProps(__spreadValues({}, o), { IgnoreWarnings: e.detail.checked });
            } else {
              return o;
            }
          })
        }));
        yield props.updateIgnoreWarnings(submission.Id, e.detail.checked);
      })}
								></dc-checkbox>
								<div class="flex flex-col gap-2">${submission.Warnings.map((e) => html` <div class="text-red-500">${e}</div>`)}</div>
							</div>
					  ` : ""}
				${props.isAdmin && ((_c = submission.RejectReasons) == null ? void 0 : _c.length) > 0 ? html`
                            <div>
                                <div class="font-bold">Return Refused Reason</div>
                                <div class="mt-2 space-y-3">
                                    ${submission.RejectReasons.map((e) => {
        var _a3, _b2, _c2, _d;
        return html` <div class="ml-4">
											<div class="flex">
												<div class="font-bold mr-1">Date:</div>
												<div>${formatDateTime(e.RejectedAt)}</div>
											</div>
											<div class="flex">
												<div class="font-bold mr-1">Comment:</div>
												<div>${(_a3 = e.Comment) == null ? void 0 : _a3.substring(0, 200)}</div>
												${((_b2 = e.Comment) == null ? void 0 : _b2.length) > 200 ? html` <div>...</div>
															<dc-view-button
																class="my-auto ml-2"
																title="View full comment"
																@click=${() => {
          setRejectionComment(e.Comment);
          setShowRejectionCommentModal(true);
        }}
															></dc-view-button>` : ""}
											</div>
											${((_c2 = e.ReturnFiles) == null ? void 0 : _c2.length) > 0 ? html`
                                                        <div class="font-bold">Attached files:</div>
                                                        <div>
                                                            ${(_d = e.ReturnFiles) == null ? void 0 : _d.map((rf) => {
          return html` <div>
																	<a @click=${() => onDocumentDownload(submission.Id, "RefusedReasonFile", rf.Reference)}
																		>${rf.Name}${rf.CreatedAt ? html` (${formatDateTime(rf.CreatedAt)})` : ""}</a
																	>
																</div>`;
        })}
                                                        </div></div>` : ""}
										</div>`;
      })}
                                </div>
                            </div>
                            </div>` : ""}
			</div>`;
    }
  };
  const htmlGrid = () => {
    var _a2;
    const columns = [
      {
        field: ReturnLibraryFieldNames.Details,
        label: "",
        columnClass: "w-10"
      }
    ].concat(
      props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.CompanyId,
          label: i18next.t("ID"),
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: ReturnLibraryFieldNames.CompanyName,
          columnClass: "w-20",
          label: i18next.t("Client name"),
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        }
      ] : []
    ).concat([
      {
        field: ReturnLibraryFieldNames.TaxReturnType,
        label: i18next.t("Return type"),
        filterable: true,
        sortable: true,
        filterDescriptor: {
          type: "select",
          multiSelect: true,
          searchable: true,
          searchProps: { type: "includes" },
          dataSource: ALL_TAX_RETURN_TYPES.map((rt) => ({
            value: rt,
            label: ALL_TAX_RETURN_TYPE_LABELS[rt]
          }))
        },
        columnType: "enum"
      },
      {
        field: ReturnLibraryFieldNames.ServiceCountry,
        columnClass: "w-16",
        label: i18next.t("Country"),
        filterable: true,
        sortable: true,
        filterDescriptor: {
          type: "select",
          dataSource: props.worldCountries.map((sc) => ({ label: sc.Name, value: sc.Code })),
          searchable: true,
          searchProps: { type: "includes" }
        },
        columnType: "enum"
      }
    ]).concat(
      props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.EstablishmentCountryType,
          label: i18next.t("Est. Country type"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: ALL_ESTABLISHMENT_COUNTRY_TYPES.map((ct) => ({ label: ct, value: ct }))
          },
          columnType: "enum"
        }
      ] : []
    ).concat([
      {
        field: ReturnLibraryFieldNames.PeriodStartDate,
        label: i18next.t("Period"),
        sortable: true,
        filterable: true,
        filterDescriptor: {
          type: "select",
          searchable: true,
          searchProps: { type: "includes" },
          multiSelect: true,
          dataSource: periodsDataSource
        },
        columnType: "enum"
      },
      {
        field: ReturnLibraryFieldNames.ClientApprovalDueDate,
        label: i18next.t("Approval due"),
        sortable: true,
        filterable: true,
        columnType: "date",
        filterDescriptor: { from: true, to: true, type: "date" }
      },
      {
        field: ReturnLibraryFieldNames.SubmissionDueDate,
        label: i18next.t("Submission due"),
        sortable: true,
        filterable: true,
        columnType: "date",
        filterDescriptor: { from: true, to: true, type: "date" }
      }
    ]).concat(
      props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.ReturnGenerationDueDate,
          label: i18next.t("Return gen due"),
          sortable: true,
          filterable: true,
          columnType: "date",
          filterDescriptor: { from: true, to: true, type: "date" }
        }
      ] : []
    ).concat([
      {
        field: ReturnLibraryFieldNames.PaymentDueDate,
        label: i18next.t("Payment due"),
        sortable: true,
        filterable: true,
        columnType: "date",
        filterDescriptor: { from: true, to: true, type: "date" }
      },
      {
        field: ReturnLibraryFieldNames.PaymentStatus,
        columnClass: "w-24",
        label: i18next.t("Payment status"),
        // TODO: implement filter, sort
        // sortable: true,
        // filterable: true,
        // filterDescriptor: {
        // 	type: "select",
        // 	multiSelect: true,
        // 	dataSource: ALL_PAYMENT_STATUSES,
        // },
        // columnType: "enum",
        columnType: "string"
      }
    ]).concat(
      props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.Status,
          columnClass: "w-64",
          label: i18next.t("Status"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            searchable: true,
            searchProps: { type: "includes" },
            dataSource: ALL_STATUSES.map((s) => ({
              value: s,
              label: ALL_SUBMISSION_STATUS_LABELS[s]
            })),
            rightAlign: true
          },
          columnType: "enum"
        },
        {
          field: ReturnLibraryFieldNames.Revision,
          columnClass: "w-10",
          label: i18next.t("Revision"),
          columnType: "string"
        }
      ] : []
    ).concat(
      !props.isAdmin ? [
        {
          field: ReturnLibraryFieldNames.Status,
          columnClass: "w-64",
          label: i18next.t("Status"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            searchable: true,
            searchProps: { type: "includes", ignoreCase: true },
            dataSource: getWebStatuses(props.hasSubmissionOnlySubscription).map((s) => ({
              value: s,
              label: ALL_SUBMISSION_STATUS_LABELS[s]
            })),
            rightAlign: true
          },
          columnType: "enum"
        }
      ] : []
    ).concat([
      {
        field: ReturnLibraryFieldNames.Actions,
        label: ""
      }
    ]);
    const vm = {
      columns,
      data: searchResult.submissions,
      // eslint-disable-next-line complexity
      cellTemplate: (index, field) => {
        var _a3, _b;
        const item = searchResult.submissions[index];
        const htmlDueDate = (dueDate, dateToCheck) => {
          if (dueDate === void 0) {
            return i18next.t("Not Applicable");
          } else if (dateToCheck === void 0 && getDay(dueDate) < getDay(/* @__PURE__ */ new Date())) {
            return html`<span class="text-red-500">${formatDate(dueDate)}</span>`;
          } else {
            return formatDate(dueDate);
          }
        };
        if (field === ReturnLibraryFieldNames.Details) {
          return html`
						<button
							class="focus:outline-none"
							@click=${() => {
            setSearchResult(__spreadProps(__spreadValues({}, searchResult), {
              submissions: searchResult.submissions.map((o, i) => {
                if (i === index) {
                  return __spreadProps(__spreadValues({}, o), { DetailsOpen: !o.DetailsOpen });
                } else {
                  return o;
                }
              })
            }));
          }}
						>
							<div class="px-1 font-bold w-5 transform ${item.DetailsOpen ? "-rotate-90" : "rotate-90"}">&#10095;</div>
						</button>
					`;
        } else if (field === ReturnLibraryFieldNames.CompanyId) {
          return item.ClientId.toString();
        } else if (field === ReturnLibraryFieldNames.CompanyName) {
          return html` <div class="absolute left-0 right-0 vertical-center truncate px-3" title="${item.ClientName}">${item.ClientName}</div>`;
        } else if (field === ReturnLibraryFieldNames.TaxReturnType) {
          return ALL_TAX_RETURN_TYPE_LABELS[item.TaxReturnType];
        } else if (field === ReturnLibraryFieldNames.ServiceCountry) {
          return ((_a3 = item.ServiceCountry) == null ? void 0 : _a3.Code) === "EL" ? "GR" : (_b = item.ServiceCountry) == null ? void 0 : _b.Code;
        } else if (field === ReturnLibraryFieldNames.EstablishmentCountryType) {
          return item.EstablishmentCountryType;
        } else if (field === ReturnLibraryFieldNames.PeriodStartDate) {
          return formatPeriod(moment.utc(item.PeriodStartDate), item.FrequencyType);
        } else if (field === ReturnLibraryFieldNames.ReturnGenerationDueDate) {
          return htmlDueDate(item.ReturnGenerationDueDate, item.ReturnGenerationDate);
        } else if (field === ReturnLibraryFieldNames.ClientApprovalDueDate) {
          return htmlDueDate(item.ClientApprovalDueDate, item.ClientApprovalDate);
        } else if (field === ReturnLibraryFieldNames.SubmissionDueDate) {
          return htmlDueDate(item.SubmissionDueDate, item.SubmissionDate);
        } else if (field === ReturnLibraryFieldNames.PaymentDueDate) {
          return htmlDueDate(item.SubmittedAndPaidDueDate, item.SubmittedAndPaidDate);
        } else if (field === ReturnLibraryFieldNames.PaymentProofUploaded) {
          if (item.PaymentProof) {
            return html`<a
							class="absolute left-0 right-0 vertical-center truncate"
							title="${item.PaymentProof.Name}"
							@click=${() => {
              props.downloadDocument(item.Id, "PaymentProof", item.PaymentProof.Reference);
            }}
							>${item.PaymentProof.Name}</a
						>`;
          } else {
            return "";
          }
        } else if (field === ReturnLibraryFieldNames.Status) {
          if (props.isAdmin) {
            return item.IsSubmissionOnly ? htmlSubmissionOnlyStatusDropdown(item) : htmlFullComplianceStatusDropdown(item);
          } else {
            if (item.IsSubmissionOnly) {
              let status;
              if (item.Status === "DataUploaded") {
                status = i18next.t("Awaiting return");
              } else if (item.Status === "ReturnAvailable") {
                status = i18next.t("Return available");
              } else {
                status = ALL_SUBMISSION_STATUS_LABELS[item.Status];
              }
              return html`<span title=${status}>${status}</span>`;
            }
            const localizedStatus = ALL_SUBMISSION_STATUS_LABELS[item.Status];
            return html`<span title=${localizedStatus}>${localizedStatus}</span>`;
          }
        } else if (field === ReturnLibraryFieldNames.PaymentStatus) {
          const readOnly = props.isAdmin && !props.permissionManager.hasPermission("Submission_Period_Modify") || !props.isAdmin && item.TaxReturnType !== "PrimaryVAT";
          const nextPaymentStatuses = getNextPaymentStatuses(item.PaymentStatus);
          if (readOnly || nextPaymentStatuses.length === 0) {
            return getPaymentStatusLabel(item.PaymentStatus);
          } else {
            return html` <dc-dropdown
							.isStatusLike=${true}
							.buttonText=${getPaymentStatusLabel(item.PaymentStatus)}
							.selectedValues=${[item.PaymentStatus]}
							.align=${"right"}
							.dataSource=${nextPaymentStatuses.map((status) => ({
              label: getPaymentStatusLabel(status),
              value: status
            }))}
							@change=${(e) => {
              const newPaymentStatus = e.detail.selectedValue;
              changePaymentStatus(item.Id, newPaymentStatus);
            }}
						></dc-dropdown>`;
          }
        } else if (field === ReturnLibraryFieldNames.Revision) {
          return mapBooleanToState(item.Revision);
        } else if (field === ReturnLibraryFieldNames.Actions) {
          if (!props.isAdmin && item.IsSubmissionOnly && item.Status === "New") {
            return html`
							${PrimaryButton({
              text: i18next.t("Upload return"),
              size: "small",
              class: "m-1 max-w-fit",
              onClick: () => __async(void 0, null, function* () {
                const files = yield props.getSubmissionPeriodFiles(item.Id);
                setReturnUploadModalProps({ submissionPeriodId: item.Id, returnFiles: files.ReturnFiles });
                setShowUploadReturnModal(true);
              })
            })}
						`;
          }
          return html`<div class="flex items-center gap-2 m-1">${htmlTableActionDropdown(item)}${htmlUKPrimaryVATSubmitButton(item)}</div>`;
        } else {
          return "";
        }
      },
      cellClass: (_index, field) => {
        if (field === ReturnLibraryFieldNames.Actions || field === ReturnLibraryFieldNames.Status || field === ReturnLibraryFieldNames.PaymentStatus) {
          return "actions";
        }
        return "";
      },
      rowDetailsTemplate: (index) => {
        if (searchResult.submissions[index].DetailsOpen) {
          return html` <tr style="background-color:white">
						<td colspan="8">
							<div class="my-4">${htmlSubmissionDetails(searchResult.submissions[index], index)}</div>
						</td>
					</tr>`;
        } else {
          return html``;
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult.total
      }
    };
    return html`
			<div>
				<div>${templateTitle(i18next.t("Return Library"))}</div>
				<div class="flex flex-wrap gap-4 my-4 items-end">
					${props.isAdmin ? html`
								<dc-input
									class="w-64"
									.label=${"Assignee"}
									.value=${assigneeFragment}
									@change=${(e) => {
      setAssigneeFragment(e.detail.value);
    }}
								></dc-input>
						  ` : ""}
					<dc-select
						class="w-64"
						.label=${i18next.t("Payment proof")}
						.dataSource=${PAYMENT_PROOF_DS}
						.selectedValues=${selectedPaymentProofFilter}
						@change=${(e) => {
      const newVal = e.detail.selectedValue;
      setSelectedPaymentProofFilter(newVal);
    }}
					></dc-select>
					${props.isAdmin ? html`
								<button
									class="btn btn-primary"
									?disabled=${(searchResult == null ? void 0 : searchResult.total) === 0}
									@click=${() => props.exportSubmissions({
      gridState: __spreadProps(__spreadValues({}, gridState), {
        pageIndex: 0,
        pageSize: (searchResult == null ? void 0 : searchResult.total) === 0 ? 1 : searchResult == null ? void 0 : searchResult.total
      }),
      paymentProofFilter: selectedPaymentProofFilter
    })}
								>
									Export as CSV
								</button>
								<button class="btn btn-primary" ?disabled=${(searchResult == null ? void 0 : searchResult.total) === 0} @click=${onExportReturnClick}>Export Returns</button>
								<button class="btn btn-primary" @click=${() => setShowBatchUploadModal(true)}>PROOF Batch Upload</button>
						  ` : ""}
					${props.isAdmin ? html`
								<div>
									<dc-checkbox2
										.checked=${onlyAssignedToMe}
										.label=${"Only assigned to me"}
										.labelnowrap=${true}
										@change=${(e) => {
      setOnlyAssignedToMe(e.detail.checked);
    }}
									></dc-checkbox2>
								</div>
								<div>
									<dc-checkbox2
										.checked=${showNearDeadlines}
										.label=${"Show near deadlines"}
										.labelnowrap=${true}
										@change=${(e) => {
      setShowNearDeadlines(e.detail.checked);
    }}
									></dc-checkbox2>
								</div>
						  ` : ""}
					<div>
						<dc-checkbox2
							.checked=${showLastPeriodsOnly}
							.label=${i18next.t("Show last periods only")}
							.labelnowrap=${true}
							@change=${(e) => {
      setShowLastPeriodsOnly(e.detail.checked);
    }}
						></dc-checkbox2>
					</div>
					${((_a2 = gridState.appliedFilters) == null ? void 0 : _a2.length) > 0 || isSubmissionPeriodFilterActive ? html`
								<div>
									<button class="btn btn-primary whitespace-nowrap" @click=${onResetFilters}>${i18next.t("Reset filters")}</button>
								</div>
						  ` : ""}
				</div>
				<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
			</div>
		`;
  };
  const htmlTableActionDropdown = (item) => getActionsDataSource(item).length !== 0 ? html`
					<dc-dropdown
						.buttonText=${i18next.t("Actions")}
						.align=${"right"}
						.dataSource=${getActionsDataSource(item)}
						@change=${(e) => __async(void 0, null, function* () {
    const action = e.detail.selectedValue;
    if (item.Status === "ReturnAvailable" && action === "Approve") {
      if (item.TaxReturnType === "IOSS" && item.PaymentDueDate.getDate() < Date.now()) {
        notifyWarning(i18next.t("Please note that your return will only be submitted if payment is received by the VAT intermediary before the submission deadline."));
      }
      const isConfirmed = yield confirmModal.confirm(i18next.t("approve-return"));
      if (isConfirmed) {
        changeStatus(item.Id, item.Status, "ApprovedByClient", void 0, void 0, void 0, void 0, void 0);
      }
    } else if (item.Status === "ReturnAvailable" && action === "Reject") {
      returnLibraryModalsHook.openRefusedByClientModal(item);
    } else if (item.Status === "ApprovedByClient" && action === "Submit") {
      changeStatus(item.Id, item.Status, "SubmittedByClient", void 0, void 0, void 0, void 0, void 0);
    } else if (item.Status === "RefusedByClient" && action === "Approve") {
      const isConfirmed = yield confirmModal.confirm(i18next.t("approve-return"));
      if (isConfirmed) {
        changeStatus(item.Id, item.Status, "ApprovedByClient", void 0, void 0, void 0, void 0, void 0);
      }
    } else if (item.Status === "RefusedByClient" && action === "Reopen") {
      returnLibraryModalsHook.openStatusChangedToNewModal(item);
    } else if (item.Status === "SubmittedByClient" && action === "ReturnToApproved") {
      changeStatus(item.Id, item.Status, "ApprovedByClient", void 0, void 0, void 0, void 0, void 0);
    } else if (action === "UploadPaymentProof") {
      returnLibraryModalsHook.openUploadModal(item, "PaymentProof");
    } else if (action === "ManualUpload") {
      returnLibraryModalsHook.openReturnDocumentsUploadModal(item);
    } else if (action === "PayComplianceFee") {
      returnLibraryModalsHook.openComplianceFeeModal(item);
    } else if (action === "UploadSubmissionProof") {
      returnLibraryModalsHook.openUploadModal(item, "SubmissionProof");
    } else if (action === "UploadIntrastatSubmissionProofs") {
      returnLibraryModalsHook.openUploadModal(item, "IntrastatSubmissionProofs");
    } else if (action === "UploadNotToBeSubmittedProof") {
      returnLibraryModalsHook.openUploadModal(item, "NotToBeSubmittedProof");
    } else {
      notifyWarning("Action cannot be performed");
    }
  })}
					></dc-dropdown>
			  ` : "";
  const rejectionCommentModal = () => html`
			<dc-modal .visible=${showRejectionCommentModal} @close=${() => setShowRejectionCommentModal(false)} .header=${"Refuse comment"} .content=${html` <div>${rejectionComment}</div>`}>
			</dc-modal>
		`;
  const batchProofFileTemplate = (batchProofFile) => html`
		<div class="flex justify-between gap-2 items-start mb-3 w-full">
			<div class="flex flex-col w-full">
				<div class="flex items-end gap-3">
					<div>${fileIcon(batchProofFile == null ? void 0 : batchProofFile.FileName)}</div>
					<div class="text-sm truncate">${batchProofFile == null ? void 0 : batchProofFile.FileName}</div>

					${batchProofFile.IsSuccess ? circleCheckedIcon() : triangleExclamationIcon()}
				</div>
				<div>
					${!batchProofFile.IsSuccess ? html`
								<div class="flex flex-col sm:flex-row sm:gap-4">
									<div class="text-sm text-red-500 ml-9">${`File upload failed - ${mapBatchUploadErrorReason(batchProofFile.ErrorReason)}`}</div>
									${batchProofFile.SubmissionPeriodId ? html`
												<a
													class="text-sm text-red-500 ml-9 sm:ml-0 cursor-pointer hover:underline"
													@click=${(e) => onOpenReturnLibraryClick(e, batchProofFile.SubmissionPeriodId)}
												>
													Open return library
												</a>
										  ` : ""}
								</div>
						  ` : ""}
				</div>
			</div>
		</div>
	`;
  const returnFileTemplate = (file) => html`
		<div class="flex justify-between gap-2 items-start mb-3 w-full">
			<div class="flex flex-col w-full">
				<div class="flex items-end gap-3">
					<div class="cursor-pointer" @click=${() => onDocumentDownload(returnUploadModalProps.submissionPeriodId, "Return", file.Reference)}>${fileIcon(file.Name)}</div>
					<a class="text-sm truncate cursor-pointer hover:underline" @click=${() => onDocumentDownload(returnUploadModalProps.submissionPeriodId, "Return", file.Reference)}
						>${`${file.Name} (V${file.Version} - ${formatDateTime(file.CreatedAt)})`}</a
					>
					${circleCheckedIcon()}
				</div>
			</div>
		</div>
	`;
  const returnUploadFilesTemplate = () => {
    var _a2;
    return (_a2 = returnUploadModalProps == null ? void 0 : returnUploadModalProps.returnFiles) == null ? void 0 : _a2.map((f) => returnFileTemplate(f));
  };
  const openAllReturnsWithErrorButtonTemplate = () => {
    const errorReturnsToOpen = batchUploadResult == null ? void 0 : batchUploadResult.filter((res) => res.SubmissionPeriodId && !res.IsSuccess);
    return (errorReturnsToOpen == null ? void 0 : errorReturnsToOpen.length) > 1 ? html` <a class="text-md max-w-fit text-brand-primary-dark text-underline cursor-pointer hover:underline" @click=${onOpenReturnLibraryClick}>Open all error returns</a> ` : "";
  };
  const batchUploadModalContentTemplate = () => html`
		<div class="flex flex-col gap-4 px-8 py-4">
			<div>${templateTitle("Upload Proof")}</div>
			<div class="break-words">${`File name convention: "${BATCH_UPLOAD_FILE_NAME_CONVENTION}"`}</div>
			<dc-dropzone-select
				class="h-40 rounded-xl bg-gray-100 block hover:border-2 hover:border-dashed hover:border-gray-500"
				.accept=${[".xml", ".png", ".xlsx", ".pdf", ".csv", ".txt"]}
				.uploadButtonLabel=${"Select Files"}
				.label=${"Drop proof files here to upload"}
				.note=${"You can upload multiple files at once"}
				.dragOverText=${"Drop files here to upload"}
				.onFileUpload=${(files) => onBatchUpload(files)}
				.multiple=${true}
			></dc-dropzone-select>
			<div class="flex flex-col gap-2">${batchUploadResult == null ? void 0 : batchUploadResult.map(batchProofFileTemplate)}</div>
			${openAllReturnsWithErrorButtonTemplate()}
		</div>
	`;
  const onConfirmReturnUpload = () => __async(void 0, null, function* () {
    yield props.confirmReturnDocument(returnUploadModalProps.submissionPeriodId, loader);
    yield loadSubmissions();
    setShowUploadReturnModal(false);
  });
  const uploadReturnModalContentTemplate = () => html`
		<div class="flex flex-col gap-4 px-8 py-4">
			<div>${templateTitle(i18next.t("Upload Return Document"))}</div>
			<dc-dropzone-select
				class="h-40 rounded-xl bg-gray-100 block hover:border-2 hover:border-dashed hover:border-gray-500"
				.uploadButtonLabel=${i18next.t("Select File")}
				.label=${i18next.t("Drop return file here to upload")}
				.note=${i18next.t("Upload return file")}
				.dragOverText=${i18next.t("Drop file here to upload")}
				.onFileUpload=${(files) => onReturnUpload(files)}
			></dc-dropzone-select>
			<div class="flex flex-col gap-2">${returnUploadFilesTemplate()}</div>
			<div class="flex gap-4">
				${PrimaryButton({ text: i18next.t("Confirm"), class: "w-28", onClick: onConfirmReturnUpload })}
				${SecondaryButton({ text: i18next.t("Close"), class: "w-28", onClick: () => setShowUploadReturnModal(false) })}
			</div>
		</div>
	`;
  const batchUploadModalTemplate = () => html`
			<dc-modal class="proof-batch-upload-modal" .visible=${showBatchUploadModal} @close=${onCloseBatchUploadModal} .header=${"Batch Proof Upload"} .content=${batchUploadModalContentTemplate()}>
			</dc-modal>
		`;
  const uploadReturnModalTemplate = () => html`
			<dc-modal
				class="proof-batch-upload-modal"
				.visible=${showUploadReturnModal}
				@close=${onCloseUploadReturnModal}
				.header=${i18next.t("Upload Return")}
				.content=${uploadReturnModalContentTemplate()}
			>
			</dc-modal>
		`;
  const mainTemplate = html`
		<div class="my-2 mb-16 mt-8 flex flex-col justify-between">
			<div class="grid grid-cols-1 gap-2 mb-8 w-full">${searchResult ? htmlGrid() : ""}</div>

			${returnLibraryModalsHook.htmlAllModals} ${rejectionCommentModal()} ${batchUploadModalTemplate()}${uploadReturnModalTemplate()} ${hmrcModal.uKPrimaryVATSubmissionModalTemplate()}
		</div>
		${confirmModal.mainTemplate()} ${loading.count > 0 ? html` <dc-loader></dc-loader>` : ""}
	`;
  return { mainTemplate };
};
