import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { currentLocale, getLocalizedUrl } from "../globals";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { validatePassword } from "GlobalShared/validators/password-validator";
import { validateEmail } from "GlobalShared/validators/email-validator";
import { useWorldCountries } from "./hooks/WebCustomHooks";
import { footerLinksTemplate, footerTextTemplate, headerTemplate } from "GlobalShared/templates/commons";
import { ALPHANUMERIC_REGEX } from "GlobalShared/common/Constants";
export const observedAttributes = ["email", "error", "registration-number", "registration-country"];
export const name = "ww-register-delivery-service";
export const Component = (host) => {
  const props = {
    email: host.email,
    error: host.error,
    registrationCountry: host.registrationCountry,
    registrationNumber: host.registrationNumber
  };
  const validateEmailInternal = () => {
    return email && !validateEmail(email) ? i18next.t("Please provide a valid e-mail address.") : void 0;
  };
  const validatePasswordInternal = () => {
    return password && !validatePassword(password) ? i18next.t(
      "Password must be at least 8 digits long and contain any three of the following four qualities: Uppercase Characters, Lowercase Characters, Alphanumeric Characters, Special Characters."
    ) : void 0;
  };
  const generateError = () => {
    var _a, _b, _c;
    return (_c = (_b = (_a = validateEmailInternal()) != null ? _a : validatePasswordInternal()) != null ? _b : validateRegistrationNumber()) != null ? _c : error;
  };
  const validateRegistrationNumber = () => {
    return registrationNumber && (registrationNumber == null ? void 0 : registrationNumber.length) > 10 || (registrationNumber == null ? void 0 : registrationNumber.length) < 7 || registrationNumber && (Array.from(registrationNumber).every((e) => e === "0") || !ALPHANUMERIC_REGEX.test(registrationNumber)) ? i18next.t("The account number seems to be invalid. Please enter a valid FedEx or TNT account number.") : void 0;
  };
  const hasError = () => {
    return !email || !password || !registrationNumber || !registrationCountry || generateError() !== void 0;
  };
  const [email, setEmail] = useState(props.email);
  const [error, setError] = useState(void 0);
  const [password, setPassword] = useState(void 0);
  const [registrationNumber, setRegistrationNumber] = useState(props.registrationNumber);
  const [registrationCountry, setRegistrationCountry] = useState(props.registrationCountry);
  const countries = useWorldCountries(currentLocale);
  useEffect(() => {
    setError(void 0);
  }, [email, password, registrationNumber, registrationCountry]);
  useEffect(() => {
    setError(props.error);
  }, [props.error]);
  return html` <div class="min-h-screen m-auto relative sm:pb-20">
            ${headerTemplate()}
			${html` <div class="bg-white bg-opacity-75 text-black rounded px-8 pb-4 m-auto flex flex-col max-w-sm mb-16 mt-10">
				<div class="text-xl md:text-3xl font-bold mt-8 mb-6 text-center">${i18next.t("Create your account")}</div>
				<div class="mb-4">
					<dc-input
						.label=${i18next.t("Email")}
						.name=${"Email"}
						.value=${email}
						.placeholder=${i18next.t("Email")}
						.autocomplete=${"off"}
						@change=${(e) => setEmail(e.detail.value)}
					></dc-input>
				</div>
				<div class="mb-4">
					<dc-input
						.label=${i18next.t("Password")}
						.type=${"password"}
						.name=${"Password"}
						.value=${password}
						.placeholder=${"*************"}
						.autocomplete=${"off"}
						@change=${(e) => setPassword(e.detail.value)}
					></dc-input>
				</div>
				<div class="mb-4">
					<label class="form-label">${i18next.t("What is your FedEx or TNT account number?")}<dc-tooltip .label=${i18next.t("fedex_cross_border_hint")}></dc-tooltip></label>
					<dc-input
						.name=${"RegistrationNumber"}
						.value=${registrationNumber}
						.autocomplete=${"off"}
						@change=${(e) => setRegistrationNumber(e.detail.value)}
					></dc-input>
				</div>
				<div class="mb-4">
					<dc-select
						.label=${`${i18next.t("In which country was your FedEx or TNT account created?")}`}
						.selectedValues=${[registrationCountry]}
						.dataSource=${countries.map((country) => ({
    label: country.Name,
    value: country.Code
  }))}
						.name=${"FedexAccountCountry"}
						.filterable=${true}
						@change=${(e) => {
    setRegistrationCountry(e.detail.selectedValue);
  }}
					></dc-select>
				</div>
				<input type="hidden" value=${registrationCountry} name=${"RegistrationCountry"} />
				<input type="hidden" value=${currentLocale} name=${"locale"} />
				${hasError() ? html`<div class="mb-2">
							<label class="text-red-400"> ${generateError()} </label>
					  </div>` : ""}
				<div class="text-sm mb-4">
					${unsafeHTML(
    i18next.t(
      "login_disclaimer",
      "By signing in or creating an account, you accept our {{- link1_start}}General Terms of Business{{- link1_end}} and {{- link2_start}}Privacy Notice{{- link2_end}}.",
      {
        link1_start: '<a class="underline" href="https://www.taxdesk.com/terms-conditions/" target="_blank" rel="noopener">',
        link1_end: "</a>",
        link2_start: '<a class="underline" href="https://www.taxdesk.com/privacy-policy/" target="_blank" rel="noopener" >',
        link2_end: "</a>"
      }
    )
  )}
				</div>
				<div class="w-full">
					<button ?disabled=${hasError()} class="btn btn-primary btn-full" type="submit">${i18next.t("Create Account")}</button>
				</div>
				<div class="mt-4 text-center mb-12">
					<span class="text-sm">${i18next.t("You already have an account? Please")}</span>
					<a class="font-semibold underline text-sm" href=${getLocalizedUrl("/account/login/x")} data-routing="server">${i18next.t("sign in")}</a>
				</div>
			</div>`}
			<div class="w-full mt-4 sm:absolute inset-x-0 bottom-0 pb-4 px-2">
                ${footerTextTemplate()}
                ${footerLinksTemplate()}
			</div>
		</div>
	</div>`;
};
