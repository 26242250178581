export var TransactionValidationErrorGroupCode = /* @__PURE__ */ ((TransactionValidationErrorGroupCode2) => {
  TransactionValidationErrorGroupCode2["MissingData"] = "MissingData";
  TransactionValidationErrorGroupCode2["InvalidPayeeIdentifier"] = "InvalidPayeeIdentifier";
  TransactionValidationErrorGroupCode2["InvalidBooleanValue"] = "InvalidBooleanValue";
  TransactionValidationErrorGroupCode2["InvalidEnumValue"] = "InvalidEnumValue";
  TransactionValidationErrorGroupCode2["InvalidCurrencyCode"] = "InvalidCurrencyCode";
  TransactionValidationErrorGroupCode2["InvalidCountryCode"] = "InvalidCountryCode";
  TransactionValidationErrorGroupCode2["InvalidDecimalValue"] = "InvalidDecimalValue";
  TransactionValidationErrorGroupCode2["InvalidDateTimeValue"] = "InvalidDateTimeValue";
  TransactionValidationErrorGroupCode2["TooLong"] = "TooLong";
  TransactionValidationErrorGroupCode2["DuplicatedValue"] = "DuplicatedValue";
  TransactionValidationErrorGroupCode2["ZeroValue"] = "ZeroValue";
  TransactionValidationErrorGroupCode2["InvalidDateOutOfQuarter"] = "InvalidDateOutOfQuarter";
  TransactionValidationErrorGroupCode2["InvalidSeparator"] = "InvalidSeparator";
  TransactionValidationErrorGroupCode2["InvalidHeader"] = "InvalidHeader";
  TransactionValidationErrorGroupCode2["UnprocessableFile"] = "UnprocessableFile";
  TransactionValidationErrorGroupCode2["InvalidColumnCount"] = "InvalidColumnCount";
  TransactionValidationErrorGroupCode2["InvalidXmlFile"] = "InvalidXmlFile";
  TransactionValidationErrorGroupCode2["DecimalIsNotNegative"] = "DecimalIsNotNegative";
  TransactionValidationErrorGroupCode2["InvalidGuid"] = "InvalidGuid";
  TransactionValidationErrorGroupCode2["InvalidMessageId"] = "InvalidMessageId";
  TransactionValidationErrorGroupCode2["InvalidDocRefId"] = "InvalidDocRefId";
  TransactionValidationErrorGroupCode2["DuplicatedMessageId"] = "DuplicatedMessageId";
  TransactionValidationErrorGroupCode2["TooLargeFile"] = "TooLargeFile";
  TransactionValidationErrorGroupCode2["NonCrossBorderTransaction"] = "NonCrossBorderTransaction";
  TransactionValidationErrorGroupCode2["DecimalIsNotPositive"] = "DecimalIsNotPositive";
  TransactionValidationErrorGroupCode2["MigratedError"] = "MigratedError";
  return TransactionValidationErrorGroupCode2;
})(TransactionValidationErrorGroupCode || {});
export var TransactionValidationErrorGroupColumn = /* @__PURE__ */ ((TransactionValidationErrorGroupColumn2) => {
  TransactionValidationErrorGroupColumn2["PayeeAccountId"] = "PayeeAccountId";
  TransactionValidationErrorGroupColumn2["TransactionIdentifier"] = "TransactionIdentifier";
  TransactionValidationErrorGroupColumn2["IsRefund"] = "IsRefund";
  TransactionValidationErrorGroupColumn2["CorrelatingTransactionIdentifier"] = "CorrelatingTransactionIdentifier";
  TransactionValidationErrorGroupColumn2["TransactionDateType"] = "TransactionDateType";
  TransactionValidationErrorGroupColumn2["TransactionDateValue"] = "TransactionDateValue";
  TransactionValidationErrorGroupColumn2["Currency"] = "Currency";
  TransactionValidationErrorGroupColumn2["Amount"] = "Amount";
  TransactionValidationErrorGroupColumn2["PaymentMethodType"] = "PaymentMethodType";
  TransactionValidationErrorGroupColumn2["PaymentMethodOther"] = "PaymentMethodOther";
  TransactionValidationErrorGroupColumn2["InitiatedAtPhysicalPremisesOfMerchant"] = "InitiatedAtPhysicalPremisesOfMerchant";
  TransactionValidationErrorGroupColumn2["PayerMsSource"] = "PayerMsSource";
  TransactionValidationErrorGroupColumn2["PayerMsCountryCode"] = "PayerMsCountryCode";
  TransactionValidationErrorGroupColumn2["PspRoleType"] = "PspRoleType";
  TransactionValidationErrorGroupColumn2["PspRoleOther"] = "PspRoleOther";
  TransactionValidationErrorGroupColumn2["Action"] = "Action";
  TransactionValidationErrorGroupColumn2["Issues"] = "Issues";
  TransactionValidationErrorGroupColumn2["MessageId"] = "MessageId";
  TransactionValidationErrorGroupColumn2["DocRefId"] = "DocRefId";
  return TransactionValidationErrorGroupColumn2;
})(TransactionValidationErrorGroupColumn || {});
