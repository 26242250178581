var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class PatchCompanies {
  constructor(_data) {
    /**
     */
    __publicField(this, "AddedCompanies");
    /**
     */
    __publicField(this, "RemovedCompanies");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PatchCompanies
     */
    __publicField(this, "$type", "V1.Account.PatchCompanies");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AddedCompanies"] && _data["AddedCompanies"].constructor === Array) {
        this.AddedCompanies = [];
        for (let item of _data["AddedCompanies"]) {
          this.AddedCompanies.push(item);
        }
      }
      if (_data["RemovedCompanies"] && _data["RemovedCompanies"].constructor === Array) {
        this.RemovedCompanies = [];
        for (let item of _data["RemovedCompanies"]) {
          this.RemovedCompanies.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.AddedCompanies && this.AddedCompanies.constructor === Array) {
      _data["AddedCompanies"] = [];
      for (let item of this.AddedCompanies) {
        _data["AddedCompanies"].push(item);
      }
    }
    if (this.RemovedCompanies && this.RemovedCompanies.constructor === Array) {
      _data["RemovedCompanies"] = [];
      for (let item of this.RemovedCompanies) {
        _data["RemovedCompanies"].push(item);
      }
    }
    return _data;
  }
}
