import { SubmissionPeriodPaymentStatusChange } from "./SubmissionPeriodPaymentStatusChange";
import { Due } from "./Due";
import { Paid } from "./Paid";
import { PaidByClient } from "./PaidByClient";
export class SubmissionPeriodPaymentStatusChangeFactory {
  static create($type) {
    if ($type === "V1.Submission.SubmissionPeriodPaymentStatusChange") {
      const result = new SubmissionPeriodPaymentStatusChange();
      return result;
    }
    if ($type === "V1.Submission.Due") {
      const result = new Due();
      return result;
    }
    if ($type === "V1.Submission.Paid") {
      const result = new Paid();
      return result;
    }
    if ($type === "V1.Submission.PaidByClient") {
      const result = new PaidByClient();
      return result;
    }
    throw new Error($type + " not found");
  }
}
