var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useState, useEffect, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { notifyError, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { templateTitle } from "GlobalShared/templates/commons";
import { webLanguages } from "GlobalShared/helpers/webLocaleHelper";
import { TITLES, LEGAL_STATUS, BUSINESS_TYPES } from "./BusinessDetailsData";
import { isEuCountry } from "GlobalShared/helpers/countryHelper";
import { BusinessDetailsValidator } from "./BusinessDetailsValidator";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { useConfirmModal } from "GlobalShared/components/modals/commonModals";
const DEFAULT_BUSINESS_DETAILS_MODEL = {
  legalStatus: "Company",
  countryOfEstablishment: "AL",
  vatNumber: "100",
  companyName: "BelaCo",
  contactTitle: "Mr",
  contactFirstName: "Bela",
  contactLastName: "Last",
  addressCity: "Szeged",
  addressZip: "1234",
  addressStreet: "K\xE1r\xE1sz u.",
  addressStreetNo: "123",
  addressProvince: "Province",
  preferredLanguage: "en-GB",
  phone: "1234"
};
export const useBusinessDetails = (props) => {
  const getVatNumberValidationMessage = () => {
    if (isEuCountry(props.euCountries, businessDetails.countryOfEstablishment) && (isEmpty(businessDetails.vatNumber) || !businessDetails.vatEUValid)) {
      return i18next.t("Invalid VAT number, VAT will be charged for our services.");
    }
    return void 0;
  };
  const getDefaultBusinessDetails = () => {
    if (props.devMode) {
      return {
        legalStatus: DEFAULT_BUSINESS_DETAILS_MODEL.legalStatus,
        companyName: DEFAULT_BUSINESS_DETAILS_MODEL.companyName,
        countryOfEstablishment: DEFAULT_BUSINESS_DETAILS_MODEL.countryOfEstablishment,
        vatNumber: DEFAULT_BUSINESS_DETAILS_MODEL.vatNumber,
        vatEUValid: false,
        addressZip: DEFAULT_BUSINESS_DETAILS_MODEL.addressZip,
        addressCity: DEFAULT_BUSINESS_DETAILS_MODEL.addressCity,
        addressStreet: DEFAULT_BUSINESS_DETAILS_MODEL.addressStreet,
        addressStreetNo: DEFAULT_BUSINESS_DETAILS_MODEL.addressStreetNo,
        addressProvince: DEFAULT_BUSINESS_DETAILS_MODEL.addressProvince,
        contactTitle: DEFAULT_BUSINESS_DETAILS_MODEL.contactTitle,
        contactFirstName: DEFAULT_BUSINESS_DETAILS_MODEL.contactFirstName,
        contactLastName: DEFAULT_BUSINESS_DETAILS_MODEL.contactLastName,
        contactPosition: DEFAULT_BUSINESS_DETAILS_MODEL.contactPosition,
        preferredLanguage: DEFAULT_BUSINESS_DETAILS_MODEL.preferredLanguage,
        phone: DEFAULT_BUSINESS_DETAILS_MODEL.phone
      };
    } else {
      return {
        legalStatus: void 0,
        companyName: void 0,
        countryOfEstablishment: void 0,
        vatNumber: void 0,
        vatEUValid: false,
        addressZip: void 0,
        addressCity: void 0,
        addressStreet: void 0,
        addressStreetNo: void 0,
        addressProvince: void 0,
        contactTitle: void 0,
        contactFirstName: void 0,
        contactLastName: void 0,
        contactPosition: void 0,
        preferredLanguage: void 0,
        phone: void 0
      };
    }
  };
  const handleBusinessTypeChange = (e) => __async(void 0, null, function* () {
    e.preventDefault();
    const tempVal = e.detail.selectedValue;
    if (tempVal === "Marketplace") {
      const isConfirmed = yield confirmMarketplaceSelectionModal.confirm(
        i18next.t(
          "You can create a Marketplace account only if more than 50% of your sales are via an online marketplace. We reserve the right to ask for verification of your qualification and to move your account to Enterprise level if you fail to provide evidence. Please confirm your eligibility."
        )
      );
      if (!isConfirmed) {
        return;
      }
    }
    setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { businessType: tempVal }));
    validationContext.validateField("businessType");
  });
  const [businessDetails, setBusinessDetails] = useState(getDefaultBusinessDetails());
  const [isValid, setIsValid] = useState(false);
  const validationContext = useValidationContext(new BusinessDetailsValidator());
  const confirmMarketplaceSelectionModal = useConfirmModal({ cancelText: i18next.t("Cancel"), confirmText: i18next.t("Confirm") });
  useEffect(() => __async(void 0, null, function* () {
    if (props.origBusinessDetails) {
      setBusinessDetails(props.origBusinessDetails);
    }
  }), [props.origBusinessDetails]);
  useEffect(() => __async(void 0, null, function* () {
    setIsValid(validationContext.validationResult.isValid());
  }), [validationContext.validationResult]);
  useEffect(() => {
    validationContext.validate(businessDetails);
  }, [businessDetails]);
  useEffect(() => __async(void 0, null, function* () {
    if (!isEmpty(businessDetails.vatNumber) && isEuCountry(props.euCountries, businessDetails.countryOfEstablishment)) {
      withErrorHandling(
        () => __async(void 0, null, function* () {
          const result = yield props.checkVatNumber(`${businessDetails.countryOfEstablishment}${businessDetails.vatNumber}`);
          setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { vatEUValid: result }));
        }),
        (error) => {
          notifyError(error);
        }
      );
    } else if (isEmpty(businessDetails.vatNumber) && isEuCountry(props.euCountries, businessDetails.countryOfEstablishment)) {
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { vatEUValid: false }));
    }
  }), [businessDetails == null ? void 0 : businessDetails.vatNumber, businessDetails == null ? void 0 : businessDetails.countryOfEstablishment]);
  const templateTitleFirstNameLastName = () => {
    return html` <div class="grid md:grid-cols-7 gap-8">
			<div class="md:col-span-1">
				<dc-select
					.label=${`${i18next.t("Title")}*`}
					.dataSource=${TITLES}
					.selectedValues=${[businessDetails.contactTitle]}
					.validationMessage=${validationContext.getValidationMessage("contactTitle")}
					@change=${(e) => {
      const tempVal = e.detail.selectedValue;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { contactTitle: tempVal }));
      validationContext.validateField("contactTitle");
    }}
				></dc-select>
			</div>
			<dc-input
				.label=${`${businessDetails.legalStatus === "Individual" ? i18next.t("Sole trader's first name") : i18next.t("Contact first name")}*`}
				class="md:col-span-2"
				.value=${businessDetails.contactFirstName}
				.validationMessage=${validationContext.getValidationMessage("contactFirstName")}
				@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { contactFirstName: tempVal }));
      validationContext.validateField("contactFirstName");
    }}
			></dc-input>
			<dc-input
				.label=${`${businessDetails.legalStatus === "Individual" ? i18next.t("Sole trader's last name") : i18next.t("Contact last name")}*`}
				class="md:col-span-2"
				.value=${businessDetails.contactLastName}
				.validationMessage=${validationContext.getValidationMessage("contactLastName")}
				@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { contactLastName: tempVal }));
      validationContext.validateField("contactLastName");
    }}
			></dc-input>
			<dc-input
				.label=${`${i18next.t("Position in the company")}*`}
				class="md:col-span-2"
				.value=${businessDetails.contactPosition}
				.validationMessage=${validationContext.getValidationMessage("contactPosition")}
				@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { contactPosition: tempVal }));
      validationContext.validateField("contactPosition");
    }}
			></dc-input>
		</div>`;
  };
  const mainTemplate = () => {
    var _a;
    return html`<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
				<div class="md:col-span-2 lg:col-span-4 mt-4 mb-2">${templateTitle(i18next.t("Business details"))}</div>
				<div class="lg:col-span-2">
					<dc-select
						.label=${`${i18next.t("Business Type")}*`}
						.selectedValues=${[businessDetails.businessType]}
						.dataSource=${BUSINESS_TYPES}
						.validationMessage=${validationContext.getValidationMessage("businessType")}
						@change=${handleBusinessTypeChange}
					></dc-select>
				</div>
				<div class="lg:col-span-2">
					<dc-select
						.label=${`${i18next.t("Legal status")}*`}
						.selectedValues=${[businessDetails.legalStatus]}
						.dataSource=${LEGAL_STATUS}
						.validationMessage=${validationContext.getValidationMessage("legalStatus")}
						@change=${(e) => {
      const tempVal = e.detail.selectedValue;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { legalStatus: tempVal }));
      validationContext.validateField("legalStatus");
    }}
					></dc-select>
				</div>
				${businessDetails.legalStatus === "Company" || businessDetails.legalStatus === "Partnership" ? html`<dc-input
							class="lg:col-span-2"
							.label=${`${i18next.t("Company name")}*`}
							.value=${businessDetails.companyName}
							.validationMessage=${validationContext.getValidationMessage("companyName")}
							@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { companyName: tempVal }));
      validationContext.validateField("companyName");
    }}
					  ></dc-input>` : ""}
				<div class="col-span-1 md:col-span-2 lg:col-span-4">${templateTitleFirstNameLastName()}</div>
				<div class="md:col-span-1 lg:col-span-2">
					<label class="form-label">${i18next.t("Preferred language")}* <dc-tooltip .label=${i18next.t("We will contact you in line with your language preference")}></dc-tooltip></label>
					<dc-select
						.selectedValues=${[businessDetails.preferredLanguage]}
						.validationMessage=${validationContext.getValidationMessage("preferredLanguage")}
						.dataSource=${Object.keys(webLanguages).map((key) => ({
      label: webLanguages[key].name,
      value: webLanguages[key].locale
    }))}
						@change=${(e) => {
      const tempVal = e.detail.selectedValue;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { preferredLanguage: tempVal }));
      validationContext.validateField("preferredLanguage");
    }}
					></dc-select>
				</div>
				<div class="md:col-span-1 lg:col-span-2">
					<dc-input
						.label=${`${i18next.t("Phone Number")}*`}
						.tooltip=${i18next.t("Please add the country calling code too (e.g. +86 for China, +49 for Germany, etc.)")}
						class="md:col-span-1 lg:col-span-3"
						.value=${businessDetails.phone}
						.validationMessage=${validationContext.getValidationMessage("phone")}
						@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { phone: tempVal }));
      validationContext.validateField("phone");
    }}
					></dc-input>
				</div>

				<div class="lg:col-span-2">
					<dc-select
						.label=${`${i18next.t("Country of incorporation")}*`}
						.selectedValues=${[businessDetails.countryOfEstablishment]}
						.dataSource=${(_a = props.worldCountries) == null ? void 0 : _a.map((country) => ({
      label: country.Name,
      value: country.Code
    }))}
						.filterable=${true}
						.validationMessage=${validationContext.getValidationMessage("countryOfEstablishment")}
						@change=${(e) => {
      const tempVal = e.detail.selectedValue;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { countryOfEstablishment: tempVal }));
      validationContext.validateField("countryOfEstablishment");
    }}
					></dc-select>
				</div>

				<div class="lg:col-span-2 mb-10">
					<label class="form-label">
						${i18next.t("VAT Number")}*<dc-tooltip
							.label=${i18next.t("EU based businesses must provide a valid VAT ID in the country of establishment, otherwise we will charge VAT for our services.")}
						></dc-tooltip>
					</label>
					<div class="relative">
						<div class="flex">
							<dc-input class="w-16" .readonly=${true} .value=${businessDetails.countryOfEstablishment}></dc-input>
							<dc-input
								class="w-full"
								.value=${businessDetails.vatNumber}
								.validationMessage=${validationContext.getValidationMessage("vatNumber")}
								@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { vatNumber: tempVal }));
      validationContext.validateField("vatNumber");
    }}
							></dc-input>
						</div>
						<div class="absolute">
							${isEmpty(validationContext.getValidationMessage("vatNumber")) && getVatNumberValidationMessage() !== void 0 ? html` <div class="text-orange-600">${getVatNumberValidationMessage()}</div> ` : ""}
						</div>
					</div>
				</div>
				<dc-input
					.label=${`${i18next.t("City")}*`}
					class="md:col-span-2"
					.value=${businessDetails.addressCity}
					.validationMessage=${validationContext.getValidationMessage("addressCity")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { addressCity: tempVal }));
      validationContext.validateField("addressCity");
    }}
				></dc-input>
				<dc-input
					.label=${`${i18next.t("Province")}*`}
					class="md:col-span-2 ${(businessDetails == null ? void 0 : businessDetails.countryOfEstablishment) === "CN" ? "" : "hidden"}"
					.value=${businessDetails.addressProvince}
					.validationMessage=${validationContext.getValidationMessage("addressProvince")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { addressProvince: tempVal }));
      validationContext.validateField("addressProvince");
    }}
				></dc-input>
				<dc-input
					.label=${`${i18next.t("Postal/Zip code")}*`}
					class="md:col-span-${(businessDetails == null ? void 0 : businessDetails.countryOfEstablishment) === "CN" ? "1" : "2"}"
					.value=${businessDetails.addressZip}
					.validationMessage=${validationContext.getValidationMessage("addressZip")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { addressZip: tempVal }));
      validationContext.validateField("addressZip");
    }}
				></dc-input>
				<dc-input
					.label=${`${i18next.t("Street name")}*`}
					class="md:col-span-${(businessDetails == null ? void 0 : businessDetails.countryOfEstablishment) === "CN" ? "2" : "3"}"
					.value=${businessDetails.addressStreet}
					.validationMessage=${validationContext.getValidationMessage("addressStreet")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { addressStreet: tempVal }));
      validationContext.validateField("addressStreet");
    }}
				></dc-input>
				<dc-input
					.label=${`${i18next.t("Street number")}*`}
					class="md:col-span-1"
					.value=${businessDetails.addressStreetNo}
					.validationMessage=${validationContext.getValidationMessage("addressStreetNo")}
					@change=${(e) => {
      const tempVal = e.detail.value;
      setBusinessDetails(__spreadProps(__spreadValues({}, businessDetails), { addressStreetNo: tempVal }));
      validationContext.validateField("addressStreetNo");
    }}
				></dc-input>
			</div>
			${confirmMarketplaceSelectionModal.mainTemplate()}`;
  };
  return { mainTemplate, state: businessDetails, isValid };
};
