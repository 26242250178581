var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./periods.scss";
import { html } from "lit-html";
import { useClient, useEffect, useLoadingReducer, useMemo, useState } from "GlobalShared/haunted/CustomHooks";
import { Psps as PspsClient } from "WebServices/APIs/self/clients";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { useReportingCountries } from "./useReportingCountries";
import i18next from "i18next";
import { currentLocale } from "../../../globals";
import { component } from "haunted";
import { CompanyDataTemplate } from "./company-data-template";
export const CompanyData = () => {
  const getMyPsps = () => __async(void 0, null, function* () {
    loader.start();
    const psps2 = (yield pspsClient.getMyPsps()).data;
    if (psps2.length === 1) {
      setOpenedPspId(psps2[0].Id);
    }
    setPsps(psps2);
    loader.stop();
  });
  const [loading, dispatchLoading] = useLoadingReducer();
  const loader = {
    start: () => dispatchLoading("inc"),
    stop: () => dispatchLoading("dec")
  };
  const pspsClient = useClient(PspsClient);
  const countries = useWorldCountries(currentLocale);
  const reportingCountries = useReportingCountries();
  const allCountries = useMemo(() => {
    return countries.map((c) => ({ name: c.Name, code: c.Code }));
  }, [countries]);
  const [openedPspId, setOpenedPspId] = useState();
  const [psps, setPsps] = useState(void 0);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    getMyPsps();
  }, []);
  const accordionHeaderTitleTemplate = (psp) => {
    const countryCodes = psp.Countries.join(", ");
    const countryCountString = `${i18next.t("Countries")} (${psp.Countries.length}):`;
    return html`
			<div class="flex justify-between mr-16">
				<div class="mr-16 text-nowrap truncate"><span class="font-bold">${psp.Name.Value}</span> <span>(${psp.Identifier})</span></div>
				<div class="truncate">${countryCountString} ${countryCodes}</div>
			</div>
		`;
  };
  const accordionGroupTemplate = () => {
    const filteredPsps = psps.filter((psp) => !searchText || psp.Name.Value.toLowerCase().includes(searchText.toLowerCase()));
    if (filteredPsps.length === 0)
      return html`<div class="text-xl font bold">${i18next.t("No results found")}</div>`;
    return filteredPsps.map((psp) => {
      return html`
				<dc-accordion
					class="accordion-group-item"
					.isOpen=${psp.Id === openedPspId || filteredPsps.length === 1}
					.headerTitle=${accordionHeaderTitleTemplate(psp)}
					.contentTemplate=${() => CompanyDataTemplate({ countries: allCountries, reportingCountries, model: psp })}
					.onClick=${() => {
        if (psp.Id === openedPspId) {
          setOpenedPspId("");
          return;
        }
        setOpenedPspId(psp.Id);
      }}
				></dc-accordion>
			`;
    });
  };
  const searchBoxTemplate = () => html`
		<div class="flex">
			<dc-searchbox .value=${searchText} .onInputDebounceMs=${150} .placeholder=${i18next.t("Company Name")} @input=${(e) => setSearchText(e.detail.value)}></dc-searchbox>
		</div>
	`;
  return psps ? html`
				<div class="text-2xl font-bold my-6">${i18next.t("Companies")}</div>
				${searchBoxTemplate()}
				<div class="my-12">${accordionGroupTemplate()}</div>
				${loading.count > 0 ? html`<dc-loader></dc-loader>` : ""}
		  ` : html`<dc-loader></dc-loader>`;
};
customElements.define("ww-admin-company-data", component(CompanyData, { useShadowDOM: false }));
