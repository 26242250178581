var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class InviteUser {
  constructor(_data) {
    /**
     */
    __publicField(this, "CompanyIds");
    /**
     */
    __publicField(this, "Email");
    /**
     */
    __publicField(this, "PspIds");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof InviteUser
     */
    __publicField(this, "$type", "V1.Account.InviteUser");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["CompanyIds"] && _data["CompanyIds"].constructor === Array) {
        this.CompanyIds = [];
        for (let item of _data["CompanyIds"]) {
          this.CompanyIds.push(item);
        }
      }
      this.Email = _data["Email"];
      if (_data["PspIds"] && _data["PspIds"].constructor === Array) {
        this.PspIds = [];
        for (let item of _data["PspIds"]) {
          this.PspIds.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.CompanyIds && this.CompanyIds.constructor === Array) {
      _data["CompanyIds"] = [];
      for (let item of this.CompanyIds) {
        _data["CompanyIds"].push(item);
      }
    }
    _data["Email"] = this.Email;
    if (this.PspIds && this.PspIds.constructor === Array) {
      _data["PspIds"] = [];
      for (let item of this.PspIds) {
        _data["PspIds"].push(item);
      }
    }
    return _data;
  }
}
