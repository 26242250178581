var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { SearchResult } from "./../models/V1/ComplianceMasterData/TaxCode/Search/SearchResult";
export class CompaniesTaxCodes {
  /**
   * Creates an instance of CompaniesTaxCodes.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof CompaniesTaxCodes
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param AddRq_ _addRq
   * @returns Promise<AddRq_>
   * @memberof CompaniesTaxCodes
   */
  add(pAddRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/companies/{companyId}/TaxCodes", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pAddRq.companyId, required: true }]);
    client.addBody({ value: pAddRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 201 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateRq_ _updateRq
   * @returns Promise<UpdateRq_>
   * @memberof CompaniesTaxCodes
   */
  update(pUpdateRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/companies/{companyId}/TaxCodes/{id}", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([
      { name: "companyId", value: pUpdateRq.companyId, required: true },
      { name: "id", value: pUpdateRq.id, required: true }
    ]);
    client.addBody({ value: pUpdateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DeleteRq_ _deleteRq
   * @returns Promise<DeleteRq_>
   * @memberof CompaniesTaxCodes
   */
  delete(pDeleteRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/companies/{companyId}/TaxCodes/{id}", "DELETE", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([
      { name: "companyId", value: pDeleteRq.companyId, required: true },
      { name: "id", value: pDeleteRq.id, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param HasAnyIncompleteRq_ _hasAnyIncompleteRq
   * @returns Promise<HasAnyIncompleteRq_>
   * @memberof CompaniesTaxCodes
   */
  hasAnyIncomplete(pHasAnyIncompleteRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/companies/{companyId}/TaxCodes/any", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pHasAnyIncompleteRq.companyId, required: true }]);
    client.addBody({ value: pHasAnyIncompleteRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof CompaniesTaxCodes
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/companies/{companyId}/TaxCodes/Search", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pSearchRq.companyId, required: true }]);
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
