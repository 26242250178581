var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { SearchResult } from "./../models/V1/Companies/Search/SearchResult";
import { Subscription } from "./../models/V1/CompanyInfo/Subscriptions/Subscription";
import { SubscriptionFactory } from "./../models/V1/CompanyInfo/Subscriptions/SubscriptionFactory";
export class Companies {
  /**
   * Creates an instance of Companies.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Companies
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SignUpRq_ _signUpRq
   * @returns Promise<SignUpRq_>
   * @memberof Companies
   */
  signUp(pSignUpRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Companies", "POST", "application/json; charset=UTF-8", "application/json", options);
    client.addBody({ value: pSignUpRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 201 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeCompanyRq_ _changeCompanyRq
   * @returns Promise<ChangeCompanyRq_>
   * @memberof Companies
   */
  changeCompany(pChangeCompanyRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Companies/selected", "POST", "application/json; charset=UTF-8", "application/json", options);
    client.addBody({ value: pChangeCompanyRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SearchRq_ _searchRq
   * @returns Promise<SearchRq_>
   * @memberof Companies
   */
  search(pSearchRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Companies/search", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSearchRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ReValidateVatNumberRq_ _reValidateVatNumberRq
   * @returns Promise<ReValidateVatNumberRq_>
   * @memberof Companies
   */
  reValidateVatNumber(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Companies/vatnumber/revalidate", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param AddSubscriptionFeedbackRq_ _addSubscriptionFeedbackRq
   * @returns Promise<AddSubscriptionFeedbackRq_>
   * @memberof Companies
   */
  addSubscriptionFeedback(pAddSubscriptionFeedbackRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Companies/subscriptions/{subscriptionId}",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([{ name: "subscriptionId", value: pAddSubscriptionFeedbackRq.subscriptionId, required: true }]);
    client.addBody({ value: pAddSubscriptionFeedbackRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetExpiringSubscriptionsRq_ _getExpiringSubscriptionsRq
   * @returns Promise<GetExpiringSubscriptionsRq_>
   * @memberof Companies
   */
  getExpiringSubscriptions(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Companies/subscriptions/expiring", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: Subscription, isArray: true, factory: SubscriptionFactory }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
