import { formatDateTimeUTC } from "GlobalShared/helpers/dateHelper";
import { circleCheckedIcon, closeIcon, fileIcon, triangleExclamationIcon } from "GlobalShared/templates/commons";
import classNames from "classnames";
import { html } from "lit-html";
import { BEFORE_FINALIZE_PERIOD_STATUSES, SOURCE_FILE_VALIDATION_ENDED_STATUSES } from "./PeriodsHelper";
import { SourceFileStatus } from "WebServices/APIs/self/models/V1/Cesop/Periods/SourceFile";
import i18next from "i18next";
export const usePeriodFiles = (props) => {
  const sourceFileTemplate = (sourceFile, downloadHandler, showErrorHandler, deleteFileHandler, isCurrentVersion) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return html`
		<div class="flex justify-between gap-2 items-start mb-3 w-full">
			<div class="flex flex-col w-11/12">
				<div class="flex items-end gap-3">
					<div
						class=${classNames({ "cursor-pointer": (sourceFile == null ? void 0 : sourceFile.FileInfo) !== void 0, "pointer-events-none": (sourceFile == null ? void 0 : sourceFile.FileInfo) === void 0 })}
						title=${(_b = (_a = sourceFile == null ? void 0 : sourceFile.FileInfo) == null ? void 0 : _a.Name) != null ? _b : sourceFile == null ? void 0 : sourceFile.FileName}
						@click=${() => downloadHandler(sourceFile == null ? void 0 : sourceFile.FileInfo)}
					>
						${fileIcon((_d = (_c = sourceFile == null ? void 0 : sourceFile.FileInfo) == null ? void 0 : _c.Name) != null ? _d : sourceFile == null ? void 0 : sourceFile.FileName)}
					</div>
					<div
						class=${classNames("text-sm truncate", {
      "hover:underline cursor-pointer": (sourceFile == null ? void 0 : sourceFile.FileInfo) !== void 0
    })}
						title=${(_f = (_e = sourceFile == null ? void 0 : sourceFile.FileInfo) == null ? void 0 : _e.Name) != null ? _f : sourceFile == null ? void 0 : sourceFile.FileName}
						@click=${() => downloadHandler(sourceFile == null ? void 0 : sourceFile.FileInfo)}
					>
						${(_h = (_g = sourceFile == null ? void 0 : sourceFile.FileInfo) == null ? void 0 : _g.Name) != null ? _h : sourceFile == null ? void 0 : sourceFile.FileName}
					</div>
					<div class="text-sm whitespace-nowrap">(${formatDateTimeUTC(sourceFile == null ? void 0 : sourceFile.UploadedAtUtc)})</div>

					${!SOURCE_FILE_VALIDATION_ENDED_STATUSES.includes(sourceFile == null ? void 0 : sourceFile.Status) ? html`<dc-loader2 .isLoading=${true} .size=${"small"}></dc-loader2>` : html` <div>${(sourceFile == null ? void 0 : sourceFile.Status) === SourceFileStatus.ValidationError ? triangleExclamationIcon() : circleCheckedIcon()}</div> `}
				</div>
				${(sourceFile == null ? void 0 : sourceFile.Status) === SourceFileStatus.ValidationError ? html`<div class="text-sm text-red-500 hover:underline cursor-pointer max-w-fit ml-9" @click=${() => showErrorHandler(sourceFile)}>
							${i18next.t("Validation error, view details")}
					  </div>` : ""}
			</div>

			${isCurrentVersion && SOURCE_FILE_VALIDATION_ENDED_STATUSES.includes(sourceFile == null ? void 0 : sourceFile.Status) && BEFORE_FINALIZE_PERIOD_STATUSES.includes(props.period.Status) ? html` <span class="self-center" @click=${() => deleteFileHandler(sourceFile)}>${closeIcon("w-4 h-4 text-xl text-brand-primary cursor-pointer font-bold hover:fill-red-500")}</span> ` : ""}
		</div>
	`;
  };
  const payeeSourceFileTemplate = (payeeFile, downloadHandler, showErrorHandler, deleteFileHandler) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    const showCloseIcon = !((_a = props.period.CurrentVersion.SourceFiles) == null ? void 0 : _a.length) && SOURCE_FILE_VALIDATION_ENDED_STATUSES.includes(props.period.CurrentVersion.Payees.Status);
    return html`
			<div class="flex justify-between gap-2 items-start mb-3 w-full">
				<div class=${classNames("flex flex-col", { "w-full": !showCloseIcon, "w-11/12": showCloseIcon })}>
					<div class="flex items-end gap-3">
						<div
							class=${classNames({
      "pointer-events-none": (payeeFile == null ? void 0 : payeeFile.FileInfo) === void 0,
      "cursor-pointer": (payeeFile == null ? void 0 : payeeFile.FileInfo) !== void 0
    })}
							title=${(_c = (_b = payeeFile == null ? void 0 : payeeFile.FileInfo) == null ? void 0 : _b.Name) != null ? _c : payeeFile == null ? void 0 : payeeFile.FileName}
							@click=${() => downloadHandler(payeeFile == null ? void 0 : payeeFile.FileInfo)}
						>
							${fileIcon((_e = (_d = payeeFile == null ? void 0 : payeeFile.FileInfo) == null ? void 0 : _d.Name) != null ? _e : payeeFile == null ? void 0 : payeeFile.FileName)}
						</div>
						<div
							class=${classNames("text-sm truncate", {
      "hover:underline cursor-pointer": (payeeFile == null ? void 0 : payeeFile.FileInfo) !== void 0
    })}
							title=${(_g = (_f = payeeFile == null ? void 0 : payeeFile.FileInfo) == null ? void 0 : _f.Name) != null ? _g : payeeFile == null ? void 0 : payeeFile.FileName}
							@click=${() => downloadHandler(payeeFile == null ? void 0 : payeeFile.FileInfo)}
						>
							${(_i = (_h = payeeFile == null ? void 0 : payeeFile.FileInfo) == null ? void 0 : _h.Name) != null ? _i : payeeFile == null ? void 0 : payeeFile.FileName}
						</div>

						<div class="text-sm whitespace-nowrap">(${formatDateTimeUTC(payeeFile == null ? void 0 : payeeFile.UploadedAtUtc)})</div>

						${!SOURCE_FILE_VALIDATION_ENDED_STATUSES.includes(payeeFile == null ? void 0 : payeeFile.Status) ? html`<dc-loader2 .isLoading=${true} .size=${"small"}></dc-loader2>` : html` <div>${(payeeFile == null ? void 0 : payeeFile.Status) === SourceFileStatus.ValidationError ? triangleExclamationIcon() : circleCheckedIcon()}</div> `}
					</div>
					${(payeeFile == null ? void 0 : payeeFile.Status) === SourceFileStatus.ValidationError ? html`<div class="text-sm text-red-500 hover:underline cursor-pointer max-w-fit ml-9" @click=${() => showErrorHandler()}>${i18next.t("Validation error, view details")}</div>` : ""}
				</div>
				${showCloseIcon ? html`<span class="self-center" @click=${deleteFileHandler}>${closeIcon("w-4 h-4 fill-brand-primary cursor-pointer hover:fill-red-500")}</span>` : ""}
			</div>
		`;
  };
  const messageProofFileTemplate = (proofFile, downloadHandler) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    return html`
		<div class="flex justify-between gap-2 items-start mb-3 w-full">
			<div class="flex w-11/12 items-end gap-3">
				<div
					class=${classNames({ "cursor-pointer": (proofFile == null ? void 0 : proofFile.FileInfo) !== void 0, "pointer-events-none": (proofFile == null ? void 0 : proofFile.FileInfo) === void 0 })}
					title=${(_b = (_a = proofFile == null ? void 0 : proofFile.FileInfo) == null ? void 0 : _a.Name) != null ? _b : ""}
					@click=${() => downloadHandler(proofFile.FileInfo)}
				>
					${fileIcon((_c = proofFile == null ? void 0 : proofFile.FileInfo) == null ? void 0 : _c.Name)}
				</div>
				<div
					class=${classNames("text-sm truncate", {
      "hover:underline cursor-pointer": (proofFile == null ? void 0 : proofFile.FileInfo) !== void 0
    })}
					title=${(_e = (_d = proofFile == null ? void 0 : proofFile.FileInfo) == null ? void 0 : _d.Name) != null ? _e : ""}
					@click=${() => downloadHandler(proofFile.FileInfo)}
				>
					${(_g = (_f = proofFile == null ? void 0 : proofFile.FileInfo) == null ? void 0 : _f.Name) != null ? _g : ""}
				</div>
				${((_h = proofFile == null ? void 0 : proofFile.FileInfo) == null ? void 0 : _h.CreatedAt) ? html`<div class="text-sm whitespace-nowrap">(${formatDateTimeUTC((_i = proofFile == null ? void 0 : proofFile.FileInfo) == null ? void 0 : _i.CreatedAt)})</div>` : ""}
			</div>
		</div>
	`;
  };
  const cesopResponseFileTemplate = (file, downloadHandler, showSuccesIcon = false) => {
    var _a, _b, _c;
    return html`
		<div class="flex w-full items-end gap-3">
			<div class=${classNames({ "cursor-pointer": file !== void 0, "pointer-events-none": file === void 0 })} title=${(_a = file == null ? void 0 : file.Name) != null ? _a : ""} @click=${() => downloadHandler(file)}>
				${fileIcon(file == null ? void 0 : file.Name)}
			</div>
			<div
				class=${classNames("text-sm truncate", {
      "hover:underline cursor-pointer": file !== void 0
    })}
				title=${(_b = file == null ? void 0 : file.Name) != null ? _b : ""}
				@click=${() => downloadHandler(file)}
			>
				${(_c = file == null ? void 0 : file.Name) != null ? _c : ""}
			</div>
			${(file == null ? void 0 : file.CreatedAt) ? html`<div class="text-sm whitespace-nowrap">(${formatDateTimeUTC(file == null ? void 0 : file.CreatedAt)})</div>` : ""} ${showSuccesIcon ? circleCheckedIcon() : ""}
		</div>
	`;
  };
  const submissionProofFileTemplate = (file, downloadHandler) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    return html`
		<div class="flex justify-between gap-2 items-start mb-3 w-full">
			<div class="flex w-11/12 items-end gap-3">
				<div
					class=${classNames({ "cursor-pointer": (file == null ? void 0 : file.FileInfo) !== void 0, "pointer-events-none": (file == null ? void 0 : file.FileInfo) === void 0 })}
					title=${(_b = (_a = file == null ? void 0 : file.FileInfo) == null ? void 0 : _a.Name) != null ? _b : ""}
					@click=${() => downloadHandler(props.period, file.FileInfo.Reference)}
				>
					${fileIcon((_c = file == null ? void 0 : file.FileInfo) == null ? void 0 : _c.Name)}
				</div>
				<div
					class=${classNames("text-sm truncate", {
      "hover:underline cursor-pointer": (file == null ? void 0 : file.FileInfo) !== void 0
    })}
					title=${(_e = (_d = file == null ? void 0 : file.FileInfo) == null ? void 0 : _d.Name) != null ? _e : ""}
					@click=${() => {
      downloadHandler(props.period, file.FileInfo.Reference);
    }}
				>
					${(_g = (_f = file == null ? void 0 : file.FileInfo) == null ? void 0 : _f.Name) != null ? _g : ""}
				</div>
				${((_h = file == null ? void 0 : file.FileInfo) == null ? void 0 : _h.CreatedAt) ? html`<div class="text-sm whitespace-nowrap">(${formatDateTimeUTC((_i = file == null ? void 0 : file.FileInfo) == null ? void 0 : _i.CreatedAt)})</div>` : ""}
			</div>
		</div>
	`;
  };
  return {
    sourceFileTemplate,
    payeeSourceFileTemplate,
    messageProofFileTemplate,
    cesopResponseFileTemplate,
    submissionProofFileTemplate
  };
};
