var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class Address {
  constructor(_data) {
    /**
     */
    __publicField(this, "Building");
    /**
     */
    __publicField(this, "City");
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "PostalCode");
    /**
     */
    __publicField(this, "Street");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Address
     */
    __publicField(this, "$type", "V1.Cesop.Address");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Building = _data["Building"];
      this.City = _data["City"];
      this.CountryCode = _data["CountryCode"];
      this.PostalCode = _data["PostalCode"];
      this.Street = _data["Street"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Building"] = this.Building;
    _data["City"] = this.City;
    _data["CountryCode"] = this.CountryCode;
    _data["PostalCode"] = this.PostalCode;
    _data["Street"] = this.Street;
    return _data;
  }
}
