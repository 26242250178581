var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import classNames from "classnames";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { AMAZON_AUTOMATION_ENABLED } from "./ComplianceUploadsHelper";
import { getAllSellerEntityFields, SellerEntityValidator } from "./SellerEntityValidator";
import { fileIcon, removeIcon } from "GlobalShared/templates/commons";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { notifyError } from "GlobalShared/helpers/errorHelper";
import { AMAZON_TRANSACTION_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
import { mapBooleanToState } from "GlobalShared/helpers/booleanHelper";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
export const useAmazonUpload = (props) => {
  const editSellerEntity = (sellerEntity) => {
    setCurrentSellerEntity(__spreadProps(__spreadValues({}, sellerEntity), { isAutomatedManuallyChecked: true }));
    sellerEntityValidationContext.clearFieldValidations();
    props.setUploadModalMode("ShowSellerEntityEdit");
  };
  const removeSellerEntity = (sellerEntity) => {
    var _a, _b;
    return props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
      amazonConfiguration: __spreadProps(__spreadValues({}, (_a = props.defaultCompanyConfiguration) == null ? void 0 : _a.amazonConfiguration), {
        sellerEntities: (_b = props.defaultCompanyConfiguration) == null ? void 0 : _b.amazonConfiguration.sellerEntities.filter((se) => se.id !== sellerEntity.id)
      })
    }));
  };
  const handleAmazonTemplateToggleChange = (e) => __async(void 0, null, function* () {
    var _a, _b;
    e.preventDefault();
    const useAmazonTemplate = e.detail.state === "Yes";
    if (!props.isToggleStateChanged((_a = props.defaultCompanyConfiguration) == null ? void 0 : _a.useAmazonTemplate, useAmazonTemplate)) {
      return;
    }
    if (useAmazonTemplate) {
      props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
        useAmazonTemplate: true
      }));
      const complianceUpload = yield props.getCompliance((_b = props.currentComplianceUpload) == null ? void 0 : _b.Id);
      props.setCurrentComplianceUpload(complianceUpload);
      return;
    }
    if (!props.isAdmin && props.uploadModalState === "Closed") {
      props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
        useAmazonTemplate
      }));
      return;
    }
    if (!useAmazonTemplate) {
      const isConfirmed = yield props.confirmModal.confirm(i18next.t("Please note, all uploaded files will be removed if you proceed. Do you wish to continue?"));
      if (isConfirmed) {
        try {
          props.dispatchFullScreenLoading("inc");
          yield props.removeUploadedAmazonTransactions(props.currentComplianceUpload.Id);
          yield props.loadComplianceUploads();
          props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
            useAmazonTemplate: false
          }));
          yield props.deleteJobs("Amazon");
        } catch (error) {
          notifyError(error);
        } finally {
          props.dispatchFullScreenLoading("dec");
        }
      }
    }
  });
  const addSellerEntity = (mode) => {
    setCurrentSellerEntity({
      isAutomated: false,
      isEnabled: true,
      isAutomatedManuallyChecked: false
    });
    sellerEntityValidationContext.clearFieldValidations();
    props.setUploadModalMode(mode === "Default" ? "ShowAddNewSellerEntityFromModal" : "ShowAddNewSellerEntity");
  };
  const saveSellerEntity = () => {
    var _a, _b, _c, _d;
    setCurrentSellerEntity(void 0);
    if (props.uploadModalState === "ShowSellerEntityEdit") {
      props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
        amazonConfiguration: __spreadProps(__spreadValues({}, (_a = props.defaultCompanyConfiguration) == null ? void 0 : _a.amazonConfiguration), {
          sellerEntities: (_b = props.defaultCompanyConfiguration) == null ? void 0 : _b.amazonConfiguration.sellerEntities.map((se) => se.id === currentSellerEntity.id ? currentSellerEntity : se)
        })
      }));
    } else {
      props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
        amazonConfiguration: __spreadProps(__spreadValues({}, (_c = props.defaultCompanyConfiguration) == null ? void 0 : _c.amazonConfiguration), {
          sellerEntities: (_d = props.defaultCompanyConfiguration) == null ? void 0 : _d.amazonConfiguration.sellerEntities.concat(currentSellerEntity)
        })
      }));
    }
  };
  const [dispatchIfSellerEntityValid, setDispatchIfSellerEntityValid] = useState(false);
  const sellerEntityValidationContext = useValidationContext(
    new SellerEntityValidator(props.defaultCompanyConfiguration, props.uploadModalState === "ShowSellerEntityEdit")
  );
  const [currentSellerEntity, setCurrentSellerEntity] = useState(void 0);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfSellerEntityValid) {
      setDispatchIfSellerEntityValid(false);
      if (sellerEntityValidationContext.validationResult.isValid()) {
        saveSellerEntity();
        yield props.deleteJobs("Amazon");
        yield props.loadComplianceUploads();
        props.setUploadModalMode(props.uploadModalState === "ShowAddNewSellerEntityFromModal" || props.uploadModalState === "ShowSellerEntityEdit" ? "Opened" : "Closed");
      }
    }
  }), [sellerEntityValidationContext.validationResult]);
  useEffect(() => {
    if (currentSellerEntity !== void 0) {
      sellerEntityValidationContext.validate(currentSellerEntity);
    }
  }, [currentSellerEntity]);
  const htmlAmazonFileUploadButton = (mode, sellerEntityId) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    return html`
			<dc-fileupload2
				.label=${i18next.t("Upload")}
				.fileReference=${(_c = (_b = (_a = props.currentComplianceUpload) == null ? void 0 : _a.AmazonFiles) == null ? void 0 : _b.find((af) => af.SellerEntity.Id === sellerEntityId)) == null ? void 0 : _c.Reference}
				.fileName=${(_f = (_e = (_d = props.currentComplianceUpload) == null ? void 0 : _d.AmazonFiles) == null ? void 0 : _e.find((af) => af.SellerEntity.Id === sellerEntityId)) == null ? void 0 : _f.Name}
				.accept=${AMAZON_TRANSACTION_ACCEPT_LIST}
				.linkGenerator=${(reference) => props.linkGenerator(props.currentComplianceUpload.Id, reference)}
				.readonly=${!sellerEntityId || !((_i = (_h = (_g = props.defaultCompanyConfiguration) == null ? void 0 : _g.amazonConfiguration) == null ? void 0 : _h.sellerEntities.find((sellerEntity) => sellerEntity.id === sellerEntityId)) == null ? void 0 : _i.isEnabled) || props.hasOngoingDataProcessingJob("Amazon", sellerEntityId) || mode === "Readonly"}
				.withLoader=${props.isDataProcessingJobOngoingForUploadType("Amazon", sellerEntityId)}
				.showFileLink=${false}
				.withUploadIcon=${true}
				@upload=${(event) => props.uploadFileToBlob(event.detail.files[0], "Amazon", sellerEntityId)}
			></dc-fileupload2>
		`;
  };
  const htmlSellerAccount = (mode, sellerEntity) => {
    var _a, _b, _c, _d, _e, _f;
    const currentAmazonFile = (_b = (_a = props.currentComplianceUpload) == null ? void 0 : _a.AmazonFiles) == null ? void 0 : _b.find((amazonFile) => amazonFile.SellerEntity.Id === sellerEntity.id);
    const currentAmazonUploadJob = (_c = props.currentUploadJobs) == null ? void 0 : _c.find((job) => job.type === "Amazon" && job.sellerEntityId === sellerEntity.id);
    const isErrorFileSameAsValidFile = (currentAmazonFile == null ? void 0 : currentAmazonFile.Reference) === ((_d = currentAmazonUploadJob == null ? void 0 : currentAmazonUploadJob.latestUploadedFile) == null ? void 0 : _d.Reference);
    const isActiveJobStatusValidiationError = ((_e = props.defaultCompanyConfiguration) == null ? void 0 : _e.useAmazonTemplate) && (currentAmazonUploadJob == null ? void 0 : currentAmazonUploadJob.status) === "ValidationError" && ((_f = props.defaultCompanyConfiguration) == null ? void 0 : _f.amazonConfiguration.sellerEntities.some((se) => currentAmazonUploadJob.sellerEntityId === se.id && se.isEnabled));
    const isFileSuccessFullyUploaded = (currentAmazonFile == null ? void 0 : currentAmazonFile.Reference) && !isErrorFileSameAsValidFile;
    return html`
			<div class="flex gap-4 justify-between border-b mb-4 pb-4">
				<div class="flex flex-col">
					<div class="flex items-center">
						<div class="flex items-center h-full space-x-2 mr-6">
							<dc-edit-button @click=${() => editSellerEntity(sellerEntity)} .disabled=${mode === "Readonly"}></dc-edit-button>
							<span
								class="remove-icon-container"
								?disabled=${mode === "Readonly"}
								@click=${() => __async(void 0, null, function* () {
      if (yield props.confirmModal.confirm(i18next.t("Are you sure to delete?"))) {
        removeSellerEntity(sellerEntity);
      }
    })}
							>
								${removeIcon(17, 17)}
							</span>
						</div>
						<div class="flex flex-col w-full">
							<div class="flex flex-row space-x-2 flex-wrap">
								${sellerEntity.id}, ${sellerEntity.name}, ${sellerEntity.isEnabled ? i18next.t("Enabled") : i18next.t("Disabled")}
								${AMAZON_AUTOMATION_ENABLED ? mode === "OnlyDefaultConfig" ? html`, ${i18next.t("Automation")}: ${sellerEntity.isAutomated ? i18next.t("On") : i18next.t("Off")}` : "" : ""}
							</div>
						</div>
					</div>
					<div class="ml-20 mt-4">
						${props.htmlFileUploadStatus("Amazon", isErrorFileSameAsValidFile, currentAmazonFile, isFileSuccessFullyUploaded, sellerEntity.id, isActiveJobStatusValidiationError)}
					</div>
				</div>
				<div class="w-64">
					${mode === "Default" ? html`
								<div class="pb-2 flex justify-end">
									<div class="flex flex-col items-end">${htmlAmazonFileUploadButton(mode, sellerEntity.id)}</div>
								</div>
						  ` : ""}
				</div>
			</div>
		`;
  };
  const htmlSellerEntities = (mode) => {
    var _a;
    const companyConfiguration = mode === "Readonly" ? props.currentComplianceUpload.CompanyConfigSnapshot : props.defaultCompanyConfiguration;
    return html`
			<div class="mt-4">
				<div><h3 class="font-bold">${i18next.t("Seller Accounts")}</h3></div>
				<div class="mt-4">
					${(_a = companyConfiguration == null ? void 0 : companyConfiguration.amazonConfiguration) == null ? void 0 : _a.sellerEntities.map((sellerEntity) => {
      return html` ${htmlSellerAccount(mode, sellerEntity)} `;
    })}

					<div class="mt-4">
						${mode !== "Readonly" ? PrimaryButton({ class: "max-w-fit", text: i18next.t("Add Seller Account"), disabled: props.hasAnyOngoingDataProcessingJob(), onClick: () => addSellerEntity(mode) }) : ""}
					</div>
				</div>
			</div>
		`;
  };
  const htmlAmazonUpload = (mode, companyConfiguration) => {
    var _a, _b, _c, _d;
    return html`
			<hr class=${classNames("mt-4", { hidden: !((_b = (_a = props.currentComplianceUpload) == null ? void 0 : _a.AvailableTemplates) == null ? void 0 : _b.includes("Mp")) })} />
			<div class="mt-4">
				<span class="form-label">${i18next.t("Use Amazon's template to upload data")}</span>
				<dc-toggle
					.state=${companyConfiguration.useAmazonTemplate ? "Yes" : "No"}
					.readonly=${props.hasOngoingDataProcessingJob("Amazon") || mode === "Readonly"}
					.customClass=${"mt-4"}
					@change=${handleAmazonTemplateToggleChange}
				></dc-toggle>
			</div>
			${companyConfiguration.useAmazonTemplate ? html`
						<div>
							${((_c = props.currentComplianceUpload) == null ? void 0 : _c.IsAmazonInvoicingServiceOptional) === true ? html`
										<div class="mt-4">
											<label class="form-label">${i18next.t("Amazon invoicing services before 2021 July")}</label>
											<div>
												<dc-toggle
													.state=${mapBooleanToState((_d = companyConfiguration == null ? void 0 : companyConfiguration.amazonConfiguration) == null ? void 0 : _d.useAmazonRates)}
													.readonly=${props.hasAnyOngoingDataProcessingJob() || mode === "Readonly"}
													@change=${(e) => {
      var _a2;
      const useAmazonRates = e.detail.state === "Yes";
      props.setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, props.defaultCompanyConfiguration), {
        amazonConfiguration: __spreadProps(__spreadValues({}, (_a2 = props.defaultCompanyConfiguration) == null ? void 0 : _a2.amazonConfiguration), {
          useAmazonRates
        })
      }));
    }}
												></dc-toggle>
											</div>
											<div class="text-sm italic mt-4 text-gray-600">${i18next.t("amazon-vat-rates-warning")}</div>
										</div>
								  ` : ""}
							${htmlSellerEntities(mode)}
							<div class="mt-4">
								<label class="form-label">${i18next.t("Mode of Refund")}</label>
								<div class="flex items-center space-x-4 my-2">
									<span>${i18next.t("Deduct all Refund")}</span>
								</div>
							</div>
							<div><span class="text-sm italic mt-4 text-gray-600">${i18next.t("compliance-upload-refund-label-deductall")}</span></div>
						</div>
				  ` : ""}
		`;
  };
  const htmlAmazonAutomation = () => {
    var _a, _b, _c;
    return AMAZON_AUTOMATION_ENABLED ? html`
					<dc-checkbox2
						.checked=${currentSellerEntity.isAutomated}
						.label=${"Automated"}
						@change=${(e) => {
      setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), {
        isAutomated: e.detail.checked,
        isAutomatedManuallyChecked: true
      }));
    }}
					></dc-checkbox2>
					<div class="flex space-x-4 mt-4">
						<div class="flex space-x-4">
							<span>${i18next.t("Read our guide how to automate transactions with Amazon:")}</span>${fileIcon(
      (_c = (_b = (_a = props.currentComplianceUpload) == null ? void 0 : _a.AmazonFiles) == null ? void 0 : _b.find((af) => af.SellerEntity.Id === currentSellerEntity.id)) == null ? void 0 : _c.Name
    )}
						</div>
					</div>
			  ` : "";
  };
  const htmlMwsAuthTokenInputField = () => (
    //!!! Temporary removed becourse of TDTP-184
    AMAZON_AUTOMATION_ENABLED ? html`
					<dc-input
						.label=${i18next.t("MWSAuthToken")}
						.value=${currentSellerEntity.mwsAuthToken}
						.validationMessage=${sellerEntityValidationContext.getValidationMessage("MwsAuthToken")}
						@change=${(e) => {
      const newMwsToken = e.detail.value;
      let newIsAutomated = currentSellerEntity.isAutomated;
      if (!currentSellerEntity.isAutomatedManuallyChecked && !isEmpty(newMwsToken)) {
        newIsAutomated = true;
      }
      sellerEntityValidationContext.validateField("MwsAuthToken");
      setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), {
        mwsAuthToken: e.detail.value,
        isAutomated: newIsAutomated
      }));
    }}
					></dc-input>
			  ` : ""
  );
  const htmlSellerEntityModalContent = () => html`
		<div class="space-y-4">
			${props.uploadModalState === "ShowSellerEntityEdit" ? html`<div class="font-bold text-xl">${currentSellerEntity.name}</div>` : ""}
			<dc-input
				.label=${i18next.t("Id")}
				.value=${currentSellerEntity.id}
				.readonly=${props.uploadModalState === "ShowSellerEntityEdit"}
				.validationMessage=${sellerEntityValidationContext.getValidationMessage("Id")}
				@change=${(e) => {
    sellerEntityValidationContext.validateField("Id");
    setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), { id: e.detail.value }));
  }}
			></dc-input>
			<dc-input
				.label=${i18next.t("Name")}
				.value=${currentSellerEntity.name}
				.validationMessage=${sellerEntityValidationContext.getValidationMessage("Name")}
				@change=${(e) => {
    sellerEntityValidationContext.validateField("Name");
    setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), { name: e.detail.value }));
  }}
			></dc-input>

			${htmlMwsAuthTokenInputField()}
			<dc-checkbox2
				.checked=${currentSellerEntity.isEnabled}
				.label=${"Enabled"}
				@change=${(e) => {
    setCurrentSellerEntity(__spreadProps(__spreadValues({}, currentSellerEntity), {
      isEnabled: e.detail.checked
    }));
  }}
			></dc-checkbox2>
			${htmlAmazonAutomation()}
			<div class="flex mt-4 space-x-4">
				<button
					class="btn btn-primary"
					@click=${() => {
    setCurrentSellerEntity(void 0);
    props.setUploadModalMode(props.uploadModalState === "ShowAddNewSellerEntityFromModal" || props.uploadModalState === "ShowSellerEntityEdit" ? "Opened" : "Closed");
  }}
				>
					${i18next.t("Cancel")}
				</button>
				<button
					class="btn btn-primary"
					@click=${() => {
    setCurrentSellerEntity(__spreadValues({}, currentSellerEntity));
    sellerEntityValidationContext.validateFields(getAllSellerEntityFields());
    setDispatchIfSellerEntityValid(true);
  }}
				>
					${i18next.t("Save")}
				</button>
			</div>
		</div>
	`;
  return {
    currentSellerEntity,
    htmlSellerEntityModalContent,
    htmlAmazonUpload
  };
};
