import "./dc-checkbox2.scss";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { component } from "haunted";
import classNames from "classnames";
const DEFAULTS = {
  checked: false,
  label: "",
  labelnowrap: false,
  readonly: false
};
export class ChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, cancelable: true });
  }
}
export const Component = (host) => {
  const props = {
    checked: host.checked !== void 0 ? host.checked : DEFAULTS.checked,
    label: host.label !== void 0 ? host.label : DEFAULTS.label,
    labelnowrap: host.labelnowrap !== void 0 ? host.labelnowrap : DEFAULTS.labelnowrap,
    readonly: host.readonly !== void 0 ? host.readonly : DEFAULTS.readonly
  };
  const onClicked = (_e) => {
    if (!props.readonly) {
      if (host.dispatchEvent(
        new ChangeEvent({
          checked: !checked
        })
      )) {
        setChecked(!checked);
      }
    }
    return true;
  };
  const onChange = (e) => {
    e.stopPropagation();
  };
  const [checked, setChecked] = useState(false);
  const [componentId, setComponentId] = useState("");
  useEffect(() => {
    setChecked(props.checked);
    const elem = document.getElementById(componentId);
    if (elem !== null) {
      elem.checked = props.checked;
    }
  }, [props.checked]);
  useEffect(() => {
    const id = `_${name}_${Math.random().toString(36).substr(2, 9)}`;
    setComponentId(id);
  }, []);
  return html`
		<div class=${classNames("switch w-full block content-center", { "pointer-events-none": props.readonly })}>
			<label class="flex flex-wrap toggle items-center mt-4">
				<label class="mr-2 text-gray-800 text-sm font-bold ${props.labelnowrap ? "whitespace-nowrap" : ""}" for=${componentId}>${unsafeHTML(props.label)}</label>
				<input id=${componentId} @click=${onClicked} @change=${onChange} type="checkbox" class="hidden" ?checked=${checked} ?readonly=${props.readonly} />
				<div class="toggle-control"></div>
			</label>
		</div>
	`;
};
customElements.define(
  "dc-checkbox2",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
