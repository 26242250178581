export var PeriodFieldField = /* @__PURE__ */ ((PeriodFieldField2) => {
  PeriodFieldField2["PspName"] = "PspName";
  PeriodFieldField2["StartDate"] = "StartDate";
  PeriodFieldField2["ReportingCountry"] = "ReportingCountry";
  PeriodFieldField2["Status"] = "Status";
  PeriodFieldField2["RegistrationIdentifier"] = "RegistrationIdentifier";
  PeriodFieldField2["Version"] = "Version";
  return PeriodFieldField2;
})(PeriodFieldField || {});
export var PeriodFieldOrder = /* @__PURE__ */ ((PeriodFieldOrder2) => {
  PeriodFieldOrder2["Ascending"] = "Ascending";
  PeriodFieldOrder2["Descending"] = "Descending";
  return PeriodFieldOrder2;
})(PeriodFieldOrder || {});
