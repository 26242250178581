var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class PdfFieldValue {
  constructor(_data) {
    /**
     */
    __publicField(this, "Name");
    /**
     */
    __publicField(this, "Type");
    /**
     */
    __publicField(this, "Values");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PdfFieldValue
     */
    __publicField(this, "$type", "V1.TemplateMapping.PdfFieldValue");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Name = _data["Name"];
      this.Type = PdfFieldValueType[_data["Type"]];
      if (_data["Values"] && _data["Values"].constructor === Array) {
        this.Values = [];
        for (let item of _data["Values"]) {
          this.Values.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Name"] = this.Name;
    _data["Type"] = PdfFieldValueType[this.Type];
    if (this.Values && this.Values.constructor === Array) {
      _data["Values"] = [];
      for (let item of this.Values) {
        _data["Values"].push(item);
      }
    }
    return _data;
  }
}
export var PdfFieldValueType = /* @__PURE__ */ ((PdfFieldValueType2) => {
  PdfFieldValueType2["Unknown"] = "Unknown";
  PdfFieldValueType2["Checkbox"] = "Checkbox";
  PdfFieldValueType2["Dropdown"] = "Dropdown";
  PdfFieldValueType2["Text"] = "Text";
  return PdfFieldValueType2;
})(PdfFieldValueType || {});
