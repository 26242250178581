import { FilterVatRateTypes as AdminFilterVatRateTypes } from "AdminServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search/Filter";
import { FilterVatRateTypes as WebFilterVatRateTypes } from "WebServices/APIs/self/models/V1/ComplianceMasterData/TaxCode/Search/Filter";
export const FILTER_VAT_RATE_TYPES_DATA_SOURCE = Array.from(new Set(Object.values(AdminFilterVatRateTypes).concat(Object.values(WebFilterVatRateTypes))));
export const TaxCodeFieldNames = {
  ClientTaxCode: "ClientTaxCode",
  Country: "Country",
  TransactionType: "TransactionType",
  VatRateType: "VatRateType",
  VatRate: "VatRate",
  TypeOfGoods: "TypeOfGoods",
  ReverseCharge: "ReverseCharge",
  Actions: "Actions"
};
export const DEFAULT_TAX_CODE = {
  clientTaxCode: "",
  companyId: void 0,
  countryCode: "",
  id: "",
  reverseCharge: void 0,
  transactionTypeCode: "",
  typeOfGoods: "",
  vatRateType: void 0,
  vatRate: void 0
};
const getVatRateTypeSetting = (vatRateType, vatRateTypes, countryCode) => {
  const vatRateTypeSetting = vatRateTypes.find((vrt) => vrt.countryCode === countryCode);
  return vatRateTypeSetting == null ? void 0 : vatRateTypeSetting.typeRateOptions.find((tro) => tro.type === vatRateType);
};
export const mapTaxCode = (taxCode, typeOfGoods, vatRates) => {
  var _a;
  const vatRateTypeSetting = getVatRateTypeSetting(taxCode.VatRateType, vatRates, taxCode.CountryCode);
  return {
    clientTaxCode: taxCode.ClientTaxCode,
    companyId: taxCode.CompanyId,
    countryCode: taxCode.CountryCode,
    id: taxCode.Id,
    reverseCharge: taxCode.ReverseCharge !== void 0 && !isNaN(taxCode.ReverseCharge) ? parseFloat((taxCode.ReverseCharge * 100).toFixed()) : void 0,
    //DEVNOTE: Convert to percentage. Backend handles this as a number from 0 to 1.
    transactionTypeCode: taxCode.TransactionTypeCode,
    typeOfGoods: (_a = typeOfGoods.find((tog) => tog.id === taxCode.TypeOfGoods)) == null ? void 0 : _a.name,
    vatRateType: vatRateTypeSetting == null ? void 0 : vatRateTypeSetting.type,
    vatRate: vatRateTypeSetting == null ? void 0 : vatRateTypeSetting.rate.toString()
  };
};
export const mapTypeOfGoods = (typeOfGoods) => ({
  id: typeOfGoods.Id,
  name: typeOfGoods.Name
});
export const mapVatRateTypeSetting = (vatRateTypeSetting) => ({
  countryCode: vatRateTypeSetting.CountryCode,
  typeRateOptions: vatRateTypeSetting.TypeRateOptions.map(
    (tro) => ({
      type: tro.Type,
      rate: tro.Rate
    })
  )
});
export const mapTransactionType = (transactionType) => ({
  code: transactionType.Code,
  description: transactionType.Description
});
