var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState } from "GlobalShared/haunted/CustomHooks";
import { notifyError, notifySuccess, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { useEffect } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
export const useVatNumberValidationModal = (props) => {
  const loadCountryConfigurations = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const countryConfigurations = yield props.getCountryConfigurations();
        const sortedCountryConfigurations = [...countryConfigurations].sort((a, b) => {
          var _a, _b;
          const countryCodeA = ((_a = a.CountryCode) == null ? void 0 : _a.toUpperCase()) || "";
          const countryCodeB = ((_b = b.CountryCode) == null ? void 0 : _b.toUpperCase()) || "";
          return countryCodeA.localeCompare(countryCodeB);
        });
        setCurrentCountryConfigurations(sortedCountryConfigurations);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const handleCountryClick = (countryConfiguration) => __async(void 0, null, function* () {
    if (!props.permissionManager.hasPermission("EU_VAT_Validation_Modify")) {
      return;
    }
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const newStatus = !countryConfiguration.IsActive;
        yield props.updateCountryConfigurations(countryConfiguration.CountryCode, newStatus);
        yield loadCountryConfigurations();
        notifySuccess("VAT number validation status updated successfully.");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [currentCountryConfigurations, setCurrentCountryConfigurations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (!showModal || !props.isAdmin || !props.permissionManager.hasPermission("EU_VAT_Validation_Read") || !props.worldCountries || props.worldCountries.length === 0) {
      return;
    }
    loadCountryConfigurations();
  }, [showModal]);
  const countryConfigurationTemplate = (countryConfiguration) => {
    const statusBasedClasses = countryConfiguration.IsActive ? "border-brand-secondary bg-brand-secondary" : "border-gray-100 bg-gray-100";
    const permissionBasedClasses = props.permissionManager.hasPermission("EU_VAT_Validation_Modify") ? countryConfiguration.IsActive ? `${statusBasedClasses} cursor-pointer hover:bg-brand-secondary-dark hover:border-brand-secondary-dark` : `${statusBasedClasses} cursor-pointer hover:bg-gray-100 hover:border-brand-secondary` : statusBasedClasses;
    return html`
			<div @click=${() => handleCountryClick(countryConfiguration)} class="flex items-center justify-center w-40 py-2 px-4 select-none border rounded-full ${permissionBasedClasses}">
				${countryConfiguration.CountryName}
			</div>
		`;
  };
  const contentTemplate = () => html`
			<div class="pr-4 pl-2 py-2 evenly-spaced-grid">
				${currentCountryConfigurations.map((countryConfiguration) => countryConfigurationTemplate(countryConfiguration))}
			</div>
		`;
  const mainTemplate = () => props.isAdmin && props.permissionManager.hasPermission("EU_VAT_Validation_Read") ? html`
					<dc-modal .customClass=${"w-3/5"} .visible=${showModal} .header=${i18next.t("VAT Number Validation")} .content=${contentTemplate()} @close=${() => setShowModal(false)}></dc-modal>
			  ` : "";
  return { mainTemplate, setShowModal };
};
