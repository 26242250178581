var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { PaymentInfo } from "./../models/V1/Payments/PaymentInfo/PaymentInfo";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class Payments {
  /**
   * Creates an instance of Payments.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Payments
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetPaymentInfoRq_ _getPaymentInfoRq
   * @returns Promise<GetPaymentInfoRq_>
   * @memberof Payments
   */
  getPaymentInfo(pGetPaymentInfoRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Orders/{orderId}/PaymentInfo", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "orderId", value: pGetPaymentInfoRq.orderId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: PaymentInfo },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param PayRq_ _payRq
   * @returns Promise<PayRq_>
   * @memberof Payments
   */
  pay(pPayRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Orders/{orderId}/Pay", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "orderId", value: pPayRq.orderId, required: true }]);
    client.addBody({ value: pPayRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadBankTransferPaymentProofRq_ _downloadBankTransferPaymentProofRq
   * @returns Promise<DownloadBankTransferPaymentProofRq_>
   * @memberof Payments
   */
  downloadBankTransferPaymentProof(pDownloadBankTransferPaymentProofRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/orders/{orderId}/payments/{paymentId}/proof",
      "GET",
      "application/json; charset=UTF-8",
      "image/png, image/jpeg, application/pdf",
      options
    );
    client.addPathParams([
      { name: "orderId", value: pDownloadBankTransferPaymentProofRq.orderId, required: true },
      { name: "paymentId", value: pDownloadBankTransferPaymentProofRq.paymentId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadBankTransferPaymentProofRq_ _uploadBankTransferPaymentProofRq
   * @returns Promise<UploadBankTransferPaymentProofRq_>
   * @memberof Payments
   */
  uploadBankTransferPaymentProof(pUploadBankTransferPaymentProofRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/orders/{orderId}/payments/{paymentId}/proof", "PATCH", "multipart/form-data", "text/plain, application/json, text/json", options);
    client.addPathParams([
      { name: "orderId", value: pUploadBankTransferPaymentProofRq.orderId, required: true },
      { name: "paymentId", value: pUploadBankTransferPaymentProofRq.paymentId, required: true }
    ]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadBankTransferPaymentProofRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 201 }, { statusCode: 409, clazz: ApiError }, { statusCode: 415, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
