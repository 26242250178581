import "./dc-modal.scss";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { component } from "haunted";
import { closeIcon } from "GlobalShared/templates/commons";
import classNames from "classnames";
const DEFAULTS = {
  dismissable: true,
  visible: false,
  header: html``,
  content: html``,
  overflowH: true,
  overflowW: true,
  showHeader: true,
  customClass: void 0
};
export const Component = (host) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const props = {
    dismissable: (_a = host.dismissable) != null ? _a : DEFAULTS.dismissable,
    visible: (_b = host.visible) != null ? _b : DEFAULTS.visible,
    header: (_c = host.header) != null ? _c : DEFAULTS.header,
    content: (_d = host.content) != null ? _d : DEFAULTS.content,
    overflowH: (_e = host.overflowH) != null ? _e : DEFAULTS.overflowH,
    overflowW: (_f = host.overflowW) != null ? _f : DEFAULTS.overflowW,
    showHeader: (_g = host.showHeader) != null ? _g : DEFAULTS.showHeader,
    customClass: (_h = host.customClass) != null ? _h : DEFAULTS.customClass
  };
  const onClose = (_e2) => {
    if (props.dismissable) {
      host.dispatchEvent(new CustomEvent("close"));
    }
  };
  const [isVisible, setIsVisible] = useState(props.visible);
  useEffect(() => {
    if (props.dismissable) {
      const onCloseOnEscKey = (e) => {
        if (e.key === "Escape") {
          onClose(e);
        }
      };
      document.addEventListener("keyup", onCloseOnEscKey, true);
      return () => {
        document.removeEventListener("keyup", onCloseOnEscKey);
      };
    }
    return void 0;
  }, []);
  useEffect(() => {
    setIsVisible(props.visible);
  }, [props.visible]);
  return html`
		${isVisible ? html`
					<div class="modal-overlay" @click=${onClose}></div>
					<div class="dc-modal rounded-xl ${(_i = props.customClass) != null ? _i : "default"}">
						${props.showHeader ? html`
									<div class="modal-header rounded-t-xl">
										${props.header}${props.dismissable ? html` <div class="flex items-center" @click=${onClose}>${closeIcon()}</div> ` : ""}
									</div>
							  ` : ""}
						<div class="modal-content rounded-b-xl">
							<div class=${classNames("p-4", { "modal-overflow-h": props.overflowH, "modal-overflow-w": props.overflowW })}>${props.content}</div>
						</div>
					</div>
			  ` : ""}
	`;
};
customElements.define(
  "dc-modal",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
