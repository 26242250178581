import { component } from "haunted";
import { Header } from "WebComponents/header";
import { Footer } from "WebComponents/footer";
import { Home } from "WebComponents/home";
import { RedirectWithSetCompany } from "WebComponents/redirect-with-set-company";
import "@webcomponents/custom-elements";
import "GlobalShared/ui/dc-components";
import { GuidesAndTutorials } from "WebComponents/static/guides-and-tutorials";
import { FAQs } from "WebComponents/static/faqs";
import { Legal } from "WebComponents/static/legal";
import { Privacy } from "WebComponents/static/privacy";
import { Accessibility } from "WebComponents/static/accessibility";
import { Contact } from "WebComponents/static/contact";
import * as Login from "WebComponents/login";
import * as EmailVerify from "WebComponents/email-verify";
import * as RegisterByInvitation from "WebComponents/register-by-invitation";
import * as RegisterDeliveryService from "WebComponents/register-delivery-service";
import * as Register from "WebComponents/register";
import * as ForgotPassword from "WebComponents/forgot-password";
import * as ResetPassword from "WebComponents/reset-password";
import * as LanguageSelect from "WebComponents/language-select";
import * as ProfileSelect from "WebComponents/profile-select";
import { AdminHome } from "WebComponents/admin/admin-home";
import { AdminSignup } from "WebComponents/admin/admin-signup";
import { AdminServices } from "WebComponents/admin/admin-services";
import { AdminOrders } from "WebComponents/admin/admin-orders";
import { AdminComplianceUpload } from "WebComponents/admin/admin-compliance-upload";
import { AdminQuestionnaire } from "WebComponents/admin/admin-questionnaire";
import * as AC from "GlobalShared/components/q11e/all-components";
import * as Q11eBankingInformation from "GlobalShared/components/q11e/q11e-banking-information";
import * as Q11eBusinessDetails from "GlobalShared/components/q11e/q11e-business-details";
import * as Q11eTaxDetails from "GlobalShared/components/q11e/q11e-tax-details";
import * as Q11eCountryDetails from "GlobalShared/components/q11e/q11e-country-details";
import * as Q11ePersonnelDetails from "GlobalShared/components/q11e/q11e-personnel-details";
import * as OrderVatCountryServices from "GlobalShared/components/orders/services/order-vat-country-service";
import * as NotificationFeedback from "WebComponents/notification/notification-feedback";
import { AdminReturnLibrary } from "WebComponents/admin/admin-return-library";
import { AdminClientAccounts } from "WebComponents/admin/admin-client-accounts";
import * as CookieConsent from "WebComponents/cookie-consent";
import "WebComponents/admin/admin-amazon-skus";
import "WebComponents/admin/admin-default-vat-rates";
import "WebComponents/admin/cesop/periods";
import "WebComponents/admin/cesop/company-data";
import "WebComponents/login-twofactor";
import { AdminCustomDataTemplate } from "WebComponents/custom-source/AdminCustomDataTemplate";
customElements.define("ww-home", component(Home, { useShadowDOM: false }));
customElements.define("ww-admin-home", component(AdminHome, { useShadowDOM: false }));
customElements.define("ww-admin-signup", component(AdminSignup, { useShadowDOM: false }));
customElements.define("ww-admin-services", component(AdminServices, { useShadowDOM: false }));
customElements.define("ww-admin-orders", component(AdminOrders, { useShadowDOM: false }));
customElements.define("ww-admin-compliance-uploads", component(AdminComplianceUpload, { useShadowDOM: false }));
customElements.define("ww-admin-return-library", component(AdminReturnLibrary, { useShadowDOM: false }));
customElements.define("ww-admin-questionnaire", component(AdminQuestionnaire, { useShadowDOM: false }));
customElements.define("ww-admin-client-accounts", component(AdminClientAccounts, { useShadowDOM: false }));
customElements.define("ww-admin-custom-data-template", component(AdminCustomDataTemplate, { useShadowDOM: false }));
customElements.define(
  Login.name,
  component(Login.Component, {
    useShadowDOM: false,
    observedAttributes: Login.observedAttributes
  })
);
customElements.define(
  RegisterByInvitation.name,
  component(RegisterByInvitation.Component, {
    useShadowDOM: false,
    observedAttributes: RegisterByInvitation.observedAttributes
  })
);
customElements.define(
  RegisterDeliveryService.name,
  component(RegisterDeliveryService.Component, {
    useShadowDOM: false,
    observedAttributes: RegisterDeliveryService.observedAttributes
  })
);
customElements.define(
  Register.name,
  component(Register.Component, {
    useShadowDOM: false,
    observedAttributes: Register.observedAttributes
  })
);
customElements.define(
  ForgotPassword.name,
  component(ForgotPassword.Component, {
    useShadowDOM: false,
    observedAttributes: ForgotPassword.observedAttributes
  })
);
customElements.define(
  ResetPassword.name,
  component(ResetPassword.Component, {
    useShadowDOM: false,
    observedAttributes: ResetPassword.observedAttributes
  })
);
customElements.define(
  LanguageSelect.name,
  component(LanguageSelect.Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
customElements.define(
  ProfileSelect.name,
  component(ProfileSelect.Component, {
    useShadowDOM: false,
    observedAttributes: ProfileSelect.observedAttributes
  })
);
customElements.define("ww-header", component(Header, { useShadowDOM: false }));
customElements.define("ww-footer", component(Footer, { useShadowDOM: false }));
customElements.define("ww-guides-and-tutorials", component(GuidesAndTutorials, { useShadowDOM: false }));
customElements.define("ww-faqs", component(FAQs, { useShadowDOM: false }));
customElements.define("ww-legal", component(Legal, { useShadowDOM: false }));
customElements.define("ww-privacy", component(Privacy, { useShadowDOM: false }));
customElements.define("ww-accessibility", component(Accessibility, { useShadowDOM: false }));
customElements.define("ww-contact", component(Contact, { useShadowDOM: false }));
customElements.define("ww-redirect-with-set-company", component(RedirectWithSetCompany, { useShadowDOM: false }));
customElements.define(
  AC.AnswerString.name,
  component(AC.AnswerString.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerString.observedAttributes
  })
);
customElements.define(
  AC.AnswerOssDetail.name,
  component(AC.AnswerOssDetail.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerOssDetail.observedAttributes
  })
);
customElements.define(
  AC.AnswerBool.name,
  component(AC.AnswerBool.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerBool.observedAttributes
  })
);
customElements.define(
  AC.AnswerAddress.name,
  component(AC.AnswerAddress.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerAddress.observedAttributes
  })
);
customElements.define(
  AC.AnswerMoney.name,
  component(AC.AnswerMoney.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerMoney.observedAttributes
  })
);
customElements.define(
  AC.AnswerDate.name,
  component(AC.AnswerDate.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerDate.observedAttributes
  })
);
customElements.define(
  AC.AnswerFileRef.name,
  component(AC.AnswerFileRef.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerFileRef.observedAttributes
  })
);
customElements.define(
  AC.AnswerName.name,
  component(AC.AnswerName.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerName.observedAttributes
  })
);
customElements.define(
  AC.AnswerMultiString.name,
  component(AC.AnswerMultiString.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerMultiString.observedAttributes
  })
);
customElements.define(
  AC.AnswerExistingVatNumbers.name,
  component(AC.AnswerExistingVatNumbers.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerExistingVatNumbers.observedAttributes
  })
);
customElements.define(
  AC.AnswerTurnOvers.name,
  component(AC.AnswerTurnOvers.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerTurnOvers.observedAttributes
  })
);
customElements.define(
  AC.AnswerLegalRepresentatives.name,
  component(AC.AnswerLegalRepresentatives.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerLegalRepresentatives.observedAttributes
  })
);
customElements.define(
  AC.AnswerBusinessOwners.name,
  component(AC.AnswerBusinessOwners.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerBusinessOwners.observedAttributes
  })
);
customElements.define(
  AC.AnswerBeneficialOwners.name,
  component(AC.AnswerBeneficialOwners.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerBeneficialOwners.observedAttributes
  })
);
customElements.define(
  AC.AnswerBankInformation.name,
  component(AC.AnswerBankInformation.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerBankInformation.observedAttributes
  })
);
customElements.define(
  AC.AnswerVatNumber.name,
  component(AC.AnswerVatNumber.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerVatNumber.observedAttributes
  })
);
customElements.define(
  AC.AnswerCountry.name,
  component(AC.AnswerCountry.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerCountry.observedAttributes
  })
);
customElements.define(
  AC.AnswerMultiCountry.name,
  component(AC.AnswerMultiCountry.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerMultiCountry.observedAttributes
  })
);
customElements.define(
  Q11eBankingInformation.name,
  component(Q11eBankingInformation.Component, {
    useShadowDOM: false,
    observedAttributes: Q11eBankingInformation.observedAttributes
  })
);
customElements.define(
  Q11eBusinessDetails.name,
  component(Q11eBusinessDetails.Component, {
    useShadowDOM: false,
    observedAttributes: Q11eBusinessDetails.observedAttributes
  })
);
customElements.define(
  Q11eTaxDetails.name,
  component(Q11eTaxDetails.Component, {
    useShadowDOM: false,
    observedAttributes: Q11eTaxDetails.observedAttributes
  })
);
customElements.define(
  Q11eCountryDetails.name,
  component(Q11eCountryDetails.Component, {
    useShadowDOM: false,
    observedAttributes: Q11eCountryDetails.observedAttributes
  })
);
customElements.define(
  Q11ePersonnelDetails.name,
  component(Q11ePersonnelDetails.Component, {
    useShadowDOM: Q11ePersonnelDetails.useShadowDOM,
    observedAttributes: Q11ePersonnelDetails.observedAttributes
  })
);
customElements.define(
  OrderVatCountryServices.name,
  component(OrderVatCountryServices.Component, {
    useShadowDOM: false,
    observedAttributes: OrderVatCountryServices.observedAttributes
  })
);
customElements.define(
  AC.AnswerFixedEstablishments.name,
  component(AC.AnswerFixedEstablishments.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerFixedEstablishments.observedAttributes
  })
);
customElements.define(
  AC.AnswerAccountingLocation.name,
  component(AC.AnswerAccountingLocation.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerAccountingLocation.observedAttributes
  })
);
customElements.define(
  AC.AnswerClientBase.name,
  component(AC.AnswerClientBase.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerClientBase.observedAttributes
  })
);
customElements.define(
  AC.AnswerLegalEntityForm.name,
  component(AC.AnswerLegalEntityForm.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerLegalEntityForm.observedAttributes
  })
);
customElements.define(
  AC.AnswerLegalEntityType.name,
  component(AC.AnswerLegalEntityType.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerLegalEntityType.observedAttributes
  })
);
customElements.define(
  AC.AnswerProductType.name,
  component(AC.AnswerProductType.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerProductType.observedAttributes
  })
);
customElements.define(
  AC.AnswerOrganisationType.name,
  component(AC.AnswerOrganisationType.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerOrganisationType.observedAttributes
  })
);
customElements.define(
  AC.AnswerPercentage.name,
  component(AC.AnswerPercentage.Component, {
    useShadowDOM: false,
    observedAttributes: AC.AnswerPercentage.observedAttributes
  })
);
customElements.define(
  CookieConsent.name,
  component(CookieConsent.Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
customElements.define(
  EmailVerify.name,
  component(EmailVerify.Component, {
    useShadowDOM: false,
    observedAttributes: EmailVerify.observedAttributes
  })
);
customElements.define(
  NotificationFeedback.name,
  component(NotificationFeedback.Component, {
    useShadowDOM: false,
    observedAttributes: NotificationFeedback.observedAttributes
  })
);
