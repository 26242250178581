import { html } from "lit-html";
import { component } from "haunted";
import { PRIMARY_COLOR } from "GlobalShared/common/Constants";
export const Component = (host) => {
  var _a;
  const props = {
    disabled: host.disabled,
    visible: (_a = host.visible) != null ? _a : false
  };
  const onClick = (e) => {
    e.stopPropagation();
    if (!props.disabled) {
      host.dispatchEvent(new CustomEvent("click"));
    }
  };
  return html` <div @click=${onClick} class="cursor-pointer">
		${props.visible ? html`<svg width="24" height="24" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" class="hover:scale-110">
					<g clip-path="url(#clip0_2601_6497)">
						<path
							d="M32.1538 7.05469C41.1316 7.05469 48.3205 11.137 53.5538 15.9959C58.7538 20.8104 62.2316 26.579 63.876 30.5393C64.2427 31.4157 64.2427 32.3919 63.876 33.2683C62.2316 37.2287 58.7538 42.9972 53.5538 47.8117C48.3205 52.6704 41.1316 56.7529 32.1538 56.7529C23.176 56.7529 15.987 52.6704 10.7538 47.8117C5.55378 42.9859 2.07603 37.2287 0.420528 33.2683C0.0537784 32.3919 0.0537784 31.4157 0.420528 30.5393C2.07603 26.579 5.55378 20.8104 10.7538 15.9959C15.987 11.137 23.176 7.05469 32.1538 7.05469ZM16.1538 31.9038C16.1538 40.7231 23.3205 47.8781 32.1538 47.8781C40.9871 47.8781 48.1538 40.7231 48.1538 31.9038C48.1538 23.0846 40.9871 15.9294 32.1538 15.9294C23.3205 15.9294 16.1538 23.0846 16.1538 31.9038ZM32.1538 24.804C32.1538 28.72 35.3428 31.9038 39.2649 31.9038C40.5427 31.9038 41.7427 31.571 42.776 30.9719C42.7982 31.2825 42.8205 31.582 42.8205 31.9038C42.8205 37.7833 38.0428 42.5534 32.1538 42.5534C26.2648 42.5534 21.487 37.7833 21.487 31.9038C21.487 26.0243 26.2648 21.2541 32.1538 21.2541C32.4648 21.2541 32.776 21.2652 33.087 21.2985C32.4983 22.3302 32.1538 23.5283 32.1538 24.804Z"
							fill=${PRIMARY_COLOR}
						/>
						<path
							d="M32.2163 42.5674C26.3138 42.5674 21.5288 37.7901 21.5288 31.897C21.5288 26.0039 26.3138 21.2266 32.2163 21.2266C38.1189 21.2266 42.9038 26.0039 42.9038 31.897C42.9038 37.7901 38.1189 42.5674 32.2163 42.5674Z"
							fill=${PRIMARY_COLOR}
						/>
					</g>
					<line x1="4.24247" y1="11.9623" x2="52.9964" y2="59.8566" stroke="white" stroke-width="6" stroke-linecap="round" />
					<line x1="4.2425" y1="3.96494" x2="60.9649" y2="59.7575" stroke=${PRIMARY_COLOR} stroke-width="6" stroke-linecap="round" />
					<defs>
						<clipPath id="clip0_2601_6497">
							<rect width="64" height="49.92" fill="white" transform="matrix(-1 0 0 1 64.1538 7)" />
						</clipPath>
					</defs>
			  </svg>` : html` <svg width="24" height="24" viewBox="0 0 256 200" fill="none" class="hover:scale-110">
					<path
						d="M128 0.222656C92.0889 0.222656 63.3333 16.5782 42.4 36.0449C21.6 55.3338 7.68889 78.4449 1.11111 94.3115C-0.355558 97.8227 -0.355558 101.734 1.11111 105.245C7.68889 121.112 21.6 144.223 42.4 163.512C63.3333 182.978 92.0889 199.334 128 199.334C163.911 199.334 192.667 182.978 213.6 163.512C234.4 144.178 248.311 121.112 254.933 105.245C256.4 101.734 256.4 97.8227 254.933 94.3115C248.311 78.4449 234.4 55.3338 213.6 36.0449C192.667 16.5782 163.911 0.222656 128 0.222656ZM192 99.7782C192 135.112 163.333 163.778 128 163.778C92.6667 163.778 64 135.112 64 99.7782C64 64.4449 92.6667 35.7782 128 35.7782C163.333 35.7782 192 64.4449 192 99.7782ZM128 71.3338C128 87.0227 115.244 99.7782 99.5556 99.7782C94.4444 99.7782 89.6444 98.4449 85.5111 96.0449C85.4222 97.2893 85.3333 98.4893 85.3333 99.7782C85.3333 123.334 104.444 142.445 128 142.445C151.556 142.445 170.667 123.334 170.667 99.7782C170.667 76.2227 151.556 57.1115 128 57.1115C126.756 57.1115 125.511 57.156 124.267 57.2893C126.622 61.4227 128 66.2227 128 71.3338Z"
						fill=${PRIMARY_COLOR}
					/>
					<circle cx="127.75" cy="99.75" r="42.75" fill=${PRIMARY_COLOR} />
			  </svg>`}
	</div>`;
};
customElements.define(
  "dc-view-button",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
