import { FormatSettings } from "./FormatSettings";
import { DefaultFormatSettings } from "./DefaultFormatSettings";
import { DsvFormatSettings } from "./DsvFormatSettings";
export class FormatSettingsFactory {
  static create($type) {
    if ($type === "V1.GenerationConfiguration.FormatSettings") {
      const result = new FormatSettings();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.DefaultFormatSettings") {
      const result = new DefaultFormatSettings();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.DsvFormatSettings") {
      const result = new DsvFormatSettings();
      return result;
    }
    throw new Error($type + " not found");
  }
}
