var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { html } from "lit-html";
import { useEffect, useState, useValidationContext } from "GlobalShared/haunted/CustomHooks";
import { PercentageAnswer as WebPercentageAnswer } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { PercentageAnswer as AdminPercentageAnswer } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Answers";
import { PercentageValidator } from "./AnswerValidators";
export const observedAttributes = ["q11e", "questionId"];
export const useShadowDOM = false;
export const name = "ww-answer-percentage";
const DEFAULTS = {
  validation: {
    required: false
  }
};
export class PercentageChangeEvent extends CustomEvent {
  constructor(detail) {
    super("change", { detail, bubbles: true });
  }
}
export const Component = (host) => {
  const props = {
    q11e: host.q11e,
    questionId: host.questionId,
    validation: host.validation !== void 0 ? __spreadValues(__spreadValues({}, DEFAULTS.validation), host.validation) : DEFAULTS.validation
  };
  const getPercentageAnswer = () => {
    var _a;
    if ((_a = props.q11e) == null ? void 0 : _a.isAdmin) {
      return new AdminPercentageAnswer({ Percent: percent, QuestionId: props.questionId });
    }
    return new WebPercentageAnswer({ Percent: percent, QuestionId: props.questionId });
  };
  const getLabel = () => {
    return question.Label + (props.validation.required ? "*" : "");
  };
  const [percent, setPercent] = useState(void 0);
  const [question, setQuestion] = useState(void 0);
  const validationContext = useValidationContext(new PercentageValidator(props.validation));
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => {
    if (validationContext.validationResult.isValid() && dispatchIfValid) {
      setDispatchIfValid(false);
      host.dispatchEvent(
        new PercentageChangeEvent({
          answer: getPercentageAnswer()
        })
      );
    }
  }, [validationContext.validationResult]);
  useEffect(() => {
    if (props.q11e) {
      setQuestion(props.q11e.getQuestion(props.questionId));
      const answer = props.q11e.getAnswer(props.questionId);
      if (answer instanceof WebPercentageAnswer || answer instanceof AdminPercentageAnswer) {
        setPercent(answer.Percent);
      }
    }
  }, [props.q11e, props.questionId]);
  useEffect(() => {
    validationContext.validate(getPercentageAnswer());
  }, [percent]);
  return html`
		${props.q11e !== void 0 && question !== void 0 ? html`<dc-input
					.readonly=${props.q11e.isReadonly(props.questionId)}
					.label=${getLabel()}
					.value=${percent}
					.validationMessage=${validationContext.getValidationMessage("percent")}
					.type=${"number"}
					@change=${(e) => {
    var _a;
    setPercent(Number((_a = e.detail) == null ? void 0 : _a.value));
    setDispatchIfValid(true);
    validationContext.validateField("percent");
  }}
				></dc-input>` : ""}
	`;
};
