var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class FormatSettings {
  constructor(_data) {
    /**
     */
    __publicField(this, "DecimalSeparator");
    /**
     */
    __publicField(this, "IncludesHeader");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof FormatSettings
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.FormatSettings");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.DecimalSeparator = FormatSettingsDecimalSeparator[_data["DecimalSeparator"]];
      this.IncludesHeader = _data["IncludesHeader"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["$type"] = this.$type;
    _data["DecimalSeparator"] = FormatSettingsDecimalSeparator[this.DecimalSeparator];
    _data["IncludesHeader"] = this.IncludesHeader;
    return _data;
  }
}
export var FormatSettingsDecimalSeparator = /* @__PURE__ */ ((FormatSettingsDecimalSeparator2) => {
  FormatSettingsDecimalSeparator2["Dot"] = "Dot";
  FormatSettingsDecimalSeparator2["Comma"] = "Comma";
  return FormatSettingsDecimalSeparator2;
})(FormatSettingsDecimalSeparator || {});
