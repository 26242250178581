import { PspIdentifierType } from "WebServices/APIs/self/models/V1/Cesop/Psps/PspAbstraction";
import { useMemo } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
export const useIdentifierTypes = () => {
  return useMemo(
    () => [
      {
        value: PspIdentifierType.Bic,
        label: i18next.t("EU Bic")
      },
      {
        value: PspIdentifierType.Other,
        label: i18next.t("Other")
      }
    ],
    []
  );
};
