var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../../converters/DateConverter";
export class NewFrequencyConfig {
  constructor(_data) {
    /**
     */
    __publicField(this, "EFiling");
    /**
     */
    __publicField(this, "FilingExtension");
    /**
     */
    __publicField(this, "FrequencyType");
    /**
     */
    __publicField(this, "ValidFrom");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof NewFrequencyConfig
     */
    __publicField(this, "$type", "V1.Company.VatRegistration.Frequency.NewFrequencyConfig");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.EFiling = _data["EFiling"];
      this.FilingExtension = _data["FilingExtension"];
      this.FrequencyType = NewFrequencyConfigFrequencyType[_data["FrequencyType"]];
      this.ValidFrom = DateConverter.from(_data["ValidFrom"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["EFiling"] = this.EFiling;
    _data["FilingExtension"] = this.FilingExtension;
    _data["FrequencyType"] = NewFrequencyConfigFrequencyType[this.FrequencyType];
    _data["ValidFrom"] = DateConverter.toUtcDateString(this.ValidFrom);
    return _data;
  }
}
export var NewFrequencyConfigFrequencyType = /* @__PURE__ */ ((NewFrequencyConfigFrequencyType2) => {
  NewFrequencyConfigFrequencyType2["Monthly"] = "Monthly";
  NewFrequencyConfigFrequencyType2["Quarterly"] = "Quarterly";
  NewFrequencyConfigFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  NewFrequencyConfigFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  NewFrequencyConfigFrequencyType2["BiMonthly"] = "BiMonthly";
  NewFrequencyConfigFrequencyType2["FourMonthly"] = "FourMonthly";
  NewFrequencyConfigFrequencyType2["SixMonthly"] = "SixMonthly";
  NewFrequencyConfigFrequencyType2["Yearly"] = "Yearly";
  return NewFrequencyConfigFrequencyType2;
})(NewFrequencyConfigFrequencyType || {});
