var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class Name {
  constructor(_data) {
    /**
     */
    __publicField(this, "NameType");
    /**
     */
    __publicField(this, "Value");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Name
     */
    __publicField(this, "$type", "V1.Cesop.Name");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.NameType = NameNameType[_data["NameType"]];
      this.Value = _data["Value"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["NameType"] = NameNameType[this.NameType];
    _data["Value"] = this.Value;
    return _data;
  }
}
export var NameNameType = /* @__PURE__ */ ((NameNameType2) => {
  NameNameType2["Business"] = "Business";
  NameNameType2["Trade"] = "Trade";
  NameNameType2["Legal"] = "Legal";
  NameNameType2["Person"] = "Person";
  NameNameType2["Other"] = "Other";
  return NameNameType2;
})(NameNameType || {});
