var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TransactionDataField } from "./TransactionDataField";
export class SeparateTransactionData {
  constructor(_data) {
    /**
     */
    __publicField(this, "PurchaseFields");
    /**
     */
    __publicField(this, "SaleFields");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SeparateTransactionData
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.SeparateTransactionData");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["PurchaseFields"] && _data["PurchaseFields"].constructor === Array) {
        this.PurchaseFields = [];
        for (let item of _data["PurchaseFields"]) {
          const purchaseFields_ = new TransactionDataField();
          this.PurchaseFields.push(purchaseFields_.init(item));
        }
      }
      if (_data["SaleFields"] && _data["SaleFields"].constructor === Array) {
        this.SaleFields = [];
        for (let item of _data["SaleFields"]) {
          const saleFields_ = new TransactionDataField();
          this.SaleFields.push(saleFields_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.PurchaseFields && this.PurchaseFields.constructor === Array) {
      _data["PurchaseFields"] = [];
      for (let item of this.PurchaseFields) {
        _data["PurchaseFields"].push(item.toJSON());
      }
    }
    if (this.SaleFields && this.SaleFields.constructor === Array) {
      _data["SaleFields"] = [];
      for (let item of this.SaleFields) {
        _data["SaleFields"].push(item.toJSON());
      }
    }
    return _data;
  }
}
