var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class TaxCode {
  constructor(_data) {
    /**
     */
    __publicField(this, "ClientTaxCode");
    /**
     */
    __publicField(this, "CompanyId");
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "ReverseCharge");
    /**
     */
    __publicField(this, "TransactionTypeCode");
    /**
     */
    __publicField(this, "TypeOfGoods");
    /**
     */
    __publicField(this, "VatRateType");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TaxCode
     */
    __publicField(this, "$type", "V1.ComplianceMasterData.TaxCode.TaxCode");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientTaxCode = _data["ClientTaxCode"];
      this.CompanyId = _data["CompanyId"];
      this.CountryCode = _data["CountryCode"];
      this.Id = _data["Id"];
      this.ReverseCharge = _data["ReverseCharge"];
      this.TransactionTypeCode = _data["TransactionTypeCode"];
      this.TypeOfGoods = _data["TypeOfGoods"];
      this.VatRateType = TaxCodeVatRateType[_data["VatRateType"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientTaxCode"] = this.ClientTaxCode;
    _data["CompanyId"] = this.CompanyId;
    _data["CountryCode"] = this.CountryCode;
    _data["Id"] = this.Id;
    _data["ReverseCharge"] = this.ReverseCharge;
    _data["TransactionTypeCode"] = this.TransactionTypeCode;
    _data["TypeOfGoods"] = this.TypeOfGoods;
    _data["VatRateType"] = TaxCodeVatRateType[this.VatRateType];
    return _data;
  }
}
export var TaxCodeVatRateType = /* @__PURE__ */ ((TaxCodeVatRateType2) => {
  TaxCodeVatRateType2["Standard"] = "Standard";
  TaxCodeVatRateType2["Standard2"] = "Standard2";
  TaxCodeVatRateType2["Reduced"] = "Reduced";
  TaxCodeVatRateType2["Reduced2"] = "Reduced2";
  TaxCodeVatRateType2["SuperReduced"] = "SuperReduced";
  TaxCodeVatRateType2["SuperReduced2"] = "SuperReduced2";
  TaxCodeVatRateType2["Exempt"] = "Exempt";
  TaxCodeVatRateType2["ZeroRated"] = "ZeroRated";
  TaxCodeVatRateType2["Middle"] = "Middle";
  return TaxCodeVatRateType2;
})(TaxCodeVatRateType || {});
