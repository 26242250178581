import i18next from "i18next";
import { html } from "lit-html";
const priceLine = (label, value, tooltip) => {
  return html`<div class="flex justify-between text-gray-600">
		<div>${label} ${tooltip !== void 0 ? html`<dc-tooltip .label=${tooltip}></dc-tooltip>` : ""}</div>
		<div class="whitespace-nowrap">&euro; ${value}</div>
	</div>`;
};
export const priceBreakdownTemplate = (summary) => {
  if (summary === void 0) {
    return html``;
  }
  return html`
		<div>
			<div class="text-gray-600 text-lg pb-4">${i18next.t("Order Summary")}</div>
			<hr class="mb-4" />
			<div class="text-sm">
				${summary.countries ? summary.countries.filter((c) => c.total > 0).map(
    (country) => html`
									<div class="py-2">
										${priceLine(country.label, country.total)}

										<div class="pl-4">
											${country.fiscalRep > 0 ? priceLine(
      i18next.t("Fiscal representation"),
      country.fiscalRep,
      i18next.t(
        "When operating in a country where fiscal representation is required, you must appoint the Fiscal Representative provided by Tax Desk."
      )
    ) : ""}
											${country.filingAgent > 0 ? priceLine(i18next.t("Filing agent"), country.filingAgent) : ""}
											${country.retro > 0 ? country.label === "Italy" ? priceLine(
      i18next.t("Retrospective Return"),
      country.retro,
      i18next.t(
        "The calculation of the retrospective periods is crucial for preparation of the registration documents in Italy. You must provide Tax Desk your transaction data covering past reporting periods as soon as possible. Without them the Italian VAT registration process might be delayed."
      )
    ) : priceLine(i18next.t("Retrospective Return"), country.retro) : ""}
											${country.intrastat > 0 ? priceLine(i18next.t("Intrastat Report"), country.intrastat) : ""}
											${country.homeCountryCompliance > 0 ? priceLine(i18next.t("Home country compliance"), country.homeCountryCompliance) : ""}
											${country.monthlyCompliance > 0 ? priceLine(i18next.t("Monthly country compliance"), country.monthlyCompliance) : ""}
											${country.selfRevision > 0 ? priceLine(i18next.t("Self Revision / Retrospective Returns"), country.selfRevision) : ""}
											${country.deregistration > 0 ? priceLine(i18next.t("Deregistration"), country.deregistration) : ""}
										</div>
									</div>
								`
  ) : ""}
				${summary.eori ? html`<div class="py-2">${priceLine(i18next.t("EORI number application in the EU"), summary.eori)}</div>` : ""}
				${summary.ukEori ? html`<div class="py-2">${priceLine(i18next.t("EORI number application in the UK"), summary.ukEori)}</div>` : ""}
				${summary.ioss ? html`<div class="py-2">
							${priceLine(
    i18next.t("IOSS Registration and Compliance"),
    summary.ioss,
    summary.isIossTier0 ? i18next.t("This is the IOSS registration fee only. Monthly compliance fees will be charged separately. Detailed instructions will follow") : i18next.t("Total fee includes the registration fee and the monthly compliance fees up to the selected package amount.")
  )}
					  </div>` : ""}
				${summary.iossDeregistration ? html`<div class="py-2">${priceLine(i18next.t("IOSS Deregistration"), summary.iossDeregistration)}</div>` : ""}
				${summary.ossSummary.yearlyCompliance ? html`<div class="py-2">${priceLine(i18next.t("OSS Registration and Compliance"), summary.ossSummary.yearlyCompliance)}</div>` : ""}
				${summary.ossSummary.quarterlyCompliance ? html`<div class="py-2">${priceLine(i18next.t("OSS Quarterly Compliance"), summary.ossSummary.quarterlyCompliance)}</div>` : ""}
				${summary.ossSummary.deregistration ? html`<div class="py-2">${priceLine(i18next.t("OSS Deregistration"), summary.ossSummary.deregistration)}</div>` : ""}
				${summary.changeCompanyDetail ? html`<div class="py-2">${priceLine(i18next.t("Change Company Detail"), summary.changeCompanyDetail)}</div>` : ""}
				${summary.generalVatAdvice ? html`<div class="py-2">${priceLine(i18next.t("General VAT Advice"), summary.generalVatAdvice)}</div>` : ""}
				${summary.specialistVatAdvice ? html`<div class="py-2">${priceLine(i18next.t("Specialist VAT Advice"), summary.specialistVatAdvice)}</div>` : ""}
				${summary.communicatingWithTaxAuthorities ? html`<div class="py-2">${priceLine(i18next.t("Communicating with Tax Authorities"), summary.communicatingWithTaxAuthorities)}</div>` : ""}
				<hr />
				<div class="py-4 text-gray-600">
					<div class="flex justify-between">
						<div>${i18next.t("Sub-total")}</div>
						<div>&euro; ${summary.subTotal}</div>
					</div>
					<div class="flex justify-between">
						<div>${i18next.t("VAT")}</div>
						<div>&euro; ${summary.vat}</div>
					</div>
				</div>
				<hr />
				<div class="flex justify-between font-bold py-4">
					<div>${i18next.t("Total fee")}</div>
					<div>&euro; ${summary.total}</div>
				</div>
			</div>
		</div>
	`;
};
