var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { ErrorMessage } from "./ErrorMessage";
export class Error {
  constructor(_data) {
    /**
     */
    __publicField(this, "Code");
    /**
     */
    __publicField(this, "ErrorMessages");
    /**
     */
    __publicField(this, "Message");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Error
     */
    __publicField(this, "$type", "V1.Common.Error.Error");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Code = _data["Code"];
      if (_data["ErrorMessages"] && _data["ErrorMessages"].constructor === Array) {
        this.ErrorMessages = [];
        for (let item of _data["ErrorMessages"]) {
          const errorMessages_ = new ErrorMessage();
          this.ErrorMessages.push(errorMessages_.init(item));
        }
      }
      this.Message = _data["Message"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Code"] = this.Code;
    if (this.ErrorMessages && this.ErrorMessages.constructor === Array) {
      _data["ErrorMessages"] = [];
      for (let item of this.ErrorMessages) {
        _data["ErrorMessages"].push(item.toJSON());
      }
    }
    _data["Message"] = this.Message;
    return _data;
  }
}
