var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { FileFactory } from "./Files/FileFactory";
import { CompanyConfiguration } from "./Configuration/CompanyConfiguration";
export class ComplianceUpload {
  constructor(_data) {
    /**
     */
    __publicField(this, "AvailableTemplates");
    /**
     */
    __publicField(this, "ClientId");
    /**
     */
    __publicField(this, "ClientName");
    /**
     */
    __publicField(this, "CompanyConfigSnapshot");
    /**
     */
    __publicField(this, "CountryCode");
    /**
     */
    __publicField(this, "CurrentFileVersion");
    /**
     */
    __publicField(this, "ErrorMessage");
    /**
     */
    __publicField(this, "ErrorReason");
    /**
     */
    __publicField(this, "Files");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "IgnoreOverdue");
    /**
     */
    __publicField(this, "IsAmazonInvoicingServiceOptional");
    /**
     */
    __publicField(this, "IsOverdue");
    /**
     */
    __publicField(this, "Period");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "Type");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ComplianceUpload
     */
    __publicField(this, "$type", "V1.ComplianceUploads.ComplianceUpload");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["AvailableTemplates"] && _data["AvailableTemplates"].constructor === Array) {
        this.AvailableTemplates = [];
        for (let item of _data["AvailableTemplates"]) {
          this.AvailableTemplates.push(ComplianceUploadAvailableTemplates[item]);
        }
      }
      this.ClientId = _data["ClientId"];
      this.ClientName = _data["ClientName"];
      if (_data["CompanyConfigSnapshot"]) {
        const companyConfigSnapshot_ = new CompanyConfiguration();
        this.CompanyConfigSnapshot = companyConfigSnapshot_.init(_data["CompanyConfigSnapshot"]);
      }
      this.CountryCode = _data["CountryCode"];
      this.CurrentFileVersion = _data["CurrentFileVersion"];
      this.ErrorMessage = _data["ErrorMessage"];
      this.ErrorReason = ComplianceUploadErrorReason[_data["ErrorReason"]];
      if (_data["Files"] && _data["Files"].constructor === Array) {
        this.Files = [];
        for (let item of _data["Files"]) {
          const $typeFiles = item && item.$type;
          if ($typeFiles) {
            const files_ = FileFactory.create($typeFiles);
            this.Files.push(files_.init(item));
          }
        }
      }
      this.Id = _data["Id"];
      this.IgnoreOverdue = _data["IgnoreOverdue"];
      this.IsAmazonInvoicingServiceOptional = _data["IsAmazonInvoicingServiceOptional"];
      this.IsOverdue = _data["IsOverdue"];
      this.Period = _data["Period"];
      this.Status = ComplianceUploadStatus[_data["Status"]];
      this.Type = ComplianceUploadType[_data["Type"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.AvailableTemplates && this.AvailableTemplates.constructor === Array) {
      _data["AvailableTemplates"] = [];
      for (let item of this.AvailableTemplates) {
        _data["AvailableTemplates"].push(ComplianceUploadAvailableTemplates[item]);
      }
    }
    _data["ClientId"] = this.ClientId;
    _data["ClientName"] = this.ClientName;
    _data["CompanyConfigSnapshot"] = this.CompanyConfigSnapshot ? this.CompanyConfigSnapshot.toJSON() : void 0;
    _data["CountryCode"] = this.CountryCode;
    _data["CurrentFileVersion"] = this.CurrentFileVersion;
    _data["ErrorMessage"] = this.ErrorMessage;
    _data["ErrorReason"] = ComplianceUploadErrorReason[this.ErrorReason];
    if (this.Files && this.Files.constructor === Array) {
      _data["Files"] = [];
      for (let item of this.Files) {
        _data["Files"].push(item.toJSON());
      }
    }
    _data["Id"] = this.Id;
    _data["IgnoreOverdue"] = this.IgnoreOverdue;
    _data["IsAmazonInvoicingServiceOptional"] = this.IsAmazonInvoicingServiceOptional;
    _data["IsOverdue"] = this.IsOverdue;
    _data["Period"] = this.Period;
    _data["Status"] = ComplianceUploadStatus[this.Status];
    _data["Type"] = ComplianceUploadType[this.Type];
    return _data;
  }
}
export var ComplianceUploadAvailableTemplates = /* @__PURE__ */ ((ComplianceUploadAvailableTemplates2) => {
  ComplianceUploadAvailableTemplates2["Amazon"] = "Amazon";
  ComplianceUploadAvailableTemplates2["Mp"] = "Mp";
  ComplianceUploadAvailableTemplates2["CustomTemplate1"] = "CustomTemplate1";
  ComplianceUploadAvailableTemplates2["CustomTemplate2"] = "CustomTemplate2";
  ComplianceUploadAvailableTemplates2["Csv"] = "Csv";
  ComplianceUploadAvailableTemplates2["Dsv"] = "Dsv";
  ComplianceUploadAvailableTemplates2["Excel"] = "Excel";
  ComplianceUploadAvailableTemplates2["SAPText"] = "SAPText";
  return ComplianceUploadAvailableTemplates2;
})(ComplianceUploadAvailableTemplates || {});
export var ComplianceUploadErrorReason = /* @__PURE__ */ ((ComplianceUploadErrorReason2) => {
  ComplianceUploadErrorReason2["UnknownError"] = "UnknownError";
  ComplianceUploadErrorReason2["UnableToSentToReturnGeneration"] = "UnableToSentToReturnGeneration";
  ComplianceUploadErrorReason2["MissingVatNumberValidation"] = "MissingVatNumberValidation";
  ComplianceUploadErrorReason2["ExternalValidationServiceUnavailable"] = "ExternalValidationServiceUnavailable";
  ComplianceUploadErrorReason2["ExternalValidationServiceUnknownError"] = "ExternalValidationServiceUnknownError";
  ComplianceUploadErrorReason2["ExternalValidationServiceViesUnavailable"] = "ExternalValidationServiceViesUnavailable";
  ComplianceUploadErrorReason2["ExternalValidationServiceCountryUnavailable"] = "ExternalValidationServiceCountryUnavailable";
  ComplianceUploadErrorReason2["MissingVatRate"] = "MissingVatRate";
  ComplianceUploadErrorReason2["ExternalValidationServiceConcurrentLimitReached"] = "ExternalValidationServiceConcurrentLimitReached";
  ComplianceUploadErrorReason2["ExternalValidationServiceTimeout"] = "ExternalValidationServiceTimeout";
  ComplianceUploadErrorReason2["ExternalValidationServiceInvalidInput"] = "ExternalValidationServiceInvalidInput";
  return ComplianceUploadErrorReason2;
})(ComplianceUploadErrorReason || {});
export var ComplianceUploadStatus = /* @__PURE__ */ ((ComplianceUploadStatus2) => {
  ComplianceUploadStatus2["New"] = "New";
  ComplianceUploadStatus2["WaitingForFinalize"] = "WaitingForFinalize";
  ComplianceUploadStatus2["InvalidData"] = "InvalidData";
  ComplianceUploadStatus2["SaftCompliance"] = "SaftCompliance";
  ComplianceUploadStatus2["MasterDataSetup"] = "MasterDataSetup";
  ComplianceUploadStatus2["DataUploadFinalized"] = "DataUploadFinalized";
  ComplianceUploadStatus2["DataVatGenerated"] = "DataVatGenerated";
  ComplianceUploadStatus2["SentToReturnGeneration"] = "SentToReturnGeneration";
  ComplianceUploadStatus2["InsufficientCredit"] = "InsufficientCredit";
  ComplianceUploadStatus2["UploadProcessed"] = "UploadProcessed";
  ComplianceUploadStatus2["Error"] = "Error";
  return ComplianceUploadStatus2;
})(ComplianceUploadStatus || {});
export var ComplianceUploadType = /* @__PURE__ */ ((ComplianceUploadType2) => {
  ComplianceUploadType2["IOSS"] = "IOSS";
  ComplianceUploadType2["VAT"] = "VAT";
  return ComplianceUploadType2;
})(ComplianceUploadType || {});
