import { CustomSourceConfiguration } from "./CustomSourceConfiguration";
import { OneCustomSourceConfiguration } from "./OneCustomSourceConfiguration";
import { SeparateCustomSourceConfiguration } from "./SeparateCustomSourceConfiguration";
export class CustomSourceConfigurationFactory {
  static create($type) {
    if ($type === "V1.GenerationConfiguration.CustomSourceConfiguration") {
      const result = new CustomSourceConfiguration();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.OneCustomSourceConfiguration") {
      const result = new OneCustomSourceConfiguration();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.SeparateCustomSourceConfiguration") {
      const result = new SeparateCustomSourceConfiguration();
      return result;
    }
    throw new Error($type + " not found");
  }
}
