var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { CustomSourceMapping } from "./CustomSourceMapping";
export class UpdateCustomSourceMapping {
  constructor(_data) {
    /**
     */
    __publicField(this, "Mapping");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof UpdateCustomSourceMapping
     */
    __publicField(this, "$type", "V1.GenerationConfiguration.UpdateCustomSourceMapping");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Mapping"]) {
        const mapping_ = new CustomSourceMapping();
        this.Mapping = mapping_.init(_data["Mapping"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["$type"] = this.$type;
    _data["Mapping"] = this.Mapping ? this.Mapping.toJSON() : void 0;
    return _data;
  }
}
