export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NO_SPECIAL_CHAR_REGEX = /^[^*_$()=@/%!+"'§`.:;,\?<>~\|{}&#]*$/;
export const NO_SPECIAL_CHAR_AND_NUMBERS_REGEX = /^[^0-9*_$()=@/%!+"'§`.:;,\?<>~\|{}&#]*$/;
export const DATE_REGEX = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
export const SEATING_REGEX = /^([1-9]|[1-4][0-9]|50)[A-Fa-f]$/;
export const DECIMAL_PRICE_REGEX = /^\d+(\.\d{1,2})?$/;
export const NON_DECIMAL_PRICE_REGEX = /^\d+$/;
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEAFULT_MIN_DATE = /* @__PURE__ */ new Date("1900-01-01");
export const COUNTRY_CODE_LENGTH = 2;
export const EXCEL_SEPARATOR_COMMAND = "SEP=,\n";
export const ALPHANUMERIC_REGEX = /^[0-9a-zA-Z]+$/;
export const DIGITS = /\d/;
export const LOWERCASE_CHARACTER = /[a-z]/;
export const UPPERCASE_CHARACTER = /[A-Z]/;
export const SPECIAL_CHARACTER = /[-+_!@#$%^&*.,?]/;
export const ONLY_DIGIT_REGEX = /^[0-9]*$/;
export const PRIMARY_COLOR = "#506363";
export const SECONDARY_COLOR = "#6ce3b6";
export const TERTIARY_COLOR = "#6DAEDB";
export const INACTIVE_COLOR = "#578baf";
export const ACTIVE_COLOR = "#6DAEDB";
export const LOGO_URL = "/images/logo.png";
export const SIGNATURE_URL = "/images/signature.png";
