var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { AdditionalInfo } from "./AdditionalInfo";
export class HungarianAdditionalInfo extends AdditionalInfo {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "AuthCertReference");
    /**
     */
    __publicField(this, "EncryptionCertReference");
    /**
     */
    __publicField(this, "OnBehalfOf");
    /**
     */
    __publicField(this, "SignatureCertReference");
    /**
     */
    __publicField(this, "UserId");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof HungarianAdditionalInfo
     */
    __publicField(this, "$type", "V1.Cesop.Psps.Extensions.HungarianAdditionalInfo");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.AuthCertReference = _data["AuthCertReference"];
      this.EncryptionCertReference = _data["EncryptionCertReference"];
      this.OnBehalfOf = _data["OnBehalfOf"];
      this.SignatureCertReference = _data["SignatureCertReference"];
      this.UserId = _data["UserId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["AuthCertReference"] = this.AuthCertReference;
    _data["EncryptionCertReference"] = this.EncryptionCertReference;
    _data["OnBehalfOf"] = this.OnBehalfOf;
    _data["SignatureCertReference"] = this.SignatureCertReference;
    _data["UserId"] = this.UserId;
    return _data;
  }
}
