import { html } from "lit-html";
import { circleCheckedIcon, triangleExclamationIcon } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
export const observedAttributes = ["q11e", "worldCountries", "birthCountries", "invalidAccordions"];
export const useShadowDOM = false;
export const name = "ww-q11e-personnel-details";
export const Component = (host) => {
  var _a, _b, _c, _d;
  const props = {
    q11e: (_a = host.q11e) != null ? _a : void 0,
    worldCountries: (_b = host.worldCountries) != null ? _b : [],
    birthCountries: (_c = host.birthCountries) != null ? _c : [],
    invalidAccordions: host.invalidAccordions,
    onAnswerChanged: (_d = host.onAnswerChanged) != null ? _d : void 0
  };
  const handleAccordionClick = (e, groupName) => {
    e.preventDefault();
    e.stopPropagation();
    if (openAccordionGroupNames.includes(groupName)) {
      setOpenAccordionGroupNames(openAccordionGroupNames.filter((openGroupName) => openGroupName !== groupName));
      return;
    }
    setOpenAccordionGroupNames([...openAccordionGroupNames, groupName]);
  };
  const getAccordionIcon = (groupName) => {
    var _a2;
    return ((_a2 = props.invalidAccordions) == null ? void 0 : _a2.includes(groupName)) ? triangleExclamationIcon(24, 24, "fill-orange-400") : circleCheckedIcon();
  };
  const [openAccordionGroupNames, setOpenAccordionGroupNames] = useState([]);
  useEffect(() => {
    if (props.invalidAccordions !== void 0) {
      setOpenAccordionGroupNames([...openAccordionGroupNames, ...props.invalidAccordions]);
    }
  }, [props.invalidAccordions]);
  const accordionHeaderTitleTemplate = (title, groupName) => html`
		<div class="flex gap-4 items-center"><span class="text-lg font-bold">${title}</span><span>${props.invalidAccordions !== void 0 ? getAccordionIcon(groupName) : ""}</span></div>
	`;
  const accordionsTemplate = () => {
    var _a2, _b2, _c2;
    const accordions = [
      {
        title: accordionHeaderTitleTemplate(i18next.t("Business Owners"), "business_owners"),
        visible: true,
        groupName: "business_owners",
        isValid: !((_a2 = props.invalidAccordions) == null ? void 0 : _a2.includes("business_owners")),
        content: () => html`<ww-answer-business-owners .q11e=${props.q11e} .questionId=${"business_owner"} @change=${props.onAnswerChanged}> </ww-answer-business-owners>`
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("Authorized signatory"), "authorized_signatory"),
        visible: true,
        groupName: "authorized_signatory",
        isValid: !((_b2 = props.invalidAccordions) == null ? void 0 : _b2.includes("authorized_signatory")),
        content: () => html`
					<ww-answer-legal-representatives
						.q11e=${props.q11e}
						.worldCountries=${props.worldCountries}
						.birthCountries=${props.birthCountries}
						.questionId=${"legal_rep"}
						@change=${props.onAnswerChanged}
					></ww-answer-legal-representatives>
				`
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("Beneficial Owner"), "beneficial_owner"),
        visible: true,
        groupName: "beneficial_owner",
        isValid: !((_c2 = props.invalidAccordions) == null ? void 0 : _c2.includes("beneficial_owner")),
        content: () => html`
					<ww-answer-beneficial-owners
						.q11e=${props.q11e}
						.questionId=${"beneficial_owner"}
						.worldCountries=${props.worldCountries}
						@change=${props.onAnswerChanged}
					></ww-answer-beneficial-owners>
				`
      }
    ];
    return accordions.map((accordion) => {
      return accordion.visible ? html`
						<dc-accordion
							.contentTemplate=${accordion.content}
							.isOpen=${openAccordionGroupNames.includes(accordion.groupName)}
							.headerTitle=${accordion.title}
							.onClick=${(e) => handleAccordionClick(e, accordion.groupName)}
						></dc-accordion>
				  ` : "";
    });
  };
  return html` <div class="mt-8 flex flex-col gap-8">${accordionsTemplate()}</div> `;
};
