var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import {
  hook,
  Hook,
  useEffect as hauntedUseEffect,
  useState as hauntedUseState,
  useRef as hauntedUseRef,
  useMemo as hauntedUseMemo,
  useCallback as hauntedUseCallback,
  useReducer as hauntedUseReducer
} from "haunted";
import { formatTimestampUTC, parseTimestampUTC } from "GlobalShared/helpers/dateHelper";
import { ClientManager } from "GlobalShared/ClientManager";
import { ReduxContext } from "GlobalShared/ReduxContext";
import { getParameter, updateParameter } from "GlobalShared/router";
import { DCValidationResult } from "./HooksHelpers";
import { isCollection } from "immutable";
import { decodeGridState, encodeGridState } from "GlobalShared/helpers/urlHelper";
import { toBoolean } from "GlobalShared/helpers/booleanHelper";
class ClientManagerHook extends Hook {
  constructor(id, state, ctor) {
    super(id, state);
    __publicField(this, "abortController", new AbortController());
    __publicField(this, "client");
    this.client = new ClientManager(this.abortController.signal).createSelfClient(ctor);
  }
  update() {
    return this.client;
  }
  teardown() {
    this.abortController.abort();
  }
}
class QueryStateManagerHook extends Hook {
  constructor(id, state, key, initialValue) {
    super(id, state);
    __publicField(this, "args");
    __publicField(this, "key");
    this.updater = this.updater.bind(this);
    this.key = key;
    const parameter = getParameter(key);
    if (parameter !== void 0) {
      if (initialValue instanceof Date) {
        this.makeArgs(parseTimestampUTC(parameter).toDate());
      } else if (typeof initialValue === "number") {
        this.makeArgs(parseInt(parameter, 10));
      } else if (typeof initialValue === "string") {
        this.makeArgs(parameter);
      } else if (typeof initialValue === "boolean") {
        this.makeArgs(toBoolean(parameter));
      } else {
        this.makeArgs(parameter);
      }
    } else {
      this.makeArgs(initialValue);
    }
  }
  update() {
    return this.args;
  }
  makeArgs(value) {
    this.args = Object.freeze([value, this.updater.bind(this)]);
  }
  updater(value) {
    if (value instanceof Date) {
      updateParameter(this.key, formatTimestampUTC(value));
    } else if (typeof value === "number") {
      updateParameter(this.key, value.toString());
    } else if (typeof value === "string") {
      updateParameter(this.key, value);
    } else if (typeof value === "boolean") {
      updateParameter(this.key, value.toString());
    }
    this.makeArgs(value);
    this.state.update();
  }
}
export const useRef = hauntedUseRef;
export const useState = hauntedUseState;
export const useCallback = hauntedUseCallback;
export const useMemo = hauntedUseMemo;
export const useReducer = hauntedUseReducer;
export const useClient = hook(ClientManagerHook);
export const useQueryState = hook(QueryStateManagerHook);
export const useEffect = (callback, values) => {
  hauntedUseEffect(() => {
    window.setTimeout(() => {
      const result = callback();
      if (result instanceof Promise) {
        result.catch((reason) => __async(void 0, null, function* () {
          console.log(reason);
        }));
      }
    }, 0);
  }, values);
};
export const useReduxState = (path) => {
  const [data, setData] = useState(void 0);
  useEffect(() => {
    const items = path.split(".");
    const currentVal = ReduxContext.store.getState().getIn(items);
    setData(isCollection(currentVal) ? currentVal.toJS() : currentVal);
    let savedData = currentVal;
    return ReduxContext.store.subscribe(() => {
      const newData = ReduxContext.store.getState().getIn(items);
      if (newData !== savedData) {
        savedData = newData;
        const currData = isCollection(newData) ? newData.toJS() : newData;
        setData(currData);
      }
    });
  }, [path]);
  return data;
};
export const useValidationContext = (validator, dispatcher) => {
  const [validationResult, setValidationResult] = useState(new DCValidationResult());
  const [fieldsExplicitlyValidated, setFieldsExplicitlyValidated] = useState({});
  const [newFieldsToAddAtValidation, setNewFieldsToAddAtValidation] = useState([]);
  const [dispatchIfValid, setDispatchIfValid] = useState(false);
  useEffect(() => __async(void 0, null, function* () {
    if (dispatchIfValid) {
      setDispatchIfValid(false);
      if (validationResult.isValid() && dispatcher) {
        dispatcher();
      }
    }
  }), [validationResult]);
  const addValidationFieldsAtValidation = (fieldNames) => {
    setNewFieldsToAddAtValidation(fieldNames);
  };
  const addValidationFields = (otherNewFieldsToBeValidated) => {
    if ((newFieldsToAddAtValidation == null ? void 0 : newFieldsToAddAtValidation.length) > 0 || (otherNewFieldsToBeValidated == null ? void 0 : otherNewFieldsToBeValidated.length) > 0) {
      const newValidationFields = __spreadValues({}, fieldsExplicitlyValidated);
      otherNewFieldsToBeValidated == null ? void 0 : otherNewFieldsToBeValidated.forEach((fieldName) => {
        newValidationFields[fieldName] = true;
      });
      newFieldsToAddAtValidation == null ? void 0 : newFieldsToAddAtValidation.forEach((fieldName) => {
        newValidationFields[fieldName] = true;
      });
      setFieldsExplicitlyValidated(newValidationFields);
      setNewFieldsToAddAtValidation([]);
    }
  };
  const validate = (viewModel, newFieldsToBeValidated) => {
    addValidationFields(newFieldsToBeValidated);
    const validationResult2 = new DCValidationResult(validator.validate(viewModel));
    setValidationResult(validationResult2);
    return validationResult2;
  };
  return {
    validate,
    validateAndDispatch: (viewModel, newFieldsToBeValidated) => {
      setDispatchIfValid(true);
      return validate(viewModel, newFieldsToBeValidated);
    },
    validateField: (fieldName) => {
      addValidationFieldsAtValidation([fieldName]);
    },
    getValidationMessage: (fieldName) => {
      const msg = fieldsExplicitlyValidated[fieldName] ? validationResult.getValidationMessage(fieldName) : "";
      return msg;
    },
    validateFields: addValidationFieldsAtValidation,
    clearFieldValidations: () => {
      setFieldsExplicitlyValidated({});
    },
    validationResult
  };
};
export const useLoadingReducer = () => useReducer(
  (state, action) => {
    if (action === "inc") {
      return { count: state.count + 1 };
    } else if (action === "dec") {
      return { count: state.count - 1 };
    } else {
      return state;
    }
  },
  { count: 0 }
);
export const useQueryGridState = (defaultGridState, queryParam = "gs") => {
  var _a;
  const [gridState, setGridState] = useState((_a = decodeGridState(getParameter(queryParam))) != null ? _a : defaultGridState);
  useEffect(() => {
    updateParameter(queryParam, encodeGridState(gridState));
  }, [gridState]);
  return [gridState, setGridState];
};
export const useId = () => {
  return useMemo(() => {
    return window.crypto.randomUUID();
  }, []);
};
