var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { authenticatedFetch } from "./Authentication";
export class ClientManager {
  constructor(abortSignal) {
    __publicField(this, "abortSignal");
    this.abortSignal = abortSignal;
  }
  createSelfClient(ctor) {
    return new ctor(window.location.origin, {
      fetch: authenticatedFetch(this.abortSignal)
    });
  }
}
