var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
export class Company {
  constructor(_data) {
    /**
     */
    __publicField(this, "AccountManagerEmail");
    /**
     */
    __publicField(this, "AMLReceivedAt");
    /**
     */
    __publicField(this, "ClientAcceptanceValidTo");
    /**
     */
    __publicField(this, "ClientSource");
    /**
     */
    __publicField(this, "CompanyName");
    /**
     */
    __publicField(this, "CoordinatorEmail");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "IsSignedUp");
    /**
     */
    __publicField(this, "Notes");
    /**
     */
    __publicField(this, "PreparerEmail");
    /**
     */
    __publicField(this, "QuestionnaireStatus");
    /**
     */
    __publicField(this, "RegistrationDate");
    /**
     */
    __publicField(this, "ReviewerEmail");
    /**
     */
    __publicField(this, "Status");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Company
     */
    __publicField(this, "$type", "V1.Company.Search.Company");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.AccountManagerEmail = _data["AccountManagerEmail"];
      this.AMLReceivedAt = DateConverter.from(_data["AMLReceivedAt"]);
      this.ClientAcceptanceValidTo = DateConverter.from(_data["ClientAcceptanceValidTo"]);
      this.ClientSource = CompanyClientSource[_data["ClientSource"]];
      this.CompanyName = _data["CompanyName"];
      this.CoordinatorEmail = _data["CoordinatorEmail"];
      this.Id = _data["Id"];
      this.IsSignedUp = _data["IsSignedUp"];
      this.Notes = _data["Notes"];
      this.PreparerEmail = _data["PreparerEmail"];
      this.QuestionnaireStatus = CompanyQuestionnaireStatus[_data["QuestionnaireStatus"]];
      this.RegistrationDate = DateConverter.from(_data["RegistrationDate"]);
      this.ReviewerEmail = _data["ReviewerEmail"];
      this.Status = CompanyStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["AccountManagerEmail"] = this.AccountManagerEmail;
    _data["AMLReceivedAt"] = DateConverter.toUtcDateString(this.AMLReceivedAt);
    _data["ClientAcceptanceValidTo"] = DateConverter.toUtcDateString(this.ClientAcceptanceValidTo);
    _data["ClientSource"] = CompanyClientSource[this.ClientSource];
    _data["CompanyName"] = this.CompanyName;
    _data["CoordinatorEmail"] = this.CoordinatorEmail;
    _data["Id"] = this.Id;
    _data["IsSignedUp"] = this.IsSignedUp;
    _data["Notes"] = this.Notes;
    _data["PreparerEmail"] = this.PreparerEmail;
    _data["QuestionnaireStatus"] = CompanyQuestionnaireStatus[this.QuestionnaireStatus];
    _data["RegistrationDate"] = DateConverter.toIsoUtcString(this.RegistrationDate);
    _data["ReviewerEmail"] = this.ReviewerEmail;
    _data["Status"] = CompanyStatus[this.Status];
    return _data;
  }
}
export var CompanyClientSource = /* @__PURE__ */ ((CompanyClientSource2) => {
  CompanyClientSource2["TaxDesk"] = "TaxDesk";
  CompanyClientSource2["Geopost"] = "Geopost";
  CompanyClientSource2["Partner"] = "Partner";
  CompanyClientSource2["Hellotax"] = "Hellotax";
  return CompanyClientSource2;
})(CompanyClientSource || {});
export var CompanyQuestionnaireStatus = /* @__PURE__ */ ((CompanyQuestionnaireStatus2) => {
  CompanyQuestionnaireStatus2["Incomplete"] = "Incomplete";
  CompanyQuestionnaireStatus2["Completed"] = "Completed";
  CompanyQuestionnaireStatus2["Approved"] = "Approved";
  CompanyQuestionnaireStatus2["DataVerified"] = "DataVerified";
  return CompanyQuestionnaireStatus2;
})(CompanyQuestionnaireStatus || {});
export var CompanyStatus = /* @__PURE__ */ ((CompanyStatus2) => {
  CompanyStatus2["Active"] = "Active";
  CompanyStatus2["Rejected"] = "Rejected";
  CompanyStatus2["Inactive"] = "Inactive";
  return CompanyStatus2;
})(CompanyStatus || {});
