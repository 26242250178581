var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DataProcessingJobFactory } from "./../DataProcessingJobFactory";
export class SearchResult {
  constructor(_data) {
    /**
     */
    __publicField(this, "DataProcessingJobs");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SearchResult
     */
    __publicField(this, "$type", "V1.DataProcessingJobs.Search.SearchResult");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["DataProcessingJobs"] && _data["DataProcessingJobs"].constructor === Array) {
        this.DataProcessingJobs = [];
        for (let item of _data["DataProcessingJobs"]) {
          const $typeDataProcessingJobs = item && item.$type;
          if ($typeDataProcessingJobs) {
            const dataProcessingJobs_ = DataProcessingJobFactory.create($typeDataProcessingJobs);
            this.DataProcessingJobs.push(dataProcessingJobs_.init(item));
          }
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.DataProcessingJobs && this.DataProcessingJobs.constructor === Array) {
      _data["DataProcessingJobs"] = [];
      for (let item of this.DataProcessingJobs) {
        _data["DataProcessingJobs"].push(item.toJSON());
      }
    }
    return _data;
  }
}
