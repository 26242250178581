var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class TypeAndRate {
  constructor(_data) {
    /**
     */
    __publicField(this, "Rate");
    /**
     */
    __publicField(this, "Type");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof TypeAndRate
     */
    __publicField(this, "$type", "V1.ComplianceMasterData.TypeAndRate");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Rate = _data["Rate"];
      this.Type = TypeAndRateType[_data["Type"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Rate"] = this.Rate;
    _data["Type"] = TypeAndRateType[this.Type];
    return _data;
  }
}
export var TypeAndRateType = /* @__PURE__ */ ((TypeAndRateType2) => {
  TypeAndRateType2["Standard"] = "Standard";
  TypeAndRateType2["Standard2"] = "Standard2";
  TypeAndRateType2["Reduced"] = "Reduced";
  TypeAndRateType2["SuperReduced"] = "SuperReduced";
  TypeAndRateType2["SuperReduced2"] = "SuperReduced2";
  TypeAndRateType2["Exempt"] = "Exempt";
  TypeAndRateType2["ZeroRated"] = "ZeroRated";
  TypeAndRateType2["Middle"] = "Middle";
  TypeAndRateType2["Reduced2"] = "Reduced2";
  return TypeAndRateType2;
})(TypeAndRateType || {});
