var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
export class Subscriptions {
  /**
   * Creates an instance of Subscriptions.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Subscriptions
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SubscribeRq_ _subscribeRq
   * @returns Promise<SubscribeRq_>
   * @memberof Subscriptions
   */
  subscribe(pSubscribeRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Subscriptions", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSubscribeRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UnSubscribeRq_ _unSubscribeRq
   * @returns Promise<UnSubscribeRq_>
   * @memberof Subscriptions
   */
  unSubscribe(pUnSubscribeRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Subscriptions", "DELETE", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pUnSubscribeRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
