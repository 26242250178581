var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import i18next from "i18next";
import {
  ClientSources,
  OrderFieldNames,
  ALL_ORDER_PAYMENT_STATUSES,
  NEW_ORDER_ID,
  ALL_ORDER_STATUSES,
  PAYMENT_STATUS_LABELS,
  ORDER_STATUS_LABELS
} from "./OrderModels";
import { useEffect, useQueryGridState, useQueryState, useState } from "GlobalShared/haunted/CustomHooks";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { notifyError, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { formatDateTime } from "GlobalShared/helpers/dateHelper";
import { templateTitle } from "GlobalShared/templates/commons";
import { useOrderDetails } from "./order-details";
import { addHandler, getRouteElement, navigateTo, removeOptionals } from "GlobalShared/router";
const DEFAULT_FILTERS = [];
export const useOrders = (props) => {
  const navigate = (orderId) => {
    navigateTo(`${removeOptionals(props.isAdmin ? props.getRouteByKey("orders(/:orderId)") : props.getRouteByKey("admin/orders(/:orderId)"))}${orderId ? `/${orderId}` : ""}`);
    if (orderId === void 0) {
      loadOrders();
    }
  };
  const approveBankTransfer = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.approveBankTransferPayment({
          paymentId: paymentModel.PaymentId,
          orderId: paymentModel.OrderId,
          collected: paymentModel.Collected,
          reference: paymentModel.Reference
        });
        notifySuccess("Bank transfer payment accepted");
        setShowBankTransferModal(false);
        setPaymentModel(void 0);
        yield loadOrders();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const formatMoney = (money) => {
    return money ? `${money.Value} ${money.Currency}` : "-";
  };
  const loadOrders = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        setOrdersResult(yield props.searchOrders(gridState, showAll, showProcessingFailure));
      }),
      (error) => {
        if (error.statusCode === 400) {
          notifyWarning("Some filter values are invalid");
        } else {
          notifyError(error);
        }
      }
    );
  });
  const onExportOrdersClick = () => __async(void 0, null, function* () {
    yield props.exportOrders(gridState, showAll, showProcessingFailure);
  });
  const onSortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: OrderByValue,
      orderDir: OrderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  };
  const fixOrder = (orderId) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.fixOrder(orderId);
        yield loadOrders();
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const [gridState, setGridState] = useQueryGridState(
    {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS,
      pageSize: 20,
      orderBy: "CreatedAt",
      orderDir: "desc"
    },
    "ogs"
  );
  const [ordersResult, setOrdersResult] = useState(void 0);
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  const [paymentModel, setPaymentModel] = useState(void 0);
  const [currentPathname, setCurrentPathname] = useState(window.location.pathname);
  const [currentOrderId, setCurrentOrderId] = useState(void 0);
  const orderDetails = useOrderDetails({
    isAdmin: props.isAdmin,
    permissionManager: props.permissionManager,
    orderId: currentOrderId,
    serviceCountries: props.serviceCountries,
    euCountries: props.euCountries,
    worldCountries: props.worldCountries,
    englishWorldCountries: props.englishWorldCountries,
    navigate,
    reValidateCompanyVatNumber: props.reValidateCompanyVatNumber,
    getCompanyServices: props.getCompanyServices,
    getAvailableServices: props.getAvailableServices,
    searchClients: props.searchClients,
    createOrder: props.createOrder,
    getOrder: props.getOrder,
    getPaymentInfo: props.getPaymentInfo,
    payAndConfirm: props.payAndConfirm,
    uploadContract: props.uploadContract,
    downloadContract: props.downloadContract,
    downloadBankTransferPaymentProof: props.downloadBankTransferPaymentProof,
    uploadBankTransferPaymentProof: props.uploadBankTransferPaymentProof,
    approveBankTransferPayment: props.approveBankTransferPayment,
    approveDraftOrder: props.approveDraftOrder,
    rejectDraftOrder: props.rejectDraftOrder,
    cancelOrder: props.cancelOrder,
    updateOrder: props.updateOrder,
    downloadBillingDocument: props.downloadBillingDocument,
    loadOrders,
    checkVatNumber: props.checkVatNumber
  });
  const [showAll, setShowAll] = useQueryState("showAll", false);
  const [showProcessingFailure, setShowProcessingFailure] = useQueryState("showProcessingFailure", false);
  const init = () => {
    return addHandler("orders", (location) => {
      setCurrentPathname(location.pathname);
    });
  };
  useEffect(init, []);
  useEffect(() => __async(void 0, null, function* () {
    setCurrentOrderId(getRouteElement("orderId"));
  }), [currentPathname]);
  useEffect(() => __async(void 0, null, function* () {
    loadOrders();
  }), [gridState, showAll, showProcessingFailure]);
  const templateOrdersGrid = () => {
    var _a;
    const vmOrder = {
      columns: (props.isAdmin ? [
        {
          field: OrderFieldNames.CompanyId,
          label: "ID",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: OrderFieldNames.CompanyName,
          label: "Client Name",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: OrderFieldNames.ClientSource,
          label: "Source",
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            dataSource: ClientSources
          },
          columnType: "enum"
        }
      ] : []).concat([
        {
          field: OrderFieldNames.CreatedAt,
          label: i18next.t("Created Date"),
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "date" },
          columnType: "date"
        },
        {
          field: OrderFieldNames.OrderAmount,
          label: i18next.t("Amount"),
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "number" },
          columnType: "number"
        },
        {
          field: OrderFieldNames.ContractId,
          label: i18next.t("Contract Id"),
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: OrderFieldNames.Status,
          label: i18next.t("Status"),
          filterable: true,
          sortable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            dataSource: ALL_ORDER_STATUSES
          },
          columnType: "enum"
        },
        {
          field: OrderFieldNames.OrderPaymentStatus,
          label: i18next.t("Payment Status"),
          filterable: true,
          sortable: true,
          filterDescriptor: {
            type: "select",
            multiSelect: true,
            dataSource: ALL_ORDER_PAYMENT_STATUSES
          },
          columnType: "enum"
        },
        {
          field: OrderFieldNames.Actions,
          label: ""
        }
      ]),
      data: ordersResult.orders,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      cellTemplate: (index, field) => {
        var _a2;
        const item = ordersResult.orders[index];
        if (field === OrderFieldNames.CompanyId) {
          return (_a2 = item.CompanyId) == null ? void 0 : _a2.toString();
        } else if (field === OrderFieldNames.CompanyName) {
          return item.CompanyName;
        } else if (field === OrderFieldNames.ClientSource) {
          return item.ClientSource;
        } else if (field === OrderFieldNames.CreatedAt) {
          return formatDateTime(item.CreatedAt);
        } else if (field === OrderFieldNames.OrderAmount) {
          return formatMoney(item.Total);
        } else if (field === OrderFieldNames.ContractId) {
          return item.ContractId;
        } else if (field === OrderFieldNames.Status) {
          return ORDER_STATUS_LABELS[item.Status];
        } else if (field === OrderFieldNames.OrderPaymentStatus) {
          return PAYMENT_STATUS_LABELS[item.PaymentStatus];
        } else if (field === OrderFieldNames.Actions) {
          return html`<div class="flex space-x-2">
						<!-- ${item.HasContractReference ? html`<button
									class="btn btn-sm btn-primary"
									@click=${() => __async(void 0, null, function* () {
            yield props.downloadContract(item.Id, item.CompanyId);
          })}
							  >
									${i18next.t("Download")}
							  </button>` : ""} -->
						<div>
							<button
								class="btn btn-sm btn-primary"
								@click=${() => __async(void 0, null, function* () {
            navigate(item.Id);
          })}
							>
								${i18next.t("Details")}
							</button>
						</div>
						${props.isAdmin && props.permissionManager.hasPermission("Orders_and_Payments_Modify") && item.HasProcessingFailure ? html`<div>
									<button class="btn btn-sm btn-primary" @click=${() => __async(void 0, null, function* () {
            return fixOrder(item.Id);
          })}>${"Fix"}</button>
							  </div>` : ""}
					</div>`;
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: ordersResult.total
      }
    };
    return html`
			<div>
				<div>${templateTitle(i18next.t("Orders & Payments"))}</div>
				<div class="flex space-x-4 my-4 items-center">
					${props.isAdmin && props.permissionManager.hasPermission("Orders_and_Payments_Create") ? html`<button class="btn btn-primary" @click=${() => navigate(NEW_ORDER_ID)}>Manage Services</button>` : ""}
					${props.isAdmin && props.permissionManager.hasPermission("Orders_and_Payments_Read") ? html`<button class="btn btn-primary" @click=${() => onExportOrdersClick()}>Export</button>` : ""}
					<div class="flex justify-between space-x-2">
						<div>
							<dc-checkbox2
								.checked=${showAll}
								.label=${i18next.t("Show All")}
								@change=${(e) => {
      setShowAll(e.detail.checked);
    }}
							></dc-checkbox2>
						</div>
						<div>
							${props.isAdmin && props.permissionManager.hasPermission("Orders_and_Payments_Modify") ? html`<dc-checkbox2
										.checked=${showProcessingFailure}
										.label=${"Show Processing Failures"}
										@change=${(e) => {
      setShowProcessingFailure(e.detail.checked);
    }}
								  ></dc-checkbox2>` : ""}
						</div>
					</div>
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 || showProcessingFailure ? html`<button
								class="btn btn-primary"
								@click=${() => {
      setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: DEFAULT_FILTERS }));
      setShowProcessingFailure(false);
    }}
						  >
								Reset filters
						  </button>` : ""}
				</div>
			</div>

			<dc-table .vm=${vmOrder} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
		`;
  };
  const approveBankTransferPaymentModal = () => {
    var _a, _b;
    return html`
			<div>
				<dc-modal
					.visible=${showBankTransferModal}
					@close=${() => setShowBankTransferModal(false)}
					.header=${"Approve Bank Transfer Payment"}
					.content=${html`<div>
						<div class="grid lg:grid-cols-3 gap-4 mb-4">
							<dc-input
								.label=${"Amount"}
								.value=${(_a = paymentModel == null ? void 0 : paymentModel.Collected) == null ? void 0 : _a.Value}
								@change=${(e) => setPaymentModel(__spreadProps(__spreadValues({}, paymentModel), {
      Collected: {
        Currency: paymentModel.Collected.Currency,
        Value: Number(e.detail.value)
      }
    }))}
							></dc-input>
							<dc-input .label=${"Currency"} .readonly=${true} .value=${(_b = paymentModel == null ? void 0 : paymentModel.Collected) == null ? void 0 : _b.Currency}></dc-input>
							<dc-input
								.label=${"Reference"}
								.value=${paymentModel == null ? void 0 : paymentModel.Reference}
								@change=${(e) => setPaymentModel(__spreadProps(__spreadValues({}, paymentModel), { Reference: e.detail.value }))}
							></dc-input>
						</div>
						<div>
							<button class="btn btn-primary ml-4" @click=${() => setShowBankTransferModal(false)}>Cancel</button
							><button class="btn btn-primary ml-4" @click=${() => __async(void 0, null, function* () {
      return approveBankTransfer();
    })}>Approve</button>
						</div>
					</div>`}
				>
				</dc-modal>
			</div>
		`;
  };
  const mainTemplate = html`<div class="my-2 mb-16 mt-8">
		${currentOrderId === void 0 ? html`${ordersResult ? html`${templateOrdersGrid()} ${approveBankTransferPaymentModal()}` : `${i18next.t("Loading")}...`}` : html`${orderDetails.mainTemplate()}`}
	</div>`;
  return { mainTemplate };
};
