var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../converters/DateConverter";
export class Term {
  constructor(_data) {
    /**
     */
    __publicField(this, "DateTimeValue");
    /**
     */
    __publicField(this, "DSFieldType");
    /**
     */
    __publicField(this, "Not");
    /**
     */
    __publicField(this, "NumberValue");
    /**
     */
    __publicField(this, "Path");
    /**
     */
    __publicField(this, "StringValue");
    /**
     */
    __publicField(this, "TermOperator");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Term
     */
    __publicField(this, "$type", "V1.TemplateMapping.Term");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.DateTimeValue = DateConverter.from(_data["DateTimeValue"]);
      this.DSFieldType = TermDSFieldType[_data["DSFieldType"]];
      this.Not = _data["Not"];
      this.NumberValue = _data["NumberValue"];
      this.Path = _data["Path"];
      this.StringValue = _data["StringValue"];
      this.TermOperator = TermTermOperator[_data["TermOperator"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["DateTimeValue"] = DateConverter.toIsoUtcString(this.DateTimeValue);
    _data["DSFieldType"] = TermDSFieldType[this.DSFieldType];
    _data["Not"] = this.Not;
    _data["NumberValue"] = this.NumberValue;
    _data["Path"] = this.Path;
    _data["StringValue"] = this.StringValue;
    _data["TermOperator"] = TermTermOperator[this.TermOperator];
    return _data;
  }
}
export var TermDSFieldType = /* @__PURE__ */ ((TermDSFieldType2) => {
  TermDSFieldType2["String"] = "String";
  TermDSFieldType2["Decimal"] = "Decimal";
  TermDSFieldType2["Int"] = "Int";
  TermDSFieldType2["DateTime"] = "DateTime";
  TermDSFieldType2["Enum"] = "Enum";
  TermDSFieldType2["Boolean"] = "Boolean";
  TermDSFieldType2["Unsupported"] = "Unsupported";
  return TermDSFieldType2;
})(TermDSFieldType || {});
export var TermTermOperator = /* @__PURE__ */ ((TermTermOperator2) => {
  TermTermOperator2["Eq"] = "Eq";
  TermTermOperator2["In"] = "In";
  TermTermOperator2["Gt"] = "Gt";
  TermTermOperator2["Lt"] = "Lt";
  TermTermOperator2["Lte"] = "Lte";
  TermTermOperator2["Gte"] = "Gte";
  return TermTermOperator2;
})(TermTermOperator || {});
