import { useState } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { getRouteByKey } from "../../globals";
import { iossRegistrationFaqs, vatRegistrationFaqs } from "./faqs-data";
export function FAQs() {
  const [openedQuestions, setOpenedQuestions] = useState([vatRegistrationFaqs[0].question]);
  const [questionType, setQuestionType] = useState("vat");
  const openQuestionType = (qt) => {
    const defaultOpenQuestion = qt === "ioss" ? iossRegistrationFaqs[0].question : vatRegistrationFaqs[0].question;
    setOpenedQuestions([defaultOpenQuestion]);
    setQuestionType(qt);
  };
  const mobileQuestionSelectorTemplate = () => html`
		<div class="flex justify-around mb-6">
			<h2
				class="cursor-pointer select-none text-xl mb-4 ml-4
                ${questionType === "vat" ? "font-bold underline" : ""}"
				@click=${() => openQuestionType("vat")}
			>
				${i18next.t("VAT Registration")}
			</h2>
			<h2
				class="cursor-pointer select-none text-xl mb-4 ml-4
                ${questionType === "ioss" ? "font-bold underline" : ""}"
				@click=${() => openQuestionType("ioss")}
			>
				${i18next.t("IOSS Registration")}
			</h2>
		</div>
	`;
  const desktopQuestionSelectorTemplate = () => html`
		<a class="mt-1 pb-4 block cursor-pointer select-none ${questionType === "vat" ? "font-bold underline" : ""}" @click=${() => openQuestionType("vat")}> ${i18next.t("VAT Registration")} </a>
		<a class="mt-1 pb-4 block cursor-pointer select-none ${questionType === "ioss" ? "font-bold underline" : ""}" @click=${() => openQuestionType("ioss")}> ${i18next.t("IOSS Registration")} </a>
		<div class="pt-6 border-t-2 border-gray-300 border-solid">
			${unsafeHTML(
    i18next.t("Couldnt_find_answer", `Couldn't find the answer to your question? Contact us {{- link_first_part}}here{{- link_second_part}}`, {
      link_first_part: `<a href="${getRouteByKey("contact")}" class="font-semibold">`,
      link_second_parth: "</a>"
    })
  )}
		</div>
	`;
  const questionList = (faqsList) => html`
		<div class="md:w-3/4">
			${faqsList.map(
    (faq) => html`
					<a
						class="${openedQuestions.includes(faq.question) ? "bg-gray-300 text-brand-primary-dark" : "text-brand-primary"} border-t-2 border-gray-300 flex justify-between pt-2 pb-3 px-4 cursor-pointer font-semibold select-none"
						@click=${() => {
      if (openedQuestions.includes(faq.question)) {
        setOpenedQuestions(openedQuestions.filter((q) => q !== faq.question));
      } else {
        setOpenedQuestions(openedQuestions.concat(faq.question));
      }
    }}
					>
						<p class="pt-2 mr-2">${unsafeHTML(faq.question)}</p>
						<div>
							<div class="${openedQuestions.includes(faq.question) ? "-rotate-90" : "rotate-90"} text-2xl transform font-normal text-black">&#10095;</div>
						</div>
					</a>
					<div class="${openedQuestions.includes(faq.question) ? "block" : "hidden"} mt-3 mb-6 pl-4">${faq.answer ? unsafeHTML(faq.answer) : ""}</div>
				`
  )}
		</div>
	`;
  return html`
		<div class="py-2 max-w-screen-xl m-auto">
			<h1 class="text-2xl font-semibold mt-8 mb-4">${i18next.t("Welcome to the Frequently Asked Questions (FAQ)")}</h1>
			<h2 class="pb-4 mb-6">${i18next.t("Check out the different categories to understand VAT compliance better.")}</h2>
			<div class="md:hidden">${mobileQuestionSelectorTemplate()}</div>
			<div class="md:flex md:justify-between">
				<div class="hidden md:block w-1/4 pt-3 pr-4 border-t-2 border-white">${desktopQuestionSelectorTemplate()}</div>
				${questionType === "vat" ? questionList(vatRegistrationFaqs) : questionList(iossRegistrationFaqs)}
			</div>
			<div class="pt-6 pb-12 mt-4 px-4 sm:px-8 xl:px-40">
				<p class="leading-relaxed text-xs">${i18next.t("fx-legal")}</p>
			</div>
		</div>
	`;
}
