var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
export const useDateEditorModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(props == null ? void 0 : props.value);
  useEffect(() => {
    setCurrentDate(props == null ? void 0 : props.value);
  }, [props == null ? void 0 : props.value]);
  const show = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    if (props.onClosing) {
      props.onClosing();
    }
    setShowModal(false);
  };
  const saveChanges = () => __async(void 0, null, function* () {
    yield props.saveDate(currentDate);
    closeModal();
  });
  const onValueChange = (e) => {
    setCurrentDate(e.detail.value);
  };
  const template = () => {
    if (showModal) {
      return html`
				<dc-modal
					.visible=${showModal}
					@close=${closeModal}
					.header=${props.title}
					.content=${html`<div>
						<dc-datepicker .label=${props.fieldName} .value=${currentDate} @change=${onValueChange} .min=${props.min} .max=${props.max}></dc-datepicker>
						<div class="mt-4 flex space-x-4">
							<button class="btn btn-primary" @click=${closeModal}>Cancel</button>
							<button class="btn btn-primary" @click=${saveChanges}>Save</button>
						</div>
					</div>`}
				>
				</dc-modal>
			`;
    } else {
      return "";
    }
  };
  return {
    show,
    template
  };
};
export const useDateRangeEditorModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [currentFromDate, setCurrentFromDate] = useState(props == null ? void 0 : props.from);
  const [currentToDate, setCurrentToDate] = useState(props == null ? void 0 : props.to);
  useEffect(() => {
    setCurrentFromDate(props == null ? void 0 : props.from);
    setCurrentToDate(props == null ? void 0 : props.to);
  }, [props == null ? void 0 : props.from, props == null ? void 0 : props.to]);
  const show = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    if (props.onClosing) {
      props.onClosing();
    }
    setShowModal(false);
  };
  const saveChanges = () => __async(void 0, null, function* () {
    yield props.saveDate(currentFromDate, currentToDate);
    closeModal();
  });
  const template = () => {
    if (showModal) {
      return html`
				<dc-modal
					.visible=${showModal}
					@close=${closeModal}
					.header=${props.title}
					.content=${html`<div>
						<dc-datepicker
							.label=${props.fieldNameFrom}
							.value=${currentFromDate}
							@change=${(e) => {
        setCurrentFromDate(e.detail.value);
      }}
							.max=${currentToDate}
						></dc-datepicker>
						<dc-datepicker
							.label=${props.fieldNameTo}
							.value=${currentToDate}
							@change=${(e) => {
        setCurrentToDate(e.detail.value);
      }}
							.min=${currentFromDate}
						></dc-datepicker>
						<div class="mt-4 flex space-x-4">
							<button class="btn btn-primary" @click=${closeModal}>Cancel</button>
							<button class="btn btn-primary" @click=${saveChanges}>Save</button>
						</div>
					</div>`}
				>
				</dc-modal>
			`;
    } else {
      return "";
    }
  };
  return {
    show,
    template
  };
};
