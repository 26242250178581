var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../../converters/DateConverter";
export class IossRegistration {
  constructor(_data) {
    /**
     */
    __publicField(this, "ClientId");
    /**
     */
    __publicField(this, "ClientName");
    /**
     */
    __publicField(this, "ClientSource");
    /**
     */
    __publicField(this, "CompanyStatus");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "MerchantId");
    /**
     */
    __publicField(this, "ProductId");
    /**
     */
    __publicField(this, "RegistrationType");
    /**
     */
    __publicField(this, "SignupDate");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "SubscriptionStatus");
    /**
     */
    __publicField(this, "SubscriptionValidTo");
    /**
     */
    __publicField(this, "Tier");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof IossRegistration
     */
    __publicField(this, "$type", "V1.Company.IossRegistration.Search.IossRegistration");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientId = _data["ClientId"];
      this.ClientName = _data["ClientName"];
      this.ClientSource = IossRegistrationClientSource[_data["ClientSource"]];
      this.CompanyStatus = IossRegistrationCompanyStatus[_data["CompanyStatus"]];
      this.Id = _data["Id"];
      this.MerchantId = _data["MerchantId"];
      this.ProductId = _data["ProductId"];
      this.RegistrationType = IossRegistrationRegistrationType[_data["RegistrationType"]];
      this.SignupDate = DateConverter.from(_data["SignupDate"]);
      this.Status = IossRegistrationStatus[_data["Status"]];
      this.SubscriptionStatus = IossRegistrationSubscriptionStatus[_data["SubscriptionStatus"]];
      this.SubscriptionValidTo = DateConverter.from(_data["SubscriptionValidTo"]);
      this.Tier = IossRegistrationTier[_data["Tier"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientId"] = this.ClientId;
    _data["ClientName"] = this.ClientName;
    _data["ClientSource"] = IossRegistrationClientSource[this.ClientSource];
    _data["CompanyStatus"] = IossRegistrationCompanyStatus[this.CompanyStatus];
    _data["Id"] = this.Id;
    _data["MerchantId"] = this.MerchantId;
    _data["ProductId"] = this.ProductId;
    _data["RegistrationType"] = IossRegistrationRegistrationType[this.RegistrationType];
    _data["SignupDate"] = DateConverter.toUtcDateString(this.SignupDate);
    _data["Status"] = IossRegistrationStatus[this.Status];
    _data["SubscriptionStatus"] = IossRegistrationSubscriptionStatus[this.SubscriptionStatus];
    _data["SubscriptionValidTo"] = DateConverter.toUtcDateString(this.SubscriptionValidTo);
    _data["Tier"] = IossRegistrationTier[this.Tier];
    return _data;
  }
}
export var IossRegistrationClientSource = /* @__PURE__ */ ((IossRegistrationClientSource2) => {
  IossRegistrationClientSource2["TaxDesk"] = "TaxDesk";
  IossRegistrationClientSource2["Geopost"] = "Geopost";
  IossRegistrationClientSource2["Partner"] = "Partner";
  IossRegistrationClientSource2["Hellotax"] = "Hellotax";
  return IossRegistrationClientSource2;
})(IossRegistrationClientSource || {});
export var IossRegistrationCompanyStatus = /* @__PURE__ */ ((IossRegistrationCompanyStatus2) => {
  IossRegistrationCompanyStatus2["Active"] = "Active";
  IossRegistrationCompanyStatus2["Rejected"] = "Rejected";
  IossRegistrationCompanyStatus2["Inactive"] = "Inactive";
  return IossRegistrationCompanyStatus2;
})(IossRegistrationCompanyStatus || {});
export var IossRegistrationRegistrationType = /* @__PURE__ */ ((IossRegistrationRegistrationType2) => {
  IossRegistrationRegistrationType2["NewRegistration"] = "NewRegistration";
  IossRegistrationRegistrationType2["TakeOver"] = "TakeOver";
  return IossRegistrationRegistrationType2;
})(IossRegistrationRegistrationType || {});
export var IossRegistrationStatus = /* @__PURE__ */ ((IossRegistrationStatus2) => {
  IossRegistrationStatus2["Pending"] = "Pending";
  IossRegistrationStatus2["WaitingForSignature"] = "WaitingForSignature";
  IossRegistrationStatus2["DocumentsSigned"] = "DocumentsSigned";
  IossRegistrationStatus2["SentToTA"] = "SentToTA";
  IossRegistrationStatus2["RegistrationCompleted"] = "RegistrationCompleted";
  IossRegistrationStatus2["Failed"] = "Failed";
  IossRegistrationStatus2["Declined"] = "Declined";
  IossRegistrationStatus2["Deregistered"] = "Deregistered";
  IossRegistrationStatus2["DeregistrationInProgress"] = "DeregistrationInProgress";
  return IossRegistrationStatus2;
})(IossRegistrationStatus || {});
export var IossRegistrationSubscriptionStatus = /* @__PURE__ */ ((IossRegistrationSubscriptionStatus2) => {
  IossRegistrationSubscriptionStatus2["Active"] = "Active";
  IossRegistrationSubscriptionStatus2["Cancelled"] = "Cancelled";
  IossRegistrationSubscriptionStatus2["Expired"] = "Expired";
  IossRegistrationSubscriptionStatus2["Pending"] = "Pending";
  return IossRegistrationSubscriptionStatus2;
})(IossRegistrationSubscriptionStatus || {});
export var IossRegistrationTier = /* @__PURE__ */ ((IossRegistrationTier2) => {
  IossRegistrationTier2["Tier0"] = "Tier0";
  IossRegistrationTier2["Tier1"] = "Tier1";
  IossRegistrationTier2["Tier2"] = "Tier2";
  IossRegistrationTier2["Tier3"] = "Tier3";
  return IossRegistrationTier2;
})(IossRegistrationTier || {});
