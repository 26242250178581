var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Periods, SourceFiles } from "WebServices/APIs/self/clients";
import { CreateSourceFile } from "WebServices/APIs/self/models/V1/Cesop/Periods";
import { useCallback, useClient } from "GlobalShared/haunted/CustomHooks";
import { uploadFileToAzureStorage } from "GlobalShared/helpers/sasHelper";
import { useMemo } from "haunted";
import { withErrorHandling, notifyWarning, notifyError } from "GlobalShared/helpers/errorHelper";
import i18next from "i18next";
export const useUploadManager = () => {
  const periodsClient = useClient(Periods);
  const sourceFilesClient = useClient(SourceFiles);
  const uploadToBlob = useCallback((response, file) => __async(void 0, null, function* () {
    const indexOfQuestionMark = response.SasUri.indexOf("?") > -1 ? response.SasUri.indexOf("?") : response.SasUri.length;
    const blobUrl = response.SasUri.substring(0, indexOfQuestionMark);
    const sasToken = response.SasUri.substring(indexOfQuestionMark);
    yield uploadFileToAzureStorage(file, blobUrl, (_blobUrl) => __async(void 0, null, function* () {
      return sasToken;
    }));
  }), []);
  return useMemo(() => {
    return {
      uploadTransactions: (periodId, file) => __async(void 0, null, function* () {
        yield withErrorHandling(
          () => __async(void 0, null, function* () {
            const response = (yield periodsClient.createSourceFile({ id: periodId, body: new CreateSourceFile({ FileName: file.name }) })).data;
            yield uploadToBlob(response, file);
            yield sourceFilesClient.fileUploaded({ id: response.SourceFileId });
          }),
          (error) => {
            if (error.statusCode === 409) {
              notifyWarning(i18next.t("A file with the same name ({{fileName}}) already uploaded.", { fileName: file.name }));
            } else {
              notifyError(error);
            }
          }
        );
      }),
      delete: (sourceFileId) => __async(void 0, null, function* () {
        yield sourceFilesClient.deleteFile({ id: sourceFileId });
      }),
      uploadPayees: (periodId, file) => __async(void 0, null, function* () {
        const response = (yield periodsClient.createPayeesSourceFile({ id: periodId, body: new CreateSourceFile({ FileName: file.name }) })).data;
        yield uploadToBlob(response, file);
        yield periodsClient.payeeSourceFileUploaded({ id: periodId });
      }),
      deletePayees: (periodId) => __async(void 0, null, function* () {
        yield periodsClient.deletePayeeSourceFile({ id: periodId });
      })
    };
  }, [periodsClient, sourceFilesClient]);
};
