var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Name } from "./Name";
export class PspInfo {
  constructor(_data) {
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "Identifier");
    /**
     */
    __publicField(this, "IdentifierType");
    /**
     */
    __publicField(this, "Name");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PspInfo
     */
    __publicField(this, "$type", "V1.Cesop.PspInfo");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Id = _data["Id"];
      this.Identifier = _data["Identifier"];
      this.IdentifierType = PspInfoIdentifierType[_data["IdentifierType"]];
      if (_data["Name"]) {
        const name_ = new Name();
        this.Name = name_.init(_data["Name"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["Id"] = this.Id;
    _data["Identifier"] = this.Identifier;
    _data["IdentifierType"] = PspInfoIdentifierType[this.IdentifierType];
    _data["Name"] = this.Name ? this.Name.toJSON() : void 0;
    return _data;
  }
}
export var PspInfoIdentifierType = /* @__PURE__ */ ((PspInfoIdentifierType2) => {
  PspInfoIdentifierType2["Bic"] = "Bic";
  PspInfoIdentifierType2["Other"] = "Other";
  return PspInfoIdentifierType2;
})(PspInfoIdentifierType || {});
