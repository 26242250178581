var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { PdfFieldValue } from "./PdfFieldValue";
export class PdfFieldResponse {
  constructor(_data) {
    /**
     */
    __publicField(this, "SupportedFields");
    /**
     */
    __publicField(this, "TemplateReference");
    /**
     */
    __publicField(this, "UnsupportedFields");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof PdfFieldResponse
     */
    __publicField(this, "$type", "V1.TemplateMapping.PdfFieldResponse");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["SupportedFields"] && _data["SupportedFields"].constructor === Array) {
        this.SupportedFields = [];
        for (let item of _data["SupportedFields"]) {
          const supportedFields_ = new PdfFieldValue();
          this.SupportedFields.push(supportedFields_.init(item));
        }
      }
      this.TemplateReference = _data["TemplateReference"];
      if (_data["UnsupportedFields"] && _data["UnsupportedFields"].constructor === Array) {
        this.UnsupportedFields = [];
        for (let item of _data["UnsupportedFields"]) {
          const unsupportedFields_ = new PdfFieldValue();
          this.UnsupportedFields.push(unsupportedFields_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.SupportedFields && this.SupportedFields.constructor === Array) {
      _data["SupportedFields"] = [];
      for (let item of this.SupportedFields) {
        _data["SupportedFields"].push(item.toJSON());
      }
    }
    _data["TemplateReference"] = this.TemplateReference;
    if (this.UnsupportedFields && this.UnsupportedFields.constructor === Array) {
      _data["UnsupportedFields"] = [];
      for (let item of this.UnsupportedFields) {
        _data["UnsupportedFields"].push(item.toJSON());
      }
    }
    return _data;
  }
}
