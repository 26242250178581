var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./periods.scss";
import { html } from "lit-html";
import { useEffect, useLoadingReducer, useQueryGridState, useQueryState, useState } from "GlobalShared/haunted/CustomHooks";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { useReportingCountries } from "./useReportingCountries";
import { usePeriods } from "./usePeriods";
import { usePeriodManager } from "./period-manager";
import { FieldNames, TAB_NAMES, TAB_QUERY_PREFIX } from "./PeriodModels";
import { usePeriodEditor } from "./period-editor";
import { PeriodSearchResultStatus, PeriodSearchResultUploadType } from "WebServices/APIs/self/models/V1/Cesop/Periods/Search/PeriodSearchResultAbstraction";
import i18next from "i18next";
import { currentLocale } from "../../../globals";
import { component } from "haunted";
import { PeriodStatuses, getPeriodStatusLabel } from "./PeriodsHelper";
export const Periods = () => {
  const getActivePeriodStatuses = () => PeriodStatuses.filter((s) => s.value !== PeriodSearchResultStatus.AcceptedByCesop);
  const search = () => __async(void 0, null, function* () {
    const response = yield periodManager.search(gridState, selectedTab);
    setSearchResponse(response);
  });
  const getStatusWithColor = (status) => {
    const statusLabel = getPeriodStatusLabel(status);
    const renderStatus = (backgroundColor) => html`
			<div class="flex items-center gap-2">
				<div class="w-2 h-2 rounded-full ${backgroundColor}"></div>
				<span>${statusLabel}</span>
			</div>
		`;
    switch (status) {
      case PeriodSearchResultStatus.AwaitingData:
        return renderStatus("bg-gray-500");
      case PeriodSearchResultStatus.AcceptedByCesop:
        return renderStatus("bg-green-500");
      case PeriodSearchResultStatus.DocumentGenerationInProgress:
      case PeriodSearchResultStatus.SubmittedToTa:
      case PeriodSearchResultStatus.DataUploaded:
      case PeriodSearchResultStatus.ReadyToSubmit:
      case PeriodSearchResultStatus.PreparingCorrection:
      case PeriodSearchResultStatus.SubmissionInProgress:
        return renderStatus("bg-orange-300");
      default:
        return status;
    }
  };
  const getTransactionLabel = (item) => {
    var _a;
    const transactionCount = (_a = item.NumberOfTransactions) != null ? _a : 0;
    switch (item.UploadType) {
      case PeriodSearchResultUploadType.Csv:
        return i18next.t("{{numberOfTransactions}} transactions (CSV)", { numberOfTransactions: transactionCount });
      case PeriodSearchResultUploadType.Xml:
        return i18next.t("{{numberOfTransactionFiles}} files (XML)", { numberOfTransactionFiles: item.NumberOfTransactionFiles });
      case PeriodSearchResultUploadType.Empty:
        return i18next.t("No Transactions");
      default:
        return "N/A";
    }
  };
  const onTabSelect = (e) => {
    if (e.detail.selectedTab !== selectedTab) {
      setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0 }));
      setSelectedTab(e.detail.selectedTab);
    }
  };
  const [loading, dispatchLoading] = useLoadingReducer();
  const loader = {
    start: () => dispatchLoading("inc"),
    stop: () => dispatchLoading("dec")
  };
  const periodManager = usePeriodManager({ loader });
  const countries = useWorldCountries(currentLocale);
  const reportingCountries = useReportingCountries();
  const periods = usePeriods();
  const [searchResponse, setSearchResponse] = useState();
  const [selectedTab, setSelectedTab] = useQueryState(TAB_QUERY_PREFIX, TAB_NAMES.active);
  const [gridState, setGridState] = useQueryGridState({
    appliedFilters: [],
    orderBy: FieldNames.StartDate,
    orderDir: "desc",
    pageIndex: 0,
    pageSize: 20
  });
  const periodEditor = usePeriodEditor({ refresh: search, periodManager });
  useEffect(() => {
    search();
  }, [gridState, selectedTab]);
  const onSortChanged = (e) => {
    const orderByValue = e.detail.orderBy;
    const orderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), { orderBy: orderByValue, orderDir: orderDirValue }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail),
      pageIndex: 0
    });
    setGridState(newGridFilter);
  };
  const periodList = () => {
    const vm = {
      columns: [
        {
          field: FieldNames.RegistrationIdentifier,
          label: i18next.t("Reg. Number"),
          columnTitle: "Registration Number",
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames.PspName,
          label: i18next.t("Name"),
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: FieldNames.StartDate,
          label: i18next.t("Period"),
          sortable: true,
          filterable: true,
          columnType: "enum",
          filterDescriptor: {
            multiSelect: true,
            type: "select",
            dataSource: periods,
            searchable: true,
            searchProps: { type: "includes" }
          }
        },
        {
          field: FieldNames.ReportingCountry,
          label: i18next.t("Country"),
          sortable: true,
          filterable: true,
          columnType: "enum",
          filterDescriptor: {
            multiSelect: true,
            type: "select",
            dataSource: reportingCountries.map((c) => ({ label: c.name, value: c.code })),
            searchable: true,
            searchProps: { type: "includes" }
          }
        },
        {
          field: FieldNames.Transactions,
          label: i18next.t("Transactions"),
          sortable: false,
          filterable: false,
          columnType: "string"
        },
        {
          field: FieldNames.Version,
          label: i18next.t("Version"),
          sortable: true,
          filterable: true,
          columnType: "number",
          filterDescriptor: { type: "number", from: true, to: true }
        },
        {
          field: FieldNames.Status,
          label: i18next.t("Status"),
          sortable: true,
          filterable: selectedTab === TAB_NAMES.active,
          columnType: "enum",
          filterDescriptor: {
            multiSelect: true,
            type: "select",
            dataSource: getActivePeriodStatuses(),
            searchable: true,
            searchProps: { type: "includes" }
          }
        },
        {
          field: FieldNames.Actions,
          label: ""
        }
      ],
      data: searchResponse == null ? void 0 : searchResponse.Hits,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      cellTemplate: (index, field) => {
        var _a, _b;
        const item = searchResponse == null ? void 0 : searchResponse.Hits[index];
        if (field === FieldNames.RegistrationIdentifier) {
          return (_a = item.RegistrationIdentifier) != null ? _a : "";
        } else if (field === FieldNames.PspName) {
          return item.PspName;
        } else if (field === FieldNames.StartDate) {
          return item.Period;
        } else if (field === FieldNames.ReportingCountry) {
          return ((_b = countries.find((c) => c.Code === item.ReportingCountry)) == null ? void 0 : _b.Name) || item.ReportingCountry;
        } else if (field === FieldNames.Transactions) {
          return getTransactionLabel(item);
        } else if (field === FieldNames.Version) {
          return item.Version.toString();
        } else if (field === FieldNames.Status) {
          return getStatusWithColor(item.Status);
        } else if (field === FieldNames.Actions) {
          return html`
						<div class="flex gap-1">
							<div>
								<button @click=${() => periodEditor.open(item.Id)} class="btn btn-sm btn-primary w-20 justify-center">
									${item.Status !== PeriodSearchResultStatus.AwaitingData ? i18next.t("View") : i18next.t("Upload")}
								</button>
							</div>

							<div>
								${item.HasSubmissionProof ? html` <button @click=${() => periodManager.downloadAllProofs(item.Id)} class="btn btn-sm btn-primary justify-center">${i18next.t("Download Proof")}</button>` : ""}
							</div>
						</div>
					`;
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResponse.Count
      }
    };
    return html` <dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>`;
  };
  const resetButton = () => gridState.appliedFilters.length ? html`
					<div class="lg:-mb-48 lg:mt-2">
						<button
							class="btn btn-tertiary"
							@click=${() => {
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: 0, appliedFilters: [] }));
  }}
						>
							${i18next.t("Reset filters")}
						</button>
					</div>
			  ` : "";
  return searchResponse ? html`
				<div>
					<div class="flex items-center justify-between mb-6 flex-wrap">
						<div class="text-2xl font-bold my-6">${i18next.t("Periods")}</div>
						${resetButton()}
					</div>
					<dc-tabs
						.data=${[
    {
      name: TAB_NAMES.active,
      label: i18next.t("Active Periods"),
      template: html` <div class="my-6">${periodList()}</div> `
    },
    {
      name: TAB_NAMES.submitted,
      label: i18next.t("Submitted Periods"),
      template: html` <div class="my-6">${periodList()}</div> `
    }
  ]}
						.selectedTab=${selectedTab}
						@tabSelected=${(e) => onTabSelect(e)}
					></dc-tabs>
					<div class="cesop-period-editor">${periodEditor.template}</div>

					${loading.count > 0 ? html`<dc-loader></dc-loader>` : ""}
				</div>
		  ` : html`<dc-loader></dc-loader>`;
};
customElements.define("ww-admin-cesop-periods", component(Periods, { useShadowDOM: false }));
