var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { SearchResult } from "./../models/V1/Company/IossRegistration/Search/SearchResult";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { PossibleStatusChange } from "./../models/V1/Company/IossRegistration/PossibleStatusChange";
export class IossRegistration {
  /**
   * Creates an instance of IossRegistration.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof IossRegistration
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param SearchIossRegistrationsRq_ _searchIossRegistrationsRq
   * @returns Promise<SearchIossRegistrationsRq_>
   * @memberof IossRegistration
   */
  searchIossRegistrations(pSearchIossRegistrationsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/IossRegistration/Registrations", "POST", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pSearchIossRegistrationsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: SearchResult },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ExportIossRegistrationsRq_ _exportIossRegistrationsRq
   * @returns Promise<ExportIossRegistrationsRq_>
   * @memberof IossRegistration
   */
  exportIossRegistrations(pExportIossRegistrationsRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/IossRegistration/export", "POST", "application/json; charset=UTF-8", "text/csv, application/json", options);
    client.addBody({ value: pExportIossRegistrationsRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 400, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetAvailableStatusChangesRq_ _getAvailableStatusChangesRq
   * @returns Promise<GetAvailableStatusChangesRq_>
   * @memberof IossRegistration
   */
  getAvailableStatusChanges(options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/IossRegistration/Companies/Registrations/Statuses",
      "GET",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200, clazz: PossibleStatusChange, isArray: true }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStatusRq_ _changeStatusRq
   * @returns Promise<ChangeStatusRq_>
   * @memberof IossRegistration
   */
  changeStatus(pChangeStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/IossRegistration/Companies/{companyId}/Registrations/{registrationId}/Status",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeStatusRq.companyId, required: true },
      { name: "registrationId", value: pChangeStatusRq.registrationId, required: true }
    ]);
    client.addBody({ value: pChangeStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadRegistrationDocumentRq_ _downloadRegistrationDocumentRq
   * @returns Promise<DownloadRegistrationDocumentRq_>
   * @memberof IossRegistration
   */
  downloadRegistrationDocument(pDownloadRegistrationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/IossRegistration/Companies/{companyId}/Registrations/{registrationId}/Documents/{documentId}",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addQueryParams([{ name: "type", value: pDownloadRegistrationDocumentRq.type }]);
    client.addPathParams([
      { name: "companyId", value: pDownloadRegistrationDocumentRq.companyId, required: true },
      { name: "registrationId", value: pDownloadRegistrationDocumentRq.registrationId, required: true },
      { name: "documentId", value: pDownloadRegistrationDocumentRq.documentId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadSignedRegistrationDocumentRq_ _uploadSignedRegistrationDocumentRq
   * @returns Promise<UploadSignedRegistrationDocumentRq_>
   * @memberof IossRegistration
   */
  uploadSignedRegistrationDocument(pUploadSignedRegistrationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/IossRegistration/Companies/{companyId}/Registrations/{registrationId}/Documents/{documentId}/Signed",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadSignedRegistrationDocumentRq.companyId, required: true },
      { name: "registrationId", value: pUploadSignedRegistrationDocumentRq.registrationId, required: true },
      { name: "documentId", value: pUploadSignedRegistrationDocumentRq.documentId, required: true }
    ]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadSignedRegistrationDocumentRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadCertificateRq_ _downloadCertificateRq
   * @returns Promise<DownloadCertificateRq_>
   * @memberof IossRegistration
   */
  downloadCertificate(pDownloadCertificateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/IossRegistration/Companies/{companyId}/Registrations/{registrationId}/Certificate",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadCertificateRq.companyId, required: true },
      { name: "registrationId", value: pDownloadCertificateRq.registrationId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadDeregistrationDocumentRq_ _downloadDeregistrationDocumentRq
   * @returns Promise<DownloadDeregistrationDocumentRq_>
   * @memberof IossRegistration
   */
  downloadDeregistrationDocument(pDownloadDeregistrationDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/IossRegistration/Companies/{companyId}/Registrations/{registrationId}/deregistrationdocument",
      "GET",
      "application/json; charset=UTF-8",
      "application/pdf, application/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadDeregistrationDocumentRq.companyId, required: true },
      { name: "registrationId", value: pDownloadDeregistrationDocumentRq.registrationId, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadDocumentRq_ _uploadDocumentRq
   * @returns Promise<UploadDocumentRq_>
   * @memberof IossRegistration
   */
  uploadDocument(pUploadDocumentRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/IossRegistration/Companies/{companyId}/Registrations/{registrationId}/document",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadDocumentRq.companyId, required: true },
      { name: "registrationId", value: pUploadDocumentRq.registrationId, required: true }
    ]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadDocumentRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 200 }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param ChangeStartDateRq_ _changeStartDateRq
   * @returns Promise<ChangeStartDateRq_>
   * @memberof IossRegistration
   */
  changeStartDate(pChangeStartDateRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/IossRegistration/companies/{companyId}/Registrations/{registrationId}/startDate",
      "PATCH",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pChangeStartDateRq.companyId, required: true },
      { name: "registrationId", value: pChangeStartDateRq.registrationId, required: true }
    ]);
    client.addBody({ value: pChangeStartDateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 404, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
export var DownloadRegistrationDocumentType = /* @__PURE__ */ ((DownloadRegistrationDocumentType2) => {
  DownloadRegistrationDocumentType2["Unsigned"] = "Unsigned";
  DownloadRegistrationDocumentType2["Signed"] = "Signed";
  return DownloadRegistrationDocumentType2;
})(DownloadRegistrationDocumentType || {});
