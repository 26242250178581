var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { CompanyDetail as WebCompanyInfoDetail, CompanyDetailLegalStatus as WebCompanyInfoDetailLegalStatus } from "WebServices/APIs/self/models/V1/CompanyInfo/CompanyDetail";
import { CompanyDetail as AdminCompanyInfoDetail, CompanyDetailLegalStatus as AdminCompanyInfoDetailLegalStatus } from "AdminServices/APIs/self/models/V1/CompanyInfo/CompanyDetail";
import { IndividualDetail as WebCompanyInfoIndividualDetail } from "WebServices/APIs/self/models/V1/CompanyInfo/IndividualDetail";
import { IndividualDetail as AdminCompanyInfoIndividualDetail } from "AdminServices/APIs/self/models/V1/CompanyInfo/IndividualDetail";
import { Questionnaire as WebQuestionnaire } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire";
import { Questionnaire as AdminQuestionnaire } from "AdminServices/APIs/self/models/V1/CompanyInfo/Questionnaire";
import { QuestionnaireStatus as WebQuestinnaireStatus } from "WebServices/APIs/self/models/V1/CompanyInfo/Questionnaire/Questionnaire";
import { isEuCountry, SERVICE_COUNTRIES } from "GlobalShared/helpers/countryHelper";
import { VatRegistrationServiceKind } from "AdminServices/APIs/self/models/V1/Company/CompanyInfo/VatRegistration";
import { IossRegistrationRegistrationType } from "AdminServices/APIs/self/models/V1/Company/CompanyInfo/IossRegistration";
import { OssRegistrationRegistrationType } from "AdminServices/APIs/self/models/V1/Company/CompanyInfo/OssRegistration";
export class QuestionnaireContext {
  constructor(questionnaire, isAdmin, euCountries, questions, company, canAdminModify) {
    __publicField(this, "questionnaire");
    __publicField(this, "euCountries");
    __publicField(this, "questions");
    __publicField(this, "isAdmin");
    __publicField(this, "company");
    __publicField(this, "canAdminModify");
    /* eslint-disable @typescript-eslint/naming-convention */
    __publicField(this, "visibilityRules", {
      tax_auth_name: () => this.hasVatService(),
      estab_vat_cert: () => this.hasVatService(),
      incorp_date: () => this.hasCountryRegistration([SERVICE_COUNTRIES.Poland, SERVICE_COUNTRIES.France, SERVICE_COUNTRIES.Germany, SERVICE_COUNTRIES.Norway, SERVICE_COUNTRIES.Switzerland]),
      amount_of_cap: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany, SERVICE_COUNTRIES.France, SERVICE_COUNTRIES.Norway]),
      article_assoc: () => this.isCompany() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany, SERVICE_COUNTRIES.France]),
      corresp_is_same: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.CzechRepublic, SERVICE_COUNTRIES.Poland]),
      corresp_address: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.CzechRepublic, SERVICE_COUNTRIES.Poland]) && !this.getBooleanAnswer("corresp_is_same"),
      uk_vat: () => this.hasCountryRegistration([SERVICE_COUNTRIES.UnitedKingdom]) && !this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.UnitedKingdom]),
      uk_annual: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.UnitedKingdom]),
      de_vat: () => this.hasTakoverCountryRegistration([SERVICE_COUNTRIES.Germany]),
      de_annual: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      de_storage_address: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      de_local_tax: () => this.hasTakoverCountryRegistration([SERVICE_COUNTRIES.Germany]),
      de_store_start: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      de_import_country: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      de_est_profit: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      de_est_vat: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      de_product_type: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      fr_vat: () => this.hasCountryRegistration([SERVICE_COUNTRIES.France]) && !this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.France]),
      fr_annual: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.France]),
      fr_vat_liability: () => !this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.France]),
      fr_supplies: () => !this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.France]),
      fr_acquisitions: () => !this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.France]),
      fr_cntry_disp: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.France]),
      fr_cntry_inv: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.France]),
      prev_fisc_rep: () => !this.isEuCountry() && this.hasTakoverCountryRegistration([SERVICE_COUNTRIES.France]),
      fisc_rep_address: () => !this.isEuCountry() && this.hasTakoverCountryRegistration([SERVICE_COUNTRIES.France]),
      it_vat: () => this.hasCountryRegistration([SERVICE_COUNTRIES.Italy]) && !this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy]),
      it_annual: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy]),
      it_emp: () => this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy]),
      it_sales: () => this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy]),
      it_dist_sales: () => this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy]),
      it_turnovers: () => this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy]) && this.getBooleanAnswer("it_sales"),
      it_vat_cert: () => this.hasTakoverCountryRegistration([SERVICE_COUNTRIES.Italy]),
      es_vat: () => this.hasCountryRegistration([SERVICE_COUNTRIES.Spain]) && !this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Spain]),
      es_annual: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Spain]),
      es_sepa_bank: () => this.hasCountryRegistration([SERVICE_COUNTRIES.Spain]),
      cz_vat: () => this.hasCountryRegistration([SERVICE_COUNTRIES.CzechRepublic]) && !this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.CzechRepublic]),
      cz_annual: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.CzechRepublic]),
      pl_vat: () => this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]) && !this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Poland]),
      pl_annual: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Poland]),
      at_annual: () => this.hasNewServiceCountryRegistration(["AT"]),
      at_vat: () => this.hasTakoverCountryRegistration(["AT"]),
      at_product_type: () => this.hasNewServiceCountryRegistration(["AT"]),
      at_local_tax: () => this.hasTakoverCountryRegistration(["AT"]),
      be_annual: () => this.hasNewServiceCountryRegistration(["BE"]),
      be_vat: () => this.hasTakoverCountryRegistration(["BE"]),
      ie_annual: () => this.hasNewServiceCountryRegistration(["IE"]),
      ie_vat: () => this.hasTakoverCountryRegistration(["IE"]),
      nl_annual: () => this.hasNewServiceCountryRegistration(["NL"]),
      nl_vat: () => this.hasTakoverCountryRegistration(["NL"]),
      dk_annual: () => this.hasNewServiceCountryRegistration(["DK"]),
      dk_vat: () => this.hasTakoverCountryRegistration(["DK"]),
      ro_annual: () => this.hasNewServiceCountryRegistration(["RO"]),
      ro_vat: () => this.hasTakoverCountryRegistration(["RO"]),
      se_annual: () => this.hasNewServiceCountryRegistration(["SE"]),
      se_vat: () => this.hasTakoverCountryRegistration(["SE"]),
      pt_annual: () => this.hasNewServiceCountryRegistration(["PT"]),
      pt_vat: () => this.hasTakoverCountryRegistration(["PT"]),
      hu_annual: () => this.hasNewServiceCountryRegistration(["HU"]),
      hu_vat: () => this.hasTakoverCountryRegistration(["HU"]),
      sk_annual: () => this.hasNewServiceCountryRegistration(["SK"]),
      sk_vat: () => this.hasTakoverCountryRegistration(["SK"]),
      fi_annual: () => this.hasNewServiceCountryRegistration(["FI"]),
      fi_vat: () => this.hasTakoverCountryRegistration(["FI"]),
      el_annual: () => this.hasNewServiceCountryRegistration(["EL"]),
      el_vat: () => this.hasTakoverCountryRegistration(["EL"]),
      lu_annual: () => this.hasNewServiceCountryRegistration(["LU"]),
      lu_vat: () => this.hasTakoverCountryRegistration(["LU"]),
      bg_annual: () => this.hasNewServiceCountryRegistration(["BG"]),
      bg_vat: () => this.hasTakoverCountryRegistration(["BG"]),
      hr_annual: () => this.hasNewServiceCountryRegistration(["HR"]),
      hr_vat: () => this.hasTakoverCountryRegistration(["HR"]),
      cy_annual: () => this.hasNewServiceCountryRegistration(["CY"]),
      cy_vat: () => this.hasTakoverCountryRegistration(["CY"]),
      ee_annual: () => this.hasNewServiceCountryRegistration(["EE"]),
      ee_vat: () => this.hasTakoverCountryRegistration(["EE"]),
      lt_annual: () => this.hasNewServiceCountryRegistration(["LT"]),
      lt_vat: () => this.hasTakoverCountryRegistration(["LT"]),
      lv_annual: () => this.hasNewServiceCountryRegistration(["LV"]),
      lv_vat: () => this.hasTakoverCountryRegistration(["LV"]),
      mt_annual: () => this.hasNewServiceCountryRegistration(["MT"]),
      mt_vat: () => this.hasTakoverCountryRegistration(["MT"]),
      si_annual: () => this.hasNewServiceCountryRegistration(["SI"]),
      si_vat: () => this.hasTakoverCountryRegistration(["SI"]),
      no_annual: () => this.hasNewServiceCountryRegistration(["NO"]),
      no_vat: () => this.hasTakoverCountryRegistration(["NO"]),
      no_product_type: () => this.hasNewServiceCountryRegistration(["NO"]),
      no_voec_scheme: () => this.hasNewServiceCountryRegistration(["NO"]),
      no_employees: () => this.hasNewServiceCountryRegistration(["NO"]) && !this.getBooleanAnswer("no_voec_scheme"),
      no_org_type: () => this.hasNewServiceCountryRegistration(["NO"]) && !this.getBooleanAnswer("no_voec_scheme"),
      no_owned_by: () => this.hasNewServiceCountryRegistration(["NO"]) && !this.getBooleanAnswer("no_voec_scheme"),
      no_exclusive_operate: () => this.hasNewServiceCountryRegistration(["NO"]) && !this.getBooleanAnswer("no_voec_scheme"),
      no_premises: () => this.hasNewServiceCountryRegistration(["NO"]) && !this.getBooleanAnswer("no_voec_scheme"),
      no_premises_address: () => this.hasNewServiceCountryRegistration(["NO"]) && !this.getBooleanAnswer("no_voec_scheme"),
      no_register_name: () => this.hasNewServiceCountryRegistration(["NO"]) && !this.getBooleanAnswer("no_voec_scheme"),
      no_register_address: () => this.hasNewServiceCountryRegistration(["NO"]) && !this.getBooleanAnswer("no_voec_scheme"),
      no_vat_cert: () => this.hasTakoverCountryRegistration(["NO"]),
      ch_annual: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_vat: () => this.hasTakoverCountryRegistration(["CH"]),
      ch_is_over_taken: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_over_taken_name: () => this.hasNewServiceCountryRegistration(["CH"]) && this.getBooleanAnswer("ch_is_over_taken"),
      ch_over_taken_address: () => this.hasNewServiceCountryRegistration(["CH"]) && this.getBooleanAnswer("ch_is_over_taken"),
      ch_over_taken_vat: () => this.hasNewServiceCountryRegistration(["CH"]) && this.getBooleanAnswer("ch_is_over_taken"),
      ch_take_over_perc: () => this.hasNewServiceCountryRegistration(["CH"]) && this.getBooleanAnswer("ch_is_over_taken"),
      ch_first_supply: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_perm_est: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_turn_work: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_turn_dom: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_turn_tcom: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_turn_taxex: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_turn_other: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_delivery: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_dist_sls: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_other_sup: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_turn_all: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_comp_act: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_volun: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_start_volun: () => this.hasNewServiceCountryRegistration(["CH"]) && this.getBooleanAnswer("ch_volun"),
      ch_gen_calc: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_act_home: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_act_ch: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_date_app: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_sol_rep: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_vat_cert: () => this.hasTakoverCountryRegistration(["CH"]),
      ch_acc_per_from: () => this.hasNewServiceCountryRegistration(["CH"]),
      ch_acc_per_to: () => this.hasNewServiceCountryRegistration(["CH"]),
      other_vats: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany, SERVICE_COUNTRIES.CzechRepublic, SERVICE_COUNTRIES.Poland]),
      com_reg_name: () => this.isEuCountry() && (this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]) || this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany])),
      stock_name: () => this.getBooleanAnswer("is_on_stock"),
      ticker_symbol: () => this.getBooleanAnswer("is_on_stock"),
      mp_store_name: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      sell_id: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      mp_name: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]),
      web_link: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy, SERVICE_COUNTRIES.Germany, SERVICE_COUNTRIES.Norway]),
      // eslint-disable-next-line complexity
      legal_rep: (subQuestionId) => {
        if (subQuestionId === "document_expiration_date") {
          return this.hasVatService();
        }
        if (subQuestionId === "phone" || subQuestionId === "email") {
          return this.hasIossService();
        }
        if (subQuestionId === "father_name") {
          return this.isIndividual() && this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]) || !this.isIndividual() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Spain, SERVICE_COUNTRIES.Poland]);
        }
        if (subQuestionId === "own_more_than_25_percent") {
          return !this.isIndividual() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]);
        }
        if (subQuestionId === "is_only_director") {
          return !this.isIndividual() && this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]);
        }
        if (subQuestionId === "middle_name") {
          return this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]);
        }
        if (subQuestionId === "fiscal_code") {
          return this.isEuCountry() && this.hasCountryRegistration([SERVICE_COUNTRIES.Italy]);
        }
        return true;
      },
      business_owner: (subQuestionId) => {
        if (subQuestionId === "document_number" || subQuestionId === "document_type" || subQuestionId === "registration_number") {
          return !this.isIndividual() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany]);
        }
        if (subQuestionId === "share_percentage" || subQuestionId === "date_of_birth" || subQuestionId === "date_of_incorporation") {
          return !this.isIndividual();
        }
        return true;
      },
      has_pl_bank: () => this.isEuCountry() && this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]),
      pl_bank: () => this.isEuCountry() && this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]) && this.getBooleanAnswer("has_pl_bank"),
      other_bank: () => this.hasCountryRegistration([SERVICE_COUNTRIES.Poland, SERVICE_COUNTRIES.Spain]) || this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Germany, SERVICE_COUNTRIES.CzechRepublic, SERVICE_COUNTRIES.Switzerland]) || this.isEuCountry() && this.hasCountryRegistration([SERVICE_COUNTRIES.France]),
      bank_cert: () => this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.CzechRepublic, SERVICE_COUNTRIES.France]) || this.isEuCountry() && this.hasTakoverCountryRegistration([SERVICE_COUNTRIES.France]),
      ioss_web_link: () => this.hasIossService(),
      ioss_fixed_estab: () => this.hasIossService() && false,
      // DEVNOTE: If there is fixed eu establishment, the user cannot register for IOSS.
      ioss_exs_ioss: () => this.isIossTakeOver(),
      oss_has_registration: () => this.hasVatService() && !this.hasOssService(),
      oss_details: () => this.hasVatService() && this.getBooleanAnswer("oss_has_registration"),
      oss_takeover_registration_number: () => this.isOssTakeOver(),
      oss_takeover_registration_date: () => this.isOssTakeOver(),
      account_loc: () => this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]),
      account_addrs: () => this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]),
      commercial_reg_num: () => !this.isIndividual() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Poland]),
      commercial_reg_date: () => !this.isIndividual() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Poland]),
      leg_ent_from: () => !this.isIndividual() && this.isEuCountry() && this.hasCountryRegistration([SERVICE_COUNTRIES.Poland]),
      name_of_com_auth: () => !this.isIndividual() && this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Poland]),
      leg_ent_type: () => !this.isIndividual() && this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy]),
      client_base: () => this.isEuCountry() && this.hasNewServiceCountryRegistration([SERVICE_COUNTRIES.Italy])
    });
    this.questionnaire = questionnaire;
    this.euCountries = euCountries;
    this.questions = questions;
    this.isAdmin = isAdmin;
    this.company = company;
    this.canAdminModify = canAdminModify;
  }
  getQuestion(questionId) {
    const filteredQuestions = this.questions.filter((question) => question.Id === questionId);
    if (filteredQuestions.length === 1) {
      return filteredQuestions[0];
    } else {
      return void 0;
    }
  }
  isEuCountry() {
    return isEuCountry(this.euCountries, this.getEstablishmentCountry());
  }
  isIndividual() {
    return this.company && (this.company.BusinessDetail instanceof WebCompanyInfoIndividualDetail || this.company.BusinessDetail instanceof AdminCompanyInfoIndividualDetail);
  }
  isCompany() {
    if (this.company && this.company.BusinessDetail instanceof WebCompanyInfoDetail) {
      return this.company.BusinessDetail.LegalStatus === WebCompanyInfoDetailLegalStatus.Company;
    }
    if (this.company && this.company.BusinessDetail instanceof AdminCompanyInfoDetail) {
      return this.company.BusinessDetail.LegalStatus === AdminCompanyInfoDetailLegalStatus.Company;
    }
    return void 0;
  }
  isPartnership() {
    if (this.company && this.company.BusinessDetail instanceof WebCompanyInfoDetail) {
      return this.company.BusinessDetail.LegalStatus === WebCompanyInfoDetailLegalStatus.Partnership;
    }
    if (this.company && this.company.BusinessDetail instanceof AdminCompanyInfoDetail) {
      return this.company.BusinessDetail.LegalStatus === AdminCompanyInfoDetailLegalStatus.Partnership;
    }
    return void 0;
  }
  getCompanyDetail() {
    if (this.company && this.company.BusinessDetail instanceof WebCompanyInfoDetail) {
      return this.company.BusinessDetail;
    }
    if (this.company && this.company.BusinessDetail instanceof AdminCompanyInfoDetail) {
      return this.company.BusinessDetail;
    }
    return void 0;
  }
  getIndividualDetail() {
    if (this.company && this.company.BusinessDetail instanceof WebCompanyInfoIndividualDetail) {
      return this.company.BusinessDetail;
    }
    if (this.company && this.company.BusinessDetail instanceof AdminCompanyInfoIndividualDetail) {
      return this.company.BusinessDetail;
    }
    return void 0;
  }
  hasCountryRegistration(countries) {
    return this.company && this.company.VatRegistrations && this.company.VatRegistrations.some((c) => countries.includes(c.ServiceCountry.Code));
  }
  hasNewServiceCountryRegistration(countries) {
    return this.company && this.company.VatRegistrations && this.company.VatRegistrations.some((c) => c.ServiceKind === VatRegistrationServiceKind.NewReg && countries.includes(c.ServiceCountry.Code));
  }
  hasTakoverCountryRegistration(countries) {
    return this.company && this.company.VatRegistrations && this.company.VatRegistrations.some((c) => c.ServiceKind === VatRegistrationServiceKind.TakOver && countries.includes(c.ServiceCountry.Code));
  }
  hasVatService() {
    var _a, _b;
    return ((_b = (_a = this.company) == null ? void 0 : _a.VatRegistrations) == null ? void 0 : _b.length) > 0;
  }
  hasIossService() {
    var _a, _b;
    return ((_b = (_a = this.company) == null ? void 0 : _a.IossRegistrations) == null ? void 0 : _b.length) > 0;
  }
  isIossTakeOver() {
    return this.hasIossService() && this.company.IossRegistrations[0].RegistrationType === IossRegistrationRegistrationType.TakeOver;
  }
  hasOssService() {
    var _a;
    return ((_a = this.company) == null ? void 0 : _a.OssRegistration) !== void 0;
  }
  isOssTakeOver() {
    return this.hasOssService() && this.company.OssRegistration.RegistrationType === OssRegistrationRegistrationType.TakeOver;
  }
  getBooleanAnswer(questionId) {
    const answer = this.getAnswer(questionId);
    return answer ? answer.Value : false;
  }
  /* eslint-enable @typescript-eslint/naming-convention */
  isVisible(questionId, subQuestionId) {
    const questionRule = this.visibilityRules[questionId];
    if (questionRule !== void 0) {
      return questionRule(subQuestionId);
    }
    return true;
  }
  getQuestionnaireStatus() {
    var _a;
    return (_a = this.questionnaire) == null ? void 0 : _a.Status;
  }
  isReadonly(_questionId) {
    if (this.questionnaire === void 0 || this.questionnaire.Status === void 0) {
      return true;
    } else {
      return !this.isAdmin && this.questionnaire.Status !== WebQuestinnaireStatus.Incomplete || this.isAdmin && !this.canAdminModify;
    }
  }
  getEstablishmentCountry() {
    var _a;
    return (_a = this.company) == null ? void 0 : _a.BusinessDetail.EstablishmentCountryCode;
  }
  getServiceCountryCodes() {
    return this.company && this.company.VatRegistrations && this.company.VatRegistrations.map((s) => s.ServiceCountry.Code);
  }
  getAnswers() {
    return this.questionnaire.Answers;
  }
  getAnswer(questionId) {
    return this.questionnaire && this.questionnaire.Answers.find((a) => a.QuestionId === questionId);
  }
  getNewContextWithSetAnswer(pAnswer) {
    let newQ11E;
    if (this.questionnaire instanceof AdminQuestionnaire) {
      newQ11E = new AdminQuestionnaire(__spreadValues({}, this.questionnaire));
    } else {
      newQ11E = new WebQuestionnaire(__spreadValues({}, this.questionnaire));
    }
    let answerFound = false;
    newQ11E.Answers = newQ11E.Answers.map((answer) => {
      if (answer.QuestionId === pAnswer.QuestionId) {
        answerFound = true;
        return pAnswer;
      } else {
        return answer;
      }
    });
    if (!answerFound) {
      newQ11E.Answers.push(pAnswer);
    }
    return new QuestionnaireContext(newQ11E, this.isAdmin, this.euCountries, this.questions, this.company, this.canAdminModify);
  }
  getUploadToQuestionUrl(questionId) {
    if (this.isAdmin) {
      return `${window.location.origin}/api/v1/Questionnaire/Company/${this.company.Id}/Answers/${questionId}/Upload/`;
    }
    return `${window.location.origin}/api/v1/Questionnaire/Answers/${questionId}/Upload`;
  }
  getUploadFileUrl() {
    if (this.isAdmin) {
      return `${window.location.origin}/api/v1/Questionnaire/Company/${this.company.Id}/Answers/FileUpload`;
    }
    return `${window.location.origin}/api/v1/Questionnaire/Answers/FileUpload`;
  }
}
