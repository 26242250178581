var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import classNames from "classnames";
import { html } from "lit-html";
const DEFAULTS = {
  class: "",
  disabled: false,
  size: "regular"
};
export const SecondaryButton = (props) => {
  props = __spreadValues(__spreadValues({}, DEFAULTS), props);
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!props.disabled) {
      props.onClick();
    }
  };
  return html`
		<div
			class=${classNames("h-10 flex items-center justify-center rounded-md cursor-pointer border border-brand-primary-dark bg-white text-brand-primary-dark font-bold hover:bg-gray-100", {
    [props.class]: props.class,
    "pointer-events-none opacity-50": props.disabled,
    "h-8 px-3 text-xs": props.size === "small",
    "h-10 px-6 text-sm": props.size === "regular",
    "h-12 px-9 text-base": props.size === "large"
  })}
			@click=${onClick}
		>
			${props.text}
		</div>
	`;
};
