import { Filter } from "./Filter";
import { CompanyFilter } from "./CompanyFilter";
import { PspFilter } from "./PspFilter";
export class FilterFactory {
  static create($type) {
    if ($type === "V1.Account.Search.Filter") {
      const result = new Filter();
      return result;
    }
    if ($type === "V1.Account.Search.CompanyFilter") {
      const result = new CompanyFilter();
      return result;
    }
    if ($type === "V1.Account.Search.PspFilter") {
      const result = new PspFilter();
      return result;
    }
    throw new Error($type + " not found");
  }
}
