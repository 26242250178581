var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Money } from "./../../Common/Money";
export class Service {
  constructor(_data) {
    /**
     */
    __publicField(this, "Code");
    /**
     */
    __publicField(this, "Price");
    /**
     */
    __publicField(this, "Status");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Service
     */
    __publicField(this, "$type", "V1.Orders.Services.Service");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.Code = ServiceCode[_data["Code"]];
      if (_data["Price"]) {
        const price_ = new Money();
        this.Price = price_.init(_data["Price"]);
      }
      this.Status = ServiceStatus[_data["Status"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["$type"] = this.$type;
    _data["Code"] = ServiceCode[this.Code];
    _data["Price"] = this.Price ? this.Price.toJSON() : void 0;
    _data["Status"] = ServiceStatus[this.Status];
    return _data;
  }
}
export var ServiceCode = /* @__PURE__ */ ((ServiceCode2) => {
  ServiceCode2["VatCompliance"] = "VatCompliance";
  ServiceCode2["VatMonthlyCompliance"] = "VatMonthlyCompliance";
  ServiceCode2["VatRegistration"] = "VatRegistration";
  ServiceCode2["VatTakeOver"] = "VatTakeOver";
  ServiceCode2["VatSpanishBankAccount"] = "VatSpanishBankAccount";
  ServiceCode2["VatFiscalRepresentative"] = "VatFiscalRepresentative";
  ServiceCode2["VatFilingAgent"] = "VatFilingAgent";
  ServiceCode2["VatRetrospectiveReturn"] = "VatRetrospectiveReturn";
  ServiceCode2["VatIntrastatIn"] = "VatIntrastatIn";
  ServiceCode2["VatIntrastatOut"] = "VatIntrastatOut";
  ServiceCode2["VatDeregistration"] = "VatDeregistration";
  ServiceCode2["VatSelfRevision"] = "VatSelfRevision";
  ServiceCode2["VatHomeCountryCompliance"] = "VatHomeCountryCompliance";
  ServiceCode2["IossComplianceTier0"] = "IossComplianceTier0";
  ServiceCode2["IossComplianceTier1"] = "IossComplianceTier1";
  ServiceCode2["IossComplianceTier2"] = "IossComplianceTier2";
  ServiceCode2["IossComplianceTier3"] = "IossComplianceTier3";
  ServiceCode2["IossRegistration"] = "IossRegistration";
  ServiceCode2["IossTakeOver"] = "IossTakeOver";
  ServiceCode2["IossDeregistration"] = "IossDeregistration";
  ServiceCode2["OssCompliance"] = "OssCompliance";
  ServiceCode2["OssRegistration"] = "OssRegistration";
  ServiceCode2["OssTakeOver"] = "OssTakeOver";
  ServiceCode2["OssDeregistration"] = "OssDeregistration";
  ServiceCode2["OssQuarterlyCompliance"] = "OssQuarterlyCompliance";
  ServiceCode2["GlobalEoriUk"] = "GlobalEoriUk";
  ServiceCode2["GlobalEoriEu"] = "GlobalEoriEu";
  ServiceCode2["GlobalGeneralVatAdvice"] = "GlobalGeneralVatAdvice";
  ServiceCode2["GlobalSpecialistVatAdvice"] = "GlobalSpecialistVatAdvice";
  ServiceCode2["GlobalCommunicatingWithTaxAuthorities"] = "GlobalCommunicatingWithTaxAuthorities";
  ServiceCode2["GlobalChangeCompanyDetail"] = "GlobalChangeCompanyDetail";
  ServiceCode2["GlobalContactingTaxAuthorities"] = "GlobalContactingTaxAuthorities";
  ServiceCode2["GlobalTranslation"] = "GlobalTranslation";
  ServiceCode2["GlobalTaxCertificate"] = "GlobalTaxCertificate";
  ServiceCode2["GlobalIntrastatThresholdCheck"] = "GlobalIntrastatThresholdCheck";
  ServiceCode2["GlobalDistanceSaleThresholdCheck"] = "GlobalDistanceSaleThresholdCheck";
  return ServiceCode2;
})(ServiceCode || {});
export var ServiceStatus = /* @__PURE__ */ ((ServiceStatus2) => {
  ServiceStatus2["Active"] = "Active";
  ServiceStatus2["Cancelled"] = "Cancelled";
  return ServiceStatus2;
})(ServiceStatus || {});
