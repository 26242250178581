import { BatchUploadResultErrorReason } from "AdminServices/APIs/self/models/V1/Submission/BatchUploadResult";
export const mapBatchUploadErrorReason = (reason) => {
  switch (reason) {
    case BatchUploadResultErrorReason.Duplication:
      return "Duplicated name";
    case BatchUploadResultErrorReason.IncorrectNaming:
      return "Incorrect naming";
    case BatchUploadResultErrorReason.IncorrectStatus:
      return "Incorrect Status";
    case BatchUploadResultErrorReason.SubmissionPeriodMissing:
      return "Submission period missing";
    case BatchUploadResultErrorReason.Cancelled:
      return "Cancelled";
    case BatchUploadResultErrorReason.IncorrectProofAmount:
      return "Incorrect proof amount";
    case BatchUploadResultErrorReason.MoreDataRequired:
      return "More data required";
    default:
      return "Unknown";
  }
};
export const SESSION_KEY_FOR_SUBMISSION_ONLY_SUBMISSION_PERIOD_IDS = "submissionOnlyReturnLibrarySubmissionPeriodIds";
