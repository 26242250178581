var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { SkuCommodityCode } from "./../SkuCommodityCode";
export class ValidationMessage {
  constructor(_data) {
    /**
     */
    __publicField(this, "ColumnName");
    /**
     */
    __publicField(this, "InvalidCommodityCodes");
    /**
     */
    __publicField(this, "RowNumber");
    /**
     */
    __publicField(this, "ValidationCode");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof ValidationMessage
     */
    __publicField(this, "$type", "V1.ComplianceMasterData.Upload.ValidationMessage");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ColumnName = _data["ColumnName"];
      if (_data["InvalidCommodityCodes"] && _data["InvalidCommodityCodes"].constructor === Array) {
        this.InvalidCommodityCodes = [];
        for (let item of _data["InvalidCommodityCodes"]) {
          const invalidCommodityCodes_ = new SkuCommodityCode();
          this.InvalidCommodityCodes.push(invalidCommodityCodes_.init(item));
        }
      }
      this.RowNumber = _data["RowNumber"];
      this.ValidationCode = ValidationMessageValidationCode[_data["ValidationCode"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ColumnName"] = this.ColumnName;
    if (this.InvalidCommodityCodes && this.InvalidCommodityCodes.constructor === Array) {
      _data["InvalidCommodityCodes"] = [];
      for (let item of this.InvalidCommodityCodes) {
        _data["InvalidCommodityCodes"].push(item.toJSON());
      }
    }
    _data["RowNumber"] = this.RowNumber;
    _data["ValidationCode"] = ValidationMessageValidationCode[this.ValidationCode];
    return _data;
  }
}
export var ValidationMessageValidationCode = /* @__PURE__ */ ((ValidationMessageValidationCode2) => {
  ValidationMessageValidationCode2["Required"] = "Required";
  ValidationMessageValidationCode2["RequiredOrWrongFormat"] = "RequiredOrWrongFormat";
  ValidationMessageValidationCode2["NotFound"] = "NotFound";
  ValidationMessageValidationCode2["CannotParse"] = "CannotParse";
  ValidationMessageValidationCode2["InvalidValue"] = "InvalidValue";
  ValidationMessageValidationCode2["Unknown"] = "Unknown";
  return ValidationMessageValidationCode2;
})(ValidationMessageValidationCode || {});
