var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { Questionnaire as QuestionnaireModel } from "./../models/V1/CompanyInfo/Questionnaire/Questionnaire";
import { FileReferenceAnswer } from "./../models/V1/CompanyInfo/Questionnaire/Answers/FileReferenceAnswer";
export class Questionnaire {
  /**
   * Creates an instance of Questionnaire.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof Questionnaire
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param ChangeQuestionnaireStatusRq_ _changeQuestionnaireStatusRq
   * @returns Promise<ChangeQuestionnaireStatusRq_>
   * @memberof Questionnaire
   */
  changeQuestionnaireStatus(pChangeQuestionnaireStatusRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Company/{companyId}/Status",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([{ name: "companyId", value: pChangeQuestionnaireStatusRq.companyId, required: true }]);
    client.addBody({ value: pChangeQuestionnaireStatusRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }, { statusCode: 409, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param DownloadFileRq_ _downloadFileRq
   * @returns Promise<DownloadFileRq_>
   * @memberof Questionnaire
   */
  downloadFile(pDownloadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Company/{companyId}/Files/{reference}",
      "GET",
      "application/json; charset=UTF-8",
      "image/png, image/jpeg, application/pdf",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pDownloadFileRq.companyId, required: true },
      { name: "reference", value: pDownloadFileRq.reference, required: true }
    ]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetQuestionnaireRq_ _getQuestionnaireRq
   * @returns Promise<GetQuestionnaireRq_>
   * @memberof Questionnaire
   */
  getQuestionnaire(pGetQuestionnaireRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Questionnaire/Company/{companyId}", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pGetQuestionnaireRq.companyId, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: QuestionnaireModel },
        { statusCode: 403, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param SaveModificationRq_ _saveModificationRq
   * @returns Promise<SaveModificationRq_>
   * @memberof Questionnaire
   */
  saveModification(pSaveModificationRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/Questionnaire/Company/{companyId}", "PATCH", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addPathParams([{ name: "companyId", value: pSaveModificationRq.companyId, required: true }]);
    client.addBody({ value: pSaveModificationRq.body, isArray: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 403, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param IncompleteQuestionnaireRq_ _incompleteQuestionnaireRq
   * @returns Promise<IncompleteQuestionnaireRq_>
   * @memberof Questionnaire
   */
  incompleteQuestionnaire(pIncompleteQuestionnaireRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Company/{companyId}/Incomplete",
      "POST",
      "application/json; charset=UTF-8",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([{ name: "companyId", value: pIncompleteQuestionnaireRq.companyId, required: true }]);
    client.addBody({ value: pIncompleteQuestionnaireRq.body, isPrimitive: true });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 403, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadFileRq_ _uploadFileRq
   * @returns Promise<UploadFileRq_>
   * @memberof Questionnaire
   */
  uploadFile(pUploadFileRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Company/{companyId}/Answers/FileUpload",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([{ name: "companyId", value: pUploadFileRq.companyId, required: true }]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadFileRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 201 }, { statusCode: 403, clazz: ApiError }, { statusCode: 415, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UploadFileToQuestionRq_ _uploadFileToQuestionRq
   * @returns Promise<UploadFileToQuestionRq_>
   * @memberof Questionnaire
   */
  uploadFileToQuestion(pUploadFileToQuestionRq, options = {}) {
    const client = new TswagClient(
      this.baseUrl + "/api/v1/Questionnaire/Company/{companyId}/Answers/{questionId}/Upload",
      "POST",
      "multipart/form-data",
      "text/plain, application/json, text/json",
      options
    );
    client.addPathParams([
      { name: "companyId", value: pUploadFileToQuestionRq.companyId, required: true },
      { name: "questionId", value: pUploadFileToQuestionRq.questionId, required: true }
    ]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUploadFileToQuestionRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: FileReferenceAnswer },
        { statusCode: 403, clazz: ApiError },
        { statusCode: 415, clazz: ApiError },
        { statusCode: 422, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
