import { SubmissionPeriodStatusChange } from "./SubmissionPeriodStatusChange";
import { Approved } from "./Approved";
import { Refused } from "./Refused";
import { SubmittedByClient } from "./SubmittedByClient";
export class SubmissionPeriodStatusChangeFactory {
  static create($type) {
    if ($type === "V1.Submission.SubmissionPeriodStatusChange") {
      const result = new SubmissionPeriodStatusChange();
      return result;
    }
    if ($type === "V1.Submission.Approved") {
      const result = new Approved();
      return result;
    }
    if ($type === "V1.Submission.Refused") {
      const result = new Refused();
      return result;
    }
    if ($type === "V1.Submission.SubmittedByClient") {
      const result = new SubmittedByClient();
      return result;
    }
    throw new Error($type + " not found");
  }
}
