var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Company } from "./Company";
import { Psp } from "./Psp";
export class User {
  constructor(_data) {
    /**
     */
    __publicField(this, "Companies");
    /**
     */
    __publicField(this, "Email");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "IsReadOnly");
    /**
     */
    __publicField(this, "Psps");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof User
     */
    __publicField(this, "$type", "V1.Account.Search.User");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Companies"] && _data["Companies"].constructor === Array) {
        this.Companies = [];
        for (let item of _data["Companies"]) {
          const companies_ = new Company();
          this.Companies.push(companies_.init(item));
        }
      }
      this.Email = _data["Email"];
      this.Id = _data["Id"];
      this.IsReadOnly = _data["IsReadOnly"];
      if (_data["Psps"] && _data["Psps"].constructor === Array) {
        this.Psps = [];
        for (let item of _data["Psps"]) {
          const psps_ = new Psp();
          this.Psps.push(psps_.init(item));
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.Companies && this.Companies.constructor === Array) {
      _data["Companies"] = [];
      for (let item of this.Companies) {
        _data["Companies"].push(item.toJSON());
      }
    }
    _data["Email"] = this.Email;
    _data["Id"] = this.Id;
    _data["IsReadOnly"] = this.IsReadOnly;
    if (this.Psps && this.Psps.constructor === Array) {
      _data["Psps"] = [];
      for (let item of this.Psps) {
        _data["Psps"].push(item.toJSON());
      }
    }
    return _data;
  }
}
