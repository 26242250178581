import { COLOR_BLACK, COLOR_RED, COLOR_URL_BLUE } from "./pdf-common";
const indentForNumberedTextBox = 6;
export const textCentered = (doc, text, y) => {
  doc.text(text, doc.internal.pageSize.width / 2, y, {
    align: "center"
  });
};
export const textBold = (text, x, y, doc) => {
  doc.setFont(doc.getFont().toString(), "bold");
  doc.text(text, x, y);
  doc.setFont(doc.getFont().toString(), "normal");
};
export const numberedParagraph = (data, paragraphY, paragraphX, textBoxWidth, marginBetweenTextBoxes, doc) => {
  let textBoxY = paragraphY;
  for (const textBox of data) {
    numberedText(textBox.displayNumber, textBox.text, paragraphX, textBoxY, textBoxWidth, doc);
    textBoxY = textBoxY + calculateTextBoxHeight(textBox.text, textBoxWidth, doc.getLineHeightFactor(), doc.getFontSize()) + marginBetweenTextBoxes;
  }
};
export const unorderedParagraph = (data, paragraphY, paragraphX, textBoxWidth, marginBetweenTextBoxes, doc) => {
  let textBoxY = paragraphY;
  for (const textBox of data) {
    bulletedText(textBox, paragraphX, textBoxY, textBoxWidth, doc);
    textBoxY = textBoxY + calculateTextBoxHeight(textBox, textBoxWidth, doc.getLineHeightFactor(), doc.getFontSize()) + marginBetweenTextBoxes;
  }
};
export const alphabeticalParagraph = (data, paragraphY, paragraphX, textBoxWidth, marginBetweenTextBoxes, doc) => {
  let textBoxY = paragraphY;
  for (const textBox of data) {
    alphabeticalTextBox(textBox.symbol, textBox.text, paragraphX, textBoxY, textBoxWidth, doc);
    textBoxY = textBoxY + calculateTextBoxHeight(textBox.text, textBoxWidth, doc.getLineHeightFactor(), doc.getFontSize()) + marginBetweenTextBoxes;
  }
};
const calculateTextBoxHeight = (text, paragraphWidth, lineHeight, fontSize) => {
  const ptToMm = 2.834;
  const fontSizeHeight = fontSize / ptToMm;
  const textWidth = calculateStringWidth(text, fontSize);
  const numberOfRows = Math.ceil(textWidth / paragraphWidth);
  return Math.round(numberOfRows * fontSizeHeight * lineHeight);
};
const calculateStringWidth = (text, fontSize) => {
  const ptToPx = 1.364;
  const pxToMm = 3.7;
  const context = document.createElement("canvas").getContext("2d");
  context.font = `${fontSize * ptToPx}px times new roman`;
  return Math.round(context.measureText(text).width / pxToMm);
};
export const justifiedText = (text, x, y, maxWidth, doc) => {
  doc.text(makeLastLineNotStickOut(text), x + 5, y, {
    align: "justify",
    maxWidth,
    lineHeightFactor: 1.4
  });
};
export const numberedText = (index, text, x, y, maxWidth, doc) => {
  doc.text(`${index.toString()}.`, x, y);
  justifiedText(text, x, y, maxWidth, doc);
};
export const bulletedText = (text, x, y, maxWidth, doc) => {
  doc.circle(x + 2, y - 1, 0.65, "F");
  justifiedText(text, x, y, maxWidth, doc);
};
export const alphabeticalTextBox = (orderLetter, text, x, y, maxWidth, doc) => {
  doc.text(`${orderLetter}`, x, y);
  doc.text(makeLastLineNotStickOut(text), x + indentForNumberedTextBox, y, {
    align: "justify",
    maxWidth,
    lineHeightFactor: 1.4
  });
};
export const linkText = (urlText, urlPath, x, y, maxWidth, doc) => {
  const urlTextLength = calculateStringWidth(urlText, doc.getFontSize());
  doc.setTextColor(...COLOR_URL_BLUE);
  doc.textWithLink(urlText, x, y, {
    url: urlPath,
    align: "justify",
    maxWidth,
    lineHeightFactor: 1.4
  });
  if (urlTextLength < maxWidth) {
    doc.setDrawColor(...COLOR_URL_BLUE);
    doc.line(x, y + 1, x + urlTextLength, y + 1);
    doc.setDrawColor(...COLOR_BLACK);
  }
  doc.setTextColor(...COLOR_BLACK);
};
export const linkInFrontOfTextTextBox = (urlText, urlPath, index, firstLine, text, x, y, maxWidth, doc) => {
  doc.text(`${index.toString()}.`, x, y);
  linkText(urlText, urlPath, x + indentForNumberedTextBox, y, maxWidth, doc);
  doc.text(firstLine, x + indentForNumberedTextBox + calculateStringWidth(urlText, doc.getFontSize()), y, {
    align: "justify",
    maxWidth: maxWidth - indentForNumberedTextBox,
    lineHeightFactor: 1.4
  });
  doc.text(makeLastLineNotStickOut(text), x + indentForNumberedTextBox, y + 4, {
    align: "justify",
    maxWidth: maxWidth - indentForNumberedTextBox,
    lineHeightFactor: 1.4
  });
};
export const appendixLink = (appendixTitle, pageNumber, textAfterAppendix, x, y, doc) => {
  const appendixLength = calculateStringWidth(appendixTitle, doc.getFontSize());
  doc.setTextColor(...COLOR_RED);
  doc.textWithLink(appendixTitle, x + indentForNumberedTextBox, y, { pageNumber });
  doc.setDrawColor(...COLOR_RED);
  doc.line(x + indentForNumberedTextBox, y + 1, x + indentForNumberedTextBox + appendixLength, y + 1);
  doc.setDrawColor(...COLOR_BLACK);
  doc.setTextColor(...COLOR_BLACK);
  doc.text(textAfterAppendix, x + indentForNumberedTextBox + appendixLength, y);
};
const makeLastLineNotStickOut = (text) => {
  return text + "                                ";
};
export const linkWithBrackets = (url, x, y, maxWidth, doc) => {
  const urlTextLength = calculateStringWidth(url, doc.getFontSize());
  doc.text("(", x, y);
  linkText(url, url, x + 1, y, maxWidth, doc);
  doc.text(")", x + urlTextLength, y);
};
export const mainLine = (countryName, price, pLine, pCumulativeTop, tableProps, doc) => {
  doc.text(countryName, tableProps.tableLeftPadding, pCumulativeTop + tableProps.lineHeight * pLine);
  doc.text(`${price} ${tableProps.eurChar}`, tableProps.tableLeftPadding + tableProps.secondCol, pCumulativeTop + tableProps.lineHeight * pLine);
};
export const serviceLine = (serviceName, price, pLine, pCumulativeTop, tableProps, doc) => {
  doc.text(`	${serviceName}`, tableProps.tableLeftPadding, pCumulativeTop + tableProps.lineHeight * pLine);
  doc.text(`${price} ${tableProps.eurChar}`, tableProps.tableLeftPadding + tableProps.secondCol, pCumulativeTop + tableProps.lineHeight * pLine);
};
export const horizontalLine = (x1, y, x2, doc) => {
  doc.line(x1, y, x2, y);
};
