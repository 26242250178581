var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { UploadResult } from "./../ComplianceUploads/UploadResult";
import { DataProcessingJob } from "./DataProcessingJob";
export class IossDataProcessingJob extends DataProcessingJob {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "ValidationResult");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof IossDataProcessingJob
     */
    __publicField(this, "$type", "V1.DataProcessingJobs.IossDataProcessingJob");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["ValidationResult"]) {
        const validationResult_ = new UploadResult();
        this.ValidationResult = validationResult_.init(_data["ValidationResult"]);
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["ValidationResult"] = this.ValidationResult ? this.ValidationResult.toJSON() : void 0;
    return _data;
  }
}
