import { CustomSourceReference } from "./CustomSourceReference";
import { OneCustomSourceReference } from "./OneCustomSourceReference";
import { SeparateCustomSourceReference } from "./SeparateCustomSourceReference";
export class CustomSourceReferenceFactory {
  static create($type) {
    if ($type === "V1.GenerationConfiguration.CustomSourceReference") {
      const result = new CustomSourceReference();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.OneCustomSourceReference") {
      const result = new OneCustomSourceReference();
      return result;
    }
    if ($type === "V1.GenerationConfiguration.SeparateCustomSourceReference") {
      const result = new SeparateCustomSourceReference();
      return result;
    }
    throw new Error($type + " not found");
  }
}
