import { html } from "lit-html";
import { circleCheckedIcon, templateTitle, triangleExclamationIcon } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { uploadFileToQuestion } from "GlobalShared/helpers/questionnaireHelper";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
export const observedAttributes = ["q11e", "worldCountries", "invalidAccordions"];
export const useShadowDOM = false;
export const name = "ww-q11e-banking-information";
export const Component = (host) => {
  var _a;
  const props = {
    q11e: host.q11e,
    worldCountries: (_a = host.worldCountries) != null ? _a : [],
    invalidAccordions: host.invalidAccordions
  };
  const handleAccordionClick = (e, groupName) => {
    e.preventDefault();
    e.stopPropagation();
    if (openAccordionGroupNames.includes(groupName)) {
      setOpenAccordionGroupNames(openAccordionGroupNames.filter((openGroupName) => openGroupName !== groupName));
      return;
    }
    setOpenAccordionGroupNames([...openAccordionGroupNames, groupName]);
  };
  const hasVisibleBankInformationField = () => {
    var _a2, _b, _c, _d, _e;
    return ((_a2 = props.q11e) == null ? void 0 : _a2.isVisible("has_pl_bank")) || ((_b = props.q11e) == null ? void 0 : _b.isVisible("pl_bank")) || ((_c = props.q11e) == null ? void 0 : _c.isVisible("other_bank")) || ((_d = props.q11e) == null ? void 0 : _d.isVisible("bank_cert")) || ((_e = props.q11e) == null ? void 0 : _e.isVisible("es_sepa_bank"));
  };
  const getAccordionIcon = (groupName) => {
    var _a2;
    return ((_a2 = props.invalidAccordions) == null ? void 0 : _a2.includes(groupName)) ? triangleExclamationIcon(24, 24, "fill-orange-400") : circleCheckedIcon();
  };
  const [openAccordionGroupNames, setOpenAccordionGroupNames] = useState([]);
  useEffect(() => {
    if (props.invalidAccordions !== void 0) {
      setOpenAccordionGroupNames([...openAccordionGroupNames, ...props.invalidAccordions]);
    }
  }, [props.invalidAccordions]);
  const accordionHeaderTitleTemplate = (title, groupName) => html`
		<div class="flex gap-4 items-center"><span class="text-lg font-bold">${title}</span><span>${props.invalidAccordions !== void 0 ? getAccordionIcon(groupName) : ""}</span></div>
	`;
  const primaryBankAccountAccordionContentTemplate = () => {
    var _a2;
    return html`
		<div class="px-8 mb-8">
			<ww-answer-bank-information
				class=${((_a2 = props.q11e) == null ? void 0 : _a2.isVisible("other_bank")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"other_bank"}
				.worldCountries=${props.worldCountries}
			></ww-answer-bank-information>
		</div>
	`;
  };
  const polishBankAccountAccordionContentTemplate = () => {
    var _a2, _b, _c;
    return html`
		<div class="px-8 mb-8">
			<ww-answer-bool class=${((_a2 = props.q11e) == null ? void 0 : _a2.isVisible("has_pl_bank")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"has_pl_bank"} .validation=${{ required: true }}></ww-answer-bool>
			<div class="my-8 ${((_b = props.q11e) == null ? void 0 : _b.isVisible("pl_bank")) ? "" : "hidden"}">
				<label class="text-lg font-bold"> ${i18next.t("Please add the Polish bank details")} </label>
			</div>

			<ww-answer-bank-information
				class=${((_c = props.q11e) == null ? void 0 : _c.isVisible("pl_bank")) ? "" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"pl_bank"}
				.withDefaultCountry=${"PL"}
				.worldCountries=${props.worldCountries}
			></ww-answer-bank-information>
		</div>
	`;
  };
  const supportingDocumentsAccordionContentTemplate = () => {
    var _a2, _b;
    return html`
		<div class="px-8 mb-8">
			<ww-answer-fileref
				class=${((_a2 = props.q11e) == null ? void 0 : _a2.isVisible("bank_cert")) ? "block w-1/3 md:w-1/2" : "hidden"}
				.q11e=${props.q11e}
				.questionId=${"bank_cert"}
				.onUpload=${uploadFileToQuestion}
				.validation=${{ required: true }}
			></ww-answer-fileref>
			<div class="my-4">
				<ww-answer-bool class=${((_b = props.q11e) == null ? void 0 : _b.isVisible("es_sepa_bank")) ? "" : "hidden"} .q11e=${props.q11e} .questionId=${"es_sepa_bank"} .validation=${{ required: true }}></ww-answer-bool>
			</div>
		</div>
	`;
  };
  const accordionsTemplate = () => {
    var _a2, _b, _c, _d, _e, _f, _g, _h;
    const accordions = [
      {
        title: accordionHeaderTitleTemplate(i18next.t("Primary Bank Account"), "primary_bank_account"),
        visible: (_a2 = props.q11e) == null ? void 0 : _a2.isVisible("other_bank"),
        groupName: "primary_bank_account",
        isValid: !((_b = props.invalidAccordions) == null ? void 0 : _b.includes("primary_bank_account")),
        content: primaryBankAccountAccordionContentTemplate
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("Polish Bank Account"), "polish_bank_account"),
        visible: ((_c = props.q11e) == null ? void 0 : _c.isVisible("has_pl_bank")) || ((_d = props.q11e) == null ? void 0 : _d.isVisible("pl_bank")),
        groupName: "polish_bank_account",
        isValid: !((_e = props.invalidAccordions) == null ? void 0 : _e.includes("polish_bank_account")),
        content: polishBankAccountAccordionContentTemplate
      },
      {
        title: accordionHeaderTitleTemplate(i18next.t("Supporting Documents"), "supporting_documents"),
        visible: ((_f = props.q11e) == null ? void 0 : _f.isVisible("bank_cert")) || ((_g = props.q11e) == null ? void 0 : _g.isVisible("es_sepa_bank")),
        groupName: "supporting_documents",
        isValid: !((_h = props.invalidAccordions) == null ? void 0 : _h.includes("supporting_documents")),
        content: supportingDocumentsAccordionContentTemplate
      }
    ];
    return accordions.map((accordion) => {
      return accordion.visible ? html`
						<dc-accordion
							.contentTemplate=${accordion.content}
							.isOpen=${openAccordionGroupNames.includes(accordion.groupName)}
							.headerTitle=${accordion.title}
							.onClick=${(e) => handleAccordionClick(e, accordion.groupName)}
						></dc-accordion>
				  ` : "";
    });
  };
  return html`
		${props.q11e !== void 0 && hasVisibleBankInformationField() ? html` <div class="mt-8 flex flex-col gap-8">${accordionsTemplate()}</div> ` : html`
					<div>
						<div class="my-8">${templateTitle(i18next.t("Banking Information"))}</div>
						${i18next.t("q11e-banking-information-nobankinginformation")}
					</div>
			  `}
	`;
};
