export var PeriodFilterStatuses = /* @__PURE__ */ ((PeriodFilterStatuses2) => {
  PeriodFilterStatuses2["AwaitingData"] = "AwaitingData";
  PeriodFilterStatuses2["DataUploaded"] = "DataUploaded";
  PeriodFilterStatuses2["DocumentGenerationInProgress"] = "DocumentGenerationInProgress";
  PeriodFilterStatuses2["ReadyToSubmit"] = "ReadyToSubmit";
  PeriodFilterStatuses2["SubmissionInProgress"] = "SubmissionInProgress";
  PeriodFilterStatuses2["SubmittedToTa"] = "SubmittedToTa";
  PeriodFilterStatuses2["AcceptedByCesop"] = "AcceptedByCesop";
  PeriodFilterStatuses2["CorrectionNeeded"] = "CorrectionNeeded";
  PeriodFilterStatuses2["PreparingCorrection"] = "PreparingCorrection";
  return PeriodFilterStatuses2;
})(PeriodFilterStatuses || {});
