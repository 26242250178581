import { useMemo } from "GlobalShared/haunted/CustomHooks";
import moment from "moment";
const generatePeriods = () => {
  const quarters = [];
  const startDate = moment({ year: 2024, month: 0, day: 1 });
  const today = moment();
  let currentDate = startDate;
  while (currentDate <= today) {
    const quarter = Math.floor(currentDate.month() / 3) + 1;
    const year = currentDate.year();
    quarters.push({ quarter, year });
    currentDate = currentDate.add(3, "months");
  }
  return quarters.map((q) => ({ label: `Q${q.quarter} ${q.year}`, value: `${q.year}-${q.quarter}` }));
};
export const usePeriods = () => {
  return useMemo(() => {
    return generatePeriods();
  }, []);
};
