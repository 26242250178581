var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { ONLY_PDF_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
export const useOSSModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [ossCertificateReference, setOSSCertificateReference] = useState(void 0);
  const [ossNumber, setOSSNumber] = useState(void 0);
  const [ossRegistrationDate, setOSSRegistrationDate] = useState(void 0);
  const [ossCertificateValidationMessage, setOSSCertificateValidationMessage] = useState(void 0);
  const [ossNumberValidationMessage, setOSSNumberValidationMessage] = useState(void 0);
  const [ossRegistrationDateValidationMessage, setOSSRegistrationDateValidationMessage] = useState(void 0);
  const [paymentReference, setPaymentReference] = useState(void 0);
  const [ossCountryCode, setOssCountryCode] = useState(void 0);
  useEffect(() => __async(void 0, null, function* () {
    var _a, _b, _c;
    if (props && props.ossCertificate !== void 0) {
      setOSSNumber((_a = props.ossCertificate) == null ? void 0 : _a.RegistrationNumber);
      setOSSCertificateReference((_b = props.ossCertificate) == null ? void 0 : _b.CertificateReferenceId);
      setOSSRegistrationDate((_c = props.ossCertificate) == null ? void 0 : _c.RegistrationDate);
      setPaymentReference(props.paymentReference);
      setOssCountryCode(props.ossCountryCode);
    }
  }), [props]);
  const show = () => {
    setShowModal(true);
  };
  useEffect(() => {
    setOSSCertificateReference(void 0);
    setOSSNumber(void 0);
    setOSSRegistrationDate(void 0);
    setOSSCertificateValidationMessage(void 0);
    setPaymentReference(void 0);
    setOSSNumberValidationMessage(void 0);
    setOSSRegistrationDateValidationMessage(void 0);
  }, [showModal]);
  const template = html`${showModal ? html`<div>
				<dc-modal
					.visible=${showModal}
					@close=${() => setShowModal(false)}
					.header=${"Add OSS certificate"}
					.content=${html`<div class="space-y-4 md:w-1/2">
						<dc-fileupload
							.label=${"OSS certificate"}
							.fileName=${void 0}
							.fileReference=${ossCertificateReference}
							.validationMessage=${ossCertificateValidationMessage}
							.accept=${ONLY_PDF_ACCEPT_LIST}
							@upload=${(e) => __async(void 0, null, function* () {
    var _a;
    if (((_a = e.detail) == null ? void 0 : _a.files) && e.detail.files.length > 0) {
      setOSSCertificateReference(yield props.uploadOSSCertificate(e.detail.files[0]));
    }
  })}
						></dc-fileupload>
						<dc-input
							.label=${"OSS number*"}
							.value=${ossNumber}
							.validationMessage=${ossNumberValidationMessage}
							@change=${(e) => {
    setOSSNumber(e.detail.value);
  }}
						></dc-input>
						<dc-datepicker
							.label=${"OSS registration date*"}
							.value=${ossRegistrationDate}
							.validationMessage=${ossRegistrationDateValidationMessage}
							@change=${(e) => {
    setOSSRegistrationDate(e.detail.value);
  }}
						></dc-datepicker>
                        ${ossCountryCode && ossCountryCode === "DE" ? html`
								<dc-input
									.label=${"Payment Reference"}
									.value=${paymentReference}
									@change=${(e) => {
    setPaymentReference(e.detail.value);
  }}
								></dc-input>` : ""}
						<div>
							<button class="btn btn-primary mr-4" @click=${() => setShowModal(false)}>Cancel</button>
							<button
								class="btn btn-primary"
								@click=${() => __async(void 0, null, function* () {
    let isValid = true;
    setOSSCertificateValidationMessage(void 0);
    setOSSNumberValidationMessage(void 0);
    setOSSRegistrationDateValidationMessage(void 0);
    if (isEmpty(ossNumber)) {
      setOSSNumberValidationMessage("Required");
      isValid = false;
    }
    if (ossRegistrationDate === void 0) {
      setOSSRegistrationDateValidationMessage("Required");
      isValid = false;
    }
    if (isValid) {
      const localCert = {
        CertificateReferenceId: ossCertificateReference,
        RegistrationNumber: ossNumber,
        RegistrationDate: ossRegistrationDate
      };
      yield props.save(localCert, paymentReference);
      setShowModal(false);
    }
  })}
							>
								Save
							</button>
						</div>
					</div>`}
				>
				</dc-modal>
		  </div>` : html``}`;
  return { show, template };
};
