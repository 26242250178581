var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
import { BankAccountChangeRequest } from "./BankAccountChangeRequest";
import { VatRegistrationStatusChange } from "./VatRegistrationStatusChange";
export class AddLocalTaxStatusChange extends VatRegistrationStatusChange {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "BankAccountConfig");
    /**
     */
    __publicField(this, "ESLEFiling");
    /**
     */
    __publicField(this, "ESLFilingExtension");
    /**
     */
    __publicField(this, "ESLFrequencyType");
    /**
     */
    __publicField(this, "IntrastatEFiling");
    /**
     */
    __publicField(this, "IntrastatFilingExtension");
    /**
     */
    __publicField(this, "IntrastatFrequencyType");
    /**
     */
    __publicField(this, "LocalListingEFiling");
    /**
     */
    __publicField(this, "LocalListingFilingExtension");
    /**
     */
    __publicField(this, "LocalListingFrequencyType");
    /**
     */
    __publicField(this, "LocalVATCertificateReferenceId");
    /**
     */
    __publicField(this, "LocalVatNumber");
    /**
     */
    __publicField(this, "LocalVatRegistrationDate");
    /**
     */
    __publicField(this, "NotifyUser");
    /**
     */
    __publicField(this, "PaymentReference");
    /**
     */
    __publicField(this, "PrimaryVATEFiling");
    /**
     */
    __publicField(this, "PrimaryVATFilingExtension");
    /**
     */
    __publicField(this, "PrimaryVATFrequencyType");
    /**
     */
    __publicField(this, "SecondaryVATEFiling");
    /**
     */
    __publicField(this, "SecondaryVATFilingExtension");
    /**
     */
    __publicField(this, "SecondaryVATFrequencyType");
    /**
     */
    __publicField(this, "TaxIdentifier");
    /**
     */
    __publicField(this, "TaxOfficeId");
    /**
     */
    __publicField(this, "VLSEFiling");
    /**
     */
    __publicField(this, "VLSFilingExtension");
    /**
     */
    __publicField(this, "VLSFrequencyType");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof AddLocalTaxStatusChange
     */
    __publicField(this, "$type", "V1.Company.VatRegistration.AddLocalTaxStatusChange");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      if (_data["BankAccountConfig"]) {
        const bankAccountConfig_ = new BankAccountChangeRequest();
        this.BankAccountConfig = bankAccountConfig_.init(_data["BankAccountConfig"]);
      }
      this.ESLEFiling = _data["ESLEFiling"];
      this.ESLFilingExtension = _data["ESLFilingExtension"];
      this.ESLFrequencyType = AddLocalTaxStatusChangeESLFrequencyType[_data["ESLFrequencyType"]];
      this.IntrastatEFiling = _data["IntrastatEFiling"];
      this.IntrastatFilingExtension = _data["IntrastatFilingExtension"];
      this.IntrastatFrequencyType = AddLocalTaxStatusChangeIntrastatFrequencyType[_data["IntrastatFrequencyType"]];
      this.LocalListingEFiling = _data["LocalListingEFiling"];
      this.LocalListingFilingExtension = _data["LocalListingFilingExtension"];
      this.LocalListingFrequencyType = AddLocalTaxStatusChangeLocalListingFrequencyType[_data["LocalListingFrequencyType"]];
      this.LocalVATCertificateReferenceId = _data["LocalVATCertificateReferenceId"];
      this.LocalVatNumber = _data["LocalVatNumber"];
      this.LocalVatRegistrationDate = DateConverter.from(_data["LocalVatRegistrationDate"]);
      this.NotifyUser = _data["NotifyUser"];
      this.PaymentReference = _data["PaymentReference"];
      this.PrimaryVATEFiling = _data["PrimaryVATEFiling"];
      this.PrimaryVATFilingExtension = _data["PrimaryVATFilingExtension"];
      this.PrimaryVATFrequencyType = AddLocalTaxStatusChangePrimaryVATFrequencyType[_data["PrimaryVATFrequencyType"]];
      this.SecondaryVATEFiling = _data["SecondaryVATEFiling"];
      this.SecondaryVATFilingExtension = _data["SecondaryVATFilingExtension"];
      this.SecondaryVATFrequencyType = AddLocalTaxStatusChangeSecondaryVATFrequencyType[_data["SecondaryVATFrequencyType"]];
      this.TaxIdentifier = _data["TaxIdentifier"];
      this.TaxOfficeId = _data["TaxOfficeId"];
      this.VLSEFiling = _data["VLSEFiling"];
      this.VLSFilingExtension = _data["VLSFilingExtension"];
      this.VLSFrequencyType = AddLocalTaxStatusChangeVLSFrequencyType[_data["VLSFrequencyType"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["BankAccountConfig"] = this.BankAccountConfig ? this.BankAccountConfig.toJSON() : void 0;
    _data["ESLEFiling"] = this.ESLEFiling;
    _data["ESLFilingExtension"] = this.ESLFilingExtension;
    _data["ESLFrequencyType"] = AddLocalTaxStatusChangeESLFrequencyType[this.ESLFrequencyType];
    _data["IntrastatEFiling"] = this.IntrastatEFiling;
    _data["IntrastatFilingExtension"] = this.IntrastatFilingExtension;
    _data["IntrastatFrequencyType"] = AddLocalTaxStatusChangeIntrastatFrequencyType[this.IntrastatFrequencyType];
    _data["LocalListingEFiling"] = this.LocalListingEFiling;
    _data["LocalListingFilingExtension"] = this.LocalListingFilingExtension;
    _data["LocalListingFrequencyType"] = AddLocalTaxStatusChangeLocalListingFrequencyType[this.LocalListingFrequencyType];
    _data["LocalVATCertificateReferenceId"] = this.LocalVATCertificateReferenceId;
    _data["LocalVatNumber"] = this.LocalVatNumber;
    _data["LocalVatRegistrationDate"] = DateConverter.toUtcDateString(this.LocalVatRegistrationDate);
    _data["NotifyUser"] = this.NotifyUser;
    _data["PaymentReference"] = this.PaymentReference;
    _data["PrimaryVATEFiling"] = this.PrimaryVATEFiling;
    _data["PrimaryVATFilingExtension"] = this.PrimaryVATFilingExtension;
    _data["PrimaryVATFrequencyType"] = AddLocalTaxStatusChangePrimaryVATFrequencyType[this.PrimaryVATFrequencyType];
    _data["SecondaryVATEFiling"] = this.SecondaryVATEFiling;
    _data["SecondaryVATFilingExtension"] = this.SecondaryVATFilingExtension;
    _data["SecondaryVATFrequencyType"] = AddLocalTaxStatusChangeSecondaryVATFrequencyType[this.SecondaryVATFrequencyType];
    _data["TaxIdentifier"] = this.TaxIdentifier;
    _data["TaxOfficeId"] = this.TaxOfficeId;
    _data["VLSEFiling"] = this.VLSEFiling;
    _data["VLSFilingExtension"] = this.VLSFilingExtension;
    _data["VLSFrequencyType"] = AddLocalTaxStatusChangeVLSFrequencyType[this.VLSFrequencyType];
    return _data;
  }
}
export var AddLocalTaxStatusChangeESLFrequencyType = /* @__PURE__ */ ((AddLocalTaxStatusChangeESLFrequencyType2) => {
  AddLocalTaxStatusChangeESLFrequencyType2["Monthly"] = "Monthly";
  AddLocalTaxStatusChangeESLFrequencyType2["Quarterly"] = "Quarterly";
  AddLocalTaxStatusChangeESLFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  AddLocalTaxStatusChangeESLFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  AddLocalTaxStatusChangeESLFrequencyType2["BiMonthly"] = "BiMonthly";
  AddLocalTaxStatusChangeESLFrequencyType2["FourMonthly"] = "FourMonthly";
  AddLocalTaxStatusChangeESLFrequencyType2["SixMonthly"] = "SixMonthly";
  AddLocalTaxStatusChangeESLFrequencyType2["Yearly"] = "Yearly";
  return AddLocalTaxStatusChangeESLFrequencyType2;
})(AddLocalTaxStatusChangeESLFrequencyType || {});
export var AddLocalTaxStatusChangeIntrastatFrequencyType = /* @__PURE__ */ ((AddLocalTaxStatusChangeIntrastatFrequencyType2) => {
  AddLocalTaxStatusChangeIntrastatFrequencyType2["Monthly"] = "Monthly";
  AddLocalTaxStatusChangeIntrastatFrequencyType2["Quarterly"] = "Quarterly";
  AddLocalTaxStatusChangeIntrastatFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  AddLocalTaxStatusChangeIntrastatFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  AddLocalTaxStatusChangeIntrastatFrequencyType2["BiMonthly"] = "BiMonthly";
  AddLocalTaxStatusChangeIntrastatFrequencyType2["FourMonthly"] = "FourMonthly";
  AddLocalTaxStatusChangeIntrastatFrequencyType2["SixMonthly"] = "SixMonthly";
  AddLocalTaxStatusChangeIntrastatFrequencyType2["Yearly"] = "Yearly";
  return AddLocalTaxStatusChangeIntrastatFrequencyType2;
})(AddLocalTaxStatusChangeIntrastatFrequencyType || {});
export var AddLocalTaxStatusChangeLocalListingFrequencyType = /* @__PURE__ */ ((AddLocalTaxStatusChangeLocalListingFrequencyType2) => {
  AddLocalTaxStatusChangeLocalListingFrequencyType2["Monthly"] = "Monthly";
  AddLocalTaxStatusChangeLocalListingFrequencyType2["Quarterly"] = "Quarterly";
  AddLocalTaxStatusChangeLocalListingFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  AddLocalTaxStatusChangeLocalListingFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  AddLocalTaxStatusChangeLocalListingFrequencyType2["BiMonthly"] = "BiMonthly";
  AddLocalTaxStatusChangeLocalListingFrequencyType2["FourMonthly"] = "FourMonthly";
  AddLocalTaxStatusChangeLocalListingFrequencyType2["SixMonthly"] = "SixMonthly";
  AddLocalTaxStatusChangeLocalListingFrequencyType2["Yearly"] = "Yearly";
  return AddLocalTaxStatusChangeLocalListingFrequencyType2;
})(AddLocalTaxStatusChangeLocalListingFrequencyType || {});
export var AddLocalTaxStatusChangePrimaryVATFrequencyType = /* @__PURE__ */ ((AddLocalTaxStatusChangePrimaryVATFrequencyType2) => {
  AddLocalTaxStatusChangePrimaryVATFrequencyType2["Monthly"] = "Monthly";
  AddLocalTaxStatusChangePrimaryVATFrequencyType2["Quarterly"] = "Quarterly";
  AddLocalTaxStatusChangePrimaryVATFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  AddLocalTaxStatusChangePrimaryVATFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  AddLocalTaxStatusChangePrimaryVATFrequencyType2["BiMonthly"] = "BiMonthly";
  AddLocalTaxStatusChangePrimaryVATFrequencyType2["FourMonthly"] = "FourMonthly";
  AddLocalTaxStatusChangePrimaryVATFrequencyType2["SixMonthly"] = "SixMonthly";
  AddLocalTaxStatusChangePrimaryVATFrequencyType2["Yearly"] = "Yearly";
  return AddLocalTaxStatusChangePrimaryVATFrequencyType2;
})(AddLocalTaxStatusChangePrimaryVATFrequencyType || {});
export var AddLocalTaxStatusChangeSecondaryVATFrequencyType = /* @__PURE__ */ ((AddLocalTaxStatusChangeSecondaryVATFrequencyType2) => {
  AddLocalTaxStatusChangeSecondaryVATFrequencyType2["Monthly"] = "Monthly";
  AddLocalTaxStatusChangeSecondaryVATFrequencyType2["Quarterly"] = "Quarterly";
  AddLocalTaxStatusChangeSecondaryVATFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  AddLocalTaxStatusChangeSecondaryVATFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  AddLocalTaxStatusChangeSecondaryVATFrequencyType2["BiMonthly"] = "BiMonthly";
  AddLocalTaxStatusChangeSecondaryVATFrequencyType2["FourMonthly"] = "FourMonthly";
  AddLocalTaxStatusChangeSecondaryVATFrequencyType2["SixMonthly"] = "SixMonthly";
  AddLocalTaxStatusChangeSecondaryVATFrequencyType2["Yearly"] = "Yearly";
  return AddLocalTaxStatusChangeSecondaryVATFrequencyType2;
})(AddLocalTaxStatusChangeSecondaryVATFrequencyType || {});
export var AddLocalTaxStatusChangeVLSFrequencyType = /* @__PURE__ */ ((AddLocalTaxStatusChangeVLSFrequencyType2) => {
  AddLocalTaxStatusChangeVLSFrequencyType2["Monthly"] = "Monthly";
  AddLocalTaxStatusChangeVLSFrequencyType2["Quarterly"] = "Quarterly";
  AddLocalTaxStatusChangeVLSFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  AddLocalTaxStatusChangeVLSFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  AddLocalTaxStatusChangeVLSFrequencyType2["BiMonthly"] = "BiMonthly";
  AddLocalTaxStatusChangeVLSFrequencyType2["FourMonthly"] = "FourMonthly";
  AddLocalTaxStatusChangeVLSFrequencyType2["SixMonthly"] = "SixMonthly";
  AddLocalTaxStatusChangeVLSFrequencyType2["Yearly"] = "Yearly";
  return AddLocalTaxStatusChangeVLSFrequencyType2;
})(AddLocalTaxStatusChangeVLSFrequencyType || {});
