var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class PrefixedStorage {
  constructor(prefix, storage) {
    __publicField(this, "wrappedStorage");
    __publicField(this, "prefix");
    this.wrappedStorage = storage;
    this.prefix = prefix;
  }
  get storage() {
    return this.wrappedStorage;
  }
  removeItem(key) {
    this.storage.removeItem(this.getKey(key));
  }
  setItem(key, data) {
    if (!data) {
      this.removeItem(key);
    } else {
      this.storage.setItem(this.getKey(key), String(data));
    }
  }
  getItem(key) {
    return this.storage.getItem(this.getKey(key));
  }
  clearPermissions() {
    Object.keys(this.storage).forEach((key) => {
      if (key.startsWith(this.prefix)) {
        this.storage.removeItem(key);
      }
    });
  }
  getKey(key) {
    if (this.prefix) {
      return `${this.prefix}.${key}`;
    }
    return key;
  }
}
