import "./dc-loader2.scss";
import { html } from "lit-html";
import { component } from "haunted";
import classNames from "classnames";
export const Component = (host) => {
  const props = {
    progress: host.progress !== void 0 ? host.progress : void 0,
    isLoading: host.isLoading,
    size: host.size !== void 0 ? host.size : "normal"
  };
  return props.isLoading ? html`
				<div
					class=${classNames({
    "loader2-with-progress": props.progress !== void 0,
    "loader2": props.progress === void 0,
    [props.size]: true
  })}
					data-progress=${props.progress}
				></div>
				${props.progress !== void 0 ? html` <div class="progress">${props.progress}<span>%</span></div>` : ""}
		  ` : html``;
};
customElements.define(
  "dc-loader2",
  component(Component, {
    useShadowDOM: false,
    observedAttributes: []
  })
);
