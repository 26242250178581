var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { File } from "./File";
import { DateConverter } from "./../../../converters/DateConverter";
export class RejectReason {
  constructor(_data) {
    /**
     */
    __publicField(this, "Attachments");
    /**
     */
    __publicField(this, "Comment");
    /**
     */
    __publicField(this, "RejectedAt");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof RejectReason
     */
    __publicField(this, "$type", "V1.Submission.RejectReason");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["Attachments"] && _data["Attachments"].constructor === Array) {
        this.Attachments = [];
        for (let item of _data["Attachments"]) {
          const attachments_ = new File();
          this.Attachments.push(attachments_.init(item));
        }
      }
      this.Comment = _data["Comment"];
      this.RejectedAt = DateConverter.from(_data["RejectedAt"]);
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.Attachments && this.Attachments.constructor === Array) {
      _data["Attachments"] = [];
      for (let item of this.Attachments) {
        _data["Attachments"].push(item.toJSON());
      }
    }
    _data["Comment"] = this.Comment;
    _data["RejectedAt"] = DateConverter.toIsoUtcString(this.RejectedAt);
    return _data;
  }
}
